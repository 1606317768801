/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import Avatar from 'antd/lib/avatar/avatar';
import {DocumentUtils} from '@fstn/ecandidaturev2_api-interfaces';
import * as Locale from '../../../../common/locale';

export const avatarColumn = {
    header: <Locale.Header tkey="candidateFilesList.avatar" />,
    name: 'avatar',
    id: 'avatar',
    width: 55,
    sortable: false,
    defaultVisible: false,
    defaultLocked: 'start',
    render: (c) => (
      <div key={`avatar_cell_${c.data.id}`}>
        {c.value?.candidateFile
            && (
            <Fragment>
              {c.value && c.value.parentType
                    && (
                    <Avatar
                      src={DocumentUtils.getPreviewLink(c.value)}
                    />
                    )}
            </Fragment>
            )}
      </div>
    ),
};
