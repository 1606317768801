/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import PageHeader from 'antd/lib/page-header';
// @ts-ignore
import { usePath } from 'hookrouter';
import _ from 'lodash';
import { TextDangerButton } from '../../common/button/TextDangerButton';
import * as Locale from '../../common/locale';
import { MenuAndFormContainer } from '../../common/page/Containers';
import { sNavigate } from '../../utils/safeNavigation';
import { CenterAreaContainer } from '../canSeeDocuments/viewer/CenterAreaContainer';
import { UserPage } from '../user/UserPage';
import { LOGIN } from '../user/UserRoutesDef';
import { AdminLeftMenu } from './AdminLeftMenu';

const FooterStyle = styled.div`
grid-area: footer;
background-color: rgb(240, 240, 240);
padding-left: 1em;
padding-right: 1em;
`;
const HeaderStyle = styled.div`
grid-area: header;
`;

function AdminPageHeader() {
    const path = usePath();
    return (
      <HeaderStyle>
        <PageHeader
          title={<Locale.Title tkey="admin" />}
          className="site-page-header"
          subTitle={<Locale.Title tkey={`admin.${_.last(path.split('/'))}`} />}
          extra={(
            <TextDangerButton
              tkey="logout"
              onClick={() => sNavigate(`${LOGIN.LOGOUT}`)}
            />
)}
        />
      </HeaderStyle>
    );
}

function Footer() {
    return (
      <FooterStyle />
    );
}

export function AdminPage(props: { children: any }) {
    /**
     *  Permet de définir le style de la page
     *  style de la page, ça utilise la lib https://emotion.sh/docs/styled
     *  Tu peux soit définir un composant comme ci-dessous, soit utiliser la props css={css`display:block`}
     *
     */
    const PageStyle = styled.div`
            height: 100%;
            display: grid;
            grid-template-areas:  "header header"
                                  "left content"
                                  "footer footer";     
            grid-template-rows: 100px minmax(0, 100%);
            grid-template-columns: 260px minmax(0, 1fr);
            /* Allow to display date picker and override the App.css to keep cell in cell */
            .InovuaReactDataGrid__cell{
                overflow: visible !important;
            }
`;

    return (
      <UserPage>
        <PageStyle>
          <AdminPageHeader />
          <MenuAndFormContainer>
            <div css={css`grid-area: left;overflow-y: auto;overflow-x: hidden`}>
              <AdminLeftMenu />
            </div>
            <CenterAreaContainer>
              {props?.children}
            </CenterAreaContainer>
          </MenuAndFormContainer>
          <Footer />
        </PageStyle>
      </UserPage>
);
}
