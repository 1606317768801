import { ExclamationCircleOutlined, FormOutlined, MailOutlined } from '@ant-design/icons';
import Menu from '@inovua/reactdatagrid-community/packages/Menu';
import {
  Form, Modal, notification, Statistic,
} from 'antd';

// @ts-ignore
import React, { useContext } from 'react';
import { useImmer } from 'use-immer';
import { ResponsiveInput } from '../../../../common/input/ResponsiveInput';
import { AxiosContext, AxiosContextType } from '../../../../context/axios.context';
import { UserContext, UserContextType } from '../../../../context/user.context';
import { useUser } from '../../../../hooks/use-user.hook';

const { confirm } = Modal;

/**
 * Composant de gestion du menu contextuel dans la grid
 */
export function ReferentContextMenu({
  menuProps, details, selectedUsers, gridRef,
}: any) {
  const userCtx = useContext<UserContextType>(UserContext);
  const { loadMe } = useUser(userCtx);
  const { axios, updateToken } = useContext<AxiosContextType>(AxiosContext);
  const [state] = useImmer({ loading: false });

  const items = [];

  /**
   * Fonction appelée quand on demande a acceder au formulaire de recommandations en se reconnectant automatiquement comme
   * le referent seletcionne
   */
  const onImpersonateSelection = async () => {
    // On ne peut appliquer l'impersonate que sur un seul utilisateur selectionne (TODO : le gerer mieux au niveau IHM si possible)
    if (selectedUsers.length === 1) {
      const email = selectedUsers.map((cf) => cf.email)[0];
      const res = await axios.post(`administration/impersonate/${email}`, {});
      const accessToken = res.data.access_token;
      await updateToken(accessToken);
      await loadMe(accessToken);
      window.location.href = '/home/welcome';
    }
  };

  /**
   * Fonction appelée quand on demande l'envoi d'un email aux elements selectionnes
   * Affiche le nombre d'emails envoyes en message d'info
   */
  const onSendEmailSelection = async (form) => {
    if (selectedUsers.length > 0) {
      const listIds = selectedUsers.map((cf) => cf.id);
      try {
        const params = form;
        params.listIds = listIds;
        const response: any = await axios.post('admin/academic-referent/sendEmailSelection', params);
        notification.info({
          message: `${response.data.sent} mails envoyés pour ${response.data.total} utilisateurs sélectionnées !`,
          duration: 0,
        });
      } catch (e) {
        notification.error({
          message: "Erreur d'envoi des email",
          description: e.message,
          duration: 0,
        });
      }
    }
  };

  /* On ajoute ici dans le menu contextuel le menu permettant d'impersonate le referent selectionne */
  if (selectedUsers.length === 1) {
    items.push({
      label: 'Accéder au formulaire de recommandation',
      icon: <FormOutlined />,
      disabled: state.loading,
      onClick: (value) => {
        confirm({
          // @ts-ignore
          okButtonProps: { 'data-testid': 'referent-publish-results-ok' },
          // @ts-ignore
          cancelButtonProps: { 'data-testid': 'referent-publish-results-cancel' },
          title: 'Accès au formulaire de recommandations',
          icon: <ExclamationCircleOutlined />,
          content: (
            <div>
              <Statistic
                title="Nombre d'éléments selectionnés"
                value={selectedUsers.length}
              />
              Voulez-vous vraiment vous connecter selon ce référent ?
            </div>
          ),
          onOk: () => onImpersonateSelection(), // Appel de l'impersonnalisation avec le referent selectionne
        });
      },
    });
  }

  /* On ajoute ici dans le menu contextuel le menu permettant d'envoyer un mail a la selection */
  if (selectedUsers.length > 0) {
    const [form] = Form.useForm();
    items.push({
      label: 'Envoyer un email...',
      icon: <MailOutlined />,
      disabled: state.loading,
      onClick: (value) => {
        confirm({
          // @ts-ignore
          okButtonProps: { 'data-testid': 'referent-publish-results-ok' },
          // @ts-ignore
          cancelButtonProps: { 'data-testid': 'referent-publish-results-cancel' },
          title: "Envoi d'un email",
          icon: <ExclamationCircleOutlined />,
          onOk: () => {
            form
              .validateFields()
              .then((values) => {
                onSendEmailSelection(values);
              });
          },
          content: (
            <div>
              <Statistic
                title="Nombre d'éléments selectionnés"
                value={selectedUsers.length}
              />
              <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                  modifier: 'public',
                }}
              >
                <Form.Item
                  name="templateId"
                  label="Id du template sendGrid"
                  rules={[
                    {
                      required: true,
                      message: 'Champ obligatoire !',
                    },
                  ]}
                >
                  <ResponsiveInput data-testid="template-id" type="string" />
                </Form.Item>
              </Form>
              Voulez-vous vraiment envoyer cet email pour ces utilisateurs ?
            </div>
          ),
        });
      },
    });
  }

  return (
    <Menu
      {...menuProps}
        /* ok */ disabled={state.loading}
      items={items}
    />
  );
}
