import { IMissingFieldsList } from '@fstn/ecandidaturev2_api-interfaces';
import React, { useContext, useEffect } from 'react';
import { parse, stringify } from 'flatted';
import { useImmer } from 'use-immer';

import { notification } from 'antd';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { useEntity } from '../../../hooks/use-entity';
import { CandidateFileContext, CandidateFileContextProviderType } from '../../canSeeCandidateFile/CandidateFileContext';
import { MissingFieldsListForm } from './MissingFieldsListForm';
import { notificationTrad } from '../../../common/locale';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';

export function MissingFieldsListFormContainer(props: {}) {
    const { loadEntity } = useEntity<any>();
    const [state, updateState] = useImmer<{ missingFieldsList: IMissingFieldsList }>({ missingFieldsList: undefined });
    const { t } = useSafeTranslation();

    // if( not value.missingFields
    async function refresh() {
        updateState((draft: any) => {
            draft.missingFieldsList.missingFields = [];
        });
        const missingFieldsList = await loadEntity('validation/candidate-file/missing-fields');
        updateState((draft: any) => {
            draft.missingFieldsList = missingFieldsList;
        });
        if (missingFieldsList?.missingFields?.length === 0) {
            notification.success({ message: notificationTrad('no.missingFields.updated', t) });
        } else {
            notification.success({ message: notificationTrad('missingFields.updated', t) });
        }
    }

    const onBeforeValidate = (values: IMissingFieldsList | any, differences: Partial<IMissingFieldsList>) => {
        // clone the object to avoid readonly
        const res = parse(stringify(values));
        if (res.missingFields) {
            differences.missingFields.forEach((m, i) => {
                res.missingFields[i] = { ...res.missingFields?.[i], ...m || {} };
            });
        }
        return res;
    };

    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);

    async function load() {
        if (candidateFileContext.selectedCandidateFile?.id) {
            const { data } = await axios.get(`/candidate-file/${candidateFileContext.selectedCandidateFile?.id}`);
            updateState((draft) => {
                draft.missingFieldsList = data.missingFieldsList;
            });
        }
    }

    useEffect(() => {
        load().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <MissingFieldsListForm
        missingFieldsList={state.missingFieldsList}
        refresh={refresh}
        onBeforeValidate={onBeforeValidate}
      />
);
}
