/**
 * Extract form list property like ["academics",0,name] to key like [0].name
 * @see formListPropertyPathToKey.spec.ts
 */
export function formListPropertyPathToKey(propertyPath: string | any[] | number): string | any[] | number {
  let propertyNameWithoutListItemName = propertyPath;

  if (Array.isArray(propertyPath)) {
    propertyNameWithoutListItemName = [...propertyPath];
    propertyNameWithoutListItemName.shift();
  } else if ((`${propertyPath}`)?.indexOf(',') !== -1) {
    propertyNameWithoutListItemName = (propertyPath as string)?.split(',');
  }

  return propertyNameWithoutListItemName;
}
