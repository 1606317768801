/** @jsx jsx */
import { css, jsx } from '@emotion/core';

export function Important({ children }: any) {
    return (
      <div css={css`zoom: 1.1`}>
        {children}
      </div>
    );
}
