import React, { Fragment } from 'react';
import { AdminChild1Container } from './AdminChild1Container';

/**
 * Routes starting with /home/welcome/admin/
 * @constructor
 */
export const AdminContainerRoutesDef = () => ({
    '/admin*': () => (
      <Fragment>
        <AdminChild1Container />
      </Fragment>
    ),

});
