import React, { Fragment, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { createContext } from 'use-context-selector';
import { useScopedSelector } from '../../hooks/use-scoped-selector';

/**
 * Set disable flag on entity form items depending of context
 * @param props
 * wrapAuthorized: use to wrap it into another one
 * @constructor
 */

export type DisabledContextType = { disabled: boolean, wrapAuthorized: boolean, context?: string };
export const DisabledContext = createContext<DisabledContextType>(undefined);
export function DisabledContextProvider(props: { value:DisabledContextType } & { children?: any }) {
    /**
     * TODO disabled ignore this if already have an applied context
     */
    const [state, updateState] = useImmer({
        disabled: props.value.disabled,
        wrapAuthorized: props.value.wrapAuthorized,
        context: props.value.context,
    });
    const disabledContext = useScopedSelector('DisabledContextProvider', DisabledContext, (c) => c);
    if (!props.value.wrapAuthorized && disabledContext?.disabled !== undefined) {
        throw new Error(`Unable to wrap this context into another disabled context ${disabledContext.context}`);
    }
    const disableState = props.value.disabled === undefined ? disabledContext?.disabled : props.value.disabled;

    // console.log('Redraw SetDisabledBasedOnRights', props);
    useEffect(() => {
        updateState((draft) => {
            draft.disabled = disableState;
        });
    }, [disableState, updateState]);
    // loop add

    if (state.disabled !== undefined) {
        return (
          <Fragment>
            <DisabledContext.Provider value={state}>
              {props.children}
            </DisabledContext.Provider>
          </Fragment>
        );
    }
        return (
          <Fragment>
            {props.children}
          </Fragment>
        );
}
