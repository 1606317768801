import { PopoverProps } from 'antd';
import Popover from 'antd/lib/popover';
import * as React from 'react';

/**
 * Popover that can be disabled on property
 * @param props
 * @constructor
 */
export function ConditionnalPopover(props: { disabled?: boolean, children: any } & PopoverProps) {
    if (!props.disabled) {
        return (
          <Popover {...props}>
            {props.children}
          </Popover>
        );
    }
        return props.children;
}
