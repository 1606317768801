/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

const Style = styled.div`
  .ant-radio-button-wrapper-checked,
  .ant-switch-checked {
    background-color: ${(props) => props.color} !important;
    border: none !important;
  }
`;

/**
 * Change children background color, if it wrap components
 * @param props
 * @constructor
 */
export function Colorized(props: { color: string, children: any }) {
    return (
      <Style {...props}>
        {props.children}
      </Style>
);
}
