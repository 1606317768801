/** @jsx jsx */
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FolderOpenOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { jsx } from '@emotion/core';
import { CandidateFileStatusEnum, ExportGroupEnum, ISecretary, RightsUtils } from '@fstn/ecandidaturev2_api-interfaces';
import Menu from '@inovua/reactdatagrid-community/packages/Menu';
import { Modal, Statistic, notification } from 'antd';

// @ts-ignore
import { useContext } from 'react';
import * as Locale from '../../common/locale';
import { AxiosContext, AxiosContextProvider, AxiosContextType } from '../../context/axios.context';
import { IhmContextProvider } from '../../context/ihm.context';
import { UserContext } from '../../context/user.context';
import { useLoading } from '../../hooks/use-loading';
import { sNavigate } from '../../utils/safeNavigation';
import { DocumentDownloaderTypeSelector } from '../canDownloadDocuments/documentsDownloader/DocumentDownloaderTypeSelector';
import { ROUTES } from '../user/UserRoutesDef';
import { getEventServiceInstance } from '../../utils/eventService';
import { StrictLoading } from '../../common/indicator/SmartLoading';
import { useSegment } from '../../hooks/use-segment';
import { ExportGroupTypeSelector } from '../canExportXls/ExportGroupTypeSelector';
import { SUPER_SECRETARY_RIGHTS } from '@fstn/ecandidaturev2_api-interfaces';
import _ from 'lodash';
import { useImmer } from 'use-immer';


const { confirm } = Modal;

export function SecretaryRowContextMenu({ menuProps, details, selectedCandidateFiles, gridRef }: any) {
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  const userCtx = useContext(UserContext);
  const { doAction, loading } = useLoading();
  const { segment } = useSegment(selectedCandidateFiles);
  const [state, updateState] = useImmer({ loading: false });

  const selectedCandidateFileId = details.rowProps.data.id;
  let items = [];

  /**
   * Permet de modifier le statut de l'ensemble des dossiers selectionnes avec la valeur passee en parametre depuis le menu deroulant
   * @param cfStatus Statut a affecter a l'ensemble des dossiers selectionnes
   */
  const onChangeStatus = async (cfStatus) => {
    const updateManyBaseDto = {
      candidateFileIds: selectedCandidateFiles.map((cf) => cf.id),
      updates: { status: cfStatus },
    };
    updateState((draft) => {
      draft.loading = true;
    });
    try {
      await axios.patch('candidate-file/bulk', updateManyBaseDto);

      // Mise a jour dans la grid du nouveau statut pour les dossiers modifies (permet de refleter la realite de la modif dans l'IHM sans devoir refresh)
      segment.candidateFiles.forEach((c) => {
        const index = gridRef.current.getItemIndexBy((item) => item.id === c.id);
        if (index !== -1) {
          gridRef.current.setItemPropertyAt(index, 'candidateFileStatus', cfStatus);
        }
      });
      getEventServiceInstance().logEvent('change_status.secretary', { candidate_files_ids: selectedCandidateFiles?.map((c) => c.id), status: cfStatus });
    } catch (e) {
      notification.error({
        message: <Locale.Title tkey="cf.change_status.error" />,
        description: e.response?.data?.message,
        duration: 0,
      });
    }
    finally {
      updateState((draft) => {
        draft.loading = false;
      });
    }
  };

  if (details.rowProps.data.candidateFileStatus !== CandidateFileStatusEnum.NEW
    || RightsUtils.isAuthorizeForTable((userCtx?.userCtx?.user as ISecretary)?.rightsOverriding?.write || [], 'candidate_file')) {
    if (selectedCandidateFiles.length === 1) {
      items.push({
        label: <Locale.Button tkey="candidateFile.open" />,
        icon: <FolderOpenOutlined />,
        className: 'openCandidateFile',
        onClick: async () => {
          setTimeout(async () => {
            const params = { candidateFileId: selectedCandidateFileId };
            try {
              await axios.put('candidate-file/lock', {}, { params });
              // block it if not blocked
              localStorage.setItem('previousSecretaryUrlWithFilter', window.location.href);
              sNavigate(`${ROUTES.SECRETARY_BASE}/${selectedCandidateFileId}/programs-list`);
            } catch (e) {
              if (e.response?.status === 422) {
                confirm({
                  // @ts-ignore
                  okButtonProps: { 'data-testid': 'secretary-alreadyLocked-ok' },
                  // @ts-ignore
                  cancelButtonProps: { 'data-testid': 'secretary-alreadyLocked-cancel' },
                  title: <Locale.Notification tkey="secretary.alreadyLocked" />,
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <div>
                      <Locale.Content tkey="secretary.alreadyLocked" />
                    </div>
                  ),
                  async onOk() {
                    await axios.put('candidate-file/lock', { force: true }, { params });
                    // block it if not blocked
                    sNavigate(`${ROUTES.SECRETARY_BASE}/${selectedCandidateFileId}/programs-list`);
                  },
                });
              } else {
                throw e;
              }
            }
            getEventServiceInstance().logEvent('open.secretary.candidate_file', { candidate_file: { id: details.rowProps.data.id } });
          }, 0);
        },
      });
    }
  }

  // Les menus Fiches de synthese, export xls et export fichier ne sont disponibles que pour les SD+ et SSD
  if ((_.isEqual(userCtx.userCtx.user?.rightsOverriding, SUPER_SECRETARY_RIGHTS) || (userCtx?.userCtx?.user?.plusSecretary))) {
    items = items.concat([{
      label: <Locale.Button tkey="candidateFile.PDF" />,
      icon: <FilePdfOutlined />,
      className: 'openPDF',
      onClick: async () => {
        await doAction(async () => {
          sNavigate(`${ROUTES.SECRETARY_BASE}/pdf`, true, { segmentId: segment.id });
        });
      },
    }, {
      label: <Locale.Button tkey="candidateFile.XLS" />,
      icon: <TableOutlined />,
      className: 'openXLS',
      onClick: async () => {
        confirm({
          // @ts-ignore
          title: <Locale.Notification tkey="candidateFile.export.selectType" />,
          icon: <ExclamationCircleOutlined />,
          width: 600,
          okButtonProps: { disabled: true, style: { display: 'none' } },
          content: (
            <div>
              <AxiosContextProvider>
                <IhmContextProvider>
                  <ExportGroupTypeSelector
                    details={details}
                    segmentId={segment.id}
                    excludedGroups={[ExportGroupEnum.PROGRAMS_LIST]}
                  />
                </IhmContextProvider>
              </AxiosContextProvider>
            </div>
          ),
        });
      },
    }, {
      label: <Locale.Button tkey="candidateFile.downloadFiles" />,
      icon: <DownloadOutlined />,
      className: 'openFiles',
      onClick: async () => {
        confirm({
          // @ts-ignore
          title: <Locale.Notification tkey="candidateFile.downloadFiles.selectType" />,
          icon: <ExclamationCircleOutlined />,
          width: 600,
          okButtonProps: { disabled: true, style: { display: 'none' } },
          content: (
            <div>
              <AxiosContextProvider>
                <IhmContextProvider>
                  <DocumentDownloaderTypeSelector
                    details={details}
                    segmentId={segment.id}
                  />
                </IhmContextProvider>
              </AxiosContextProvider>
            </div>
          ),
        });
      },
    }]);
  }
  /* Ajout du menu pour changer l'état des dossiers selectionnes en proposant un menu deroulant avec les statuts possible
     Ce menu n'est accessible qu'au SSD 
  */
  if (_.isEqual(userCtx.userCtx.user?.rightsOverriding, SUPER_SECRETARY_RIGHTS)) {
    if (selectedCandidateFiles.length > 0) {
      const subItems = [];
      for (const cfStatus of Object.values(CandidateFileStatusEnum)) {
        // On filtre les statut NEW, SUBMITTED et FORCED qui ne doivent pas etre proposes dans le menu
        if (![CandidateFileStatusEnum.NEW, CandidateFileStatusEnum.SUBMITTED, CandidateFileStatusEnum.FORCED].includes(cfStatus)) {
          subItems.push({
            label: <Locale.Button tkey={`${cfStatus}`} />,
            icon: <TableOutlined />,
            disabled: state.loading,
            onClick: (value) => {
              confirm({
                // @ts-ignore
                okButtonProps: { 'data-testid': 'cf-change-status-ok' },
                // @ts-ignore
                cancelButtonProps: { 'data-testid': 'cf-change-status-cancel' },
                title: <Locale.Notification tkey="cf.status.change.confirm" />,
                icon: <ExclamationCircleOutlined />,
                content: (
                  <div>
                    <Statistic
                      title={<Locale.Label tkey="cf.status.change.nbSelected" />}
                      value={selectedCandidateFiles.length}
                    />
                    <Locale.Notification tkey="cf.status.change.description" />
                  </div>
                ),
                onOk: () => onChangeStatus(cfStatus),
                // change state for selected files
              });
            },
          });
        }
      }
      items.push({
        label: <Locale.Button tkey="cf.status.change" />,
        icon: <TableOutlined />,
        disabled: state.loading,
        items: subItems,
      });
    }
  }

  return (
    <StrictLoading loading={loading} context="SecretaryRowContextMenu">
      <Menu
        {...menuProps}
        items={items}
      >
      </Menu>
    </StrictLoading>
  );
}
