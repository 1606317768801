/** @jsx jsx */
import { IAcademicReferentReview, IDocument } from '@fstn/ecandidaturev2_api-interfaces';
import { useImmer } from 'use-immer';
import React, { Fragment, useContext } from 'react';
import { useDeepCompareCallback, useDeepCompareEffect } from 'use-deep-compare';
import UUID from 'uuid-js';
import { Box, Flex } from 'rebass';
import { css, jsx } from '@emotion/core';
import Space from 'antd/lib/space';
import { Form } from 'antd';
import Popconfirm from 'antd/lib/popconfirm';
import { DeepPartial } from 'redux';
import { useScopedSelector } from '../../hooks/use-scoped-selector';
import { EntityFormListContext } from '../../context/entityFormList.context';
import { useValidation } from '../../hooks/use-validation.hook';
import { useResponsiveForm } from '../../hooks/use-responsive-form.hook';
import { useEntity } from '../../hooks/use-entity';
import { CandidateFileContext, CandidateFileContextProviderType } from '../canSeeCandidateFile/CandidateFileContext';
import { EntityFormContext, EntityFormContextType } from '../../context/entityForm.context';
import { ValidationsContext } from '../../context/validations.context';
import { FormContentPanelContainer } from '../../common/form/formContentPanel/FormContentPanelContainer';
import { YesNoFormItem } from '../../common/form/inputFormItems/YesNoFormItems';
import * as Locale from '../../common/locale';
import { InputNumberFormItem } from '../../common/form/inputFormItems/InputNumberFormItem';
import { InputTextAreaFormItem } from '../../common/form/inputFormItems/InputTextAreaFormItem';
import { UploadFormItemContainer } from '../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import { DatePickerFormItem } from '../../common/form/inputFormItems/DatePickerFormItem';
import { IsReferent } from '../user/conditionnal/IsReferent';
import { ButtonsFormItemLayout } from '../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import { TextOkButton } from '../../common/button/TextOkButton';
import { AxiosContext, AxiosContextType } from '../../context/axios.context';
import { sNavigate } from '../../utils/safeNavigation';
import { ROUTES } from '../user/UserRoutesDef';
import { FormStyle } from '../../common/form/FormStyle';
import { CandidateCard } from '../canSeeCandidateFile/CandidateCard';
import { CandidateFilePrograms } from '../canSeeCandidateFile/CandidateFilePrograms';
import { WithInitialLoad } from '../../common/withExtraParameters/WithInitialLoad';
import { EntityFormContainer } from '../../common/form/entityForm/EntityFormContainer';
import { DisabledContextProviderAlwaysEnabled } from '../../common/rights/DisabledContextProviderAlwaysEnabled';
import { ReferentFinalReview } from './FinalReview';
import { StrictLoading } from '../../common/indicator/SmartLoading';
import styled from '@emotion/styled';

// SHort name because of column size limitations
const entity = 'a-r-review';
export function ReferentReviewEntityForm() {
  const field = useScopedSelector<any[], any>('IAcademicReferentReview', EntityFormListContext, (c) => c[0].field);
  const formListPropertyName = useScopedSelector<any[], any>('IAcademicReferentReview', EntityFormListContext, (c) => c[0]?.propertyName);
  const formListEntityId = useScopedSelector<any[], string>('IAcademicReferentReview', EntityFormListContext, (c) => c[0]?.entityValue?.id);
  const givenBySchool = useScopedSelector<any[], boolean>('IAcademicReferentReview', EntityFormListContext, (c) => c[0]?.entityValue?.givenBySchool);
  const reviewDocumentCompleted = useScopedSelector<any[], boolean>('IAcademicReferentReview', EntityFormListContext, (c) => c[0]?.entityValue?.reviewDocumentCompleted);
  const reviewDocument = useScopedSelector<any[], IDocument>('IAcademicReferentReview', EntityFormListContext, (c) => c[0]?.entityValue?.reviewDocument);

  const { validate } = useValidation();
  const responsive = useResponsiveForm();
  const [state, updateState] = useImmer({ alreadyValidated: false });
  const { putEntity } = useEntity<any>();
  const entityValue = useScopedSelector<any[], EntityFormContextType<IAcademicReferentReview>>('ReferentReviewEntityForm', EntityFormContext, (c) => c[0]?.entityValue);
  const withUpdateValidations = useScopedSelector('ReferentReviewEntityForm', ValidationsContext, (c) => c.withUpdateValidations);
  const steps = useScopedSelector('ReferentReviewEntityForm', ValidationsContext, (c) => c.steps);
  const submit = useScopedSelector<any[], any>('ReferentReviewEntityForm', EntityFormContext, (c) => c[0].submit);

  const Style = styled.div`
thead {
}

tbody {
}

table {
  border-collapse: collapse;
  border: 2px solid rgb(255 255 255);
  letter-spacing: 1px;
}

th {
  border: 1px solid rgb(255 255 255);
  padding: 8px 10px;
  text-align: center !important;
  background-color: #00B8DE;
  color: white;
}

td {
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
  text-align: center !important;
}

th.notCenter {
  text-align: left !important;
}
`;

  useDeepCompareEffect(() => {
    async function load() {
      await validate(entity, entityValue, withUpdateValidations, steps);
    }
    if (!state.alreadyValidated) {
      updateState((draft) => {
        draft.alreadyValidated = true;
      });
      load().then(() => { });
    }
  }, [entityValue, state.alreadyValidated, updateState, validate]);
  // loop add formContext.entityValue, state.alreadyValidated, updateState, validate

  const addDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'reviewDocument',
    type: 'reviewDocument',
    parentType: entity,
    parentId: formListEntityId,
  }), [entity, formListEntityId]);

  function getPropertyName(name) {
    if (field) {
      return [formListPropertyName, field.name, name];
    }
    return name;
  }
  return (
    <StrictLoading loading={!formListEntityId} context="ReferentReviewEntityForm">
      <Flex sx={{ flexWrap: 'wrap' }} className="referent-review-container">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Box {...responsive.w1}>
          <FormContentPanelContainer partName={entity}>
            <Fragment>
              <div css={css`padding: 1em;`}>
                <YesNoFormItem propertyName={getPropertyName('givenBySchool')} />
              </div>
              {givenBySchool
                  && (
                  <div css={css`padding: 1em;`}>
                    <UploadFormItemContainer
                      onAfterChange={async () => {
                        await putEntity('comments/onUpdateAcademicReferentReviewDocument', { id: formListEntityId }, { referentReviewId: formListEntityId });
                      }}
                      accept={'image/*,.pdf'}
                      propertyName={getPropertyName('reviewDocument')}
                      factory={addDocument}
                    />
                    <DatePickerFormItem
                      onAfterChange={async () => {
                        /**
                         * Need this to be sure that data exists on server
                         */
                        setTimeout(async () => {
                          await putEntity('comments/onUpdateAcademicReferentReviewDate', { id: formListEntityId }, { referentReviewId: formListEntityId });
                        }, 1000);
                      }}
                      propertyName={getPropertyName('juryDate')}
                    />
                    {reviewDocument?.originalName && (
                      <div css={css`padding: 1em;`}>
                        <YesNoFormItem propertyName={getPropertyName('reviewDocumentCompleted')} />
                      </div>
                    )}
                </div>
              )}

              {/* S'il n'ya pas d'avis PE fourni, ou qu'il ne contient pas toutes les infos necessaires, on affiche le formulaire facultatif complementaire (moyennes, classement, avis jury, commentaires) */}
              {(givenBySchool === false || reviewDocumentCompleted === false)
                && (
                  <Fragment>
                    <Style>
                      <Space direction="vertical" css={css`width:100%`}>
                        <div
                          className="referent-review-notation-container"
                          css={css`
  padding: 1em;
  padding-right: 0em;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  >.ant-form-item{
    width: calc( 33% - .5em);
    min-width: calc( 33% - .5em);
    max-width: calc( 33% - .5em);
    flex: 1;
  }
  >.label{
    align-items: center;
      display: flex;
  }
  `}
                        >
                          <div style={{ width: 'calc( 100% + -1em )' }}>
                            <Form.Item>
                              <Locale.Help
                                tkey="academic-referent-review.average"
                              />
                            </Form.Item>
                          </div>
                          <table>
                            <thead>
                              <tr>
                                <th scope="col" className="notCenter"><Locale.Label tkey='tabHeadYear'/></th>
                                <th scope="col" colSpan={2}><Locale.Label tkey='tabHeadYearN-2'/></th>
                                <th scope="col" colSpan={2}><Locale.Label tkey='tabHeadYearN-1'/></th>
                                <th scope="col"><Locale.Label tkey='tabHeadYearN'/></th>
                              </tr>
                              <tr>
                                <th scope="col" className="notCenter"><Locale.Label tkey='tabHeadSem'/></th>
                                <th scope="col"><Locale.Label tkey='tabHeadS1N-2'/></th>
                                <th scope="col"><Locale.Label tkey='tabHeadS2N-2'/></th>
                                <th scope="col"><Locale.Label tkey='tabHeadS1N-1'/></th>
                                <th scope="col"><Locale.Label tkey='tabHeadS2N-1'/></th>
                                <th scope="col"><Locale.Label tkey='tabHeadS1N'/></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row" className="notCenter"><Locale.Label tkey='tabHeadAverage'/></th>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y1s1GenCandidateAverage')}
                                  />
                                </td>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y1s2GenCandidateAverage')}
                                  />
                                </td>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y2s1GenCandidateAverage')}
                                  />
                                </td>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y2s2GenCandidateAverage')}
                                  />
                                </td>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y3s1GenCandidateAverage')}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row" className="notCenter"><Locale.Label tkey='tabHeadRank'/></th>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y1s1Rank')}
                                  />
                                </td>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y1s2Rank')}
                                  />
                                </td>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y2s1Rank')}
                                  />
                                </td>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y2s2Rank')}
                                  />
                                </td>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y3s1Rank')}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row" className="notCenter"><Locale.Label tkey='tabHeadStaff'/></th>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y1s1Staff')}
                                  />
                                </td>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y1s2Staff')}
                                  />
                                </td>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y2s1Staff')}
                                  />
                                </td>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y2s2Staff')}
                                  />
                                </td>
                                <td> 
                                  <InputNumberFormItem
                                    labelVisible={false}
                                    wrapperCol={{ span: 8 }}
                                    propertyName={getPropertyName('y3s1Staff')}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div css={css`padding: 1em;`}>
                            <ReferentFinalReview propertyName={getPropertyName('finalReview')} />
                        </div>
                        <div css={css`padding: 1em;`}>
                          <InputTextAreaFormItem
                            wrapperCol={{ span: 24 }}
                            labelCol={{ span: 24 }}
                            propertyName={getPropertyName('comments')}
                          />
                        </div>
                      </Space>
                    </Style>
                  </Fragment>
                )}

              <IsReferent>
                <ButtonsFormItemLayout>
                  <Popconfirm
                    title={<Locale.Title tkey="academic-referent-review.complete.confirm" />}
                    onConfirm={submit}
                    okText={<Locale.Button tkey="ok" />}
                    cancelText={<Locale.Button tkey="cancel" />}
                  >
                    <TextOkButton tkey="academic-referent-review.complete" />
                  </Popconfirm>
                </ButtonsFormItemLayout>
              </IsReferent>
            </Fragment>
          </FormContentPanelContainer>
        </Box>
      </Flex>
    </StrictLoading>
  );
}

export function ReferentReviewForm({ academicReferentReviewId }: any) {
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  const { deleteEntity } = useEntity();
  const { updateCandidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);

  async function complete(values: IAcademicReferentReview) {
    if (values.completed) {
      await axios.put(`a-r-review/${values.id}/update`, {});
    } else {
      await axios.put(`a-r-review/${values.id}/complete`, {});
    }

    /**
     * Remove selected file to display all candidateFile on the referent list not only the selected one
     */
    updateCandidateFileContext(((draft) => {
      draft.selectedCandidateFile = undefined;
    }));
    sNavigate(ROUTES.REFERENT_BASE);
  }
  const onBeforeValidate = async (values: IAcademicReferentReview, differences: DeepPartial<IAcademicReferentReview>, setFieldsValue) => {
    // Si on bascule l'element Avis PE fourni à false et qu'un PE avait ete joint, on le supprime pour éviter de garder un doc "fantome"
    if (differences.givenBySchool === false && !!values.reviewDocument) {
      await deleteEntity('document', values.reviewDocument);
      differences.reviewDocument = null;
      setFieldsValue({ values, reviewDocument: null });
    }
    // Si on bascule l'element Avis PE fourni à false, on efface les infos liées (la completude du document et la date de remise)
    if (differences.givenBySchool === false) {
      // @ts-ignore
      differences.juryDate = null;
      differences.reviewDocumentCompleted = null;
      setFieldsValue({ values, juryDate: null });
      setFieldsValue({ values, reviewDocumentCompleted: null });
    }
    // Si on bascule Avis PE fourni à true ou avis fourni complet a true, on efface toutees les informations du formulaire complémentaire qui disparait pour eviter les donnees fantomes
    if (differences?.givenBySchool || differences?.reviewDocumentCompleted) {
      differences.y1s1GenCandidateAverage = null;
      setFieldsValue({ values, y1s1GenCandidateAverage: null });
      differences.y1s1Rank = null;
      setFieldsValue({ values, y1s1Rank: null });
      differences.y1s1Staff = null;
      setFieldsValue({ values, y1s1Staff: null });
      differences.y1s2GenCandidateAverage = null;
      setFieldsValue({ values, y1s2GenCandidateAverage: null });
      differences.y1s2Rank = null;
      setFieldsValue({ values, y1s2Rank: null });
      differences.y1s2Staff = null;
      setFieldsValue({ values, y1s2Staff: null });
      differences.y2s1GenCandidateAverage = null;
      setFieldsValue({ values, y2s1GenCandidateAverage: null });
      differences.y2s1Rank = null;
      setFieldsValue({ values, y2s1Rank: null });
      differences.y2s1Staff = null;
      setFieldsValue({ values, y2s1Staff: null });
      differences.y2s2GenCandidateAverage = null;
      setFieldsValue({ values, y2s2GenCandidateAverage: null });
      differences.y2s2Rank = null;
      setFieldsValue({ values, y2s2Rank: null });
      differences.y2s2Staff = null;
      setFieldsValue({ values, y2s2Staff: null });
      differences.y3s1GenCandidateAverage = null;
      setFieldsValue({ values, y3s1GenCandidateAverage: null });
      differences.y3s1Rank = null;
      setFieldsValue({ values, y3s1Rank: null });
      differences.y3s1Staff = null;
      setFieldsValue({ values, y3s1Staff: null });
      differences.finalReview = null;
      setFieldsValue({ values, finalReview: null });
      differences.comments = null;
      setFieldsValue({ values, comments: null });
    }
    return differences;
  };

  return (
    <FormStyle>
      <div css={css`.message{background-color: whitesmoke}`}>
        <CandidateCard />
        <CandidateFilePrograms />
      </div>
      <WithInitialLoad entity={entity} entityId={academicReferentReviewId}>
        <EntityFormContainer listeners={{ onBeforeValidate }} onFinish={complete}>
          <DisabledContextProviderAlwaysEnabled>
            <ReferentReviewEntityForm />
          </DisabledContextProviderAlwaysEnabled>
        </EntityFormContainer>
      </WithInitialLoad>
    </FormStyle>
  );
}
