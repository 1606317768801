/** @jsx jsx */
import StringFilter from '@inovua/reactdatagrid-community/StringFilter';
import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import { MutableRefObject } from 'react';
import { jsx } from '@emotion/core';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { IUser } from '@fstn/ecandidaturev2_api-interfaces';
import { CustomGridSelectEditor } from '../../../../common/customDatagrid/customGridSelectEditor';
import { AdminContextMenu } from './AdminContextMenu';
import { getSelectedElements } from '../../common/AdminEditableList';

// Pour alimentation des combos (filtres et edition) du genre
const listGender = [
    { id: 'm', label: 'Masculin' },
    { id: 'f', label: 'Féminin' },
    { id: 'o', label: 'Autre' }];

export const adminListInitialColumns = () => [
    {
        header: 'Genre',
        name: 'gender',
        width: 150,
        editable: true,
        style: { textAlign: 'center' },
        lockable: false,
        render: ({ value }) => listGender.find((element) => element.id === value)?.label,
        filterEditor: SelectFilter,
        filterEditorProps: {
            placeholder: 'All',
            dataSource: listGender,
        },
        renderEditor: (editorProps) => <CustomGridSelectEditor {...editorProps} />,
        editorProps: {
            idProperty: 'gender',
            dataSource: listGender,
        },
    }, {
        header: 'Prénom',
        name: 'firstName',
        width: 200,
        editable: true,
        style: { textAlign: 'left' },
    }, {
        header: 'Nom',
        name: 'lastName',
        width: 200,
        editable: true,
        style: { textAlign: 'left' },
    }, {
        header: 'E-mail',
        name: 'email',
        className: 'email',
        'test-id': 'email',
        width: 250,
        editable: true,
        style: { textAlign: 'left' },
        filterEditor: StringFilter,
        filterEditorProps: {
            className: 'emailFilter',
            'test-id': 'emailFilter',
        },
    },
];

export const adminListInitialFilters = [
    {
        name: 'firstName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'lastName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'email', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'gender', operator: 'contains', type: 'string', value: '',
    },
];

export function adminListRenderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedUsers, gridRef) {
    return (
      <AdminContextMenu
        menuProps={menuProps}
        details={details}
        selectedUsers={getSelectedElements(details.rowProps.data, selectedUsers)}
        gridRef={gridRef}
      />
    );
}

/**
 * Map result to row
 * @param d
 */
export function adminListMapToRow(d: IUser) {
    const row: any = {
        id: d.id,
        firstName: d.firstName,
        lastName: d.lastName,
        email: d.email,
        gender: d.gender,
    };
    return row;
}
