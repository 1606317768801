/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { useSafeTranslation } from '../../../../hooks/use-safe-translation';
import { AdminEditableList } from '../../common/AdminEditableList';
import {
  configsListInitialColumns,
  configsListInitialFilters,
  configsListMapToRow,
  configsListRenderRowContextMenu,
} from './BachelorSpecialityInitialConfig';

export function BachelorSpecialityListPage() {
  const { t } = useSafeTranslation();
  return (
    <Fragment>
      <AdminEditableList
        entity="admin/bachelor-specialities"
        renderRowContextMenu={configsListRenderRowContextMenu}
        initialFilters={configsListInitialFilters}
        initialColumns={configsListInitialColumns(t)}
        gridStyle={{ height: 'calc( 100% - 250px)' }}
        mapToRow={configsListMapToRow}
      />
    </Fragment>
  );
}
