import { IStayAbroad } from '@fstn/ecandidaturev2_api-interfaces/dist/src';
import { View } from '@react-pdf/renderer';
import React, { Fragment } from 'react';
import { PDFCardGutter } from '../../../common/pdf/components/PDFCardGutter';
import { PDFH1 } from '../../../common/pdf/components/PDFH1';
import { PDFEntityForm } from '../../../common/pdf/form/PDFEntityForm';
import { PDFEntityFormList } from '../../../common/pdf/form/PDFEntityFormList';
import { PDFDatePickerFormItem, PDFInputFormItem } from '../../../common/pdf/form/PDFInputFormItems';
import { StaysAbroadList } from './export.type';

export function StaysAbroadListFormPDF(props: { staysAbroadList: StaysAbroadList }) {
  if (!props.staysAbroadList) {
    return <PDFH1>Séjours à l'étranger</PDFH1>;
  }
  return (
    <Fragment>
      <PDFH1>Séjours à l'étranger</PDFH1>
      <PDFEntityForm
        entity="stays-abroad-list"
        value={props.staysAbroadList}
      >
        <PDFEntityFormList<IStayAbroad> propertyName="staysAbroad">
          {(value, index) => (
            <Fragment>
              <PDFCardGutter>
                <View style={{ width: '50%' }}>
                  <PDFInputFormItem
                    propertyName={['staysAbroad', index, 'country', 'id']}
                  />
                </View>
                <View style={{ width: '50%' }}>
                  <PDFInputFormItem
                    propertyName={['staysAbroad', index, 'reason', 'id']}
                  />
                </View>
              </PDFCardGutter>
              <PDFCardGutter>
                <View style={{ width: '50%' }}>
                  <PDFDatePickerFormItem
                    propertyName={['staysAbroad', index, 'beginDate']}
                  />
                </View>
                <View style={{ width: '50%' }}>
                  <PDFDatePickerFormItem
                    propertyName={['staysAbroad', index, 'endDate']}
                  />
                </View>
              </PDFCardGutter>
            </Fragment>
          )}
        </PDFEntityFormList>
      </PDFEntityForm>
    </Fragment>
  );
}
