import styled from '@react-pdf/styled-components';
import React, { ReactNode } from 'react';

const Container = styled.Text`
    font-size: 11px;
    font-weight: 800;
    color: dodgerblue;
    margin-bottom: 10px;
`;

export function PDFH1(props: { debug?: boolean, children?: ReactNode }) {
    return (
      <Container debug={props.debug}>
        {props.children}
      </Container>
    );
}
