/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { DocumentCategoryEnum, DocumentTypeEnum, IAdditionalDocument } from '@fstn/ecandidaturev2_api-interfaces';
import Card from 'antd/lib/card';
import { useDeepCompareCallback } from 'use-deep-compare';
import UUID from 'uuid-js';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import { UploadFormItemContainer } from '../../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import { EntityFormListContext } from '../../../context/entityFormList.context';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { ColdDataSelectorFormItem } from '../../../common/form/inputFormItems/ColdDataSelectorFormItem';

export function AdditionalDocumentForm() {
  const formListEntity = useScopedSelector<any[], string>('IAdditionalDocument', EntityFormListContext, (c) => c[0]?.entity);
  const field = useScopedSelector<any[], any>('IAdditionalDocument', EntityFormListContext, (c) => c[0].field);
  const formListEntityValue = useScopedSelector<any[], IAdditionalDocument>('AdditionalDocumentForm', EntityFormListContext, (c) => c[0]?.entityValue);

  const { t } = useSafeTranslation();

  const id = formListEntityValue?.id;
  const category = formListEntityValue?.category;
  const name = formListEntityValue?.name;

  function getDocumentTitle() {
    return `${t('additional.document.title')} ${formListEntityValue?.name || ''}`;
  }

  const addDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    type: DocumentTypeEnum.ADDITIONAL,
    // the user submitted name is stored in the AdditionnalDocument entity, a uuid as a name is a fallback to work around the factory only called as initialisation
    name: UUID.create(1).toString(),
    parentType: formListEntity,
    parentId: id,
  }), [formListEntity, id, name]);



  return (
    <Card
      title={getDocumentTitle()}
      key={id}
      css={css``}
    >
      <div>
        <div>
          {/* Category update is disabled when name or document are defined */}
          <DisabledContextProvider value={{
            disabled: formListEntityValue.mandatory
              || (formListEntityValue.name != null && formListEntityValue.name !== "")
              || formListEntityValue.document != null,
            wrapAuthorized: true,
            context: 'AdditionalDocumentForm',
          }}>
            <ColdDataSelectorFormItem
              api="document-category"
              propertyName={['additionalDocuments', field.name, 'category']}
            />
          </DisabledContextProvider>
        </div>
        {/* Name field is displayed if the category is OTHER */}
        {category?.id === DocumentCategoryEnum.OTHER && (
          <div>
            <InputFormItem
              propertyName={['additionalDocuments', field.name, 'name']}
            />
          </div>
        )}
        {/* File upload is displayed if the category is not OTHER or a name is defined */}
        {category && (category.id !== DocumentCategoryEnum.OTHER || name) && (
          <div>
            <UploadFormItemContainer
              propertyName={['additionalDocuments', field.name, 'document']}
              accept={'image/*,.pdf'}
              factory={addDocument}
            />
          </div>
        )}
      </div>
    </Card>
  );
}
