import { CheckCircleOutlined, IssuesCloseOutlined } from '@ant-design/icons/lib';

/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/core';
import Badge from 'antd/lib/badge';
import React, { Fragment, HTMLAttributes } from 'react';

export interface MenuItemValidationFlagProps extends HTMLAttributes<HTMLElement> {
    entity: string
    validations: any
    step: string;
}

/**
 * Validation flag for menu item
 * @param props
 * @constructor
 */
export function MenuItemValidationFlag(props:{ haveValidations: boolean, step }) {
        if (!props.haveValidations) {
            return (
              <Fragment>
                {/* classname is used to be able to mask it if SECRATRY_VALID button not pressed */}
                <Badge className={`validation-flag-valid ${props.step}`} style={{ width: 20, fontSize: '16px', color: '#54C41A' }} count={<CheckCircleOutlined />} />
              </Fragment>
);
        }
        return (
          <Fragment>
            <Badge
              style={{
 width: 20,
fontSize: '16px',
color: '#FAAD13',
fontWeight: 700,
}}
              count={<IssuesCloseOutlined />}
            />

            {/* </Popover> */}
          </Fragment>
        );
    }
