import { walkEntityPropertyAndMapDateStrAsMoment } from '@fstn/ecandidaturev2_api-interfaces';
import { omit } from 'lodash';
import React, { useEffect, Fragment } from 'react';

import { useImmer } from 'use-immer';
import { useEntity } from '../../hooks/use-entity';
import { useIsMounted } from '../../hooks/use-is-mounted';

/**
 * Permet de reload la value depuis les children
 * Par exemple est utilisé lors du delete d'un element de la liste
 */
export type WithInitialLoadContextType = {
    reload: () => Promise<void>
};
export const WithInitialLoadContext = React.createContext({} as WithInitialLoadContextType);

export function WithInitialLoad<T>(props: { entity: string, entityId?: any, children?: any, propsName?: string, forceReloadWhenThisValueChange?: string }) {
    //console.log('Redraw WithInitialLoad');
    const [state, updateState] = useImmer({ initialValues: undefined as T, loaded: false, isLoading: true });
    const { loadEntity, ready } = useEntity<T>();
    const isMounted = useIsMounted();

    const exist = !!state.initialValues;

    async function initialLoad() {
        if (isMounted) {
            if (!state.isLoading) {
                updateState((draft: any) => {
                    draft.isLoading = true;
                });
            }
            const data = await loadEntity(props?.entity, props.entityId);
            walkEntityPropertyAndMapDateStrAsMoment(data);
            if (isMounted) {
                updateState((draft: any) => {
                    draft.initialValues = data;
                    draft.loaded = true;
                    draft.isLoading = false;
                });
            }
        }
    }

    useEffect(() => {
        initialLoad().then(() => {
        });
        // eslint-disable-next-line
    }, [props.forceReloadWhenThisValueChange]);

    useEffect(() => {
        if (!exist && !state.isLoading && !state.loaded && (ready || !!props.entityId)) {
            initialLoad().then(() => {
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exist, loadEntity, props?.entity, props.entityId, state.isLoading, state.loaded, updateState, ready]);

    let childrenAsArray;
    if (Array.isArray(props.children)) {
        childrenAsArray = props.children;
    } else {
        childrenAsArray = [props.children];
    }

    const children = React.Children.map(childrenAsArray,
        (c) => {
            const extra = { [props.propsName || 'initialValues']: state.initialValues };
            const newProps = { ...c.props, ...omit(props, 'children'), ...extra };
            // console.log('Redraw WithInitialLoad', state.initialValues, newProps);
            return React.cloneElement(c, newProps);
        });
    return (
      <Fragment>
        <WithInitialLoadContext.Provider value={{ reload: async () => initialLoad() }}>
          {state.isLoading && <Fragment />}
          {!state.isLoading && children}
        </WithInitialLoadContext.Provider>
      </Fragment>
    );
}
