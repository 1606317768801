/** @jsx jsx */
import { css, jsx } from '@emotion/core';

export function CardSection(props:{ children:any }) {
return (
  <div css={css`padding: 1em`}>
    {props.children}
  </div>
);
}
