/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import * as Locale from '../../../../common/locale';
import {IsSecretary} from '../../../user/conditionnal/IsSecretary';
import {InputCommentsItemContainer} from '../../../user/inputCommentsItem/InputCommentsItemContainer';

/**
 * Comments Btw all types of Secretaries part of right container
 * @constructor
 */
export function CommentsBtwAllTypesOfSecretariesRightContainer() {
    return (
      <Fragment>
        <h1>
          <Locale.Title tkey="secretaryReview.secretary.commentsBtwAllTypesOfSecretaries" />
        </h1>
        <br />
        <IsSecretary elseChildren={(
          <InputCommentsItemContainer
            disabled
            type="commentsBtwAllTypesOfSecretaries"
          />
        )}
        >
          <InputCommentsItemContainer type="commentsBtwAllTypesOfSecretaries" />
        </IsSecretary>
      </Fragment>
);
}
