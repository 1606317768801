/** @jsx jsx */
import {jsx} from '@emotion/core';
import styled from '@emotion/styled';
import {DEFAULT} from '@fstn/ecandidaturev2_api-interfaces';
import React, {useCallback, useContext} from 'react';
import {Box} from 'rebass';
import {useImmer} from 'use-immer';
import {TextOkButton} from '../../../../common/button/TextOkButton';
import {EntityFormContainer} from '../../../../common/form/entityForm/EntityFormContainer';
import {FormContentPanelContainer} from '../../../../common/form/formContentPanel/FormContentPanelContainer';
import {ColdDataAutoCompleteFormItem} from '../../../../common/form/inputFormItems/ColdDataAutoCompleteFormItem';
import {FixSelectFormItem} from '../../../../common/form/inputFormItems/FixSelectFormItem';
import {InputFormItem} from '../../../../common/form/inputFormItems/InputFormItem';
import {AxiosContext, AxiosContextType} from '../../../../context/axios.context';
import {useResponsiveForm} from '../../../../hooks/use-responsive-form.hook';
import {sNavigateReload} from '../../../../utils/safeNavigation';
import {ADMIN} from '../../../user/UserRoutesDef';
import {ResultPanel} from '../../common/ResultPanel';
import {ButtonsFormItemLayout} from '../../../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import {DisabledContextProviderAlwaysEditableAdmin} from '../../../../common/rights/DisabledContextProviderAlwaysEditableAdmin';
// @ts-ignore

const Style = styled.div``;

/**
 *  LAURENT exemple de page d'édition
 * @param props
 * @constructor
 */
export function SpecializedSecretaryEditPage(props) {
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  const responsive = useResponsiveForm();

  const [state, updateState] = useImmer({
    captcha: false,
valid: false,
loading: false,
    error: undefined as string,
result: undefined as string,
  });

  const onFinish = async (values) => {
    try {
      updateState((draft) => {
        draft.loading = true;
      });
      await axios.post('/admin/specialized-secretary', values);
      updateState((draft) => {
        draft.result = 'Utilisateur créé';
        draft.error = undefined;
      });
      const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await sleep(2500);
      sNavigateReload(ADMIN.SPECIALIZED_SECRETARY_EDIT);
    } catch (ex) {
      updateState((draft) => {
        draft.error = ex.response.data.message;
        draft.result = undefined;
      });
    } finally {
      updateState((draft) => {
        draft.loading = false;
      });
    }
  };

  const mapper = useCallback((p) => ({
    id: p.id, name: `${p?.name}${p?.optionName ? ` / ${p?.optionName}` : ''}`,
  }), []);

  return (
    <Style>
      <EntityFormContainer
        entity="specialized-secretary"
        initialValues={{ ...props, rights: DEFAULT }}
        noSave
        layout="vertical"
        onFinish={onFinish}
      >
        <DisabledContextProviderAlwaysEditableAdmin>
          <FormContentPanelContainer partName="specialized-secretary">
            <FixSelectFormItem
              propertyName="gender"
              options={[
              { value: 'm', label: 'Masculin' },
              { value: 'f', label: 'Féminin' },
              { value: 'o', label: 'Autre' }]}
            />
            <InputFormItem
              propertyName="firstName"
              required
              rules={[{ required: true, message: 'Champ obligatoire !' }]}
            />
            <InputFormItem
              propertyName="lastName"
              required
              rules={[{ required: true, message: 'Champ obligatoire !' }]}
            />
            <InputFormItem
              propertyName="email"
              autoComplete="email"
              required
              rules={[{ required: true, message: 'Champ obligatoire !' },
            { type: 'email' }]}
            />
            <Box {...responsive.w1}>
              <ColdDataAutoCompleteFormItem
              // disabled={false}
                api="programs"
                propertyName="program"
                mapper={mapper}
                required
                rules={[{ required: true, message: 'Champ obligatoire !' }]}
              />
            </Box>
            <ButtonsFormItemLayout>
              <SubmitButton />
            </ButtonsFormItemLayout>
          </FormContentPanelContainer>
        </DisabledContextProviderAlwaysEditableAdmin>
      </EntityFormContainer>
      <ResultPanel error={state.error} result={state.result} />
    </Style>
  );
}

function SubmitButton() {
  return (
    <TextOkButton
      tkey="specialized-secretary.add"
      htmlType="submit"
    />
  );
}
