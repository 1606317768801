/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { ValidationStep } from '@fstn/ecandidaturev2_api-interfaces';
import {
 Checkbox, Form, Modal, Switch,
} from 'antd';
import Alert from 'antd/lib/alert';
import React, { Fragment, ReactElement } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';

import * as Locale from '../locale';
import { ValidationsContext } from '../../context/validations.context';
import { useSafeTranslation } from '../../hooks/use-safe-translation';
import { ROUTES } from '../../pages/user/UserRoutesDef';
import { sNavigate } from '../../utils/safeNavigation';
import { useScopedSelector } from '../../hooks/use-scoped-selector';
import { SubmitCandidateFileForm } from '../../pages/candidate/SubmitCandidateFileForm';
import { StrictLoading } from '../indicator/SmartLoading';
import { IsDesktop } from '../../pages/user/conditionnal/IsDesktop';
import { IsMobile } from '../../pages/user/conditionnal/IsMobile';

function ConfirmCheck(props: { onChange: (value) => void, confirmed: boolean }) {
    return (
      <div
        css={css`.ant-form-item .ant-form-item-label > label{height: fit-content}`}
      >
        <IsDesktop>
          <Form.Item
            labelCol={{ span: 22 }}
            wrapperCol={{ span: 2 }}
            label={<Locale.Label tkey="submit.confirmed" />}
            initialValue={props.confirmed}
          >
            <Checkbox
              onChange={props.onChange}
              data-testid="submit.confirmed"
              checked={props.confirmed}
            />
          </Form.Item>
        </IsDesktop>
        <IsMobile>
          <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label={<Locale.Label tkey="submit.confirmed" />}
            initialValue={props.confirmed}
          >
            <Switch
              style={{ zoom: '2' }}
              onChange={props.onChange}
              data-testid="submit.confirmed"
              checked={props.confirmed}
            />
          </Form.Item>
        </IsMobile>

      </div>
);
}

/**
 * Display a modal if candidate file is not ok,
 * It's detected by using if there is any validation message to display,
 * validations messages are loaded by the left menu component and added to redux
 * @param props
 * @constructor
 */
export function WithCandidateFileOk(props: { children: ReactElement }) {
    const { t } = useSafeTranslation();

    const { validations } = useScopedSelector('WithCandidateFileOk', ValidationsContext, (validationsCtx) => validationsCtx.validationsCtxState[ValidationStep.SUBMIT]);
    const isReady = useScopedSelector('WithCandidateFileOk', ValidationsContext, (validationsCtx) => validationsCtx.loaded);

    const [state, updateSate] = useImmer({
        loaded: false,
        validationsMessages: undefined,
        displayChildren: false,
        confirmed: false,
    });

    useDeepCompareEffect(() => {
        updateSate((draft) => {
            if (isReady) {
                draft.validationsMessages = Object.keys(validations)
                    .filter((entity) => entity !== 'candidate-file')
                    .filter((entity) => (validations[entity] && Object.keys(validations[entity]).length > 0))
                    .map((entity) => t(`submit.validations.error.${entity}`)) || [];
                draft.loaded = true;
            }
        });
    }, [validations, isReady]);

    if (!isReady || !state.loaded || !validations) {
        return <StrictLoading loading><Fragment /></StrictLoading>;
    }

    if (state.displayChildren) {
        return props.children;
    }

    if (state.validationsMessages?.length === 0) {
        return (
          <StrictLoading loading={!isReady || !state.loaded || !validations}>
            <Modal
              width="800vw"
              okText={<Locale.Button tkey="submit.confirm.continue" />}
              okButtonProps={{ disabled: !state.confirmed, 'data-testid': 'submit.confirm.continue' } as any}
              cancelButtonProps={{ disabled: state.confirmed, 'data-testid': 'submit.confirm.cancel' } as any}
              title={<Locale.Notification tkey="validCandidateFile" />}
              visible
              onCancel={() => updateSate(((draft) => {
                        sNavigate(`${ROUTES.CANDIDATE_BASE}/home`);
                    }))}
              onOk={() => {
                        updateSate(((draft) => {
                            draft.displayChildren = true;
                        }));
                    }}
            >
              <br />
              <IsDesktop>
                <div css={css`height:500px`}>
                  <SubmitCandidateFileForm />
                </div>
              </IsDesktop>
              <ConfirmCheck
                onChange={(value) => {
                        updateSate((draft) => {
                            draft.confirmed = value;
                        });
                    }}
                confirmed={state.confirmed}
              />
            </Modal>
            {props.children}
          </StrictLoading>
        );
    }
    return (
      <Modal
        width="800vw"
        okButtonProps={{ 'data-testid': 'validations-ok', disabled: !state.confirmed } as any}
        okText={<Locale.Button tkey="submit.continue" />}
        cancelText={<Locale.Button tkey="submit.completeCandidateFile" />}
        cancelButtonProps={{ 'data-testid': 'validations-cancel' } as any}
        title={<Locale.Notification tkey="invalidCandidateFile" />}
        visible
        onCancel={() => updateSate(((draft) => {
              sNavigate(`${ROUTES.CANDIDATE_BASE}/home`);
          }))}
        onOk={() => {
              updateSate(((draft) => {
                  draft.displayChildren = true;
              }));
          }}
      >
        <div css={css`display: flex; gap: 1em;`}>
          <div css={css`flex:1`}>
            <Locale.Notification tkey="invalidCandidateFile.description" />
            <div>
              {
                      // eslint-disable-next-line react/no-array-index-key
                      state.validationsMessages.map((msg, i) => <Alert key={i} message={msg} banner />)
                  }
            </div>

            <br />
            <ConfirmCheck
              onChange={(value) => {
                  updateSate((draft) => {
                      draft.confirmed = value;
                  });
              }}
              confirmed={state.confirmed}
            />
          </div>

          <IsDesktop>
            <div css={css`height:700px;flex:1;flex-basis: 500px`}>
              <SubmitCandidateFileForm />
            </div>
          </IsDesktop>
        </div>
      </Modal>
);
}
