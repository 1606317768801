import React, { Fragment } from 'react';
import { RgpdPageSwitcher } from './rgpd/RgpdPageSwitcher';
import { AppConfigPageSwitcherContainer } from './switcher/AppConfigPageSwitcherContainer';
import { ExtraPaymentPageSwitcher } from './switcher/ExtraPaymentSwitcher';
import { RegisterPageSwitcher } from './switcher/RegisterPageSwitcher';
import { RightAreaContextProvider } from '../canSeeCandidateFile/RightAreaContext';

/**
 *  LAURENT
 *  Root élément of a logged user page
 *  It provide a way to redirect user to other content if the user is not logged: register or didn't accept RGPD
 */
export function UserPage({ children }: any) {
  // console.log('Redraw UserPage');
  return (
    <Fragment>
      <RightAreaContextProvider>
        <RgpdPageSwitcher>
          <RegisterPageSwitcher>
            <ExtraPaymentPageSwitcher>
              <AppConfigPageSwitcherContainer>
                {children}
              </AppConfigPageSwitcherContainer>
            </ExtraPaymentPageSwitcher>
          </RegisterPageSwitcher>
        </RgpdPageSwitcher>
      </RightAreaContextProvider>
    </Fragment>
  );
}
