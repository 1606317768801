/** @jsx jsx */
import {jsx} from '@emotion/core';
import {UserRoleEnum} from '@fstn/ecandidaturev2_api-interfaces';
import {Fragment, useContext} from 'react';
import {UserContext} from '../../../context/user.context';

export function IsSecretaryOrCandidate(props: { children: any, elseChildren?: any }) {
    const { userCtx } = useContext(UserContext);
    return (
      <Fragment>
        {(userCtx.user?.role?.name === UserRoleEnum.SECRETARY || userCtx.user?.role?.name === UserRoleEnum.CANDIDATE) && props.children}
        {(userCtx.user?.role?.name !== UserRoleEnum.SECRETARY && userCtx.user?.role?.name !== UserRoleEnum.CANDIDATE) && props.elseChildren}
      </Fragment>
    );
}
