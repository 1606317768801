import { ExclamationCircleOutlined, FormOutlined } from '@ant-design/icons';
import Menu from '@inovua/reactdatagrid-community/packages/Menu';
import {
 Form, Modal, notification, Statistic,
} from 'antd';

// @ts-ignore
import React, { useContext } from 'react';
import { ResponsiveInput } from '../../../../common/input/ResponsiveInput';
import { AxiosContext, AxiosContextType } from '../../../../context/axios.context';
import { sNavigateReload } from '../../../../utils/safeNavigation';
import { ADMIN } from '../../../user/UserRoutesDef';

const { confirm } = Modal;

/**
 * Composant de gestion du menu contextuel dans la grid
 */
export function ReferentReviewContextMenu({
    menuProps, details, selectedUsers, gridRef,
}: any) {
    const { axios } = useContext<AxiosContextType>(AxiosContext);

    const items = [];

    /**
     * Fonction appelée quand on demande la reaffectation des elements selectionnes
     * Affiche le nombre d'elements reaffectes en message d'info puis redirige sur la page pour raffraichir la liste
     */
    const onReaffectSelection = async (form) => {
        if (selectedUsers.length > 0) {
            const listIds = selectedUsers.map((cf) => cf.id);
            try {
                const params = form;
                params.listIds = listIds;
                const response: any = await axios.post('admin/academic-referent-review/reaffectSelection', params);
                notification.info({
                    message: `${response.data.updated} éléments réaffectés sur ${response.data.total} sélectionnées !`,
                    duration: 0,
                });
                const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
                await sleep(2000);
                sNavigateReload(ADMIN.REFERENT_REVIEW_LIST);
            } catch (e) {
                notification.error({
                    message: 'Erreur de reaffectation',
                    description: e.message,
                    duration: 0,
                });
            }
        }
    };

    /* On ajoute ici dans le menu contextuel le menu permettant de reaffecter la selection */
    /* TODO : voir comment ne pas fermer la modale si formulaire mal renseigne */
    const [form] = Form.useForm();
    if (selectedUsers.length > 0) {
        items.push({
            label: 'Affecter à un autre référent ...',
            icon: <FormOutlined />,
            onClick: (value) => {
                confirm({
                    // @ts-ignore
                    okButtonProps: { 'data-testid': 'referent-publish-results-ok' },
                    // @ts-ignore
                    cancelButtonProps: { 'data-testid': 'referent-publish-results-cancel' },
                    title: 'Réaffectation des demandes de recommandation',
                    icon: <ExclamationCircleOutlined />,
                    onOk: () => {
                        form
                            .validateFields()
                            .then((values) => {
                                onReaffectSelection(values);
                            });
                    },
                    content: (
                      <div>
                        <Statistic
                          title="Nombre d'éléments selectionnés"
                          value={selectedUsers.length}
                        />
                        <Form
                          form={form}
                          layout="vertical"
                          name="form_in_modal"
                          initialValues={{
                                    modifier: 'public',
                                }}
                        >
                          <Form.Item
                            name="newEmail"
                            label="Nouvel email référent"
                            rules={[
                                        {
                                            required: true,
                                            message: 'Email obligatoire !',
                                        }, { type: 'email' },
                                    ]}
                          >
                            <ResponsiveInput data-testid="referent-email" type="email" />
                          </Form.Item>
                        </Form>
                        Voulez-vous vraiment réaffecter ces demandes ?
                      </div>
                    ),
                });
            },
        });
    }

    return (
      <Menu
        {...menuProps}
        items={items}
      />
    );
}
