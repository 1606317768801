/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { DocumentUtils, IDocument } from '@fstn/ecandidaturev2_api-interfaces';
import React, {
    useContext, useRef, useState,
} from 'react';
import { useImmer } from 'use-immer';
import { notification } from 'antd';
import { RcFile } from 'antd/lib/upload';
import styled from '@emotion/styled';
import UUID from 'uuid-js';
import { useDeepCompareEffect, useDeepCompareMemo } from 'use-deep-compare';

import { UserContext, UserContextType } from '../../../../context/user.context';
import { RightAreaContext, RightAreaContextProviderType } from '../../../../pages/canSeeCandidateFile/RightAreaContext';
import { AxiosContext, AxiosContextType } from '../../../../context/axios.context';
import {
    CandidateFileContext,
    CandidateFileContextProviderType,
} from '../../../../pages/canSeeCandidateFile/CandidateFileContext';
import { UploadWithContent } from './UploadWithContent';
import { useEntity } from '../../../../hooks/use-entity';
import { DisabledContext } from '../../../rights/DisabledContext';
import { useScopedSelector } from '../../../../hooks/use-scoped-selector';
import { InlineLoading } from '../../../indicator/SmartLoading';
import _ from 'lodash';

export
    function UploadFormItemUploadContainer<T>(props: {
        value?: IDocument,
        accept: string,
        propertyName: string | any[],
        onBeforeUpload?: (imageContext: { width: number; height: number }) => Promise<boolean>,
        onAfterChange?: any,
        onChange?: (d: Partial<IDocument>) => void,
        factory: () => IDocument
    }) {
    const userContext = useContext<UserContextType>(UserContext);
    const rightCtx = useContext<RightAreaContextProviderType>(RightAreaContext);
    const oldProps = useRef({});
    const { axios, token } = useContext<AxiosContextType>(AxiosContext);

    const disabled = useScopedSelector('UploadFormItemUploadContainer', DisabledContext, (c) => c?.disabled);

    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    const { deleteEntity } = useEntity();
    const document = useRef(props.value);

    const [state, updateState] = useImmer({
        hash: 0, createEmptyDocumentExecuted: false,
    });

    const [loading, setLoading] = useState(false);
    const newProps = [props];

    // console.log('Redraw UploadFormItemUploadContainer ', oldProps.current, newProps);
    oldProps.current = newProps;

    useDeepCompareEffect(() => {
        if (props.value) {
            document.current = props.value;
        }
    }, [props.value]);

    const deleteUploadedDocument = async () => {
        const resp = await deleteEntity('document', { id: props?.value?.id });
        if (resp.status === 200) {
            // await deleteEntity('document', document.current);
            document.current = null;
            props?.onChange?.(null);
            updateState((draft) => {
                draft.hash = new Date().getTime();
                draft.createEmptyDocumentExecuted = false;
            });
            rightCtx?.forceUpdate?.();
        }
    };

    function onChange(e: any) {
        const { file } = e;
        if (file.status === 'uploading') {
            // notification.info({message: `${file.name} file uploading.`});
        } else if (file.status === 'error') {
            setLoading(false);
            notification.warn({ message: `${file.name} error: ${file.response?.message}` });
        } else if (file.status === 'done' || file.status === 'success') {
            notification.success({ message: `${file.name} file uploaded.` });
            props?.onChange?.(file.response);
            props?.onAfterChange?.(file.response);
            updateState((draft) => {
                draft.hash = new Date().getTime();
            });
            rightCtx?.forceUpdate?.();
            setLoading(false);
        }
    }

    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    async function loadImage(image: HTMLImageElement) {
        return new Promise<GlobalEventHandlers>((resolve, reject) => {
            const reader = new FileReader();
            image.onload = function onload() {
                resolve(this);
            };
            reader.onerror = reject;
        });
    }

    async function onBeforeUpload(file: RcFile, FileList: RcFile[]): Promise<any> {
        setLoading(true);
        const newDocumentValueIsNull = !props?.value?.id || !props?.value?.candidateFile;
        if (!state.createEmptyDocumentExecuted && newDocumentValueIsNull) {
            /**
             * If onBeforeUpload failed(ex: file is too big, antd will set the property to null,
             * so to not recreate another document on the entity we should replace the existing one into the form
             */

            /** TODO FOR RELOAD
            formContext.entityValue[`${props.propertyName}`]
            if (props.previousDocumentValue) {
                props?.onChange?.(props.previousDocumentValue);
                return;
            }

            await saveEntity('document', document.current);

            /* updateState(((draft) => {
                draft.createEmptyDocumentExecuted = true;
            }));

            props?.onChange?.(newDocument); */
        }
        if (props.onBeforeUpload) {
            let imageContext: any;
            if (file.type === 'image/png' || file.type === 'image/jpeg') {
                const loadedFile: any = await readFileAsync(file);
                const image = new Image();
                image.src = loadedFile;
                imageContext = await loadImage(image);
                // eslint-disable-next-line consistent-return
                const isValid = await props.onBeforeUpload?.(imageContext);
                if (!isValid) {
                    setLoading(false);
                }
                return isValid;
            }
        }
        // eslint-disable-next-line consistent-return
        return true;
    }
    return useDeepCompareMemo(() => {
        if (document.current && !document.current.candidateFile) {
            let temp = _.cloneDeep(document.current);
            temp.candidateFile = candidateFileContext?.selectedCandidateFile || userContext?.userCtx?.user?.candidateFile;
            document.current = temp;
        }

        if (!document.current || !document.current.candidateFile) {
            document.current = createEmptyDocument<T>(
                props.factory,
                candidateFileContext?.selectedCandidateFile?.id || userContext?.userCtx?.user?.candidateFile?.id,
            );
        }
        const uploadLink = document.current && DocumentUtils.getUploadLink(document.current, token);
        return (
            <Style>
                <InlineLoading loading={loading} context="UploadFormItemUploadContainer" />
                {/* Upload component should stay here during the upload processus, so we can just hide it in css During this time */}
                <div css={css`display:${loading ? 'none' : 'auto'}`}>
                    <UploadWithContent
                        axios={axios}
                        disabled={disabled}
                        accept={props.accept}
                        onBeforeUpload={onBeforeUpload}
                        deleteUploadedDocument={deleteUploadedDocument}
                        onChange={onChange}
                        propertyName={props.propertyName}
                        token={token}
                        document={document.current}
                        uploadLink={uploadLink}
                    />
                </div>
            </Style>
        );
    }, [document.current, loading]);
}

const Style = styled.div`
    .ant-upload.ant-upload-btn{
      padding: 0 !important;
    }
`;

// eslint-disable-next-line
function createEmptyDocument<T>(factory: Function, candidateFileId: string) {
    const newDocument = factory() || {};
    newDocument.id = newDocument.id || UUID.create(1).toString();
    if (!candidateFileId) {
        throw new Error('Candidate file is missing, please be sure that the component is wrap into CandidateFileContextProvider and that candidateFile was selected');
    }
    newDocument.candidateFile = { id: candidateFileId };
    /* if (candidateFileContext?.selectedCandidateFile
         && (getDisabledForWriteAllContexts(userContext, formContext, formListContext))) {
         notification.info({
             message: (
               <Fragment>
                 {newDocument.name}
                 :
                 {' '}
                 <Locale.Notification tkey="needToSwitchIntoEdit.title" />
               </Fragment>),
             description: <Locale.Notification tkey="needToSwitchIntoEdit.description" />,
         });
         const rights = (formListEntityValue as any)?.rights;
         await formContext.onImmediateChange({ rights: RightsUtils.toEdit(rights, userContext.userCtx?.user?.role?.name) });
         // await loadRights()
         return newDocument;
     } */

    return newDocument;
}
