/** @jsx jsx */
// noinspection ES6UnusedImports
import React from 'react';
import { jsx } from '@emotion/core';
import { ButtonProps } from 'antd-button-color';
import * as Locale from '../locale';
import { TextButtonWithoutTranslation } from './TextButtonWithoutTranslation';

/**
 * Simple Button with text
 * @param tkey
 * @param props
 * @constructor
 */
export function TextButton({ tkey, ...props }: ButtonProps & { tkey: string }) {
    return (
        <TextButtonWithoutTranslation data-testid={tkey} {...props}>
            <Locale.Button tkey={tkey} />
        </TextButtonWithoutTranslation>
    )
}
