import { useRoutes } from 'hookrouter';
import React, {
  Fragment, useMemo, useRef,
} from 'react';
import { SecretaryChild1RoutesDef } from './SecretaryChild1RoutesDef';
import { useIsRootPath } from '../../../hooks/use-is-root-path.hook';
import { useIsPdfPath } from '../../../hooks/use-is-pdf-path.hook';

export function SecretaryChild1Container() {
  const routesChildren = useRoutes(SecretaryChild1RoutesDef());
  const { isRoot } = useIsRootPath();
  const { isPdf } = useIsPdfPath();

  const oldProps = useRef({});
  return useMemo(() => {
    // console.log('Redraw SecretaryChild1Container', oldProps.current, [routesChildren, isRoot]);
    oldProps.current = [routesChildren, isRoot];
    return (
      <Fragment>
        {routesChildren}
      </Fragment>
    );
  },
    // eslint-disable-next-line
    [isRoot, routesChildren?.props?.children?.props?.entityId, isPdf]);
}
