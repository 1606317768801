import {
    AppConfigDto,
    IPayment,
    IPaymentsList,
    walkEntityPropertyAndMapDateStrAsMoment,
} from '@fstn/ecandidaturev2_api-interfaces';
import {Card, Form} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import React, {Fragment, useCallback, useEffect} from 'react';
import {useImmer} from 'use-immer';
import {TextOkButton} from '../../../common/button/TextOkButton';
import {FormContentPanelContainer} from '../../../common/form/formContentPanel/FormContentPanelContainer';
import {InputNumberFormItem} from '../../../common/form/inputFormItems/InputNumberFormItem';
import {InputTextAreaFormItem} from '../../../common/form/inputFormItems/InputTextAreaFormItem';
import * as Locale from '../../../common/locale';
import {EntityFormContextProvider} from '../../../context/entityForm.context';
import {EntityFormListContextProvider} from '../../../context/entityFormList.context';
import {useEntity} from '../../../hooks/use-entity';
import {PaymentCard} from './PaymentCard';
import {PaymentHistory} from './PaymentHistory';
import {DisabledContextProvider} from '../../../common/rights/DisabledContext';
import {ButtonsFormItemLayout} from '../../../common/form/buttonsFormItem/ButtonsFormItemLayout';

export function CreateExtraPaymentForm() {
    const [form] = useForm();
    const [state, updateState] = useImmer({
        captcha: false,
        valid: false,
        loading: false,
        extraPayments: undefined as IPayment[],
    });

    const { loadEntity, saveEntity } = useEntity<AppConfigDto>();

    const load = useCallback(async () => {
        const paymentsList: IPaymentsList = await loadEntity('payments-list');
        walkEntityPropertyAndMapDateStrAsMoment(paymentsList);
        updateState((draft) => {
            draft.extraPayments = paymentsList.payments;
        });
    }, [loadEntity, updateState]);
    // loop add

    useEffect(() => {
        load().then();
    }, [load]);

    const onFinish = async (values) => {
        try {
            const payment = { ...values };
            await saveEntity('extra-payment', payment);
            updateState((draft) => {
                draft.loading = true;
            });
            window.location.reload();
        } finally {
            updateState((draft) => {
                draft.loading = false;
            });
        }
    };

    return (
      <Fragment>

        <DisabledContextProvider value={{
 disabled: false,
wrapAuthorized: true,
            context: 'CreateExtraPaymentForm',
}}
        >
          <Form
            layout="vertical"
            form={form}
            className="extra-payment-form"
            onFinish={onFinish}
            noValidate
          >
            <EntityFormContextProvider
              entity="payment"
              isFieldTouched={form.isFieldTouched}
              getFieldValue={form.getFieldValue}
              getFieldsValue={form.getFieldsValue}
              onChange={() => {
                                       }}
              onImmediateChange={() => {
                                       }}
            >
              <EntityFormListContextProvider
                entity="payment"
                propertyName=""
                remove={() => {
                }}
              >

                <FormContentPanelContainer partName="payment">
                  <Card title={<Locale.Title tkey="extra-payment.history" />}>
                    <PaymentHistory />
                  </Card>

                  <Card title={<Locale.Title tkey="extra-payment.card" />}>
                    <PaymentCard />
                  </Card>

                  {state.extraPayments?.filter((p) => !p.received && p.extra)?.length < 2
                        && (
                        <Card title={<Locale.Title tkey="extra-payment.add" />}>
                          <InputTextAreaFormItem
                            propertyName="extraReason"
                            required
                            rules={[{ required: true }]}
                          />
                          <InputNumberFormItem
                            propertyName="amount"
                            required
                            rules={[{ required: true }]}
                          />
                          <br />
                          <ButtonsFormItemLayout>
                            <TextOkButton
                              tkey="payment.add.extra"
                              htmlType="submit"
                            />
                          </ButtonsFormItemLayout>
                        </Card>
)}
                </FormContentPanelContainer>
              </EntityFormListContextProvider>
            </EntityFormContextProvider>
          </Form>
        </DisabledContextProvider>
      </Fragment>
);
}
