import { ICompanyExperiencesList } from '@fstn/ecandidaturev2_api-interfaces';
import { FormLayout } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import React, { Fragment, HTMLAttributes } from 'react';
import { Box, Flex } from 'rebass';
import { DeepPartial } from 'redux';
import UUID from 'uuid-js';
import { EntityFormContainer } from '../../../common/form/entityForm/EntityFormContainer';
import { EntityFormListContainer } from '../../../common/form/entityForm/EntityFormListContainer';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { FormStyle } from '../../../common/form/FormStyle';
import { SecretaryReviewFormFooterContainer } from '../../../common/form/secretaryReviewForm/SecretaryReviewFormFooterContainer';
import { WithSwitchToReadOnlyOnDisplayForSecretary } from '../../../common/withExtraParameters/WithSwitchToReadOnlyOnDisplayForSecretary';
import { WithInitialLoad } from '../../../common/withExtraParameters/WithInitialLoad';
import { YesNoFormItem } from '../../../common/form/inputFormItems/YesNoFormItems';
import { EntityFormListContext } from '../../../context/entityFormList.context';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { IsSecretary } from '../../user/conditionnal/IsSecretary';
import { CompanyExperienceForm } from './CompanyExperienceForm';
import { CompanyReferentReviewsListForm } from './CompanyReferentReviewsListForm';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { DisabledContextProviderForEntityForm } from '../../../common/rights/DisabledContextProviderForEntityForm';

const entity = 'company-experiences-list';

export type CompanyExperiencesListProps = HTMLAttributes<HTMLElement>;

function CompanyExperiencesListEntityForm() {
const formListEntityValue = useScopedSelector<any[], ICompanyExperiencesList>('CompanyExperiencesListEntityForm', EntityFormListContext, (c) => c[0]?.entityValue);

    const responsive = useResponsiveForm();

    return (
      <Flex flexDirection="column">
        <Box {...responsive.w1}>
          <FormContentPanelContainer partName={entity}>
            <YesNoFormItem labelCol={{ span: 18 }} wrapperCol={{ span: 4 }} propertyName="haveExperience" />
          </FormContentPanelContainer>
          {formListEntityValue.haveExperience
                && (
                <Fragment>
                  <section id="experiences">
                    <EntityFormListContainer propertyName="companyExperiences" entity="companyExperience">
                      <CompanyExperienceForm />
                    </EntityFormListContainer>
                  </section>
                  <section id="reviews">
                    <CompanyReferentReviewsListForm />
                  </section>
                </Fragment>
)}
        </Box>
      </Flex>
    );
}

export function CompanyExperiencesListForm(props:{ layout?: FormLayout }) {
    const onBeforeValidate = (values: Store, differences: DeepPartial<ICompanyExperiencesList>, setFieldsValue) => {
        if (differences.haveExperience === false) {
            const companyExperiences = [];
            // should force to null to delete the stay and the related document, if not set to null, object is still present in database
           if (values.companyExperiences) {
               // eslint-disable-next-line @typescript-eslint/no-unused-vars
               if (Array.isArray(values.companyExperiences)) {
                   // eslint-disable-next-line @typescript-eslint/no-unused-vars
                   for (const companyExperience of values.companyExperiences) {
                       companyExperiences.push(null);
                   }
               }
           }
            differences.companyExperiences = companyExperiences;
            setFieldsValue({ values, companyExperiences });
            const companyReferentReviews = [];
            // should force to null to delete the stay and the related document, if not set to null, object is still present in database
            if (values.companyReferentReviews) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                if (Array.isArray(values.companyReferentReviews)) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    for (const companyReferentReview of values.companyReferentReviews) {
                        companyReferentReviews.push(null);
                    }
                }
            }
            differences.companyReferentReviews = companyReferentReviews;
            setFieldsValue({ values, companyReferentReviews });
        } else if ((!values?.companyExperiences || values?.companyExperiences.length === 0) && !differences?.companyExperiences) {
                const companyExperiences = [{ id: UUID.create(1).toString() }];
                differences.companyExperiences = companyExperiences;
                setFieldsValue({ values, companyExperiences });
            }
        return differences;
    };

    return (
      <FormStyle>
        <WithInitialLoad entity={entity}>
          <WithSwitchToReadOnlyOnDisplayForSecretary>
            <EntityFormContainer listeners={{ onBeforeValidate }} layout={props.layout}>
              <DisabledContextProviderForEntityForm>
                <CompanyExperiencesListEntityForm />
                <IsSecretary>
                  <SecretaryReviewFormFooterContainer propertyName="secretaryReview" />
                </IsSecretary>
              </DisabledContextProviderForEntityForm>
            </EntityFormContainer>
          </WithSwitchToReadOnlyOnDisplayForSecretary>
        </WithInitialLoad>
      </FormStyle>
    );
}
