import {UserOutlined} from '@ant-design/icons';
import {IComments, UserRoleEnum} from '@fstn/ecandidaturev2_api-interfaces';
import {Avatar} from 'antd';
import moment from 'moment';
import React from 'react';

export function getAvatar(role) {
    if (role === UserRoleEnum.SECRETARY) {
        return <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />;
    }
    return role === UserRoleEnum.SECRETARY
        ? <Avatar style={{ backgroundColor: '#f56a00' }} icon={<UserOutlined />} />
        : <Avatar style={{ backgroundColor: '#ccc' }} icon={<UserOutlined />} />;
}

export const formatComments = (comments) => (
    comments?.map?.((comment: IComments) => ({
        author: comment.author,
        avatar: getAvatar(comment.role),
        content: <p>{comment.content}</p>,
        datetime: moment(comment.datetime).fromNow(),
        pureDatetime: comment.datetime,
    }))
);

export function getButtonType(type): 'primary' | 'link' | 'success' | 'warning' | 'info' | 'danger' {
    switch (type) {
        case 'commentsBtwSecretaryAndCandidate':
            return 'primary';
        case 'commentsBtwSecretaries':
            return 'warning';
        case 'commentsBtwAllTypesOfSecretaries':
            return 'danger';
    }
    return undefined;
}
