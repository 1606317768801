import { DocumentUtils, IDocument } from '@fstn/ecandidaturev2_api-interfaces';
import { AxiosInstance } from 'axios';
import FileDownload from 'js-file-download';

export async function documentDownload(axios: AxiosInstance, token: string, document: IDocument) {
    const downloadLink = DocumentUtils.getLink(document, token);
    const response = await axios.get(downloadLink, {
        responseType: 'blob',
    });
    if (response && response.data) {
        await FileDownload(response.data, document?.originalName);
    }
}
