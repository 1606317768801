import {ValidationStep} from '@fstn/ecandidaturev2_api-interfaces';
import {usePath, useRoutes} from 'hookrouter';
import React, {Fragment, useContext, useEffect} from 'react';
import {useImmer} from 'use-immer';
import {UserContext, UserContextType} from '../../context/user.context';
import {ValidationsContextProvider} from '../../context/validations.context';
import {ForgotPasswordCodeForm} from './forgotPassword/ForgotPasswordCodeForm';
import {ForgotPasswordEmailForm} from './forgotPassword/ForgotPasswordEmailForm';
import {ForgotPasswordForm} from './forgotPassword/ForgotPasswordForm';
import {useUser} from '../../hooks/use-user.hook';
import {RegisterEmailForm} from './register/RegisterEmailForm';
import {RegisterForm} from './register/RegisterForm';
import {RegisterCodeForm} from './register/ValidateCodeForm';
import {LOGIN, ROUTES} from '../user/UserRoutesDef';
import {BeforeLoginPage} from './BeforeLoginPage';
import {CenterForm} from './CenterForm';
import {LoginPage} from './LoginPage';
import {LogoutPage} from './LogoutPage';

export const LoginRoutesDef = (t, unloadMe) => ({
    '/home': async () => {
        await unloadMe();
        setTimeout(() => {
            window.location.href = '/';
        }, 500);
        return <Fragment />;
    },
    [ROUTES.BASE]: () => <LoginPage />,
    [LOGIN.LOGIN]: () => <LoginPage />,
    [LOGIN.LOGOUT]: () => <LogoutPage />,
    [`${ROUTES.PUBLIC_BASE}*`]: () => <LoginRouteContent />,
    '/': () => <LoginPage />,
});

export function LoginRouteContent() {
    const userCtx = useContext<UserContextType>(UserContext);
    const { unloadMe } = useUser(userCtx);
    const routesDef = loginRouteDefContent(unloadMe);
    const routes = useRoutes(routesDef as any);
    const path = usePath();
    const [, updateState] = useImmer({ loaded: false, routes: undefined as any, path: '' });
    useEffect(() => {
        updateState((draft) => {
            draft.path = path;
        });
    }, [updateState, path]);

    return (
      <Fragment>{routes}</Fragment>
    );
}

function loginRouteDefContent(unloadMe) {
    return {
        '/register-email': () => <BeforeLoginPage><CenterForm><RegisterEmailForm /></CenterForm></BeforeLoginPage>,
        '/register-code/:email': ({ email }: any) => (
          <BeforeLoginPage>
            <CenterForm>
              <RegisterCodeForm
                email={email}
              />
            </CenterForm>
          </BeforeLoginPage>
        ),
        '/register/:email/:code/:firstName/:lastName': ({
                                                         email, code, firstName, lastName,
                                                     }: any) => (
                                                       <BeforeLoginPage>
                                                         <CenterForm>
                                                           <ValidationsContextProvider steps={['REGISTER']} entity="register">
                                                             <RegisterForm
                                                               email={email}
                                                               firstName={firstName}
                                                               lastName={lastName}
                                                               code={code}
                                                             />
                                                           </ValidationsContextProvider>
                                                         </CenterForm>
                                                       </BeforeLoginPage>
        ),
        '/register/:email/:code': ({ email, code }: any) => (
          <BeforeLoginPage>
            <CenterForm>
              <ValidationsContextProvider steps={[ValidationStep.REGISTER]} entity="candidate">
                <RegisterForm
                  email={email}
                  firstName=""
                  lastName=""
                  code={code}
                />
              </ValidationsContextProvider>
            </CenterForm>
          </BeforeLoginPage>
        ),
        '/forgot-password-email': () => <BeforeLoginPage><CenterForm><ForgotPasswordEmailForm /></CenterForm></BeforeLoginPage>,
        '/forgot-password-code/:email': ({ email }: any) => (
          <BeforeLoginPage>
            <CenterForm>
              <ForgotPasswordCodeForm
                email={email}
              />
            </CenterForm>
          </BeforeLoginPage>
        ),
        '/forgot-password/:email/:code': ({ email, code }: any) => (
          <BeforeLoginPage>
            <CenterForm>
              <ForgotPasswordForm
                email={email}
                code={code}
              />
            </CenterForm>
          </BeforeLoginPage>
        ),
    };
}
