import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import React, { MutableRefObject } from 'react';
import moment from 'moment';
import { ProgramManagerRowContextMenu } from './ProgramManagerRowContextMenu';
import 'moment/locale/fr';
import { avatarColumn } from '../canSeeCandidateFile/candidateFilesList/columns/AvatarColumn';
import { firstNameColumn, firstNameFilter } from '../canSeeCandidateFile/candidateFilesList/columns/FirstNameColumn';
import { lastNameColumn, lastNameFilter } from '../canSeeCandidateFile/candidateFilesList/columns/LastNameColumn';
import { emailFilter } from '../canSeeCandidateFile/candidateFilesList/columns/EmailColumn';
import { shortIdColumn, shortIdFilter } from '../canSeeCandidateFile/candidateFilesList/columns/ShortIdColumn';
import { lastDegreeColumn, lastDegreeFilter } from '../canSeeCandidateFile/candidateFilesList/columns/LastDegreeColumn';
import { createdAtColumn, createdAtFilter } from '../canSeeCandidateFile/candidateFilesList/columns/CreatedAtColumn';
import {
    submittedAtColumn,
    submittedAtFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/SubmittedAtColumn';
import {
    candidateFileStatusColumn,
    candidateFileStatusFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/CandidateFileStatusColumn';

import {
    lastSchoolNameColumn,
    lastSchoolNameFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/LastSchoolNameColumn';
import { programColumn, programFilter } from '../canSeeCandidateFile/candidateFilesList/columns/ProgramColumn';
import { getSelectedCandidateFiles } from '../canSeeCandidateFile/candidateFilesList/CandidateFilesList';

moment.locale('fr');

export const CandidateFilesListInitialColumns = () => [
    { ...avatarColumn, defaultLocked: undefined },
    { ...shortIdColumn, defaultLocked: undefined },
    { ...lastNameColumn, defaultLocked: undefined },
    { ...firstNameColumn, defaultLocked: undefined },
    { ...lastSchoolNameColumn, defaultLocked: undefined },
    { ...lastDegreeColumn, defaultLocked: undefined },
    { ...candidateFileStatusColumn, defaultLocked: undefined },
    { ...createdAtColumn, defaultLocked: undefined },
    { ...submittedAtColumn, defaultLocked: undefined },
    programColumn,
];

export const CandidateFilesListInitialFilters = [
    shortIdFilter,
    firstNameFilter,
    lastNameFilter,
    lastSchoolNameFilter,
    candidateFileStatusFilter,
    lastDegreeFilter,
    createdAtFilter,
    submittedAtFilter,
    emailFilter,
    programFilter,
];

export function renderRowContextMenu(menuProps: any,
    details: {
        rowProps: TypeRowProps;
        cellProps: TypeCellProps;
        grid: any;
        computedProps: TypeComputedProps;
        computedPropsRef: MutableRefObject<TypeComputedProps>;
    },
    selectedCandidateFiles,
    gridRef) {
    return (
        <ProgramManagerRowContextMenu
            menuProps={menuProps}
            details={details}
            selectedCandidateFiles={getSelectedCandidateFiles(details.rowProps.data, selectedCandidateFiles)}
            gridRef={gridRef}
        />
    );
}
