import styled from '@emotion/styled';
import { Switch, SwitchProps } from 'antd';
import React, { Fragment } from 'react';
import { DisabledContext } from '../../rights/DisabledContext';
import { copyEntityFormProps, EntityFormItemContainer, ItemProps } from '../entityForm/EntityFormItemContainer';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

const Style = styled.div`
.ant-form-item-control .ant-form-item-children-icon{
        display: none !important ;
    }
`;
/**
 * EntityFormItemContainer for checkbox form item
 * @param props
 * @constructor
 */
export function SwitchFormItem(props: ItemProps & Omit<SwitchProps, 'disabled'> & { layout?: 'vertical' | 'horizontal' }) {
  return (
    <Style>
      <EntityFormItemContainer
        {...copyEntityFormProps(props)}
        valuePropName="checked"
        validateTrigger={['onChange', 'onBlur']}
      >
        <SwitchContentContainer
          data-testid={`${props.propertyName}`?.replace(/,/g, '_')}
          style={props.style}
        />
      </EntityFormItemContainer>
    </Style>
  );
}

function SwitchContentContainer(props: Omit<SwitchProps, 'disabled'>) {
  const disabled = useScopedSelector('CheckboxContentContainer', DisabledContext, (c) => c?.disabled);
  return (
    <Fragment>
      <Switch
        style={props.style || { zoom: '2' }}
        className={props['data-testid']}
        data-testid={props['data-testid']}
        checked={props.checked}
        onChange={props.onChange}
        disabled={disabled}
      />
    </Fragment>
  );
}
