/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext } from 'react';
import { useImmer } from 'use-immer';
import UUID from 'uuid-js';
import { IStayAbroad, UserRoleEnum, ICountry } from '@fstn/ecandidaturev2_api-interfaces';
import { UserContext, UserContextType } from '../../../context/user.context';
import * as Locale from '../../../common/locale';
import { ColdDataAutoCompleteFormItem } from '../../../common/form/inputFormItems/ColdDataAutoCompleteFormItem';

export function StayAbroadModal(props: {
  showIdiom: boolean,
  onCancel: () => Promise<void>,
  onAdd: (stayAbroad: Partial<IStayAbroad>) => Promise<void>
}) {
  const [stayAbroadCountryForm] = useForm();
  const { userCtx } = useContext<UserContextType>(UserContext);

  const [state, updateState] = useImmer({ loading: false });
  async function onAdd(stayAbroad: Partial<IStayAbroad>) {
    updateState((draft) => { draft.loading = true; });
    await props.onAdd(stayAbroad);
    updateState((draft) => { draft.loading = false; });
    stayAbroadCountryForm.resetFields();
  }

  return (
    <Modal
      title={<Locale.Title tkey="stayAbroad.country" />}
      visible={props.showIdiom}
      onOk={() => {
        stayAbroadCountryForm.submit();
      }}
      // @ts-ignore
      okButtonProps={{ 'data-testid': 'stayAbroad.ok', loading: state.loading }}
      cancelButtonProps={{ disabled: state.loading }}
      onCancel={props.onCancel}
    >
      <Locale.Help tkey="stayAbroad.country" />
      <Form
        form={stayAbroadCountryForm as any}
        onFinish={async (v) => {
          const stayAbroad: Partial<IStayAbroad> = {
            id: UUID.create(1).toString(),
            country: v.country.id,
          };

          if (userCtx.user?.role?.name === UserRoleEnum.SECRETARY) {
            // if we are secretary, we switched to edit mode so we need to set the right rights
            stayAbroad.rights = {
              manage: [UserRoleEnum.SECRETARY],
              read: Object.values(UserRoleEnum),
              write: [UserRoleEnum.SECRETARY],
              delete: [UserRoleEnum.SECRETARY],
            };
          }
          await onAdd(stayAbroad);
        }}
      >
        <ColdDataAutoCompleteFormItem
          propertyName="country"
          api="countries"
          rules={[
          {
            required: true,
            message: 'Champ obligatoire !',
          },
        ]}
          filter={(s: ICountry) => s.id !== 'France'}
        />
      </Form>
    </Modal>
  );
}
