import {AppConfigDto, IAfterLoginMessage} from '@fstn/ecandidaturev2_api-interfaces';
import {Card} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, {Fragment} from 'react';
import * as Locale from '../../../common/locale';

export function AfterLoginMessagesPage(props: { appConfig: AppConfigDto }) {
    const { appConfig } = props;
    if (appConfig.appMessages.length === 0) {
        return <Fragment />;
    }
    return (
      <Modal visible title={<Locale.Title tkey="afterLoginMessages" />}>
        {appConfig.appMessages.map((m: IAfterLoginMessage) => (
          <Card>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: m.content }} />
          </Card>
            ))}
      </Modal>
    );
}
