/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { IMotivation, IProgramsList, ITrafficAttributedSource } from '@fstn/ecandidaturev2_api-interfaces';
import { Card, Divider, Form } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import { StoreValue } from 'rc-field-form/lib/interface';
import { Fragment, HTMLAttributes } from 'react';
import { Box, Flex } from 'rebass';
import { orderBy } from 'lodash';

import { EntityFormContainer } from '../../../common/form/entityForm/EntityFormContainer';
import { EntityFormItemContainer } from '../../../common/form/entityForm/EntityFormItemContainer';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { FormStyle } from '../../../common/form/FormStyle';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import { InputTextAreaFormItem } from '../../../common/form/inputFormItems/InputTextAreaFormItem';
import { SecretaryReviewFormFooterContainer } from '../../../common/form/secretaryReviewForm/SecretaryReviewFormFooterContainer';
import { WithSwitchToReadOnlyOnDisplayForSecretary } from '../../../common/withExtraParameters/WithSwitchToReadOnlyOnDisplayForSecretary';
import { FormListFieldData } from '../../../common/form/type';
import { WithInitialLoad } from '../../../common/withExtraParameters/WithInitialLoad';
import { EntityFormContext } from '../../../context/entityForm.context';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { IsSecretary } from '../../user/conditionnal/IsSecretary';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { DisabledContextProviderForEntityForm } from '../../../common/rights/DisabledContextProviderForEntityForm';
import { IsDesktop } from '../../user/conditionnal/IsDesktop';
import { SwitchFormItem } from '../../../common/form/inputFormItems/SwitchFormItem';
import { IsMobile } from '../../user/conditionnal/IsMobile';

export type MotivationProps = HTMLAttributes<HTMLElement>;
const entity = 'motivation';

const Style = styled.div`

`;

function MotivationEntityForm({ programsList }: { programsList?: IProgramsList }) {
  const trafficAttributedSources = useScopedSelector<any[], IMotivation>('MotivationForm_trafficAttributedSources', EntityFormContext, (c) => c[0]?.entityValue?.trafficAttributedSources);
  const responsive = useResponsiveForm();
  function getTrafficAttributedSources(field: FormListFieldData) {
    return trafficAttributedSources?.[field.fieldKey];
  }

  return (
    <Style>
      <Box {...responsive.w1}>
        <FormContentPanelContainer partName={entity}>
          <InputTextAreaFormItem propertyName="why" />
          {programsList?.programLines?.length > 1 && <InputTextAreaFormItem propertyName="order" />}
          <InputTextAreaFormItem propertyName="otherPrograms" />
          <InputTextAreaFormItem propertyName="whyApprenticesship" />
          <InputTextAreaFormItem propertyName="professionalOrientation" />
          <InputTextAreaFormItem propertyName="teamWork" />
          <InputTextAreaFormItem propertyName="profile" />
          <InputTextAreaFormItem propertyName="activity" />
          <Divider />
          <EntityFormItemContainer
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            dependenciesValues={[trafficAttributedSources]}
            propertyName="trafficAttributedSources"
          >
            <Form.List name="trafficAttributedSources">
              {(fields, _:
                { add: (defaultValue?: StoreValue) => void, remove: (index: number | number[]) => void }) => {
                const fieldValues: { fieldValue: ITrafficAttributedSource, field: FormListFieldData }[] = fields.map((field: FormListFieldData) => ({
                  fieldValue: getTrafficAttributedSources(field),
                  field,
                }));
                const categories = fieldValues.map(({ fieldValue }) => fieldValue?.trafficSource?.category).sort();
                return (
                  <Fragment>
                    {
                      ([...new Set(categories)]).map((category, i) => (
                        <Card
                          title={category}
                          key={i as any}
                          css={css`width:100%`}
                        >
                          <Fragment>
                            {
                              orderBy(
                                fieldValues
                                  .filter(({ fieldValue }) => fieldValue?.trafficSource.category === category),
                                ['fieldValue.trafficSource.ranking', 'fieldValue.trafficSource.id'],
                              )
                                .map(({ fieldValue, field }) => (
                                  <Fragment key={`key_${field.name}`}>
                                    <Flex
                                      name="trafficAttributedSources"
                                      flexWrap="wrap"
                                    >
                                      <Box {...responsive.w1}>
                                        <IsDesktop>
                                          <SwitchFormItem
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 4 }}
                                            propertyName={['trafficAttributedSources', field.name, 'selected']}
                                            label={fieldValue?.trafficSource.id}
                                            style={{ zoom: 1 }}
                                          />
                                        </IsDesktop>
                                        <IsMobile>
                                          <SwitchFormItem
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            propertyName={['trafficAttributedSources', field.name, 'selected']}
                                            label={fieldValue?.trafficSource.id}
                                          />
                                        </IsMobile>
                                      </Box>
                                      {fieldValue?.trafficSource.other && fieldValue.selected
                                        && (
                                          <Box {...responsive.w1}>
                                            <InputFormItem
                                              propertyName={['trafficAttributedSources', field.name, 'other']}
                                              label={fieldValue?.trafficSource.other}
                                            />
                                          </Box>
                                        )}
                                    </Flex>
                                  </Fragment>
                                ))
                            }
                          </Fragment>
                        </Card>
                      ))
                    }
                  </Fragment>
                );
              }}
            </Form.List>
          </EntityFormItemContainer>
        </FormContentPanelContainer>
      </Box>
    </Style>
  );
}

export function MotivationForm(props: { layout?: FormLayout }) {
  return (
    <FormStyle>
      <WithInitialLoad entity={entity}>
        <WithSwitchToReadOnlyOnDisplayForSecretary>
          <EntityFormContainer layout={props.layout}>
            <DisabledContextProviderForEntityForm>
              <WithInitialLoad entity="programs-list" propsName="programsList">
                <MotivationEntityForm />
              </WithInitialLoad>
              <IsSecretary>
                <SecretaryReviewFormFooterContainer propertyName="secretaryReview" />
              </IsSecretary>
            </DisabledContextProviderForEntityForm>
          </EntityFormContainer>
        </WithSwitchToReadOnlyOnDisplayForSecretary>
      </WithInitialLoad>
    </FormStyle>
  );
}
