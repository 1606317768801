import styled from '@react-pdf/styled-components';
import React, { ReactNode } from 'react';

const Container = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-size: 9px;
    align-self: center;
    align-items: center;
    align-content: center;
`;

export function PDFTextGutter(props: { children: ReactNode, debug?: boolean }) {
    return (
      <Container debug={props.debug}>
        {props.children}
      </Container>
    );
}
