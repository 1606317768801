import styled from '@react-pdf/styled-components';
import React, { ReactNode } from 'react';
import { PDFLabel } from './PDFLabel';

const Container = styled.View`
    display: flex;
    flex-direction: ${(props) => (props.layout === 'vertical' ? 'column' : 'row')};
    margin: 5px;
    margin-bottom: ${(props) => (props.layout === 'vertical' ? '20px' : '0px')};
    justify-content: space-between;
`;
const Label = styled.View` 
    display: inline;
    color: #555;
    margin-bottom: ${(props) => (props.layout === 'vertical' ? '10px' : '0px')};
`;

const Content = styled.View`
    display: inline;
    color: #888;
    margin-left: 5px;
    flex: 1;
    
`;

export function PDFFormControl(props: { title: ReactNode, children?: ReactNode, debug?: boolean, bordered?: boolean, layout?: 'vertical' | 'horizontal' }) {
    return (
      <Container debug={props.debug} bordered={props.bordered} layout={props.layout}>
        <Label debug={props.debug} bordered={props.bordered} layout={props.layout}>
          <PDFLabel>{props.title}</PDFLabel>
        </Label>
        <Content debug={props.debug}>
          {props.children}
        </Content>
      </Container>
    );
}
