/** @jsx jsx */
import {jsx} from '@emotion/core';
import styled from '@emotion/styled';
import {Anchor, Menu} from 'antd';
// @ts-ignore
import {usePath} from 'hookrouter';
import _ from 'lodash';
import React, {HTMLAttributes} from 'react';
import {AnchorWithTooltip} from '../specializedSecretary/SpecializedSecretaryLeftMenu';

// language=LESS prefix=*{ suffix=}
const Style: any = styled.div`
    grid-area: left;

    .ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 10px;
    }
`;

export type LeftMenuProps = HTMLAttributes<HTMLElement>;

export const ExaminerLeftMenu: React.FunctionComponent<LeftMenuProps> = () => {
    const path = usePath();

    return (
      <Style>
        <Menu
          style={{ width: 'auto', paddingLeft: '5px', height: '100%' }}
          selectedKeys={[(`${_.last(path.split('/'))}`).replace('/', '')]}
          defaultOpenKeys={['candidate-file']}
          mode="inline"
        >
          <Anchor onClick={(e) => e.stopPropagation()}>
            <AnchorWithTooltip entityName="programs-list" />
            <AnchorWithTooltip entityName="profile" />
            <AnchorWithTooltip entityName="bachelor" />
            <AnchorWithTooltip entityName="academics-list" />
            <AnchorWithTooltip entityName="languages-list" />
            <AnchorWithTooltip entityName="stays-abroad-list" />
            <AnchorWithTooltip entityName="company-experiences-list" />
            <AnchorWithTooltip entityName="motivation" />
            <AnchorWithTooltip entityName="apprenticeship-company" />
            <AnchorWithTooltip entityName="additional-documents-list" />
          </Anchor>
        </Menu>
      </Style>
    );
};
