import { UserRoleEnum } from '@fstn/ecandidaturev2_api-interfaces';
import _ from 'lodash';
import { RoutesContextType } from '../context/routes.context';
import { AdminRoutesDef } from '../pages/admin/routes/AdminRoutesDef';

import { LoginRoutesDef } from '../pages/anonymous/LoginRoutesDef';
import { CandidateRoutesDef, CandidateRoutesPath, PaymentRoutesDef } from '../pages/candidate/routes/CandidateRoutesDef';
import { ExaminerRoutesDef } from '../pages/examiner/routes/ExaminerRoutesDef';
import { JuryRoutesDef } from '../pages/jury/JuryRoutesDef';
import { ProgramManagerRoutesDef } from '../pages/programManager/ProgramManagerRoutesDef';
import { ReferentRoutesDef } from '../pages/referent/routes/ReferentRoutesDef';
import { SecretaryRoutesDef, SecretaryRoutesPath } from '../pages/secretary/routes/SecretaryRoutesDef';
import { SpecializedSecretaryRoutesDef } from '../pages/specializedSecretary/routes/SpecializedSecretaryRoutesDef';

import { RgpdRoutesDef } from '../pages/user/rgpd/RgpdRoutesDef';
import { RegisterRoutesDef } from '../pages/user/UserRoutesDef';
import { useSafeTranslation } from './use-safe-translation';

export function useRoutesDef({ routesCtx, updateRoutesCtx }: RoutesContextType, unloadMe) {
    const { t } = useSafeTranslation();

    async function updateRoutes(userCtx) {
        // console.log('Redraw useRoutesDef');
        let routes: any = { ...RgpdRoutesDef(t), ...RegisterRoutesDef(t), ...LoginRoutesDef(t, unloadMe) };
        let writableRoutesPath: any = [];
        const routesName = userCtx?.user?.role?.name || 'LOGIN';
        switch (userCtx?.user?.role?.name) {
            case UserRoleEnum.CANDIDATE:
                routes = { ...routes, ...PaymentRoutesDef(), ...CandidateRoutesDef() };
                writableRoutesPath = [...writableRoutesPath, ...CandidateRoutesPath()];
                break;
            case UserRoleEnum.SECRETARY:
                routes = { ...routes, ...SecretaryRoutesDef() };
                writableRoutesPath = [...writableRoutesPath, ...SecretaryRoutesPath()];
                break;
            case UserRoleEnum.SPECIALIZED_SECRETARY:
                routes = { ...routes, ...SpecializedSecretaryRoutesDef() };
                writableRoutesPath = [...writableRoutesPath];
                break;
            case UserRoleEnum.REFERENT:
                routes = { ...routes, ...ReferentRoutesDef(t) };
                writableRoutesPath = [...writableRoutesPath];
                break;
            case UserRoleEnum.EXAMINER:
                routes = { ...routes, ...ExaminerRoutesDef() };
                writableRoutesPath = [...writableRoutesPath];
                break;
            case UserRoleEnum.PROGRAM_MANAGER:
                routes = { ...routes, ...ProgramManagerRoutesDef(t) };
                writableRoutesPath = [...writableRoutesPath];
                break;
            case UserRoleEnum.JURY:
                routes = { ...routes, ...JuryRoutesDef(t) };
                writableRoutesPath = [...writableRoutesPath];
                break;
            case UserRoleEnum.ADMIN:
                routes = { ...routes, ...AdminRoutesDef(t) };
                writableRoutesPath = [...writableRoutesPath];
                break;
        }

        if (!_.isEqual(routesCtx.routesName, routesName)) {
            updateRoutesCtx((draft: any) => {
                draft.routes = routes;
                draft.routesName = routesName;
                draft.writableRoutesPath = writableRoutesPath;
            });
        }
    }

    return { updateRoutes };
}
