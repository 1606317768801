import Upload from 'antd/lib/upload';
import { IDocument } from '@fstn/ecandidaturev2_api-interfaces';
import React from 'react';
import { AxiosInstance } from 'axios';
import UploadContent from './UploadContent';

export function UploadWithContent(props: {
    document: IDocument,
    accept: string,
    token:string,
    axios:AxiosInstance
    onChange:any,
    onBeforeUpload: any,
    deleteUploadedDocument: any,
    disabled: boolean,
    uploadLink: string,
    propertyName: string | any[],
}) {
   return (
     <Upload.Dragger
       name="file"
       action={props.uploadLink}
       headers={props.token ? {
            authorization: `Bearer ${props.token}`,
        } : {}}
       showUploadList={false}
       multiple={false}
       data={props.document}
        // @ts-ignore
       beforeUpload={props.onBeforeUpload}
       listType="picture-card"
       onChange={props.onChange}
       accept={props.accept}
       /* ok */disabled={props.disabled}
     >
       <UploadContent
         axios={props.axios}
         token={props.token}
         propertyName={props.propertyName}
         onDelete={props.deleteUploadedDocument}
         /* ok */disabled={props.disabled}
         document={props?.document}
       />
     </Upload.Dragger>
);
}
