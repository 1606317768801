/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { ColdDataAutoCompleteContainer } from '../../../../common/coldData/ColdDataAutoCompleteContainer';
import * as Locale from '../../../../common/locale';

/**
 * Fonction appelee pour la generation de la combo pour filtrage des filières
 * @param props
 * @returns
 */
export function LastSchoolTrackTypeFilter(props) {
        // Recuperation des etablissements
        const filterEditorProps = props?.filterEditorProps;

        return props.render(
                <ColdDataAutoCompleteContainer
                        {...filterEditorProps}
                        api="school-track-types"
                        params="{ style: { width: '150px' } }"
                        allowClear
                        mode="multiple"
                        multiple
                        onClear={() => {
                                props.onChange({ ...props.filterValue, value: undefined });
                        }}
                        onChange={(v) => {
                                if (!v) {
                                        props.onChange({ ...props.filterValue, value: [] });
                                        return;
                                }
                                if (Array.isArray(v)) {
                                        props.onChange({ ...props.filterValue, value: v.map((s) => s.id) });
                                } else {
                                        props.onChange({ ...props.filterValue, value: [v.id] });
                                }
                        }}
                />,
        );
}

/**
 * Display School_track value
 * @param value
 * @constructor
 */
export function LastSchoolTrackTypeCellRender({ data, value }: any) {
        return (
                <Fragment key={`lastSchoolTrackType_cell_${data.id}`}>
                        {value}
                </Fragment>
        );
}

export const lastSchoolTrackTypeColumn = {
        header: <Locale.Header tkey="candidateFilesList.lastSchoolTrackType" />,
        name: 'lastSchoolTrackType',
        id: 'lastSchoolTrackType',
        group: 'profile',
        defaultWidth: 100,
        defaultLocked: 'start',
        lockable: false,
        filterEditor: LastSchoolTrackTypeFilter,
        render: LastSchoolTrackTypeCellRender,
        filterable: true,
};

export const lastSchoolTrackTypeFilter = {
        name: 'lastSchoolTrackType', operator: 'inlist', type: 'select', value: '',
};
