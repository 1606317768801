import React from 'react';
import { useSafeTranslation } from '../../hooks/use-safe-translation';
import { CandidateFilesList } from '../canSeeCandidateFile/candidateFilesList/CandidateFilesList';
import {
    CandidateFilesListInitialColumns,
    CandidateFilesListInitialFilters,
    renderRowContextMenu,
} from './CandidateFilesListInitialConfig';

export function ExaminerHome() {
    const { t } = useSafeTranslation();

    return (
      <CandidateFilesList
        initialColumns={CandidateFilesListInitialColumns(t)}
        renderRowContextMenu={renderRowContextMenu}
        gridStyle={{ height: 'calc( 100% - 100px)' }}
        initialFilters={CandidateFilesListInitialFilters}
      />
);
}
