import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import React, { Fragment, MutableRefObject } from 'react';

export const configsListInitialColumns = (t) => [
    {
        header: 'Spécialité',
        name: 'name',
        defaultFlex: 1,
        editable: true,
        style: { textAlign: 'left' },

    },
    {
        header: 'Position',
        name: 'ranking',
        defaultFlex: 1,
        editable: true,
        style: { textAlign: 'left' },

    },
];

export const configsListInitialFilters = [
    {
        name: 'name', operator: 'contains', type: 'string', value: '',
    },
];

export function configsListRenderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedConfig) {
    return (
        <Fragment />
    );
}

/**
 * Map result to row
 * @param d
 */
export function configsListMapToRow(d: any) {
    const row: any = {
        name: d.name,
        ranking: d.ranking,
        id: d.id,
    };
    return row;
}
