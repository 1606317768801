/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import PageHeader from 'antd/lib/page-header';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Fragment, useContext } from 'react';
import { useImmer } from 'use-immer';
// @ts-ignore
import useMobileDetect from 'use-mobile-detect-hook';
import { TextDangerButton } from '../../common/button/TextDangerButton';
import * as Locale from '../../common/locale';
import { MenuAndFormContainer } from '../../common/page/Containers';
import { UserContext } from '../../context/user.context';
import { sNavigate } from '../../utils/safeNavigation';
import { CenterAreaContainer } from '../canSeeDocuments/viewer/CenterAreaContainer';
import { UserPage } from '../user/UserPage';
import { LOGIN, ROUTES } from '../user/UserRoutesDef';
import { DocumentHeaderButton } from '../canSeeDocuments/DocumentHeaderButton';
import { IsPath } from '../user/conditionnal/IsPath';
import { TextPrimaryButton } from '../../common/button/TextPrimaryButton';

const HeaderStyle = styled.div`
grid-area: header;
`;

export function ProgramManagerPageHeader() {
  const userCtx = useContext(UserContext);

  return (
    <HeaderStyle>
      <PageHeader
        title={<Locale.Title tkey="program-manager" />}
        className="site-page-header"
        subTitle={`${userCtx.userCtx?.user?.name} ${userCtx.userCtx?.user?.program?.name} (${userCtx.userCtx?.user?.program?.optionName || ''})`}
        extra={(
          <Fragment>
            <DocumentHeaderButton />
            <IsPath different={`${ROUTES.PROGRAM_MANAGER_BASE}`}>
              <TextDangerButton
                tkey="secretary.exit"
                onClick={() => sNavigate(`${ROUTES.PROGRAM_MANAGER_BASE}`, true)}
              />
            </IsPath>
            <IsPath equals={`${ROUTES.PROGRAM_MANAGER_BASE}`}>
              <TextPrimaryButton
                tkey="statistics"
                onClick={() => sNavigate(`${ROUTES.PROGRAM_MANAGER_BASE}/statistics`, true)}
              />
              <TextDangerButton
                tkey="logout"
                onClick={() => sNavigate(`${LOGIN.LOGOUT}`)}
              />
            </IsPath>
          </Fragment>
        )}
      />
    </HeaderStyle>
  );
}

export function ProgramManagerPage(props: { children: any }) {
  const detectMobile = useMobileDetect();
  const PageStyle = detectMobile.isMobile()
    ? styled.div`
            height: 100%;
            display: grid;
            grid-template-areas:  "header"
                                  "left"
                                  "content";`
    : styled.div`
            height: 100%;
            display: grid;
            grid-template-areas:  "header"
                                  "content";
            grid-template-rows: 50px minmax(0, 100%);
            grid-template-columns: minmax(0, 1fr);`;

  const [state] = useImmer<{ compact: boolean }>({ compact: detectMobile.isMobile() });
  return (
    <UserPage>
      <PageStyle>
        {!state.compact
          && <ProgramManagerPageHeader />}
        <MenuAndFormContainer>
          <CenterAreaContainer>
            {props?.children}
          </CenterAreaContainer>
        </MenuAndFormContainer>
      </PageStyle>
    </UserPage>
  );
}

export function FullScreenProgramManagerPage(props: { children: any }) {
  return (
    <UserPage>
      <ProgramManagerPageHeader />
      <div css={css`height:calc( 100% - 100px)`}>
        {props?.children}
      </div>
    </UserPage>
  );
}