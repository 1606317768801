/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { ExaminerReviewStatusEnum } from '@fstn/ecandidaturev2_api-interfaces';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Tag } from 'antd';
import * as Locale from '../../../../common/locale';
import { useSafeTranslation } from '../../../../hooks/use-safe-translation';
import { ResponsiveSelect } from '../../../../common/input/ResponsiveSelect';
import { getOptions } from '../../../../common/coldData/utils';
// Pour alimentation des combos (filtres et edition) du genre
const listExaminerReviewStatus = Object.values(ExaminerReviewStatusEnum);

/**
 * Fonction appelee pour la generation de la combo pour filtrage des genres
 * @param props
 * @returns
 */
export function ExaminerReviewStatusFilter(props) {
    const { t } = useSafeTranslation();
    return props.render(
        <ResponsiveSelect
            mode="multiple"
            {...props.filterEditorProps}
            data-testid={props['data-testid']}
            value={props.value}
            allowClear
            tagRender={(p) => <Tag color={statusToColor(p.value)}><Locale.Label tkey={`candidateFilesList.${p.value}`} /></Tag>}
            onClear={() => {
                props.onChange({ ...props.filterValue, value: undefined });
            }}
            onChange={(e) => {
                props.onChange({ ...props.filterValue, value: e });
            }}
        >
            {getOptions(listExaminerReviewStatus.map((s) => ({ label: s, id: s })), 'candidateFilesList', props['test-id'], t)}
        </ResponsiveSelect>,
    );
}

/**
 * Display ExaminerReviewStatus value
 * @param value
 * @constructor
 */
export function ExaminerReviewStatusCellRender({ value, data }: any) {
    return (
        <div style={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1em',
        }}
        >
            <Checkbox checked={data.examinerReview?.completed} />
            <div style={{ flex: 0 }}>
                <Tag
                    color={statusToColor(value)}
                    css={css`
white-space: nowrap;
flex-wrap: nowrap;
wrap: nowrap;
width: 130px;
margin-right: 0;
`}
                >
                    <Locale.Label tkey={`candidateFilesList.${value || ExaminerReviewStatusEnum.UNDONE}`} />
                </Tag>
            </div>
            <div style={{ flex: 1 }}>{data.examinerReview?.comments}</div>
        </div>
    );
}

export const examinerReviewStatusEditorProps = {
    idProperty: 'examinerReviewStatus',
    dataSource: listExaminerReviewStatus,
};

export const examinerReviewStatusColumn = {
    header: <Locale.Header tkey="candidateFilesList.examinerReview" />,
    name: 'examinerReviewReview',
    id: 'examinerReviewReview',
    defaultWidth: 410,

    lockable: false,
    filterable: true,
    render: ExaminerReviewStatusCellRender,
    filterEditor: ExaminerReviewStatusFilter,
    filterEditorProps: examinerReviewStatusEditorProps,
};

export const examinerReviewStatusFilter = {
    name: 'examinerReviewReview', operator: 'inlist', type: 'select', value: '',
};

function statusToColor(status) {
    switch (status) {
        case ExaminerReviewStatusEnum.UNDONE: {
            return '#ccc';
        }
        case ExaminerReviewStatusEnum.UNFAVORABLE: {
            return 'geekblue';
        }
        case ExaminerReviewStatusEnum.RESERVED: {
            return 'blue';
        }
        case ExaminerReviewStatusEnum.NEUTRAL: {
            return 'cyan';
        }
        case ExaminerReviewStatusEnum.FAIRLY_FAVORABLE: {
            return 'green';
        }
        case ExaminerReviewStatusEnum.FAVORABLE: {
            return 'lime';
        }
        case ExaminerReviewStatusEnum.VERY_FAVORABLE: {
            return 'gold';
        }
    }
    return '#ccc';
}

export const examinerReviewNotationColumn = {
    header: <Locale.Header tkey="candidateFilesList.examinerReviewNotation" />,
    name: 'examinerReviewNotation',
    id: 'examinerReviewNotation',
    defaultWidth: 70,
    style: { textAlign: 'right' },
    filterEditor: NumberFilter,
};

export const examinerReviewNotationFilter = {
    name: 'examinerReviewNotation', operator: 'eq', type: 'number', value: '',
};
