/** @jsx jsx */
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { jsx } from '@emotion/core';
import * as Locale from '../../../../common/locale';
import { dateRender, sortDate } from '../../../secretary/CandidateFilesListInitialConfig';

export const createdAtColumn = {
    header: <Locale.Header tkey="candidateFilesList.createdAt" />,
    name: 'createdAt',
    id: 'createdAt',
    group: 'candidateFile',
    defaultWidth: 140,
    type: 'date',
    sort: sortDate,
    render: dateRender,
    enableColumnFilterContextMenu: false,
    // @ts-ignore
    filterEditor: DateFilter,
    filterEditorProps: (props, { index }) => ({
        dateFormat: 'DD-MM-YYYY',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: index === 1 ? 'Fin' : 'Début',
    }),
};

export const createdAtFilter = {
    name: 'createdAt',
    operator: 'inrange',
    type: 'date',
    value: '',
};
