/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { AreaContainerPanel } from '../../../common/layout/AreaContainerPanel';
import { useIsSmallScreen } from '../../../hooks/use-is-small-screen.hook';

export function CenterAreaContainer(props: any) {
    const smallScreen = useIsSmallScreen();
    const CenterStyle = smallScreen
        ? styled.div`
    component: CenterStyle;
`
        : styled.div`
    height: 100%;
    overflow: auto; 
    flex-wrap: wrap;
    flex: 1 1 600px;
    component: CenterStyle;
`;
    return (
      <CenterStyle>
        <AreaContainerPanel css={css`
    grid-area: content;
    width: 100%;
    min-height: 100%;
    height: 100%;
    position: relative;
    flex: 1;
    .container.text-center{
        width:100%;
    }`}
        >
          {props.children}
        </AreaContainerPanel>
      </CenterStyle>
    );
}
