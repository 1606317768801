/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { IProgramLine, IProgramsList } from '@fstn/ecandidaturev2_api-interfaces';
import { Affix, Card } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import { Store } from 'rc-field-form/lib/interface';
import { HTMLAttributes, useRef } from 'react';
import { DeepPartial } from 'redux';
import { useImmer } from 'use-immer';
import styled from '@emotion/styled';
import { EntityFormContainer } from '../../../common/form/entityForm/EntityFormContainer';
import { EntityFormListContainer } from '../../../common/form/entityForm/EntityFormListContainer';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { FormStyle } from '../../../common/form/FormStyle';
import { CheckboxFormItem } from '../../../common/form/inputFormItems/CheckboxFormItem';
import { SecretaryReviewFormFooterContainer } from '../../../common/form/secretaryReviewForm/SecretaryReviewFormFooterContainer';
import { WithSwitchToReadOnlyOnDisplayForSecretary } from '../../../common/withExtraParameters/WithSwitchToReadOnlyOnDisplayForSecretary';
import { WithInitialLoad } from '../../../common/withExtraParameters/WithInitialLoad';
import { Important } from '../../../common/layout/Important';
import { EntityFormContext } from '../../../context/entityForm.context';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { IsSecretary } from '../../user/conditionnal/IsSecretary';
import { IsSecretaryOrCandidate } from '../../user/conditionnal/IsSecretaryOrCandidate';
import { ProgramForm } from './ProgramForm';
import { ProgramModal } from './ProgramModal';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { IsDesktop } from '../../user/conditionnal/IsDesktop';
import { DisabledContextProviderForEntityForm } from '../../../common/rights/DisabledContextProviderForEntityForm';
import { useEntity } from '../../../hooks/use-entity';

export type ProgramsListProps = HTMLAttributes<HTMLElement>;
const entity = 'programs-list';

const Style = styled.div`
  height: 100%;
`;
function ProgramsListEntityForm() {
  const { deleteEntity } = useEntity();
  const programLines = useScopedSelector<any[], IProgramLine[]>('ProgramsListEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.programLines);
  const thereIsSomeSelectedPrograms = programLines?.length;
  const [state, updateState] = useImmer({ show: false, add: undefined as any });
  return (
    <Style>
      <FormContentPanelContainer partName={entity}>
        <ProgramModal
          onCancel={async () => {
            updateState((draft) => { draft.show = false; });
          }}
          onAdd={async (_programLines: IProgramLine[]) => {
            for (const programLine of _programLines) {
              state.add(programLine);
              // eslint-disable-next-line no-await-in-loop
            }
            updateState((draft) => {
              draft.show = false;
            });
          }}
          show={state.show}
          hiddenPrograms={programLines?.map((p) => p?.program) || []}
        />
        <EntityFormListContainer
          compact
          propertyName="programLines"
          entity="programLines"
          addDecorator={(add) => {
            updateState((draft) => {
              draft.add = add;
              draft.show = true;
            });
          }}
          removeDecorator={async (remove, value: IProgramLine) => {
            if (value.motivationLetter) {
              await deleteEntity('document', value.motivationLetter);
            }
            remove();
          }}
        >
          <ProgramForm />
        </EntityFormListContainer>
        {!!thereIsSomeSelectedPrograms
          && (
            <IsSecretaryOrCandidate elseChildren={<ConfirmCheckbox />}>

              <IsDesktop elseChildren={<ConfirmCheckbox />}>
                <Affix offsetBottom={80}>
                  <ConfirmCheckbox />
                </Affix>
              </IsDesktop>
            </IsSecretaryOrCandidate>
          )}
      </FormContentPanelContainer>
    </Style>
  );
}

function ConfirmCheckbox() {
  const responsive = useResponsiveForm();
  return (
    <Card
      {...responsive.w1}
      css={css`
      .ant-checkbox-wrapper{
        width: 30px;
        height: 30px;
      }
            .ant-checkbox-inner{
              width: 30px;
              height: 30px;
              border: 3px solid #888888; ;

              &::after{
                top: 9px;
                left: 5px;
                width: 8px;
                height: 18px;
                zoom: 1;
              }
            }
            `}
    >
      <Important>
        <CheckboxFormItem labelCol={{ span: 22 }} wrapperCol={{ span: 2 }} propertyName="confirmed" />
      </Important>
    </Card>
  );
}

export const ProgramsListForm = (props: { layout?: FormLayout }) => {
  const previousData = useRef(null);
  const onBeforeValidate = (values: Store, differences: DeepPartial<IProgramsList>, setFieldsValue: Function, oldData: DeepPartial<IProgramsList>) => {
    const previous = previousData.current || oldData;
    // Dans le cas ou on ajoute une nouvelle formation, on decoche la case "confirmed" pour forcer le candidat a reaccepter
    if (differences?.programLines?.length > previous?.programLines?.length) {
      differences.confirmed = false;
      setFieldsValue({ values, confirmed: false });
    }

    previousData.current = values;
    return differences;
  };
  // On ajoute ici dans le afterUpdate le refresh de la page complete si necessaire (si on le fait dans le beforeValidate, dans certains cas, la maj BDD n'est pas faite)
  const onAfterUpdate = (differences: DeepPartial<IProgramsList>, oldData: DeepPartial<IProgramsList>) => {
    // Dans le cas ou on passe de 1 à 2 formations, la page motivation doit etre rafraichie car un nouveau champ obligatoire (order) apparait
    // donc on force un refresh de la page (sinon, incoherence potentielle de l'onglet "motivation" entre affichage sur le front et l'etat reel du dossier)
    if (oldData?.programLines?.length < 2 && differences?.programLines?.length >= 2) window.location.reload();

    // Dans le cas ou passe d'un nombre > 1 de formations a une seule, la page motivation doit etre rafraichie car le champ obligatoire (order) disparait
    // donc on force un refresh de la page pour eviter les incoherences (voir comment ci-dessus)
    if (differences?.programLines?.length === 1 && oldData?.programLines?.length > 1) window.location.reload();
  };

  return (
    <FormStyle>
      <WithInitialLoad entity={entity}>
        <WithSwitchToReadOnlyOnDisplayForSecretary>
          <EntityFormContainer listeners={{ onBeforeValidate, onAfterUpdate }} layout={props.layout}>
            <DisabledContextProviderForEntityForm>
              <ProgramsListEntityForm />
              <IsSecretary>
                <SecretaryReviewFormFooterContainer propertyName="secretaryReview" />
              </IsSecretary>
            </DisabledContextProviderForEntityForm>
          </EntityFormContainer>
        </WithSwitchToReadOnlyOnDisplayForSecretary>
      </WithInitialLoad>
    </FormStyle>
  );
};
