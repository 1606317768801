/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { InputFilter } from '../filters/InputFilter';
import { PopOverCommentsFlat } from '../PopOverCommentsFlat';
import * as Locale from '../../../../common/locale';

export const CommentsBtwSecretariesFilter = InputFilter;
export const commentsBtwSecretariesFilterEditorProps = {
    className: 'commentsBtwSecretariesFilter',
    'test-id': 'commentsBtwSecretariesFilter',
};

export function CommentsBtwSecretariesCellRender({ value, data }: any) {
        return (
          <Fragment
            key={`commentsBtwSecretaries_cell_${data.id}`}
          >
            <PopOverCommentsFlat
              content={value}
              tkey="commentsBtwSecretaries"
            />
          </Fragment>
        );
    }

export const commentsBtwSecretariesColumn = {
    header: <Locale.Header tkey="candidateFilesList.commentsBtwSecretaries" />,
    name: 'commentsBtwSecretaries',
    group: 'candidateFile',
    defaultWidth: 150,
    id: 'commentsBtwSecretaries',
    filterEditor: CommentsBtwSecretariesFilter,
    filterEditorProps: commentsBtwSecretariesFilterEditorProps,
    render: CommentsBtwSecretariesCellRender,

};
export const commentsBtwSecretariesWith = 300;

export const commentsBtwSecretariesFilter = {
        name: 'commentsBtwSecretaries', operator: 'contains', type: 'string', value: '',
    };

export const commentsBtwSecretariesType = {
};
