import { usePath, useRoutes } from 'hookrouter';
import React, { useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { UserConfigContextProvider } from '../../context/user.config.context';
import { UserContext, UserContextType } from '../../context/user.context';
import { CandidateFileListContextProvider } from '../canSeeCandidateFile/CandidateFileListContext';
import { ProgramManagerContainerRoutesDef } from './ProgramManagerContainerRoutesDef';
import { DefaultPage } from './DefaultPage';

export function ProgramManagerContainer() {
  const routesChildren = useRoutes(ProgramManagerContainerRoutesDef());
  const { userCtx } = useContext<UserContextType>(UserContext);
  const path = usePath();
  const [, updateState] = useImmer({ loaded: false, routes: undefined as any, path: '' });
  useEffect(() => {
    updateState((draft) => {
      draft.path = path;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState, path]);
  if (path === '/') {
    return (
      <CandidateFileListContextProvider>
        {routesChildren}
      </CandidateFileListContextProvider>
    );
  }
  return (
    <CandidateFileListContextProvider>
      <UserConfigContextProvider>
        {userCtx.user && <DefaultPage />}
        {routesChildren}
      </UserConfigContextProvider>
    </CandidateFileListContextProvider>
  );
}
