/** @jsx jsx */
import { PlusCircleOutlined } from '@ant-design/icons';
import { jsx } from '@emotion/core';
import React from 'react';
import { TextButton } from './TextButton';

/**
 * Button related to ADD action
 * @param tkey
 * @param props
 * @constructor
 */
export function TextAddButton({ tkey, ...props }:any) {
    return (
      <TextButton
        type="success"
        size="large"
        icon={<PlusCircleOutlined />}
        {...props}
        tkey={tkey}
      />
);
}
