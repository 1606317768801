/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ICandidateFile, ISecretary, RightsUtils } from '@fstn/ecandidaturev2_api-interfaces';
import { useImmer } from 'use-immer';
import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import { Popconfirm } from 'antd';
import moment from 'moment';
import { Fragment, MutableRefObject } from 'react';
import {
    candidateFileStatusColumn,
    candidateFileStatusFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/CandidateFileStatusColumn';
import { avatarColumn } from '../canSeeCandidateFile/candidateFilesList/columns/AvatarColumn';
import {
    commentsBtwSecretariesColumn,
    commentsBtwSecretariesFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/SpecializedSecretaryReviewCommentsColumn';
import {
    lastUpdatedByColumn,
    lastUpdatedByFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/LastUpdatedByColumn';
import { useEntity } from '../../hooks/use-entity';
import { lastNameColumn, lastNameFilter } from '../canSeeCandidateFile/candidateFilesList/columns/LastNameColumn';
import {
    commentsBtwAllTypesOfSecretariesColumn,
    commentsBtwAllTypesOfSecretariesFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/CommentsBtwAllTypesOfSecretariesColumn';
import { lastDegreeColumn, lastDegreeFilter } from '../canSeeCandidateFile/candidateFilesList/columns/LastDegreeColumn';
import {
    paymentDateColumn,
    paymentDateFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/PaymentDateColumn';
import { emailColumn, emailFilter } from '../canSeeCandidateFile/candidateFilesList/columns/EmailColumn';
import {
    connectedAtColumn,
    connectedAtFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/ConnectedAtColumn';
import {
    lastUpdatedAtColumn,
    lastUpdatedAtFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/LastUpdatedAtColumn';
import {
    particularCaseColumn,
    particularCaseFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/ParticularCaseColumn';
import { ignoreItColumn, ignoreItFilter } from '../canSeeCandidateFile/candidateFilesList/columns/IgnoreItColumn';
import { firstNameColumn, firstNameFilter } from '../canSeeCandidateFile/candidateFilesList/columns/FirstNameColumn';
import { SecretaryRowContextMenu } from './SecretaryRowContextMenu';
import { getSelectedCandidateFiles } from '../canSeeCandidateFile/candidateFilesList/CandidateFilesList';
import { shortIdColumn, shortIdFilter } from '../canSeeCandidateFile/candidateFilesList/columns/ShortIdColumn';
import {
    secretaryLockedByColumn,
    secretaryLockedByFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/SecretaryLockedByColumn';
import {
    programSchoolColumn,
    programSchoolFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/ProgramSchoolColumn';
import { UserContextTypeContent } from '../../context/user.context';
import { programColumn, programFilter } from '../canSeeCandidateFile/candidateFilesList/columns/ProgramColumn';
import { createdAtColumn, createdAtFilter } from '../canSeeCandidateFile/candidateFilesList/columns/CreatedAtColumn';
import { TextOkButton } from '../../common/button/TextOkButton';
import { useSafeTranslation } from '../../hooks/use-safe-translation';
import { TextDangerButton } from '../../common/button/TextDangerButton';
import { disabledFilter, disabledColumn } from '../canSeeCandidateFile/candidateFilesList/columns/DisabledColumn';
import { lastSchoolTrackTypeColumn, lastSchoolTrackTypeFilter } from '../canSeeCandidateFile/candidateFilesList/columns/LastSchoolTrackTypeColumn';

export function sortDate(p1, p2) {
    if (p1 === p2) {
        return 0;
    }
    if (!p1) {
        return 1;
    }
    if (!p2) {
        return -1;
    }
    return (p1.diff(p2));
}

export function dateRender({ value }) {
    return (value && moment(value).isValid() ? moment(value).locale('fr').format('Do MMMM  YYYY') : '-');
}

export const CandidateFilesListInitialColumns = (t, userCtx: UserContextTypeContent) => {
    const res: any = [
        { ...avatarColumn, defaultVisible: true, defaultLocked: 'start' },
        { ...shortIdColumn, defaultVisible: true, defaultLocked: 'start' },
        { ...lastNameColumn, defaultLocked: 'start' },
        { ...firstNameColumn, defaultLocked: 'start' },
        { ...lastSchoolTrackTypeColumn, defaultLocked: 'start' },
        { ...lastDegreeColumn, defaultLocked: 'start' },
        { ...emailColumn, defaultVisible: false, defaultLocked: 'start' },
        /*--*/
        candidateFileStatusColumn,
        commentsBtwSecretariesColumn,
        commentsBtwAllTypesOfSecretariesColumn,
        particularCaseColumn,
        ignoreItColumn,
        connectedAtColumn,
        lastUpdatedAtColumn,
        lastUpdatedByColumn,
        { ...secretaryLockedByColumn, defaultVisible: true },
        { ...programColumn, defaultVisible: false },
        { ...programSchoolColumn, defaultVisible: false },
        { ...createdAtColumn, defaultVisible: false },
        { ...paymentDateColumn, defaultVisible: false },
        { ...disabledColumn, defaultVisible: false },
    ];

    if (RightsUtils.isAuthorizeForTable((userCtx?.user as ISecretary)?.rightsOverriding?.write || [], 'candidate_file')) {
        res.push(
            {
                header: '',
                name: 'cmd1',
                width: 300,
                filterable: false,
                render: (c) => (
                    <Fragment key={`disable_cell_${c.data.id}`}>
                        <DisableCandidateFile
                            candidateFile={c.data}
                        />
                    </Fragment>
                ),

            },
        );
    }
    return res;
};

/**
 * Disable canddiate file (only for super secretary)
 * @param candidateFile
 * @constructor
 */
function DisableCandidateFile({ candidateFile }: any) {
    const [state, updateState] = useImmer({ disabled: candidateFile.disabled });
    const { id } = candidateFile;
    const { patchEntity } = useEntity<ICandidateFile>();
    const { t } = useSafeTranslation();
    return (
        <Fragment>
            <TextOkButton
                tkey="secretary.candidateFile.enable"
                key={`enable_${id}`}
                style={{ maxWidth: 150, overflow: 'hidden' }}
                disabled={!state.disabled}
                onClick={async () => {
                    await patchEntity('candidate-file', { id: candidateFile.id }, { disabled: false });
                    updateState((draft) => {
                        draft.disabled = false;
                    });
                    window.location.reload();
                }}
            />
            <Popconfirm
                title={t('secretary.candidateFile.disable')}
                disabled={state.disabled}
                key={`disablePop_${id}`}
                onConfirm={async () => {
                    await patchEntity('candidate-file', { id: candidateFile.id }, { disabled: true });
                    updateState((draft) => {
                        draft.disabled = true;
                    });
                    window.location.reload();
                }}
            >

                <TextDangerButton
                    style={{ maxWidth: 150, overflow: 'hidden' }}
                    disabled={state.disabled}
                    tkey="secretary.candidateFile.disable"
                    key={`disable_${id}`}
                />
            </Popconfirm>
        </Fragment>
    );
}

export const CandidateFilesListInitialFilters = [
    shortIdFilter,
    firstNameFilter,
    lastNameFilter,
    lastDegreeFilter,
    lastSchoolTrackTypeFilter,
    candidateFileStatusFilter,
    programSchoolFilter,
    commentsBtwAllTypesOfSecretariesFilter,
    commentsBtwSecretariesFilter,
    emailFilter,
    programFilter,
    particularCaseFilter,
    ignoreItFilter,
    paymentDateFilter,
    createdAtFilter,
    connectedAtFilter,
    lastUpdatedAtFilter,
    lastUpdatedByFilter,
    secretaryLockedByFilter,
    disabledFilter
];

export function renderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedCandidateFiles, gridRef) {
    return (
        <SecretaryRowContextMenu
            menuProps={menuProps}
            details={details}
            selectedCandidateFiles={getSelectedCandidateFiles(details.rowProps.data, selectedCandidateFiles)}
            gridRef={gridRef}
        />
    );
}
