/** @jsx jsx */
import { LockOutlined } from '@ant-design/icons';
import { css, jsx } from '@emotion/core';
import React from 'react';

/**
 * Lock indicator for left menu
 * @param size
 * @constructor
 */
export function Locked({ size }: any) {
   return (
     <span css={css`
span[role=img]{
  color: red;
    border: 1px solid;
    padding: 3px;
    border-radius: 50%;
}`}
     >
       <LockOutlined style={{ fontSize: size - 6 }} />
     </span>
);
}
