/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { useContext } from 'react';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import { InputNumberFormItem } from '../../../common/form/inputFormItems/InputNumberFormItem';
import { EntityFormListContext } from '../../../context/entityFormList.context';
import { UserContext } from '../../../context/user.context';
import { DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

const Style = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  max-width: 100%;
  overflow: hidden;
  .ant-form-item-with-help{
  display: block
  }
`;

export function ValidationNotesForm(props: { field }) {
  const { canEditValidation } = useContext(UserContext);
const formListEntityValue = useScopedSelector<any[], any>('ValidationNotesForm', EntityFormListContext, (c) => c[0]?.entityValue);

  return (
    <div onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
    }}
    >
      <DisabledContextProvider value={{ disabled: !canEditValidation(), wrapAuthorized: true, context: 'ValidationNotesForm' }}>
        <Style>
          {/* etudesSuperieuresMoyenneGeneralS1 */}
          <InputNumberFormItem
            allowClear={false}
            propertyName={['academics', props.field.name, 's1GenAverage']}
          />

          {/* etudesSuperieuresMoyenneGeneralS2 */}
          <InputNumberFormItem
            allowClear={false}
            propertyName={['academics', props.field.name, 's2GenAverage']}
          />

          {/* moyenneMatiere1S1 */}
          <InputNumberFormItem
            allowClear={false}
            label={formListEntityValue.s1m1Name}
            propertyName={['academics', props.field.name, 's1m1Average']}
          />

          {/* moyenneMatiere1S2 */}
          <InputNumberFormItem
            label={formListEntityValue.s2m1Name}
            propertyName={['academics', props.field.name, 's2m1Average']}
          />

          {/* moyenneMatiere2S1 */}
          <InputNumberFormItem
            allowClear={false}
            label={formListEntityValue.s1m2Name}
            propertyName={['academics', props.field.name, 's1m2Average']}
          />

          {/* moyenneMatiere2S2 */}
          <InputNumberFormItem
            allowClear={false}
            label={formListEntityValue.s2m2Name}
            propertyName={['academics', props.field.name, 's2m2Average']}
          />

          {/* etudesSuperieuresClassementS1 */}
          <InputFormItem
            allowClear={false}
            propertyName={['academics', props.field.name, 's1Rank']}
          />

          {/* etudesSuperieuresClassementS2 */}
          <InputFormItem
            allowClear={false}
            propertyName={['academics', props.field.name, 's2Rank']}
          />

        </Style>
      </DisabledContextProvider>
    </div>
  );
}
