import { ExclamationCircleOutlined, TableOutlined } from '@ant-design/icons';
import { JuryReviewStatusEnum } from '@fstn/ecandidaturev2_api-interfaces';
import Menu from '@inovua/reactdatagrid-community/packages/Menu';
import { Modal, notification, Statistic } from 'antd';

// @ts-ignore
import React, { useContext } from 'react';
import { useImmer } from 'use-immer';
import * as Locale from '../../common/locale';
import { AxiosContext, AxiosContextType } from '../../context/axios.context';
import { getEventServiceInstance } from '../../utils/eventService';
import { useLoading } from '../../hooks/use-loading';
import { StrictLoading } from '../../common/indicator/SmartLoading';
import { useExportUtils } from '../../hooks/use-export-utils';
import { useSegment } from '../../hooks/use-segment';
import { endExportPopup } from '../../utils/endExportPopup';
import { UserContext } from '../../context/user.context';
import { endPublishJuryReviewPopup } from '../../utils/endPublishJuryReviewPopup';

const { confirm } = Modal;

export function JuryRowContextMenu({
    menuProps, details, selectedCandidateFiles, gridRef,
}: any) {
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const [state, updateState] = useImmer({ loading: false });
    const { downloadExport } = useExportUtils();
    const { doAction, loading } = useLoading();

    const items = [];

    const { segment } = useSegment(selectedCandidateFiles);

    const userCtx = useContext(UserContext);

    /**
     * Change jury review status for selected candidate files
     */
    const onChangeJuryStatus = async (juryReviewStatus) => {
        const updateManyBaseDto = {
            candidateFileIds: selectedCandidateFiles.map((cf) => cf.id),
            updates: { status: juryReviewStatus },
        };
        try {
            await axios.patch('jury-review/bulk', updateManyBaseDto);

            segment.candidateFiles.forEach((c) => {
                const index = gridRef.current.getItemIndexBy((item) => item.id === c.id);
                if (index !== -1) {
                    gridRef.current.setItemPropertyAt(index, 'juryReviewStatus', juryReviewStatus);
                    gridRef.current.setItemPropertyAt(index, 'juryReviewStatusUpdatedAt', new Date());
                }
            });
            getEventServiceInstance().logEvent('change_status.jury', { candidate_files_ids: selectedCandidateFiles?.map((c) => c.id), status: juryReviewStatus });
        } catch (e) {
            if (e.response.status === 422) {
                notification.error({
                    message: <Locale.Title tkey="juryReview.changeStatus.error.alreadyPublished" />,
                    description: <Locale.Help tkey="juryReview.changeStatus.error.alreadyPublished" />,
                    duration: 0,
                });
            } else {
                notification.error({
                    message: <Locale.Title tkey="juryReview.changeStatus.error" />,
                    description: <Locale.Help tkey="juryReview.changeStatus.error" />,
                    duration: 0,
                });
            }
        }
    };

    /**
     * Publish jury results by email for selected candidate files
     */
    const onPublishResults = async () => {
        let result;
        const updateManyBaseDto = {
            candidateFileIds: selectedCandidateFiles.map((cf) => cf.id),
            updates: {},
        };
        updateState((draft) => {
            draft.loading = true;
        });
        try {
            getEventServiceInstance().logEvent('publish_status.jury', { candidate_files_ids: updateManyBaseDto.candidateFileIds });
            result = await axios.patch('jury-review/publish', updateManyBaseDto);
            // On met a jour la grid avec les nouvelles valeurs pour tous les avis notifiés avec succès (listPublishedId)
            result?.data?.listPublishedId?.forEach((c) => {
                const index = gridRef.current.getItemIndexBy((item) => item.id === c);
                if (index !== -1) {
                    gridRef.current.setItemPropertyAt(index, 'juryReviewLastPublishedStatus', gridRef.current.getItemAt(index).juryReviewStatus);
                    gridRef.current.setItemPropertyAt(index, 'juryReviewPublicatedAt', new Date());
                }
            });
        } catch (e) {
            // 404
            if (e.response.status === 404) {
                notification.error({
                    message: <Locale.Title tkey="juryReview.publish.error.unexistingReview" />,
                    description: <Locale.Help tkey="juryReview.publish.error.unexistingReview" />,
                    duration: 0,
                });
            } else {
                // Si une erreur imprevue survient durant la publication, on essaie de rechercher dans le message d'erreur le nb de publications envoyees
                // avant que l'erreur survienne (pour affichage dans la popup de fin) et on affiche un popup d'erreur avec le message d'erreur remonté depuis le back
                result = { data: { nbSent: e.response?.data?.nbSent } }
                notification.error({
                    message: <Locale.Title tkey="juryReview.publish.error" />,
                    description: e.response?.data?.message,
                    duration: 0,
                });
            }
        } finally {
            updateState((draft) => {
                draft.loading = false;
            });
        }
        /* En fin de processus, on genere un contenu formaté qui sera affiché dans la popup de signalement de fin de traitement
           Ce rapport contient :
           - le nombre de publicaitons envoyées
           - la liste des publications non envoyées car deja envoyées préalablement (uniquement s'il y en a)
           - la liste des publications non envoyées car l'avis jury ne correspond pas a un template sendGrid pour la formation (uniquement s'il y en a)
        */
        return (
            <div>
                <Locale.Content tkey='nbJuryPublishSent' /> : {result?.data?.nbSent}
                {(result?.data?.listAlreadyPublished?.length > 0) && (
                    <div>
                        <br />
                        <h3 className='h3'><Locale.Header tkey='alreadyPublishList' /></h3>
                        <ul>
                            {(result?.data?.listAlreadyPublished?.map((elem) => {
                                return (<li>{elem}</li>)
                            }))}
                        </ul>
                    </div>
                )}
                {(result?.data?.listUnexistingTemplate?.length > 0) && (
                    <div>
                        <br />
                        <h3 className='h3'><Locale.Header tkey='unexistingTemplateList' /></h3>
                        <ul>
                            {(result?.data?.listUnexistingTemplate?.map((elem) => {
                                return (<li>{elem}</li>)
                            }))}
                        </ul>
                    </div>
                )}
            </div>

        );
    };

    if (selectedCandidateFiles.length > 0) {
        const subItems = [];
        for (const juryReviewStatus of Object.values(JuryReviewStatusEnum)) {
            subItems.push({
                label: <Locale.Button tkey={`${juryReviewStatus}`} />,
                icon: <TableOutlined />,
                disabled: state.loading,
                onClick: (value) => {
                    confirm({
                        // @ts-ignore
                        okButtonProps: { 'data-testid': 'jury-change-status-ok' },
                        // @ts-ignore
                        cancelButtonProps: { 'data-testid': 'jury-change-status-cancel' },
                        title: <Locale.Notification tkey="jury.status.change.confirm" />,
                        icon: <ExclamationCircleOutlined />,
                        content: (
                            <div>
                                <Statistic
                                    title={<Locale.Label tkey="jury.status.change.nbSelected" />}
                                    value={selectedCandidateFiles.length}
                                />
                                <Locale.Notification tkey="jury.status.change.description" />
                            </div>
                        ),
                        onOk: () => onChangeJuryStatus(juryReviewStatus),
                        // change state for selected files
                    });
                },

            });
        }
        // On ne propose le menu de publication des avis jury que si un compte sendGrid (ApiKey presente) est rattaché à la formation
        if (userCtx?.userCtx?.user?.program?.sendgridApiKey) {
            items.push({
                label: <Locale.Button tkey="jury.publishResults" />,
                icon: <TableOutlined />,
                disabled: state.loading,
                onClick: () => {
                    confirm({
                        // @ts-ignore
                        okButtonProps: { 'data-testid': 'jury-publish-results-ok' },
                        // @ts-ignore
                        cancelButtonProps: { 'data-testid': 'jury-publish-results-cancel' },
                        title: <Locale.Notification tkey="jury.publishResults.confirm" />,
                        icon: <ExclamationCircleOutlined />,
                        content: (
                            <div>
                                <Statistic
                                    title={<Locale.Label tkey="jury.publishResults.nbSelected" />}
                                    value={selectedCandidateFiles.length}
                                />
                                <Locale.Notification tkey="jury.publishResults.description" />
                            </div>
                        ),
                        onOk: () => doAction(async () => {
                            const result = await onPublishResults();
                            // Affichage de la popup recapitulative du traitement avec le resultat formaté lors de la réception du bilan depuis le back
                            endPublishJuryReviewPopup("publishJuryReview", result);
                        }),
                        // change state for selected files
                    });
                },
            });
        }
        items.push({
            label: <Locale.Button tkey="jury.status.change" />,
            icon: <TableOutlined />,
            disabled: state.loading,
            items: subItems,
        });
        items.push(
            {
                label: <Locale.Button tkey="candidateFile.XLS" />,
                icon: <TableOutlined />,
                disabled: state.loading,
                onClick: async (value) => {
                    await doAction(async () => {
                        await downloadExport(segment.id, []);
                        endExportPopup('downloadXls');
                    });
                },
            },
        );
    }

    return (
        <StrictLoading loading={loading}>
            <Menu
                {...menuProps}
                disabled={state.loading}
                items={items}
            >
            </Menu>
        </StrictLoading>
    );
}
