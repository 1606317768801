/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { ValidationStep } from '@fstn/ecandidaturev2_api-interfaces';
import { AcademicsListForm } from '../../candidateFile/academicsList/AcademicsListForm';
import { AdditionalDocumentsListForm } from '../../candidateFile/additionalDocumentsList/AdditionalDocumentsListForm';
import { ApprenticeshipCompanyForm } from '../../candidateFile/apprenticeshipCompany/ApprenticeshipCompanyForm';
import { BachelorForm } from '../../candidateFile/bachelor';
import { WithInitialLoad } from '../../../common/withExtraParameters/WithInitialLoad';
import { CompanyExperiencesListForm } from '../../candidateFile/companyExperiencesList/CompanyExperiencesListForm';
import { LanguagesListForm } from '../../candidateFile/languagesList/LanguagesListForm';
import { MotivationForm } from '../../candidateFile/motivation/MotivationForm';
import { ProfileForm } from '../../candidateFile/profile';
import { ProgramsListForm } from '../../candidateFile/programsList/ProgramsListForm';
import { StaysAbroadListForm } from '../../candidateFile/staysAbroadList/StaysAbroadListForm';
import { CandidateCard } from '../../canSeeCandidateFile/CandidateCard';
import { CandidateFileContextProvider } from '../../canSeeCandidateFile/CandidateFileContext';
import { ExaminerPage, FullScreenExaminerPage } from '../ExaminerPage';
import { ExaminerPageReviewPage } from '../ExaminerReviewPage';
import { ValidationsContextProvider } from '../../../context/validations.context';
import { SecretaryPageCandidateFilePDFContainer } from '../../secretary/routes/SecretaryChild1RoutesDef';

/**
 * Routes starting with /home/welcome/secretary/
 * @constructor
 */
export function ExaminerChild1RoutesDef(): any {
  return {
    '/pdf': () => (
      <FullScreenExaminerPage>
        <SecretaryPageCandidateFilePDFContainer />
      </FullScreenExaminerPage>
    ),
    '/:candidateFileId/review': ({ candidateFileId }: any) => (
      <WithInitialLoad
        entity="candidate-file"
        entityId={candidateFileId}
        propsName="selectedCandidateFile"
      >
        <CandidateFileContextProvider>
          <WithInitialLoad
            entity={`examiner-review?s={"candidateFile.id":"${encodeURIComponent(candidateFileId)}"}`}
            propsName="examinerReview"
          >
            <ExaminerPageReviewPage />
          </WithInitialLoad>
        </CandidateFileContextProvider>
      </WithInitialLoad>
    ),
    '/:candidateFileId/*': ({ candidateFileId }: any) => (<FullCandidateFileForm candidateFileId={candidateFileId} />),
    '/:candidateFileId': ({ candidateFileId }: any) => (<FullCandidateFileForm candidateFileId={candidateFileId} />),
  };
}

/**
 * Full Candidate File Form
 * @param candidateFileId
 * @constructor
 */
export function FullCandidateFileForm({ candidateFileId }: { candidateFileId: string }) {
  return (
    <WithInitialLoad
      entity="candidate-file"
      entityId={candidateFileId}
      propsName="selectedCandidateFile"
    >
      <CandidateFileContextProvider>
        <ExaminerPage>
          <div css={css`position:relative; height: 100%;`}>
            <div css={css``}>
              <CandidateCard />
            </div>
            <div css={css`max-height: calc( 100% - 80px); overflow: scroll`}>
              <ValidationsContextProvider steps={[ValidationStep.SUBMIT]} entity="candidate-file">
                <ProgramsListForm layout="horizontal" />
                <ProfileForm layout="horizontal" />
                <BachelorForm layout="horizontal" />
                <AcademicsListForm layout="horizontal" />
                <LanguagesListForm layout="horizontal" />
                <StaysAbroadListForm layout="horizontal" />
                <CompanyExperiencesListForm layout="horizontal" />
                <MotivationForm layout="horizontal" />
                <ApprenticeshipCompanyForm layout="horizontal" />
                <AdditionalDocumentsListForm layout="horizontal" />
              </ValidationsContextProvider>
            </div>
          </div>
        </ExaminerPage>
      </CandidateFileContextProvider>
    </WithInitialLoad>
  );
}
