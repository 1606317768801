/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useContext } from 'react';
import { UserContext, UserContextType } from '../../context/user.context';

export function UserCard() {
    const { userCtx } = useContext<UserContextType>(UserContext);
    return (

      <article
        id="candidate-card"
        className="message  is-small"
        css={css`
   padding: 0.5em;
    display: flex;
    justify-content: center;
    gap: 1em;
    align-items: center;
    flex-wrap: wrap;
    .message-body{
        padding-left: 0em;
        padding-right: 0em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border: none;
    }
`}
      >
        <div className="message-body">
          <div>{userCtx?.user?.firstName}</div>
          <div>{userCtx?.user?.lastName}</div>
        </div>
      </article>
    );
}
