/** @jsx jsx */
import {ISecretary} from '@fstn/ecandidaturev2_api-interfaces';
import {Fragment} from 'react';
import {jsx} from '@emotion/core';
import * as Locale from '../../../../common/locale';

export const secretaryLockedByColumn = {
    header: <Locale.Header tkey="candidateFilesList.header.secretaryLockedBy" />,
    name: 'secretaryLockedBy',
    id: 'secretaryLockedBy',
    defaultVisible: false,
    lockable: false,
    defaultWidth: 140,
    render: ({ value, data }: { value?: ISecretary, data: any }) => (
      <Fragment key={`firstName_cell_${data.id}`}>
        {`${value?.firstName || '-'} ${value?.lastName || '-'}`}
      </Fragment>
    ),
};

export const secretaryLockedByFilter = {
    name: 'secretaryLockedBy', operator: 'startsWith', type: 'string', value: '',
};
