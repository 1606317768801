/** @jsx jsx */
import { jsx } from '@emotion/core';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import * as Locale from '../../../../common/locale';

export const shortIdColumn = {
    header: <Locale.Header tkey="candidateFilesList.shortId" />,
    name: 'shortId',
    id: 'shortId',
    defaultWidth: 70,
    style: { textAlign: 'right' },
    filterEditor: NumberFilter,
    defaultLocked: 'start',
};

export const shortIdFilter = {
        name: 'shortId', operator: 'eq', type: 'number', value: '',
    };
