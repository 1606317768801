import { AppConfigDto } from '@fstn/ecandidaturev2_api-interfaces';
import Modal from 'antd/lib/modal/Modal';
import { usePath } from 'hookrouter';
import moment from 'moment';
import React, { Fragment, useContext } from 'react';
import * as Locale from '../../../common/locale';
import { UserContext } from '../../../context/user.context';
import { ROUTES } from '../UserRoutesDef';
import { UserRoleEnum, CandidateFileStatusEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { PositiveActionButton } from '../../../common/button/PositiveActionButton';
import { sNavigate } from '../../../utils/safeNavigation';
import { MessageFilled } from '@ant-design/icons';
import { css } from '@emotion/core';

export function IsNotNewCandidateFile(props: { children: any, elseChildren?: any }) {
  const { userCtx } = useContext(UserContext);
  if (userCtx.user?.role?.name === UserRoleEnum.CANDIDATE && userCtx.user?.candidateFile?.status.toString() !== CandidateFileStatusEnum.NEW) {
    return (
      <Fragment>
        {props.children}
      </Fragment>
    );
  }
  else return <Fragment />;
}

export function OpenCloseModale(props: { appConfig: AppConfigDto }) {
  const path = usePath();
  const { userCtx } = useContext(UserContext);
  /* Pour les candidats, on laisse l'accès à la home page (qui affichera le chat si la periode d'ouverture est close) 
     et aux formations visées (sauf si le dossier est NEW, auquel cas on ferme l'acces) et bien sur au chat */
  if (moment().isBetween(props.appConfig.open, props.appConfig.close)
    || ((path === `${ROUTES.CANDIDATE_BASE}/home`
      || path === `${ROUTES.CANDIDATE_BASE}/programs-list`
      || path === `${ROUTES.CANDIDATE_BASE}/chat`)
      && userCtx.user?.role?.name === UserRoleEnum.CANDIDATE && userCtx.user?.candidateFile?.status.toString() !== CandidateFileStatusEnum.NEW)) {
    return <Fragment />;
  }

  /* Donc la popup de fermeture de la plateforme n'est affichée que pour les autres roles, et sur les autres pages du candidat 
     dans le cas ou la date d'ouverture du role connecte est close */
  return (
    <Modal
      visible
      title={<Locale.Title tkey={moment().isAfter(props.appConfig.open) ? 'app.closed' : 'app.notOpenedYet'} />}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      zIndex={2000}
    >
      <Locale.Content tkey={moment()?.isAfter(props.appConfig.open) ? 'app.closed' : 'app.notOpenedYet'} />
      <IsNotNewCandidateFile>
        <div className="message-header" >
          <PositiveActionButton tkey="retourChat"
            icon={(
              <MessageFilled style={{ fontSize: '20px' }} css={css`color:white;`} />
            )}
            onClick={() => sNavigate(`${ROUTES.CANDIDATE_BASE}/chat`)}
          />
        </div>
      </IsNotNewCandidateFile>
    </Modal>
  );
}
