import React, { useContext } from 'react';
import { UserContext } from '../../context/user.context';
import { useScopedSelector } from '../../hooks/use-scoped-selector';
import { EntityFormContext } from '../../context/entityForm.context';
import { getDisabledForWrite } from '../../utils/formDisableStateManager';
import { DisabledContextProvider } from './DisabledContext';

/**
 * Disabled Context provider to use in entity form
 * @constructor
 */
export function DisabledContextProviderForEntityForm(props:{ children?:any }) {
    //console.log('Redraw DisabledContextProviderForEntityForm');
    const userCtx = useContext(UserContext);
    const disabled = useScopedSelector<any[], boolean>('EntityFormItemContainer', EntityFormContext, (c) => getDisabledForWrite(userCtx.userCtx, c[0]?.entity, c[0]?.entityValue?.rights));
    return (

      <DisabledContextProvider value={{
            disabled,
            wrapAuthorized: false,
            context: 'EntityFormContainer',
        }}
      >
        {props?.children}
      </DisabledContextProvider>
    );
}
