/** @jsx jsx */
import {jsx} from '@emotion/core';
import {CandidateFileStatusEnum} from '@fstn/ecandidaturev2_api-interfaces';
import {Fragment, useContext} from 'react';
// @ts-ignore
import {sNavigate} from '../../utils/safeNavigation';
import {CandidateFileContext, CandidateFileContextProviderType} from '../canSeeCandidateFile/CandidateFileContext';
// @ts-ignore
import {ROUTES} from '../user/UserRoutesDef';

export function OnlyVisibleIfCandidateFileIsOpenForCandidate(props: { children }) {
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    if (candidateFileContext.selectedCandidateFile?.status.id !== CandidateFileStatusEnum.NEW
         && candidateFileContext.selectedCandidateFile?.status.id !== CandidateFileStatusEnum.WAITING_CANDIDATE) {
        sNavigate(`${ROUTES.CANDIDATE_BASE}/home`);
        return <Fragment />;
    }
    return props.children;
}
