import styled from '@react-pdf/styled-components';
import React, { ReactNode } from 'react';

const Container = styled.Text`
font-size: 10px;
font-weight: 800;
`;

export function PDFLabel(props: { debug?: boolean, children?: ReactNode }) {
    return (
      <Container debug={props.debug}>
        {`${props.children}:`}
      </Container>
    );
}
