/** @jsx jsx */
import { jsx } from '@emotion/core';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/blue-light.css';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// noinspection ES6UnusedImports
import styled from '@emotion/styled';

import React, { Fragment, useMemo } from 'react';
import './App.css';
import Bugsnag from '@bugsnag/js';
import { WithAddRoutesProps } from './common/withExtraParameters/WithAddRoutesProps';
import { LoginPage } from './pages/anonymous/LoginPage';
import { usePathExtractor } from './hooks/use-path-extractor.hook';

function IApp(props:{ routes?:any }) {
    const Style = styled.div`
  height: 100%;
  width: 100%;
`;
    const { partPath } = usePathExtractor(1);
    // console.log('Redraw App');
    return useMemo(() => (
      <Style>
        <div data-testid="App" className="App">
          <WithAddRoutesProps>
            {(routes) => (
              <Fragment>{routes || <LoginPage />}</Fragment>
                        )}
          </WithAddRoutesProps>
        </div>
      </Style>
        ), [partPath]);
}
export const App = IApp;
