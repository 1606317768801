/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { IIdiom, } from '@fstn/ecandidaturev2_api-interfaces';
import { Card } from 'antd';
import React, { Fragment } from 'react';
import { Box, Flex } from 'rebass';
import { useDeepCompareCallback } from 'use-deep-compare';
import UUID from 'uuid-js';

import { ColdDataSelectorFormItem } from '../../../common/form/inputFormItems/ColdDataSelectorFormItem';
import { DatePickerFormItem } from '../../../common/form/inputFormItems/DatePickerFormItem';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import { UploadFormItemContainer } from '../../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import * as Locale from '../../../common/locale';
import { EntityFormContext } from '../../../context/entityForm.context';
import { EntityFormListContext } from '../../../context/entityFormList.context';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { useSeparator } from '../../../hooks/use-separator.hook';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

export function LanguageForm() {
  const responsive = useResponsiveForm();
  useSeparator();

  const getFieldValue = useScopedSelector<any[], any>('LanguageForm', EntityFormContext, (c) => c[0]?.getFieldValue);
  const formListEntityId = useScopedSelector<any[], string>('LanguageForm', EntityFormListContext, (c) => c[0]?.entityValue?.id);
  const formListEntityIdiom = useScopedSelector<any[], IIdiom>('LanguageForm', EntityFormListContext, (c) => c[0]?.entityValue?.idiom);
  const formListEntity = useScopedSelector<any[], string>('LanguageForm', EntityFormListContext, (c) => c[0]?.entity);
  const field = useScopedSelector<any[], any>('LanguageForm', EntityFormListContext, (c) => c[0].field);

  const addDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: `${formListEntityIdiom?.name}_certificateDocument`,
    type: 'certificateDocument',
    parentType: formListEntity,
    parentId: formListEntityId,
  }), [formListEntityId, formListEntity]);

  return (
    <Card
      id={formListEntityId}
      key={formListEntityId}
      title={<Fragment>{getFieldValue(['languages', field.name, 'idiom'])?.name || <Locale.Title tkey="languages" />}</Fragment>}
      css={css``}
    >
      <Flex name="languages" flexWrap="wrap">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Box {...responsive.w1}>
          <ColdDataSelectorFormItem
            api="idioms"
            // disabled
            hidden
            propertyName={['languages', field.name, 'idiom']}
          />
          <ColdDataSelectorFormItem
            api="language-levels"
            propertyName={['languages', field.name, 'level']}
          />
          <InputFormItem propertyName={['languages', field.name, 'certification']} />
          <InputFormItem propertyName={['languages', field.name, 'score']} />
          <DatePickerFormItem propertyName={['languages', field.name, 'date']} />
          <UploadFormItemContainer
            propertyName={['languages', field.name, 'certificateDocument']}
            factory={addDocument}
            accept={'image/*,.pdf'}
          />
        </Box>
      </Flex>
    </Card>
  );
}
