/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Card, Form, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useContext } from 'react';
import { TextOkButton } from '../../../common/button/TextOkButton';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import { InputNumberFormItem } from '../../../common/form/inputFormItems/InputNumberFormItem';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { EntityFormContextProvider } from '../../../context/entityForm.context';
import { EntityFormListContextProvider } from '../../../context/entityFormList.context';
import { LOGIN } from '../../user/UserRoutesDef';
import { sNavigate } from '../../../utils/safeNavigation';
import { DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { ButtonsFormItemLayout } from '../../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import { useLoading } from '../../../hooks/use-loading';
import { DisabledContextProviderAlwaysEnabled } from '../../../common/rights/DisabledContextProviderAlwaysEnabled';
import * as Locale from "../../../common/locale";
import { Box } from "rebass";
import { useResponsiveForm } from "../../../hooks/use-responsive-form.hook";
import { TextDangerButton } from '../../../common/button/TextDangerButton';
import { useImmer } from 'use-immer';

export function RegisterCodeForm(props: { email: string }) {
  const [form] = useForm();
  const { doAction, loading } = useLoading();
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  const responsive = useResponsiveForm();
  const [state, updateState] = useImmer({ codeExpirated: false });
  
  const onFinish = async (values) => doAction(async () => {
    try {
      await axios.post('/register-code', values);
      sNavigate(LOGIN.REGISTER.replace(':email', values.email).replace(':code', values.code));
    } catch (e) {
      if (e?.response?.status === 401) {
        notification.error({
          message: <Locale.Title tkey="register-code.invalid" />,
          description: <Locale.Help tkey="register-code.invalid" />,
        });
      } else if (e?.response?.status === 403) {
        updateState((draft) => {
            draft.codeExpirated = true;
        })
        notification.error({
            message: <Locale.Title tkey="forgot-password-code.expired" />,
            description: <Locale.Help tkey="forgot-password-code.expired" />,
        });
      } else {
        notification.warn({
          message: <Locale.Title tkey="server.error" />,
          description: <Locale.Help tkey="server.error" />,
        });
      }
      throw e;
    }
  });

  return (
    <Card css={css`max-width: 700px; margin: auto`}>
      <DisabledContextProviderAlwaysEnabled>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          noValidate
        >
          <EntityFormContextProvider
            entity="registrer"
            isFieldTouched={form.isFieldTouched}
            getFieldValue={form.getFieldValue}
            getFieldsValue={form.getFieldsValue}
            onChange={() => undefined}
            onImmediateChange={() => undefined}
          >
            <EntityFormListContextProvider
              entity="registrer"
              propertyName=""
              remove={() => {
              }}
            >

              <FormContentPanelContainer partName="register.code">
                <Box {...responsive.w1}>
                  <DisabledContextProvider value={{ disabled: true, wrapAuthorized: true }}>
                    <InputFormItem
                      propertyName="email"
                      initialValue={props.email}
                      labelCol={{ span: 24 }}
                      required
                      wrapperCol={{ span: 24 }}
                    />
                  </DisabledContextProvider>
                </Box>
                <Box {...responsive.w1}>
                  <InputNumberFormItem
                    propertyName="code"
                    rules={[{ required: true }]}
                    labelCol={{ span: 24 }}
                    required
                    wrapperCol={{ span: 24 }}
                  />
                  <br />
                </Box>
                {state.codeExpirated && (
                    <TextDangerButton 
                      tkey="backRegister"
                      onClick={() => sNavigate(`${LOGIN.REGISTER_EMAIL}`)}
                    />
                  )}
                <ButtonsFormItemLayout>
                  <TextOkButton
                    tkey="code"
                    data-testid="submit.register-code"
                    loading={loading}
                    htmlType="submit"
                  />
                </ButtonsFormItemLayout>
              </FormContentPanelContainer>
            </EntityFormListContextProvider>
          </EntityFormContextProvider>
        </Form>
      </DisabledContextProviderAlwaysEnabled>
    </Card>
  );
}
