import React, { useCallback, useMemo, useState } from 'react';
import { InlineLoading, SmartLoading, StrictLoading } from '../common/indicator/SmartLoading';

export function useLoading(defaultLoad?: boolean) {
    const [loading, setLoading] = useState(defaultLoad);

    const doAction = useCallback(async (action) => {
        if (!loading) {
            setLoading(true);
        }
        try {
            const v = await action();
            return v;
        } catch (e) {
            console.error(e);
            throw e;
        } finally {
                setLoading(false);
        }
    }, [loading]);

    const StrictLoadingContainer = useMemo(() => (props: any) => (
      <StrictLoading
        {...props}
        loading={loading}
      >
        {props.children}
      </StrictLoading>
), [loading]);
    const LoadingContainer = useMemo(() => (props: any) => (
      <SmartLoading
        {...props}
        loading={loading}
      >
        {props.children}
      </SmartLoading>
), [loading]);
    const InlineLoadingContainer = useMemo(() => (props: any) => (
      <InlineLoading
        {...props}
        loading={loading}
      >
        {props.children}
      </InlineLoading>
    ), [loading]);
    return {
        doAction, StrictLoadingContainer, LoadingContainer, InlineLoadingContainer, loading,
    };
}
