import { notification } from 'antd';
import React from 'react';
import * as Locale from '../common/locale';

export function handleError(formName: string, e) {
    if (e?.response?.status === 401 || e?.response?.status === 403) {
        // Ces 2 erreurs  sont liées à des erreurs de saisie de code de verification et sont traitées à part, donc pas de notification
        // ici (qui aurait fait double emploi avec 2 notifications envoyees a l'utilisateur)
        return;
    }
    if (e?.response?.status < 500) {
        notification.warn({
            message: <Locale.Title tkey={`${formName}.invalid`} />,
            description: <Locale.Help
              tkey={Array.isArray(e.response?.data?.message) ? e.response?.data?.message?.[0] : e.response?.data?.message}
            />,
        });
    } else {
        notification.warn({
            message: <Locale.Title tkey="server.error" />,
            description: <Locale.Help tkey="server.error" />,
        });
    }
}
