/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// noinspection ES6UnusedImports
import { jsx } from '@emotion/core';
import { IProgram, walkEntityPropertyAndMapDateStrAsMoment } from '@fstn/ecandidaturev2_api-interfaces';
import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import { notification } from 'antd';
import { parse, stringify } from 'flatted';
import _ from 'lodash';
import moment from 'moment';
import React, { useRef } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import { useEntity } from '../../../hooks/use-entity';
import { useLoading } from '../../../hooks/use-loading';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { ProgramModalList } from './ProgramModalList';
import { getTheme } from './utils';
import { getEventServiceInstance } from '../../../utils/eventService';

const entity = 'public/programs';

const mapToRow = (d: IProgram) => {
    const row: any = {
        ...d,
    };
    return row;
};

export function ProgramModalListContainer(props: {
    value?: IProgram[],
    onChange?: Function,
    hiddenPrograms?: IProgram[]
}) {
    // @ts-ignore
    const [state, updateState] = useImmer({
        search: [],
        allPrograms: [],
        filteredPrograms: [],
        selectedProgramsInModal: [],
        programsListPrograms: props.value,
        filters: {} as any,
    });

    const { t } = useSafeTranslation();
    const gridRef = useRef({ deselectAll: () => {} } as any);
    const hiddenProgramsName = props.hiddenPrograms.map((hp) => hp?.name);
    const { doAction, StrictLoadingContainer } = useLoading(true);
    const { loadEntities } = useEntity<any>();

    useDeepCompareEffect(() => {
        const fetchData = () => doAction(async () => {
            updateState((draft: any) => {
                draft.filters = {};
            });

            const entitiesValue = parse(stringify(await loadEntities(entity)));
            entitiesValue?.forEach?.((d) => walkEntityPropertyAndMapDateStrAsMoment(d));
            const programsNotAlreadySelected = _.orderBy(entitiesValue.filter((p) => !hiddenProgramsName.includes(p.name))
                .filter((p) => p.opened), 'homePosition');

            updateState((draft: any) => {
                draft.filters = {};
                draft.allPrograms = programsNotAlreadySelected;
                draft.filteredPrograms = (programsNotAlreadySelected).map((d: IProgram) => mapToRow(d));
            });
        });
        gridRef.current?.deselectAll?.();
        fetchData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hiddenProgramsName]);

    const onFilterSchool = (value) => {
        getEventServiceInstance().logEvent('programs_list.school_filter', { filters: value });
        gridRef.current?.deselectAll();
        updateState((draft) => {
            draft.filteredPrograms = state.allPrograms.filter(
                (e) => (!value || value.length === 0 || value.indexOf(e.school) !== -1)
                    && (!state.filters.theme || state.filters.theme?.length === 0 || e.theme?.split(',').some((t) => state.filters.theme.indexOf(getTheme(t)) !== -1))
                    && (!state.filters.requiredLevel || state.filters.requiredLevel?.length === 0 || state.filters.requiredLevel.indexOf(e.requiredLevel) !== -1)
                    && (!state.filters.status || state.filters.status?.length === 0 || state.filters.status.indexOf(e.status) !== -1),
            );
            draft.filters.school = value;
        });
};

    const onFilterThemes = (value) => {
        getEventServiceInstance().logEvent('programs_list.theme_filter', { filters: value });
        gridRef.current?.deselectAll();
        updateState((draft) => {
            draft.filteredPrograms = state.allPrograms.filter(
                (e) => (!value || value?.length === 0 || e.theme?.split(',').some((t) => value.indexOf(getTheme(t)) !== -1))
                    && (!state.filters.school || state.filters.school?.length === 0 || state.filters.school.indexOf(e.school) !== -1)
                    && (!state.filters.requiredLevel || state.filters.requiredLevel?.length === 0 || state.filters.requiredLevel.indexOf(e.requiredLevel) !== -1)
                    && (!state.filters.status || state.filters.status?.length === 0 || state.filters.status.indexOf(e.status) !== -1),
            );
            draft.filters.theme = value;
        });
    };

    function onSelectionChange(elt: TypeOnSelectionChangeArg) {
        const selected: IProgram[] = Object.values(elt.selected === true ? elt.data : elt.selected || {});
        // extract duplicated programs
        const duplicated = _(selected.filter((s) => s.optionName)
            .map((s) => `${s.school} ${s.name}`))
            .groupBy()
            .pickBy((x) => x?.length > 1)
            .keys()
            .value();

        if (duplicated?.length > 0) {
            notification.warning({
                message: t('program.option.duplicated'),
                description: duplicated,
                onClose: () => {
                    localStorage.removeItem('user');
                },
            });
            gridRef?.current?.deselectAll?.();
            return false;
        }
        if (!_.isEqual(state.selectedProgramsInModal, selected)) {
            updateState((draft) => {
                draft.selectedProgramsInModal = selected;
            });
            props.onChange(selected);
        }
        return true;
    }

   // console.log('Redraw ProgramModalListContainer');
    return (
      <StrictLoadingContainer>
        <ProgramModalList
          filteredPrograms={state.filteredPrograms}
          selectedProgramsInModal={state.selectedProgramsInModal}
          allPrograms={state.allPrograms || []}
          onSelectionChange={(elt) => onSelectionChange(elt)}
          gridRef={gridRef}
          onFilterSchool={onFilterSchool}
          onFilterThemes={onFilterThemes}
        />
      </StrictLoadingContainer>
    );
}

// @ts-ignore
window.moment = moment;

/**
 * Return selected candidate files
 * @param allPrograms
 * @param selectedAdmin
 */
export function getSelectedAdmin(allPrograms: any, selectedAdmin: { id: string }[]) {
    if (selectedAdmin && selectedAdmin?.length > 0) {
        return selectedAdmin;
    }
    return [allPrograms];
}
