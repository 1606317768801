import { IDocument } from '@fstn/ecandidaturev2_api-interfaces';
import { CandidateFileForExport } from './export.type';

export function getDocument(candidateFile:CandidateFileForExport) {
    const doc = {
        id: (candidateFile).Profile?.avatarDocument?.[0]?.id,
        parentType: (candidateFile).Profile?.avatarDocument?.[0]?.parent_type,
        parentId: (candidateFile).Profile?.avatarDocument?.[0]?.parent_id,
        type: (candidateFile).Profile?.avatarDocument?.[0]?.type,
        candidateFile: { id: (candidateFile).Profile?.avatarDocument?.[0]?.candidate_file },
    } as IDocument;
    return doc;
}

export const toDataURL = (url) => fetch(url)
    .then((response) => response.blob())
    .then((blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    }));
