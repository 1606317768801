import React, { Fragment, useContext } from 'react';
import { useAppConfig } from '../../../hooks/use-app-config';
import { IsNotWaitingCandidate } from '../conditionnal/IsNotWaitingCandidate';
import { AfterLoginMessagesPage } from '../modales/AfterLoginMessagesPage';
import { OpenCloseModale } from '../modales/OpenCloseDatePage';
import { StrictLoading } from '../../../common/indicator/SmartLoading';
import { MaintenanceModale } from '../modales/MaintenanceDatePage';
import { IsSuperSecretary } from '../conditionnal/IsSuperSecretary';
import { IsAdmin } from '../conditionnal/IsAdmin';
import { UserContext } from '../../../context/user.context';

/**
 * AppConfigPageSwitcherContainer: displays modals depending of user app config
 * For example if app is closed it will open closModale
 * @See useAppConfig
 * @param children
 * @constructor
 */
export function AppConfigPageSwitcherContainer({ children }: any) {
  // console.log('Redraw AppConfigPageSwitcherContainer');
  const { userCtx } = useContext(UserContext);
  const { appConfig, load } = useAppConfig(userCtx?.user?.role?.name);  // On passe le role de la personne connectee pour enregistrer dans le appConfig
  if (!appConfig) {
    load();
    return <StrictLoading loading context="AppConfigPageSwitcherContainer"><Fragment /></StrictLoading>;
  }
  // Si dans le appConfig courant, ce n'est pas le meme role, c'est qu'on a pas les bonnes valeurs d'ouverture/fermeture application 
  // (changement de role ou page d'accueil "anonyme" puis acces au site avec le role lie a la connexion)
  // Donc on les recharge
  if (userCtx?.user?.role?.name !== appConfig.role) {
    load();
  }

  return (
    <Fragment>
      <AfterLoginMessagesPage appConfig={appConfig} />
      <IsNotWaitingCandidate>
        <IsAdmin elseChildren={<OpenCloseModale appConfig={appConfig} />} />
      </IsNotWaitingCandidate>
      <IsSuperSecretary elseChildren={<MaintenanceModale appConfig={appConfig} />} />
      {children}
    </Fragment>
  );
}
