/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { ISpecializedSecretaryReview, UserRoleEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { Divider } from 'antd';
import React, {
  Fragment, useContext, useEffect, useState,
} from 'react';

import { useDeepCompareCallback } from 'use-deep-compare';
import UUID from 'uuid-js';
import { EntityFormContainer } from '../../common/form/entityForm/EntityFormContainer';
import { InputTextAreaWithSubmitFormItem } from '../../common/form/inputFormItems/InputTextAreaWithSubmitFormItem';
import { UploadFormItemContainer } from '../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import { UserContext, UserContextType } from '../../context/user.context';
import { useEntity } from '../../hooks/use-entity';
import { DisabledContextProvider } from '../../common/rights/DisabledContext';

const Style = styled.div`
`;

export function SpecializedSecretaryReviewFormItem(
  props: { review: ISpecializedSecretaryReview },
) {
  const { patchEntity } = useEntity<any>();
  const addDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'specializedSecretaryReviewDocument',
    type: 'specializedSecretaryReviewDocument',
    parentType: 'specialized-secretary-review',
    parentId: props.review?.id,
  }), [props.review]);
  // Cette fonction, appelée quand le champ de saisie de commentaire perd le focus, met a jour le commentaire avec la valeur dans le textArea
  const updateComment = async (commentModified) => {
    await patchEntity('specialized-secretary-review', { id: props.review?.id }, { comments: commentModified });
  };
  return (
    <Fragment>
      <InputTextAreaWithSubmitFormItem
        propertyName="comments"
        allowClear
        rows={25}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        onBlur={updateComment}
      />
      <Divider />
      <UploadFormItemContainer
        accept={'image/*,.pdf'}
        propertyName="doc"
        factory={addDocument}
      />
    </Fragment>
  );
}

export function SpecializedSecretaryReviewFormContainer(props: {}) {
  const { userCtx } = useContext<UserContextType>(UserContext);
  const programId = (userCtx.user as any)?.program?.id;
  const { loadEntity } = useEntity<ISpecializedSecretaryReview>();
  const [review, setReview] = useState(undefined);

  useEffect(() => {
    async function load() {
      setReview(await loadEntity('specialized-secretary-review/byProgramId', programId));
    }
    load().then();
  }, [loadEntity, programId]);

  return (
    <Style>
      <EntityFormContainer
        entity="specialized-secretary-review"
        initialValues={{ ...review }}
        noValidate
      >
        <DisabledContextProvider value={{
          disabled: userCtx.user.role.name !== UserRoleEnum.SPECIALIZED_SECRETARY,
          wrapAuthorized: true,
          context: 'RightMenuContainer',
        }}
        >
          <SpecializedSecretaryReviewFormItem
            review={review}
          />
        </DisabledContextProvider>
      </EntityFormContainer>
    </Style>
  );
}
