import { useContext } from 'react';
import FileDownload from 'js-file-download';
import { AxiosContext, AxiosContextType } from '../context/axios.context';

// @ts-ignore

export function useExportUtils() {
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    async function downloadExport(segmentId, groups) {
        const response = await axios.put('export/candidate-files/xls', {
            groups: groups,

        }, {
            responseType: 'blob',
            params: { segmentId },
        });
        const dateFormated = new Date().toLocaleString();
        await FileDownload(response.data, `export-${dateFormated}.xlsx`);
    }
    return { downloadExport };
}
