import {
    AppConfigDto,
    IUser,
    IVersionedBaseStringId,
    IVersionedBaseWithSecretaryReview,
} from '@fstn/ecandidaturev2_api-interfaces';
import { parse, stringify } from 'flatted';
import { useCallback, useContext } from 'react';
import { DeepPartial } from 'redux';
import { AxiosContext, AxiosContextType } from '../context/axios.context';
import {
    CandidateFileContext,
    CandidateFileContextProviderType,
} from '../pages/canSeeCandidateFile/CandidateFileContext';

/**
 * Use to load entity for all the pages
 */
export function useEntity<T extends DeepPartial<IUser> | DeepPartial<IVersionedBaseStringId> | AppConfigDto | DeepPartial<IVersionedBaseWithSecretaryReview> | { commentsBtwSecretaryAndCandidate: any }>() {
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    const { axios, getAxiosWithCache } = useContext<AxiosContextType>(AxiosContext);

    const candidateFileId = candidateFileContext?.selectedCandidateFile?.id;
    const ready = !!candidateFileId;
    const loadEntity = useCallback(
        async (entity: string, entityId?: string | number) => {
            const params: any = {};
            if (candidateFileId && entity !== 'candidate-file') {
                params.candidateFileId = candidateFileId;
            }

            entityId = entityId ? `/${entityId}` : '';
            const res = await axios.get(`/${entity}${entityId}`, { params, forceUpdate: true });
            let data = parse(stringify(res?.data)) as any;
            if (Array.isArray(data)) {
                data = data[0];
            }
            return data;
        }, [axios, candidateFileId],
    );

    async function loadEntities(entity: string, cache: number = 0) {
        console.log('loadEntities', entity);
        const params: any = {};
        if (candidateFileContext?.selectedCandidateFile?.id) {
            params.candidateFileId = candidateFileContext.selectedCandidateFile?.id;
        }

        const res = cache ? await getAxiosWithCache(cache).get(`/${entity}`, { params })
            : await axios.get(`/${entity}`, { params });
        const data = parse(stringify(res?.data)) as any;
        // const data = res?.data as any;
        return data;
    }

    /**
     *
     * @param entity
     * @param allValues
     */
    async function saveEntity(entity: string, allValues: T) {
        const params: any = {};
        if (candidateFileContext) {
            params.candidateFileId = candidateFileContext.selectedCandidateFile?.id;
        }
        return axios.post(`/${entity}`, allValues, { params });
    }

    /**
     *
     * @param entity
     * @param allValues
     */
    async function deleteEntity(entity: string, allValues: T & { id: string }) {
        const params: any = {};
        if (candidateFileContext) {
            params.candidateFileId = candidateFileContext.selectedCandidateFile?.id;
        }

        let url = `/${entity}`;
        if (allValues?.id) {
            // Petite securite : si l'id contient un "/" (cela arrive avec les traffic_source !), il faut l'encoder correctement pour l'url de patch
            const regex = /\//g;
            url = `${url}/${allValues.id.toString().replace(regex, '%2F')}`;
        }
        const res = await axios.delete(url, { params });
        return res;
    }

    async function patchEntity(entity: string, allValues: Partial<T> & { id: string }, changedValue: Partial<T>) {
        const params: any = {};
        if (candidateFileContext) {
            params.candidateFileId = candidateFileContext.selectedCandidateFile?.id;
        }

        let url = `/${entity}`;
        if (allValues?.id) {
            // Petite securite : si l'id contient un "/" (cela arrive avec les traffic_source !), il faut l'encoder correctement pour l'url de patch
            const regex = /\//g;
            url = `${url}/${allValues.id.toString().replace(regex, '%2F')}`;
        }
        const res = await axios.patch(url, changedValue, { params });
        const data = res?.data;
        return data;
    }

    async function putEntity(entity: string, allValues: Partial<T> & { id: string }, changedValue: Partial<T>) {
        const params: any = {};
        if (candidateFileContext) {
            params.candidateFileId = candidateFileContext.selectedCandidateFile?.id;
        }

        let url = `/${entity}`;
        if (allValues?.id) {
            // Petite securite : si l'id contient un "/" (cela arrive avec les traffic_source !), il faut l'encoder correctement pour l'url
            const regex = /\//g;
            url = `${url}/${allValues.id.toString().replace(regex, '%2F')}`;
        }
        const res = await axios.put(url, changedValue, { params });
        const data = res?.data;
        return data;
    }

    async function postEntity(entity: string, allValues: T, changedValue: Partial<T>) {
        // @ts-ignore
        if (!allValues.id) {
            return undefined;
        }
        const params: any = {};
        if (candidateFileContext) {
            params.candidateFileId = candidateFileContext.selectedCandidateFile?.id;
        }

        // Petite securite : si l'id contient un "/" (cela arrive avec les traffic_source !), il faut l'encoder correctement pour l'url
        const regex = /\//g;
        // @ts-ignore
        const res = await axios.post(`/${entity}/${allValues.id.toString().replace(regex, '%2F')}`, changedValue, { params });
        const data = res?.data;
        return data;
    }

    return {
        loadEntity, loadEntities, saveEntity, deleteEntity, patchEntity, postEntity, putEntity, ready,
    };
}
