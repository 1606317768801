import React from 'react';
import { ROUTES } from '../user/UserRoutesDef';
import { ProgramManagerContainer } from './ProgramManagerContainer';

export const ProgramManagerRoutesDef = (t) => ({

    [`${ROUTES.PROGRAM_MANAGER_BASE}*`]: () => (
        <ProgramManagerContainer />
    ), [ROUTES.BASE]: () => <ProgramManagerContainer />,
});
