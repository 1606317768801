import React, { Fragment, useContext } from 'react';
import {
  Badge,
  Button, Collapse, Space, Table,
} from 'antd';
import Column from 'antd/lib/table/Column';
import _ from 'lodash';
import { Loading } from '../../../../common/indicator/Loading';
import { AxiosContext, AxiosContextType } from '../../../../context/axios.context';
import { useUser } from '../../../../hooks/use-user.hook';
import { UserContext, UserContextType } from '../../../../context/user.context';

export function PaymentCheckerPage(props: { importResult: any }) {
  const userCtx = useContext<UserContextType>(UserContext);
  const { loadMe } = useUser(userCtx);
  const { axios, updateToken } = useContext<AxiosContextType>(AxiosContext);
  const { importResult } = props;
  if (!importResult) {
    return <Loading />;
  }
  return (
    <Fragment>
      <Collapse>
        <Collapse.Panel
          header={(
            <Fragment>
              <Badge count={_.get(importResult, ['missingInSchool', 'length'])}>
                <div style={{ marginRight: '1em' }}>Paiements présents à la banque mais pas dans la plateforme</div>
              </Badge>
            </Fragment>
          )}
          key={1}
        >
          {importResult.missingInSchool && (
            <Fragment>
              <Table dataSource={importResult.missingInSchool}>
                <Column title="Email" dataIndex="email" key="email" />
                <Column title="Nombre de paiement en succés" dataIndex="payed" key="payed" />
                <Column title="Nombre de paiement sur l'interface bancaire" dataIndex="payedInBank" key="payedInBank" />
                <Column
                  title="Action"
                  key="action"
                  render={(text, p: any) => (
                    <Space size="middle">
                      <Button onClick={() => impersonate(p.email)}>Vérifier le dossier</Button>
                    </Space>
                  )}
                />
              </Table>
            </Fragment>
          )}
        </Collapse.Panel>
        <Collapse.Panel
          header={(
            <Fragment>
              <Badge count={_.get(importResult, ['notExistingCandidate', 'length'])}>
                <div style={{ marginRight: '1em' }}>Dossiers présents à la banque mais pas dans la plateforme</div>
              </Badge>
            </Fragment>
          )}
          key={0}
        >
          {importResult.notExistingCandidate && (
            <Table dataSource={importResult.notExistingCandidate}>
              <Column title="Email" dataIndex="email" key="email" />
            </Table>
          )}
        </Collapse.Panel>
      </Collapse>
    </Fragment>
  );

  async function impersonate(email) {
    const res = await axios.post(`administration/impersonate/${email}`, {});
    const accessToken = res.data.access_token;
    await updateToken(accessToken);
    await loadMe(accessToken);
    window.location.href = '/home/welcome/candidate';
  }
}
