/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/core';
import { ILanguage } from '@fstn/ecandidaturev2_api-interfaces';
import { FormLayout } from 'antd/lib/form/Form';
import React from 'react';
import { Box, Flex } from 'rebass';
import { useImmer } from 'use-immer';
import { EntityFormContainer } from '../../../common/form/entityForm/EntityFormContainer';
import { EntityFormListContainer } from '../../../common/form/entityForm/EntityFormListContainer';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { FormStyle } from '../../../common/form/FormStyle';
import { SecretaryReviewFormFooterContainer } from '../../../common/form/secretaryReviewForm/SecretaryReviewFormFooterContainer';
import { WithSwitchToReadOnlyOnDisplayForSecretary } from '../../../common/withExtraParameters/WithSwitchToReadOnlyOnDisplayForSecretary';
import { FormListFieldData } from '../../../common/form/type';
import { WithInitialLoad } from '../../../common/withExtraParameters/WithInitialLoad';
import { EntityFormContext } from '../../../context/entityForm.context';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { IsSecretary } from '../../user/conditionnal/IsSecretary';
import { LanguageForm } from './LanguageForm';
import { LanguageIdiomModal } from './LanguageIdiomModal';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { DisabledContextProviderForEntityForm } from '../../../common/rights/DisabledContextProviderForEntityForm';
import { useEntity } from '../../../hooks/use-entity';

const entity = 'languages-list';

function LanguagesListEntityForm(props: {}) {
  const { deleteEntity } = useEntity();
  const responsive = useResponsiveForm();
  const languages = useScopedSelector<any[], any[]>('LanguagesListEntityForm', EntityFormContext, (c) => c[0]?.entityValue.languages);
  /** TODO FOR RELOAD
   const [formContext] = useContext<[EntityFormContextType<any>('(EntityFormContext);
   */
  const [state, updateState] = useImmer({
    schoolTracks: [],
    showAddIdiom: false,
    add: undefined,
  });

  async function onAddIdiom(academic: any) {
    updateState((draft) => {
      draft.showAddIdiom = false;
    });
    state.add(academic);
  }

  async function onCancelIdiom() {
    updateState((draft) => {
      draft.showAddIdiom = false;
    });
  }
  function getLanguageNameForField(field: FormListFieldData) {
    return languages?.[field.fieldKey]?.name;
  }
  return (
    <Flex flexDirection="column">
      <LanguageIdiomModal
        onAddIdiom={onAddIdiom}
        onCancel={onCancelIdiom}
        showIdiom={state.showAddIdiom}
      />
      <Box {...responsive.w1}>
        <FormContentPanelContainer partName={entity}>
          <EntityFormListContainer
            compact
            propertyName="languages"
            entity="language"
            addDecorator={async (add) => {
              updateState((draft) => {
                draft.showAddIdiom = true;
                draft.add = add;
              });
            }}
            removeDecorator={async (remove, value: ILanguage) => {
              if (value.certificateDocument) {
                await deleteEntity('document', value.certificateDocument);
              }
              remove();
            }}
            sort={(l1, l2) => {
              if (getLanguageNameForField(l1) === 'Anglais') {
                return -1;
              }
              if (getLanguageNameForField(l2) === 'Anglais') {
                return 1;
              }
              return 0;
            }}
          >
            <LanguageForm />
          </EntityFormListContainer>
        </FormContentPanelContainer>
      </Box>
    </Flex>
  );
}

export function LanguagesListForm(props: { layout?: FormLayout }) {
  return (
    <FormStyle>
      <WithInitialLoad entity={entity}>
        <WithSwitchToReadOnlyOnDisplayForSecretary>
          <EntityFormContainer layout={props.layout}>
            <DisabledContextProviderForEntityForm>
              <LanguagesListEntityForm />
              <IsSecretary>
                <SecretaryReviewFormFooterContainer propertyName="secretaryReview" />
              </IsSecretary>
            </DisabledContextProviderForEntityForm>
          </EntityFormContainer>
        </WithSwitchToReadOnlyOnDisplayForSecretary>
      </WithInitialLoad>
    </FormStyle>
  );
}
