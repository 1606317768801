/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { IComments, IUserRole } from '@fstn/ecandidaturev2_api-interfaces';
import { Comment } from 'antd';
import React from 'react';
import { CommentsList } from './CommentsList';
import { CommentsListComposerInputContainer } from './CommentsListComposerInputContainer';
import { getAvatar } from './utils';

const Style = styled.div`
height: 100%;
background-color:white;
`;

export function CommentsListComposer(props: {
  comments: IComments[],
  userRole: IUserRole,
  disabled?: boolean,
  type: 'commentsBtwSecretaryAndCandidate',
  handleSubmit: Function,
  submitting: boolean,
  className: string,
}) {
  return (
    <Style className={props.className}>
      {!props.disabled && (
        <Comment
          css={css`background-color: white; width: 100%`}
          avatar={
            getAvatar(props.userRole?.name?.replace('_', '-'))
          }
          content={(
            <CommentsListComposerInputContainer
              type={props.type}
              size="default"
              onSubmit={props.handleSubmit}
              submitting={props.submitting}
            />
          )}
        />
      )}
      {props.comments?.length > 0
        && <CommentsList css={css`height: calc( 100% - 100)`} comments={props.comments} />}
    </Style>
  );
}
