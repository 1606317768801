/* eslint-disable react-hooks/exhaustive-deps */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/core';
import { IRights, RightsUtils, ValidationStep } from '@fstn/ecandidaturev2_api-interfaces';
import { Menu } from 'antd';
// @ts-ignore
import { usePath } from 'hookrouter';
import { omit } from 'lodash';
import React, {
 Fragment, useContext, useMemo,
} from 'react';

import { IhmContext } from '../../context/ihm.context';
import { RightsContext, RightsContextType } from '../../context/rights.context';
import { UserContext, UserContextType } from '../../context/user.context';
import { ValidationsContext } from '../../context/validations.context';
import { useRights } from '../../hooks/use-rights.hook';
// noinspection,TypeScriptCheckImport
import {
    CandidateFileContext,
    CandidateFileContextProviderType,
} from '../../pages/canSeeCandidateFile/CandidateFileContext';
import { AreRightsLoading } from '../../pages/user/conditionnal/AreRightsLoading';
import { CanWrite } from '../../pages/user/conditionnal/CanWrite';
import { IsCandidate } from '../../pages/user/conditionnal/IsCandidate';
import { IsSecretary } from '../../pages/user/conditionnal/IsSecretary';
import { Locked } from '../indicator/Locked';
import * as Locale from '../locale';
import { useScopedSelector } from '../../hooks/use-scoped-selector';
import { MenuItemValidationFlagContainer } from './MenuItemValidationFlagContainer';
import { InlineLoading } from '../indicator/SmartLoading';

/**
 * LeftMenuItem: Candidate and Secretary left  menu item
 * @param entityName
 * @param noValidate
 * @param props
 * @constructor
 */
export function LeftMenuItem(props: { entityName: string, noValidate?: boolean, compact?: boolean }) {
    const { isNavigationAllowed } = useContext(IhmContext);
    const { userCtx, canEditValidation } = useContext<UserContextType>(UserContext);
    const validationForButtonValid = useScopedSelector('LeftMenuItem_validationForButtonValid', ValidationsContext, (c) => c.validationsCtxState[ValidationStep.SECRETARY_REVIEW_VALID]?.validations);
    const validationsContextForStep = useScopedSelector('LeftMenuItem_validationsContextForStep', ValidationsContext,
        (c) => Object.keys(c.validationsCtxState)
            .filter((s) => s !== ValidationStep.PAYMENT)
            .filter((s) => s !== ValidationStep.SECRETARY_REVIEW_VALID)
            .map((step, i) => ({
 step,
                haveValidations: Object.values(c.validationsCtxState[step]?.validations?.[props.entityName || 'candidate-file'] || {})?.length > 0,
                isLoading: Object.values(c.validationsCtxState[step].loadingsValidations?.[props.entityName || 'candidate-file'] || {})?.length > 0,
})));

    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    const path = usePath();
    const { allEntitiesAreWrittable } = useRights();
    const rights = useScopedSelector<RightsContextType, IRights>('LeftMenuItem', RightsContext, (c) => c.rightsCtxState.rights);

    // find  a way to manage the submit state here

    const roleName = userCtx.user?.role?.name;
    const entityRights = rights?.[props.entityName];
    const canWrite = entityRights ? RightsUtils.roleCanWrite(entityRights, roleName) : allEntitiesAreWrittable(rights, roleName);
    const style: any = useMemo(() => {
        if (!canWrite) {
            return css`opacity: 0.5;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;
}
        return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;
    }, [canWrite]);

    const validButtonPress = !(validationForButtonValid?.[props.entityName] && Object.keys(validationForButtonValid?.[props.entityName]).length);

    return (
      <Menu.Item
        {...omit(props, 'compact', 'entityName')}
        key={props.entityName}
        data-testid={`menu.${props.entityName}`}
        eventKey={props.entityName}
        css={css`
        overflow: visible !important;
        padding-top: 0!important;
        flex:1;
        min-height: 40px;
        .ant-menu-title-content{
            display: flex;
            flex-wrap: nowrap;
            gap:1em;
            align-items: center;
        }                    
        // Display only secretary validation status if button valid/notvalid clicked
        ${canEditValidation() && validButtonPress ? '' : '.validation-flag-valid.SECRETARY_REVIEW{opacity:0}'};

        background-color:${path.includes(props.entityName) ? '#E6F6FF !important' : 'transparent !important'};
        color:${path.includes(props.entityName) ? '#1890ff !important' : 'rgba(0, 0, 0, 0.85) !important'};
        .translation{
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }`}
        disabled={!isNavigationAllowed() || (canWrite === false && !candidateFileContext)}
        title={<Locale.Label tkey={`${props.entityName}.menu`} />}
      >
        <AreRightsLoading else={(
          <Fragment>
            <div css={css`/*position: absolute;*/ left: 0`}>
              <div css={style}>
                <IsCandidate>
                  <CanWrite entityName={props.entityName} else={<Locked size={14} />} />
                </IsCandidate>
              </div>
            </div>
            <div
              className="is-flex is-align-items-center is-justify-content-space-between"
              css={css`gap:5px; flex:1; /*margin-left: 20px; width: calc(100% - 20px)*/`}
            >
              {!props.compact
                && (
                <div css={css`text-overflow: ellipsis;max-width: 100%; overflow: hidden;`}>
                  <Locale.Label tkey={`${props.entityName}.menu`} />
                </div>
                )}
              <IsCandidate>
                <CanWrite entityName={props.entityName}>
                  <MenuItemValidationFlagContainer
                    entityName={props.entityName}
                    compact={props.compact}
                    noValidate={props.noValidate}
                    validationsContextForStep={validationsContextForStep}
                  />
                </CanWrite>
              </IsCandidate>
              <IsSecretary>
                <MenuItemValidationFlagContainer
                  entityName={props.entityName}
                  compact={props.compact}
                  noValidate={props.noValidate}
                  validationsContextForStep={validationsContextForStep}
                />
              </IsSecretary>
            </div>
          </Fragment>
                )}
        >
          <InlineLoading loading context="LeftMenuItem" />
        </AreRightsLoading>
      </Menu.Item>
    );
}
