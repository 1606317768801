/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import * as Locale from '../../../../common/locale';
import {MissingFieldsListFormContainer} from '../../../secretary/missingFieldsList/MissingFieldsListFormContainer';

/**
 * Missing fields part of right container
 * @constructor
 */
export function MissingFieldsRightContainer() {
return (
  <Fragment>
    <h1><Locale.Title tkey="secretaryReview.secretary.missingFields" /></h1>
    <br />
    <MissingFieldsListFormContainer />
  </Fragment>
);
}
