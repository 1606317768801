/** @jsx jsx */
import {jsx} from '@emotion/core';
import {UserRoleEnum} from '@fstn/ecandidaturev2_api-interfaces';
import {Fragment, useContext} from 'react';
import {UserContext} from '../../../context/user.context';

export function IsSecretaryOrSpecializedOrExaminer(props: { children: any, elseChildren?: any }) {
    const { userCtx } = useContext(UserContext);
    return (
      <Fragment>
        {(userCtx.user?.role?.name === UserRoleEnum.SECRETARY
            || userCtx.user?.role?.name === UserRoleEnum.SPECIALIZED_SECRETARY
            || userCtx.user?.role?.name === UserRoleEnum.EXAMINER) && props.children}
        {!(userCtx.user?.role?.name === UserRoleEnum.SECRETARY
            || userCtx.user?.role?.name === UserRoleEnum.SPECIALIZED_SECRETARY
            || userCtx.user?.role?.name === UserRoleEnum.EXAMINER) && props.elseChildren}
      </Fragment>
    );
}
