import axios, {
    AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import wrapper from 'axios-cache-plugin';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import { get } from 'lodash';
import React from 'react';
import { useImmer } from 'use-immer';
import { ROUTES } from '../pages/user/UserRoutesDef';
import { handleError } from '../utils/formErrorHandler';
import { sNavigateReload } from '../utils/safeNavigation';

export type AxiosContextType = {
    axios?: AxiosInstance, axiosWithCache?: AxiosInstance, token: string,
    updateToken?: (token: string) => Promise<void>,
    getAxiosWithCache?: (ttl: number) => AxiosInstance
};

function getAxiosWithCache(axiosInstance, ttl) {
    return wrapper(axiosInstance, {
        maxCacheSize: 5000000,
        ttl,
        excludeHeaders: true,
        baseURL: '/api',
    });
}

export const AxiosContext = React.createContext<AxiosContextType>(
    {
        axios: configureAxiosInstance(axios.create(), ''),
        axiosWithCache: getAxiosWithCache(axios, 5000),
        token: '',
        getAxiosWithCache: (ttl) => getAxiosWithCache(configureAxiosInstance(createInstanceWithCache(), undefined), ttl),
    },
);

function configureAxiosInstance(axiosInstance, token: string) {
    axiosInstance?.interceptors?.request?.use((config: AxiosRequestConfig) => {
        // Do something before request is sent
        config = {
            baseURL: '/api',
            ...config,
        };

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, (error: Error) => Promise.reject(error));

    axiosInstance?.interceptors?.response?.use((response: AxiosResponse) => response, (error: AxiosError) => {
        handleError('generic', error);
        if ((get(error, 'response.status') === 401 || get(error, 'response.status') === 403)) {
            if (error.request.responseURL.includes('/api/login')
                || error.request.responseURL.includes('/api/register-code')
                || error.request.responseURL.includes('/api/forgot-password-email')
                || error.request.responseURL.includes('/api/forgot-password-code')
            ) {
                return Promise.reject(error);
            } if (!error.request.responseURL.includes('/me')) {
                sNavigateReload(ROUTES.LOGIN);
                return Promise.reject(error);
            }
            /* updateToken(undefined);
            notification.warning({
                message: 'Session Expired',
                description: 'Your session has expired. Would you like to be redirected to the login page?',
                onClose: () => {
                    localStorage.removeItem('user');
                },
            }); */
        } else if (get(error, 'response.status') === 404) {
            // ignore 404
        } else if (get(error, 'response.status') === 422) {
            return Promise.reject(error);
        } else {
            /* notification.error({
                 message: 'Oups!',
                 description:
                     'Hmmmm, an error occured!',
             }); */
            return Promise.reject(error);
        }
        return undefined;
    });
    return axiosInstance;
}

function createInstanceWithCache() {
    return axios.create({ adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter)) });
}

export const AxiosContextProvider = (props: { token?: string, children: any }) => {
    // console.log('Redraw AxiosContextProvider');
    let axiosInstance = configureAxiosInstance(axios.create(), props.token);
    const [state, updateState] = useImmer<any>({
        token: props.token,
        axios: axiosInstance,
        axiosWithCache: getAxiosWithCache(axiosInstance, 5000),
    });

    async function updateToken(token: string) {
        if (token !== state.token) {
            axiosInstance = configureAxiosInstance(axios.create(), token);
            const axiosWithCache = getAxiosWithCache(axiosInstance, 5000);
            return updateState((draft) => {
                draft.token = token;
                draft.axios = axiosInstance;
                draft.axiosWithCache = axiosWithCache;
            });
        }
        return undefined;
    }

    return (
        <AxiosContext.Provider value={{
            axios: state.axios,
            axiosWithCache: state.axiosWithCache,
            token: state.token,
            updateToken,
            getAxiosWithCache: (ttl) => getAxiosWithCache(configureAxiosInstance(createInstanceWithCache(), undefined), ttl),
        }}
        >
            {props?.children}
        </AxiosContext.Provider>

    );
};
