import React from 'react';
import { AcademicsListForm } from '../../candidateFile/academicsList/AcademicsListForm';
import { AdditionalDocumentsListForm } from '../../candidateFile/additionalDocumentsList/AdditionalDocumentsListForm';
import { ApprenticeshipCompanyForm } from '../../candidateFile/apprenticeshipCompany/ApprenticeshipCompanyForm';
import { BachelorForm } from '../../candidateFile/bachelor';
import { SubmitCandidateFileForm } from '../SubmitCandidateFileForm';
import { CompanyExperiencesListForm } from '../../candidateFile/companyExperiencesList/CompanyExperiencesListForm';
import { WithCandidateFileOk } from '../../../common/conditionnal/WithCandidateFileOk';
import { WithCandidateFileOkForPaymentOk } from '../../../common/conditionnal/WithCandidateFileOkForPaymentOk';
import { WithProgramsListOk } from '../../../common/conditionnal/WithProgramsListOk';
import { LanguagesListForm } from '../../candidateFile/languagesList/LanguagesListForm';
import { MotivationForm } from '../../candidateFile/motivation/MotivationForm';
import { PaymentsListForm } from '../../candidateFile/paymentsList/PaymentsListForm';
import { ProfileForm } from '../../candidateFile/profile';
import { ProgramsListForm } from '../../candidateFile/programsList/ProgramsListForm';
import { StaysAbroadListForm } from '../../candidateFile/staysAbroadList/StaysAbroadListForm';
import { IsNew } from '../../user/conditionnal/IsNew';
import { CandidateChat } from '../CandidateChat';
import { CandidateHome } from '../CandidateHome';
import { OnlyVisibleIfCandidateFileIsOpenForCandidate } from '../OnlyVisibleIfCandidateFileIsOpenForCandidate';
import { WithBachelorOk } from '../../../common/conditionnal/WithBachelorOk';

/**
 * Routes starting with /home/welcome/secretary/
 * @constructor
 */
export function CandidateChild1RoutesDef(): any {
  return {
    '/chat': () => <CandidateChat />,
    '/home': () => (<CandidateHome />),
    '/programs-list': () => <ProgramsListForm />,
    '/profile': () => <ProfileForm />,
    '/bachelor': () => <BachelorForm />,
    '/academics-list*': () => <WithProgramsListOk><WithBachelorOk><AcademicsListForm /></WithBachelorOk></WithProgramsListOk>,
    '/languages-list': () => <LanguagesListForm />,
    '/stays-abroad-list': () => <StaysAbroadListForm />,
    '/company-experiences-list': () => <CompanyExperiencesListForm />,
    '/motivation': () => <WithProgramsListOk><MotivationForm /></WithProgramsListOk>,
    '/apprenticeship-company': () => <ApprenticeshipCompanyForm />,
    '/additional-documents-list': () => <AdditionalDocumentsListForm />,
    '/payments-list': () => <PaymentsListForm />,
    '/submit': () => (
      <OnlyVisibleIfCandidateFileIsOpenForCandidate>
        <IsNew>
          <WithCandidateFileOkForPaymentOk>
            <WithCandidateFileOk>
              <SubmitCandidateFileForm />
            </WithCandidateFileOk>
          </WithCandidateFileOkForPaymentOk>
        </IsNew>
      </OnlyVisibleIfCandidateFileIsOpenForCandidate>
    ),
    '': () => <CandidateHome />,
    '/': () => <CandidateHome />,
    '*': () => <ProgramsListForm />,
  };
}
