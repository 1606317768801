import { TableOutlined } from '@ant-design/icons';
import Menu from '@inovua/reactdatagrid-community/packages/Menu';

import React from 'react';
import * as Locale from '../../common/locale';
import { useLoading } from '../../hooks/use-loading';
import { StrictLoading } from '../../common/indicator/SmartLoading';
import { useExportUtils } from '../../hooks/use-export-utils';
import { useSegment } from '../../hooks/use-segment';
import { endExportPopup } from '../../utils/endExportPopup';

export function ProgramManagerRowContextMenu({
  menuProps, details, selectedCandidateFiles, gridRef,
}: any) {
  const { downloadExport } = useExportUtils();
  const { doAction, loading } = useLoading();

  const items = [];

  const { segment } = useSegment(selectedCandidateFiles);

  if (selectedCandidateFiles.length > 0) {
    items.push(
      {
        label: <Locale.Button tkey="candidateFile.XLS" />,
        icon: <TableOutlined />,
        disabled: loading,
        onClick: async (value) => {
          doAction(async () => {
            await downloadExport(segment.id, []);
            endExportPopup('downloadXls');
          });
        },
      },
    );
  }

  return (
    <StrictLoading loading={loading} context="ProgramManagerRowContextMenu">
      <Menu
        {...menuProps}
        disabled={loading}
        items={items}
      >
      </Menu>
    </StrictLoading>
  );
}
