/** @jsx jsx */
import {jsx} from '@emotion/core';
import styled from '@emotion/styled';
import {DEFAULT} from '@fstn/ecandidaturev2_api-interfaces';
import {useContext} from 'react';
import {useImmer} from 'use-immer';
import {TextOkButton} from '../../../../common/button/TextOkButton';
import {EntityFormContainer} from '../../../../common/form/entityForm/EntityFormContainer';
import {FormContentPanelContainer} from '../../../../common/form/formContentPanel/FormContentPanelContainer';
import {FixSelectFormItem} from '../../../../common/form/inputFormItems/FixSelectFormItem';
import {InputFormItem} from '../../../../common/form/inputFormItems/InputFormItem';
import {AxiosContext, AxiosContextType} from '../../../../context/axios.context';
import {sNavigateReload} from '../../../../utils/safeNavigation';
import {ADMIN} from '../../../user/UserRoutesDef';
import {ResultPanel} from '../../common/ResultPanel';
import {ButtonsFormItemLayout} from '../../../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import {DisabledContextProviderAlwaysEditableAdmin} from '../../../../common/rights/DisabledContextProviderAlwaysEditableAdmin';
// @ts-ignore

const Style = styled.div``;

/**
 *  LAURENT exemple de page d'édition
 * @param props
 * @constructor
 */
export function AdminEditPage(props) {
    const { axios } = useContext<AxiosContextType>(AxiosContext);

    const [state, updateState] = useImmer({
        captcha: false,
        valid: false,
        loading: false,
        error: undefined as string,
        result: undefined as string,
    });

    const onFinish = async (values) => {
        try {
            updateState((draft) => {
                draft.loading = true;
            });
            await axios.post('/admin/admin', values);
            updateState((draft) => {
                draft.result = 'Utilisateur créé';
                draft.error = undefined;
            });
            const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
            await sleep(2500);
            sNavigateReload(ADMIN.ADMIN_EDIT);
        } catch (ex) {
            updateState((draft) => {
                draft.error = ex.response.data.message;
                draft.result = undefined;
            });
        } finally {
            updateState((draft) => {
                draft.loading = false;
            });
        }
    };

    return (
      <Style>
        <EntityFormContainer
          entity="admin"
          initialValues={{ ...props, rights: DEFAULT }}
          noSave
          layout="vertical"
          onFinish={onFinish}
        >
          <DisabledContextProviderAlwaysEditableAdmin>
            <FormContentPanelContainer partName="admin">
              <FixSelectFormItem
                propertyName="gender"
                options={[
                            { value: 'm', label: 'Masculin' },
                            { value: 'f', label: 'Féminin' },
                            { value: 'o', label: 'Autre' }]}
              />
              <InputFormItem
                propertyName="firstName"
                required
                rules={[{ required: true, message: 'Champ obligatoire !' }]}
              />
              <InputFormItem
                propertyName="lastName"
                required
                rules={[{ required: true, message: 'Champ obligatoire !' }]}
              />
              <InputFormItem
                propertyName="email"
                autoComplete="email"
                required
                rules={[{ required: true, message: 'Champ obligatoire !' },
                            { type: 'email' }]}
              />
              <ButtonsFormItemLayout>
                <SubmitButton />
              </ButtonsFormItemLayout>
            </FormContentPanelContainer>
          </DisabledContextProviderAlwaysEditableAdmin>
        </EntityFormContainer>
        <ResultPanel error={state.error} result={state.result} />
      </Style>
    );
}

function SubmitButton() {
    return (
      <TextOkButton
        tkey="admin.add"
        htmlType="submit"
      />
    );
}
