/** @jsx jsx */
import { css, jsx } from '@emotion/core';

export function AreaContainerPanel(props: any) {
    return (
      <div
        {...props}
        css={css`
            min-height: 100%;
            padding: 5px;
        `}
      >
        {props.children}
      </div>
    );
}
