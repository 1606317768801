// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import { omit } from 'lodash';
import React, { ReactElement, useContext } from 'react';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { PDFFormControl } from '../components/PDFFormControl';
import { PDFEntityFormContext, PDFEntityFormContextType } from './context/pdfEntityForm.context';

const Container = styled(View)`
  width: 100%;
`;

export function internalGetValue(store: any, propertyName: any): any {
    if (Array.isArray(propertyName) && propertyName.length > 0) {
        const tmpPropertyName = [...propertyName];
        const e = tmpPropertyName.shift();
        if (propertyName.length === 1) {
            return store?.[propertyName[0]];
        }
            return internalGetValue(store?.[e], tmpPropertyName);
    }
        return store?.[propertyName];
}

export function PDFEntityFormItem(props: { children: (value) => ReactElement[] | ReactElement, debug?: boolean, propertyName: string, layout?: 'vertical' | 'horizontal' }) {
    const { t } = useSafeTranslation();
    const [formContext] = useContext<[PDFEntityFormContextType<any>, any]>(PDFEntityFormContext);

    let value = internalGetValue(formContext?.entityValue, props.propertyName);

    value = value?.replace('@@', '');
    const childrenComponents = props.children(value);
    let childrenAsArray;
    if (Array.isArray(props.children)) {
        childrenAsArray = childrenComponents;
    } else {
        childrenAsArray = [childrenComponents];
    }

    const children = React.Children.map(childrenAsArray,
        (c, i) => React.cloneElement(c, {
            // eslint-disable-next-line react/no-array-index-key
 ...c.props, ...omit(props, 'children'), key: i, value,
}));

    return (
      <Container debug={props.debug}>
        <PDFFormControl
          title={t(`${formContext?.entity}.${props?.propertyName}.label.html`)}
          layout={props.layout}
          debug={props.debug}
        >
          {children}
        </PDFFormControl>
      </Container>
    );
}
