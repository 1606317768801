import { omit } from 'lodash';
import React from 'react';

/**
 * WithOnChangeValueChange: allows to map onChange value, used to map event to event.target.value
 * @param props
 * @constructor
 */
export function WithOnChangeValueChange(props: { valueMapper: Function, onChange?: Function, children?: any }) {
    let childrenAsArray;
    if (Array.isArray(props.children)) {
        childrenAsArray = props.children;
    } else {
        childrenAsArray = [props.children];
    }
    const children = React.Children.map(childrenAsArray,
        (c) => {
            const extra = {
                onChange: async (e) => {
                     const d = await props.valueMapper(e);
                     await props?.onChange?.(d);
                },
            };
            return React.cloneElement(c, { ...c.props, ...omit(props, 'children', 'valuemapper'), ...extra });
        });
    return children;
}
