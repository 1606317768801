/** @jsx jsx */
import {jsx} from '@emotion/core';
import * as Locale from '../../../../common/locale';
import {InputFilter} from '../filters/InputFilter';

export const lastNameColumn = {
        header: <Locale.Header tkey="candidateFilesList.lastName" />,
        name: 'lastName',
        id: 'lastName',
        group: 'profile',
        defaultLocked: 'start',
        defaultWidth: 130,
        filterable: true,
        filterEditor: InputFilter,

    };

export const lastNameFilter = {
        name: 'lastName', operator: 'startsWith', type: 'string', value: '',
    };
