/** @jsx jsx */
import { ExclamationCircleFilled } from '@ant-design/icons';
import { jsx } from '@emotion/core';
import React from 'react';
import { TextButton } from './TextButton';

/**
 * Simple Danger Button
 * @param tkey
 * @param props
 * @constructor
 */
export function TextDangerButton({ tkey, ...props }: any) {
    return <TextButton icon={<ExclamationCircleFilled />} {...props} tkey={tkey} danger type="primary" />;
}
