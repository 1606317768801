/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Affix } from 'antd';
import { useContext, useRef } from 'react';
import { useImmer } from 'use-immer';
import UUID from 'uuid-js';
import { IhmContext } from '../../context/ihm.context';

/**
 * Card Header that keep Affix on top
 * @param props
 * @constructor
 */
export function AffixedCardHeader(props: { children }) {
    const { ihmState, updateIhmState } = useContext(IhmContext);
    const ref = useRef();
    const [affixedState, updateAffixedState] = useImmer({ affixed: false, id: UUID.create(1).toString() });

    const style: any = affixedState.affixed ? {
            width: '100%',
        }
        : {};

    return (
      <div style={style}>
        <Affix
          ref={ref}
          target={() => ihmState.scrollableFormContainer?.current}
          onChange={(affixed) => {
                updateAffixedState((draft) => {
                    draft.affixed = affixed;
                });
                if (affixed) {
                    updateIhmState({
                        ...ihmState, affixedScrollableFormContainer: affixedState.id,
                    });
                }
            }}
        >
          <div style={affixedState.affixed ? { backgroundColor: 'white', padding: '1em', width: '100%' } : {}}>
            {(!affixedState.affixed || ihmState.affixedScrollableFormContainer === affixedState.id)
                    && props.children}
          </div>
        </Affix>
      </div>
);
}
