/** @jsx jsx */
import { jsx } from '@emotion/core';
import { uniq } from 'lodash';
import { Fragment } from 'react';
import { Badge } from 'antd';
import { ConditionnalPopover } from '../../../../common/antd/ConditionnalPopover';
import * as Locale from '../../../../common/locale';
import { ColdDataSelectorContainer } from '../../../../common/coldData/ColdDataSelectorContainer';
import { perc2color } from '../utils/Color';

export function ProgramSchoolFilter(props) {
    // Recuperation des formation
    const filterEditorProps = props?.filterEditorProps;

    // La ligne css={css`.ant-select-selector {width: 300px !important}`} ci-dessous permet de rajouter directement du css
    // dans une classe existante utilisée. Ici, on ajoute width sur la classe ant-select-selector pour outrepasser son mode
    // "autoadapatif par defaut". Le !important permet de signaler que cette nouvelle propriete ne doit pas etre "redefinie" par
    // d'eventuelles nouvelles props appliquées ensuite
    return props.render(
      <ColdDataSelectorContainer
        {...filterEditorProps}
        api="program-schools"
        params="{ style: { width: '200px' } }"
        allowClear
        mode="multiple"
        onClear={() => {
                props.onChange({ ...props.filterValue, value: undefined });
            }}
        onChange={(v) => {
                props.onChange({ ...props.filterValue, value: v?.map((vv) => vv.id) });
            }}
      />,
    );
}
export const programSchoolFilterEditorProps = {
    className: 'programSchoolFilter',
    'test-id': 'programSchoolFilter',
};

export function ProgramSchoolCellRender({ value, data }: any) {
        const { length } = uniq(data?.programLines?.map?.((p) => p.program?.school));
        return (
          <Fragment key={`programLines_cell_${data.id}`}>
            <ConditionnalPopover
              disabled={data?.programLines?.length === 0}
              content={(
                <ul>
                  {uniq(data?.programLines?.map?.((p) => p.program?.school)).map?.((s) => (
                    <li
                      key={`${s}`}
                    >
                      {`${s}`}
                    </li>
                            ))}
                </ul>
                    )}
            >
              <Badge
                count={length}
                overflowCount={10}
                style={{ backgroundColor: perc2color(24 - length, 0, 40), color: 'black' }}
              />
            </ConditionnalPopover>
          </Fragment>
        );
    }

export const programSchoolColumn = {
    header: <Locale.Header tkey="candidateFilesList.programSchool" />,
    name: 'countSchool',
    id: 'countSchool',
    defaultWidth: 120,
    filterEditor: ProgramSchoolFilter,
    filterEditorProps: programSchoolFilterEditorProps,
    render: ProgramSchoolCellRender,
};

export const programSchoolFilter = {
    name: 'countSchool', operator: 'inlist', type: 'select', value: '',
};
