/** @jsx jsx */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { jsx } from '@emotion/core';
import { IAcademicReferent, IVersionedBaseStringId, UserRoleEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { AxiosInstance } from 'axios';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
// @ts-ignore
import * as UUID from 'uuid-js';
import { ReferentCard } from './ReferentCard';
import { UserContext, UserContextType } from '../../../context/user.context';
import { useEntity } from '../../../hooks/use-entity';
import { ResponsiveInput } from '../../../common/input/ResponsiveInput';
import * as Locale from '../../../common/locale';
import { useLoading } from '../../../hooks/use-loading';

const { confirm } = Modal;

export function ReferentEmailModal(props: {
  showReferentEmail: boolean,
  axios: AxiosInstance,
  type: 'academic-referent',
  onCancel: () => Promise<void>,
  onAddReferentReview: (referent: any) => Promise<void>
}) {
  const { doAction, InlineLoadingContainer, loading } = useLoading(false);
  const userContext = useContext<UserContextType>(UserContext);
  const [emailForm] = useForm();
  const [newReferentForm] = useForm();
  const { saveEntity } = useEntity();
  const [visible, setVisible] = useState(props.showReferentEmail);

  useEffect(() => {
    setVisible(props.showReferentEmail);
  }, [props.showReferentEmail]);

  const onFinishAddReferent = (values, referentEmail, referent) => doAction(async () => {
    referent = {
      id: UUID.create(1).toString(),
    };
    if (userContext.userCtx.user?.role?.name === UserRoleEnum.SECRETARY) {
      // if we are secretary, we switched to edit mode so we need to set the right rights
      referent = {
        ...referent,
        rights: {
          manage: [UserRoleEnum.SECRETARY],
          read: Object.values(UserRoleEnum),
          write: [UserRoleEnum.SECRETARY],
          delete: [UserRoleEnum.SECRETARY],
        },
      };
    }
    referent.firstName = values.firstName;
    referent.lastName = values.lastName;
    referent.email = referentEmail;
    referent = (await saveEntity(props.type, referent as IVersionedBaseStringId)).data;
    await props.onAddReferentReview(referent);
    setVisible(false);
    return true;
  });

  const onAddReferentReview = async (referentEmail) => doAction(async () => {
    try {
      emailForm.resetFields();
      newReferentForm.resetFields();
      // search for existing referent
      //const existingReferent = await props.axios.get(`/${props.type}/?s={"email":"${encodeURIComponent(referentEmail)}"}`);
      const existingReferent = await props.axios.get(`/${props.type}/referentByEmail?email=${encodeURIComponent(referentEmail)}`);
      let referent: Partial<IAcademicReferent>;

      await new Promise((resolve, reject) => {
        if (existingReferent?.data?.[0]) {
          referent = {
            ...existingReferent?.data?.[0],
            existing: true,
            createdAt: moment.utc(),
          };
          confirm({
            width: "616px",
            // @ts-ignore
            okButtonProps: { 'data-testid': 'referent-email-confirm-ok' },
            // @ts-ignore
            cancelButtonProps: { 'data-testid': 'referent-email-confirm-cancel' },
            title: <Locale.Notification tkey="existingReferent" />,
            icon: <ExclamationCircleOutlined />,
            content: (
              <div>
                <Locale.Notification tkey="existingReferent.description" />
                <ReferentCard referent={referent as any} />
              </div>
            ),
            async onOk() {
              await props.onAddReferentReview(referent);
              resolve(undefined);
            },
            async onCancel() {
              resolve(undefined);
            },
          });
        } else {
          confirm({
            // @ts-ignore
            okButtonProps: { 'data-testid': 'referent-email-confirm-new-ok' },
            // @ts-ignore
            cancelButtonProps: { 'data-testid': 'referent-email-confirm-new-cancel' },
            title: <Locale.Notification tkey="newReferent" />,
            icon: <ExclamationCircleOutlined />,
            content: (
              <div>
                <Form
                  form={newReferentForm as any}
                >
                  <Locale.Help tkey="referent.firstName" />
                  <Form.Item
                    name="firstName"
                    rules={[{ required: true, message: 'Champ obligatoire !' }]}
                  >
                    <ResponsiveInput
                      autoComplete=""
                      data-testid="referent-firstName"
                    />
                  </Form.Item>
                  <Locale.Help tkey="referent.lastName" />
                  <Form.Item
                    name="lastName"
                    rules={[{ required: true, message: 'Champ obligatoire !' }]}
                  >
                    <ResponsiveInput
                      data-testid="referent-lastName"
                      type="lastName"
                      autoComplete=""
                    />
                  </Form.Item>
                </Form>
              </div>
            ),
            async onOk() {
              if (!newReferentForm.getFieldsValue().lastName || !newReferentForm.getFieldsValue().firstName) {
                return reject();
              }
              await onFinishAddReferent(newReferentForm.getFieldsValue(), referentEmail, referent);
              if (newReferentForm.getFieldsError().filter((e) => e.errors.length > 0).length > 0) {
                return reject();
              }
              return resolve(true);
            },
            async onCancel() {
              resolve(undefined);
            },
          });
        }
      });
    } finally {
      emailForm.setFieldsValue({ email: '' });
    }
  });

  return (
    <Modal
      title={<Locale.Title tkey="referent.email" />}
      visible={visible}
      onOk={emailForm.submit}
      // @ts-ignore
      okButtonProps={{ 'data-testid': 'referent-email-ok', loading }}
      cancelButtonProps={{ disabled: loading }}
      onCancel={props.onCancel}
    >
      <InlineLoadingContainer>
        <Locale.Help tkey="referent.email" />
        <Form form={emailForm as any} onFinish={(v) => onAddReferentReview(v.email)}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: <Locale.Help tkey="invalid.email" />,
              },
            ]}
          >
            <ResponsiveInput data-testid="referent-email" type="email" />
          </Form.Item>
        </Form>
      </InlineLoadingContainer>
    </Modal>
  );
}
