/** @jsx jsx */
import { LoadingOutlined } from '@ant-design/icons';
import { css, jsx } from '@emotion/core';
import React from 'react';

/**
 * SmartLoading indicator
 * @param size
 * @constructor
 */
export function Loading({ size }: any) {
    return (
      <span css={css`
span[role=img]{
    color: #CCC;
    border: 1px solid;
    padding: 2px;
    line-height: ${size}px !important;
    border-radius: 50%;
}`}
      >
        <LoadingOutlined style={{ fontSize: `${size - 4} !important` }} />
      </span>
);
}
