/** @jsx jsx */
import { HomeOutlined } from '@ant-design/icons';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import PageHeader from 'antd/lib/page-header';
// @ts-ignore
import { usePath } from 'hookrouter';
import _ from 'lodash';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useContext } from 'react';
import { useImmer } from 'use-immer';
// @ts-ignore
import useMobileDetect from 'use-mobile-detect-hook';
import { TextDangerButton } from '../../common/button/TextDangerButton';
import { TextNeutralButton } from '../../common/button/TextNeutralButton';
import * as Locale from '../../common/locale';
import { MenuAndFormContainer } from '../../common/page/Containers';
import { UserContext } from '../../context/user.context';
import { sNavigate } from '../../utils/safeNavigation';
import { CandidateFileContext, CandidateFileContextProviderType } from '../canSeeCandidateFile/CandidateFileContext';
import { CenterAreaContainer } from '../canSeeDocuments/viewer/CenterAreaContainer';
import { UserPage } from '../user/UserPage';
import { LOGIN, ROUTES } from '../user/UserRoutesDef';
import { IsPath } from '../user/conditionnal/IsPath';

const FooterStyle = styled.div`
grid-area: footer;
background-color: rgb(240, 240, 240);
padding-left: 1em;
padding-right: 1em;
`;
const HeaderStyle = styled.div`
grid-area: header;
`;

function ReferentPageHeader() {
    const path = usePath();
    return (
      <HeaderStyle>
        <PageHeader
          title={<Locale.Title tkey="referent" />}
          className="site-page-header"
          subTitle={<Locale.Title tkey={`referent.${_.last(path.split('/'))}`} />}
          extra={(
            <Fragment>
              <IsPath different={ROUTES.REFERENT_BASE}>
                <TextNeutralButton
                  tkey="referent.home"
                  icon={<HomeOutlined />}
                  onClick={() => sNavigate(`${ROUTES.REFERENT_BASE}`)}
                />
              </IsPath>
              <TextDangerButton
                tkey="logout"
                onClick={() => sNavigate(`${LOGIN.LOGOUT}`)}
              />
            </Fragment>
                  )}
        />
      </HeaderStyle>
    );
}

function ReferentFooter() {
    return (
      <FooterStyle />
    );
}

export function ReferentPage(props: { children: any }) {
    const detectMobile = useMobileDetect();
    const ReferentPageStyle = detectMobile.isMobile()
        ? styled.div`
            height: 100%;
            display: grid;
            grid-template-areas:  "header"
                                  "left"
                                  "content"
                                  "footer";`
        : styled.div`
            height: 100%;
            display: grid;
            grid-template-areas:  "header"
                                  "content"
                                  "footer";
            grid-template-rows: 80px minmax(0, 100%) 60px;
            grid-template-columns: minmax(0, 1fr);`;

    const [state] = useImmer<{ compact: boolean }>({ compact: detectMobile.isMobile() });
    return (
      <UserPage>
        <ReferentPageStyle>
          {!state.compact
                && <ReferentPageHeader />}
          <MenuAndFormContainer>
            <CenterAreaContainer>
              {props?.children}
            </CenterAreaContainer>
          </MenuAndFormContainer>
          <ReferentFooter />
        </ReferentPageStyle>
      </UserPage>
);
}

function CustomPageHeader() {
    const userCtx = useContext(UserContext);
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    return (
      <HeaderStyle>
        <PageHeader
          title={(
            <Locale.Title tkey="referent" />
                )}
          className="site-page-header"
          subTitle={`${userCtx.userCtx?.user?.name}`}
          extra={(
            <Fragment>
              <IsPath startsWith={`${ROUTES.REFERENT_BASE}/${candidateFileContext?.selectedCandidateFile?.id}`}>
                <IsPath different={ROUTES.REFERENT_BASE}>
                  <TextDangerButton
                    tkey="secretary.exit"
                    onClick={() => sNavigate(`${ROUTES.REFERENT_BASE}`)}
                  />
                </IsPath>
              </IsPath>
              <TextDangerButton
                tkey="logout"
                onClick={() => sNavigate(`${LOGIN.LOGOUT}`)}
              />
            </Fragment>
                )}
        />
      </HeaderStyle>
    );
}

export function FullScreenReferentPage(props: { children: any }) {
    return (
      <UserPage>
        <div css={css`
height: 100%;
.InovuaReactDataGrid__row{
background-color: white !important;
}`}
        >
          <CustomPageHeader />
          {props?.children}
        </div>
      </UserPage>
    );
}
