import { usePath, useRoutes } from 'hookrouter';
import React, {
 Fragment, useMemo, useRef,
} from 'react';
import { AdminChild1RoutesDef } from './AdminChild1RoutesDef';
import { useIsRootPath } from '../../../hooks/use-is-root-path.hook';

export function AdminChild1Container() {
    const routesChildren = useRoutes(AdminChild1RoutesDef());
    const { isRoot } = useIsRootPath();
    const path = usePath();

    const oldProps = useRef({});
    return useMemo(() => {
        oldProps.current = [routesChildren, isRoot];
        return (
          <Fragment>
            {routesChildren}
          </Fragment>
        );
    },
        // eslint-disable-next-line
     [isRoot, routesChildren?.props?.children?.props?.entityId,path]);
}
