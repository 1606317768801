/** @jsx jsx */
import {jsx} from '@emotion/core';
import {IComments} from '@fstn/ecandidaturev2_api-interfaces';
import React, {useContext, useEffect} from 'react';
import {useImmer} from 'use-immer';
import {UserContext} from '../../../context/user.context';
import {CommentsListComposer} from './CommentsListComposer';

export function CommentsListComposerContainer(props: {
    value?: IComments[],
    disabled?: boolean,
    onChange?: Function,
    type: 'commentsBtwSecretaryAndCandidate',
    className?: string,
}) {
    const userContext = useContext(UserContext);
    const [state, updateState] = useImmer<{
        comments: IComments[],
        submitting: boolean,
        disabled?: boolean
    }>({
        comments: props.value,
        submitting: false,
        disabled: props.disabled,
    });

    useEffect(() => {
        updateState((draft) => {
            draft.comments = props.value;
        });
    }, [updateState, props.value]);

    const handleSubmit = (value) => {
        if (!value) {
            return;
        }

        updateState((draft) => {
            draft.submitting = true;
        });

        //console.log('state.comments', state.comments);

        const newComments = [
            {
                author: userContext.userCtx?.user?.name,
                role: userContext.userCtx?.user?.role?.name,
                content: value,
                datetime: new Date(),
            },
            ...(Array.isArray(state.comments) ? state.comments : []),
        ];
        props.onChange(newComments);
        setTimeout(() => {
            updateState((draft) => {
                draft.submitting = false;
                draft.comments = newComments;
            });
        }, 500);
    };

    const { comments, submitting } = state;

    return (
      <CommentsListComposer
        className={props.className}
        userRole={userContext.userCtx.user.role}
        type={props.type}
        comments={comments}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
    );
}
