import { flatMap } from 'lodash';

export function reverseToId(options: any[]) {
  // @ts-ignore
  return flatMap(options, (o) => flatMap(o.children, (oo) => oo.children.flatMap((oProgram) => (oProgram?.program)))).filter((o) => !!o);
}

export function getTheme(t: string) {
  return t.trim();
}
