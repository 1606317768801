/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { ColdDataSelectorContainer } from '../../coldData/ColdDataSelectorContainer';
import { copyEntityFormProps, EntityFormItemContainer } from '../entityForm/EntityFormItemContainer';

export function ColdDataSelectorFormItem(props: {
    api: string,
    propertyName?: any,
    params?: any,
    initialValue?: any,
    hidden?: boolean,
    required?: boolean,
    rules?: any,
    mapper?: ((v: any) => any),
    filter?: ((v: any) => boolean)
    listeners?: { dataLoaded?: (d: any[]) => void },
    allowClear?: boolean,
    labelCol?: any,
    wrapperCol?:any,
    // redraw this item when dependencies item value change
    dependencies?: any[],
    dependenciesValues?: any[]
}) {
    return (
      <EntityFormItemContainer
        {...copyEntityFormProps(props)}
        dependencies={props.dependencies}
      >
        <ColdDataSelectorContainer
          data-testid={(`${props.propertyName}`)?.replace(/,/g, '_')}
          allowClear={props.allowClear}
          id={(`${props.propertyName}`)?.replace(/,/g, '_')}
          api={props.api}
          mapper={props.mapper}
          filter={props.filter}
          listeners={props.listeners}
        />
      </EntityFormItemContainer>
        );
}
