/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext } from 'react';
import { SUPER_SECRETARY_RIGHTS } from '@fstn/ecandidaturev2_api-interfaces';
import _ from 'lodash';
import { UserContext, UserContextTypeContent } from '../../../context/user.context';

export function IsSuperSecretary(props: { children?: any, elseChildren?: any }) {
  const { userCtx } = useContext(UserContext);
  return (
    <Fragment>
      {userCtx.user?.rightsOverriding && _.isEqual(userCtx.user?.rightsOverriding, SUPER_SECRETARY_RIGHTS) && props.children}
      {!(userCtx.user?.rightsOverriding && _.isEqual(userCtx.user?.rightsOverriding, SUPER_SECRETARY_RIGHTS)) && props.elseChildren}
    </Fragment>
  );
}

export function isSuperSecretary(userCtx: UserContextTypeContent): boolean {
  return (userCtx.user?.rightsOverriding && _.isEqual(userCtx.user?.rightsOverriding, SUPER_SECRETARY_RIGHTS))
}