import AwesomeDebouncePromise from 'awesome-debounce-promise';
import _ from 'lodash';
import { useCallback } from 'react';

const debouncedChanges: any = { changes: {} };

/**
 * hook us to provide a debounced callback with a queue of event
 * see spec to learn more
 * @param key
 * @param callBack
 * @param delay
 */
export function useDebouncedCallWithQueuedParams(key, callBack, delay = 2000) {
     const immediateOnChange = useCallback(() => {
        const changes = deQueueChangesToDebounced();
        if (changes && Object.keys(changes).length > 0) {
            return callBack(changes);
        }
        return undefined;
         // eslint-disable-next-line
    }, []);

    const debouncedOnChange = AwesomeDebouncePromise(immediateOnChange, delay);
    function queueChangesToDebounced(changedValues: any) {
        const oldChanges = _.cloneDeep(debouncedChanges.changes[key]);
        if (oldChanges) {
            changedValues = _.merge(oldChanges, changedValues);
        }
        debouncedChanges.changes[key] = { ...debouncedChanges.changes[key], ...changedValues };
    }

    function deQueueChangesToDebounced() {
        const res = debouncedChanges.changes[key];
        debouncedChanges.changes[key] = {};
        return res;
    }

    return {
        queueChangesToDebounced, deQueueChangesToDebounced, debouncedOnChange, immediateOnChange,
    };
}
