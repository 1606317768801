import React, { Fragment } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { EntityIcon } from '../../assets/icons/left-menu.icons';
import { Loading } from '../indicator/Loading';
import { MenuItemValidationFlag } from './MenuItemValidationFlag';

export function MenuItemValidationFlagContainer(props:{ compact:boolean, entityName: string, noValidate: boolean, validationsContextForStep:any }) {
    return (
      <Fragment>
        {(props?.compact && props.entityName) && <EntityIcon entity={props.entityName} />}
        {!props.noValidate
            && props.validationsContextForStep
                .map((step, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                  <div key={i} css={css`align-items: center;align-content: center;display: flex;gap: 5px`}>
                    {!!step.isLoading && <Loading size={10} />}
                    {!step.isLoading
                        && (
                        <MenuItemValidationFlag
                          step={step.step}
                          haveValidations={step.haveValidations}
                        />
                        )}
                  </div>
                ))}
      </Fragment>
    );
}
