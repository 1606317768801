import { Popconfirm, PopconfirmProps } from 'antd';
import * as React from 'react';

/**
 * PopConfirm that can be disabled on property
 * @param props
 * @constructor
 */
export function ConditionnalPopconfirm(props: { disabled?: boolean, children: any } & PopconfirmProps) {
    if (!props.disabled) {
        return (
          <Popconfirm
            {...props}
            overlayClassName="top-0-right-0-confirm yellow-confirm"
          >
            {props.children}
          </Popconfirm>
        );
    }
        return props.children;
}
