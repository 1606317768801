/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import React from 'react';
import { omit } from 'lodash';

import { Debounced } from '../../withExtraParameters/Debounced';
import { copyEntityFormProps, EntityFormItemContainer, ItemProps } from '../entityForm/EntityFormItemContainer';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { DisabledContext } from '../../rights/DisabledContext';

/**
 * EntityFormItemContainer for text area input
 * @param props
 * @constructor
 */
export function InputTextAreaFormItem(props: ItemProps & Omit<TextAreaProps, 'disabled'>) {
  return (
    <EntityFormItemContainer
      {...copyEntityFormProps(props)}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Debounced>
        <InputTextAreaContent
          propertyName={props.propertyName}
          {...props}
        />
      </Debounced>
    </EntityFormItemContainer>
  );
}

function InputTextAreaContent(props: ItemProps & Omit<TextAreaProps, 'disabled'>) {
  const disabled = useScopedSelector('CheckboxContentContainer', DisabledContext, (c) => c?.disabled);
  return (
    <Input.TextArea
      data-testid={`${props.propertyName}`?.replace(/,/g, '_')}
      readOnly={disabled}
      {...omit(props, 'propertyName', 'wrapperCol', 'labelCol')}
    />
  );
}
