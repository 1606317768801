import React, { Fragment } from 'react';

export const configsListInitialColumns = () => [
    {
        header: 'Pays',
        name: 'id',
        defaultFlex: 2,
        editable: false,
        style: { textAlign: 'left' },
    },
    {
        header: 'Position',
        name: 'ranking',
        defaultFlex: 1,
        editable: true,
        style: { textAlign: 'left' },

    },
];

export const configsListInitialFilters = [
    {
        name: 'id', operator: 'contains', type: 'string', value: '',
    },
];

export function configsListRenderRowContextMenu() {
    return (
        <Fragment />
    );
}

/**
 * Map result to row
 * @param d
 */
export function configsListMapToRow(d: any) {
    const row: any = {
        ranking: d.ranking,
        id: d.id,
    };
    return row;
}
