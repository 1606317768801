/** @jsx jsx */
import { jsx } from '@emotion/core';
import { EntityFormContext } from '../../../context/entityForm.context';
import { FormContentPanel } from './FormContentPanel';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

/**
 * FormContentPanelContainer: display panel for Form
 * @param partName
 * @param children
 * @param compact
 * @constructor
 */
export function FormContentPanelContainer({
 partName, children, compact, extra,
}: any) {
    /* console.log('Redraw FormContentPanelContainer'); */
    const entity = useScopedSelector<any[], string>('FormContentPanelContainer', EntityFormContext, (c) => c[0]?.entity);
    return (
      <FormContentPanel
        entity={entity}
        partName={partName}
        compact={compact}
        extra={extra}
      >
        {children}
      </FormContentPanel>
);
}
