import { Select } from 'antd';
import React, { useState } from 'react';

const selectStyle = {
    width: '100%',
    height: '100%',
    margin: '0',
};

/**
 * Ce composant permet de generer une combo dans une cellule du datagrid en édition (remplace le selectEditor par defaut)
 * @param props
 * @returns
 */
export function CustomGridSelectEditor(props: {
    value, onChange, onComplete, dataSource, onCancel, onTabNavigation,
}) {
    const {
 value, onChange, onComplete, dataSource, onCancel, onTabNavigation,
} = props;
    const [data, setData] = useState(value || '');

    const handleChange = (event) => {
        onChange && onChange(event);
        setData(event);
    };

    const handleOnBlur = (event) => {
        onComplete && onComplete(event);
    };

    const handleOnKeyDown = (event) => {
        const { key } = event;
        if (key === 'Escape') {
            onCancel && onCancel(event);
        }
        if (key === 'Enter') {
            onComplete && onComplete(event);
        }
        if (key === 'Tab') {
            event.preventDefault();
            onTabNavigation && onTabNavigation(true, event.shiftKey ? -1 : 1);
        }
    };

    return (
      <Select
        showSearch
        style={selectStyle}
        value={data}
        onChange={handleChange}
        onBlur={handleOnBlur}
        onKeyDown={handleOnKeyDown}
        filterOption={(inputValue, option) => option.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
      >
        {dataSource.map((option, index) => (
          <option key={option.label} value={option.id}>
            {option.label}
          </option>
                ))}
      </Select>
    );
}
