/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {Fragment} from 'react';
import {useDeepCompareEffect} from 'use-deep-compare';
import {useImmer} from 'use-immer';
import * as Locale from '../../../common/locale';
import {useEntity} from '../../../hooks/use-entity';
import {PaymentLine} from './PaymentLine';

export function PaymentCard(props: {}) {
    const [state, updateState] = useImmer({ paymentsDetails: undefined });
    const { loadEntity } = useEntity();

    async function load() {
        const res = await loadEntity('payment/calculate');
        updateState((draft) => {
            draft.paymentsDetails = res;
        });
    }

    useDeepCompareEffect(() => {
        if (!state.paymentsDetails) {
            load().then();
        }
    }, [state.paymentsDetails]);

    return (
      <Fragment>
        {state.paymentsDetails && (
        <Fragment>
          <PaymentLine
            title={<Locale.Title tkey="payment.initialAmount" />}
            quantity={state.paymentsDetails.initialAmount.quantity}
            price={state.paymentsDetails.initialAmount.price}
          />
          <PaymentLine
            title={<Locale.Title tkey="payment.additionalProgramAmount" />}
            quantity={state.paymentsDetails.additionalProgramAmount.quantity}
            price={state.paymentsDetails.additionalProgramAmount.price}
          />
          <PaymentLine
            title={<Locale.Title tkey="payment.price" />}
            price={state.paymentsDetails.total}
          />
          {state.paymentsDetails.totalPaid > 0 && state.paymentsDetails.total - state.paymentsDetails.totalPaid > 0
                && (
                <div css={css`*{color:red !important}`}>
                  <PaymentLine
                    title={<Locale.Title tkey="payment.paid.total" />}
                    price={-(state.paymentsDetails.total - state.paymentsDetails.totalPaid)}
                  />
                </div>
)}
        </Fragment>
        )}
      </Fragment>
);
}
