// @ts-ignore
import { usePath } from 'hookrouter';
import React from 'react';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { CandidateFilesList } from '../../canSeeCandidateFile/candidateFilesList/CandidateFilesList';
import { CandidateFilesListModalContainer } from '../../canSeeCandidateFile/candidateFilesList/CandidateFilesListModalContainer';
import {
    CandidateFilesListInitialColumns,
    CandidateFilesListInitialFilters,
    renderRowContextMenu,
} from '../CandidateFilesListInitialConfig';
import { CustomPageHeader } from '../SpecializedSecretaryPage';

export function DefaultPage() {
    const { t } = useSafeTranslation();
    const path = usePath();
    const visible = path === '/home/welcome' || path === '/home/welcome/specialized-secretary';
    return (
      <CandidateFilesListModalContainer title={<CustomPageHeader />} visible={visible}>
        <CandidateFilesList
          initialColumns={CandidateFilesListInitialColumns(t)}
          renderRowContextMenu={renderRowContextMenu}
          gridStyle={{ height: 'calc( 100% - 100px)' }}
          initialFilters={CandidateFilesListInitialFilters}
          visible={visible}
        />
      </CandidateFilesListModalContainer>
);
}
