/**
 * Cette fonctionnalité d'administration permet de supprimer toutes les formations qui sont fermées des dossiers candidats en cours de constitution
 * (c-a-d en etat NEW). Cela permet de relancer des ouvertures de campagne en évitant que des candidats aient encore pu avoir choisi des formations qui ne sont 
 * pas rouvertes pour cette nouvelle ouverture
 */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Card, Divider, Form, Space, } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Fragment, useContext } from 'react';
import { useImmer } from 'use-immer';
import { AxiosContext, AxiosContextType } from '../../context/axios.context';
import { ResultPanel } from './common/ResultPanel';

export function CleanCloseProgram() {
  const { axios } = useContext<AxiosContextType>(AxiosContext);

  const [state, updateState] = useImmer({
    loading: false,
    error: undefined as string,
    result: undefined as string,
  });

  async function onFinish() {
    try {
      updateState((draft) => {
        draft.loading = true;
      });
      const result = await axios.post('/administration/cleanCloseProgram');
      updateState((draft) => {
        draft.result = `${result.data} formations supprimées`;
        draft.error = undefined;
      });
    } catch (ex) {
      updateState((draft) => {
        draft.error = ex.response.data.message;
        draft.result = undefined;
      });
    } finally {
      updateState((draft) => {
        draft.loading = false;
      });
    }
  }

  return (
    <Fragment>
      <Title level={2}>Suppression des formations fermées des dossiers de candidature "En cours"</Title>
      <Divider />
      <Space direction="vertical">
        <Card title="Instructions" style={{ width: 800 }}>
          <p>
            En cliquant sur le bouton ci-dessous, toutes les formations fermées (en administration) seront supprimées des sélections effectuées sur
            tous les dossier "en cours".
          </p>
          <p>
            <br />
            <strong><em>Attention :</em></strong>
            {' '}
            Si une formation a été fermée par erreur, elle sera supprimée des dossiers en cours qui l'auraient choisie !
          </p>

        </Card>
        <br />
        <Form
          name="impersonate"
          onFinish={onFinish}
          wrapperCol={{
            span: 8,
          }}
        >

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Suppression des sélections
            </Button>
          </Form.Item>
        </Form>
      </Space>
      <ResultPanel error={state.error} result={state.result} />
    </Fragment>
  );
}
