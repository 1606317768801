/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {AreaContainerPanel} from '../../../common/layout/AreaContainerPanel';

export function LeftAreaContainer(props: any) {
    return (
      <AreaContainerPanel css={css`
            grid-area: left;
            overflow-y: auto;
            overflow-x: hidden;
            height:fit-content;
            min-height:fit-content;
            max-height: 100%;
            padding-left: 0 !important;
           
`}
      >
        {props.children}
      </AreaContainerPanel>
    );
}
