/** @jsx jsx */
import React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { jsx } from '@emotion/core';
import { TextButtonWithoutTranslation } from './TextButtonWithoutTranslation';

/**
 * Simple Neutral Button
 * @param tkey
 * @param props
 * @constructor
 */
export function TextNeutralButtonWithoutTranslation({ ...props }:any) {
    return (
        <TextButtonWithoutTranslation type="default" icon={<CheckCircleFilled />} {...props}>
            {props.children}
        </TextButtonWithoutTranslation>
    );
}
