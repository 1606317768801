import styled from '@react-pdf/styled-components';
import React, { ReactNode } from 'react';
import { EmptyPDFValue } from '../../../pages/canSeePDF/candidateFilePDF/Empty';

const Container = styled.Text`
    color: ${(props) => (props.color ? props.color : '#555')};
`;

export function PDFText(props: { children?: ReactNode, debug?: boolean, style ?: any, color ?: string }) {
    return (
      <Container debug={props.debug} style={props.style} color={props.color}>
        {!props.children && <EmptyPDFValue />}
        {!!props.children && props.children }
      </Container>
    );
}
