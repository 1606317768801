/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {AdminEditableList} from '../../common/AdminEditableList';
import {
    adminListInitialColumns,
    adminListInitialFilters,
    adminListMapToRow,
    adminListRenderRowContextMenu,
} from './AdminListInitialConfig';

export function AdminListPage(props) {
    return (
      <Fragment>
        <AdminEditableList
          entity="admin/admin"
          checkboxColumn
          renderRowContextMenu={adminListRenderRowContextMenu}
          initialFilters={adminListInitialFilters}
          initialColumns={adminListInitialColumns()}
          mapToRow={adminListMapToRow}
        />
      </Fragment>
    );
}
