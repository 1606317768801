// @ts-ignore
import { usePath } from 'hookrouter';

/**
 * Extract n part of the current path, it's useful to reload aapp when some parts change
 * @param index
 */
export function usePathExtractor(index) {
    const path = usePath();
    const partPath = path.split('/')[index];
    return { partPath };
}
