import { CandidateFileStatusEnum } from '@fstn/ecandidaturev2_api-interfaces';
import React, { Fragment, useContext } from 'react';
import { useAppConfig } from '../../../hooks/use-app-config';
import { CandidateFileContext, CandidateFileContextProviderType } from '../../canSeeCandidateFile/CandidateFileContext';
import { UserRoleEnum } from '@fstn/ecandidaturev2_api-interfaces';
import moment from 'moment';

/**
 * Cette fonction permet de savoir si un candidat est en statut WAITING_CANDIDATE, ou si la periode d'ouverture de la plateforme
 * aux candidats est close : dans ces 2 cas, la homepage affichee sera le chat au lieu de la page d'accueil classique 
 * @param props 
 * @returns 
 */
export function IsWaitingCandidateOrCloseCandidate(props: { children?: any, elseChildren?: any }) {
  const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
  const { appConfig } = useAppConfig(UserRoleEnum.CANDIDATE);
  if (candidateFileContext?.selectedCandidateFile?.status?.id === CandidateFileStatusEnum.WAITING_CANDIDATE
    || !(moment().isBetween(appConfig.open, appConfig.close))) {
    return (
      <Fragment>
        {props.children}
      </Fragment>
    );
  }
  return (
    <Fragment>
      {props.elseChildren}
    </Fragment>
  );
}
