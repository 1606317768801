import { ICandidateFile } from '@fstn/ecandidaturev2_api-interfaces';
import { Draft } from 'immer';
import React, { useCallback } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import { CandidateFileStyle } from './CandidateFileStyle';
import { StrictLoading } from '../../common/indicator/SmartLoading';

export const CandidateFileContext = React.createContext({} as CandidateFileContextProviderType);

export interface CandidateFileContextProviderContentType {
    selectedCandidateFile?: Partial<ICandidateFile>;
    loaded: boolean
}

export interface CandidateFileContextProviderType {
  candidateFileContext?: CandidateFileContextProviderContentType
  updateCandidateFileContext?: (f: (draft: Draft<CandidateFileContextProviderContentType>) => void | CandidateFileContextProviderContentType) => void
}

export const CandidateFileContextProvider = (props: { children?: any, selectedCandidateFile?: Partial<ICandidateFile> }) => {
    // console.log('Redraw CandidateFileContextProvider', props.selectedCandidateFile);
    const [state, updateState] = useImmer<CandidateFileContextProviderContentType>({
        loaded: false,
        selectedCandidateFile: props.selectedCandidateFile,
    });

    useDeepCompareEffect(() => {
            if (!state.selectedCandidateFile?.id || props.selectedCandidateFile?.id !== state.selectedCandidateFile?.id) {
                    updateState((draft) => {
                        draft.loaded = true;
                        draft.selectedCandidateFile = props.selectedCandidateFile;
                    });
            }
    }, [updateState, props.selectedCandidateFile]);
    // loop add

    const updateStateWrapper = useCallback(
        (f: (draft: Draft<CandidateFileContextProviderContentType>) => void | CandidateFileContextProviderContentType) => {
            updateState((draft: Draft<CandidateFileContextProviderContentType>) => {
                f(draft);
            });
        }, [updateState],
    );

  return (
    <StrictLoading loading={!state.selectedCandidateFile}>
      <CandidateFileStyle>
        <CandidateFileContext.Provider value={{ candidateFileContext: state, updateCandidateFileContext: updateStateWrapper }}>
          {props?.children}
        </CandidateFileContext.Provider>
      </CandidateFileStyle>
    </StrictLoading>
  );
};
