import { UserRoleEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { notification } from 'antd';
import React, { useContext } from 'react';
import { useDeepCompareCallback } from 'use-deep-compare';
import Bugsnag from '@bugsnag/js';
import * as Locale from '../common/locale';
import { AxiosContext, AxiosContextType } from '../context/axios.context';
import { RoutesContext } from '../context/routes.context';
import { UserContextType } from '../context/user.context';
import { useRoutesDef } from './use-routes-def.hook';

export function useUser(userContext: UserContextType) {
    const unloadMe = async () => {
        Bugsnag.pauseSession();
        await userContext.updateUserCtx((draft) => {
            draft.user = undefined;
        });
        await axios.delete('/login');
    };

    const { updateRoutes } = useRoutesDef(useContext(RoutesContext), unloadMe);
    const { updateToken } = useContext<AxiosContextType>(AxiosContext);
    const { axios } = useContext<AxiosContextType>(AxiosContext);

    const cookie = async () => {
        await axios.get('/cookie');
    };

    const loadMe = useDeepCompareCallback(async (token?: string) => {
        try {
            let config = {};
            if (token) {
                config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
            }
            const res = await axios.get('/me', config);
            if (res?.data) {
                Bugsnag.setUser(res.data.id, res.data.email, `${res.data.firstName} ${res.data.lastName}`);
                if (res?.data?.role?.name === UserRoleEnum.CANDIDATE) {
                    await axios.patch(`/candidate-file/${res.data?.candidateFile?.id}`, { connectedAt: new Date() });
                }
                await userContext.updateUserCtx((draft) => {
                    draft.user = res.data;
                });
            }
            // need to update routes even if 401
            await updateRoutes({ ...userContext.userCtx, user: res?.data });
        } catch (error) {
            userContext.updateUserCtx((draft) => {
                draft.token = undefined;
            });
            if (window.location.pathname !== '/login') {
                // navigate("/login")s
                return Promise.reject(error);
            }
        }
        return undefined;
    }, [userContext]);
    const login = async (email: string, password: string, remember: string) => {
        try {
            localStorage.removeItem('CandidateFileContext');
            const res = await axios.post('/login', {
                username: email,
                password: password,
                remember,
            }, {});
            if (res?.status < 300) {
                const accessToken = res.data.access_token;
                await updateToken(accessToken);
                await loadMe(accessToken);
                Bugsnag.startSession();
                return res.data.payload;
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                await updateToken(undefined);
                notification.error({
                    message: <Locale.Title tkey="login.invalid" />,
                    description: <Locale.Help tkey="login.invalid" />,
                });
            } else {
                notification.warn({
                    message: <Locale.Title tkey="server.error" />,
                    description: <Locale.Help tkey="server.error" />,
                });
            }
            throw e;
        }
        return undefined;
    };

    return {
        cookie,
        unloadMe,
        loadMe,
        login,
    };
}
