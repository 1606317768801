import React, {
 MutableRefObject, useCallback, useRef, useState,
} from 'react';

/**
 *  LAURENT
 *  Use the React context API to stock IHM context
 *  https://fr.reactjs.org/docs/context.html
 */
export type IhmContextType = {
    ihmState: {
        scrollableFormContainer: MutableRefObject<any>,
        affixedScrollableFormContainer: any,
        waitingDebouned: boolean
    },
    updateIhmState: Function,
    startDebounced: ()=>void,
    completeDebounced: ()=>void,
    withBlockIhm: (cb: any) => Promise<any>,
    isNavigationAllowed: ()=>boolean,
};
export const IhmContext = React.createContext({} as IhmContextType);
/**
 *
 * scrollableFormContainer and affixedScrollableFormContainer are used by affic component to display titles on top of the scrolling container
 * @param props
 * @constructor
 */
const IIhmContextProvider = (props: { children: any }) => {
    const ref = useRef();

    /**
     * MUST NOT BE IMMER BECAUSE IT'S NOT COMPATIBLE WITH THE USER REF
     */
    const [ihmState, updateIhmState] = useState({
        scrollableFormContainer: ref,
        affixedScrollableFormContainer: undefined,
        waitingDebouned: false,
    });
    // console.log('Redraw IhmContextProvider', prev, ihmState);

    const startDebounced = useCallback(() => {
        // console.log('Redraw IhmContextProvider startDebounced');
       /* updateIhmState({
            ...ihmState,
        }); */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ihmState]);

    const completeDebounced = useCallback(() => {
        // console.log('Redraw IhmContextProvider completeDebounced');
        /* updateIhmState({
            ...ihmState,
        }); */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ihmState]);

    const withBlockIhm = useCallback(async (cb) => {
        // console.log('Redraw IhmContextProvider withBlockIhm');
        const timer = setTimeout(startDebounced, 1000);
        try {
            return cb();
        } finally {
            clearTimeout(timer);
            completeDebounced();
        }
    }, [completeDebounced, startDebounced]);
    const isNavigationAllowedV = ihmState.waitingDebouned;
    const isNavigationAllowed = useCallback(() => !isNavigationAllowedV, [isNavigationAllowedV]);

    return (
      <IhmContext.Provider
        value={{
 ihmState, updateIhmState, startDebounced, completeDebounced, isNavigationAllowed, withBlockIhm,
}}
      >
        {props?.children}
      </IhmContext.Provider>
    );
};

export const IhmContextProvider = IIhmContextProvider;
