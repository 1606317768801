import { ICandidateFile } from '@fstn/ecandidaturev2_api-interfaces';
import { parse } from 'flatted';
import { Draft } from 'immer';
import React, { useCallback } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';

export const CandidateFileListContext = React.createContext({} as CandidateFileListContextProviderType);

export interface CandidateFileListContextProviderContentType {
    selectedCandidateFiles?: Partial<ICandidateFile>[];
    loaded: boolean
}

export interface CandidateFileListContextProviderType {
  candidateFileListContext?: CandidateFileListContextProviderContentType
  updateCandidateFileListContext?: (f: (draft: Draft<CandidateFileListContextProviderContentType>) => void | CandidateFileListContextProviderContentType) => void
}

export const CandidateFileListContextProvider = ((props: { children?: any, selectedCandidateFiles?: Partial<ICandidateFile>[] }) => {
    // console.log('Redraw CandidateFileListContextProvider');
    const [state, updateState] = useImmer<CandidateFileListContextProviderContentType>({
        loaded: false,
        selectedCandidateFiles: props.selectedCandidateFiles,
    });

    useDeepCompareEffect(() => {
        try {
            const newState = parse(localStorage.getItem('CandidateFileListContext') as string);
            if (!state.selectedCandidateFiles || props.selectedCandidateFiles !== state.selectedCandidateFiles) {
                if (newState) {
                    updateState((draft) => {
                        draft.loaded = true;
                        draft.selectedCandidateFiles = newState.selectedCandidateFileLists;
                    });
                } else {
                    updateState((draft) => {
                        draft.loaded = true;
                        draft.selectedCandidateFiles = props.selectedCandidateFiles;
                    });
                }
            }
        } catch (e) {
            // ignore this
        }
    }, [updateState, props.selectedCandidateFiles]);
    // loop add

    const updateStateWrapper = useCallback(
        (f: (draft: Draft<CandidateFileListContextProviderContentType>) => void | CandidateFileListContextProviderContentType) => {
            updateState((draft: Draft<CandidateFileListContextProviderContentType>) => {
                f(draft);
//                localStorage.setItem('CandidateFileListContext', stringify(draft));
            });
        }, [updateState],
    );

  return (
    <CandidateFileListContext.Provider value={{ candidateFileListContext: state, updateCandidateFileListContext: updateStateWrapper }}>
      {props?.children}
    </CandidateFileListContext.Provider>
  );
});
