/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import { Popover } from 'antd';
import React, { Fragment, MutableRefObject, useCallback } from 'react';
import { ColdDataSelectorContainer } from '../../../../common/coldData/ColdDataSelectorContainer';

function SchoolTrackFilter(props: { onChange: Function, filterValue: any }) {
    const mapper = useCallback((p) => ({ id: p.id }), []);
    // La ligne css={css`.ant-select-selector {width: 300px !important}`} ci-dessous permet de rajouter directement du css
    // dans une classe existante utilisée. Ici, on ajoute width sur la classe ant-select-selector pour outrepasser son mode
    // "autoadapatif par defaut". Le !important permet de signaler que cette nouvelle propriete ne doit pas etre "redefinie" par
    // d'eventuelles nouvelles props appliquées ensuite
    return (
        <div className="InovuaReactDataGrid__column-header__filter-wrapper" css={css`.ant-select-selector {width: 200px !important}`}>
            <ColdDataSelectorContainer
                api="school-tracks"
                params="{ style: { width: '200px' } }"
                allowClear
                mapper={mapper}
                onChange={(v) => {
                    props.onChange({ ...props.filterValue, value: v });
                }}
            />
        </div>
    );
}

export const configsListInitialColumns = (t) => [
    {
        header: 'Filières',
        name: 'countSchoolTrack',

        defaultFlex: 2,
        lockable: false,
        filterable: true,
        filterEditor: SchoolTrackFilter,
        // filterEditorProps: { style: { width: '100%' } },
        render: ({ value }) => (
            <Popover content={<ul>{value.map?.((p) => <li key={p.id}>{p.id}</li>)}</ul>}>
                {value.length}
                {' '}
                filières
            </Popover>
        ),
    },
    {
        header: 'Nom établissement',
        name: 'name',
        type: 'string',
        defaultFlex: 2,

        editable: true,
        style: { textAlign: 'left' },
        lockable: false,
    },
    {
        header: 'Ville',
        name: 'nameCity',
        type: 'string',
        defaultFlex: 1,

        editable: false,
        style: { textAlign: 'left' },
        lockable: true,
    },
    {
        header: 'Code postal',
        name: 'zipCode',
        type: 'string',
        defaultFlex: 1,

        editable: false,
        style: { textAlign: 'left' },
        lockable: false,
    },
];

export const configsListInitialFilters = [
    {
        name: 'countSchoolTrack', operator: 'countSchoolTracks', type: 'countSchoolTracks', value: '',
    },
    {
        name: 'name', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'nameCity', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'zipCode', operator: 'contains', type: 'string', value: '',
    },
];

export function configsListRenderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedConfig) {
    return (
        <Fragment />
    );
}

/**
 * Map result to row
 * @param d
 */
export function configsListMapToRow(d: any) {
    const row: any = {
        id: d.id,
        name: d.name,
        countSchoolTrack: d.tracks,
        nameCity: d.city?.name,
        zipCode: d.city?.zipCode,
    };
    // console.log(row)
    return row;
}
