import React, { Fragment } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import BoolEditor from '@inovua/reactdatagrid-community/BoolEditor';

export const configsListInitialColumns = () => [
    {
        header: 'Nom',
        name: 'name',
        defaultFlex: 1,
        editable: true,
        style: { textAlign: 'left' },
    },
    {
        header: 'Position',
        name: 'ranking',
        type: 'number',
        defaultFlex: 1,
        editable: true,
        style: { textAlign: 'center' },
    },
    {
        header: 'Obligatoire',
        name: 'mandatory',
        defaultFlex: 1,
        editable: true,
        editor: BoolEditor,
        style: { textAlign: 'center' },
        render: (c) => (c.value ? <CheckCircleOutlined /> : ''),
    },
];

export const configsListInitialFilters = [
    {
        name: 'name', operator: 'contains', type: 'string', value: '',
    },
];

export function configsListRenderRowContextMenu() {
    return (
        <Fragment />
    );
}

/**
 * Map result to row
 * @param d
 */
export function configsListMapToRow(d: any) {
    const row: any = {
        ranking: d.ranking,
        name: d.name,
        mandatory: d.mandatory,
        id: d.id,
    };
    return row;
}
