import { AxiosInstance } from 'axios';
import React from 'react';
import { useImmer } from 'use-immer';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useLoading } from '../hooks/use-loading';
import { useIsMounted } from '../hooks/use-is-mounted';
import { StrictLoading } from '../common/indicator/SmartLoading';

type ConfigContextTypeContent = {
    max?: any
    config: {
        number: any,
        date: any,
    }
};
export type ConfigContextType = { configCtx?: ConfigContextTypeContent, updateConfigCtx?: any };
export const ConfigContext = React.createContext({} as ConfigContextType);
export const ConfigContextProvider = (props: { children: any, axios: AxiosInstance }) => {
    const { doAction, loading } = useLoading(true);
    const isMounted = useIsMounted();
    const [configCtx, updateConfigCtx] = useImmer<ConfigContextTypeContent>({
        config: {
            number: undefined as any,
            date: undefined as any,
        },
        max: async (property: string) => {
            const res = await props.axios.get(`/number-config/MAX_${property.toUpperCase()}`);
            return res?.data?.value;
        },
    });

    useDeepCompareEffect(() => {
        doAction(async () => {
            if (isMounted.current) {
                const number = (await props.axios.get('/number-config')).data;
                const date = (await props.axios.get('/date-config')).data;
                const config = {
                    number,
                    date,
                };
                updateConfigCtx((draft) => {
                    draft.config = config;
                });
            }
        });
    }, [isMounted.current, props.axios, updateConfigCtx]);

    return (
        <StrictLoading loading={loading} context="ConfigContextTypeContent">
            <ConfigContext.Provider value={{ configCtx, updateConfigCtx }}>
                {props?.children}
            </ConfigContext.Provider>
        </StrictLoading>
    );
};
