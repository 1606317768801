import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Popconfirm from 'antd/lib/popconfirm';
// @ts-ignore
import React, { Fragment, useContext } from 'react';
import * as Locale from '../../../common/locale';
import { UserContext, UserContextType } from '../../../context/user.context';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { EntityFormContextProvider } from '../../../context/entityForm.context';
import { TextDangerButton } from '../../../common/button/TextDangerButton';
import { useUser } from '../../../hooks/use-user.hook';
import { sNavigate } from '../../../utils/safeNavigation';
import { EntityFormListContextProvider } from '../../../context/entityFormList.context';
import { ROUTES } from '../UserRoutesDef';
import { ButtonsFormItemLayout } from '../../../common/form/buttonsFormItem/ButtonsFormItemLayout';

export function RgpdRefusedForm() {
    const [form] = useForm();
    const userCtx = useContext<UserContextType>(UserContext);
    const { loadMe } = useUser(userCtx);
    const { axios } = useContext<AxiosContextType>(AxiosContext);

    async function cancelRefused() {
        await axios.put('/rgpd/unset');
        await loadMe();
        sNavigate(ROUTES.BASE);
    }

    return (
      <Fragment>
        <Form
          layout="horizontal"
          form={form}
          className="rgpd-form"
          noValidate
        >
          <EntityFormContextProvider
            entity="rgpd.refused"
            isFieldTouched={form.isFieldTouched}
            getFieldValue={form.getFieldValue}
            getFieldsValue={form.getFieldsValue}
            onChange={() => {
                    }}
            onImmediateChange={() => {
                    }}
          >
            <EntityFormListContextProvider
              entity="rgpd.refused"
              propertyName=""
              remove={() => {
                        }}
            >

              <FormContentPanelContainer partName="rgpd.refused">
                <ButtonsFormItemLayout>
                  <Popconfirm
                    title={<Locale.Title tkey="rgpd.refused.cancel.confirm" />}
                    onConfirm={cancelRefused}
                    okText={<Locale.Button tkey="ok" />}
                    cancelText={<Locale.Button tkey="cancel" />}
                  >
                    <TextDangerButton tkey="rgpd.refused.cancel" />
                  </Popconfirm>
                </ButtonsFormItemLayout>
              </FormContentPanelContainer>
            </EntityFormListContextProvider>
          </EntityFormContextProvider>
        </Form>
      </Fragment>
    );
}
