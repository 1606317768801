/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment, useContext} from 'react';
import {Importer, ImporterField} from 'react-csv-importer-v2';

/**
 * LAURENT
 * DOCUMENTATION: npm i react-csv-importer-v2
 */
// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer-v2/dist/index.css';
import {useImmer} from 'use-immer';
import {AxiosContext, AxiosContextType} from '../../../../context/axios.context';
import {sNavigateReload} from '../../../../utils/safeNavigation';
import {ADMIN} from '../../../user/UserRoutesDef';
import {ResultPanel} from '../../common/ResultPanel';
import {UserListPage} from './UserListPage';

export function UserImporter() {
    const [state, updateState] = useImmer({
        values: [], error: undefined as string, result: undefined as string, details: [] as string[],
    });
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    return (
      <Fragment>
        <Importer
          delimiter=";"
                // restartable // optional, lets user choose to upload another file when import is complete
          onStart={() => {
                }}
          processChunk={async (rows) => {
                    updateState((draft) => {
                        draft.values = [...draft.values, ...rows];
                    });
                }}
          onComplete={async () => {
                    try {
                        const response: any = await axios?.post('/import/user', {
                            bulk: state.values,
                        });
                        updateState((draft) => {
                            draft.result = `${response.data.created} utilisateurs importés`;
                            if (response.data.ignored.length > 0) {
                                draft.details.push('Utilisateurs déjà existants :');
                                response.data.ignored.forEach((elem) => {
                                    draft.details.push(`- ${elem.email} : ${elem.role}`);
                                });
                            }
                            draft.error = undefined;
                        });
                    } catch (ex) {
                        updateState((draft) => {
                            draft.error = ex.response.data.message;
                            draft.result = undefined;
                        });
                    }
                }}
          onClose={async () => {
                    sNavigateReload(ADMIN.USER_IMPORTER);
                }}
        >
          <ImporterField name="role" label="role" />
          <ImporterField name="lastName" label="last_name" />
          <ImporterField name="firstName" label="first_name" />
          <ImporterField name="email" label="email" />
          <ImporterField name="program_name" label="program_name" optional />
          <ImporterField name="option_name" label="option_name" optional />
        </Importer>
        <br />
        <ResultPanel
          error={state.error}
          result={state.result}
          redirect={ADMIN.USER_IMPORTER}
          details={state.details}
        />
        <br />
        <span><h1>Liste des utilisateurs</h1></span>
        <UserListPage />
      </Fragment>
    );
}
