/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {AdminEditableList} from '../../common/AdminEditableList';
import {
    secretaryListInitialColumns,
    secretaryListInitialFilters,
    secretaryListMapToRow,
    secretaryListRenderRowContextMenu,
} from './SecretaryListInitialConfig';

export function SecretaryListPage(props) {
  return (
    <Fragment>
      <AdminEditableList
        entity="admin/secretary"
        checkboxColumn
        renderRowContextMenu={secretaryListRenderRowContextMenu}
        initialFilters={secretaryListInitialFilters}
        initialColumns={secretaryListInitialColumns()}
        mapToRow={secretaryListMapToRow}
      />
    </Fragment>
  );
}
