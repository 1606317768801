/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Empty, Form } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { StoreValue } from 'rc-field-form/lib/interface';
import { Fragment, useContext } from 'react';
import { useImmer } from 'use-immer';
import UUID from 'uuid-js';

import { IRights, IVersionedBaseStringId } from '@fstn/ecandidaturev2_api-interfaces';
import { EntityFormContext } from '../../../context/entityForm.context';
import { EntityFormListContext, EntityFormListContextProvider } from '../../../context/entityFormList.context';
import { UserContext } from '../../../context/user.context';
import { useValidation } from '../../../hooks/use-validation.hook';
import { getDisabledForWrite } from '../../../utils/formDisableStateManager';
import { CenterButtonsFormItemLayout } from '../buttonsFormItem/CenterButtonsFormItem';
import { EntityFormItemContainer } from './EntityFormItemContainer';
import { FormContentPanelContainer } from '../formContentPanel/FormContentPanelContainer';
import { InputFormItem } from '../inputFormItems/InputFormItem';
import { ItemListAddButton } from '../buttonsFormItem/ItemListAddButton';
import { ItemListRemoveButton } from '../buttonsFormItem/ItemListRemoveButton';
import { FormListFieldData } from '../type';
import { WithInitialLoadContext, WithInitialLoadContextType } from '../../withExtraParameters/WithInitialLoad';
import { DisabledContext, DisabledContextProvider } from '../../rights/DisabledContext';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

/**
 * FormListItemContainer: Form Item that contains list of entities.
 * For example, list of stays abroads
 * Provides ItemListAddButton and ItemListRemoveButton
 * @see ItemListAddButton
 * @see ItemListRemoveButton
 * @param props
 * @constructor
 */
function FormListItemContainer(props: {
  children: any,
  remove: (value: any) => Promise<void>,
  hideRemove?: boolean,
  propertyName: string | any[]
}) {
  const mandatory = useScopedSelector<any[], boolean>('FormListItemContainer_mandatory', EntityFormListContext, (c) => c[0]?.entityValue?.mandatory);
  const [state, updateSate] = useImmer({ overDelete: false });
  const disabled = useScopedSelector('FormListItemContainer_disabled', DisabledContext, (c) => c?.disabled);

  // noinspection TypeScriptUnresolvedVariable
  return (
    <Fragment>

      <div
        onMouseOver={() => updateSate((draft) => {
          draft.overDelete = true;
        })}
        onMouseLeave={() => updateSate((draft) => {
          draft.overDelete = false;
        })}
        css={css`    
                    button[data-testid="itemListRemoveButton"]{
                    padding-left: 5px; 
                    padding-right: 5px;}
                    position: absolute;
                    z-index: 1;
                    right: 8px;
                    margin-top: 0.3em;
                    margin-right: 0.3em;`}
      >
        {!props.hideRemove && (
          <ItemListRemoveButton
            /* delete for write and not for delete because it's based on parent rights */
            disabled={disabled || mandatory}
            remove={props?.remove}
            property={props?.propertyName as string}
          />
        )}
      </div>
      <div css={css`
    margin: 0.5em;
.ant-card-head{
  background-color: ${state.overDelete ? '#ff7875 !important' : ''}
}
.ant-card-body{
  background-color: ${state.overDelete ? '#FFEDED !important' : ''}
}
`}
      >
        {props?.children}
      </div>
    </Fragment>
  );
}

// language=LESS prefix=*{ suffix=}
const Style = styled.div`
      h3{
        text-transform: uppercase;
        width: 100%;
        border-bottom: ${(p) => `1px solid ${(p.theme as any)?.primary}`};
        color: ${(p) => (p.theme as any)?.primary};

      }
`;

function EntityFormListContextProviderContent<T>(props: {
  propertyName: any,
  children: any,
  removeDecorator?: (remove: () => void, value: T) => {},
  hideRemove?: boolean,
  remove: Function,
  validate: Function
}) {
  const formEntity = useScopedSelector<any[], string>('EntityFormListContextProviderContent', EntityFormContext, (c) => c[0]?.entity);
  const getFieldsValue = useScopedSelector<any[], any>('EntityFormListContextProviderContent', EntityFormContext, (c) => c[0]?.getFieldsValue);

  return (
    <FormListItemContainer
      hideRemove={props.hideRemove}
      remove={async (value) => {
        // this remove change the flag to remove without waiting the debounced
        if (props?.removeDecorator) {
          props?.removeDecorator(async () => {
            props.remove();
          }, value);
        } else {
          await props.remove();
        }
        await props.validate(formEntity, getFieldsValue());
      }}
      propertyName={props?.propertyName}
    >
      {props?.children}
    </FormListItemContainer>
  );
}

/**
 * 
 * @param props Le hackRefresh permet, quand on lui met une nouvelle valeur, de forcer le rechargement complet de la liste (notamment quand on ajoute un cursus
 * qui doit s'intercaler au bon endroit en fonction du tri)
 * On le passe au composant "EntityFormItemContainer" qui refresh la liste si une nouvelle valeur est positionnée
 * @returns 
 */
export function EntityFormListContainer<T extends IVersionedBaseStringId>(props: {
  children?: any,
  headerDetails?: any,
  entity: string,
  propertyName: string | any[],
  compact?: boolean,
  hideRemove?: boolean,
  ignoreMaxLimit?: boolean,  // permet d'indiquer si on autorise la saisie de plus de valeurs que défini dans la parametre MAX_XXX
  sort?: (f1: FormListFieldData, f2: FormListFieldData) => number,
  hackRefresh?: any,
  addDecorator?: (add: (defaultValue?: StoreValue) =>
    void,
    remove: any) => any,
  removeDecorator?: (remove: () => void, value: T) => any
}) {
  // console.log('Redraw EntityFormListContainer', props);
  const withInitialLoadCtx = useContext<WithInitialLoadContextType>(WithInitialLoadContext);
  const formRights = useScopedSelector<any[], IRights>('EntityFormListContainer_formRights', EntityFormContext, (c) => c[0]?.entityValue?.rights);
  const formEntity = useScopedSelector<any[], string>('EntityFormListContainer_formEntity', EntityFormContext, (c) => c[0]?.entity);
  const getFieldsValue = useScopedSelector<any[], any>('EntityFormListContainer_getFieldsValue', EntityFormContext, (c) => c[0]?.getFieldsValue);
  const onImmediateChange = useScopedSelector<any[], any>('EntityFormListContainer_onImmediateChange', EntityFormContext, (c) => c[0]?.onImmediateChange);
  const setFieldsValue = useScopedSelector<any[], any>('EntityFormListContainer_setFieldsValue', EntityFormContext, (c) => c[0]?.setFieldsValue);
  const getFieldValue = useScopedSelector<any[], any>('EntityFormListContainer_getFieldValue', EntityFormContext, (c) => c[0]?.getFieldValue);
  const formDisabledProps = useScopedSelector<any[], any>('EntityFormListContainer_formDisabled', EntityFormContext, (c) => c[0]?.disabled);
  const userCtx = useContext(UserContext);

  const { validate } = useValidation();
  const disabled = useScopedSelector('EntityFormListContainer_disabled', DisabledContext, (c) => c?.disabled);
  return (
    <Style>
      <FormContentPanelContainer partName={props?.propertyName as string} compact={props.compact}>
        {props.headerDetails}
        <EntityFormItemContainer
          propertyName={props?.propertyName}
          label="" wrapperCol={{ span: 24 }}
          hasFeedback={false}
          skipRerenderOnValidateChange
          dependenciesValues={props.hackRefresh}
        >
          <Form.List name={props?.propertyName}>
            {(fields, { add, remove }: { add: (defaultValue?: StoreValue) => void, remove: (index: number | number[]) => void }) => {
              let sortedFields = [];
              if (props?.sort) {
                sortedFields = fields.sort(props?.sort);
              } else {
                sortedFields = _.orderBy(fields, 'createdAt', 'asc');
              }

              async function removeItem(i) {
                /* Le probleme ici : le getFieldsValue() récupère la liste sans application du tri, donc cette liste n'est pas dans le meme
                   ordre que celle affichée à l'utilisateur donc il faut trouver la correspondance à partir de la liste triée (sortedFields)
                   en prenant la key (index dans la tableau non trié) pour supprimer le bon element selectionné par l'utilisateur
                */
                const indexToRemove = sortedFields[i].key;  // Recuperation du bon index a partir de la liste triée
                const listValues = getFieldsValue()[`${props?.propertyName}`].filter((v, index) => (indexToRemove !== index));
                const newValues = getFieldsValue();
                newValues[`${props?.propertyName}`] = listValues;
                setFieldsValue(newValues);
                await onImmediateChange({ [`${props?.propertyName}`]: listValues });

                // workaround
                // bug de desynchro aprés une suppression du coup on recharge tout pour être synchro
                await withInitialLoadCtx.reload();
              }

              return (
                <Fragment>
                  {sortedFields.filter((field) => !!field).map((field: FormListFieldData, i) => {
                    const fieldValue = getFieldValue(props?.propertyName)?.[field.name];
                    if (!fieldValue) {
                      return <Empty />;
                    }
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div
                        css={css`margin-bottom: 10px;box-shadow: 1px 0px 6px 1px rgba(156,156,156,1);`}
                        key={`${fieldValue.id}`}
                        id={`${fieldValue.id}`}
                      >
                        <DisabledContextProvider value={{
                          disabled: getDisabledForWrite(userCtx.userCtx, formEntity, formRights),
                          wrapAuthorized: true,
                          context: 'EntityFormListContainer',
                        }}
                        >
                          <EntityFormListContextProvider
                            remove={() => removeItem(i)}
                            entity={props?.entity}
                            propertyName={props?.propertyName}
                            entityValue={fieldValue}
                            field={field}
                          >
                            <EntityFormListContextProviderContent<T>
                              validate={validate}
                              removeDecorator={props.removeDecorator}
                              hideRemove={props.hideRemove}
                              remove={() => removeItem(i)}
                              propertyName={props.propertyName}
                            >
                              <InputFormItem propertyName="rights" hidden label="" help="" />
                              <InputFormItem propertyName="id" hidden label="" help="" />
                              {props.children}
                            </EntityFormListContextProviderContent>
                          </EntityFormListContextProvider>
                        </DisabledContextProvider>
                      </div>
                    );
                  })}
                  {!formDisabledProps
                    && (
                      <div css={css`margin-top: 1em;margin-bottom: 1em`}>
                        <CenterButtonsFormItemLayout>
                          <ItemListAddButton
                                          /* ok */ disabled={disabled}
                            ignoreMaxLimit={props.ignoreMaxLimit}
                            add={async () => {
                              if (props?.addDecorator) {
                                props?.addDecorator(add, removeItem);
                              } else {
                                // if we are secretary, we switched to edit mode so we need to set the right rights
                                add({
                                  id: UUID.create(1).toString(),
                                  createdAt: moment.utc(),
                                  rights: formRights,
                                });
                              }
                            }}
                            property={props?.propertyName as string}
                            items={sortedFields}
                          />
                        </CenterButtonsFormItemLayout>
                      </div>
                    )}
                </Fragment>
              );
            }}
          </Form.List>
        </EntityFormItemContainer>
      </FormContentPanelContainer>
    </Style>
  );
}
