import _, { flatMap } from 'lodash';
import React, { useContext } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import { ColdDataAutoComplete } from './ColdDataAutoComplete';
import { onOptionChange } from './utils';
import { ColdDataContext, ColdDataContextType } from '../../context/coldData.context';

/**
 * Select Input for Autocomplete ColdData
 * @param props
 * @constructor
 */
export function ColdDataAutoCompleteContainer(props: any) {
    const { coldDataCtx } = useContext<ColdDataContextType>(ColdDataContext);
    const { coldData } = coldDataCtx;

    if (!coldData) {
        throw new Error('Missing Cold data context');
    }
    if (!props.onChange) {
        throw Error('Missing onChange for  ColdDataSelectorContainer');
    }
    const [state, updateState] = useImmer({
        data: undefined,
        value: props.value,
        propertyName: props.propertyName,
    });

    useDeepCompareEffect(() => {
        updateState((draft) => {
            draft.value = props.value;
        });
    }, [props.value, updateState]);

    // console.log('Redraw ColdDataAutoCompleteContainer');
    useDeepCompareEffect(() => {
        let data = coldData[props.api];
        if (props?.filter) {
            data = data?.filter(props?.filter);

            if (props.value && !data?.map((d) => d.id).includes(props.value.id)) {
                updateState((draft) => {
                    draft.value = undefined;
                    props.onChange(undefined);
                });
            }
        }
        if (data && props?.mapper) {
            data = flatMap(data, props?.mapper);
        }
        if (!_.isEqual(data, state.data)) {
            if (data) {
                props.listeners?.dataLoaded?.(data);
            }
            /**
             * If selected value not if current available value, we remove it.
             * It can happens if we choose the programType and the school doesn't match anymore
             */
            if (state.value && !_.isEqual(props.value, state.value)) {
                props.onChange(props.value);
            }
            updateState((draft) => {
                draft.data = data;
                draft.value = props.value;
            });
        }
    }, [coldData, props, state.value]);

    const notReady = !state.data || !Array.isArray(state.data);

    return (
      <ColdDataAutoComplete
        id={props.id}
        className={props.className}
        data-testid={props['data-testid']}
        disabled={notReady || props.disabled}
        value={props.value}
        onChange={onOptionChange(state.data, props.onChange)}
        onOptionChange={onOptionChange}
        api={props.api}
        allowClear={props.allowClear}
        options={state.data}
      />
    );
}
