import React, { useContext, useEffect, useState } from 'react';
import { useEntity } from '../hooks/use-entity';
import { AxiosContext, AxiosContextType } from './axios.context';
import { UserContext } from './user.context';

export type UserConfigContextTypeContent = { config: any, alreadyLoaded?: boolean };
export type UserConfigContextType = {
    config?: UserConfigContextTypeContent,
    updateTableConfig?: Function,
    resetTableConfig?: Function,
    getTableConfig?: Function,
};
export const UserConfigContext = React.createContext({} as UserConfigContextType);
export const UserConfigContextProvider = (props: { children: any }) => {
    const { patchEntity } = useEntity<any>();
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const userCtx = useContext(UserContext);
    const [config, setConfig] = useState(undefined);
    const [alreadyLoaded, setAlreadyLoaded] = useState(undefined);

    // loop add

    const id = userCtx.userCtx.user?.id;

    useEffect(() => {
        async function load() {
            const resConfig = await axios.get(`/user-config/${id}`);
            await setConfig(resConfig.data?.value);
        }
       if (!alreadyLoaded) {
           setAlreadyLoaded(true);
           load().then();
       }
    }, [axios, alreadyLoaded, setConfig, setAlreadyLoaded, id]);
    // loop add

    async function updateTableConfig(tableName: string, configName: string, _config: any) {
        async function save() {
            const resConfig = (await axios.get(`/user-config/${id}`)).data?.value;
            const updatedConfig = { ...resConfig, [tableName]: { ...resConfig[tableName], [configName]: _config } };
            await patchEntity('user-config', { id: userCtx?.userCtx?.user?.id },
                { value: updatedConfig });
            setConfig(updatedConfig);
        }
        if (Object.keys(config)) {
            await save();
        }
    }

    function getTableConfig(tableName: string, configName: string) {
        const v = config?.[tableName]?.[configName];
        if (!v) {
            return v;
        }
        if (Array.isArray(v)) {
            return [...v];
        }
            return { ...v };
    }

    function resetTableConfig(tableName: string) {
        async function save() {
            const updatedConfig = { ...config, [tableName]: {} };
            await patchEntity('user-config', { id: userCtx?.userCtx?.user?.id },
                { value: updatedConfig });
            setConfig(updatedConfig);
        }

        if (Object.keys(config)) {
            save().then();
        }
    }

    return (
      <UserConfigContext.Provider
        value={{
 config, updateTableConfig, resetTableConfig, getTableConfig,
}}
      >
        {config && props?.children}
      </UserConfigContext.Provider>
    );
};
