import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import React from 'react';
import { copyEntityFormProps, EntityFormItemContainer, ItemProps } from '../entityForm/EntityFormItemContainer';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { DisabledContext } from '../../rights/DisabledContext';
import { Debounced } from '../../withExtraParameters/Debounced';

function InputPasswordFormItemContent(props: { propertyName: string | any[] | number } & InputProps) {
  const disabled = useScopedSelector('InputPasswordFormItemContent', DisabledContext, (c) => c?.disabled);

  return (
    <Input.Password
      data-testid={`${props.propertyName}`?.replace(/,/g, '_')}
      type="password"
      allowClear
      size="middle"
      onPressEnter={props.onPressEnter}
      addonBefore={props.addonBefore}
      addonAfter={props.addonAfter}
      prefix={props.prefix}
      suffix={props.suffix}
      bordered={props.bordered}
      readOnly={disabled}
      autoComplete={props.autoComplete}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onClick={props.onClick}
      onFocus={props.onFocus}
    />
);
}

/**
 * EntityFormItemContainer for password text input
 * @param props
 * @constructor
 */
export function InputPasswordFormItem(props: ItemProps & Omit<InputProps, 'disabled'>) {
    return (
      <EntityFormItemContainer
        {...copyEntityFormProps(props)}
      >
        <Debounced>
          <InputPasswordFormItemContent propertyName={props.propertyName} autoComplete={props.autoComplete} />
        </Debounced>
      </EntityFormItemContainer>
);
}
