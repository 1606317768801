/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Card } from 'antd';
import React, { Fragment } from 'react';
import { Box, Flex } from 'rebass';
import { useDeepCompareCallback } from 'use-deep-compare';
import UUID from 'uuid-js';

import { ColdDataSelectorFormItem } from '../../../common/form/inputFormItems/ColdDataSelectorFormItem';
import { DatePickerFormItem } from '../../../common/form/inputFormItems/DatePickerFormItem';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import { UploadFormItemContainer } from '../../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import * as Locale from '../../../common/locale';
import { EntityFormContext } from '../../../context/entityForm.context';
import { EntityFormListContext } from '../../../context/entityFormList.context';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { useSeparator } from '../../../hooks/use-separator.hook';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

const entity = 'stay-abroad';
export function StayAbroadForm() {
  const responsive = useResponsiveForm();
  useSeparator();
  const formListEntity = useScopedSelector<any[], string>('StayAbroadForm', EntityFormListContext, (c) => c[0]?.entity);
  const field = useScopedSelector<any[], any>('StayAbroadForm', EntityFormListContext, (c) => c[0].field);
  const country = useScopedSelector<any[], string>('StayAbroadForm', EntityFormListContext, (c) => c[0]?.entityValue?.country?.id);
  const formListEntityId = useScopedSelector<any[], string>('StayAbroadForm', EntityFormListContext, (c) => c[0]?.entityValue?.id);
  const getFieldValue = useScopedSelector<any[], any>('LanguageForm', EntityFormContext, (c) => c[0]?.getFieldValue);

  const addStayAbroadDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: `${country}_stayAbroadDocument`,
    type: 'stayAbroadDocument',
    parentType: formListEntity,
    parentId: formListEntityId,
  }), [formListEntityId, entity]);

  return (
    <Card
      id={formListEntityId}
      key={formListEntityId}
      title={<Fragment>{getFieldValue(['staysAbroad', field.name, 'country'])?.id || getFieldValue(['staysAbroad', field.name, 'country']) || <Locale.Title tkey="staysAbroad" />}</Fragment>}
      css={css``}
    >
      <Flex name="staysAbroad" flexWrap="wrap">
        <Box {...responsive.w1}>
          <ColdDataSelectorFormItem
            api="stay-abroad-reasons"
            propertyName={['staysAbroad', field.name, 'reason']}
          />
          <InputFormItem propertyName={['staysAbroad', field.name, 'precision']} />
          <DatePickerFormItem propertyName={['staysAbroad', field.name, 'beginDate']} />
          <DatePickerFormItem propertyName={['staysAbroad', field.name, 'endDate']} />
          <UploadFormItemContainer
            propertyName={['staysAbroad', field.name, 'stayAbroadDocument']}
            factory={addStayAbroadDocument}
            accept={'image/*,.pdf'}
          />
        </Box>
      </Flex>
    </Card>
  );
}
