import {useRoutes} from 'hookrouter';
import React, {useContext, useRef} from 'react';
import {useDeepCompareMemo} from 'use-deep-compare';
import {UserConfigContextProvider} from '../../../context/user.config.context';
import {UserContext, UserContextType} from '../../../context/user.context';
import {CandidateFileListContextProvider} from '../../canSeeCandidateFile/CandidateFileListContext';
import {DefaultPage} from './DefaultPage';
import {SecretaryContainerRoutesDef} from './SecretaryContainerRoutesDef';
import {useIsRootPath} from '../../../hooks/use-is-root-path.hook';

export function SecretaryContainer() {
    const { userCtx } = useContext<UserContextType>(UserContext);
    const routesChildren = useRoutes(SecretaryContainerRoutesDef());
    const { isRoot } = useIsRootPath();
    const oldProps = useRef({});
    return useDeepCompareMemo(() => {
        //console.log('Redraw SecretaryContainer', oldProps.current, [userCtx.user, routesChildren, isRoot]);
        oldProps.current = [userCtx.user, routesChildren, isRoot];
        return (
          <CandidateFileListContextProvider>
            <UserConfigContextProvider>
              {!isRoot && userCtx.user && <DefaultPage /> }
              {routesChildren}
            </UserConfigContextProvider>
          </CandidateFileListContextProvider>
        );
    }, [userCtx.user, isRoot]);
}
