/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { InputFilter } from '../filters/InputFilter';
import { PopOverCommentsFlat } from '../PopOverCommentsFlat';
import * as Locale from '../../../../common/locale';

export const CommentsBtwAllTypesOfSecretariesFilter = InputFilter;
export const commentsBtwAllTypesOfSecretariesFilterEditorProps = {
    className: 'commentsBtwAllTypesOfSecretariesFilter',
    'test-id': 'commentsBtwAllTypesOfSecretariesFilter',
};

export function CommentsBtwAllTypesOfSecretariesCellRender({ value, data }: any) {
        return (
          <Fragment
            key={`commentsBtwAllTypesOfSecretaries_cell_${data.id}`}
          >
            <PopOverCommentsFlat
              content={value}
              tkey="commentsBtwAllTypesOfSecretaries"
            />
          </Fragment>
        );
    }

export const commentsBtwAllTypesOfSecretariesColumn = {
    header: <Locale.Header tkey="candidateFilesList.commentsBtwAllTypesOfSecretaries" />,
    name: 'commentsBtwAllTypesOfSecretaries',
    group: 'candidateFile',
    id: 'commentsBtwAllTypesOfSecretaries',
    defaultWidth: 150,
    filterEditor: CommentsBtwAllTypesOfSecretariesFilter,
    filterEditorProps: commentsBtwAllTypesOfSecretariesFilterEditorProps,
    render: CommentsBtwAllTypesOfSecretariesCellRender,
};

export const commentsBtwAllTypesOfSecretariesWith = 300;

export const commentsBtwAllTypesOfSecretariesFilter = {
    name: 'commentsBtwAllTypesOfSecretaries', operator: 'contains', type: 'string', value: '',
};

export const commentsBtwAllTypesOfSecretariesType = {
};
