import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import React, { MutableRefObject } from 'react';
import { LocaleListContextMenu } from './LocaleListContextMenu';
import { getSelectedElements } from '../../common/AdminEditableList';

export const localesListInitialColumns = (t) => [
    {
        header: 'Entrée',
        name: 'id',
        defaultFlex: 1,
        editable: false,
        style: { textAlign: 'right' },

    },
    {
        header: 'Traduction',
        name: 'value',
        defaultFlex: 2,
        defaultWidth: 640,
        lockable: false,
        filterable: true,
    },
];

export const localesListInitialFilters = [
    {
        name: 'id', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'value', operator: 'contains', type: 'string', value: '',
    },
];

/**
 * Fonction de gestion du menu contextuel dans la grid
 * @param menuProps
 * @param details
 * @param selectedLocales
 * @param gridRef
 * @returns
 */
export function localesListRenderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedLocales, gridRef) {
    return (
      <LocaleListContextMenu
        menuProps={menuProps}
        details={details}
        selectedLocales={getSelectedElements(details.rowProps.data, selectedLocales)}
        gridRef={gridRef}
      />
    );
}

/**
 * Map result to row
 * @param d
 */
export function localesListMapToRow(d: any) {
    const row: any = {
        id: d.id,
        value: d.value,
    };
    return row;
}
