import styled from '@react-pdf/styled-components';
import React, { ReactNode } from 'react';

export const Container = styled.View`
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.85);
    font-size: 8px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
    border: ${(props) => (props.bordered ? '1px solid #f0f0f0' : '0px')};
    position: relative;
`;

const Title = styled.View` 
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 10px;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    align-content: center;
    border: ${(props) => (props.bordered ? '1px solid #f0f0f0' : '0px')};
    border-radius: 2px;
    padding-top: 7px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    background-color: ${(props) => (props.bgColor ? props.bgColor : 'dodgerblue')};
    color: white;
`;

const Content = styled.View`
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    align-content: center;
      font-size: 9px;
      padding: 5px;
`;

export function PDFCard(props:{ title:ReactNode, children?: ReactNode, debug?: boolean, bordered: boolean, titleBgColor?: string }) {
    return (
      <Container debug={props.debug} bordered={props.bordered}>
        <Title debug={props.debug} bordered={props.bordered} bgColor={props.titleBgColor}>
          {props.title}
        </Title>
        <Content debug={props.debug}>
          {props.children}
        </Content>
      </Container>
    );
}
