/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/core';
import { useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { AxiosContext, AxiosContextType } from '../../context/axios.context';
import { StrictLoading } from '../../common/indicator/SmartLoading';
import * as Locale from '../../common/locale';
import { Divider, Layout, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { useSafeTranslation } from '../../hooks/use-safe-translation';


/**
 * Genere la page avec les 3 tableaux de statistiques, ainsi que la courbe tracee depuis le premier tableau a l'aide du composant "recharts"
 * Le premier tableau pouvant contenir beacoup de lignes (entre 40 et 60), il est prposé avec un ascenseur pour eviter une page globale trop grande
 * @returns 
 */
export function CandidateFilesStatistics() {
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const [state, updateState] = useImmer({ statistics: undefined });
    const { t } = useSafeTranslation();

    //const formatDate =
    const columnsDailyStatistics = [
        {
            title: <Locale.Label tkey='dailyEventDate' />,
            dataIndex: 'event_date',
            key: 'event_date',
            width: '150px'
        },
        {
            title: <Locale.Label tkey='dailyDayCreated' />,
            dataIndex: 'day_created',
            key: 'day_created',
            width: '100px'
        },
        {
            title: <Locale.Label tkey='dailyDaySubmitted' />,
            dataIndex: 'day_submitted',
            key: 'day_submitted',
            width: '100px'
        },
        {
            title: <Locale.Label tkey='dailyTotalCreated' />,
            dataIndex: 'total_created',
            key: 'total_created',
            width: '100px'
        },
        {
            title: <Locale.Label tkey='dailyTotalSubmitted' />,
            dataIndex: 'total_submitted',
            key: 'total_submitted',
            width: '100px'
        },
        {
            title: <Locale.Label tkey='previousDailyTotalCreated' />,
            dataIndex: 'previous_total_created',
            key: 'previous_total_created',
            width: '100px'
        },
        {
            title: <Locale.Label tkey='previousDailyTotalSubmitted' />,
            dataIndex: 'previous_total_submitted',
            key: 'previous_total_submitted',
            width: '100px'
        }
    ];

    const columnsGlobalStatistics = [
        {
            title: <Locale.Label tkey='globalTotal' />,
            dataIndex: 'total',
            key: 'total',
            width: '100px'
        },
        {
            title: <Locale.Label tkey='globalTotalSubmitted' />,
            dataIndex: 'total_submitted',
            key: 'total_submitted',
            width: '100px'
        },
        {
            title: <Locale.Label tkey='globalTotalValid' />,
            dataIndex: 'total_valid',
            key: 'total_valid',
            width: '120px'
        },
        {
            title: <Locale.Label tkey='globalTotalWaitingCandidate' />,
            dataIndex: 'total_waiting_candidate',
            key: 'total_waiting_candidate',
            width: '140px'
        },
        {
            title: <Locale.Label tkey='globalTotalInProgressSecretary' />,
            dataIndex: 'total_in_progress_secretary',
            key: 'total_in_progress_secretary',
            ellipsis: true,
            width: '120px'
        },
        {
            title: <Locale.Label tkey='globalTotalUnprocessed' />,
            dataIndex: 'total_unprocessed',
            key: 'total_unprocessed',
            width: '150px'
        },
        {
            title: <Locale.Label tkey='globalTotalNew' />,
            dataIndex: 'total_new',
            key: 'total_new',
            width: '120px'
        }
    ];

    const columnsStatisticsByProgram = [
        {
            title: <Locale.Label tkey='globalProgramName' />,
            dataIndex: 'program_name',
            key: 'program_name',
            ellipsis: true,
            width: '500px'
        },
        {
            title: <Locale.Label tkey='globalTotal' />,
            dataIndex: 'total',
            key: 'total',
            width: '100px'
        },
        {
            title: <Locale.Label tkey='globalTotalSubmitted' />,
            dataIndex: 'total_submitted',
            key: 'total_submitted',
            width: '100px'
        },
        {
            title: <Locale.Label tkey='globalTotalValid' />,
            dataIndex: 'total_valid',
            key: 'total_valid',
            width: '100px'
        },
        {
            title: <Locale.Label tkey='globalTotalWaitingCandidate' />,
            dataIndex: 'total_waiting_candidate',
            key: 'total_waiting_candidate',
            width: '120px'
        },
        {
            title: <Locale.Label tkey='globalTotalInProgressSecretary' />,
            dataIndex: 'total_in_progress_secretary',
            key: 'total_in_progress_secretary',
            ellipsis: true,
            width: '100px'
        },
        {
            title: <Locale.Label tkey='globalTotalUnprocessed' />,
            dataIndex: 'total_unprocessed',
            key: 'total_unprocessed',
            width: '100px'
        },
        {
            title: <Locale.Label tkey='globalTotalNew' />,
            dataIndex: 'total_new',
            key: 'total_new',
            width: '100px'
        }
    ];


    useEffect(() => {
        async function load() {

            const res = await axios.get('candidate-file/globalStatistics');

            updateState((draft) => {
                draft.statistics = res.data;
            });
        }
        load().then();
    }, [axios, updateState]);

    return (
        <StrictLoading loading={!state.statistics} tip="Compute statistics">
            <Title level={2}><Locale.Title tkey="titleStatistics" /> {new Date().toLocaleString("fr-FR")}</Title>
            <br />
            <Layout style={{ width: '770px' }}>
                <Title level={3}><Locale.Title tkey="dailyStatistics" /></Title>
                <Table dataSource={state.statistics?.dailyStatistics} columns={columnsDailyStatistics} pagination={false} scroll={{ x: '750px', y: '600px' }} size="small" />
            </Layout>
            <div css={css`padding-top: 15px; padding-bottom: 15px;`}>
                <Divider />
            </div>
            <Layout style={{ width: '1200px' }}>
                <Title level={3}><Locale.Title tkey="dailyGraph" /></Title>
                <LineChart
                    width={1200}
                    height={600}
                    data={state.statistics?.dailyStatistics}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <YAxis />
                    <XAxis dataKey="event_date" />
                    <Tooltip formatter={(value, name) => ([value, t(`${name}.label.html`)])} />
                    <Legend formatter={(value) => (<Locale.Label tkey={value} />)} />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Line type="monotone" dataKey="total_created" stroke="#283593" yAxisId={0} />
                    <Line type="monotone" dataKey="total_submitted" stroke="#29B6F6" yAxisId={0} />
                    <Line type="monotone" dataKey="previous_total_created" stroke="#1ABC9C" yAxisId={0} />
                    <Line type="monotone" dataKey="previous_total_submitted" stroke="#CDDC39" yAxisId={0} />
                </LineChart>
            </Layout>
            <div css={css`padding-top: 15px; padding-bottom: 15px;`}>
                <Divider />
            </div>
            <Layout style={{ width: '850px' }}>
                <Title level={3}><Locale.Title tkey="globalStatistics" /></Title>
                <Table dataSource={state.statistics?.globalStatistics} columns={columnsGlobalStatistics} pagination={false} />
            </Layout>
            <div css={css`padding-top: 15px; padding-bottom: 15px;`}>
                <Divider />
            </div>            <Layout style={{ width: '1350px' }}>
                <Title level={3}><Locale.Title tkey="statisticsByProgram" /></Title>
                <Table dataSource={state.statistics?.statisticsByProgram} columns={columnsStatisticsByProgram} pagination={false} size="small" bordered={true} />
            </Layout>
        </StrictLoading>
    );
}
