import { FilePdfOutlined } from "@ant-design/icons";
import React, { Fragment, useContext } from "react";
import { documentDownload } from "../../utils/documentDownload";
import { RightAreaContext, RightAreaContextProviderType } from "../canSeeCandidateFile/RightAreaContext";
import { AxiosContext, AxiosContextType } from "../../context/axios.context";
import { TextNeutralButtonWithoutTranslation } from "../../common/button/TextNeutralButtonWithoutTranslation";
import { LocaleDocumentButton } from "../../utils/additionalDocumentUtils";

export function DocumentHeaderButton() {
	const rightAreaContext = useContext<RightAreaContextProviderType>(RightAreaContext);
	const { axios, token } = useContext<AxiosContextType>(AxiosContext);

	const document = rightAreaContext.rightAreaContext.document;

	return (
		<Fragment>
			{document && (
				<TextNeutralButtonWithoutTranslation
					icon={<FilePdfOutlined />}
					onClick={async (e) => {
						e.stopPropagation();
						await documentDownload(axios, token, document);
					}}
				>
					<LocaleDocumentButton document={document}/>
				</TextNeutralButtonWithoutTranslation>
			)}
		</Fragment>
	);
}
