/** @jsx jsx */

import React, { useContext } from 'react';
import { css, jsx } from '@emotion/core';
import { ButtonProps } from 'antd-button-color';
import { IhmContext } from '../../context/ihm.context';
import { useLoading } from '../../hooks/use-loading';
import { ResponsiveButton } from '../input/ResponsiveButton';

export function TextButtonWithoutTranslation(props: ButtonProps) {
    const { ihmState, startDebounced, completeDebounced } = useContext(IhmContext);
    const { doAction, loading } = useLoading();

    return (
        <ResponsiveButton
            loading={ihmState.waitingDebouned || loading}
            css={css`max-width: 100%;position: relative; border: none`}
            {...props}
            onClick={async (e) => doAction(async () => {
                startDebounced();

                try {
                    if (props.onClick) {
                        await props.onClick(e);
                    }
                } finally {
                    completeDebounced();
                }
            })}
        >
            <span css={css`max-width: 100%; text-overflow: ellipsis`}>
                {props.children}
            </span>
        </ResponsiveButton>
    );
}
