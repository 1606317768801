/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';

export function ButtonsFormItemLayout(props: any) {
    return (
      <div
        css={
            css`
    padding-top: 0.5em;
    padding-bottom: 0.5em; 
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    width: fit-content;   
    
    .ant-form-item-explain{
      display: none;
    }
    .ant-btn.ant-btn-primary{
      margin-right: 40px;
    }
    .ant-form-item-control-input-content{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        gap:1em;
        .ant-btn{
            height: 100%;
            flex: 1;
            margin-right: 0 !important;
        }
}`
        }
      >
        <div className="ant-form-item-control-input-content">
          {props.children}
        </div>
      </div>
    );
}
