/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ROUTES } from '../../user/UserRoutesDef';
import { ReferentContainer } from './ReferentContainer';

export const ReferentRoutesDef = (t) => ({
  [`${ROUTES.BASE}*`]: () => (
    <ReferentContainer />
  ),
});
