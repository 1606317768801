import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import React, { Fragment, MutableRefObject } from 'react';

export const configsListInitialColumns = (t) => [
    {
        header: 'Avis jury',
        name: 'id',
        defaultFlex: 1,
        editable: false,
        style: { textAlign: 'right' },

    },
    {
        header: 'Label en francais',
        name: 'frenchLabel',
        defaultFlex: 1,
        type: 'string',
    },
];

export const configsListInitialFilters = [
    {
        name: 'id', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'frenchLabel', operator: 'contains', type: 'string', value: '',
    },
];

export function configsListRenderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedConfig) {
    return (
        <Fragment />
    );
}

/**
 * Map result to row
 * @param d
 */
export function configsListMapToRow(d: any) {
    const row: any = {
        id: d.id,
        frenchLabel: d.frenchLabel,
    };
    return row;
}
