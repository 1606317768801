import React, { Fragment } from "react";
import { IDocument, IAdditionalDocument, DocumentCategoryEnum } from "@fstn/ecandidaturev2_api-interfaces"
import * as Locale from "../common/locale";

// IDocument interface is not linked in any way to a IAdditionalDocument but the backend do
// This naive type predicaye guard to extend the type to have access to the linked IAdditionalDocument
// Not great but good enough and far better than just casting the type
export function isAdditionalDocument(
	document: IDocument
): document is (IDocument & { documentAdditionalDocument: IAdditionalDocument}) {
	console.log(document)
	return document.parentType === "additionalDocument" && "documentAdditionalDocument" in document
}

export function LocaleDocumentButton({ document }: { document: IDocument }) {
	if (isAdditionalDocument(document)) {
		if (document.documentAdditionalDocument.category.id === DocumentCategoryEnum.OTHER) {
			return <Fragment>{document.documentAdditionalDocument.name}</Fragment>
		} else {
			return <Locale.Button tkey={`${document.parentType}_${document.documentAdditionalDocument.category.id}`}/>
		}
	} else {
		return <Locale.Button tkey={`${document.parentType}_${document.name}`} />;
	}
}
