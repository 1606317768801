/** @jsx jsx */
import { jsx } from '@emotion/core';
import { IDocument } from '@fstn/ecandidaturev2_api-interfaces';
import React, { Fragment, useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { UserContext, UserContextType } from '../../../context/user.context';
import { CandidateFileContext, CandidateFileContextProviderType } from '../../canSeeCandidateFile/CandidateFileContext';
import { RightAreaContext, RightAreaContextProviderType } from '../../canSeeCandidateFile/RightAreaContext';
import { DocumentViewer } from './DocumentViewer';
import { RightMenu } from './RightMenu';
import { SpecializedSecretaryRightContainer } from './rightContainers/SpecializedSecretaryRightContainer';
import { MissingFieldsRightContainer } from './rightContainers/MissingFieldsRightContainer';
import { CommentsBtwSecretariesRightContainer } from './rightContainers/CommentsBtwSecretariesRightContainer';
import { CommentsBtwAllTypesOfSecretariesRightContainer } from './rightContainers/CommentsBtwAllTypesOfSecretariesRightContainer';
import { CommentsBtwSecretaryAndCandidateRightContainer } from './rightContainers/CommentsBtwSecretaryAndCandidateRightContainer';

export function RightMenuContainer() {
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    const rightAreaContext = useContext<RightAreaContextProviderType>(RightAreaContext);
    const { userCtx } = useContext<UserContextType>(UserContext);
    const [state, updateState] = useImmer<{
        documentsList?: IDocument[],
        selectedDocument?: IDocument,
    }>({
        documentsList: undefined,
        selectedDocument: undefined as IDocument,
    });

    const documentHash = '22';
    const selectedCandidateFileId = candidateFileContext?.selectedCandidateFile?.id;
    const { incToForceUpdate } = rightAreaContext.rightAreaContext;
    useEffect(() => {
        const load = async () => {
            const params: any = {};
            params.candidateFileId = selectedCandidateFileId;
            const res = await axios.get(`/document?inc=${incToForceUpdate}`, { params });
            updateState((draft) => {
                draft.documentsList = res.data.filter((d: IDocument) => !!d.originalName).sort((d1: IDocument, d2: IDocument) => d1?.type.localeCompare(d2?.type));
            });
        };
        load().then();
    }, [axios, selectedCandidateFileId, updateState, documentHash, incToForceUpdate]);

    const hideOnRight = () => {
        rightAreaContext.updateRightAreaContext((draft) => {
            draft.displayedComponent = undefined;
            draft.large = false;
            draft.document = undefined;
        });
    };

    const displayOnRight = (elt) => {
        if (elt.key?.startsWith('form_')) {
            const formId = elt.key.replace('form_', '');
            rightAreaContext.updateRightAreaContext((draft) => {
                draft.large = false;
                draft.displayedComponent = (
                    <Fragment>
                        {formId === 'commentsBtwSecretaries' && (<CommentsBtwSecretariesRightContainer />)}
                        {formId === 'missingFields' && (<MissingFieldsRightContainer />)}
                        {formId === 'commentsBtwAllTypesOfSecretaries' && (<CommentsBtwAllTypesOfSecretariesRightContainer />)}
                        {formId === 'commentsBtwSecretaryAndCandidate' && (<CommentsBtwSecretaryAndCandidateRightContainer />)}
                        {formId === 'specializedSecretary' && (<SpecializedSecretaryRightContainer />)}
                    </Fragment>
                );
            });
        } else if (elt.key?.startsWith('document_')) {
            const docId = elt.key.replace('document_', '');
            const d = state.documentsList?.find((doc) => doc.id === docId);
            rightAreaContext.updateRightAreaContext((draft) => {
                draft.large = true;
                draft.displayedComponent = <DocumentViewer document={d} />;
                draft.document = d;
            });
        }
    };

    return (
        <RightMenu
        /* ok */ disabled={!rightAreaContext.rightAreaContext.displayedComponent}
            displayOnRight={displayOnRight}
            hideOnRight={hideOnRight}
            userRole={userCtx.user?.role}
            candidateFileStatus={candidateFileContext.selectedCandidateFile?.status}
            documentsList={state.documentsList}
        />
    );
}
