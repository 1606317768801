import {usePath, useRoutes} from 'hookrouter';
import React, {Fragment, useEffect} from 'react';
import {useImmer} from 'use-immer';
import {SecretaryPageWithValidations} from '../SecretaryPageWithValidations';
import {SecretaryChild2RoutesDef} from './SecretaryChild2RoutesDef';

export function SecretaryChild2Container() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const path = usePath();
    const [,updateState] = useImmer({ loaded: false, routes: undefined as any, path: '' });
    useEffect(() => {
        updateState((draft) => {
            draft.path = path;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateState, path]);
    const routesChildren = useRoutes(SecretaryChild2RoutesDef());
    //console.log('Redraw SecretaryChild2Container', routesChildren);
    return (
      <Fragment>
        <SecretaryPageWithValidations>
          {routesChildren}
        </SecretaryPageWithValidations>
      </Fragment>
    );
}
