import { LockOutlined, UserOutlined } from '@ant-design/icons';
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { IUser } from '@fstn/ecandidaturev2_api-interfaces';
import Checkbox from 'antd/lib/checkbox';
import Form from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
// @ts-ignore
import { usePath } from 'hookrouter';
import React, { Fragment, useContext } from 'react';
import { useImmer } from 'use-immer';
import queryString from 'query-string';
import moment from 'moment';
import { PositiveActionButton } from '../../../common/button/PositiveActionButton';
import { TextOkButton } from '../../../common/button/TextOkButton';
import { ResponsiveInput, ResponsiveInputPassword } from '../../../common/input/ResponsiveInput';
import * as Locale from '../../../common/locale';
import { UserContext } from '../../../context/user.context';
import { useAppConfig } from '../../../hooks/use-app-config';
import { useLoading } from '../../../hooks/use-loading';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { useUser } from '../../../hooks/use-user.hook';
import { sNavigate, sNavigateReload } from '../../../utils/safeNavigation';

import { LOGIN, ROUTES } from '../../user/UserRoutesDef';
import { DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { SmartLoading } from '../../../common/indicator/SmartLoading';
import { getEventServiceInstance } from '../../../utils/eventService';
import { TextDangerButton } from '../../../common/button/TextDangerButton';
import { UserCard } from '../../user/UserCard';
import { TextNeutralButton } from '../../../common/button/TextNeutralButton';
import { ConfigContext } from '../../../context/config.context';

const Style = styled.div`
  flex: 1;

  .login-form {
    justify-content: space-between;

    .ant-btn {
      padding: 10px;
      margin-top: 10px;
      font-weight: bold;
    }
  }
}

.ant-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1em;
`;

export async function tryLogin(login: (email: string, password: string, remember: string) => Promise<any>, values) {
  const result: IUser = await login(values.email?.trim(), values.password?.trim(), values.remember);
  getEventServiceInstance().setUserId(values.email?.trim());
  return result;
}

export function LoginForm() {
  const { t } = useSafeTranslation();
  const [, updateState] = useImmer({ loading: false });
  const userCtx = useContext(UserContext);
  const configCtx = useContext(ConfigContext);
  const { login } = useUser(userCtx);
  const path = usePath();
  const [form] = useForm();
  const { isOpen, alreadyLoaded } = useAppConfig(null);
  const { doAction, loading } = useLoading();

  const onFinish = async (values) => doAction(async () => {
    userCtx.reset();
    updateState((draft) => {
      draft.loading = true;
    });
    try {
      const result = await tryLogin(login, values);
      setTimeout(() => sNavigate(path.indexOf('login') === -1 && path.indexOf('welcome') === -1 && path.length > 1 ? path : `${ROUTES.BASE}/${result.role?.name?.toLowerCase().replace('_', '-')}`), 100);
    } catch (e) {
      updateState((draft) => {
        draft.loading = false;
      });
    }
  });
  const parsed = queryString.parse(window.location.search);
  const email = parsed.email as string;
  const userNameP = Locale.PlaceHolder({ tkey: 'userName' });
  const passwordP = Locale.PlaceHolder({ tkey: 'password' });

  const openCandidate = configCtx?.configCtx?.config?.date?.find((el) => el.id === 'OPEN_CANDIDATE')?.value;
  const closeCandidate = configCtx?.configCtx?.config?.date?.find((el) => el.id === 'CLOSE_CANDIDATE')?.value;

  // noinspection HtmlUnknownTarget
  return (
    <SmartLoading loading={!alreadyLoaded} context="Login">
      <Style>
        <div
          css={css`display: flex;
                      flex-wrap: wrap;
                      gap: 1em;
                      max-width: 900px;
                      margin: auto;
                      margin-top: 2em;
                      margin-bottom: 2em; `}
        >
          {userCtx.userCtx.user
            && (
              <Fragment>
                <div css={css`flex: 1 0 250px;
                              text-align: center;`}
                >
                  <Form.Item>
                    <h2 className="h2"><Locale.Title tkey="already-logged" /></h2>
                  </Form.Item>
                  <UserCard />
                  <div css={css`display: flex;
                                  gap: 1em;
                                  justify-content: flex-end;`}
                  >
                    <TextNeutralButton
                      tkey="continue"
                      onClick={() => sNavigateReload(`${ROUTES.BASE}/${userCtx.userCtx.user?.role?.name?.toLowerCase().replace('_', '-')}`)}
                    />
                    <TextDangerButton
                      tkey="logout"
                      onClick={() => sNavigate(`${LOGIN.LOGOUT}`)}
                    />
                  </div>
                </div>
              </Fragment>

            )}
          {!userCtx.userCtx.user
            && (
              <Fragment>
                <div css={css`flex: 1 0 250px;
                              text-align: center;`}
                >
                  <DisabledContextProvider value={{ disabled: false, wrapAuthorized: false }}>
                    <Form className="login-form">
                      <Form.Item>
                        <h2 className="h2"><Locale.Title tkey="login.register" /></h2>
                      </Form.Item>

                      <Form.Item style={{ flex: 1 }}>
                        <div>
                          <Locale.Content tkey="login.register" />
                        </div>
                      </Form.Item>

                      <Form.Item>
                        <PositiveActionButton
                          disabled={!isOpen() || !(moment().isAfter(openCandidate) && moment().isBefore(closeCandidate)) || userCtx.userCtx.user}
                          tkey="register"
                          htmlType="submit"
                          loading={loading}
                          onClick={() => sNavigate(LOGIN.REGISTER_EMAIL)}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Form>
                  </DisabledContextProvider>
                </div>

                <div css={css`flex: 1 0 250px;
                              text-align: center;`}
                >
                  <DisabledContextProvider value={{ disabled: false, wrapAuthorized: false }}>
                    <Form
                      form={form}
                      className="login-form"
                      initialValues={{ remember: true, email }}
                      onFinish={onFinish}
                    >
                      <Form.Item>
                        <h2 className="h2"><Locale.Title tkey="login.signin" /></h2>
                      </Form.Item>

                      <Form.Item
                        name="email"
                        rules={[{ required: false, message: t('login.email.error') }]}
                      >
                        <ResponsiveInput
                          prefix={<UserOutlined className="site-form-item-icon" />}
                          placeholder={userNameP}
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[{ required: false, message: t('login.password.error') }]}
                      >
                        <ResponsiveInputPassword
                          prefix={<LockOutlined className="site-form-item-icon" />}
                          type="password"
                          placeholder={passwordP}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Form.Item hidden name="remember" valuePropName="checked" noStyle>
                          <Checkbox><Locale.Label tkey="remember.me" /></Checkbox>
                        </Form.Item>

                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          className="login-form-forgot"
                          href={`${ROUTES.PUBLIC_BASE}/forgot-password-email`}
                        >
                          <Locale.Button tkey="forgot.password" />
                        </a>
                      </Form.Item>

                      <Form.Item>
                        <PositiveActionButton
                          tkey="login"
                          loading={loading}
                          htmlType="submit"
                          backgroundColor="#002E42"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Form>
                  </DisabledContextProvider>
                </div>

              </Fragment>
            )}
        </div>
        {window.location.href.includes('locahost') && (
          <Fragment>
            <div>
              <TextOkButton
                tkey="admin.login"
                onClick={() => form.setFieldsValue({ email: 'admin@yopmail.com', password: 'test' })}
              />
            </div>
            <div>
              <TextOkButton
                tkey="superSecretary.login"
                onClick={() => form.setFieldsValue({
                  email: 'superSecretary@yopmail.com',
                  password: 'test',
                })}
              />
              <TextOkButton
                tkey="secretary.login"
                onClick={() => form.setFieldsValue({
                  email: 'secretary@yopmail.com',
                  password: 'test',
                })}
              />
            </div>
            <div>
              <TextOkButton
                tkey="jury.infres.login"
                onClick={() => form.setFieldsValue({
                  email: 'celine.reynas+JuryINFRES@gmail.com',
                  password: 'daworututi',
                })}
              />
              <TextOkButton
                tkey="examiner.infres.login"
                onClick={() => form.setFieldsValue({
                  email: 'celine.reynas+ExamINFRES@gmail.com',
                  password: 'zerojoloki',
                })}
              />
              <TextOkButton
                tkey="secretary.infres.login"
                onClick={() => form.setFieldsValue({
                  email: 'celine.reynas+SecINFRES@gmail.com',
                  password: 'lijifejina',
                })}
              />
              <TextOkButton
                tkey="manager.infres.login"
                onClick={() => form.setFieldsValue({
                  email: 'celine.reynas+RespINFRES@gmail.com',
                  password: 'torebiqewi',
                })}
              />
            </div>
          </Fragment>
        )}
      </Style>
    </SmartLoading>
  );
}
