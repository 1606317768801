import styled from '@emotion/styled';
import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import React, { Fragment } from 'react';
import { DisabledContext } from '../../rights/DisabledContext';
import { copyEntityFormProps, EntityFormItemContainer, ItemProps } from '../entityForm/EntityFormItemContainer';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

const Style = styled.div`
.ant-form-item-control .ant-form-item-children-icon{
        display: none !important ;
    }
`;
/**
 * EntityFormItemContainer for checkbox form item
 * @param props
 * @constructor
 */
export function CheckboxFormItem(props: ItemProps & Omit<CheckboxProps, 'disabled'> & { layout?: 'vertical' | 'horizontal' }) {
    return (
      <Style>
        <EntityFormItemContainer
          {...copyEntityFormProps(props)}
          valuePropName="checked"
          validateTrigger={['onChange', 'onBlur']}
        >
          <CheckboxContentContainer
            data-testid={`${props.propertyName}`?.replace(/,/g, '_')}
          />
        </EntityFormItemContainer>
      </Style>
        );
}

function CheckboxContentContainer(props: Omit<CheckboxProps, 'disabled'>) {
    const disabled = useScopedSelector('CheckboxContentContainer', DisabledContext, (c) => c?.disabled);
    return (
      <Fragment>
        <Checkbox
          className={props['data-testid']}
          data-testid={props['data-testid']}
          indeterminate={props.checked !== false && props.checked !== true}
          checked={props.checked}
          onChange={props.onChange}
          disabled={disabled}
        />
      </Fragment>
        );
}
