import React from 'react';
import { ADMIN, ROUTES } from '../../user/UserRoutesDef';
import { AdminImpersonate } from '../AdminImpersonate';
import { AdminHome } from '../AdminHome';
import { AcademyImporter } from '../partial/academy/AcademyImporter';
import { SchoolTrackTypeImporter } from '../partial/schoolTrackType/SchoolTrackTypeImporter';
import { SchoolTrackImporter } from '../partial/schoolTrack/SchoolTrackImporter';
import { SchoolImporter } from '../partial/school/SchoolImporter';
import { UserImporter } from '../partial/user/UserImporter';
import { TrafficSourceImporter } from '../partial/trafficSource/TrafficSourceImporter';
import { BachelorTechnoSeriesImporter } from '../partial/bachelorTechnoSeries/BachelorTechnoSeriesImporter';
import { BachelorMentionImporter } from '../partial/bachelorMention/BachelorMentionImporter';
import { LanguageLevelImporter } from '../partial/languageLevel/languageLevelImporter';
import { IdiomImporter } from '../partial/language/IdiomImporter';
import { LocaleListPage } from '../partial/locale/LocaleListPage';
import { LocaleEditPage } from '../partial/locale/LocaleEditPage';
import { ProgramImporter } from '../partial/program/ProgramImporter';
import { NumberConfigListPage } from '../partial/numberConfig/NumberConfigListPage';
import { StringConfigListPage } from '../partial/stringConfig/StringConfigListPage';
import { DateConfigListPage } from '../partial/dateConfig/DateConfigListPage';
import { SecretaryListPage } from '../partial/secretary/SecretaryListPage';
import { SecretaryEditPage } from '../partial/secretary/SecretaryEditPage';
import { ProgramManagerListPage } from '../partial/programManager/ProgramManagerListPage';
import { ProgramManagerEditPage } from '../partial/programManager/ProgramManagerEditPage';
import { ExaminerListPage } from '../partial/examiner/ExaminerListPage';
import { ExaminerEditPage } from '../partial/examiner/ExaminerEditPage';
import { JuryListPage } from '../partial/jury/JuryListPage';
import { JuryEditPage } from '../partial/jury/JuryEditPage';
import { ReferentListPage } from '../partial/referent/ReferentListPage';
import { ReferentReviewListPage } from '../partial/referent/ReferentReviewListPage';
import { AdminListPage } from '../partial/admin/AdminListPage';
import { AdminEditPage } from '../partial/admin/AdminEditPage';
import { SpecializedSecretaryListPage } from '../partial/specializedSecretary/SpecializedSecretaryListPage';
import { SpecializedSecretaryEditPage } from '../partial/specializedSecretary/SpecializedSecretaryEditPage';
import { CountryImporter } from '../partial/country/countryImporter';
import { PaymentChecker } from '../partial/paymentChecker/PaymentChecker';
import { BachelorSpecialityImporter } from '../partial/bachelorSpeciality/BachelorSpecialityImporter';
import { CleanCloseProgram } from '../CleanCloseProgram';
import { TestPublishJuryReviewEmail } from '../TestPublishJuryReviewEmail';
import { CandidateEditPage } from '../partial/candidate/CandidateEditPage';
import { CandidateFilesStatistics } from '../../canSeeCandidateFile/CandidateFileStatistics';
import { PreviousStatisticImporter } from '../partial/statistic/previousStatisticImporter';
import { NostudyActivityImporter } from '../partial/nostudyActivity/NostudyActivityImporter';
import { JuryReviewStatusListPage } from '../partial/juryReviewStatus/JuryReviewStatusListPage';
/**
 * Routes starting with /home/welcome/referent/
 * @constructor
 */
export function AdminChild1RoutesDef(): any {
    return {
        /* LAURENT les différentes routes qui vont afficher le contenu de l'administration, en clé tu as le path (url) et en valeur le composant à afficher
     * ça utilise hookrouter https://github.com/Paratron/hookrouter    *
     *  */
        [ADMIN.IMPERSONATE.replace(ROUTES.ADMIN_BASE, '')]: () => <AdminImpersonate />,
        [ADMIN.CLEAN_CLOSE_PROGRAM.replace(ROUTES.ADMIN_BASE, '')]: () => <CleanCloseProgram />,
        [ADMIN.TEST_PUBLISH_JURYREVIEW_EMAIL.replace(ROUTES.ADMIN_BASE, '')]: () => <TestPublishJuryReviewEmail />,
        [ADMIN.CANDIDATE_FILES_STATISTICS.replace(ROUTES.ADMIN_BASE, '')]: () => <CandidateFilesStatistics />,
        [ROUTES.ADMIN_BASE.replace(ROUTES.ADMIN_BASE, '/')]: () => <AdminHome />,
        [ROUTES.BASE]: () => <AdminHome />,
        [`${ADMIN.ACADEMY_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <AcademyImporter />,
        [`${ADMIN.SCHOOL_TRACK_TYPE_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <SchoolTrackTypeImporter />,
        [`${ADMIN.SCHOOL_TRACK_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <SchoolTrackImporter />,
        [`${ADMIN.SCHOOL_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <SchoolImporter />,
        [`${ADMIN.USER_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <UserImporter />,
        [`${ADMIN.TRAFFIC_SOURCE_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <TrafficSourceImporter />,
        [`${ADMIN.BACHELOR_SERIES_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <BachelorTechnoSeriesImporter />,
        [`${ADMIN.BACHELOR_SPECIALITY_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <BachelorSpecialityImporter />,
        [`${ADMIN.BACHELOR_MENTION_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <BachelorMentionImporter />,
        [`${ADMIN.LANGUAGE_LEVEL_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <LanguageLevelImporter />,
        [`${ADMIN.IDIOM_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <IdiomImporter />,
        [`${ADMIN.PREVIOUS_STATISTIC_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <PreviousStatisticImporter />,
        [`${ADMIN.COUNTRY_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <CountryImporter />,
        [`${ADMIN.LOCALE_LIST.replace(ROUTES.ADMIN_BASE, '')}`]: () => <LocaleListPage />,
        [`${ADMIN.LOCALE_EDIT.replace(ROUTES.ADMIN_BASE, '')}`]: () => <LocaleEditPage />,
        [`${ADMIN.NOSTUDY_ACTIVITY_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <NostudyActivityImporter />,
        // [`${ADMIN.PROGRAM_LIST.replace(ROUTES.ADMIN_BASE, '')}`]: () => <ProgramListPage />,
        [`${ADMIN.PROGRAM_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <ProgramImporter />,
        [`${ADMIN.NUMBER_CONFIG_LIST.replace(ROUTES.ADMIN_BASE, '')}`]: () => <NumberConfigListPage />,
        [`${ADMIN.STRING_CONFIG_LIST.replace(ROUTES.ADMIN_BASE, '')}`]: () => <StringConfigListPage />,
        [`${ADMIN.DATE_CONFIG_LIST.replace(ROUTES.ADMIN_BASE, '')}`]: () => <DateConfigListPage />,
        [`${ADMIN.JURY_REVIEW_STATUS_CONFIG_LIST.replace(ROUTES.ADMIN_BASE, '')}`]: () => <JuryReviewStatusListPage />,
        [`${ADMIN.SECRETARY_LIST.replace(ROUTES.ADMIN_BASE, '')}`]: () => <SecretaryListPage />,
        [`${ADMIN.SECRETARY_EDIT.replace(ROUTES.ADMIN_BASE, '')}`]: () => <SecretaryEditPage />,
        [`${ADMIN.PROGRAM_MANAGER_LIST.replace(ROUTES.ADMIN_BASE, '')}`]: () => <ProgramManagerListPage />,
        [`${ADMIN.PROGRAM_MANAGER_EDIT.replace(ROUTES.ADMIN_BASE, '')}`]: () => <ProgramManagerEditPage />,
        [`${ADMIN.EXAMINER_LIST.replace(ROUTES.ADMIN_BASE, '')}`]: () => <ExaminerListPage />,
        [`${ADMIN.EXAMINER_EDIT.replace(ROUTES.ADMIN_BASE, '')}`]: () => <ExaminerEditPage />,
        [`${ADMIN.PAYMENT_CHECKER_IMPORTER.replace(ROUTES.ADMIN_BASE, '')}`]: () => <PaymentChecker />,
        [`${ADMIN.JURY_LIST.replace(ROUTES.ADMIN_BASE, '')}`]: () => <JuryListPage />,
        [`${ADMIN.JURY_EDIT.replace(ROUTES.ADMIN_BASE, '')}`]: () => <JuryEditPage />,
        [`${ADMIN.REFERENT_LIST.replace(ROUTES.ADMIN_BASE, '')}`]: () => <ReferentListPage />,
        [`${ADMIN.REFERENT_REVIEW_LIST.replace(ROUTES.ADMIN_BASE, '')}`]: () => <ReferentReviewListPage />,
        [`${ADMIN.ADMIN_LIST.replace(ROUTES.ADMIN_BASE, '')}`]: () => <AdminListPage />,
        [`${ADMIN.ADMIN_EDIT.replace(ROUTES.ADMIN_BASE, '')}`]: () => <AdminEditPage />,
        [`${ADMIN.CANDIDATE_EDIT.replace(ROUTES.ADMIN_BASE, '')}`]: () => <CandidateEditPage />,
        [`${ADMIN.SPECIALIZED_SECRETARY_LIST.replace(ROUTES.ADMIN_BASE, '')}`]: () => <SpecializedSecretaryListPage />,
        [`${ADMIN.SPECIALIZED_SECRETARY_EDIT.replace(ROUTES.ADMIN_BASE, '')}`]: () => <SpecializedSecretaryEditPage />,
    };
}
