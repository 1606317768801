import React, {
 ReactNode, useContext, useEffect,
} from 'react';
import { useImmer } from 'use-immer';
import { AxiosContext, AxiosContextType } from '../../context/axios.context';
import { UserContext } from '../../context/user.context';
import { useUser } from '../../hooks/use-user.hook';
import { useIsMounted } from '../../hooks/use-is-mounted';
import { StrictLoading } from '../indicator/SmartLoading';

/**
 *  LAURENT
 *  Call /me endpoint to load last connected user data
 */
export const WithLoadMe = (props:{ children:ReactNode }) => {
    const userCtx = useContext(UserContext);
    const { loadMe } = useUser(userCtx);
    const { token } = useContext<AxiosContextType>(AxiosContext);
    const isMounted = useIsMounted();
    // console.log('Redraw WithLoadMe$', userCtx);
    const [state, updateState] = useImmer({
        loaded: false, isLoading: false,
    });

    useEffect(() => {
        if (isMounted) {
            if (!state.loaded && !state.isLoading) {
                updateState((draft) => {
                    draft.isLoading = true;
                });
                loadMe(token).then(() => {
                    if (isMounted) {
                        updateState((draft) => {
                            draft.loaded = true;
                            draft.isLoading = false;
                        });
                    }
                });
            }
        }
        // eslint-disable-next-line
    }, [])

    return (
      <StrictLoading loading={!state.loaded} context="WithLoadMe">
        {props.children}
      </StrictLoading>
);
};
