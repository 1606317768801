import { useRoutes } from 'hookrouter';
import React, { Fragment, useContext, useRef } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';
import { UserConfigContextProvider } from '../../../context/user.config.context';
import { UserContext, UserContextType } from '../../../context/user.context';
import { CandidateFileListContextProvider } from '../../canSeeCandidateFile/CandidateFileListContext';
import { AdminContainerRoutesDef } from './AdminContainerRoutesDef';
import { AdminPage } from '../AdminPage';

export function AdminContainer() {
    const { userCtx } = useContext<UserContextType>(UserContext);
    const routesChildren = useRoutes(AdminContainerRoutesDef());
    const oldProps = useRef({});
    return useDeepCompareMemo(() => {
        oldProps.current = [userCtx.user, routesChildren];
        return (
          <Fragment>
            <CandidateFileListContextProvider>
              <UserConfigContextProvider>
                <AdminPage>
                  {routesChildren}
                </AdminPage>
              </UserConfigContextProvider>
            </CandidateFileListContextProvider>
          </Fragment>
        );
    }, [userCtx.user]);
}
