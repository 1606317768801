/** @jsx jsx */
import { jsx } from '@emotion/core';
import { DEFAULT, ReferentReviewStatusEnum } from '@fstn/ecandidaturev2_api-interfaces';
import moment from 'moment';
import { Fragment, useContext } from 'react';
import { useImmer } from 'use-immer';
import UUID from 'uuid-js';

import { EntityFormListContainer } from '../../../common/form/entityForm/EntityFormListContainer';
import { FormListFieldData } from '../../../common/form/type';
import { ReferentEmailModal } from './ReferentEmailModal';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { EntityFormContext } from '../../../context/entityForm.context';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { CandidateFileContext, CandidateFileContextProviderType } from '../../canSeeCandidateFile/CandidateFileContext';
import { AcademicReferentReviewForm } from './AcademicReferentReviewForm';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { UserContext, UserContextType } from '../../../context/user.context';

export function addNewReferentReview(oldReferentReviewsList, referent) {
    const referentReviewId = UUID.create(1).toString();

    const referentReviewsList = {
        ...oldReferentReviewsList,
        academicReferentReviews: [...oldReferentReviewsList.academicReferentReviews,
        {
            id: referentReviewId,
            rights: DEFAULT,
            status: ReferentReviewStatusEnum.WAITING,
            referent,
        }],
    };
    return { referentReviewsList, referentReviewId };
}

export function AcademicReferentsReviewsListForm() {
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const responsive = useResponsiveForm();
    const { canSuperEditValidation } = useContext<UserContextType>(UserContext);
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    const academicReferentReviews = useScopedSelector<any[], any>('AcademicReferentsReviewsListForm', EntityFormContext, (c) => c[0]?.entityValue?.academicReferentReviews);
    const getFieldsValue = useScopedSelector<any[], any>('AcademicReferentsReviewsListForm', EntityFormContext, (c) => c[0]?.getFieldsValue);
    const onImmediateChange = useScopedSelector<any[], any>('AcademicReferentsReviewsListForm', EntityFormContext, (c) => c[0]?.onImmediateChange);
    const setFieldsValue = useScopedSelector<any[], any>('AcademicReferentsReviewsListForm', EntityFormContext, (c) => c[0]?.setFieldsValue);

    const [state, updateState] = useImmer({
        showReferentEmail: false,
    });

    /**
     * status will remains WAITING if the referent refused the RGPD
     * @param referent
     */
    async function onAddReferentReview(referent) {
        const { referentReviewsList, referentReviewId } = addNewReferentReview(getFieldsValue(), referent);
        await onImmediateChange(referentReviewsList, getFieldsValue());
        setFieldsValue(referentReviewsList);

        /**
         * Need this to be sure that data exists on server
         */
        setTimeout(async () => {
            const params = {} as any;
            if (candidateFileContext) {
                params.candidateFileId = candidateFileContext.selectedCandidateFile?.id;
            }
            await axios.put(`academic-referent/${referentReviewId}/confirm`, {}, { params });

            updateState((draft) => {
                draft.showReferentEmail = false;
            });
        }, 1000);
    }

    async function onCancelAddReferentReview() {
        updateState((draft) => {
            draft.showReferentEmail = false;
        });
    }

    function getReferentReviewForField(field: FormListFieldData) {
        return academicReferentReviews?.[field.fieldKey];
    }

    return (
        <Fragment>
            <ReferentEmailModal
                axios={axios}
                onCancel={onCancelAddReferentReview}
                type="academic-referent"
                onAddReferentReview={onAddReferentReview}
                showReferentEmail={state.showReferentEmail}
            />
            <EntityFormListContainer
                {...responsive.colProps}
                propertyName="academicReferentReviews"
                entity="academicReferentReviews"
                compact={true}
                sort={(f1: FormListFieldData, f2: FormListFieldData) => moment(getReferentReviewForField(f1)?.createdAt).valueOf() - moment(getReferentReviewForField(f2)?.createdAt).valueOf()}
                hideRemove={!canSuperEditValidation()}
                addDecorator={async (add) => {
                    updateState((draft) => {
                        draft.showReferentEmail = true;
                    });
                }}
            >
                <AcademicReferentReviewForm />
            </EntityFormListContainer>
        </Fragment>
    );
}
