/** @jsx jsx */
import { useQueryParams } from 'hookrouter';
import React, { Fragment } from 'react';
import { WithInitialLoad } from '../../../common/withExtraParameters/WithInitialLoad';
import { CandidateFileContextProvider } from '../../canSeeCandidateFile/CandidateFileContext';
import { CandidateFileListContextProvider } from '../../canSeeCandidateFile/CandidateFileListContext';
import { SecretaryPageCandidateFilePDF } from '../../canSeePDF/candidateFilePDF/SecretaryPageCandidateFilePDF';
import { SecretaryChild2Container } from './SecretaryChild2Container';
import { FullScreenSecretaryPage } from '../SecretaryPage';
import { jsx, css } from '@emotion/core';
import { CandidateFilesStatistics } from '../../canSeeCandidateFile/CandidateFileStatistics';
import { Chat } from '../Chat';

/**
 * Routes starting with /home/welcome/secretary/
 * @constructor
 */
export function SecretaryChild1RoutesDef(): any {
  return {
    '/pdf': () => (
      <FullScreenSecretaryPage>
        <SecretaryPageCandidateFilePDFContainer />
      </FullScreenSecretaryPage>
    ),
    '/statistics': () => (
      <FullScreenSecretaryPage>
        <div css={css`margin: 20px`}>
          <CandidateFilesStatistics />
        </div>
      </FullScreenSecretaryPage>
    ),
    '/:candidateFileId/chat': ({ candidateFileId }: any) => (
      <Chat candidateFileId={candidateFileId}/>
    ),
    '/:candidateFileId/*': ({ candidateFileId }: any) => (
      <WithInitialLoad
        entity="candidate-file"
        entityId={candidateFileId}
        propsName="selectedCandidateFile"
      >
        <CandidateFileContextProvider>
          <SecretaryChild2Container />
        </CandidateFileContextProvider>
      </WithInitialLoad>
    ),
    '/*': () => (
      <Fragment />
    ),
  };
}

export function SecretaryPageCandidateFilePDFContainer() {
  const [queryParams] = useQueryParams();

  const { ids = '' } = queryParams;
  return (
    <CandidateFileListContextProvider selectedCandidateFiles={ids.split(',').map((s) => ({ id: s }))}>
      <SecretaryPageCandidateFilePDF />
    </CandidateFileListContextProvider>
  );
}
