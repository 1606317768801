/**
 * Deep clone destination rights to source
 * @param destination
 * @param source
 */
export function deepCloneRightsForSourceChanges(destination, source) {
    if (!destination) {
        return;
    }
    for (const property in source) {
        if (property === 'rights') {
            try {
                destination[property] = source[property];
            } catch (e) {
                // ignore readonly
            }
        } else if (Array.isArray(source) && !destination[property] && source[property] !== undefined) {
            const res = {};
            deepCloneRightsForSourceChanges(res, source[property]);
            if (Object.keys(res).length > 0) destination[property] = res;
        } else if (typeof source[property] === 'object' && source[property] !== null) {
            deepCloneRightsForSourceChanges(destination[property], source[property]);
        } else if (Array.isArray(source[property]) && source[property] !== null) {
            deepCloneRightsForSourceChanges(destination[property], source[property]);
        }
    }
}
