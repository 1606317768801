import {CandidateFileStatusEnum} from '@fstn/ecandidaturev2_api-interfaces';
import React, {Fragment, useContext} from 'react';
import {CandidateFileContext, CandidateFileContextProviderType} from '../../canSeeCandidateFile/CandidateFileContext';

export function IsNotWaitingCandidate(props: { children?: any }) {
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    if (candidateFileContext?.selectedCandidateFile?.status?.id !== CandidateFileStatusEnum.WAITING_CANDIDATE) {
        return (
          <Fragment>
            {props.children}
          </Fragment>
        );
    }
    return <Fragment />;
}
