/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Popover from 'antd/lib/popover';
import React from 'react';
import * as Locale from '../../../common/locale';

export function PopOverCommentsFlat(props: { content: any, tkey: string }) {
    return (
      <Popover
        content={(
          <div>
            {props?.content?.split?.('\n')?.map?.((s, i) => (
              <div key={`${s}_${i as any}`}>
                {s}
              </div>
              ))}
          </div>
)}
        title={<Locale.Title tkey={props.tkey} />}
        trigger="hover"
      >
        <div css={css`
  height: 100%;
  font-size: 5px;`}
        >
          {props?.content?.split?.('\n')?.map?.((s, i) => (
            <div key={`${s}_${i as any}`}>
              {s}
            </div>
        ))}
        </div>
      </Popover>
    );
}
