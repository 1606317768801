/** @jsx jsx */
import {jsx} from '@emotion/core';
import * as Locale from '../../../../common/locale';
import {InputFilter} from '../filters/InputFilter';

export const firstNameColumn = {
    header: <Locale.Header tkey="candidateFilesList.firstName" />,
    name: 'firstName',
    id: 'firstName',
    group: 'profile',
    defaultLocked: 'start',
    defaultWidth: 135,
    filterable: true,
    filterEditor: InputFilter,
};

export const firstNameFilter = {
        name: 'firstName', operator: 'startsWith', type: 'string', value: '',
    };
