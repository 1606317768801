/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {useSafeTranslation} from '../../../../hooks/use-safe-translation';
import {AdminEditableList} from '../../common/AdminEditableList';
import {
    configsListInitialColumns,
    configsListInitialFilters,
    configsListMapToRow,
    configsListRenderRowContextMenu,
} from './ConfigListInitialConfig';

export function NumberConfigListPage() {
    const { t } = useSafeTranslation();
    return (
      <Fragment>
        <AdminEditableList
          entity="number-config"
          renderRowContextMenu={configsListRenderRowContextMenu}
          initialFilters={configsListInitialFilters}
          initialColumns={configsListInitialColumns(t)}
          mapToRow={configsListMapToRow}
        />
      </Fragment>
    );
}
