import { usePath, useRoutes } from 'hookrouter';
import React, { Fragment, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { sNavigate } from '../../utils/safeNavigation';
import { CandidateFileListContextProvider } from '../canSeeCandidateFile/CandidateFileListContext';
import { SecretaryPageCandidateFilePDF } from '../canSeePDF/candidateFilePDF/SecretaryPageCandidateFilePDF';
import { ROUTES } from '../user/UserRoutesDef';
import { JuryHome } from './JuryHome';
import { JuryPage } from './JuryPage';
import { JuryStatusImporter } from './juryStatusImporter';

export const JuryRoutesDef = (t) => ({
  [`${ROUTES.BASE}*`]: () => (
    <JuryRouteContent />
  ),
  [`${ROUTES.BASE}`]: () => {
    sNavigate(`${ROUTES.JURY_BASE}`);
    return <Fragment />;
  },
});

const routesDef = {
  '/pdf': () => (
    <JuryPage>
      <SecretaryPageCandidateFilePDF />
    </JuryPage>
  ),
  '/jury': () => (
    <JuryPage>
      <JuryHome />
    </JuryPage>
  ),
  '/jury/import': () => (
    <JuryPage>
      <JuryStatusImporter />
    </JuryPage>
  ),
};

function IJuryRouteContent() {
  const path = usePath();
  const routesChildren = useRoutes(routesDef);

  const [, updateState] = useImmer({ loaded: false, routes: undefined as any, path: '' });
  useEffect(() => {
    if (!routesChildren) {
      window.location.href = path;
    }
    updateState((draft) => {
      draft.path = path;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState, path]);
  return (
    <CandidateFileListContextProvider>
      {routesChildren}
    </CandidateFileListContextProvider>
  );
}

const JuryRouteContent = IJuryRouteContent;
