import { IApprenticeshipCompany } from '@fstn/ecandidaturev2_api-interfaces';
import { FormLayout } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import React, { Fragment, HTMLAttributes } from 'react';
import { Box, Flex } from 'rebass';
import { EntityFormContainer } from '../../../common/form/entityForm/EntityFormContainer';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { FormStyle } from '../../../common/form/FormStyle';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import { InputTextAreaFormItem } from '../../../common/form/inputFormItems/InputTextAreaFormItem';
import { SecretaryReviewFormFooterContainer } from '../../../common/form/secretaryReviewForm/SecretaryReviewFormFooterContainer';
import { WithSwitchToReadOnlyOnDisplayForSecretary } from '../../../common/withExtraParameters/WithSwitchToReadOnlyOnDisplayForSecretary';
import { WithInitialLoad } from '../../../common/withExtraParameters/WithInitialLoad';
import { YesNoFormItem } from '../../../common/form/inputFormItems/YesNoFormItems';
import { EntityFormContext } from '../../../context/entityForm.context';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { IsSecretary } from '../../user/conditionnal/IsSecretary';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { DisabledContextProviderForEntityForm } from '../../../common/rights/DisabledContextProviderForEntityForm';

export type ApprenticeshipCompanyProps = HTMLAttributes<HTMLElement>;
const entity = 'apprenticeship-company';

function ApprenticeshipCompanyEntityForm() {
    const formEntityValue = useScopedSelector<any[], IApprenticeshipCompany>('ApprenticeshipCompanyEntityForm', EntityFormContext, (c) => c[0]?.entityValue);

    const responsive = useResponsiveForm();

    return (
      <Flex flexDirection="column">

        <Box {...responsive.w1}>
          <FormContentPanelContainer partName={entity}>
            <YesNoFormItem labelCol={{ span: 18 }} wrapperCol={{ span: 4 }} propertyName="found" />
            {!formEntityValue.found
                    && <YesNoFormItem labelCol={{ span: 18 }} wrapperCol={{ span: 4 }} propertyName="needHelp" />}

            {!formEntityValue.needHelp && formEntityValue.found
                    && (
                    <Fragment>
                      <InputFormItem propertyName="name" />
                      <InputFormItem propertyName="address" />
                      <InputFormItem propertyName="firstName" />
                      <InputFormItem propertyName="lastName" />
                      <InputFormItem propertyName="phone" />
                      <InputFormItem propertyName="email" />
                      <InputFormItem propertyName="jobTitle" />
                    </Fragment>
)}
            {formEntityValue.needHelp && !formEntityValue.found
                    && (
                    <Fragment>
                      <InputFormItem propertyName="desiredProfile" />
                      <InputFormItem propertyName="type" />
                      <InputFormItem propertyName="searchArea" />
                      <InputTextAreaFormItem propertyName="remarks" />
                    </Fragment>
)}
          </FormContentPanelContainer>
        </Box>
      </Flex>
    );
}

export function ApprenticeshipCompanyForm(props:{ layout?: FormLayout }) {
    const onBeforeValidate = (values: Store, differences: Partial<IApprenticeshipCompany>, setFieldsValue) => {
        if (differences.found === true) {
            differences.needHelp = null;
            setFieldsValue({ values, needHelp: null });
        }
        if (differences.found === true || differences.needHelp === false) {
            differences.desiredProfile = null;
            differences.type = null;
            differences.searchArea = null;
            differences.remarks = null;
        } else if (differences.needHelp === true || differences.found === false) {
            differences.name = null;
            differences.firstName = null;
            differences.address = null;
            differences.remarks = null;
            differences.email = null;
            differences.remarks = null;
            differences.phone = null;
            differences.jobTitle = null;
            differences.lastName = null;
        }
        return differences;
    };
    return (
      <FormStyle>
        <WithInitialLoad entity={entity}>
          <WithSwitchToReadOnlyOnDisplayForSecretary>
            <EntityFormContainer listeners={{ onBeforeValidate }} layout={props.layout}>
              <DisabledContextProviderForEntityForm>
                <ApprenticeshipCompanyEntityForm />
                <IsSecretary>
                  <SecretaryReviewFormFooterContainer propertyName="secretaryReview" />
                </IsSecretary>
              </DisabledContextProviderForEntityForm>
            </EntityFormContainer>
          </WithSwitchToReadOnlyOnDisplayForSecretary>
        </WithInitialLoad>
      </FormStyle>
    );
}
