/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/core';
import { IProgram } from '@fstn/ecandidaturev2_api-interfaces';
import { Select } from 'antd';
import _, { flatMap } from 'lodash';
import React from 'react';
import { EntityFormItemContainer } from '../../../common/form/entityForm/EntityFormItemContainer';
import * as Locale from '../../../common/locale';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { CandidateFilesListHeaderStatistic } from '../../canSeeCandidateFile/candidateFilesList/CandidateFilesListHeaderStatistic';
import { CandidateFilesListHeaderStatisticGutter } from '../../canSeeCandidateFile/candidateFilesList/CandidateFilesListHeaderStatisticGutter';
import { getTheme } from './utils';

export function ProgramModalListHeader(props:{
    allPrograms: IProgram[],
    filteredPrograms: IProgram[],
    selectedPrograms: IProgram[],
    onFilterSchool: (value: any, option: any) => void,
    onFilterThemes: (value: any, option: any) => void
}) {
    const { t } = useSafeTranslation();

    const options = flatMap(props.allPrograms, (p: IProgram) => p.theme?.split(',').map((theme) => ({
        label: getTheme(theme),
        value: getTheme(theme),
    }))).filter((o) => !!o);

    return (
      <div css={css`display: flex;align-items:center`}>
        <div css={css`flex: 1`}>
          <EntityFormItemContainer propertyName="school" labelCol={{ span: 4 }} initialValue={[]} dependenciesValues={[props.onFilterSchool]}>
            <Select
              data-testid={'school'?.replace(/,/g, '_')}
              className="school"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              options={_.uniqBy(props.allPrograms.map((p: IProgram) => ({
                        label: p.school,
                        value: p.school,
                    })), ((i) => i.label))}
              placeholder={t('modale.program.filter.school')}
              onChange={props.onFilterSchool}
            />
          </EntityFormItemContainer>
          <EntityFormItemContainer propertyName="theme" labelCol={{ span: 4 }} initialValue={[]} dependenciesValues={[props.onFilterThemes]}>
            <Select
              data-testid={'theme'?.replace(/,/g, '_')}
              className={`theme

                                `}
              mode="multiple"
              allowClear
              options={_.uniqBy(options, ((i) => i?.label))}
              placeholder={t('modale.program.filter.themes')}
              onChange={props.onFilterThemes}
            />
          </EntityFormItemContainer>
        </div>
        <CandidateFilesListHeaderStatisticGutter tkey="modale.program.stats">
          <CandidateFilesListHeaderStatistic
            title={<Locale.Label tkey="results" />}
            value={`${props.filteredPrograms?.length}/${props.allPrograms?.length}`}
          />
          <CandidateFilesListHeaderStatistic
            title={<Locale.Label tkey="selected" />}
            value={`${props.selectedPrograms?.length || 0}`}
          />
        </CandidateFilesListHeaderStatisticGutter>
      </div>
);
}
