import { IProgramsList } from '@fstn/ecandidaturev2_api-interfaces';
import { Modal } from 'antd';
import React, { Fragment, ReactElement } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import * as Locale from '../locale';
import { useEntity } from '../../hooks/use-entity';
import { ROUTES } from '../../pages/user/UserRoutesDef';
import { sNavigate } from '../../utils/safeNavigation';

/**
 * Will prompt a modal and redirect to the programs list if it's not valid,
 * To see valid condition please take a look at this unit test
 * @param props
 * @constructor
 */
export function WithProgramsListOk(props: { children: ReactElement }) {
    //console.log('Redraw WithProgramsListOk');
    const { loadEntity } = useEntity<IProgramsList>();
    const [state, updateSate] = useImmer({
        loaded: false,
        entityValue: undefined as IProgramsList,
    });

    async function load() {
        updateSate((draft) => {
            draft.loaded = true;
        });
        const res = await loadEntity('programs-list');
        updateSate((draft) => {
            draft.entityValue = res;
        });
    }

    useDeepCompareEffect(() => {
        if (!state.loaded) {
            load().then();
        }
    }, [state.loaded]);

    if (!state.loaded || !state.entityValue) {
        return <Fragment />;
    }

    if (state.entityValue.confirmed && state.entityValue.programLines.length > 0) {
        return (
          <Fragment>
            {props.children}
          </Fragment>
        );
    }
    return (
      <Modal
        width="800vw"
        okButtonProps={{ 'data-testid': 'programs-list-redirect-ok' } as any}
        okText={<Locale.Button tkey="submit.continue" />}
        cancelText={<Locale.Button tkey="submit.completeCandidateFile" />}
        cancelButtonProps={{ disabled: true, style: { display: 'none' }, 'data-testid': 'programs-list-redirect-cancel' } as any}
        title={<Locale.Notification tkey="invalidCandidateFile" />}
        visible
        onOk={async () => {
                await sNavigate(`${ROUTES.CANDIDATE_BASE}/programs-list`);
            }}
      >
        <div>
          <Locale.Notification tkey="invalidProgramsList.description" />
        </div>

      </Modal>
    );
}
