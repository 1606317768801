/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as Locale from '../../../common/locale';

export function CandidateFilesListHeaderStatisticGutter(props: { tkey: string, children: any, color?: string, extra?: any }) {
    const Style = css`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 5px;
  background: #eee;
  padding: 10px;

  .ant-statistic {
    background: ${props.color || '#457992'} !important;
  }
`;

    return (
      <div css={Style}>
        <div css={css`
            display: flex;
            align-items: center; 
            padding-right:1em;  
            flex-direction: column;   
            justify-content: space-around;  
`}
        >
          <Locale.Label tkey={props.tkey} />
          {props.extra}
        </div>
        {props.children}
      </div>
    );
}
