import { useContext, useState } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { isEqual, uniqBy } from 'lodash';
import { AxiosContext, AxiosContextType } from '../context/axios.context';

/**
 * A segment is a part of candidate files that are selected in the grid to do an export, or other actions
 */
export function useSegment(selectedCandidateFiles) {
    const [segment, setSegment] = useState({ id: undefined, candidateFiles: [] });
    const { axios } = useContext<AxiosContextType>(AxiosContext);

    async function load() {
        if (!isEqual(selectedCandidateFiles, segment?.candidateFiles)) {
            let seg = {
                ...segment,
                candidateFiles: uniqBy(selectedCandidateFiles.map((s) => ({ id: s.id })), 'id'),
            };
            if (segment.id) {
                await axios.patch(`segment/${segment.id}`, seg);
            } else {
                seg = ((await axios.post('segment', seg)).data);
            }
            setSegment(seg);
        }
    }

    useDeepCompareEffect(() => {
        load().then();
    }, [selectedCandidateFiles, segment?.id, segment?.candidateFiles]);

    return { segment };
}
