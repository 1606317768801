/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {useSafeTranslation} from '../../../../hooks/use-safe-translation';
import {AdminEditableList} from '../../common/AdminEditableList';
import {
    configsListInitialColumns,
    configsListInitialFilters,
    configsListMapToRow,
    configsListRenderRowContextMenu,
} from './TrafficSourceInitialConfig';

export function TrafficSourceListPage() {
  const { t } = useSafeTranslation();
  return (
    <Fragment>
      <AdminEditableList
        entity="admin/traffic-source"
        renderRowContextMenu={configsListRenderRowContextMenu}
        initialFilters={configsListInitialFilters}
        initialColumns={configsListInitialColumns(t)}
        gridStyle={{ height: 'calc( 100% - 250px)' }}
        mapToRow={configsListMapToRow}
      />
    </Fragment>
  );
}
