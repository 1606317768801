/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import {
    faBriefcase, faCommentDots,
    faEuroSign,
    faFilePdf,
    faGlobeEurope,
    faGraduationCap, faHome,
    faLanguage, faListAlt,
    faPollH,
    faSchool,
    faSearch,
    faShoppingBasket,
    faUser, faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function renderContent(entity: string) {
    switch (entity) {
        case 'profile':
            return (
              <FontAwesomeIcon icon={faUser} size="lg" css={css`color:#29B6F6`} />);
        case 'programs-list':
        case 'program':
            return (
              <FontAwesomeIcon icon={faShoppingBasket} size="lg" css={css`color:#000000`} />);
        case 'bachelor':
            return (
              <FontAwesomeIcon icon={faGraduationCap} size="lg" css={css`color:#E57373`} />);
        case 'academics-list':
        case 'academics':
            return (
              <FontAwesomeIcon icon={faSchool} size="lg" css={css`color:#1ABC9C`} />);
        case 'languages-list':
        case 'language':
            return (
              <FontAwesomeIcon icon={faLanguage} size="lg" css={css`color:#CC9966`} />);
        case 'stays-abroad-list':
        case 'stay-abroad':
            return (
              <FontAwesomeIcon icon={faGlobeEurope} size="lg" css={css`color:#283593`} />);
        case 'company-experiences-list':
        case 'company-experiences':
            return (
              <FontAwesomeIcon icon={faBriefcase} size="lg" css={css`color:#CDDC39`} />);
        case 'motivation':
            return (
              <FontAwesomeIcon icon={faPollH} size="lg" css={css`color:#7B7D7D`} />);
        case 'apprenticeship-company':
            return (
              <FontAwesomeIcon icon={faSearch} size="lg" css={css`color:#FF80AB`} />);
        case 'additional-documents-list':
        case 'additional-document':
            return (
              <FontAwesomeIcon icon={faFilePdf} size="lg" css={css`color:#9B59B6`} />);
        case 'payments-list':
            return (
              <FontAwesomeIcon icon={faEuroSign} size="lg" css={css`color:#000000`} />);
        case 'motivation-letter':
            return (
              <FontAwesomeIcon icon={faBriefcase} size="lg" css={css`color:#CDDC39`} />);
        case 'commentsBtwSecretaries':
            return (
              <FontAwesomeIcon icon={faHome} size="lg" css={css`color:#FFFFFF`} />);
        case 'commentsBtwAllTypesOfSecretaries':
            return (
              <FontAwesomeIcon icon={faUserFriends} size="lg" css={css`color:#FFFFFF`} />);
        case 'missingFields':
            return (
              <FontAwesomeIcon icon={faListAlt} size="lg" css={css`color:#FFFFFF`} />);
        case 'commentsBtwSecretaryAndCandidate':
            return (
              <FontAwesomeIcon icon={faCommentDots} size="lg" css={css`color:#FFFFFF`} />);
        case 'specializedSecretary':
            return (
              <FontAwesomeIcon icon={faHome} size="lg" css={css`color:#FFFFFF`} />);
        default:
            return (<FontAwesomeIcon className={entity} icon={faBriefcase} size="lg" />);
    }
}
export function EntityIcon({ entity }: { entity: string }) {
    return (
      <div css={css`width: 33px;text-align: center`}>
        {renderContent(entity)}
      </div>
);
}
