import { useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { AxiosContext, AxiosContextType } from '../context/axios.context';

const cache = {};

export function useColdData(entity: string, params ?: any): any[] {
    const { axios, axiosWithCache } = useContext<AxiosContextType>(AxiosContext);
    const [state, updateState] = useImmer({ data: undefined });

    if (!axios) {
        throw new Error('Missing context, please use this hook inside a context that provide axios');
    }

    useEffect(() => {
        (async () => {
            if (!cache[entity] || !Array.isArray(cache[entity])) {
                cache[entity] = {};
                const res = await axiosWithCache.get(`/${entity}`, { params });
                cache[entity] = res?.data || [];
            }
            updateState((draft: any) => {
                draft.data = cache[entity];
            });
        })();
    }, [axios, axiosWithCache, entity, params, updateState]);

    return [state];
}
