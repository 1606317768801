/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import * as Locale from '../../../../common/locale';
import { JuryReviewStatusCellRender, JuryReviewStatusFilter } from './JuryReviewStatusColumn';

export const juryReviewLastPublishedStatusEditorProps = {
    idProperty: 'juryReviewLastPublishedStatus',
};

export const juryReviewLastPublishedStatusColumn = {
    header: <Locale.Header tkey="candidateFilesList.juryReviewLastPublishedStatus" />,
    name: 'juryReviewLastPublishedStatus',
    id: 'juryReviewLastPublishedStatus',
    group: 'candidateFile',
    defaultWidth: 130,
    filterEditor: JuryReviewStatusFilter,
    render: JuryReviewStatusCellRender,
    filterEditorProps: juryReviewLastPublishedStatusEditorProps
};

export const juryReviewLastPublishedStatusFilter = {
    name: 'juryReviewLastPublishedStatus', operator: 'inlist', type: 'select', value: '',
};
