/* eslint-disable react-hooks/exhaustive-deps */
import { Input, notification } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import React, { Fragment, useEffect, useState } from 'react';
import * as Locale from '../../locale';
import { DisabledContext } from '../../rights/DisabledContext';
import { copyEntityFormProps, EntityFormItemContainer, ItemProps } from '../entityForm/EntityFormItemContainer';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

function InputTextAreaContent(props: { onChange?: any, onBlur?: any, value?: string, propertyName: string | any[] | number, allowClear: boolean, rows: number, disabled: boolean }) {
  const [txtValue, setTxtValue] = useState(props.value);

  useEffect(() => setTxtValue(props.value), [props.value]);
  return (
    <Fragment>
      <Input.TextArea
        data-testid={`${props.propertyName}`?.replace(/,/g, '_')}
        allowClear={props.allowClear}
        value={txtValue}
        readOnly={props.disabled}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setTxtValue(e.target.value);
        }}
        onBlur={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (!props.disabled && props.onBlur) {
            props.onBlur(txtValue);
            notification.success({ message: <Locale.Notification tkey="saved" /> });
          }
        }}
        rows={props.rows}
      />
      { /*}
      <ButtonsFormItemLayout>
        {!props.disabled
          && (
            <TextOkButton
              onClick={() => {
                notification.success({ message: <Locale.Notification tkey="saved" /> });
                props.onChange(txtValue);
              }}
              tkey="submit-text"
            />
          )}
      </ButtonsFormItemLayout> */
      }
    </Fragment>
  );
}

export function InputTextAreaWithSubmit(props: { propertyName: string | any[] | number, rows: number, onChange?: any, onBlur?: any, value?: string, allowClear?: boolean, disabled?: boolean }) {
  return (
    <InputTextAreaContent
      propertyName={props.propertyName}
      allowClear={props.allowClear}
      rows={props.rows}
      disabled={props.disabled}
      onChange={props.onChange}
      onBlur={props.onBlur}
      value={props.value}
    />
  );
}

/**
 * EntityFormItemContainer for text area input
 * @param props
 * @constructor
 */
export function InputTextAreaWithSubmitFormItem(props: ItemProps & Omit<TextAreaProps, 'disabled'>) {
  const disabled = useScopedSelector('SpecializedSecretaryReviewFormItem', DisabledContext, (c) => c?.disabled);
  return (
    <EntityFormItemContainer
      {...copyEntityFormProps(props)}
      validateTrigger={['onChange']}
    >
      <InputTextAreaWithSubmit
        propertyName={props.propertyName}
        allowClear={props.allowClear}
        rows={props.rows}
        disabled={disabled}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    </EntityFormItemContainer>
  );
}
