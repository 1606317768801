import styled from '@react-pdf/styled-components';
import React, { ReactNode } from 'react';

const Container = styled.Text`
font-size: 10px;
font-weight: 500;
`;

export function PDFH3(props: { debug?: boolean, children?: ReactNode, style?: any }) {
  return (
    <Container debug={props.debug} style={props.style}>
      {props.children}
    </Container>
  );
}
