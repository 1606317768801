import {useRoutes} from 'hookrouter';
import React from 'react';
import {CandidateFileListContextProvider} from '../../canSeeCandidateFile/CandidateFileListContext';
import {CandidateContainerRoutesDef} from './CandidateContainerRoutesDef';

export function CandidateContainer() {
    const routesChildren = useRoutes(CandidateContainerRoutesDef());
    return (
      <CandidateFileListContextProvider>
        {routesChildren}
      </CandidateFileListContextProvider>
    );
}
