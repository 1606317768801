import styled from '@react-pdf/styled-components';
import { View } from '@react-pdf/renderer';
import React, { Fragment } from 'react';
import { PDFCardGutter } from '../../../common/pdf/components/PDFCardGutter';
import { PDFH1 } from '../../../common/pdf/components/PDFH1';
import { PDFLabel } from '../../../common/pdf/components/PDFLabel';
import { PDFSpacer } from '../../../common/pdf/components/PDFSpacer';
import { PDFEntityForm } from '../../../common/pdf/form/PDFEntityForm';
import { PDFEntityFormList } from '../../../common/pdf/form/PDFEntityFormList';
import {
  PDFColdDataSelectorFormItem,
  PDFDatePickerFormItem,
  PDFInputFormItem,
} from '../../../common/pdf/form/PDFInputFormItems';
import { CompanyExperience, CompanyExperiencesList } from './export.type';
import { PDFText } from '../../../common/pdf/components/PDFText';

const Content = styled.View`
    color: #888;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 9px;    
`;

const ContentHeader = styled.View`
    margin-left: 7px;
    margin-right: 7px;
`;

export function CompanyExperiencesListFormPDF(props: { companyExperiencesList: CompanyExperiencesList }) {
  if (!props.companyExperiencesList) {
    return <PDFH1>Expériences professionnelles</PDFH1>;
  }
  return (
    <Fragment>
      <PDFH1>Expériences professionnelles</PDFH1>
      <PDFEntityForm
        entity="company-experiences-list"
        value={props.companyExperiencesList}
      >
        <PDFEntityFormList<CompanyExperience> propertyName="companyExperiences">
          {(value, index) => (
            <Fragment>
              <View wrap={false}>
                <PDFCardGutter>
                  <View style={{ width: '50%' }}>
                    <PDFInputFormItem
                      propertyName={['companyExperiences', index, 'name']}
                    />
                  </View>
                  <View style={{ width: '50%' }}>
                    <PDFInputFormItem
                      propertyName={['companyExperiences', index, 'city']}
                    />
                  </View>
                </PDFCardGutter>
                <PDFCardGutter>
                  <View style={{ width: '50%' }}>
                    <PDFColdDataSelectorFormItem
                      propertyName={['companyExperiences', index, 'as', 'id']}
                    />
                  </View>
                  <View style={{ width: '50%' }}>
                    <PDFInputFormItem
                      propertyName={['companyExperiences', index, 'service']}
                    />
                  </View>
                </PDFCardGutter>
                <PDFCardGutter>
                  <View style={{ width: '50%' }}>
                    <PDFDatePickerFormItem
                      propertyName={['companyExperiences', index, 'beginDate']}
                    />
                  </View>
                  <View style={{ width: '50%' }}>
                    <PDFDatePickerFormItem
                      propertyName={['companyExperiences', index, 'endDate']}
                    />
                  </View>
                </PDFCardGutter>
                <PDFSpacer />
                <ContentHeader>
                  <PDFLabel>Tâches réalisées</PDFLabel>
                </ContentHeader>
                <PDFSpacer />
                <Content>
                  <PDFText color="#888">
                    {value.production
                    }
                  </PDFText>
                </Content>
                <PDFSpacer />
              </View>
            </Fragment>
          )}
        </PDFEntityFormList>
      </PDFEntityForm>
    </Fragment>
  );
}
