import { DeleteOutlined, ExclamationCircleOutlined, RedoOutlined } from '@ant-design/icons';
import Menu from '@inovua/reactdatagrid-community/packages/Menu';
import { Modal, notification, Statistic } from 'antd';

// @ts-ignore
import React, { useContext } from 'react';
import { AxiosContext, AxiosContextType } from '../../../../context/axios.context';
import { sNavigateReload } from '../../../../utils/safeNavigation';
import { ADMIN } from '../../../user/UserRoutesDef';

const { confirm } = Modal;

/**
 * Composant de gestion du menu contextuel dans la grid
 */
export function JuryContextMenu({
                                    menuProps, details, selectedUsers, gridRef,
                                }: any) {
    const { axios } = useContext<AxiosContextType>(AxiosContext);

    const items = [];

    /**
     * Fonction appelée quand on demande la suppression des elements selectionnes
     * Affiche le nombre d'elements supprimes en message d'info puis redirige sur la page pour raffraichir la liste
     */
    const onDeleteSelection = async () => {
        if (selectedUsers.length > 0) {
            const listIds = selectedUsers.map((cf) => cf.id);
            try {
                const response: any = await axios.post('admin/jury/deleteSelection', listIds);
                notification.info({
                    message: `${response.data.deleted} éléments supprimés sur ${response.data.total} sélectionnées !`,
                    duration: 0,
                });
                const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
                await sleep(2000);
                sNavigateReload(ADMIN.JURY_LIST);
            } catch (e) {
                notification.error({
                    message: 'Erreur de suppression',
                    description: e.message,
                    duration: 0,
                });
            }
        }
    };

    /**
     * Fonction appelée quand on demande la suppression des elements selectionnes
     * Affiche le nombre d'elements supprimes en message d'info puis redirige sur la page pour raffraichir la liste
     */
    const onUpdatePasswordSelection = async () => {
        if (selectedUsers.length > 0) {
            const listIds = selectedUsers.map((cf) => cf.id);
            try {
                const response: any = await axios.post('admin/jury/updatePasswordSelection', listIds);
                notification.info({
                    message: `${response.data.modified} mots de passe regénérés pour ${response.data.total} utilisateurs sélectionnées !`,
                    duration: 0,
                });
            } catch (e) {
                notification.error({
                    message: 'Erreur de regénération des mots de passe',
                    description: e.message,
                    duration: 0,
                });
            }
        }
    };

    /* On ajoute ici dans le menu contextuel le menu permettant d'effacer la selection */
    if (selectedUsers.length > 0) {
        items.push({
            label: 'Supprimer...',
            icon: <DeleteOutlined />,
            onClick: (value) => {
                confirm({
                    // @ts-ignore
                    okButtonProps: { 'data-testid': 'jury-publish-results-ok' },
                    // @ts-ignore
                    cancelButtonProps: { 'data-testid': 'jury-publish-results-cancel' },
                    title: 'Suppression des utilisateurs',
                    icon: <ExclamationCircleOutlined />,
                    content: (
                      <div>
                        <Statistic
                          title="Nombre d'éléments selectionnés"
                          value={selectedUsers.length}
                        />
                        Voulez-vous vraiment supprimer ces utilisateurs ?
                      </div>
                    ),
                    onOk: () => onDeleteSelection(), // Appel de la suppression des elements selectionnes
                });
            },
        });

        /* On ajoute ici dans le menu contextuel le menu permettant d'effacer la selection */
        items.push({
            label: 'Regénérer un mot de passe...',
            icon: <RedoOutlined />,
            onClick: (value) => {
                confirm({
                    // @ts-ignore
                    okButtonProps: { 'data-testid': 'jury-publish-results-ok' },
                    // @ts-ignore
                    cancelButtonProps: { 'data-testid': 'jury-publish-results-cancel' },
                    title: 'Regénération mots de passe',
                    icon: <ExclamationCircleOutlined />,
                    content: (
                      <div>
                        <Statistic
                          title="Nombre d'éléments selectionnés"
                          value={selectedUsers.length}
                        />
                        Voulez-vous vraiment regénérer un mot de passe pour ces utilisateurs ?
                      </div>
                    ),
                    onOk: () => onUpdatePasswordSelection(), // Appel de la regeneration de mot de passe pour des elements selectionnes
                });
            },
        });
    }

    return (
      <Menu
        {...menuProps}
        items={items}
      />
    );
}
