/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {AdminEditableList} from '../../common/AdminEditableList';
import {
    configsListInitialColumns,
    configsListInitialFilters,
    configsListMapToRow,
    configsListRenderRowContextMenu,
} from './IdiomInitialConfig';

export function IdiomListPage() {
  return (
    <Fragment>
      <AdminEditableList
        entity="admin/idiom"
        renderRowContextMenu={configsListRenderRowContextMenu}
        initialFilters={configsListInitialFilters}
        initialColumns={configsListInitialColumns()}
        gridStyle={{ height: 'calc( 100% - 250px)' }}
        mapToRow={configsListMapToRow}
      />
    </Fragment>
  );
}
