import { CandidateFileStatusEnum, ValidationStep } from '@fstn/ecandidaturev2_api-interfaces';
import React, { useContext } from 'react';
import { WithInitialLoad } from '../../common/withExtraParameters/WithInitialLoad';
import { RightsContextProvider } from '../../context/rights.context';
import { UserContext, UserContextType } from '../../context/user.context';
import { ValidationsContextProvider } from '../../context/validations.context';
import {
    CandidateFileContext,
    CandidateFileContextProvider,
    CandidateFileContextProviderType,
} from '../canSeeCandidateFile/CandidateFileContext';
import { CandidatePage } from './CandidatePage';

function InternalCandidatePageWithValidations(props: { children }) {
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    let steps = [ValidationStep.SUBMIT, ValidationStep.PAYMENT];
    if (candidateFileContext.selectedCandidateFile?.status?.id === CandidateFileStatusEnum.WAITING_CANDIDATE) {
        steps = [ValidationStep.SUBMIT, ValidationStep.SECRETARY_REVIEW];
    }
    return (
      <ValidationsContextProvider steps={steps} entity="candidate-file">
        <RightsContextProvider>
          <CandidatePage>
            {props.children}
          </CandidatePage>
        </RightsContextProvider>
      </ValidationsContextProvider>
);
}

function ICandidatePageWithValidations(props: { children: any }) {
    // console.log('Redraw CandidatePageWithValidations');
    const { userCtx } = useContext<UserContextType>(UserContext);
    return (
      <WithInitialLoad
        entity="candidate-file"
        entityId={userCtx.user?.candidateFile?.id}
        propsName="selectedCandidateFile"
      >
        <CandidateFileContextProvider>
          <InternalCandidatePageWithValidations>
            {props.children}
          </InternalCandidatePageWithValidations>
        </CandidateFileContextProvider>
      </WithInitialLoad>
    );
}

export const CandidatePageWithValidations = ICandidatePageWithValidations;
