import { flatMap } from 'lodash';
import { formValidationGetter } from './formValidationGetter';

export function hasValidationsErrors<T>(entity: string, validationForEntity: Partial<T>, propertyName: string | any[] | number, t: (key: string) => string): any {
    const validation = formValidationGetter<T>(validationForEntity, propertyName);
    const result = !!validation && Object.values(validation).length > 0;
    return result;
}
/**
 * Get validation message
 * @param entity
 * @param validationForEntity
 * @param propertyName
 * @param t
 */
export function formValidationMessage<T>(entity: string, validationForEntity: Partial<T>, propertyName: string | any[] | number, t: (key: string) => string): any {
    const help = t(`${entity}.${propertyName}.help`);
    if (help?.length > 0 && !(help.startsWith('"') && help.endsWith('"') && help?.length === 2)) {
      return help;
    }

    const validation = formValidationGetter<T>(validationForEntity, propertyName);
    if (validation && Object.values(validation).length > 0) {
        // @ts-ignore

        return flatMap(Object.values((validation || {})), ((msgs: string) => (`${msgs}`).split(',').map((msg) => {
            if (Array.isArray(propertyName)) {
                for (const propertyElt of propertyName) {
                    msg = msg.replace(propertyElt, '');
                }
                return msg;
            }
            return msg.replace(`${propertyName}`, '');
        })))
            // remove validating:true
            ?.filter((m) => m !== 'true')
            ?.map((m) => t(m.trim()) || undefined).join(',');
    }
    return undefined;
}
