import styled from '@emotion/styled';

// .ant-switch-checked
import {
    IComments,
    IMissingFieldsList,
    ISecretaryReview,
    SecretaryReviewStatusEnum,
} from '@fstn/ecandidaturev2_api-interfaces';
import React from 'react';
import { useImmer } from 'use-immer';

import { SecretaryReviewFormContainer } from './SecretaryReviewFormContainer';

export function SecretaryReviewFormItemContent(props: { onChange?: (d: Partial<ISecretaryReview>) => void,
    secretaryReviewId: number,
    secretaryReviewState: SecretaryReviewStatusEnum,
    particularCase: boolean,
    ignoreIt: boolean,
    commentsBtwSecretaries: any
    commentsBtwAllTypesOfSecretaries:any,
    commentsBtwSecretaryAndCandidate:any,
    missingFieldsList: IMissingFieldsList,
    candidateFileStatusId:string
     }) {
   const [state, updateState] = useImmer<{
        secretaryReview: Partial<ISecretaryReview>,
        commentsBtwSecretaries: string,
        commentsBtwAllTypesOfSecretaries: string,
        commentsBtwSecretaryAndCandidate: IComments[],
        particularCase: boolean,
        ignoreIt: boolean,
        status: string,
        missingFieldsList: IMissingFieldsList
    }>({
        secretaryReview: {
            id: props.secretaryReviewId,
            state: props.secretaryReviewState,
        },
        particularCase: props.particularCase,
        ignoreIt: props.ignoreIt,
        commentsBtwSecretaries: props.commentsBtwSecretaries,
        commentsBtwAllTypesOfSecretaries: props.commentsBtwAllTypesOfSecretaries,
        commentsBtwSecretaryAndCandidate: props.commentsBtwSecretaryAndCandidate,
        missingFieldsList: props.missingFieldsList,
        status: props.candidateFileStatusId || '',
    });

    return (
      <Style>
        <Header state={state} updateState={updateState} onChange={props.onChange} />
      </Style>
    );
}

export function Header(props: { state: any, updateState: any, onChange: (d: Partial<ISecretaryReview>) => void }) {
    // console.log('Redraw IHeader');
    return <SecretaryReviewFormContainer state={props.state} updateState={props.updateState} onChange={props.onChange} />;
}

const Style = styled.div`
`;
