import Button from 'antd-button-color';
import React, { Fragment } from 'react';
import { useResponsiveInputSize } from '../../hooks/use-responsive-input-size.hook';
import { IsDesktop } from '../../pages/user/conditionnal/IsDesktop';
import { IsMobile } from '../../pages/user/conditionnal/IsMobile';

/**
 * Responsive button
 * @param props
 * @constructor
 */
export function ResponsiveButton(props: any) {
    const { size } = useResponsiveInputSize();
    return (
      <Fragment>
        <IsDesktop>
          <Button {...props} size={props.size || size} />
        </IsDesktop>
        <IsMobile>
          <Button
            {...props}
            style={{
 width: '100%', minHeight: '80px', minWidth: '80px', ...(props.style || {}),
}}
            size={props.size || size}
          />
        </IsMobile>
      </Fragment>
);
}
