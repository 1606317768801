import React, { Fragment } from 'react';
import { PDFH1 } from '../../../common/pdf/components/PDFH1';
import { Motivation } from './export.type';
import styled from '@react-pdf/styled-components';
import { PDFLabel } from '../../../common/pdf/components/PDFLabel';
import { PDFText } from '../../../common/pdf/components/PDFText';
import { PDFSpacer } from '../../../common/pdf/components/PDFSpacer';


const Content = styled.View`
    color: #888;
    margin-left: 5px;
    font-size: 9px;    
`;
export function MotivationFormPDF(props: { motivation: Motivation, debug?: boolean }) {
  if (!props.motivation) {
    return <PDFH1>Motivations</PDFH1>;
  }
  return (
    <Fragment>
      <PDFH1 debug={props.debug}>Motivations</PDFH1>

      <PDFLabel>Pourquoi aspirez-vous à devenir ingénieur </PDFLabel>
      <PDFSpacer />
      <Content>
        <PDFText color="#888">
          {props.motivation.why
          }
        </PDFText>
      </Content>
      <PDFSpacer />

      {!!props.motivation.order && (
        <Fragment>
          <PDFLabel>Pouvez-vous indiquer votre ordre de préférence entre les formations que vous avez sélectionnées dans ce dossier ? Si
            vous n'avez pas encore établi de priorités, merci d’en expliquer les raisons </PDFLabel>
          <PDFSpacer />
          <Content>
            <PDFText color="#888">
              {props.motivation.order
              }
            </PDFText>
          </Content>
          <PDFSpacer />
        </Fragment>
      )}

      <PDFLabel>Citez les autres formations, en dehors de celles de ce dossier, auxquelles vous êtes candidat(e) </PDFLabel>
      <PDFSpacer />
      <Content>
        <PDFText color="#888">
          {props.motivation.otherPrograms
          }
        </PDFText>
      </Content>
      <PDFSpacer />

      <PDFLabel>Pourquoi choisissez-vous la voie de l'apprentissage ?* (Si bien sûr vous souhaitez avoir ce statut. Sinon indiquer "non
        concerné") </PDFLabel>
      <PDFSpacer />
      <Content>
        <PDFText color="#888">
          {props.motivation.whyApprenticesship
          }
        </PDFText>
      </Content>
      <PDFSpacer />

      <PDFLabel>Comment souhaitez-vous orienter votre carrière professionnelle </PDFLabel>
      <PDFSpacer />
      <Content>
        <PDFText color="#888">
          {props.motivation.professionalOrientation
          }
        </PDFText>
      </Content>
      <PDFSpacer />

      <PDFLabel>Pourriez-vous illustrer par une expérience vécue, votre capacité à animer une équipe ou un groupe de travail </PDFLabel>
      <PDFSpacer />
      <Content>
        <PDFText color="#888">
          {props.motivation.teamWork
          }
        </PDFText>
      </Content>
      <PDFSpacer />

      <PDFLabel>Faites un portrait de vous-même en indiquant brièvement vos points forts et vos points faibles </PDFLabel>
      <PDFSpacer />
      <Content>
        <PDFText color="#888">
          {props.motivation.profile
          }
        </PDFText>
      </Content>
      <PDFSpacer />

      <PDFLabel>Quelles sont vos activités associatives, culturelles, sportives, vos expériences significatives à l’étranger à but touristique
        ou humanitaire, ou autres centres d'intérêt... </PDFLabel>
      <PDFSpacer />
      <Content>
        <PDFText color="#888">
          {props.motivation.activity
          }
        </PDFText>
      </Content>
      <PDFSpacer />
    </Fragment>
  );
}
