export const ROUTES = {
    ALL: '*',
    LOGIN: '/',
    BASE: '/home/welcome',
    PUBLIC_BASE: '/public',
    ADMIN_BASE: '/home/welcome/admin',
    CANDIDATE_BASE: '/home/welcome/candidate',
    SECRETARY_BASE: '/home/welcome/secretary',
    REFERENT_BASE: '/home/welcome/referent',
    EXAMINER_BASE: '/home/welcome/examiner',
    PROGRAM_MANAGER_BASE: '/home/welcome/program-manager',
    SPECIALIZED_SECRETARY: '/home/welcome/specialized-secretary',
    JURY_BASE: '/home/welcome/jury',
    RGPD_BASE: '/public/rgpd',
    REGISTER_BASE: '/public/register',
    SUBMIT: '/home/welcome/candidate/submit',
};

export const EXAMINER = {
    REVIEW: `${ROUTES.EXAMINER_BASE}/:candidateFileId/review`,
};

export const REFERENT = {
    ACCEPT: `${ROUTES.REFERENT_BASE}/:candidateFileId/:academicReferentReviewId/accept-review`,
    REFUSE: `${ROUTES.REFERENT_BASE}`,
};

export const LOGIN = {
    LOGIN: '/login',
    LOGOUT: '/logout',
    REGISTER_EMAIL: `${ROUTES.PUBLIC_BASE}/register-email`,
    REGISTER_CODE: `${ROUTES.PUBLIC_BASE}/register-code/:email`,
    REGISTER: `${ROUTES.PUBLIC_BASE}/register/:email/:code`,
};

export const RGPD = {
    ASK: `${ROUTES.RGPD_BASE}/ask`,
    REFUSED: `${ROUTES.RGPD_BASE}/refused`,
};

export const ADMIN = {
    CLEAN_CLOSE_PROGRAM: `${ROUTES.ADMIN_BASE}/cleanCloseProgram`,
    TEST_PUBLISH_JURYREVIEW_EMAIL: `${ROUTES.ADMIN_BASE}/testPublishJuryReviewEmail`,
    IMPERSONATE: `${ROUTES.ADMIN_BASE}/impersonate`,
    CANDIDATE_FILES_STATISTICS: `${ROUTES.ADMIN_BASE}/candidateFilesStatistics`,
    LOCALE_LIST: `${ROUTES.ADMIN_BASE}/localeList`,
    LOCALE_EDIT: `${ROUTES.ADMIN_BASE}/localeEdit`,
    PROGRAM_LIST: `${ROUTES.ADMIN_BASE}/programList`,
    NUMBER_CONFIG_LIST: `${ROUTES.ADMIN_BASE}/numberConfigList`,
    STRING_CONFIG_LIST: `${ROUTES.ADMIN_BASE}/stringConfigList`,
    DATE_CONFIG_LIST: `${ROUTES.ADMIN_BASE}/dateConfigList`,
    JURY_REVIEW_STATUS_CONFIG_LIST: `${ROUTES.ADMIN_BASE}/juryReviewStatusList`,
    SECRETARY_LIST: `${ROUTES.ADMIN_BASE}/secretaryList`,
    SECRETARY_EDIT: `${ROUTES.ADMIN_BASE}/secretaryEdit`,
    PROGRAM_MANAGER_LIST: `${ROUTES.ADMIN_BASE}/programManagerList`,
    PROGRAM_MANAGER_EDIT: `${ROUTES.ADMIN_BASE}/programManagerEdit`,
    EXAMINER_LIST: `${ROUTES.ADMIN_BASE}/examinerList`,
    EXAMINER_EDIT: `${ROUTES.ADMIN_BASE}/examinerEdit`,
    JURY_LIST: `${ROUTES.ADMIN_BASE}/juryList`,
    JURY_EDIT: `${ROUTES.ADMIN_BASE}/juryEdit`,
    REFERENT_LIST: `${ROUTES.ADMIN_BASE}/referentList`,
    REFERENT_REVIEW_LIST: `${ROUTES.ADMIN_BASE}/referentReviewList`,
    ADMIN_LIST: `${ROUTES.ADMIN_BASE}/adminList`,
    ADMIN_EDIT: `${ROUTES.ADMIN_BASE}/adminEdit`,
    CANDIDATE_EDIT: `${ROUTES.ADMIN_BASE}/candidateEdit`,
    SPECIALIZED_SECRETARY_LIST: `${ROUTES.ADMIN_BASE}/specializedSecretaryList`,
    SPECIALIZED_SECRETARY_EDIT: `${ROUTES.ADMIN_BASE}/specializedSecretaryEdit`,
    ACADEMY_IMPORTER: `${ROUTES.ADMIN_BASE}/academyImporter`,
    BACHELOR_SERIES_IMPORTER: `${ROUTES.ADMIN_BASE}/bachelorSeriesImporter`,
    BACHELOR_SPECIALITY_IMPORTER: `${ROUTES.ADMIN_BASE}/bachelorSpecialityImporter`,
    BACHELOR_MENTION_IMPORTER: `${ROUTES.ADMIN_BASE}/bachelorMentionImporter`,
    LANGUAGE_LEVEL_IMPORTER: `${ROUTES.ADMIN_BASE}/languageLevelImporter`,
    IDIOM_IMPORTER: `${ROUTES.ADMIN_BASE}/languageImporter`,
    PREVIOUS_STATISTIC_IMPORTER: `${ROUTES.ADMIN_BASE}/previousStatisticImporter`,
    COUNTRY_IMPORTER: `${ROUTES.ADMIN_BASE}/countryImporter`,
    TRAFFIC_SOURCE_IMPORTER: `${ROUTES.ADMIN_BASE}/trafficSourceImporter`,
    SCHOOL_TRACK_TYPE_IMPORTER: `${ROUTES.ADMIN_BASE}/schoolTrackTypeImporter`,
    SCHOOL_TRACK_IMPORTER: `${ROUTES.ADMIN_BASE}/schoolTrackImporter`,
    SCHOOL_IMPORTER: `${ROUTES.ADMIN_BASE}/schoolImporter`,
    USER_IMPORTER: `${ROUTES.ADMIN_BASE}/userImporter`,
    PROGRAM_IMPORTER: `${ROUTES.ADMIN_BASE}/programImporter`,
    PAYMENT_CHECKER_IMPORTER: `${ROUTES.ADMIN_BASE}/paymentCheckerImporter`,
    NOSTUDY_ACTIVITY_IMPORTER: `${ROUTES.ADMIN_BASE}/nostudyActivityImporter`,
};

export const RegisterRoutesDef = (t) => ({});
