/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import * as Locale from '../../../../common/locale';
import {SpecializedSecretaryReviewFormContainer} from '../../../specializedSecretary/SpecializedSecretaryReviewFormContainer';

/**
 * Specialized secretary part of right container
 * @constructor
 */
export const SpecializedSecretaryRightContainer = () => (
  <Fragment>
    <h1><Locale.Title tkey="secretaryReview.secretary.specializedSecretaryReview" /></h1>
    <br />
    <SpecializedSecretaryReviewFormContainer />
  </Fragment>
    );
