/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext } from 'react';
import { Importer, ImporterField } from 'react-csv-importer-v2';

/**
 * LAURENT
 * DOCUMENTATION: npm i react-csv-importer-v2
 */
// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer-v2/dist/index.css';
import { useImmer } from 'use-immer';
import { AxiosContext, AxiosContextType } from '../../../../context/axios.context';
import { sNavigateReload } from '../../../../utils/safeNavigation';
import { ADMIN } from '../../../user/UserRoutesDef';
import { SchoolTrackListPage } from './SchoolTrackListPage';
import { ResultPanel } from '../../common/ResultPanel';
import { ButtonDeleteAll } from '../../common/ButtonDeleteAll';

export function SchoolTrackImporter() {
  const [state, updateState] = useImmer({ values: [], error: undefined as string, result: undefined as string });
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  return (
    <Fragment>
      <Importer
        delimiter=";"
        // restartable // optional, lets user choose to upload another file when import is complete
        onStart={() => {
        }}
        processChunk={async (rows) => {
          updateState((draft) => {
            draft.values = [...draft.values, ...rows];
          });
        }}
        onComplete={async () => {
          try {
            const response: any = await axios?.post('/import/school-track', {
              bulk: state.values,
            });
            updateState((draft) => {
              draft.result = `${response.data.created} filières importées et ${response.data.updated} fillières mises à jour`;
              draft.error = undefined;
            });
          } catch (ex) {
            updateState((draft) => {
              draft.error = ex.response.data.message;
              draft.result = undefined;
            });
          }
        }}
        onClose={async () => {
          sNavigateReload(ADMIN.SCHOOL_TRACK_IMPORTER);
        }}
      >
        <ImporterField name="id" label="Id" />
        <ImporterField name="type" label="Type" />
        <ImporterField name="subject1" label="Subject1" />
        <ImporterField name="subject2" label="Subject2" />
        <ImporterField name="subject3" label="Subject3" />
        <ImporterField name="subject4" label="Subject4" />
        <ImporterField name="subject5" label="Subject5" optional />
        <ImporterField name="subject6" label="Subject6" optional />
        <ImporterField name="subject7" label="Subject7" optional />
        <ImporterField name="subject8" label="Subject8" optional />
        <ImporterField name="subject9" label="Subject9" optional />
        <ImporterField name="subject10" label="Subject10" optional />
        <ImporterField name="subject11" label="Subject11" optional />
        <ImporterField name="subject12" label="Subject12" optional />
        <ImporterField name="subject13" label="Subject13" optional />
        <ImporterField name="subject14" label="Subject14" optional />
        <ImporterField name="subject15" label="Subject15" optional />
        <ImporterField name="subject16" label="Subject16" optional />
        <ImporterField name="subject17" label="Subject17" optional />
        <ImporterField name="subject18" label="Subject18" optional />
        <ImporterField name="subject19" label="Subject19" optional />
        <ImporterField name="subject20" label="Subject20" optional />
        <ImporterField name="subject21" label="Subject21" optional />
        <ImporterField name="subject22" label="Subject22" optional />
        <ImporterField name="subject23" label="Subject23" optional />
        <ImporterField name="subject24" label="Subject24" optional />
        <ImporterField name="subject25" label="Subject25" optional />
        <ImporterField name="subject26" label="Subject26" optional />
        <ImporterField name="subject27" label="Subject27" optional />
        <ImporterField name="subject28" label="Subject28" optional />
        <ImporterField name="subject29" label="Subject29" optional />
        <ImporterField name="subject30" label="Subject30" optional />
        <ImporterField name="subject31" label="Subject31" optional />
        <ImporterField name="subject32" label="Subject32" optional />
      </Importer>
      <br />
      <ButtonDeleteAll message="filières supprimées sur" url="/api/admin/school-track/deleteAll" updateState={updateState} />
      <ResultPanel error={state.error} result={state.result} redirect={ADMIN.SCHOOL_TRACK_IMPORTER} />
      <br />
      <span><h1>Liste des filières</h1></span>
      <SchoolTrackListPage />
    </Fragment>
  );
}
