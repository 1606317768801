import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import React, { MutableRefObject } from 'react';
import BoolEditor from '@inovua/reactdatagrid-community/BoolEditor';
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
import { ProgramListContextMenu } from './ProgramListContextMenu';
import { getSelectedElements } from '../../common/AdminEditableList';

export const programsListInitialColumns = (t) => [
    {
        header: 'Nom formation',
        name: 'name',

        defaultWidth: 200,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Option',
        name: 'optionName',

        defaultWidth: 400,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Ouverte?',
        name: 'opened',

        editor: BoolEditor,
        render: ({ value }) => (value ? 'yes' : 'no'),
        defaultWidth: 90,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Places ouvertes',
        name: 'capacity',

        defaultWidth: 130,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Position',
        name: 'homePosition',

        defaultWidth: 90,
        lockable: false,
        filterable: true,
        type: 'number',
        editor: NumericEditor,
    },
    {
        header: 'URL descriptif',
        name: 'website',

        defaultWidth: 200,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Etablissement',
        name: 'school',

        defaultWidth: 200,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Campus',
        name: 'campus',

        defaultWidth: 200,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Sendgrid API Key',
        name: 'sendgridApiKey',

        defaultWidth: 220,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Email émetteur',
        name: 'emailSender',

        defaultWidth: 200,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Template Id non convoqué',
        name: 'notSummonedTemplateId',

        defaultWidth: 220,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Template Id convoqué écrits',
        name: 'summonedWritingsTemplateId',

        defaultWidth: 220,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Template Id convoqué oraux',
        name: 'summonedOralsTemplateId',

        defaultWidth: 220,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Template Id admissible liste complémentaire',
        name: 'eligibleCompListTemplateId',

        defaultWidth: 220,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Template Id admissible',
        name: 'okEligibleTemplateId',

        defaultWidth: 220,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Template Id grand admissible',
        name: 'eliteAdmittedTemplateId',

        defaultWidth: 220,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Template Id admis',
        name: 'admittedTemplateId',

        defaultWidth: 220,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Template Id admis liste attente',
        name: 'waitingListAdmittedTemplateId',

        defaultWidth: 220,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Template Id non retenu jury final',
        name: 'notSelectedJuryFinalTemplateId',

        defaultWidth: 220,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Template Id abandon',
        name: 'cancelledTemplateId',

        defaultWidth: 220,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Template Id absents tests',
        name: 'absentTestsTemplateId',

        defaultWidth: 220,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Formations acceptées',
        name: 'acceptedSchoolTracks',

        defaultWidth: 400,
        lockable: false,
        filterable: true,
    },
    {
        header: 'URL logo Ecole',
        name: 'logoUrl',

        defaultWidth: 200,
        lockable: false,
        filterable: true,
    },
    {
        header: 'URL logo partenaire / autre',
        name: 'logo2Url',

        defaultWidth: 200,
        lockable: false,
        filterable: true,
    },
    {
        header: 'URL Notice',
        name: 'noticeUrl',

        defaultWidth: 200,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Statut élève',
        name: 'status',

        defaultWidth: 150,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Domaines de spécialité',
        name: 'theme',

        defaultWidth: 300,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Niveau requis',
        name: 'requiredLevel',

        defaultWidth: 150,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Question spécifique fomation',
        name: 'specificQuestion',

        defaultWidth: 300,
        lockable: false,
        filterable: true,
    },
    {
        header: 'Nb dossiers liés',
        name: 'programLines',

        defaultWidth: 150,
        lockable: false,
        filterable: true,
        editable: false,
        render: ({ value }) => (<div>{value?.length}</div>),
    },
];

export const programsListInitialFilters = [
    {
        name: 'optionName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'campus', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'school', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'name', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'requiredLevel', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'theme', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'status', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'acceptedSchoolTracks', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'specificQuestion', operator: 'contains', type: 'string', value: '',
    },
];

export function programsListRenderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedPrograms, gridRef) {
    return (
        <ProgramListContextMenu
            menuProps={menuProps}
            details={details}
            selectedPrograms={getSelectedElements(details.rowProps.data, selectedPrograms)}
            gridRef={gridRef}
        />
    );
}

/**
 * Map result to row
 * @param d
 */
export function programsListMapToRow(d: any) {
    const row: any = {
        ...d,
    };
    return row;
}
