/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Card, Divider, List, Space, Typography, } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Fragment } from 'react';
import { UserConfigContextProvider } from '../../context/user.config.context';

export function AdminHome() {
  const data = [
    "Gestions des paramètres applicatifs de l'application (traductions, paramètres, ...)",
    'Gestions des utilisateurs (création, édition, ...) par type (jury, secrétaire, ...)',
    'Gestions des référents et des demandes de recommandations',
    "Importation des données de base de l'application, y compris les utilisateurs (secrétaires, jury, ...)",
  ];
  return (
    <UserConfigContextProvider>
      <Title level={2}>Administration eCandidature V2</Title>
      <Divider />
      <Space direction="vertical">
        <Card title="Fonctions du site" style={{ width: 800 }}>
          <List
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text mark>*</Typography.Text>
                {' '}
                {item}
              </List.Item>
            )}
          />
        </Card>
        <Card
          style={{ width: 800 }}
          headStyle={{ color: 'red' }}
          title="ATTENTION"
        >
          <p>
            La
            <strong> totalité</strong>
            {' '}
            des jurys (
            <u>1 pour chaque formation</u>
            ) doit impérativement être créée avant de débuter la campagne.
            <br />
            Dans le cas contraire, les avis jurys ne seront pas exportés correctement.
          </p>
          <p>
            <strong><em>Note :</em></strong>
            {' '}
            S'ils ne sont pas connus avant le début de la campagne, il suffit de les créer avec une adresse e-mail factice, qui pourra ensuite être modifiée quand
            le jury sera effectivement connu.
          </p>
          <p>
            <br />
            Le paramètre <em>START_SCHOOL</em> pour le calcul de l'âge limite nécessitant des conditions dérogatoires à remplir obligatoirement n'est pris en compte 
            qu'au lancement des serveurs. Il doit donc être défini <strong>avant le début de la campagne</strong> et nécessite un redémarrage des serveurs back.
          </p>

        </Card>
      </Space>
      <Fragment />
    </UserConfigContextProvider>
  );
}
