/* eslint-disable react-hooks/exhaustive-deps */
import styled from '@emotion/styled';
import { Alert, Modal } from 'antd';
import React, { Fragment, useContext, useEffect } from 'react';
import { LogoutImage } from '../../assets/LogoutImage';
import * as Locale from '../../common/locale';
import { AxiosContextProvider } from '../../context/axios.context';
import { UserContext } from '../../context/user.context';
import { useUser } from '../../hooks/use-user.hook';
import { sNavigate } from '../../utils/safeNavigation';
import { CenterForm } from './CenterForm';
import { getEventServiceInstance } from '../../utils/eventService';

const Style = styled.div`
  overflow: auto;
  max-height: 100%;
`;

export function LogoutPage() {
    return (
      <Style>
        <AxiosContextProvider>
          <Modal
            visible
            title={<Locale.Content tkey="logout.header" />}
            okText={<Locale.Content tkey="logout.login" />}
            okButtonProps={{ danger: true }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={() => sNavigate('/login')}
            closable={false}
          >
            <LogoutImage />
            <br />
            <br />
            <Alert type="warning" message={<Locale.Content tkey="logout.messages" />} />
            <CenterForm>
              <LogoutForm />
            </CenterForm>
          </Modal>
          <Locale.Content tkey="logout.footer" />
        </AxiosContextProvider>
      </Style>
    );
}

export function LogoutForm() {
    const userCtx = useContext(UserContext);
    const { unloadMe } = useUser(userCtx);
    useEffect(() => {
        getEventServiceInstance().logEvent(`logout_${userCtx.userCtx?.user?.role?.name}`);
        getEventServiceInstance().setUserId(null);
        unloadMe().then();
    }, [unloadMe]);
    return <Fragment />;
}
