/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { DEFAULT, ISchoolTrack, ISchoolTrackType } from '@fstn/ecandidaturev2_api-interfaces';
import { Affix, ButtonProps, Card } from 'antd';
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useContext } from 'react';
import { Box } from 'rebass';

import { TextOkButton } from '../../../common/button/TextOkButton';
import { EntityFormContainer } from '../../../common/form/entityForm/EntityFormContainer';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { CheckboxFormItem } from '../../../common/form/inputFormItems/CheckboxFormItem';
import { ColdDataAutoCompleteFormItem } from '../../../common/form/inputFormItems/ColdDataAutoCompleteFormItem';
import { FixSelectFormItem } from '../../../common/form/inputFormItems/FixSelectFormItem';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import { InputPasswordFormItem } from '../../../common/form/inputFormItems/InputPasswordFormItem';
import { SchoolAutoCompleteFormItem } from '../../../common/form/inputFormItems/SchoolAutoCompleteFormItem';
import { HorizontalFormItem } from '../../../common/form/inputFormItems/HorizontalFormItem';
import { Important } from '../../../common/layout/Important';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { EntityFormContext } from '../../../context/entityForm.context';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { sNavigateReload } from '../../../utils/safeNavigation';
import { DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { ButtonsFormItemLayout } from '../../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { useLoading } from '../../../hooks/use-loading';
import { useUser } from '../../../hooks/use-user.hook';
import { UserContext, UserContextType } from '../../../context/user.context';
import { tryLogin } from '../login/Login';
import { RGPD } from '../../user/UserRoutesDef';
import { IsDesktop } from '../../user/conditionnal/IsDesktop';
import { IsMobile } from '../../user/conditionnal/IsMobile';
import { SwitchFormItem } from '../../../common/form/inputFormItems/SwitchFormItem';

function SubmitButton(props: ButtonProps) {
  return (
    <TextOkButton
      data-testid="submit.register"
      tkey="register"
      htmlType="submit"
      {...props}
    />
  );
}

// eslint-disable-next-line react/no-unused-prop-types
export function RegisterForm(props: { email: string, code: string, firstName: string, lastName: string }) {
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  const { doAction, loading } = useLoading();
  const userCtx = useContext<UserContextType>(UserContext);
  const { login } = useUser(userCtx);

  const onFinish = async (values) => doAction(async () => {
    await axios.post('/register', values);
    await tryLogin(login, { ...values, remember: true });
    sNavigateReload(RGPD.ASK);
  });

  const onBeforeValidate = (values: any | any, differences: Partial<any>, setFieldsValue) => {
    if (differences.phone?.startsWith?.('0')) {
      const phone = differences.phone.replace('0', '+33');
      differences.phone = phone;
    }
    return differences;
  };

  return (
    <Card css={css`max-width: min(100%,700px); margin: auto`}>
      <EntityFormContainer
        entity="register"
        initialValues={{ ...props, rights: DEFAULT }}
        listeners={{ onBeforeValidate }}
        layout="horizontal"
        onFinish={onFinish}
      >
        <RegisterFormContent loading={loading} />
      </EntityFormContainer>
    </Card>
  );
}

function RegisterFormContent(props: { loading: boolean }) {
  const responsive = useResponsiveForm();
  const { t } = useSafeTranslation();
  const schoolTrack = useScopedSelector<any[], ISchoolTrack>('RegisterFormContent_schoolTrack', EntityFormContext, (c) => c[0]?.entityValue?.schoolTrack);
  const schoolTrackType = useScopedSelector<any[], ISchoolTrackType>('RegisterFormContent_schoolTrackType', EntityFormContext, (c) => c[0]?.entityValue?.schoolTrackType);

  return (
    <DisabledContextProvider value={{ disabled: false, wrapAuthorized: true }}>
      <FormContentPanelContainer partName="register">

        <Box {...responsive.w1}>
          <FixSelectFormItem
            propertyName="gender"
            options={[
              { value: 'm', label: t('gender.male') },
              { value: 'f', label: t('gender.female') },
              { value: 'o', label: t('gender.other') }]}
          />
        </Box>
        <Box {...responsive.w1}>
          <InputFormItem
            propertyName="firstName"
          />
        </Box>
        <Box {...responsive.w1}>
          <InputFormItem
            propertyName="lastName"
          />
        </Box>
        <Box {...responsive.w1}>
          <InputFormItem
            propertyName="phone"
            autoComplete="phone"
          />
        </Box>
        <InputFormItem
          propertyName="code"
          hidden
        />
        <Box {...responsive.w1}>
          <DisabledContextProvider value={{ disabled: true, wrapAuthorized: true }}>
            <InputFormItem
              propertyName="email"
              autoComplete="email"
            />
          </DisabledContextProvider>
        </Box>
        <Box {...responsive.w1}>
          <InputPasswordFormItem
            propertyName="password"
            autoComplete="new-password"
          />
        </Box>
        <Box {...responsive.w1}>
          <ColdDataAutoCompleteFormItem
            api="school-track-types"
            propertyName="schoolTrackType"
          />
        </Box>

        <Box {...responsive.w1}>
          {!!schoolTrackType
            && (
              <ColdDataAutoCompleteFormItem
                api="school-tracks"
                propertyName="schoolTrack"
                dependenciesValues={[schoolTrackType]}
                dependencies={['schoolTrackType']}
                filter={(s: ISchoolTrack) => {
                  return schoolTrackType.id === s.type?.id;
                }}
              />
            )}
        </Box>

        <Box {...responsive.w1}>
          {!!schoolTrack
            && (
              <SchoolAutoCompleteFormItem
                rules={[{ required: true }]}
                required
                // disabled={false}
                propertyName="school"
                dependenciesValues={[schoolTrack]}
                dependencies={['schoolTrack']}
                schoolTrack={schoolTrack}
              />
            )}
        </Box>
        <IsDesktop>
          <Affix offsetBottom={0}>
            <Important>
              <HorizontalFormItem>
                <CheckboxFormItem
                  propertyName="rgpd"
                  labelCol={{ span: 20 }}
                  wrapperCol={{ span: 2 }}
                  required
                  rules={[{ required: true }]}
                />
              </HorizontalFormItem>
              <br />
              <ButtonsFormItemLayout>
                <SubmitButton loading={props.loading} />
              </ButtonsFormItemLayout>
            </Important>
          </Affix>
        </IsDesktop>
        <IsMobile>
          <Important>
            <HorizontalFormItem>
              <IsDesktop>
                <CheckboxFormItem
                  propertyName="rgpd"
                  labelCol={{ span: 20 }}
                  wrapperCol={{ span: 2 }}
                  required
                  rules={[{ required: true }]}
                />
              </IsDesktop>
              <IsMobile>
                <SwitchFormItem
                  size="default"
                  propertyName="rgpd"
                  labelCol={{ span: 20 }}
                  wrapperCol={{ span: 2 }}
                  required
                  rules={[{ required: true }]}
                />
              </IsMobile>
            </HorizontalFormItem>
            <br />
            <ButtonsFormItemLayout>
              <SubmitButton loading={props.loading} />
            </ButtonsFormItemLayout>
          </Important>
        </IsMobile>
      </FormContentPanelContainer>
    </DisabledContextProvider>
  );
}
