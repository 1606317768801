import { ClearOutlined } from '@ant-design/icons';
/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import {
    ICandidateFile,
    JuryReviewStatusEnum,
    SUPER_SECRETARY_RIGHTS,
    walkEntityPropertyAndMapDateStrAsMoment,
} from '@fstn/ecandidaturev2_api-interfaces';
import Menu from '@inovua/reactdatagrid-community/packages/Menu';
import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';

import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useDeepCompareCallback } from 'use-deep-compare';
import { IColumn } from '@inovua/reactdatagrid-community/types';
import { debounce, uniqBy, cloneDeep } from 'lodash';
import { useQueryParams } from 'hookrouter';
import * as Locale from '../../../common/locale';
import { UserConfigContext } from '../../../context/user.config.context';
import { UserContext } from '../../../context/user.context';
import { useEntity } from '../../../hooks/use-entity';
import { useLoading } from '../../../hooks/use-loading';
import { CandidateRow, filterTypes } from './CandidateFilesList.type';
import { CandidateFilesListHeader } from './CandidateFilesListHeader';
import { encodeFilters, encodeSort } from './utils';
import _ from 'lodash';
import { Checkbox } from 'antd';

const Style = styled.div`
  height: calc(100% - 40px);
  width: calc(100% - 0px);
  margin: auto;
  position: relative;

  .ant-select {
    width: 100%;
  }
  
  .particular-case .InovuaReactDataGrid__cell__content{
    background-color: #ccf4da !important;
  }
  
  .ignore-it .InovuaReactDataGrid__cell__content{
    background-color: #f4dacc !important;
  }
  
  .yellow .InovuaReactDataGrid__cell__content{
    background-color: #ffff7f !important;
    color: black !important;
  }

  .desactivated .InovuaReactDataGrid__cell__content{
    opacity: 0.5;
  }

`;

const styleCheck = {
    renderCheckbox: (checkboxProps) => {
        const { onChange, checked } = checkboxProps

        return (
            <div
                css={css`
                .ant-checkbox-inner{
                    width: 24px;
                    height: 30px;

                    &::after{
                        top: 11px;
                        left: 5px;
                        width: 7px;
                        height: 17px;
                    }
                }
            `}
            >
                <Checkbox
                    checked={checked}
                    onClick={e => {
                        e.stopPropagation();
                        onChange(!checked);
                    }} />
            </div>
        )
    }
} as IColumn;

export function getJuryReviewUpdatedAt(cf: any) {
    return (cf.juryReviews?.[0]?.status !== undefined && cf.juryReviews?.[0]?.status !== JuryReviewStatusEnum.UNDONE) ? cf.juryReviews?.[0]?.updatedAt : '-';
}

function generateFetchHack() {
    return Math.round(Math.random() * 10000);
}

export function getSearchHackToForceReFetch() {
    return { fetch: generateFetchHack() };
}

export function CandidateFilesList({
    initialColumns, initialFilters, renderRowContextMenu, gridStyle, renderListHeader, visible,
}: any) {
    const [gridRef, setGridRef] = useState(null);
    const { userCtx, canSeeValidation, isExaminer } = useContext(UserContext);
    const userConfigCtx = useContext(UserConfigContext);
    const { doAction, loading } = useLoading();
    let savedHiddenColumns = userConfigCtx.getTableConfig('candidateFiles', 'hiddenColumns') || [];
    let savedVisibleColumns = userConfigCtx.getTableConfig('candidateFiles', 'visibleColumns') || [];
    initialColumns = uniqBy(cloneDeep(initialColumns), (c: IColumn) => c.id);
    savedHiddenColumns.forEach((c: any) => {
        const uic = initialColumns.find((ic) => ic?.name === c);
        if (uic) {
            uic.visible = false;
        }
    });
    savedVisibleColumns.forEach((c: any) => {
        const uic = initialColumns.find((ic) => ic?.name === c);
        if (uic) {
            uic.visible = true;
        }
    });
    if (initialColumns.filter((c) => c.visible).length === 0) {
        initialColumns[0].visible = true;
    }
    initialColumns.forEach((c: any) => {
        c.filterDelay = 0;
    });

    const initialColumnsOrder = initialColumns.map((c) => c.name) || [];
    const savedFilters = userConfigCtx.getTableConfig('candidateFiles', 'filters') || [];

    initialFilters = savedFilters.length > 0 ? savedFilters : initialFilters;
    const { loadEntities } = useEntity<ICandidateFile>();

    const [columns, setColumns] = useState(initialColumns);
    const [filters, setFilters] = useState(initialFilters);
    const [selectedCandidateFiles, setSelectedCandidateFiles] = useState([]);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [, setFilteredCount] = useState(0);
    const [sorter] = useState({ dir: -1, name: 'shortId' } as any);
    const [columnOrder] = useState(initialColumnsOrder || []);

    const [parsed] = useQueryParams();
    const fetch = parsed.fetch ? parseInt(parsed.fetch as string, 10) : 0;
    const [hackReFetchData, setHackReFetchData] = useState(fetch);
    const [stats, setStats] = useState([]);
    const [fetchedAt, setFetchedAt] = useState(moment());
    useEffect(() => {
        if (fetch !== hackReFetchData) {
            setHackReFetchData(fetch);
        }
    }, [fetch, hackReFetchData]);

    const iSaveColumns = async (_savedVisibleColumns, _savedHiddenColumns, _columns) => {
        await userConfigCtx.updateTableConfig('candidateFiles', 'hiddenColumns', _savedHiddenColumns);
        await userConfigCtx.updateTableConfig('candidateFiles', 'visibleColumns', _savedVisibleColumns);
        setColumns(_columns);
        setTimeout(() => window.location.reload(), 500);
    };
    const saveColumns = debounce(iSaveColumns, 2000);
    // noinspection DuplicatedCode
    const onSelectionChange = (elt: TypeOnSelectionChangeArg) => {
        /* elt (envoyé par la composant datagrid) est composé de  :
            selected qui contient :
                - "true" si on a coché la case "select all"
                - la liste des éléments sélectionnés si on les a choisi un par un
            data qui contient : les données de la ligne sur laquelle le clic (droit ou gauche) a été fait ou tableau vide si on désélectionne tout
                                (case globale) ou la tableau de tous les éléments si on sélectionne tout (case globale)
            unselected qui contient : la liste de tous les éléments désélectionnés (uniquement quand "selected" est à "true"), vide sinon
        */
        if (elt.unselected) {
            // Il y a des éléments désélectionnés donc il faut prendre le tableau complet et enlever tous les désélectionnés
            // à partir du shortId qui sert de clé dans "unselected"
            setSelectedCandidateFiles(uniqBy(data.filter((r) => !Object.keys(elt.unselected).includes(r.shortId)), 'id'));
        } else if (elt.selected === true) {
            // On a cliqué sur "tout sélectionner" et comme il n'y a pas de "unselected", on prend tout le tableau
            setSelectedCandidateFiles(uniqBy(data, 'id') as any);
        } else if (Array.isArray(elt.data) && elt.data.length === 0) {
            // Cas ou on clique sur "tout désélectionner", on vide la tableau des sélectionnés
            setSelectedCandidateFiles([]);
        } else {
            // Cas où on a sélectionné un ou plusieurs éléments dans la grid : on prend ces éléments sélectionnés pour les mettre dans la liste des séléectionnés
            setSelectedCandidateFiles(uniqBy(Object.values(elt.selected as any), 'id'));
        }
    };

    // noinspection JSUnusedLocalSymbols
    /**
     * Fetch data
     * @param skip is ignored, not used
     * @param limit is ignored, not used
     * @param sortInfo is ignored, did localy
     * @param groupBy is ignored, not used
     * @param filterValue
     */
    const fetchData = async ({
        skip, limit, sortInfo, groupBy, filterValue,
    }) => doAction(async () => {
        if (gridRef) {
            gridRef.current?.deselectAll();
        }
        let { qb } = encodeFilters(JSON.parse(JSON.stringify(filterValue)), RequestQueryBuilder.create(), userCtx.user, sortInfo);
        // Pour tous les roles excepte le SSD, on ajoute un filtre systematique pour enlever les dossiers desactivés
        // Sinon, les résultats affichés (total filtrés, grid, ...) ne sont pas cohérents car les nombres correspondent au total (y compris les désactivés) alors qu'un filtre
        // est applique ensuite pour ne pas afficher les inactifs
        if (!(userCtx.user?.rightsOverriding && _.isEqual(userCtx.user?.rightsOverriding, SUPER_SECRETARY_RIGHTS))) {
            qb.setFilter({ field: 'disabled', operator: CondOperator.EQUALS, value: false });
        }
        qb = qb.setLimit(limit);
        qb = qb.setPage(skip / limit + 1);
        if (sortInfo) {
            qb = encodeSort(sortInfo, qb, userCtx.user);
        }
        let q = qb.query();
        q = q.startsWith('?') ? q : `?${q}`;
        const candidateFiltesRows = await loadEntities(
            `candidate-file-row/${userCtx.user.role?.name.toLowerCase().replace('_', '-')}/${q || ''}`, 0,
        );
        let { data: newData } = candidateFiltesRows;
        let { total: newCount } = candidateFiltesRows;
        const { stats: newStats } = candidateFiltesRows;

        newData = newData?.map?.((cf: any) => ({
            ...cf,
            juryReviewLastPublishedStatus: cf.juryReviews?.map((r: any) => r.lastPublishedStatus)?.[0] || JuryReviewStatusEnum.UNDONE,
            juryReviewStatus: cf.juryReviews?.map((r) => r.status)?.[0] || JuryReviewStatusEnum.UNDONE,
            // data filter by backend, see MaskFirldsForRoleInterceptor
            juryReviewStatusUpdatedAt: cf.juryReviews?.[0]?.statusUpdatedAt,
            specializedSecretaryReviewComment: cf.specializedSecretaryReviews?.[0]?.comments,
            specializedSecretaryReview: cf.specializedSecretaryReviews?.[0],
            juryReviewPublicatedAt: cf.juryReviews?.[0]?.publicatedAt,
            examinerReview: cf.examinerReviews?.[0],
            programs: cf.programLines?.map((pl) => pl.program),
            /* Use only for string filter */
            examinerReviewReview: cf.examinerReviews?.[0]?.review,
            examinerReviewNotation: cf.examinerReviews?.[0]?.notation,
        }));
        newData?.forEach?.((d) => walkEntityPropertyAndMapDateStrAsMoment(d));
        const lockedByMe = newData.filter?.((d: CandidateRow) => d.secretaryLockedBy?.id === userCtx.user.id);
        if (lockedByMe.length > 0) {
            newCount = lockedByMe.length;
            newData = lockedByMe;
        }
        setData(newData);
        setFilteredCount(newData.length);
        setStats(newStats);
        setFetchedAt(moment());
        setCount(newCount);
        return { data: newData, count: newCount };
    });

    const dataSource = useDeepCompareCallback(fetchData, [visible, hackReFetchData, fetch, gridRef]);
    return (
        <Style>
            <div css={css`
    .ant-statistic {
        padding: 0.2em !important;
        width: 120px !important;
        align-items: center;
        align-content: center;
        gap: 1em;
        .ant-statistic-content{
            line-height: normal; 
        }
    }
`}
            >
                {renderListHeader && renderListHeader({
                    initialColumns, initialFilters, renderRowContextMenu, gridStyle, renderListHeader, visible,
                })}
                {!renderListHeader && (
                    <CandidateFilesListHeader
                        selectedCount={selectedCandidateFiles.length}
                        filteredCount={count}
                        filters={filters}
                        stats={stats}
                        reFetchData={() => {
                            setHackReFetchData(generateFetchHack());
                        }}
                        fetchedAt={fetchedAt}
                    />
                )}
            </div>

            <ReactDataGrid
                licenseKey="AppName=IMTMinesAlesApp,Company=IMTMinesAles,ExpiryDate=2022-09-21,LicenseDeveloperCount=1,LicenseType=single_app,Ref=IMTMinesAlesLicenseRef,Z=-1389124616-117191260076701888-20886508571221706338-2117749941"
                dataSource={dataSource}
                pagination="remote"
                headerHeight={80}
                remoteSort
                defaultLimit={15}
                defaultSkip={0}
                checkboxColumn={styleCheck}
                loading={loading}
                i18n={i18n}
                css={css`.InovuaReactDataGrid__row-cell-wrap:hover{cursor: pointer}`}
                idProperty="shortId"
                style={gridStyle}
                filterable
                onReady={setGridRef}
                defaultSortInfo={sorter}
                enableKeyboardNavigation={false}
                showCellBorders
                checkboxSelectEnableShiftKey
                showHoverRows={false}
                /* @ts-ignore */
                pageSizes={[15, 50, 100, 500, 10000]}
                showColumnMenuGroupOptions={false}
                showColumnMenuFilterOptions={false}
                rowClassName={({ data: d, props }) => {
                    if (d.disabled) {
                        return 'desactivated';
                    }
                    if ((canSeeValidation() || isExaminer()) && (d.lastUpdatedAt as any)?.isAfter(userCtx.user.backupConnectedAt)) {
                        return 'yellow';
                    }
                    if ((canSeeValidation() || isExaminer()) && d.secretaryLockedBy) {
                        return 'yellow';
                    }
                    if ((canSeeValidation() || isExaminer()) && d.ignoreIt) {
                        return 'ignore-it';
                    }
                    if ((canSeeValidation() || isExaminer()) && d.particularCase) {
                        return 'particular-case';
                    }
                    return '';
                }}
                renderRow={(rowProps: {
                    id?: string | number;
                    data?: CandidateRow;
                    rowIndex: number;
                    rowSelected: boolean;
                    active: boolean;
                    style: CSSStyleDeclaration;
                }) => {
                    rowProps['data-testid'] = rowProps.data.email;
                    if (rowProps.data.disabled) {
                        rowProps.style.opacity = '0.5';
                    }
                    if ((canSeeValidation() || isExaminer()) && rowProps.data.particularCase) {
                        rowProps.style.backgroundColor = '#ccf4da';
                    }
                    if ((canSeeValidation() || isExaminer()) && rowProps.data.ignoreIt) {
                        rowProps.style.backgroundColor = '#f4dacc';
                    }
                    if ((canSeeValidation() || isExaminer()) && (rowProps.data.lastUpdatedAt as any)?.isAfter(userCtx.user.backupConnectedAt)) {
                        rowProps.style.backgroundColor = '#ffff7f';
                        rowProps.style.color = 'black';
                    }
                    if ((canSeeValidation() || isExaminer()) && rowProps.data.secretaryLockedBy) {
                        rowProps.style.backgroundColor = '#ffff7f';
                        rowProps.style.color = 'black';
                    }
                    return undefined;
                }}
                columns={columns}
                defaultColumnOrder={columnOrder as any}
                filterTypes={filterTypes as any}
                groupColumn
                theme="blue-light"
                defaultFilterValue={filters}
                onFilterValueChange={setFilters}
                onColumnVisibleChange={async ({ column, visible }) => {
                    if (visible === false) {
                        columns.filter((c) => c.name === column.name).forEach((c) => {
                            c.visible = false;
                        });
                    } else if (visible === true) {
                        columns.filter((c) => c.name === column.name).forEach((c) => {
                            c.visible = true;
                        });
                    }
                    savedVisibleColumns = columns.filter((c) => c.visible === true).map((c) => c.id);
                    savedHiddenColumns = columns.filter((c) => c.visible === false).map((c) => c.id);
                    setColumns(columns);

                    saveColumns(savedVisibleColumns, savedHiddenColumns, columns);
                }}
                loadingText={<Locale.Content tkey="table.loading" />}
                emptyText={<Locale.Content tkey="table.empty" />}
                renderColumnContextMenu={(menuProps, { cellProps }) => (
                    <Menu
                        {...menuProps}
                        items={[...menuProps.items, {
                            label: <Locale.Button tkey="candidateFile.config.clear" />,
                            icon: <ClearOutlined />,
                            onClick: async () => {
                                await userConfigCtx.resetTableConfig('candidateFiles');
                                setTimeout(() => window.location.reload(), 500);
                            },
                        }]}
                    />
                )}
                enableSelection
                checkboxOnlyRowSelect={false}
                renderRowContextMenu={(menuProps, details) => renderRowContextMenu(menuProps, details, selectedCandidateFiles, gridRef)}
                showColumnMenuLockOptions
                onSelectionChange={onSelectionChange}
                virtualized
                // @ts-ignore
                onColumnOrderChange={(_columns) => {
                    userConfigCtx.updateTableConfig('candidateFiles', 'columnsOrder', _columns);
                }}
                // @ts-ignore
                onRowContextMenu={(rowProps: any) => {
                    if (!gridRef || !gridRef.current) {
                        return;
                    }
                    if (selectedCandidateFiles.includes(rowProps.data)) {
                        return;
                    }
                    if (selectedCandidateFiles.length > 1) {
                        // La ligne ci-dessous, je ne sais pas a quoi elle sert, et elle pose parfois un probleme quand on selectionne
                        // tout car elle rajoute l'element clique a la liste de tous les elements (donc plus d'elements selectionnes que d'elements au total !)
                        //setSelectedCandidateFiles([...selectedCandidateFiles, rowProps.data]);
                        gridRef.current.setRowSelected(rowProps.rowIndex, true);
                    } else {
                        setSelectedCandidateFiles([rowProps.data]);
                        gridRef.current.deselectAll();
                        setTimeout(() => gridRef.current.setRowSelected(rowProps.rowIndex, true), 1000);
                    }
                }}
            />
        </Style>
    );
}

// @ts-ignore
window.moment = moment;

/**
 * Return selected candidate files
 * @param data
 * @param selectedCandidateFiles
 */
export function getSelectedCandidateFiles(data: CandidateRow, selectedCandidateFiles: { id: string }[]) {
    if (selectedCandidateFiles && selectedCandidateFiles.length > 0) {
        return selectedCandidateFiles;
    }
    return [data];
}

/**
 * Override component translation
 */
const i18n = {
    ...ReactDataGrid.defaultProps.i18n, // pagination toolbar
    pageText: <Locale.Label unTrim tkey="table.pageText" />,
    ofText: <Locale.Label unTrim tkey="table.ofText" />,
    perPageText: <Locale.Label unTrim tkey="table.perPageText" />,
    showingText: <Locale.Label unTrim tkey="table.showingText" />,

    clearAll: <Locale.Label tkey="table.clearAll" />,
    clear: <Locale.Label tkey="table.clear" />,
    showFilteringRow: <Locale.Label tkey="table.showFilteringRow" />,
    hideFilteringRow: <Locale.Label tkey="table.hideFilteringRow" />,

    enable: <Locale.Label tkey="table.enable" />,
    disable: <Locale.Label tkey="table.disable" />,

    sortAsc: <Locale.Label tkey="table.sortAsc" />,
    sortDesc: <Locale.Label tkey="table.sortDesc" />,
    unsort: <Locale.Label tkey="table.unsort" />,
    group: <Locale.Label tkey="table.group" />,
    ungroup: <Locale.Label tkey="table.ungroup" />,
    lockStart: <Locale.Label tkey="table.lockStart" />,
    lockEnd: <Locale.Label tkey="table.lockEnd" />,
    unlock: <Locale.Label tkey="table.unlock" />,
    columns: <Locale.Label tkey="table.columns" />,
    // operators,
    contains: <Locale.Label tkey="table.contains" />,
    startsWith: <Locale.Label tkey="table.startsWith" />,
    endsWith: <Locale.Label tkey="table.endsWith" />,
    notContains: <Locale.Label tkey="table.notContains" />,
    inlist: <Locale.Label tkey="table.inlist" />,
    notinlist: <Locale.Label tkey="table.notinlist" />,
    neq: <Locale.Label tkey="table.neq" />,
    inrange: <Locale.Label tkey="table.inrange" />,
    notinrange: <Locale.Label tkey="table.notinrange" />,
    eq: <Locale.Label tkey="table.eq" />,
    notEmpty: <Locale.Label tkey="table.notEmpty" />,
    empty: <Locale.Label tkey="table.empty" />,
    lt: <Locale.Label tkey="table.lt" />,
    lte: <Locale.Label tkey="table.lte" />,
    gt: <Locale.Label tkey="table.gt" />,
    gte: <Locale.Label tkey="table.gte" />,
    before: <Locale.Label tkey="table.before" />,
    beforeOrOn: <Locale.Label tkey="table.beforeOrOn" />,
    afterOrOn: <Locale.Label tkey="table.afterOrOn" />,
    after: <Locale.Label tkey="table.after" />,
    start: <Locale.Label tkey="table.start" />,
    end: <Locale.Label tkey="table.end" />,
    isParticular: <Locale.Label tkey="table.isParticular" />,
    isIgnored: <Locale.Label tkey="table.isIgnored" />,
    dragHeaderToGroup: <Locale.Label tkey="table.dragHeaderToGroup" />,
    noRecords: <Locale.Label tkey="table.noRecords" />,
};
