/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    IUser,
    IVersionedBaseStringId,
    IVersionedBaseWithSecretaryReview,
    UserRoleEnum,
} from '@fstn/ecandidaturev2_api-interfaces';
import { notification } from 'antd';
import * as moment from 'moment';
import { useContext } from 'react';
import { DeepPartial } from 'redux';
import { Listeners } from '../common/form/entityForm/EntityFormContainer';
import { FormListFieldData } from '../common/form/type';
import { notificationTrad } from '../common/locale';
import { AxiosContext, AxiosContextType } from '../context/axios.context';
import {
    CandidateFileContext,
    CandidateFileContextProviderType,
} from '../pages/canSeeCandidateFile/CandidateFileContext';
import { useSafeTranslation } from './use-safe-translation';
import { UserContext, UserContextType } from '../context/user.context';

export function normFile(e) {
    if (Array.isArray(e)) {
        return e;
    }
    return e && (e.fileList || []);
}

export function useValidation() {
    const { userCtx } = useContext<UserContextType>(UserContext);
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const { t } = useSafeTranslation();
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);

    /**
     * Return formatted validation from validationResponseData
     * @param validationResponseData
     */
    function extractEntityValidations(validationResponseData) {
        const validations: any = {};
        for (const validation of validationResponseData?.filter?.((_validation: any) => !!_validation.constraints) || []) {
            validations[validation.property] = validation.constraints;
        }
        return validations;
    }

    function getEntityValidationsForStep(validationsCtx, step, entity) {
        return (validationsCtx.validationsCtxState as any)[step]?.validations?.[entity];
    }

    function isEntityValidForStep(validationsCtx, step, entity) {
        return Object.keys(getEntityValidationsForStep(validationsCtx, step, entity) || {})?.length === 0;
    }

    async function validate<T extends DeepPartial<IUser> | DeepPartial<IVersionedBaseStringId> | DeepPartial<IVersionedBaseWithSecretaryReview>>(
        entity: string,
        newValues: FormListFieldData[] | any,
        withUpdateValidations: any,
        steps: string[],
    listeners?: Partial<Listeners<T>>,
    ) {
        moment.fn.toJSON = function () {
            return this.format();
        };
        const params: any = {};
        if (candidateFileContext) {
            params.candidateFileId = candidateFileContext.selectedCandidateFile?.id;
        }

        const promises = [];
        for (const step of steps || []) {
            promises.push(withUpdateValidations(step, entity, async () => {
                const res = await axios.post(`validation/${entity}/${step}`, newValues, { params });
                if (!res?.data) {
                    return undefined;
                }
                const validation = Object.entries(res?.data).reduce((o, [k, v]) => {
                    const entityValidations = extractEntityValidations(v);
                    return { ...o, [k]: entityValidations };
                }, {});
                // show notifications to confirm validation, entity must be different than candidateFile
                if (entity !== 'candidate-file'
                    && Object.values(validation[entity] || {})?.length === 0 && step === 'SUBMIT'
                    && userCtx.user?.role?.name === UserRoleEnum.CANDIDATE) {
                    notification.success({ message: notificationTrad(`${entity}.validated`, t) });
                }
                return validation;
            }));
        }
        await Promise.all(promises);

        if (listeners?.onAfterValidate) {
            listeners?.onAfterValidate(newValues as FormListFieldData[]);
        }
    }

    return {
        extractEntityValidations,
        validate,
        getEntityValidationsForStep,
        isEntityValidForStep,
        // loadRules
    };
}
