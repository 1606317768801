/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {useSafeTranslation} from '../../../../hooks/use-safe-translation';
import {AdminEditableList} from '../../common/AdminEditableList';
import {
    programsListInitialColumns,
    programsListInitialFilters,
    programsListMapToRow,
    programsListRenderRowContextMenu,
} from './ProgramListInitialConfig';

export function ProgramListPage() {
  const { t } = useSafeTranslation();
  return (
    <Fragment>
      <AdminEditableList
        entity="admin/program"
        checkboxColumn
        renderRowContextMenu={programsListRenderRowContextMenu}
        initialFilters={programsListInitialFilters}
        initialColumns={programsListInitialColumns(t)}
        gridStyle={{ height: 'calc( 100% - 220px)' }}
        mapToRow={programsListMapToRow}
      />
    </Fragment>
  );
}
