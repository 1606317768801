/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Anchor, Menu } from 'antd';
import Tooltip from 'antd/lib/tooltip';
// @ts-ignore
import { usePath } from 'hookrouter';
import _ from 'lodash';
import React, { HTMLAttributes } from 'react';
import * as Locale from '../../common/locale';
import { EntityIcon } from '../../assets/icons/left-menu.icons';

const { Link } = Anchor;

// @ts-ignore
// language=LESS prefix=*{ suffix=}
const Style: any = styled.div`
    height: 100%;
    grid-area: left;

    .ant-menu > div{
      height: 100%;
    }
    .ant-menu > div> div, .ant-menu > div> div .ant-anchor{
      height: 100%;
    }
    .ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 10px;
    }

`;

export type LeftMenuProps = HTMLAttributes<HTMLElement>;

export const SpecializedSecretaryLeftMenu: React.FunctionComponent<LeftMenuProps> = () => {
    const path = usePath();

    return (
      <Style>
        <Menu
          /* @ts-ignore */
          siderCollapsed
          style={{ width: 'auto', paddingLeft: '5px', height: '100%' }}
          selectedKeys={[(`${_.last(path.split('/'))}`).replace('/', '')]}
          defaultOpenKeys={['candidate-file']}
          mode="inline"
        >
          <Anchor onClick={(e) => e.stopPropagation()} style={{ height: '100%' }}>
            <AnchorWithTooltip entityName="programs-list" />
            <AnchorWithTooltip entityName="profile" />
            <AnchorWithTooltip entityName="bachelor" />
            <AnchorWithTooltip entityName="academics-list" />
            <AnchorWithTooltip entityName="languages-list" />
            <AnchorWithTooltip entityName="stays-abroad-list" />
            <AnchorWithTooltip entityName="company-experiences-list" />
            <AnchorWithTooltip entityName="motivation" />
            <AnchorWithTooltip entityName="apprenticeship-company" />
            <AnchorWithTooltip entityName="additional-documents-list" />
            <AnchorWithTooltip entityName="examiner-review" />
          </Anchor>
        </Menu>
      </Style>
    );
};

export function AnchorWithTooltip(props: { entityName: string }) {
    return (
      <Tooltip placement="right" title={<Locale.Label tkey={`${props.entityName}.menu`} />}>
        <div>
          <Link href={`#${props.entityName}`} title={<EntityIcon entity={props.entityName} />} />
        </div>
      </Tooltip>
    );
}
