/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { ValidationStep } from '@fstn/ecandidaturev2_api-interfaces';
import { AcademicsListForm } from '../../candidateFile/academicsList/AcademicsListForm';
import { AdditionalDocumentsListForm } from '../../candidateFile/additionalDocumentsList/AdditionalDocumentsListForm';
import { ApprenticeshipCompanyForm } from '../../candidateFile/apprenticeshipCompany/ApprenticeshipCompanyForm';
import { BachelorForm } from '../../candidateFile/bachelor';
import { WithInitialLoad } from '../../../common/withExtraParameters/WithInitialLoad';
import { CompanyExperiencesListForm } from '../../candidateFile/companyExperiencesList/CompanyExperiencesListForm';
import { LanguagesListForm } from '../../candidateFile/languagesList/LanguagesListForm';
import { MotivationForm } from '../../candidateFile/motivation/MotivationForm';
import { ProfileForm } from '../../candidateFile/profile';
import { ProgramsListForm } from '../../candidateFile/programsList/ProgramsListForm';
import { StaysAbroadListForm } from '../../candidateFile/staysAbroadList/StaysAbroadListForm';
import { CandidateFileContextProvider } from '../../canSeeCandidateFile/CandidateFileContext';
import { ExaminerReviewPreview } from '../ExaminerReviewPreview';
import { SpecializedSecretaryPage } from '../SpecializedSecretaryPage';
import { CandidateCard } from '../../canSeeCandidateFile/CandidateCard';
import { ValidationsContextProvider } from '../../../context/validations.context';

function FullCandidateFileForm() {
    return (
      <div css={css`position:relative; height: 100%;`}>
        <div css={css``}>
          <CandidateCard />
        </div>
        <div css={css`max-height: calc( 100% - 80px); overflow: scroll`}>
          <ValidationsContextProvider steps={[ValidationStep.SUBMIT]} entity="candidate-file">
            <ProgramsListForm layout="horizontal" />
            <ProfileForm layout="horizontal" />
            <BachelorForm layout="horizontal" />
            <AcademicsListForm layout="horizontal" />
            <LanguagesListForm layout="horizontal" />
            <StaysAbroadListForm layout="horizontal" />
            <CompanyExperiencesListForm layout="horizontal" />
            <MotivationForm layout="horizontal" />
            <ApprenticeshipCompanyForm layout="horizontal" />
            <AdditionalDocumentsListForm layout="horizontal" />
            <ExaminerReviewPreview layout="horizontal" />
          </ValidationsContextProvider>
        </div>
      </div>
    );
}

/**
 * Routes starting with /home/welcome/secretary/
 * @constructor
 */
export function SpecializedSecretaryChild1RoutesDef(): any {
    return {
        '/:candidateFileId/*': ({ candidateFileId }: any) => (
          <WithInitialLoad
            entity="candidate-file"
            entityId={candidateFileId}
            propsName="selectedCandidateFile"
          >
            <CandidateFileContextProvider>
              <SpecializedSecretaryPage>
                <FullCandidateFileForm />
              </SpecializedSecretaryPage>
            </CandidateFileContextProvider>
          </WithInitialLoad>
        ),
        '/:candidateFileId': ({ candidateFileId }: any) => (
          <WithInitialLoad
            entity="candidate-file"
            entityId={candidateFileId}
            propsName="selectedCandidateFile"
          >
            <CandidateFileContextProvider>
              <SpecializedSecretaryPage>
                <FullCandidateFileForm />
              </SpecializedSecretaryPage>
            </CandidateFileContextProvider>
          </WithInitialLoad>
        ),
    };
}
