/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx, css } from '@emotion/core';
import { TextButton } from './TextButton';

/**
 * Simple Cancel Button
 * @param tkey
 * @param props
 * @constructor
 */
export function TextCancelButton({ tkey, ...props }:any) {
    return <TextButton {...props} tkey={tkey} danger ghost type="primary" css={css`background-color: white !important;`} />;
}
