/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment, useContext} from 'react';
import {Importer, ImporterField} from 'react-csv-importer-v2';

/**
 * LAURENT
 * DOCUMENTATION: npm i react-csv-importer-v2
 */
// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer-v2/dist/index.css';
import {useImmer} from 'use-immer';
import {AxiosContext, AxiosContextType} from '../../../../context/axios.context';
import {sNavigateReload} from '../../../../utils/safeNavigation';
import {ADMIN} from '../../../user/UserRoutesDef';
import {SchoolListPage} from './SchoolListPage';
import {ResultPanel} from '../../common/ResultPanel';
import {ButtonDeleteAll} from '../../common/ButtonDeleteAll';

export function SchoolImporter() {
  const [state, updateState] = useImmer({ values: [], error: undefined as string, result: undefined as string });
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  return (
    <Fragment>
      <Importer
        delimiter=";"
        // restartable // optional, lets user choose to upload another file when import is complete
        onStart={() => {
        }}
        processChunk={async (rows) => {
          updateState((draft) => {
            draft.values = [...draft.values, ...rows];
          });
        }}
        onComplete={async () => {
          try {
            const response: any = await axios?.post('/import/school', {
              bulk: state.values,
            });
            updateState((draft) => {
              draft.result = `${response.data.created} établissements importés et ${response.data.updated} rattachés à de nouvelles fillières`;
              draft.error = undefined;
            });
          } catch (ex) {
            updateState((draft) => {
              draft.error = ex.response.data.message;
              draft.result = undefined;
            });
          }
        }}
        onClose={async () => {
          sNavigateReload(ADMIN.SCHOOL_IMPORTER);
        }}
      >
        <ImporterField name="school_track" label="school_track" />
        <ImporterField name="school_name" label="school_name" />
        <ImporterField name="city" label="city" />
        <ImporterField name="zip_code" label="zip_code" />
      </Importer>
      <br />
      <ButtonDeleteAll message="établissements supprimés sur" url="/api/admin/school/deleteAll" updateState={updateState} />
      <ResultPanel error={state.error} result={state.result} redirect={ADMIN.SCHOOL_IMPORTER} />
      <br />
      <span><h1>Liste des établissements</h1></span>
      <SchoolListPage />
    </Fragment>
  );
}
