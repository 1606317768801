import { IRights, UserRoleEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { UserContextType } from '../context/user.context';
import { canDelete, canWrite } from './rightsManager';

export const getDisabledForWrite = (userCtx, entity: string, rights: any) => {
  const userRole = userCtx.user?.role?.name;
  if (!userRole) {
    return true;
  }

  if (!rights) {
    return false;
  }

  const isRightsOverridingForWrite = userCtx.user?.rightsOverriding?.write?.indexOf(entity?.replace('-', '_'));
  if (userRole === UserRoleEnum.SECRETARY && isRightsOverridingForWrite && isRightsOverridingForWrite !== -1) {
    return false;
  }

  // TODO checkOverridingRights miss table name
  return !canWrite(rights, userRole);
};

export const getDisabledForDelete = (userCtx, entity: string, rights: any) => {
  const userRole = userCtx?.user?.role?.name;
  if (!userRole) {
    return true;
  }

  const isRightsOverridingForWrite = userCtx.user?.rightsOverriding?.delete?.indexOf(entity?.replace('-', '_'));
  if (userRole === UserRoleEnum.SECRETARY && isRightsOverridingForWrite && isRightsOverridingForWrite !== -1) {
    return false;
  }

  return !canDelete(rights, userRole);
};

export function getDisabledForWriteAllContexts(userCtx: UserContextType, formContextEntity: string, formContextRights: IRights,
                                               formListContextEntity: string, formListContextRights:IRights) {
 const disForFormContext = getDisabledForWrite(userCtx.userCtx, formContextEntity, formContextRights);
  const disForFormListContext = getDisabledForWrite(userCtx.userCtx, formListContextEntity, formListContextRights);
  return disForFormContext || disForFormListContext;
}

export function getDisabledForDeleteAllContexts(userCtx: UserContextType, formContextEntity: any, formContextRights: IRights,
                                                formListContextEntity: string, formListContextRights:IRights) {
  const res = getDisabledForDelete(userCtx.userCtx, formContextEntity, formContextRights)
      || getDisabledForDelete(userCtx.userCtx, formListContextEntity, formListContextRights);
  return res;
}
