import { InputProps } from 'antd/lib/input';
import React from 'react';
import { Debounced } from '../../withExtraParameters/Debounced';
import { ResponsiveInput } from '../../input/ResponsiveInput';
import { copyEntityFormProps, EntityFormItemContainer, ItemProps } from '../entityForm/EntityFormItemContainer';
/**
 * LAURENT
 * EntityFormItemContainer for text input
 * @param props
 * @constructor
 */
export function InputFormItem(props: ItemProps & Omit<InputProps, 'disabled'> & { rendered?: Boolean | boolean }) {
  return (
    <EntityFormItemContainer
      {...copyEntityFormProps(props)}
    >
      {props.rendered !== false && (
        <Debounced>
          <ResponsiveInput
            data-testid={`${props.propertyName}`?.replace(/,/g, '_')}
            allowClear={props.allowClear}
            size="large"
            autoComplete={props.autoComplete}
            maxLength={props.maxLength}
          />
        </Debounced>
      )}
    </EntityFormItemContainer>
  );
}
