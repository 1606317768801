/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {AdminEditableList} from '../../common/AdminEditableList';
import {
    referentListInitialColumns,
    referentListInitialFilters,
    referentListMapToRow,
    referentListRenderRowContextMenu,
} from './ReferentListInitialConfig';

export function ReferentListPage(props) {
  return (
    <Fragment>
      <AdminEditableList
        entity="admin/academic-referent"
        checkboxColumn
        renderRowContextMenu={referentListRenderRowContextMenu}
        initialFilters={referentListInitialFilters}
        initialColumns={referentListInitialColumns()}
        mapToRow={referentListMapToRow}
      />
    </Fragment>
  );
}
