import { Card } from 'antd';
import React, { Fragment } from 'react';
// @ts-ignore
import useMobileDetect from 'use-mobile-detect-hook';
import styled from '@emotion/styled';
import { UserConfigContextProvider } from '../../../context/user.config.context';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { CandidateFileListContextProvider } from '../../canSeeCandidateFile/CandidateFileListContext';
import { CandidateFilesList } from '../../canSeeCandidateFile/candidateFilesList/CandidateFilesList';
import { CandidateFilesListInitialColumns, CandidateFilesListInitialFilters } from '../CandidateFilesListInitialConfig';
import * as Locale from '../../../common/locale';

const Style = styled.div`
  
  height: 100%;
  
  .InovuaReactDataGrid__cell,
  .InovuaReactDataGrid__column-header__filter-wrapper input,
  .InovuaReactDataGrid__column-header__filter-wrapper span,
  .InovuaReactDataGrid__column-header__content>*,
  .inovua-react-toolkit-date-input__input{
    font-size:12px !important
  }
`;

export function DefaultPage() {
    const { t } = useSafeTranslation();
    const detectMobile = useMobileDetect();

    return (
      <Style>
        <CandidateFileListContextProvider>
          <Card>
            <Locale.Content tkey="referent.candidateList" />
          </Card>
          <UserConfigContextProvider>
            <CandidateFilesList
              initialColumns={CandidateFilesListInitialColumns(t, detectMobile.isMobile())}
              renderRowContextMenu={() => <Fragment />}
              renderListHeader={() => <Fragment />}
              visible
              gridStyle={{ height: 'calc( 100% - 200px)' }}
              initialFilters={CandidateFilesListInitialFilters}
            />
          </UserConfigContextProvider>
        </CandidateFileListContextProvider>
      </Style>
);
}
