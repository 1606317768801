import { Namespace, useTranslation, UseTranslationOptions } from 'react-i18next';
import { formListItemLabelCleaner } from '../utils/formListItemLabelCleaner';

function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

const showKey = window.location.href.indexOf('?key=true') !== -1;
export const useSafeTranslation = (
    ns?: Namespace,
    options?: UseTranslationOptions,
): {
    t: (key: string
    ) => string
} => {
    const { t } = useTranslation(ns, options);
    return { t: (key) => capitalizeFirstLetter(showKey ? key : t(formListItemLabelCleaner(key))?.trim())?.replace?.('""', '') || undefined };
};
