// @ts-ignore

import { IComments, UserRoleEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { parse, stringify } from 'flatted';
import _ from 'lodash';

export class CommentsUtils {
    /**
     * Return unread comments comments after my last message
     * @param comments
     */
    static getUnreadedComments(comments: IComments[]) {
        comments = parse(stringify(comments));
        if (comments.reverse) {
            comments.reverse();
        const messagesByRoles = comments?.map((c) => c.role);
        const lastIndexOfCandidate = _.lastIndexOf(messagesByRoles, UserRoleEnum.CANDIDATE);
        return comments?.slice(lastIndexOfCandidate + 1, comments.length);
        }
            return [];
    }
}
