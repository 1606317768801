/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { usePath } from 'hookrouter';
import { Fragment } from 'react';
import * as Locale from '../../common/locale';
import { sNavigate } from '../../utils/safeNavigation';
import { ROUTES } from '../user/UserRoutesDef';
import { CandidateChat } from './CandidateChat';
import { IsWaitingCandidateOrCloseCandidate } from '../user/conditionnal/IsWaitingCandidateOrCloseCandidate';

export function CandidateHome() {
  const path = usePath();
  if (path !== `${ROUTES.CANDIDATE_BASE}/home`) {
    sNavigate(`${ROUTES.CANDIDATE_BASE}/home`);
  }

  /**
   * Pour les candidats, si on est en attente d'informations de sa part, ou si la periode d'ouverture aux candidats est close
   * la home page affiche le chat (sachant qu'en cas de periode close, seul le chat sur cet home est accessible pour le candidat)
   */
  return (
    <Fragment>
      <div className="message  is-small" css={css`max-height: calc( 100% - 200px); position:relative`}>
        <IsWaitingCandidateOrCloseCandidate
          elseChildren={(<Locale.Content tkey="candidate.home" />)}>
          <CandidateChat />
        </IsWaitingCandidateOrCloseCandidate>
      </div>
    </Fragment>
  );
}
