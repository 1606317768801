/** @jsx jsx */
import { jsx } from '@emotion/core';
import _, { flatMap } from 'lodash';
import { Fragment } from 'react';
import { Badge } from 'antd';
import { useColdData } from '../../../../hooks/use-cold-data.hook';
import { ColdDataSelectorContainer } from '../../../../common/coldData/ColdDataSelectorContainer';
import { CustomGridSelectEditor } from '../../../../common/customDatagrid/customGridSelectEditor';
import { ConditionnalPopover } from '../../../../common/antd/ConditionnalPopover';
import * as Locale from '../../../../common/locale';
import { perc2color } from '../utils/Color';

/**
 * Fonction appelee pour la generation de la combo pour filtrage des formations
 * @param props
 * @returns
 */
export function ProgramFilter(props) {
    // Recuperation des formation
    const filterEditorProps = props?.filterEditorProps;

    // La ligne css={css`.ant-select-selector {width: 300px !important}`} ci-dessous permet de rajouter directement du css
    // dans une classe existante utilisée. Ici, on ajoute width sur la classe ant-select-selector pour outrepasser son mode
    // "autoadapatif par defaut". Le !important permet de signaler que cette nouvelle propriete ne doit pas etre "redefinie" par
    // d'eventuelles nouvelles props appliquées ensuite
    return props.render(
      <ColdDataSelectorContainer
        {...filterEditorProps}
        api="programs"
        params="{ style: { width: '200px' } }"
        allowClear
        mapper={mapper}
        mode="multiple"
        onClear={() => {
            props.onChange({ ...props.filterValue, value: undefined });
        }}
        onChange={(v) => {
                props.onChange({ ...props.filterValue, value: v.map((vv) => vv.id) });
            }}
      />,
    );
}

/**
 * Display Program value
 * @param value
 * @constructor
 */
export function ProgramCellRender({ data, value }: any) {
    return (
      <Fragment key={`programLines_cell_${data.id}`}>
        <ConditionnalPopover
          disabled={value?.length === 0}
          content={(
            <ul>
              {value.map?.((p) => (
                <li
                  key={p?.id}
                >
                  {`${p?.name} (${p?.optionName || ''} ${p?.school || ''})`}
                </li>
                        ))}
            </ul>
                )}
        >
          <div>
            <Badge
              count={value.length}
              overflowCount={24}
              style={{ backgroundColor: perc2color(24 - value.length, 0, 40), color: 'black' }}
            />
          </div>
        </ConditionnalPopover>
      </Fragment>
    );
}

/**
 * Return rendor Editor
 * @param editorProps
 * @constructor
 */
export function ProgramFilterEditor(editorProps) {
    const [coldData] = useColdData('public/programs');

    const { data } = coldData;
    // Tri des formations
    let listPrograms = _.sortBy(data, 'name');
    // Application du mapper
    listPrograms = flatMap(data, mapperCombo);
    // Il faut passer le dataSource dans le return, si on le passe dans le editorProps, ca ne marche pas (pas de rerender ?)
    return <CustomGridSelectEditor {...editorProps} dataSource={listPrograms} />;
}

export const programEditorProps = {
    idProperty: 'program',
};

export const programColumn = {
    header: <Locale.Header tkey="candidateFilesList.countProgram" />,
    name: 'programs',
    id: 'programs',
    sortable: true,
    defaultWidth: 120,
    filterEditor: ProgramFilter,
    render: ProgramCellRender,
};

export const programFilter = {
    name: 'programs', operator: 'inlist', type: 'select', value: '',
};

// Mapper utilisé pour générer nom formation + option dans la combo de filtrage (ColdDataSelectorContainer ou il faut id et name)
export const mapper = (p) => ({
    id: p.id, name: `${p?.name}${p?.optionName ? ` / ${p?.optionName}` : ''}`,
});

// Mapper utilisé pour générer nom formation + option dans la combo de selection (il faut id et label)
export const mapperCombo = (p) => ({
    id: p.id, label: `${p?.name}${p?.optionName ? ` / ${p?.optionName}` : ''}`,
});
