/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {AdminEditableList} from '../../common/AdminEditableList';
import {
    referentReviewListInitialColumns,
    referentReviewListInitialFilters,
    referentReviewListMapToRow,
    referentReviewListRenderRowContextMenu,
} from './ReferentReviewListInitialConfig';

export function ReferentReviewListPage(props) {
  return (
    <Fragment>
      <AdminEditableList
        entity="admin/academic-referent-review"
        checkboxColumn
        renderRowContextMenu={referentReviewListRenderRowContextMenu}
        initialFilters={referentReviewListInitialFilters}
        initialColumns={referentReviewListInitialColumns()}
        mapToRow={referentReviewListMapToRow}
      />
    </Fragment>
  );
}
