import React from 'react';
import {PaymentCompleted} from '../../candidateFile/paymentsList/PaymentCompleted';
import {PaymentError} from '../../candidateFile/paymentsList/PaymentError';
import {CandidateHome} from '../CandidateHome';
import {FullScreenCandidatePage} from '../FullScreenCandidatePage';
import {CandidateChild1Container} from './CandidateChild1Container';

/**
 * Routes starting with /home/welcome/secretary/
 * @constructor
 */
export const CandidateContainerRoutesDef = () => ({
    '/payment-completed': () => (
      <FullScreenCandidatePage>
        <PaymentCompleted />
      </FullScreenCandidatePage>
    ),
    '/payment-error': () => (
      <FullScreenCandidatePage>
        <PaymentError />
        <CandidateHome />
      </FullScreenCandidatePage>
    ),
    '*': () => (
      <CandidateChild1Container />
    ),

});
