/** @jsx jsx */
import { css, jsx } from '@emotion/core';
// @ts-ignore
import { usePath } from 'hookrouter';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { TextDangerButton } from '../../common/button/TextDangerButton';
import * as Locale from '../../common/locale';
import { MenuAndFormContainer } from '../../common/page/Containers';
import { UserContext } from '../../context/user.context';
import { sNavigate } from '../../utils/safeNavigation';
import { CenterAreaContainer } from '../canSeeDocuments/viewer/CenterAreaContainer';
import { FlexibleCenterRightAreaContainer } from '../canSeeDocuments/viewer/FlexibleCenterRightAreaContainer';
import { LeftAreaContainer } from '../canSeeDocuments/viewer/LeftAreaContainer';
import { RightAreaContainer } from '../canSeeDocuments/viewer/RightAreaContainer';
import { RightMenuContainer } from '../canSeeDocuments/viewer/RightMenuContainer';
import { SecretaryAndExaminerPageStyle } from '../specializedSecretary/SpecializedSecretaryPage';
import { IsCandidateFileSelected } from '../user/conditionnal/IsCandidateFileSelected';
import { UserPage } from '../user/UserPage';
import { UserPageHeader } from '../user/UserPageHeader';
import { EXAMINER, LOGIN, ROUTES } from '../user/UserRoutesDef';
import { ExaminerLeftMenu } from './ExaminerLeftMenu';
import { TextOkButton } from '../../common/button/TextOkButton';
import { useExaminerUtils } from './use-examiner-utils';
import { CandidateFileContext, CandidateFileContextProviderType } from '../canSeeCandidateFile/CandidateFileContext';
import { IsPath } from '../user/conditionnal/IsPath';
import { DocumentHeaderButton } from '../canSeeDocuments/DocumentHeaderButton';

export function CustomPageHeader() {
  const userCtx = useContext(UserContext);
  const { navigateToExaminerReview, navigateToCandidateFile } = useExaminerUtils();
  const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);

  const candidateFileId = candidateFileContext?.selectedCandidateFile?.id;
  return (
    <UserPageHeader
      title={<Locale.Title tkey="examiner" />}
      subTitle={`${userCtx.userCtx?.user?.name} ${userCtx.userCtx?.user?.program?.name} (${userCtx.userCtx?.user?.program?.optionName})`}
      extra={(
        <Fragment>
          <IsPath equals={EXAMINER.REVIEW.replace(':candidateFileId', candidateFileId)}>
            <TextOkButton
              tkey="candidateFile.examiner.open"
              onClick={() => navigateToCandidateFile(`${candidateFileId}`)}
            />
          </IsPath>
          <DocumentHeaderButton />
          <IsPath different={ROUTES.EXAMINER_BASE}>
            <IsPath different={`${ROUTES.EXAMINER_BASE}/pdf`}>
              <IsPath different={EXAMINER.REVIEW.replace(':candidateFileId', candidateFileId)}>
                <TextOkButton
                  tkey="candidateFile.examiner.doReview"
                  onClick={() => navigateToExaminerReview(`${candidateFileId}`)}
                />
              </IsPath>
            </IsPath>
            <TextDangerButton
              tkey="secretary.exit"
              onClick={() => sNavigate(`${ROUTES.EXAMINER_BASE}`)}
            />
          </IsPath>
          <IsPath equals={`${ROUTES.EXAMINER_BASE}`}>
            <TextDangerButton
              tkey="logout"
              onClick={() => sNavigate(`${LOGIN.LOGOUT}`)}
            />
          </IsPath>
        </Fragment>
      )}
    />
  );
}

export function ExaminerPage(props: { children: any }) {
  const path = usePath();
  const [, updateState] = useImmer({ loaded: false, routes: undefined as any, path: '' });
  useEffect(() => {
    updateState((draft) => {
      draft.path = path;
    });
  }, [updateState, path]);

  return (
    <UserPage>
      <SecretaryAndExaminerPageStyle>
        <CustomPageHeader />
        <MenuAndFormContainer>
          <IsCandidateFileSelected>
            <LeftAreaContainer>
              <ExaminerLeftMenu />
            </LeftAreaContainer>
          </IsCandidateFileSelected>
          <FlexibleCenterRightAreaContainer>
            <CenterAreaContainer>
              {props?.children}
            </CenterAreaContainer>
            <IsCandidateFileSelected>
              <RightAreaContainer />
            </IsCandidateFileSelected>
          </FlexibleCenterRightAreaContainer>
          <RightMenuContainer />
        </MenuAndFormContainer>
      </SecretaryAndExaminerPageStyle>
    </UserPage>
  );
}

export function FullScreenExaminerPage(props: { children: any }) {
  return (
    <UserPage>
      <CustomPageHeader />
      <div css={css`height:calc( 100% - 100px)`}>
        {props?.children}
      </div>
    </UserPage>
  );
}
