import { usePath, useRoutes } from 'hookrouter';
import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { CandidatePageWithValidations } from '../CandidatePageWithValidations';
import { CandidateChild1RoutesDef } from './CandidateChild1RoutesDef';

export function CandidateChild1Container() {
  const routesChildren = useRoutes(CandidateChild1RoutesDef());
  const path = usePath();
  const [, updateState] = useImmer({ loaded: false, routes: undefined as any, path: '' });
  useEffect(() => {
    updateState((draft) => {
      draft.path = path;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState, path]);
  return (
    <CandidatePageWithValidations>
      {routesChildren}
    </CandidatePageWithValidations>
  );
}
