// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ReactNode } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import { PDFEntityFormContextProvider } from './context/pdfEntityForm.context';

// eslint-disable-next-line react/no-unused-prop-types
export function PDFEntityForm<T extends any>(props: { debug?: boolean, entity: string, value: T, children: ReactNode }) {
    const [state, updateState] = useImmer({
        initialValues: props.value,
    });

    useDeepCompareEffect(() => {
        updateState((draft) => {
            draft.initialValues = props.value as any;
        });
    }, [props.value]);

    return (
      <PDFEntityFormContextProvider entityValue={state.initialValues} entity={props?.entity}>
        {props.children}
      </PDFEntityFormContextProvider>
    );
}
