/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css, jsx } from '@emotion/core';
import { SecretaryPageCandidateFilePDF } from '../../canSeePDF/candidateFilePDF/SecretaryPageCandidateFilePDF';
import { FullScreenSpecializedSecretaryPage } from '../SpecializedSecretaryPage';
import { SpecializedSecretaryChild1Container } from './SpecializedSecretaryChild1Container';
import { CandidateFilesStatistics } from '../../canSeeCandidateFile/CandidateFileStatistics';

/**
 * Routes starting with /home/welcome/secretary/
 * @constructor
 */
export const SpecializedSecretaryContainerRoutesDef = () => ({
  '/pdf': () => (
    <FullScreenSpecializedSecretaryPage>
      <SecretaryPageCandidateFilePDF />
    </FullScreenSpecializedSecretaryPage>
  ),
  '/statistics': () => (
    <FullScreenSpecializedSecretaryPage>
      <div css={css`margin: 20px`}>
        <CandidateFilesStatistics />
      </div>
    </FullScreenSpecializedSecretaryPage>
  ),
  '': () => (
    <SpecializedSecretaryChild1Container />
  ),
  '*': () => (
    <SpecializedSecretaryChild1Container />
  ),

});
