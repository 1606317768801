/** @jsx jsx */
import { ISpecializedSecretary } from '@fstn/ecandidaturev2_api-interfaces';
import StringFilter from '@inovua/reactdatagrid-community/StringFilter';
import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import { MutableRefObject, useCallback } from 'react';
import _, { flatMap } from 'lodash';
import { css, jsx } from '@emotion/core';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { useImmer } from 'use-immer';
import { useDeepCompareEffect } from 'use-deep-compare';
import { SpecializedSecretaryContextMenu } from './SpecializedSecretaryContextMenu';
import { ColdDataSelectorContainer } from '../../../../common/coldData/ColdDataSelectorContainer';
import { CustomGridSelectEditor } from '../../../../common/customDatagrid/customGridSelectEditor';
import { useColdData } from '../../../../hooks/use-cold-data.hook';
import { getSelectedElements } from '../../common/AdminEditableList';

// Pour alimentation des combos (filtres et edition) du genre
const listGender = [
    { id: 'm', label: 'Masculin' },
    { id: 'f', label: 'Féminin' },
    { id: 'o', label: 'Autre' }];

// Contiendra la liste des formations pour alimentation des combos (filtres et edition)
let listPrograms = [];

/**
 * Fonction appelee pour la generation de la combo pour filtrage des formations
 * Elle en profite pour alimenter listPrograms qui est utilisé pour afficher les combos en edition
 * @param props
 * @returns
 */
function ProgramFilter(props: { onChange: Function, filterValue: any }) {
    // Mapper utilisé pour générer nom formation + option dans la combo de filtrage (ColdDataSelectorContainer ou il faut id et name)
    const mapper = useCallback((p) => ({
        id: p.id, name: `${p?.name}${p?.optionName ? ` / ${p?.optionName}` : ''}`,
    }), []);
    // Mapper utilisé pour générer nom formation + option dans la combo de selection (il faut id et label)
    const mapperCombo = useCallback((p) => ({
        id: p.id, label: `${p?.name}${p?.optionName ? ` / ${p?.optionName}` : ''}`,
    }), []);
    // Recuperation des formation
    const [coldData] = useColdData('public/programs');
    const [state, updateState] = useImmer({
        listPrograms: [],
    });

    useDeepCompareEffect(() => {
        let { data } = coldData;
        // Tri des formations
        data = _.sortBy(data, 'name');
        // Application du mapper
        data = flatMap(data, mapperCombo);
        updateState((draft) => {
            draft.listPrograms = data;
        });
    }, [coldData]);
    // Mise a jour du tableau utilisé par le customGridSelecctEditor et le render de la colonne
    listPrograms = state.listPrograms;

    // La ligne css={css`.ant-select-selector {width: 300px !important}`} ci-dessous permet de rajouter directement du css
    // dans une classe existante utilisée. Ici, on ajoute width sur la classe ant-select-selector pour outrepasser son mode
    // "autoadapatif par defaut". Le !important permet de signaler que cette nouvelle propriete ne doit pas etre "redefinie" par
    // d'eventuelles nouvelles props appliquées ensuite
    return (
      <div className="InovuaReactDataGrid__column-header__filter-wrapper" css={css`.ant-select-selector {width: 200px !important}`}>
        <ColdDataSelectorContainer
          api="programs"
          params="{ style: { width: '200px' } }"
          allowClear
          mapper={mapper}
          onChange={(v) => {
                    props.onChange({ ...props.filterValue, value: v });
                }}
        />
      </div>
    );
}

export const specializedSecretariesListInitialColumns = () => [
    {
        header: 'Genre',
        name: 'gender',
        width: 150,

        editable: true,
        style: { textAlign: 'center' },

        render: ({ value }) => listGender.find((element) => element.id === value)?.label,
        filterEditor: SelectFilter,
        filterEditorProps: {
            placeholder: 'All',
            dataSource: listGender,
        },
        renderEditor: (editorProps) => <CustomGridSelectEditor {...editorProps} />,
        editorProps: {
            idProperty: 'gender',
            dataSource: listGender,
        },
    }, {
        header: 'Prénom',
        name: 'firstName',
        width: 200,

        editable: true,
        style: { textAlign: 'left' },

    }, {
        header: 'Nom',
        name: 'lastName',
        width: 200,

        editable: true,
        style: { textAlign: 'left' },

    }, {
        header: 'E-mail',
        name: 'email',
        className: 'email',
        'test-id': 'email',
        width: 250,

        editable: true,
        style: { textAlign: 'left' },
        filterEditor: StringFilter,
        filterEditorProps: {
            className: 'emailFilter',
            'test-id': 'emailFilter',
        },

    }, {
        header: 'Formation',
        name: 'program',
        width: 400,

        style: { textAlign: 'left' },
        // showInContextMenu: true,
        lockable: false,
        render: ({ value }) => listPrograms.find((element) => element.id === value)?.label,
        filterEditor: ProgramFilter,
        // Il faut passer le dataSource dans le return, si on le passe dans le editorProps, ca ne marche pas (pas de rerender ?)
        renderEditor: (editorProps) => <CustomGridSelectEditor {...editorProps} dataSource={listPrograms} />,
        editorProps: {
            idProperty: 'program',
        },
    }, {
        header: 'Ecole',
        name: 'school',
        width: 250,

        editable: false,
        style: { textAlign: 'left' },

    },
];

export const specializedSecretariesListInitialFilters = [
    {
        name: 'firstName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'lastName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'email', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'gender', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'program', operator: 'listPrograms', type: 'listPrograms', value: '',
    },
    {
        name: 'school', operator: 'contains', type: 'string', value: '',
    },
];

export function specializedSecretariesListRenderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedUsers, gridRef) {
    return (
      <SpecializedSecretaryContextMenu
        menuProps={menuProps}
        details={details}
        selectedUsers={getSelectedElements(details.rowProps.data, selectedUsers)}
        gridRef={gridRef}
      />
    );
}

/**
 * Map result to row
 * @param d
 */
export function specializedSecretariesListMapToRow(d: ISpecializedSecretary) {
    const row: any = {
        id: d.id,
        firstName: d.firstName,
        lastName: d.lastName,
        email: d.email,
        gender: d.gender,
        program: d.program?.id,
        school: d.program?.school,
    };
    return row;
}
