/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import styled from '@emotion/styled';
import PageHeader from 'antd/lib/page-header';
import React from 'react';
import * as Locale from '../../common/locale';
import {AxiosContextProvider} from '../../context/axios.context';
import {IsDesktop} from '../user/conditionnal/IsDesktop';
import {CenterForm} from "./CenterForm";

const BeforeLoginStyle = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    color:white;
    grid-template-areas:  "header"
                          "content"
                          "footer";
    grid-template-rows: min-content minmax(0, 100%) min-content ;
    
    .ant-page-header-heading-title{
      color: white !important;
      font-size: 30px;
    }
    
`;

export function HeaderBackground(){
    return (
        <div css={css`

width: 100%;
height: 150px;
background: linear-gradient(15deg,#00B5CC,#31D6ED);
transform-origin: 0px 0px;
transform: skewY(-5deg);
overflow: hidden;
z-index: -1;
`}/>)
}

export function BeforeLoginPage(props: { children: any }) {
    return (
      <BeforeLoginStyle>
        <IsDesktop>
          <div css={css`grid-area: header;margin-bottom:-2em;`}>
            <HeaderBackground/>
            <div css={css`
position:absolute;
top: 0;
left: 0;`}>
            <PageHeader
              title={<Locale.Title tkey="home" />}
              className="site-page-header"
            />
            </div>
              <br/>
          </div>
        </IsDesktop>
          <CenterForm>
            <AxiosContextProvider>
              <div css={css`flex:1 0; min-width: 100px;width: 100%; height: 100%; position: relative; grid-area: content;`}>
                {props?.children}
              </div>
            </AxiosContextProvider>
          </CenterForm>
          <IsDesktop>
              <div css={css`background-color: #002E42; margin-top:1em; padding: 1em; grid-area: footer`}>
                    <Locale.Content tkey="before-login.footer" />
              </div>
          </IsDesktop>
      </BeforeLoginStyle>
);
}
