/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { UserConfigContextProvider } from '../../context/user.config.context';
import { CandidateFilesList } from '../canSeeCandidateFile/candidateFilesList/CandidateFilesList';
import {
  CandidateFilesListInitialColumns,
  CandidateFilesListInitialFilters,
  renderRowContextMenu,
} from './CandidateFilesListInitialConfig';

/**
 * Jury list of candidate
 * @constructor
 */
export function JuryHome() {
  return (
    <div css={css`
height: 100%;
.InovuaReactDataGrid__row{
background-color: white !important;
};
.InovuaReactDataGrid__row--selected{
  background-color: #CDEDF0 !important;
  };`}
    >
      <UserConfigContextProvider>
        <CandidateFilesList
          initialColumns={CandidateFilesListInitialColumns()}
          renderRowContextMenu={renderRowContextMenu}
          gridStyle={{ height: 'calc( 100% - 100px)' }}
          initialFilters={CandidateFilesListInitialFilters}
        />
      </UserConfigContextProvider>
    </div>
  );
}
