import { IMissingFieldsList } from '@fstn/ecandidaturev2_api-interfaces';
import React, { Fragment } from 'react';

import { CheckboxFormItem } from '../../../common/form/inputFormItems/CheckboxFormItem';
import { InputTextAreaFormItem } from '../../../common/form/inputFormItems/InputTextAreaFormItem';
import * as Locale from '../../../common/locale';
import { EntityFormContext } from '../../../context/entityForm.context';
import { EntityFormListContext } from '../../../context/entityFormList.context';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

export function MissingFieldForm(props: {}) {
  const field = useScopedSelector<any[], any>('MissingFieldForm', EntityFormListContext, (c) => c[0].field);

  const formEntityValue = useScopedSelector<any[], IMissingFieldsList>('MissingFieldForm', EntityFormContext, (c) => c[0]?.entityValue);

  return (
    <Fragment>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <Locale.Label tkey={formEntityValue?.missingFields?.[field.name]?.name} />
        </div>
        <div style={{
          width: '110px', alignItems: 'center', display: 'flex', marginRight: '30px',
        }}
        >
          optionnel
          <CheckboxFormItem
            wrapperCol={{ span: 1 }}
            labelCol={{ span: 0 }}
            label=""
            propertyName={['missingFields', field.name, 'valid']}
          />
        </div>
      </div>
      <InputTextAreaFormItem
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 0 }}
        label=""
        propertyName={['missingFields', field.name, 'description']}
      />
    </Fragment>
  );
}
