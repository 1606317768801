/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {Fragment} from 'react';
import * as Locale from '../../../../common/locale';
import {CommentsListItemContainer} from '../../../user/commentsListItem/CommentsListItemContainer';

/**
 * Comments Btw Secretaries and candidates part of right container
 * @constructor
 */
export function CommentsBtwSecretaryAndCandidateRightContainer() {
return (
  <Fragment>
    <h1>
      <Locale.Title tkey="secretaryReview.secretary.commentsBtwSecretaryAndCandidate" />
    </h1>
    <br />
    <CommentsListItemContainer
      css={css`
    height: calc( 100% - 30px);
    position: relative;
`}
      type="commentsBtwSecretaryAndCandidate"
    />
  </Fragment>
);
}
