import styled from '@emotion/styled';

import { SelectProps } from 'antd/lib/select';
import React from 'react';
import { useSafeTranslation } from '../../hooks/use-safe-translation';
import { ResponsiveSelect } from '../input/ResponsiveSelect';
import { getOptions } from './utils';

// @ts-ignore
const Style = styled.div`
    
`;

export interface ColdDataSelectorProps extends SelectProps<string> {
    api: string,
    params?: any,
    mapper?: ((v: any) => any),
    filter?: ((v: any) => boolean)
    listeners?: { dataLoaded?: (d: any[]) => void }
}

/**
 * Select Input for ColdData
 * @param props
 * @constructor
 */
export function ColdDataSelector(props: {
    id: string;
    api: string,
    className: string;
    disabled: boolean;
    onChange: any,
    onOptionChange: (value: any, option: any) => void;
    value: any,
    options: any,
    mode?:any
}) {
    const { t } = useSafeTranslation();
    return (
      <Style>
        <ResponsiveSelect
          className={props.className}
          data-testid={props['data-testid']}
          disabled={props.disabled}
          allowClear
          value={props.value}
          mode={props.mode}
          onChange={props.onChange}
          onClear={() => props.onOptionChange(null, { value: null })}
        >
          {getOptions(props.options, props.api, props['test-id'], t)}
        </ResponsiveSelect>
      </Style>
    );
}
