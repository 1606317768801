/** @jsx jsx */
import { IProgram, IProgramLine, IProgramsList } from '@fstn/ecandidaturev2_api-interfaces';
import { css, jsx } from '@emotion/core';
import { Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import React, { useContext, Fragment } from 'react';
import { useImmer } from 'use-immer';
// @ts-ignore
import * as UUID from 'uuid-js';

import * as Locale from '../../../common/locale';
import { EntityFormContext } from '../../../context/entityForm.context';
import { UserContext, UserContextType } from '../../../context/user.context';
import { IsDesktop } from '../../user/conditionnal/IsDesktop';
import { ProgramModalListContainer } from './ProgramModalListContainer';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { TextOkButton } from '../../../common/button/TextOkButton';
import { TextCancelButton } from '../../../common/button/TextCancelButton';
import { IsMobile } from '../../user/conditionnal/IsMobile';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

export function ProgramModal(props: {
    onCancel: () => Promise<void>,
    show: boolean,
    onAdd: (programLines: IProgramLine[]) => Promise<void>,
    hiddenPrograms?: IProgram[]
}) {
    const formEntityValue = useScopedSelector<any[], IProgramsList>('ProgramModal', EntityFormContext, (c) => c[0]?.entityValue);

    const [programForm] = useForm();
    const [state, updateState] = useImmer({ loading: false, gridRef: undefined });
    const { userCtx } = useContext<UserContextType>(UserContext);
    // console.log('Redraw ProgramModal');
    async function onAdd(program) {
        if (!program) {
            return;
        }
        programForm.setFieldsValue({
            school: [],
            theme: [],
            programLines: [],
        });
        updateState((draft) => {
            draft.loading = true;
        });
        const programLines = program.map((p, i) => ({
            program: p,
            id: UUID.create(1).toString(),
            createdAt: moment.utc(),
            // set position to add order
            rank: i + props.hiddenPrograms.length,
            rights: {
                ...formEntityValue.rights,
                delete: [userCtx.user.role.name],
                write: [userCtx.user.role.name],
            },
        }));
        await props.onAdd(programLines);
        programForm.setFieldsValue({ programs: undefined });
        setTimeout(() => state?.gridRef?.gridRef?.current?.deselectAll?.(), 0);
        updateState((draft) => {
            draft.loading = false;
        });
    }

    return (
      <Modal
        className="full-screen"
        width="98vw"
        visible={props.show}
            // @ts-ignore
        okButtonProps={{ 'data-testid': 'program-email-ok', loading: state.loading }}
        footer={<Fragment />}
        title={(
          <div css={css`display: flex;
    align-items: center;
    justify-content: space-between;`}
          >
            <IsDesktop elseChildren={<div style={{ height: '60px' }} />}><Locale.Title tkey="programs.select" /></IsDesktop>
            <IsDesktop>
              <div>
                <TextCancelButton tkey="programs.select.cancel" onClick={props.onCancel} />
                <TextOkButton tkey="programs.select.ok" onClick={programForm.submit} />
              </div>
            </IsDesktop>
          </div>
          )}
        css={css`.ant-form,
.ant-col,
.ant-form-item-control,
.ant-form-item-control-input,
.ant-form-item-control-input-content{
  height: 100% !important;
}
    .ant-modal-content{
        min-height: calc( 100vh - 60px) !important;
        height: fit-content !important;
    }
    .ant-modal-header{
      height: 60px;
    }
    .ant-modal-title{
        position: fixed;
        background:white;
        left: 0em;
        top: 0em;
        right: 0em;
        padding: 1em;
        z-index: 999;
    }
`}
      >
        <IsDesktop>
          <Locale.Help tkey="programs.select" />
        </IsDesktop>

        <Form form={programForm as any} onFinish={(v) => onAdd(v.programLines)} style={{ height: '100%' }}>
          <Form.Item name="programLines" style={{ height: '100%' }}>
            <ProgramModalListContainer
              hiddenPrograms={props.hiddenPrograms}
            />
          </Form.Item>
          <IsMobile>
            <div>
              <TextCancelButton tkey="programs.select.cancel" onClick={props.onCancel} />
              <TextOkButton tkey="programs.select.ok" onClick={programForm.submit} />
            </div>
          </IsMobile>
        </Form>
      </Modal>
    );
}
