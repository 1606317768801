import {UserRoleEnum} from '@fstn/ecandidaturev2_api-interfaces';
import React, {Fragment, useContext} from 'react';
import {UserContext} from '../../../context/user.context';
import {ReferentRegisterPage} from '../../referent/ReferentRegisterPage';

export function RegisterPageSwitcher({ children }: any) {
    const { userCtx } = useContext(UserContext);
    if (!userCtx.user?.registered) {
        switch (userCtx.user?.role?.name) {
            case UserRoleEnum.REFERENT:
                return <ReferentRegisterPage />;
            case UserRoleEnum.SECRETARY:
                break;
        }
    }
    return <Fragment>{children}</Fragment>;
}
