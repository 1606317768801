/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {useSafeTranslation} from '../../../../hooks/use-safe-translation';
import {AdminEditableList} from '../../common/AdminEditableList';
import {
    localesListInitialColumns,
    localesListInitialFilters,
    localesListMapToRow,
    localesListRenderRowContextMenu,
} from './LocaleListInitialConfig';

/**
 *  LAURENT
 * Exemple de liste d'administration pour les traductions
 * Utilise la lib suivante:https://reactdatagrid.io/docs
 *
 * L'idée ici c'est que l'on a une liste générique définie dans AdminEditableList que l'on utilise pour toutes les entitées de l'application
 * Cette liste est personnalisés avec les props:
 * entity= nom du endpoint à appeler par exemple ici locales => /api/locales => apps/back/src/locale/locale.controller.ts
 * renderRowContextMenu = menu à afficher au click droit
 * initialFilters = liste des filtres possibles
 * initialColumns = liste des colonnes à afficher
 * mapToRow = transforme le résultat du endpoint en ligne du tableau
 * checkboxColumn = permet de preciser si on veut des listes avec selections et checkbox
 */

export function LocaleListPage() {
  const { t } = useSafeTranslation();
  return (
    <Fragment>
      <AdminEditableList
        entity="locales"
        checkboxColumn
        renderRowContextMenu={localesListRenderRowContextMenu}
        initialFilters={localesListInitialFilters}
        initialColumns={localesListInitialColumns(t)}
        mapToRow={localesListMapToRow}
      />
    </Fragment>
  );
}
