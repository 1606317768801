/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {AdminEditableList} from '../../common/AdminEditableList';
import {
    programManagerListInitialColumns,
    programManagerListInitialFilters,
    programManagerListMapToRow,
    programManagerListRenderRowContextMenu,
} from './ProgramManagerListInitialConfig';

export function ProgramManagerListPage(props) {
  return (
    <Fragment>
      <AdminEditableList
        entity="admin/program-manager"
        checkboxColumn
        renderRowContextMenu={programManagerListRenderRowContextMenu}
        initialFilters={programManagerListInitialFilters}
        initialColumns={programManagerListInitialColumns()}
        mapToRow={programManagerListMapToRow}
      />
    </Fragment>
  );
}
