/** @jsx jsx */
import { css, jsx } from '@emotion/core';

export function HorizontalFormItem({ children }: any) {
    return (
      <div css={css`.ant-form-item {flex-direction: row !important}`}>
        {children}
      </div>
    );
}
