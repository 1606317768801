import styled from '@emotion/styled';
import React from 'react';
import * as Locale from '../../common/locale';
import { AxiosContextProvider } from '../../context/axios.context';
import { CenterForm } from './CenterForm';
import { LoginForm } from './login/Login';

const Style = styled.div`
  overflow: auto;
  max-height: 100%;
`;

export function LoginPage() {
  return (
    <Style>
      <AxiosContextProvider>
        <Locale.Content tkey="login.header" />
        <Locale.Content tkey="login.messages" />
        <CenterForm>
          <LoginForm />
        </CenterForm>
        <Locale.Content tkey="login.footer" />
      </AxiosContextProvider>
    </Style>
  );
}
