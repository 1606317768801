import React, { Fragment } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import { FormListFieldData } from '../common/form/type';
import {createContext} from "use-context-selector";

export const EntityFormListContext = createContext([undefined, undefined] as [EntityFormListContextType<any>, any]);

export interface EntityFormListContextType<T> {
    entity: string;
    entityValue: T;
    propertyName: string | any[] | number,
    field: FormListFieldData;
    getFieldsValue?: () => any,
    remove: Function;
}

export const EntityFormListContextProvider = (props: {
    entity: string, children: any,
    entityValue?: any,
    propertyName: string | any[] | number,
    field?: FormListFieldData,
    remove: Function
}) => {
    const [state, updateState] = useImmer({
        entity: props?.entity,
        entityValue: props?.entityValue,
        field: props?.field,
        propertyName: props?.propertyName,
        remove: props?.remove,
    } as EntityFormListContextType<any>);

    useDeepCompareEffect(() => {
        updateState((draft: any) => {
            draft.entityValue = props?.entityValue;
        });
    }, [props?.entityValue, updateState]);

    return (
      <EntityFormListContext.Provider
        value={[state, updateState]}
      >
        <Fragment>
          {props?.children}
        </Fragment>
      </EntityFormListContext.Provider>
    );
};
