import { RightsUtils } from '@fstn/ecandidaturev2_api-interfaces';
import { useContext } from 'react';
import { AxiosContext, AxiosContextType } from '../context/axios.context';
import {
    CandidateFileContext,
    CandidateFileContextProviderType,
} from '../pages/canSeeCandidateFile/CandidateFileContext';

export function useRights() {
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);

    const ready = !!candidateFileContext.selectedCandidateFile?.id;

    async function loadRights(withUpdateRights) {
        const params: any = {};
        if (candidateFileContext) {
            params.candidateFileId = candidateFileContext.selectedCandidateFile?.id;
        }

        await withUpdateRights(async () => {
            const res = await axios.get('rights/candidate-file', { params });
            const mapRes = {};
            Object.keys(res?.data || {})?.forEach((k) => {
                mapRes[k] = { ...res?.data[k] };
            });
            return mapRes;
        });
    }

    function atLeastOneEntityIsWrittable(_rights, roleName) {
        return _rights && Object.keys(_rights)
            .map((k) => RightsUtils.roleCanWrite(_rights[k], roleName))
            .reduce((acc, roleCanWrite) => acc || roleCanWrite, false);
    }

    function allEntitiesAreWrittable(_rights, roleName) {
        return _rights && Object.keys(_rights)
            .map((k) => RightsUtils.roleCanWrite(_rights[k], roleName))
            .reduce((acc, roleCanWrite) => acc && roleCanWrite, true);
    }

    return {
        loadRights,
        atLeastOneEntityIsWrittable,
        allEntitiesAreWrittable,
        ready,
    };
}
