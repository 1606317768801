import { AxiosInstance, AxiosStatic } from 'axios';
import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import React from 'react';
import { initReactI18next } from 'react-i18next';

type TranslationContextTypeContent = {};
export type TranslationContextType = { translationCtx?: TranslationContextTypeContent, updateTranslationCtx?: any };
export const TranslationContext = React.createContext({} as TranslationContextType);

const alreadySaved = [];

async function initTransalation(axiosInstance: AxiosInstance | AxiosStatic) {
    await i18next
        .use(initReactI18next)
        .use(HttpApi)
        .init({
            lng: 'fr',
            fallbackLng: 'fr',
            debug: true,
            keySeparator: false,
            interpolation: {
                escapeValue: false,
            },
            saveMissing: true,
            updateMissing: true,

            missingKeyHandler: (lngs: string[], ns: string, key: string) => {
                if (key) {
                    if (alreadySaved.indexOf(key) === -1) {
                        alreadySaved.push(key);
                        axiosInstance.post('/locales', { id: key });
                    }
                }
            },
            backend: {
                loadPath: '/api/public/locales',
                allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading
            },
        }, () => undefined);
}

export const TranslationContextProvider = (props: { children: any, axios: AxiosInstance }) => {
    initTransalation(props.axios).then();
    return (
      <TranslationContext.Provider value={{}}>
        {props?.children}
      </TranslationContext.Provider>
    );
};
