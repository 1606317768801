// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useContext } from 'react';
import { PDFValue } from '../components/PDFValue';
import { internalGetValue, PDFEntityFormItem } from './PDFEntityFormItem';
import { PDFSpacer } from '../components/PDFSpacer';
import { PDFEntityFormContext, PDFEntityFormContextType } from './context/pdfEntityForm.context';

export function PDFInputFormItem(props: { propertyName: any, layout?: 'vertical' | 'horizontal', debug?: boolean }) {
    return (
      <PDFEntityFormItem propertyName={props.propertyName} debug={props.debug} layout={props.layout}>
        {(value) => <PDFValue debug={props.debug}>{value}</PDFValue>}
      </PDFEntityFormItem>
);
}

export function DynamicPDFSpacer(props: { propertyName: any }) {
    const [formContext] = useContext<[PDFEntityFormContextType<any>, any]>(PDFEntityFormContext);

    const value = internalGetValue(formContext?.entityValue, props.propertyName);
    const spaceArray = value?.match?.(/.{100}/g);
    return (
      <Fragment>
        {spaceArray?.map(() => <PDFSpacer />)}
      </Fragment>
    );
}

function SubTextArea(props: { debug: boolean, value: any }) {
    const spaceArray = props.value?.match?.(/.{100}/g);
    return (
      <Fragment>
        <PDFValue debug={props.debug}>{props.value}</PDFValue>
        {spaceArray?.map(() => <PDFSpacer />)}
      </Fragment>
);
}

export function PDFInputTextAreaFormItem(props: { propertyName: any, debug?: boolean }) {
    return (
      <PDFEntityFormItem propertyName={props.propertyName} debug={props.debug} layout="vertical">
        {(value) => (
          <SubTextArea debug={props.debug} value={value} />
)}
      </PDFEntityFormItem>
);
}

export function PDFDatePickerFormItem(props: { propertyName: any, debug?: boolean }) {
    return (
      <PDFEntityFormItem propertyName={props.propertyName} debug={props.debug}>
        {(value) => <PDFValue debug={props.debug}>{value}</PDFValue>}
      </PDFEntityFormItem>
);
}

export function PDFFixSelectFormItem(props: { propertyName: any, debug?: boolean }) {
    return (
      <PDFEntityFormItem propertyName={props.propertyName} debug={props.debug}>
        {(value) => <PDFValue debug={props.debug}>{value}</PDFValue>}
      </PDFEntityFormItem>
);
}

export function PDFCheckboxFormItem(props: { propertyName: string, debug?: boolean }) {
    return (
      <PDFEntityFormItem propertyName={props.propertyName} debug={props.debug}>
        {(value) => <PDFValue debug={props.debug}>{value}</PDFValue>}
      </PDFEntityFormItem>
);
}

export function PDFColdDataSelectorFormItem(props: { propertyName: any, debug?: boolean }) {
    return (
      <PDFEntityFormItem propertyName={props.propertyName} debug={props.debug}>
        {(value) => <PDFValue debug={props.debug}>{value}</PDFValue>}
      </PDFEntityFormItem>
);
}
