/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {Fragment, useContext} from 'react';
import {RightAreaContext, RightAreaContextProviderType} from '../../canSeeCandidateFile/RightAreaContext';
import {AreaContainerPanel} from '../../../common/layout/AreaContainerPanel';

export function RightAreaContainer(props) {
    const rightAreaContext = useContext<RightAreaContextProviderType>(RightAreaContext);
    if (rightAreaContext.rightAreaContext.displayedComponent) {
        return (
          <AreaContainerPanel css={css`
            grid-area: content;
            height: 100%;
            overflow: auto; flex-wrap: wrap;
            width: 100%;
            flex: 1;
            flex-basis: ${rightAreaContext.rightAreaContext.large ? '800px' : '400px'};
    .container.text-center{
        width:100%;
    }`}
          >
            {rightAreaContext.rightAreaContext.displayedComponent}
          </AreaContainerPanel>
        );
    }
    return <Fragment />;
}
