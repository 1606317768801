import React from 'react';
import {PaymentCompleted} from '../../candidateFile/paymentsList/PaymentCompleted';
import {PaymentError} from '../../candidateFile/paymentsList/PaymentError';
import {ROUTES} from '../../user/UserRoutesDef';
import {FullScreenCandidatePage} from '../FullScreenCandidatePage';

// NOT USE FOR THE MOMENT
import {CandidateContainer} from './CandidateContainer';

export const PaymentRoutesDef = () => ({
    [`${ROUTES.CANDIDATE_BASE}/payment-completed`]: () => (
      <FullScreenCandidatePage>
        <PaymentCompleted />
      </FullScreenCandidatePage>
    ),
    [`${ROUTES.CANDIDATE_BASE}/payment-error`]: () => (
      <FullScreenCandidatePage>
        <PaymentError />
      </FullScreenCandidatePage>
    ),
});

export const CandidateRoutesPath = () => [
    `${ROUTES.CANDIDATE_BASE}/chat`,
    `${ROUTES.CANDIDATE_BASE}/home`,
    `${ROUTES.CANDIDATE_BASE}/programs-list`,
    `${ROUTES.CANDIDATE_BASE}/profile`,
    `${ROUTES.CANDIDATE_BASE}/bachelor`,
    `${ROUTES.CANDIDATE_BASE}/academics-list`,
    `${ROUTES.CANDIDATE_BASE}/languages-list`,
    `${ROUTES.CANDIDATE_BASE}/stays-abroad-list`,
    `${ROUTES.CANDIDATE_BASE}/company-experiences-list`,
    `${ROUTES.CANDIDATE_BASE}/motivation`,
    `${ROUTES.CANDIDATE_BASE}/apprenticeship-company`,
    `${ROUTES.CANDIDATE_BASE}/additional-documents-list`,
    `${ROUTES.CANDIDATE_BASE}/payments-list`,
    `${ROUTES.CANDIDATE_BASE}/submit`];

export const CandidateRoutesDef = () => ({
    [`${ROUTES.CANDIDATE_BASE}*`]: () => (
      <CandidateContainer />
    ),
});
