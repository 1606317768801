/** @jsx jsx */
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { DocumentUtils, IDocument } from '@fstn/ecandidaturev2_api-interfaces';
import { css, jsx } from '@emotion/core';
import Popconfirm from 'antd/lib/popconfirm';
import React, { Fragment } from 'react';
import { AxiosInstance } from 'axios';
import styled from '@emotion/styled/macro';
import { TextOkButton } from '../../../button/TextOkButton';
import { ImageViewerPop } from '../../../image/ImageViewerPop';
import { ResponsiveButton } from '../../../input/ResponsiveButton';
import * as Locale from '../../../locale';
import { PDFDownloader } from '../../../pdf/PDFDownloader';
import { IsCandidate } from '../../../../pages/user/conditionnal/IsCandidate';

export const UploadContentStyle = styled.div`
margin-right: 2em; padding: 1em; margin-bottom: 0!important; margin-top: 0!important; margin-left: 0!important;
`;

function UploadContent({
    document,
    onDelete,
    disabled,
    propertyName,
    axios,
    token,
}: { document: IDocument,
    onDelete: (e?: React.MouseEvent<HTMLElement>) => void,
    disabled: boolean,
    propertyName: string | any[],
    axios:AxiosInstance,
    token:string
}) {
    const previewLink = document && DocumentUtils.getPreviewLink(document, token);
        const type = document?.originalName?.substr(document?.originalName?.lastIndexOf('.') + 1);
    const documentUploaded = !(!document || !document.id || !type || !document?.originalName);
    return (
      <Fragment>
        <UploadContentStyle
          className="columns"
        >
          {!documentUploaded
                && (
                <div className="justify-space-evenly align-self-center is-inline-block" css={css`width:100%; height:100%`}>
                  <TextOkButton
                    css={css`width:100%; height:100%`}
                    data-testid={`${propertyName}.upload`}
                    tkey={`${propertyName}.upload`}
                    type="primary"
                    disabled={disabled}
                    icon={<CloudUploadOutlined />}
                  />
                </div>
)}
          {documentUploaded
                && (
                <Fragment>
                  <IsCandidate>
                    <div
                      style={{ maxWidth: '100%' }}
                      className="justify-space-evenly column align-self-center is-full-mobile is-three-fifths is-text-overflow"
                      css={css`width:100%;height:100%;`}
                    >
                      <Fragment>
                        {document?.originalName || propertyName}
                      </Fragment>
                    </div>
                  </IsCandidate>
                  <div className="justify-space-evenly align-self-center is-two-fifths-mobile is-inline-block">
                    {type?.toLowerCase() === 'pdf' && <PDFDownloader axios={axios} document={document} token={token} />}
                    {type?.toLowerCase() !== 'pdf' && <ImageViewerPop url={previewLink} />}
                  </div>
                  <div
                    className="justify-space-evenly align-self-center  is-two-fifths-mobile is-inline-block"
                  >
                    <ResponsiveButton data-testid="upload" type="primary" disabled={disabled} icon={<CloudUploadOutlined />} />
                  </div>
                  <div
                    className="justify-space-evenly align-self-center is-two-fifths-mobile is-inline-block"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Popconfirm
                      title={<Locale.Button tkey="UploadContent.delete.confirm" />}
                      onConfirm={onDelete}
                      okText={<Locale.Button tkey="ok" />}
                      cancelText={<Locale.Button tkey="cancel" />}
                    >
                      <ResponsiveButton data-testid="uploadDelete" onClick={() => console.log('click button')} danger disabled={disabled} icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </div>
                </Fragment>
)}
        </UploadContentStyle>
      </Fragment>
    );
}

export default UploadContent;
