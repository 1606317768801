import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { createContext } from 'use-context-selector';
import { useRights } from '../hooks/use-rights.hook';
import { SmartLoading } from '../common/indicator/SmartLoading';

export type RightsContextType = { rightsCtxState: { rights: any, loading: boolean }, withUpdateRights: (cb: RightsCB) => Promise<Rights> };
export const RightsContext = createContext({} as RightsContextType);

export type Rights = any;
export type RightsCB = () => Promise<Rights>;

/**
 * Context uses to store entity validation error message
 * @param props
 * @constructor
 */
export const RightsContextProvider = (props: { children: any }) => {
    const [rightsCtxState, updateRightsCtxState] = useImmer({
        rights: undefined as any,
        loading: true,
        alreadyLoaded: false,
    });
    const ctx = { rightsCtxState, withUpdateRights };

    const { ready, loadRights } = useRights();

    useEffect(() => {
        async function load() {
            await loadRights(withUpdateRights);
            updateRightsCtxState(
                (draft) => {
                    draft.alreadyLoaded = true;
                },
            );
        }

        if (!rightsCtxState.alreadyLoaded && ready) {
            updateRightsCtxState(
                (draft) => {
                    draft.loading = true;
                },
            );
            load().then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadRights, rightsCtxState.alreadyLoaded, updateRightsCtxState, ready]);

    async function withUpdateRights(cb: RightsCB): Promise<Rights> {
        updateRightsCtxState((draft) => {
            draft.loading = true;
        });
        const updatedValidation = await cb();
        const newRights = { ...rightsCtxState?.rights, ...updatedValidation };
        updateRightsCtxState((draft) => {
            draft.rights = newRights;
            draft.loading = false;
        });
        return newRights;
    }

    return (
      <SmartLoading loading={!rightsCtxState.alreadyLoaded} context="RightsContextProvider">
        <RightsContext.Provider value={ctx}>
          {props?.children}
        </RightsContext.Provider>
      </SmartLoading>
    );
};
