/** @jsx jsx */
import { jsx } from '@emotion/core';
import { usePath } from 'hookrouter';
import React, { Fragment, useContext } from 'react';
import { TextDangerButton } from '../../../common/button/TextDangerButton';
import { sNavigate, sNavigateReload } from '../../../utils/safeNavigation';
import { CandidateFileContext, CandidateFileContextProviderType } from '../../canSeeCandidateFile/CandidateFileContext';
import { CandidateFilesListModalContainer } from '../../canSeeCandidateFile/candidateFilesList/CandidateFilesListModalContainer';
import { LOGIN, ROUTES } from '../../user/UserRoutesDef';
import { SecretaryCandidateFilesList } from '../SecretaryCandidateFilesList';
import { UserContext } from '../../../context/user.context';
import { UserPageHeader } from '../../user/UserPageHeader';
import * as Locale from '../../../common/locale';
import { SpecializedSecretaryReviewHeaderForm } from '../../specializedSecretary/SpecializedSecretaryReviewHeaderForm';
import { IsPath } from '../../user/conditionnal/IsPath';
import { TextPrimaryButton } from '../../../common/button/TextPrimaryButton';
import { IsSuperSecretary } from '../../user/conditionnal/IsSuperSecretary';

export function DefaultPage() {
  const path = usePath();
  const visible = path === '/home/welcome' || path === '/home/welcome/secretary';
  return (
    <CandidateFilesListModalContainer
      visible={visible}
      title={<CustomPageHeader />}
    >
      <SecretaryCandidateFilesList visible={visible} />
    </CandidateFilesListModalContainer>
  );
}

export function CustomPageHeader() {
  const userCtx = useContext(UserContext);
  const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
  return (
    <UserPageHeader
      title={<Locale.Title tkey="secretary" />}
      subTitle={<div>{`${userCtx.userCtx?.user?.name} ${userCtx.userCtx?.user?.email}`}</div>}
      extra={(
        <Fragment>
          <IsPath notStartsWith={`${ROUTES.SECRETARY_BASE}/${candidateFileContext?.selectedCandidateFile?.id}`}>
            <IsPath notStartsWith={`${ROUTES.SECRETARY_BASE}/pdf`}>
              <IsPath different={`${ROUTES.SECRETARY_BASE}/${candidateFileContext?.selectedCandidateFile?.id}/exit`}>
                <IsSuperSecretary>
                  <TextPrimaryButton
                    tkey="statistics"
                    onClick={() => sNavigateReload(`${ROUTES.SECRETARY_BASE}/statistics`, true)}
                  />
                </IsSuperSecretary>
                <TextDangerButton
                  tkey="logout"
                  onClick={() => sNavigate(`${LOGIN.LOGOUT}`)}
                />
              </IsPath>
            </IsPath>
          </IsPath>
        </Fragment>
      )}
    >
      <SpecializedSecretaryReviewHeaderForm />
    </UserPageHeader>
  );
}
