import {ValidationStep} from '@fstn/ecandidaturev2_api-interfaces';
import React from 'react';
import {RightsContextProvider} from '../../context/rights.context';
import {ValidationsContextProvider} from '../../context/validations.context';
import {SecretaryPage} from './SecretaryPage';

export function SecretaryPageWithValidations(props:{ children:any }) {
    // console.log('Redraw SecretaryPageWithValidations');

    return (
      <ValidationsContextProvider steps={[ValidationStep.SUBMIT, ValidationStep.SECRETARY_REVIEW, ValidationStep.SECRETARY_REVIEW_VALID]} entity="candidate-file">
        <RightsContextProvider>
          <SecretaryPage>
            {props.children}
          </SecretaryPage>
        </RightsContextProvider>
      </ValidationsContextProvider>
);
}
