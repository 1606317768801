/* eslint-disable no-nested-ternary */
import { Alert, Popconfirm } from 'antd';
// @ts-ignore
import React, { Fragment, useContext } from 'react';
import { TextDangerButton } from '../../common/button/TextDangerButton';
import { TextOkButton } from '../../common/button/TextOkButton';
import * as Locale from '../../common/locale';
import { AxiosContext, AxiosContextType } from '../../context/axios.context';
import { useSafeTranslation } from '../../hooks/use-safe-translation';
import { sNavigate, sNavigateReload } from '../../utils/safeNavigation';

import { REFERENT } from '../user/UserRoutesDef';
import { emailColumn } from '../canSeeCandidateFile/candidateFilesList/columns/EmailColumn';
import { firstNameColumn } from '../canSeeCandidateFile/candidateFilesList/columns/FirstNameColumn';
import { lastNameColumn } from '../canSeeCandidateFile/candidateFilesList/columns/LastNameColumn';
import { avatarColumn } from '../canSeeCandidateFile/candidateFilesList/columns/AvatarColumn';

export const CandidateFilesListInitialColumns = (t, isMobile) => {
    let columns = [];

    if (isMobile) {
        columns = columns.concat([
            lastNameColumn,
            firstNameColumn,
            {
                header: '',
                name: 'cmd1',
                width: 200,
                height: 300,

                filterable: false,
                render: (c) => (
                    <Alert type="error" message={<Locale.Notification tkey="desktopOnly" />} />
                ),
            }]);
    } else {
        columns = columns.concat([
            { ...avatarColumn, defaultVisible: true, filterable: false },
            { ...lastNameColumn, filterable: false },
            { ...firstNameColumn, filterable: false },
            { ...emailColumn, filterable: false },
            {
                header: '',
                name: 'cmd1',
                id: 'cmd1',
                width: 200,
                visible: true,
                defaultVisible: true,
                filterable: false,
                render: (c) => <Refuse candidate={c} />,
            },
            {
                header: '',
                name: 'cmd2',
                id: 'cmd2',
                width: 200,
                visible: true,
                defaultVisible: true,
                filterable: false,
                render: (c) => <Accept candidate={c} />,

            },
        ]);
    }

    columns = columns.concat([
        {
            header: '',
            name: 'accepted',
            width: 170,

            filterable: false,
            render: (c) => {
                const review = getReview(c);
                return (
                    <Locale.Label tkey={review?.completed === true ? 'referent.review.completed'
                        : review?.accepted === true ? 'referent.review.accepted'
                            : review.accepted === false ? 'referent.review.refused'
                                : 'referent.review.unknown'}
                    />
                );
            },

        },

    ]);
    return columns;
};

export const CandidateFilesListInitialFilters = [];

function getReview(candidate) {
    // only on academicReferentReviews per referent
    return candidate.data?.referentReview;
}

function Refuse({ candidate }: any) {
    const { id } = candidate.data;
    const { t } = useSafeTranslation();
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const review = getReview(candidate);
    if (review.accepted === false) {
        return <Locale.Label tkey="referent.review.refused" />;
    }
    return (
        <Popconfirm
            title={t('referent.review.refuse.confirm.title')}
            disabled={review.accepted}
            onConfirm={async () => {
                await axios.put(`a-r-review/${review.id}/refuse`, {});
                sNavigateReload(REFERENT.REFUSE);
            }}
        >

            <TextDangerButton
                disabled={review.accepted}
                tkey="referent.review.refuse"
                key={id}
            />
        </Popconfirm>
    );
}

function Accept({ candidate }: any) {
    const { id } = candidate.data;
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const review = getReview(candidate);

    if (review.accepted === false) {
        return <Fragment>-</Fragment>;
    }
    return (
        <TextOkButton
            tkey={review.accepted ? 'referent.review.update' : 'referent.review.accept'}
            key={id}
            onClick={async () => {
                await axios.put(`a-r-review/${review.id}/accept`, {});
                setTimeout(() => sNavigate(REFERENT.ACCEPT
                    .replace(':academicReferentReviewId', review.id)
                    .replace(':candidateFileId', id)), 0);
            }}
        />
    );
}
