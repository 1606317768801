/** @jsx jsx */
import { UserOutlined } from '@ant-design/icons';
import { css, jsx } from '@emotion/core';
import Avatar from 'antd/lib/avatar/avatar';
import Card from 'antd/lib/card';
import Meta from 'antd/lib/card/Meta';
import React, { Fragment } from 'react';

export function ReferentCard(props: { referent: any }) {
  return (
    <Card
      style={{ width: 500 }}
      cover={<div css={css`background-color: #e6f7ff; display: block; height: 20px`} />}
      actions={[]}
    >
      <Meta
        avatar={(
          <Avatar
            style={{ backgroundColor: '#87d068', width: '50px', height: '50px' }}
            size={50}
            icon={<UserOutlined />}
          />
        )}
        title={`${props.referent.firstName} ${props.referent.lastName}`}
      />
      {' '}
      <Fragment>
        <div>
          {props.referent.email}
        </div>
        <div>
          {props.referent.jobTitle}
        </div>
        <div>
          {props.referent.school?.name}
        </div>
        <div>
          {props.referent.school?.city?.name} ({props.referent.school?.city?.zipCode})
        </div>
      </Fragment>
    </Card>
  );
}
