import { PlusOutlined } from '@ant-design/icons/lib';
import styled from '@emotion/styled';
import React, { HTMLAttributes } from 'react';
import { useConfig } from '../../../hooks/use-config.hook';
import { TextAddButton } from '../../button/TextAddButton';
import { FormListFieldData } from '../type';

// language=LESS prefix=*{ suffix=}
const Style = styled.div`
`;

export interface ItemListAddButtonProps extends HTMLAttributes<HTMLElement> {
  property: string
  add: Function
  items: FormListFieldData[]
  disabled?: boolean,
  ignoreMaxLimit?: boolean  // Permet de ne pas tenir compte de la limite MAX_xxx pour desactivation du bouton d'ajout
}

export const ItemListAddButton: React.FunctionComponent<ItemListAddButtonProps> = ({
  add, property, items, disabled, ignoreMaxLimit
}: ItemListAddButtonProps) => {
  const [config] = useConfig();
  return (
    <Style>
      {(items?.length < config.maxLimit(property) || ignoreMaxLimit)
        && (
          <TextAddButton
            tkey={`${property}.add`}
        /* ok */ disabled={disabled}
            onClick={() => {
              add();
            }}
            style={{ width: '100%' }}
          >
            <PlusOutlined />
            {' '}
          </TextAddButton>
        )}
    </Style>
  );
};
