import amplitude from 'amplitude-js';

export function initAmplitude() {
    amplitude.getInstance().init('871fd0cb51123706ef3271e3e979e694');
}

export function getEventServiceInstance():amplitude.AmplitudeClient {
    const instance = amplitude.getInstance();
    return instance;
}
