// @ts-ignore
import { UserRoleEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { sNavigate } from '../utils/safeNavigation';

function removeRoutesWildCard(r: string) {
    return r.replace('*', '');
}

export function getRoutesEntity(routesCtx) {
    const routesKeys = routesCtx?.writableRoutesPath
        .filter((k) => !k.endsWith('chat'))
        .filter((k) => k.indexOf('welcome/') !== -1);
    const res = routesKeys
        .map(removeRoutesWildCard)
        .map((r) => {
            const routeEntity = r.split('/')[r.split('/').length - 1];
            return routeEntity;
        });
    return res;
}

export function getWritableRoutes(routesKeys, rights, role) {
    const res = routesKeys
        .map(removeRoutesWildCard)
        .filter((k) => !k.endsWith('chat'))
        .filter((r) => {
            const routeEntity = r.split('/')[r.split('/').length - 1];
            if (routeEntity && rights) {
                return rights[routeEntity]?.write?.indexOf?.(role) !== -1;
            }
            return false;
        });
    return res;
}

/**
 * Allow to navigate between project entity
 */
export function useNavigateEntity(routesCtx, rights) {
    const routesKeys = routesCtx?.writableRoutesPath;
    /*
          Find first and last path depending on the writtable one
      */
    const writableRoutes = getWritableRoutes(routesKeys, rights, UserRoleEnum.CANDIDATE);

    function getWritableIndex(path) {
        return writableRoutes.findIndex((r) => removeRoutesWildCard(r).startsWith(removeRoutesWildCard(path)));
    }

    function isWritable(path) {
        return getWritableIndex(path) > 0;
    }

    function hasPrevious(path) {
        const currentIndex = getWritableIndex(path);
        return currentIndex >= 1;
    }

    function hasNext(path) {
        const currentIndex = getWritableIndex(path);
        return currentIndex > -1 && currentIndex < writableRoutes.length - 1;
    }

    function navigateNext(path) {
        const currentIndex = getWritableIndex(path);
        const next = writableRoutes[currentIndex + 1];
        sNavigate(next || path);
    }

    function navigatePrevious(path) {
        const currentIndex = getWritableIndex(path);
        const prev = writableRoutes[currentIndex - 1];
        sNavigate(prev || path);
    }

    return {
        hasPrevious, hasNext, navigatePrevious, navigateNext, getRoutesEntity, isWritable,
    };
}
