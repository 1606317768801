import React from 'react';
import { ROUTES } from '../../user/UserRoutesDef';

// NOT USE FOR THE MOMENT
import { SpecializedSecretaryContainer } from './SpecializedSecretaryContainer';

export const SpecializedSecretaryRoutesDef = () => ({
    [`${ROUTES.SPECIALIZED_SECRETARY}*`]: () => (
      <SpecializedSecretaryContainer />
    ),
    [`${ROUTES.BASE}`]: () => <SpecializedSecretaryContainer />,
});
