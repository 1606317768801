import { Button, Result } from 'antd';
import React from 'react';
import { sNavigateReload } from '../../../utils/safeNavigation';

function ResultDetails(props: { details: string[] }) {
  const listItems = props.details?.map((detail) => (
    <div>
      {detail}
      <br />
    </div>
  ));
  return (<div>{listItems}</div>);
}

export function ResultPanel(props: { error: string, result: any, redirect?: string, details?: string[] }) {
  if (props.result !== undefined) {
    return (
      <Result status="success">
        {
          props.result
        }
        <p />
        <br />
        <ResultDetails details={props.details} />
        <br />
        {props.redirect
          ? (
            <Button
              type="primary"
              onClick={async () => {
                sNavigateReload(props.redirect);
              }}
            >
              Recharger la liste
            </Button>
          ) : ''}
      </Result>
    );
  }
  if (props.error !== undefined) {
    return (
      <Result status="error">
        {
          props.error
        }
        <p />
      </Result>
    );
  }
  return (<div />);
}
