/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import {
    IUser,
    IVersionedBaseStringId,
    IVersionedBaseWithSecretaryReview,
    RightsUtils,
    walkEntityPropertyAndMapDateStrAsMoment,
} from '@fstn/ecandidaturev2_api-interfaces';
import { FormInstance } from 'antd/lib/form';
import { FormLayout, useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import merge from 'deepmerge';
import _ from 'lodash';
import { FieldData } from 'rc-field-form/lib/interface';
import React, { useContext } from 'react';
import { DeepPartial } from 'redux';
import traverse from 'traverse';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';

import { UserContext } from '../../../context/user.context';
import { ValidationsContext } from '../../../context/validations.context';
import { useEntity } from '../../../hooks/use-entity';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { useValidation } from '../../../hooks/use-validation.hook';
import { deepCloneIdForSourceChanges } from '../../../utils/deepCloneIdForSourceChanges';
import { EntityForm } from './EntityForm';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { SmartLoading } from '../../indicator/SmartLoading';
import { useDebouncedCallWithQueuedParams } from '../../../hooks/use-debounced-call-with-queued-params';

/**
 * Use to do actions before on after validation
 */
export interface Listeners<T extends DeepPartial<IUser> | DeepPartial<IVersionedBaseStringId> | DeepPartial<IVersionedBaseWithSecretaryReview>> {
    onBeforeValidate?: (values: Store, differences: Partial<T>, setFieldsValue: (values: any) => void, oldData: Partial<T>) => any;
    onAfterValidate?: (values: FieldData[]) => void;
    onAfterUpdate?: (differences: Partial<T>, oldData: Partial<T>) => void;
}

/**
 * LAURENT
 * Formulaire utilisé dans toute l'application
 * Utilise la lib: https://ant.design/components/form/
 * @param entity entity nom du endpoint à appeler par exemple ici locales => /api/locales => apps/back/src/locale/locale.controller.ts
 * @param children comprend les EntityFormItemContainer à afficher
 * @param onFinish methode appeler à la soumission du formulaire si il y a un bouton avec htmlType="submit" ou si on appel form.submit()
 * Trés peu utilisé car dans la majorité des cas on sauvegarde on blur
 * @param listeners Use to do actions before on after validation
 * @param layout indique si le form doit être horizontal ou vertical https://ant.design/components/form/#components-form-demo-layout
 * @param initialValues valeurs initiales du formulaire
 * @constructor
 */
export function EntityFormContainer<T>(props: {
    entity?: string,
    children: any,
    onFinish?: (values: T) => void,
    listeners?: Listeners<T>,
    layout?: FormLayout,
    initialValues?: T | any
    noValidate?: boolean,
    noSave?: boolean,
    warningOnly?: boolean,
    ignoreUpdateInitialValues?: boolean
}) {
    // console.log('Redraw EntityFormContainer', props);
    const [form]: [FormInstance<T>] = useForm();
    const responsive = useResponsiveForm(props);
    const { canEditValidation } = useContext(UserContext);
    const { patchEntity } = useEntity<T>();
    const withUpdateValidations = useScopedSelector('EntityFormContainer', ValidationsContext, (c) => c.withUpdateValidations);
    const steps = useScopedSelector('EntityFormContainer', ValidationsContext, (c) => c.steps);

    const { validate } = useValidation();
    const [state, updateState] = useImmer({
        doNextChangeImmediately: false,
        initialValues: props.initialValues,
    });
    // console.log('Redraw EntityFormContainer', props);
    useDeepCompareEffect(() => {
        if (!_.isEqual(props.initialValues, state.initialValues)) {
            updateState((draft) => {
                draft.initialValues = props.initialValues;
            });
        }
    }, [props.initialValues]);

    const onChange = async (changes) => {
        if (Object.keys(changes).length === 0) {
            return undefined;
        }
        return saveChanged(changes);
    };

    const {
        queueChangesToDebounced,
        immediateOnChange,
        debouncedOnChange,
    } = useDebouncedCallWithQueuedParams(props.entity, onChange, 100);

    async function saveChanged(changedValues: Partial<T> | any) {
        let patchedData = _.cloneDeep(changedValues);
        const patchedDataOri = patchedData;
        const { initialValues } = state;
        if (props?.listeners?.onBeforeValidate) {
            patchedData = await props?.listeners?.onBeforeValidate?.(form.getFieldsValue(), patchedData, form.setFieldsValue, initialValues);
        }

        let allValues = form.getFieldsValue();

        // bug je ne sais pas pouruqoi on se retrouve avec un motivationLetter= undefined quand on supprime la premiere formation
        /* if ((allValues as any).programLines) {
            (allValues as any).programLines = (allValues as any).programLines.filter((p) => Object.keys(p).length > 1);
        }
        form.setFieldsValue(allValues as any); */

        if (canEditValidation()) {
            patchedData = RightsUtils.applyEntitySecretaryStatusIntoRights(initialValues, patchedData, allValues);
        }
        // console.log('deepCloneIdForSourceChanges', patchedData, allValues);
        deepCloneIdForSourceChanges(patchedData, allValues);
        // motivationLetter= undefined if remove first programLine
        // console.log('deepCloneIdForSourceChanges a', patchedData, allValues);
        let data = allValues;
        if (!props.noSave) {
            data = await patchEntity(props?.entity, allValues as any, patchedData);
            if (data) {
                // for performance reason result is not complete, so we need to merge it with previous result
                // should remove all rights from value when switching in edit mode to allow editing
                allValues = traverse(allValues).map(
                    // eslint-disable-next-line array-callback-return,func-names
                    function (f) {
                        if (!_.get(data,
                            // eslint-disable-next-line react/no-this-in-sfc
                            this.path)) {
                            return undefined;
                        }
                        // eslint-disable-next-line react/no-this-in-sfc
                        if (!this.key || this.key === 'rights' || !this.node) {
                            try {
                                // eslint-disable-next-line react/no-this-in-sfc
                                this.remove();
                            } catch (e) {
                                // ignore
                            }
                        }
                        return undefined;
                    },
                );
                const overwriteMerge = (target, source, options) => {
                    const destination = target.slice();

                    source.forEach((item, index) => {
                        const indexBasedOnId = destination.findIndex((d) => typeof d === 'object' && d?.id === item?.id);
                        if (indexBasedOnId !== -1) {
                            index = indexBasedOnId;
                        }
                        if (typeof destination[index] === 'undefined') {
                            destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
                        } else if (options.isMergeableObject(item)) {
                            destination[index] = _.merge(target[index], item, options);
                        } else if (target.indexOf(item) === -1) {
                            destination.push(item);
                        }
                    });
                    return destination;
                };
                // data = _.merge(data, allValues);
                /*
                 Il faudrait ici utiliser le merge by id mais le merge by id supprime les méthodes des dates moment.... */
                data = merge(data, allValues,
                    { arrayMerge: overwriteMerge });

                walkEntityPropertyAndMapDateStrAsMoment(data);
                // data = _.merge(data, allValues);
                if (!props.ignoreUpdateInitialValues) {
                    // console.log('update state', state.initialValues as any, data as any);
                    updateState((draft: any) => {
                        draft.initialValues = data;
                    });
                }
            }
        } else {
            data = _.merge(allValues, patchedData);
        }
        /** DO  NOT REMOVE THAT
         allow the form vlue to be already updated,
         without that it will fo to readOnlyBetween 2 update because it going to send old value to the save
         * */

        // let values = form.getFieldsValue();
        // values = { ...values, rights: RightsUtils.toEdit((values as any).rights, UserRoleEnum.SECRETARY) };
        // console.log('update state', form.getFieldsValue() as any, data as any);
        // form.setFieldsValue(values as any);
        if (!props.noValidate) {
            await validate(props.entity, data, withUpdateValidations, steps, props.listeners);
        }

        if (props?.listeners?.onAfterUpdate) {
            props?.listeners?.onAfterUpdate?.(patchedDataOri, initialValues);
        }
    }

    /**
     * Debounced on change and accumulate changes to do only one request to the server
     * @param changedValues
     */
    async function smartDebouncedOnChange(changedValues) {
        // startDebounced();

        queueChangesToDebounced(changedValues);
        if (state.doNextChangeImmediately) {
            await immediateOnChange();
            updateState((draft) => {
                draft.doNextChangeImmediately = false;
            });
        } else {
            await debouncedOnChange();
        }
        // await onChange(changedValues);
        updateState((draft) => {
            draft.doNextChangeImmediately = false;
        });
    }

    /**
     * Do immediate action
     * @param changedValues
     */
    async function smartImmediateOnChange(changedValues) {
        /* const backupChanges = deQueueChangesToDebounced();
        if (changedValues) {
            queueChangesToDebounced(changedValues);
        }
        // Try to set saved changes to the change method after the switch to edit mode
        setTimeout(() => queueChangesToDebounced(backupChanges), 500); */

        return onChange(changedValues);
        // return immediateOnChange();
    }

    // @ts-ignore
    return (
        <SmartLoading loading={!state.initialValues || !state.initialValues.rights} context="EntityForm">
            <EntityForm
                css={css`/*Mask validation check*/     
            
            .ant-form-item-children-icon{    
                display:${props.noValidate ? 'none' : ''}
            `}
                className={props.entity}
                initialValues={state.initialValues}
                responsive={responsive}
                layout={props.layout}
                onValuesChange={smartDebouncedOnChange}
                onFinish={props.onFinish}
                form={form}
                entity={props?.entity}
                warningOnly={props?.warningOnly as any /* will be ok for 4.17 */}
                cancelCurrentChanges={async () => { }/* smartDebouncedOnChange */}
                listeners={props.listeners}
                onImmediateChange={smartImmediateOnChange}
                remove={() => {
                    updateState((draft) => {
                        draft.doNextChangeImmediately = true;
                    });
                }}
                noValidate={props.noValidate}
            >
                {props?.children}
            </EntityForm>
        </SmartLoading>
    );
}
