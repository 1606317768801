import { IStayAbroad, IStaysAbroadList } from '@fstn/ecandidaturev2_api-interfaces';
import { FormLayout } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { Box, Flex } from 'rebass';
import { DeepPartial } from 'redux';
import { useImmer } from 'use-immer';
import { EntityFormContainer } from '../../../common/form/entityForm/EntityFormContainer';
import { EntityFormListContainer } from '../../../common/form/entityForm/EntityFormListContainer';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { FormStyle } from '../../../common/form/FormStyle';
import { SecretaryReviewFormFooterContainer } from '../../../common/form/secretaryReviewForm/SecretaryReviewFormFooterContainer';
import { WithSwitchToReadOnlyOnDisplayForSecretary } from '../../../common/withExtraParameters/WithSwitchToReadOnlyOnDisplayForSecretary';
import { WithInitialLoad } from '../../../common/withExtraParameters/WithInitialLoad';
import { YesNoFormItem } from '../../../common/form/inputFormItems/YesNoFormItems';
import { EntityFormListContext } from '../../../context/entityFormList.context';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { IsSecretary } from '../../user/conditionnal/IsSecretary';
import { StayAbroadForm } from './StayAbroadForm';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { DisabledContextProviderForEntityForm } from '../../../common/rights/DisabledContextProviderForEntityForm';
import { useEntity } from '../../../hooks/use-entity';
import { StayAbroadModal } from './StayAbroadModal';

const entity = 'stays-abroad-list';

export function StaysAbroadListEntityForm() {
    const { deleteEntity } = useEntity();
    const haveStay = useScopedSelector<any[], boolean>('StaysAbroadListEntityForm', EntityFormListContext, (c) => c[0]?.entityValue?.haveStay);
    const isStage = useScopedSelector<any[], boolean>('StaysAbroadListEntityForm', EntityFormListContext, (c) => c[0]?.entityValue?.isStage);

    const responsive = useResponsiveForm();

    // console.log('Redraw StaysAbroadListEntityForm');
    const [state, updateState] = useImmer({
        showAdd: false,
        add: undefined,
    });

    async function onAdd(academic: any) {
        updateState((draft) => {
            draft.showAdd = false;
        });
        state.add(academic);
    }

    async function onCancel() {
        updateState((draft) => {
            draft.showAdd = false;
        });
    }

    return (
      <Flex flexDirection="column">
        <StayAbroadModal
          onAdd={onAdd}
          onCancel={onCancel}
          showIdiom={state.showAdd}
        />
        <Box {...responsive.w1}>
          <FormContentPanelContainer partName={entity}>
            <YesNoFormItem labelCol={{ span: 18 }} wrapperCol={{ span: 4 }} propertyName="haveStay" />
            <YesNoFormItem labelCol={{ span: 18 }} wrapperCol={{ span: 4 }} propertyName="isStage" />
          </FormContentPanelContainer>
          {((haveStay === true || haveStay === false)
                    && (isStage === true || isStage === false)
                    && (isStage || haveStay)
                )
                && (
                <EntityFormListContainer
                  propertyName="staysAbroad"
                  entity="stayAbroad"
                  addDecorator={async (add) => {
                                             updateState((draft) => {
                                                 draft.showAdd = true;
                                                 draft.add = add;
                                             });
                                         }}
                  removeDecorator={async (remove, value: IStayAbroad) => {
                                             if (value.stayAbroadDocument) {
                                                 await deleteEntity('document', value.stayAbroadDocument);
                                             }
                                             remove();
                                         }}
                >
                  <StayAbroadForm />
                </EntityFormListContainer>
                )}
        </Box>
      </Flex>
    );
}

export function StaysAbroadListForm(props:{ layout?: FormLayout }) {
    // console.log('Redraw StaysAbroadListForm');
    const { deleteEntity } = useEntity();

    const onBeforeValidate = async (values: Store, differences: DeepPartial<IStaysAbroadList>, setFieldsValue: Function) => {
        if (differences?.secretaryReview) {
            differences = { secretaryReview: differences.secretaryReview, rights: differences.rights };
        } else if (differences?.haveStay === false) {
            const staysAbroad = [];
            // should force to null to delete the stay and the related document, if not set to null, object is still present in database
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            if (Array.isArray(values.staysAbroad)) {
                for (const stayAbroad of values.staysAbroad) {
                    if (stayAbroad && stayAbroad.stayAbroadDocument) {
                        await deleteEntity('document', stayAbroad.stayAbroadDocument);
                    }
                    staysAbroad.push(null);
                }
            }
            differences.staysAbroad = staysAbroad;
            setFieldsValue({ values, staysAbroad });
        }
        return differences;
    };

    return (
      <FormStyle>
        <WithInitialLoad entity={entity}>
          <WithSwitchToReadOnlyOnDisplayForSecretary>
            <EntityFormContainer listeners={{ onBeforeValidate }} layout={props.layout}>
              <DisabledContextProviderForEntityForm>
                <StaysAbroadListEntityForm />
                <IsSecretary>
                  <SecretaryReviewFormFooterContainer propertyName="secretaryReview" />
                </IsSecretary>
              </DisabledContextProviderForEntityForm>
            </EntityFormContainer>
          </WithSwitchToReadOnlyOnDisplayForSecretary>
        </WithInitialLoad>
      </FormStyle>
    );
}
