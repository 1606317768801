/** @jsx jsx */
import { LeftOutlined } from '@ant-design/icons';
import { css, jsx } from '@emotion/core';
// @ts-ignore
import { usePath } from 'hookrouter';
import { Fragment, useContext } from 'react';

import { IRights } from '@fstn/ecandidaturev2_api-interfaces';
import { IhmContext } from '../../context/ihm.context';
import { RightsContext, RightsContextType } from '../../context/rights.context';
import { RoutesContext } from '../../context/routes.context';
import { ValidationsContext } from '../../context/validations.context';
import { useNavigateEntity } from '../../hooks/use-navigate-entity';
import { useValidation } from '../../hooks/use-validation.hook';
import { getCurrentEntity } from '../../utils/pathUtils';
import { TextOkButton } from '../button/TextOkButton';
import { TextWarningButton } from '../button/TextWarningButton';
import { ResponsiveButton } from '../input/ResponsiveButton';
import { useScopedSelector } from '../../hooks/use-scoped-selector';

/**
 * Next entity button
 * @constructor
 */
export function NextEntity() {
    const path = usePath();
    const { routesCtx } = useContext(RoutesContext);
    const rights = useScopedSelector<RightsContextType, IRights>('NextEntity', RightsContext, (c) => c.rightsCtxState.rights);

    const { hasNext, hasPrevious, navigateNext } = useNavigateEntity(routesCtx, rights);
    const { isNavigationAllowed } = useContext(IhmContext);
    const { isEntityValidForStep } = useValidation();
    const entity = getCurrentEntity(path);

    const isValid = useScopedSelector('NextEntity', ValidationsContext, (c) => isEntityValidForStep(c, 'SUBMIT', entity));

    return (
      <Fragment>
        {hasNext(path)
            && isValid && (
            <TextOkButton
              css={css`zoom:1.2`}
              data-testid="navigateNext"
              /* ok */disabled={!isNavigationAllowed()}
              tkey={hasPrevious(path) ? 'navigation.next' : 'navigation.start'}
              onClick={() => navigateNext(path)}
            />
        )}
        {hasNext(path)
          && !isValid && (
          <TextWarningButton
            css={css`zoom:1.2`}
            data-testid="navigateNext"
            /* ok */disabled={!isNavigationAllowed()}
            tkey="navigation.force.next"
            onClick={() => navigateNext(path)}
          />
          )}
      </Fragment>
    );
}

/**
 * Previous entity button
 * @constructor
 */
export function PreviousEntity() {
    const path = usePath();
    const { routesCtx } = useContext(RoutesContext);
    const { isNavigationAllowed } = useContext(IhmContext);
    const rights = useScopedSelector<RightsContextType, IRights>('PreviousEntity', RightsContext, (c) => c.rightsCtxState.rights);

    const { hasPrevious, navigatePrevious } = useNavigateEntity(routesCtx, rights);
    return (
      <Fragment>
        {hasPrevious(path)
            && (
            <ResponsiveButton
              /* ok */disabled={!isNavigationAllowed()}
              data-testid="navigatePrevious"
              icon={<LeftOutlined />}
              onClick={() => navigatePrevious(path)}
            />
)}
      </Fragment>
    );
}
