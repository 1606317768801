import { BirthdateUtils } from '@fstn/ecandidaturev2_api-interfaces';
import { View } from '@react-pdf/renderer';
import React, { Fragment } from 'react';
import { PDFCard } from '../../../common/pdf/components/PDFCard';
import { PDFCardGutter } from '../../../common/pdf/components/PDFCardGutter';
import { PDFH1 } from '../../../common/pdf/components/PDFH1';
import { PDFText } from '../../../common/pdf/components/PDFText';
import { PDFTextGutter } from '../../../common/pdf/components/PDFTextGutter';
import { Profile } from './export.type';
import moment from 'moment';

export function ProfileFormPDF(props: {
  profile: Profile,
  startSchool?: Date
}) {
  if (!props.profile) {
    return <PDFH1>Motivations</PDFH1>;
  }
  return (
    <Fragment>
      <PDFCardGutter>
        {/* <PDFCard
          bordered
          title={(
            <View>
              <PDFText>
                AGE AU
                (
                {moment(config?.date('START_SCHOOL')).format('MM/DD/YYYY')}
                )
              </PDFText>
            </View>
)}
          <PDFText>
            {' '}
            {BirthdateUtils.getAgeAt(config?.date('START_SCHOOL') || new Date(), new Date(Date.parse(profile.birthDate as any)))}
            {' '}
            ans
          </PDFText>
        </PDFCard>
        > */}
        <View style={{ width: 163 }}>
          <PDFCard
            bordered
            title={<PDFTextGutter><PDFText color="white">Date de naissance</PDFText></PDFTextGutter>}
          >
            <PDFText>
              {props.profile.birthDate}
              {' ('}
              {
                Math.floor(BirthdateUtils.getAgeAt(props.startSchool || new Date(), moment(props.profile.birthDate, "DD/MM/YYYY").toDate()))}
              {` ans au ${props.startSchool?.toLocaleDateString() || new Date().toLocaleDateString()})`}
            </PDFText>
          </PDFCard>
        </View>
        <View style={{ width: 123 }}>
          <PDFCard
            bordered
            title={<PDFTextGutter><PDFText color="white">Nationalité</PDFText></PDFTextGutter>}
          >
            <PDFText>{props.profile.nationality?.id}</PDFText>
          </PDFCard>
        </View>
        <View style={{ width: 123 }}>
          <PDFCard bordered title={<PDFTextGutter><PDFText color="white">Téléphone</PDFText></PDFTextGutter>}>
            <PDFText>{props.profile.phone?.replace('@@', '')}</PDFText>
          </PDFCard>
        </View>
        <View style={{ width: 123 }}>
          <PDFCard
            bordered
            title={<PDFTextGutter><PDFText color="white">Aménagement</PDFText></PDFTextGutter>}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            <PDFText>{props.profile.thirdPartyTime === true ? 'Oui' : (props.profile.thirdPartyTime === false ? 'Non' : '')}</PDFText>
          </PDFCard>
        </View>
      </PDFCardGutter>
      <PDFTextGutter>
        <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
        <View style={{ width: "95%" }}>
          <PDFText>Adresse postale : {props.profile?.address} {props.profile?.zipCode} {props.profile?.city},  {props.profile?.country?.id}</PDFText>
        </View>
      </PDFTextGutter>
    </Fragment>
  );
}
