// @ts-ignore
import { navigate } from 'hookrouter';

export function sNavigate(url, replace = false, queryParams = {}) {
    return setTimeout(() => navigate(url, replace, queryParams), 500);
}

export function sNavigateReload(url, replace = false, queryParams = {}) {
    if (Object.keys(queryParams).length > 0) {
        url = `${url}?`;
    }
    return setTimeout(() => { window.location.href = `${url}${Object.keys(queryParams).map((k) => `${k}=${queryParams[k]}`).join('&')}`; }, 0);
}
