/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Card, Divider, Form, Space, } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Fragment, useContext } from 'react';
import { ResponsiveInput } from '../../common/input/ResponsiveInput';
import { AxiosContext, AxiosContextType } from '../../context/axios.context';
import { UserContext, UserContextType } from '../../context/user.context';
import { useUser } from '../../hooks/use-user.hook';

export function AdminImpersonate() {
  const userCtx = useContext<UserContextType>(UserContext);
  const { loadMe } = useUser(userCtx);
  const { axios, updateToken } = useContext<AxiosContextType>(AxiosContext);

  async function onFinish(values) {
    const res = await axios.post(`administration/impersonate/${values.email}`, {});
    const accessToken = res.data.access_token;
    await updateToken(accessToken);
    await loadMe(accessToken);
    if (res.data.payload.role.name === 'CANDIDATE') {
      window.location.href = '/home/welcome/candidate';
    } else if (res.data.payload.role.name === 'EXAMINER') {
      window.location.href = '/home/welcome/examiner';
    } else if (res.data.payload.role.name === 'PROGRAM_MANAGER') {
      window.location.href = '/home/welcome/program-manager';
    } else if (res.data.payload.role.name === 'SPECIALIZED_SECRETARY') {
      window.location.href = '/home/welcome/specialized-secretary';
    } else {
      window.location.href = '/home/welcome';
    }
  }

  return (
    <Fragment>
      <Title level={2}>Reconnexion avec un autre compte utilisateur</Title>
      <Divider />
      <Space direction="vertical">
        <Card title="Instructions" style={{ width: 800 }}>
          <p>
            Il suffit de saisir l'email d'un utilisateur existant dans l'application, quel que soit son
            rôle,
            pour se connecter avec son compte sans avoir à saisir de mot de passe.
          </p>
          <p>
            <br />
            <strong><em>Note :</em></strong>
            {' '}
            La connexion actuelle en tant qu'administrateur sera perdue.
          </p>

        </Card>
        <br />
        <Form
          name="impersonate"
          onFinish={onFinish}
          wrapperCol={{
            span: 8,
          }}
        >
          <Form.Item
            name="email"
            label="Email de l'utilisateur avec lequel se reconnecter"
            rules={[
              {
                required: true,
                message: 'Email obligatoire !',
              }, { type: 'email' },
            ]}
          >
            <ResponsiveInput data-testid="referent-email" type="email" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Reconnexion
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </Fragment>
  );
}
