import React from 'react';
import {FixRadioFormItem} from '../../../common/form/inputFormItems/FixRadioFormItem';
import * as Locale from '../../../common/locale';

export function FinalReview(props: { propertyName: any, onChange ?: any }) {
    return (
      <FixRadioFormItem
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        onChange={props.onChange}
        propertyName={props.propertyName}
        vertical={true}
        options={[
                {
                    value: null,
                    label: <Locale.Label tkey="undefined" />,
                },
                {
                    value: 'veryFavorable',
                    label: <Locale.Label tkey="veryFavorable" />,
                },
                { value: 'favorable', label: <Locale.Label tkey="favorable" /> },
                {
                    value: 'fairlyFavorable',
                    label: <Locale.Label tkey="fairlyFavorable" />,
                },
                { value: 'neutral', label: <Locale.Label tkey="neutral" /> },
                { value: 'reserved', label: <Locale.Label tkey="reserved" /> },
                { value: 'unfavorable', label: <Locale.Label tkey="unfavorable" /> },
                { value: 'unexisting', label: <Locale.Label tkey="unexisting" /> },
                { value: 'notExplicit', label: <Locale.Label tkey="notExplicit" /> },
                { value: 'multiple', label: <Locale.Label tkey="multiple" /> }
            ]}
      />
    );
}
