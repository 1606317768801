/** @jsx jsx */
import {jsx} from '@emotion/core';
import styled from '@emotion/styled';
import {Card} from 'antd';
import React from 'react';
import {CardSection} from '../../../common/layout/CardSection';
import * as Locale from '../../../common/locale';
import {PaymentHistory} from './PaymentHistory';

const Style = styled.div`
`;

export function PaymentCompleted() {
    return (
      <Style>
        <Card>
          <CardSection>
            <Locale.Content tkey="payment.completed" />
          </CardSection>
          <CardSection>
            <PaymentHistory />
          </CardSection>
          <CardSection>
            <Locale.Content tkey="payment.completed.bottom" />
          </CardSection>
        </Card>
      </Style>
    );
}
