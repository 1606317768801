/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/core';
import { TextButton } from './TextButton';

/**
 * Simple primary Button
 * @param tkey
 * @param props
 * @constructor
 */
export function TextPrimaryButton({ tkey, ...props }:any) {
    return <TextButton {...props} tkey={tkey} type="primary" />;
}
