/** @jsx jsx */
import { jsx } from '@emotion/core';
import { DocumentUtils, IDocument } from '@fstn/ecandidaturev2_api-interfaces';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import { FullDiv } from '../../../common/layout/FullDiv';
import { InlineImageViewer } from '../../../common/image/InlineImageViewer';
import { MemoizedPDFViewer } from '../../../common/pdf/MemoizedPDFViewer';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import './DocumentViewer.css';
import { StrictLoading } from '../../../common/indicator/SmartLoading';

export function DocumentViewer({ document }: { document: IDocument }) {
  const { token } = useContext<AxiosContextType>(AxiosContext);
  const _documentLink = DocumentUtils.getPreviewLink(document, token);
  const [state, updateState] = useImmer<any>({ type: 'img' });
  const [documentLink, setDocumentLink] = useState('');

  const type = DocumentUtils.getType(document);
  useEffect(() => {
    updateState((draft) => {
      draft.type = type;
    });
  }, [type, document.originalName, updateState]);
  useEffect(() => {
    setDocumentLink('');
    // need to force the refresh of the document to reset the page at 0
    setTimeout(() => { setDocumentLink(_documentLink); });
  }, [_documentLink]);
  // loop add type

  return (
    <StrictLoading loading={!document || !state.type || !documentLink}>
      <Fragment>
        {documentLink
          && (
            <FullDiv onClick={(e) => e.stopPropagation()}>
              <FullDiv>
                {state.type?.toLowerCase() === 'pdf'
                  && (
                    <MemoizedPDFViewer
                      url={documentLink}
                      documentId={document.id}
                      rotationAngle={document.rotationAngle}
                      documentName={`${document.parentType}:${document.name}`}
                    />
                  )}
                {state?.type?.toLowerCase() !== 'pdf'
                  && <InlineImageViewer url={documentLink} documentId={document.id} />}
              </FullDiv>
            </FullDiv>
          )}
      </Fragment>
    </StrictLoading>
  );
}
