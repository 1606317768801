import React from 'react';
import {ROUTES} from '../../user/UserRoutesDef';

// NOT USE FOR THE MOMENT
import {ExaminerContainer} from './ExaminerContainer';

export const ExaminerRoutesDef = () => ({
    [`${ROUTES.EXAMINER_BASE}*`]: () => (
      <ExaminerContainer />
    ),
    [`${ROUTES.BASE}*`]: () => <ExaminerContainer />,
});
