import {
    ICandidate,
    IJury,
    ISecretary,
    ISpecializedSecretary,
    IUser,
    SUPER_SECRETARY_RIGHTS,
    UserRoleEnum,
} from '@fstn/ecandidaturev2_api-interfaces';
import React, { useCallback } from 'react';
import _ from 'lodash';
import { useImmer } from 'use-immer';

/**
 *  LAURENT
 *  Use the React context API to stock user Context
 *  https://fr.reactjs.org/docs/context.html
 */

export type UserContextTypeContent = { user?: IUser & ICandidate & ISecretary & IJury & ISpecializedSecretary, token?: string };
export type UserContextType = {
    userCtx?: UserContextTypeContent,
    updateUserCtx?: any,
    canSeeValidation?: () => boolean,
    canChangeProgram?: () => boolean,
    canEditValidation?: () => boolean,
    canSuperEditValidation: () => boolean,
    isCandidate?: () => boolean,
    isExaminer?: () => boolean,
    isSpecializedSecretary?: () => boolean,
    reset: () => void
};

export const UserContext = React.createContext({} as UserContextType);
export const UserContextProvider = (props: { children: any, user?: IUser | ISecretary }) => {
    const [userCtx, updateUserCtx] = useImmer<UserContextTypeContent>({ user: props.user as any });

    function canSeeValidation(): boolean {
        return userCtx.user?.role?.name === UserRoleEnum.SECRETARY
            || userCtx.user?.role?.name === UserRoleEnum.SPECIALIZED_SECRETARY
            || userCtx.user?.role?.name === UserRoleEnum.EXAMINER;
    }

    function canEditValidation(): boolean {
        return userCtx.user?.role?.name === UserRoleEnum.SECRETARY;
    }

    function canSuperEditValidation(): boolean {
        return userCtx.user?.rightsOverriding && _.isEqual(userCtx.user?.rightsOverriding, SUPER_SECRETARY_RIGHTS);
    }

    function canChangeProgram(): boolean {
        return userCtx.user?.role?.name === UserRoleEnum.CANDIDATE;
    }

    function isCandidate(): boolean {
        return userCtx.user?.role?.name === UserRoleEnum.CANDIDATE;
    }

    function isExaminer(): boolean {
        return userCtx.user?.role?.name === UserRoleEnum.EXAMINER;
    }
    function isSpecializedSecretary(): boolean {
        return userCtx.user?.role?.name === UserRoleEnum.SPECIALIZED_SECRETARY;
    }

    const reset = useCallback(() => {
        localStorage.removeItem('user');
        updateUserCtx((draft) => ({}));
    }, [updateUserCtx]);
    return (
        <UserContext.Provider
            value={{
                userCtx, updateUserCtx, canSeeValidation, canChangeProgram, canEditValidation, canSuperEditValidation, isCandidate, isExaminer, isSpecializedSecretary, reset,
            }}
        >
            {props?.children}
        </UserContext.Provider>
    );
};
