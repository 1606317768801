/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';

const Style = styled.div`
.ant-form-item-control .ant-form-item-children-icon{
       display: none !important ;
    }
`;

export function CenterButtonsFormItemLayout(props: any) {
    return (
      <Style>
        <div
          css={
                    css`
    .ant-form-item-explain{
      display: none;
    }

    .ant-btn.ant-btn-primary{
      margin-right: 40px;
    }
    .ant-form-item-control-input-content{
    width: 100%;
    display: flex;
    justify-content: center;
}`
                }
        >
          {props.children}
        </div>
      </Style>
    );
}
