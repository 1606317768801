/** @jsx jsx */
import { jsx } from '@emotion/core';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import StringFilter from '@inovua/reactdatagrid-community/StringFilter';
import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import { Fragment, MutableRefObject } from 'react';

const ROLES = [
    { id: 'ADMIN', label: 'ADMIN' },
    { id: 'EXAMINER', label: 'EXAMINER' },
    { id: 'JURY', label: 'JURY' },
    { id: 'PROGRAM_MANAGER', label: 'PROGRAM_MANAGER' },
    { id: 'SECRETARY', label: 'SECRETARY' },
    { id: 'SPECIALIZED_SECRETARY', label: 'SPECIALIZED_SECRETARY' },
];

export const configsListInitialColumns = (t) => [
    {
        header: 'Rôle',
        name: 'role',
        type: 'string',
        defaultFlex: 1,

        editable: false,
        filterEditor: SelectFilter,
        filterEditorProps: {
            placeholder: 'Tous',
            dataSource: ROLES,
        },
        style: { textAlign: 'left' },

    },
    {
        header: 'Nom',
        name: 'lastName',
        type: 'string',
        defaultFlex: 1,

        editable: false,
        style: { textAlign: 'left' },

    },
    {
        header: 'Prénom',
        name: 'firstName',
        type: 'string',
        defaultFlex: 1,

        editable: false,
        style: { textAlign: 'left' },
        showInContextMenu: true,
        lockable: true,
    },
    {
        header: 'E-mail',
        name: 'email',
        className: 'email',
        'test-id': 'email',
        type: 'string',
        defaultFlex: 2,

        editable: false,
        style: { textAlign: 'left' },
        filterEditor: StringFilter,
        filterEditorProps: {
            className: 'emailFilter',
            'test-id': 'emailFilter',
        },

    },
    {
        header: 'Formation',
        name: 'programName',
        type: 'string',
        defaultFlex: 2,

        editable: false,
        style: { textAlign: 'left' },

    },
    {
        header: 'Option',
        name: 'optionName',
        type: 'string',
        defaultFlex: 1,

        editable: false,
        style: { textAlign: 'left' },

    },
];

export const configsListInitialFilters = [
    {
        name: 'role', operator: 'eq', type: 'select', value: null,
    },
    {
        name: 'lastName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'firstName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'email', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'programName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'optionName', operator: 'contains', type: 'string', value: '',
    },
];

export function configsListRenderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedConfig) {
    return (
      <Fragment />
    );
}

/**
 * Map result to row
 * @param d
 */
export function configsListMapToRow(d: any) {
    const row: any = {
        id: d.id,
        lastName: d.lastName,
        firstName: d.firstName,
        email: d.email,
        role: d.role,
        programName: d.programName,
        optionName: d.optionName,
    };
    // console.log(row)
    return row;
}
