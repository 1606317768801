/** @jsx jsx */
import {jsx} from '@emotion/core';
import * as Locale from '../../../../common/locale';

export const lastUpdatedByColumn = {
    header: <Locale.Header tkey="candidateFilesList.header.lastUpdatedBy" />,
    name: 'lastUpdatedBy',
    id: 'lastUpdatedBy',
    defaultWidth: 140,
    lockable: false,
    filterable: true,
};

export const lastUpdatedByFilter = {
    name: 'lastUpdatedBy', operator: 'startsWith', type: 'string', value: '',
};
