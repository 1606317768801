// noinspection JSUnusedLocalSymbols

/**
 * Normalize response before applying to on change
 * @param parentType
 * @param documentName
 */
export const normalize = (parentType, documentName) => (value, prevValue, newValue) => value;

/**
 * Return disabled or readonly props
 * @param props
 */
export function getDisabled(props: { disabled?: boolean, readOnly?: boolean }) {
    return props.disabled !== undefined ? props.disabled : props.readOnly;
}
