import styled from '@react-pdf/styled-components';
import React from 'react';

const Container = styled.View`
margin-bottom: 10px;
`;

export function PDFSpacer(props: { debug?: boolean }) {
    return (
      <Container debug={props.debug} />
    );
}
