import React from 'react';

export function CardImage() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        data-name="Layer 1"
        viewBox="0 0 567.17 517.31"
      >
        <path
          fill="#3f3d56"
          d="M326.035 307.389H821.0350000000001V352.389H326.035z"
          transform="rotate(-16.244 -255.39 1341.518)"
        />
        <path
          fill="#3f3d56"
          d="M386.4 575.97l-70.046-240.414a7 7 0 014.762-8.678l463.719-135.109a7.006 7.006 0 018.678 4.762l62.056 212.99-1.92.559-62.056-212.989a5.009 5.009 0 00-6.2-3.402L321.677 328.798a5 5 0 00-3.402 6.199L388.32 575.41z"
          transform="translate(-316.071 -191.488)"
        />
        <path
          fill="#6c63ff"
          d="M752.933 410.51a6.512 6.512 0 01-6.243-4.683l-11.749-40.323a6.507 6.507 0 014.422-8.059l40.324-11.748a6.507 6.507 0 018.058 4.422l11.749 40.324a6.507 6.507 0 01-4.422 8.058l-40.324 11.749a6.479 6.479 0 01-1.815.26zM385.318 336.3a11.691 11.691 0 00-.43-1.22 12 12 0 00-22.91 6.67 11.38 11.38 0 00.29 1.26 12.02 12.02 0 0011.53 8.64 11.75 11.75 0 003.35-.48 12.013 12.013 0 008.17-14.87zm-8.73 12.95a10.013 10.013 0 01-12.4-6.8 11.435 11.435 0 01-.28-1.26 9.997 9.997 0 0119.04-5.54 8.338 8.338 0 01.45 1.21 10.004 10.004 0 01-6.81 12.39z"
          transform="translate(-316.071 -191.488)"
        />
        <path
          fill="#6c63ff"
          d="M355.885 344.87a11.002 11.002 0 014.208-12.057 11 11 0 105.818 19.967 11.002 11.002 0 01-10.026-7.91z"
          transform="translate(-316.071 -191.488)"
        />
        <path
          fill="#ccc"
          d="M666.747 394.43a6.042 6.042 0 00-7.45-4.2l-66.39 18.57-7.15 2h44.9l7.15-2 24.75-6.92a6.049 6.049 0 004.19-7.45zM619.777 380.56a6.045 6.045 0 00-7.45-4.19l-99.62 27.86a6.068 6.068 0 00-4.37 6.57h25.79l7.16-2 74.3-20.78a6.055 6.055 0 004.19-7.46z"
          transform="translate(-316.071 -191.488)"
        />
        <path
          fill="#3f3d56"
          d="M876.241 408.798h-483a7.008 7.008 0 00-7 7v286a7.008 7.008 0 007 7h483a7.008 7.008 0 007-7v-286a7.008 7.008 0 00-7-7zm5 293a5.002 5.002 0 01-5 5h-483a5.002 5.002 0 01-5-5v-286a5.002 5.002 0 015-5h483a5.002 5.002 0 015 5z"
          transform="translate(-316.071 -191.488)"
        />
        <path
          fill="#6c63ff"
          d="M440.237 447.8a12 12 0 1112-12 12.014 12.014 0 01-12 12zm0-22a10 10 0 1010 10 10.011 10.011 0 00-10-10z"
          transform="translate(-316.071 -191.488)"
        />
        <path
          fill="#6c63ff"
          d="M421.585 435.8a11.002 11.002 0 017.413-10.399 11 11 0 100 20.798 11.002 11.002 0 01-7.413-10.399zM488.881 577.578a9.01 9.01 0 01-9-9v-27a9 9 0 0118 0v27a9.01 9.01 0 01-9 9zM512.881 577.578a9.01 9.01 0 01-9-9v-27a9 9 0 0118 0v27a9.01 9.01 0 01-9 9zM536.881 577.578a9.01 9.01 0 01-9-9v-27a9 9 0 0118 0v27a9.01 9.01 0 01-9 9zM573.881 577.578a9.01 9.01 0 01-9-9v-27a9 9 0 0118 0v27a9.01 9.01 0 01-9 9zM597.881 577.578a9.01 9.01 0 01-9-9v-27a9 9 0 0118 0v27a9.01 9.01 0 01-9 9zM621.881 577.578a9.01 9.01 0 01-9-9v-27a9 9 0 0118 0v27a9.01 9.01 0 01-9 9zM658.881 577.578a9.01 9.01 0 01-9-9v-27a9 9 0 0118 0v27a9.01 9.01 0 01-9 9zM682.881 577.578a9.01 9.01 0 01-9-9v-27a9 9 0 0118 0v27a9.01 9.01 0 01-9 9zM706.881 577.578a9.01 9.01 0 01-9-9v-27a9 9 0 0118 0v27a9.01 9.01 0 01-9 9zM743.881 577.578a9.01 9.01 0 01-9-9v-27a9 9 0 0118 0v27a9.01 9.01 0 01-9 9zM767.881 577.578a9.01 9.01 0 01-9-9v-27a9 9 0 0118 0v27a9.01 9.01 0 01-9 9zM791.881 577.578a9.01 9.01 0 01-9-9v-27a9 9 0 0118 0v27a9.01 9.01 0 01-9 9z"
          transform="translate(-316.071 -191.488)"
        />
        <path
          fill="#e6e6e6"
          d="M549.9 677.745H420.458a6.047 6.047 0 110-12.095h129.44a6.047 6.047 0 110 12.095zM508.4 651.745h-46.441a6.047 6.047 0 110-12.095h46.44a6.047 6.047 0 110 12.095z"
          transform="translate(-316.071 -191.488)"
        />
        <path fill="#3f3d56" d="M71.81 277.09H564.81V279.09H71.81z" />
      </svg>
    );
}
