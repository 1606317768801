export class QueryParamsUtils {
    static fromFilters(filters) {
        const params = filters.filter((f) => f.value !== undefined).reduce((o, f) => {
            const value = `${f.operator}||${JSON.stringify(f.value)}`;
            return { ...o, [f.name]: value };
        }, {});
        return params;
    }

    static toFilters(queryParams, filters) {
        const updatedFilters = JSON.parse(JSON.stringify(filters));
        for (const queryParamName of Object.keys(queryParams)) {
            updatedFilters.filter((f) => f.name === queryParamName).forEach((f) => {
                f.operator = queryParams[queryParamName].split('||')[0];
                const value = queryParams[queryParamName].split('||')[1];
                f.value = JSON.parse(value);
            });
        }
        return updatedFilters;
    }
}
