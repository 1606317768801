/** @jsx jsx */
import { HomeOutlined, MessageFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { CandidateFileStatusEnum, IRights } from '@fstn/ecandidaturev2_api-interfaces';
import { Badge } from 'antd';
import Steps from 'antd/lib/steps';
// @ts-ignore
import { usePath } from 'hookrouter';
import _ from 'lodash';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useContext } from 'react';
import { Flex } from 'rebass';

import { TextDangerButton } from '../../common/button/TextDangerButton';
import { TextNeutralButton } from '../../common/button/TextNeutralButton';
import { EntityFormSubmitButton } from '../../common/form/buttonsFormItem/EntityFormSubmitButton';
import { ResponsiveButton } from '../../common/input/ResponsiveButton';
import * as Locale from '../../common/locale';
import { MenuAndFormContainer, MenuAndFormFooterContainer } from '../../common/page/Containers';
import { NextEntity, PreviousEntity } from '../../common/page/Navigators';
import { RightsContext, RightsContextType } from '../../context/rights.context';
import { RoutesContext } from '../../context/routes.context';
import { useNavigateEntity } from '../../hooks/use-navigate-entity';
import { useSafeTranslation } from '../../hooks/use-safe-translation';
import { CommentsUtils } from '../../utils/CommentsUtils';
import { getCurrentEntity } from '../../utils/pathUtils';
import { sNavigate, sNavigateReload } from '../../utils/safeNavigation';
import { CandidateFileContext, CandidateFileContextProviderType } from '../canSeeCandidateFile/CandidateFileContext';
import { CenterAreaContainer } from '../canSeeDocuments/viewer/CenterAreaContainer';
import { LeftAreaContainer } from '../canSeeDocuments/viewer/LeftAreaContainer';
import { UserPage } from '../user/UserPage';
import { UserPageHeader } from '../user/UserPageHeader';
import { LOGIN, ROUTES } from '../user/UserRoutesDef';
import { CandidateLeftMenu, navigateTo } from './CandidateLeftMenu';
import { useScopedSelector } from '../../hooks/use-scoped-selector';
import { useIsSmallScreen } from '../../hooks/use-is-small-screen.hook';
import { IsDesktop } from '../user/conditionnal/IsDesktop';
import { IsPath } from '../user/conditionnal/IsPath';
import { IsMobile } from '../user/conditionnal/IsMobile';

const FooterStyle = styled.div`
grid-area: footer;
background-color: rgb(240, 240, 240);
padding-left: 1em;
padding-right: 1em;
`;

export function CandidatePageHeader() {
    const path = usePath();

    return (
      <Fragment>
        <UserPageHeader
          title={<Locale.Title tkey="candidate" />}
          subTitle={<Locale.Title tkey={`candidate.${_.last(path.split('/'))}`} />}
          extra={(
            <IsDesktop>
              <HeaderButtonBar />
            </IsDesktop>
                )}
        />
        <IsMobile>
          <div css={css`display: flex;flex-direction:column`}>
            <HeaderButtonBar />
          </div>
        </IsMobile>
      </Fragment>
    );
}

function HeaderButtonBar() {
    const { t } = useSafeTranslation();
    return (
      <div css={css`display: flex;align-items: center; height: 100%;`}>
        <a href={t('faq.url')} target="_blank" rel="noopener noreferrer">
          <TextNeutralButton
            css={css`width:100%`}
            tkey="faq"
            icon={<QuestionCircleOutlined />}
          />
        </a>
        <TextNeutralButton
          tkey="home"
          icon={<HomeOutlined />}
          onClick={() => sNavigateReload(`${ROUTES.CANDIDATE_BASE}/home`)}
        />
        <TextDangerButton
          tkey="logout"
          onClick={() => sNavigate(`${LOGIN.LOGOUT}`)}
        />
      </div>
);
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getCurrent(candidateFileStatusId: string) {
    switch (candidateFileStatusId) {
        case CandidateFileStatusEnum.NEW:
            return 0;
        case CandidateFileStatusEnum.FORCED:
            return 1;
        case CandidateFileStatusEnum.SUBMITTED:
            return 2;
        default:
            return undefined;
    }
}

function ChatNotification(props: {}) {
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    return (
      <div css={css``}>
        <ResponsiveButton
          shape="circle"
          size="small"
          data-testid="chatButton"
          css={css`background-color: #3047EC;width:40px !important; height:40px !important`}
          icon={(
            <Badge
              count={CommentsUtils.getUnreadedComments(candidateFileContext.selectedCandidateFile.commentsBtwSecretaryAndCandidate)
                            .length}
            >
              <MessageFilled style={{ fontSize: '20px' }} css={css`color:white;`} />
            </Badge>
                )}
          onClick={() => navigateTo({ key: 'chat' })}
        />
      </div>
    );
}

function FooterButtonsBar() {
    const path = usePath();
    const { routesCtx } = useContext(RoutesContext);
    const rights = useScopedSelector<RightsContextType, IRights>('FooterButtonsBar', RightsContext, (c) => c.rightsCtxState.rights);
    const { hasPrevious, hasNext, isWritable } = useNavigateEntity(routesCtx, rights);
    const entity = getCurrentEntity(path);
    const writable = isWritable(path);
    const nextable = hasNext(path);
    const previousable = hasPrevious(path);
    const showSubmit = !nextable && writable && entity !== 'chat';
    return (
      <Fragment>
        <IsDesktop>
          <Flex css={{ margin: '1em', gap: '1em', alignItems: 'center' }}>
            {previousable
            && <PreviousEntity />}
            {!showSubmit
            && <NextEntity />}
            {showSubmit
            && (
            <Flex justifyContent="space-evenly">
              <EntityFormSubmitButton />
            </Flex>
            )}
            <IsPath different={`${ROUTES.CANDIDATE_BASE}/chat`}>
              <ChatNotification />
            </IsPath>
          </Flex>
        </IsDesktop>
        <IsMobile>
          <div css={css`display: flex;flex-direction:column;gap:1em;width:100%;`}>
            {showSubmit
            && (<EntityFormSubmitButton />
            )}
            {!showSubmit
              && <NextEntity />}
            <IsPath different={`${ROUTES.CANDIDATE_BASE}/chat`}>
              <div css={css`display: flex;gap:1em;`}>
                {previousable
                && <PreviousEntity />}
                <ChatNotification />
              </div>
            </IsPath>
          </div>
        </IsMobile>
      </Fragment>
    );
}

function CandidateFooter() {
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);

    return (
      <FooterStyle>
        <MenuAndFormFooterContainer>
          <IsDesktop>
            <Steps.Step
              title={<Locale.Step tkey={candidateFileContext.selectedCandidateFile?.status?.id} />}
              description={(
                <Locale.Help
                  tkey={candidateFileContext.selectedCandidateFile?.status?.id}
                />
                        )}
            />
          </IsDesktop>
          <FooterButtonsBar />
        </MenuAndFormFooterContainer>
      </FooterStyle>
    );
}

export function CandidatePage(props: { children: any }) {
    const smallScreen = useIsSmallScreen();
    const CandidatePageStyle = smallScreen
        ? styled.div`
            height: 100%;
            display: block;`
        : styled.div`
            height: 100%;
            display: grid;
            grid-template-areas:  "header header"
                                  "left content"
                                  "footer footer";
            grid-template-rows: 60px minmax(0, 100%) 80px;
            grid-template-columns: 260px minmax(300px, 1fr);            
            .ant-checkbox-wrapper {
                zoom: 1.5;
            }
`;

    return (
      <UserPage>
        <CandidatePageStyle>
          {!smallScreen
                && <CandidatePageHeader />}
          <MenuAndFormContainer>
            {!smallScreen
              && (
              <LeftAreaContainer>
                <CandidateLeftMenu />
              </LeftAreaContainer>
)}
            <CenterAreaContainer>
              {props?.children}
            </CenterAreaContainer>
          </MenuAndFormContainer>
          <CandidateFooter />
        </CandidatePageStyle>
      </UserPage>
    );
}
