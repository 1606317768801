import { usePath, useRoutes } from 'hookrouter';
import React, { Fragment, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { SpecializedSecretaryChild1RoutesDef } from './SpecializedSecretaryChild1RoutesDef';

export function SpecializedSecretaryChild1Container() {
    const path = usePath();
    const [,updateState] = useImmer({ loaded: false, routes: undefined as any, path: '' });
    useEffect(() => {
        updateState((draft) => {
            draft.path = path;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateState, path]);
    const routesChildren = useRoutes(SpecializedSecretaryChild1RoutesDef());
    return (
      <Fragment>
        {routesChildren}
      </Fragment>
    );
}
