/** @jsx jsx */
import { jsx } from '@emotion/core';
import _, { flatMap } from 'lodash';
import { Fragment } from 'react';
import { useColdData } from '../../../../hooks/use-cold-data.hook';
import { CustomGridSelectEditor } from '../../../../common/customDatagrid/customGridSelectEditor';
import * as Locale from '../../../../common/locale';
import { ColdDataAutoCompleteContainer } from '../../../../common/coldData/ColdDataAutoCompleteContainer';

/**
 * Fonction appelee pour la generation de la combo pour filtrage des etablissements
 * @param props
 * @returns
 */
export function LastSchoolNameFilter(props) {
    // Recuperation des etablissements
    const filterEditorProps = props?.filterEditorProps;

    return props.render(
        <ColdDataAutoCompleteContainer
            {...filterEditorProps}
            api="schools"
            params="{ style: { width: '200px' } }"
            allowClear
            mode="multiple"
            multiple
            onClear={() => {
                props.onChange({ ...props.filterValue, value: undefined });
            }}
            onChange={(v) => {
                if (!v) {
                    props.onChange({ ...props.filterValue, value: [] });
                    return;
                }
                if (Array.isArray(v)) {
                    props.onChange({ ...props.filterValue, value: v.map((s) => s.id) });
                } else {
                    props.onChange({ ...props.filterValue, value: [v.id] });
                }
            }}
        />,
    );
}

/**
 * Display School value
 * @param value
 * @constructor
 */
export function LastSchoolNameCellRender({ data, value }: any) {
    return (
        <Fragment key={`lastSchoolName_cell_${data.id}`}>
            {`${data?.lastSchoolNameId?.name}: ${data?.lastSchoolNameId?.city?.name} (${data?.lastSchoolNameId?.city?.zipCode})`}
        </Fragment>
    );
}

/**
 * Return rendor Editor
 * @param editorProps
 * @constructor
 */
export function LastSchoolNameFilterEditor(editorProps) {
    const [coldData] = useColdData('public/schools');
    const { data } = coldData;
    // Tri des etablissements
    let listSchools = _.sortBy(data, 'name');
    // Application du mapper
    listSchools = flatMap(data, mapperCombo);
    // Il faut passer le dataSource dans le return, si on le passe dans le editorProps, ca ne marche pas (pas de rerender ?)
    return <CustomGridSelectEditor {...editorProps} dataSource={listSchools} />;
}

export const lastSchoolNameEditorProps = {
    idProperty: 'school',
};

export const lastSchoolNameColumn = {
    header: <Locale.Header tkey="candidateFilesList.lastSchoolName" />,
    name: 'lastSchoolName',
    id: 'lastSchoolName',
    defaultWidth: 230,
    filterEditor: LastSchoolNameFilter,
    render: LastSchoolNameCellRender,
};

export const lastSchoolNameFilter = {
    name: 'lastSchoolName', operator: 'inlist', type: 'select', value: '',
};

export const mapperCombo = (p) => ({
    id: p.id, label: `${p?.name} (${p?.city})`,
});
