import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import React, { MutableRefObject } from 'react';
// @ts-ignore
import { getSelectedCandidateFiles } from '../canSeeCandidateFile/candidateFilesList/CandidateFilesList';
import { ExaminerRowContextMenu } from './ExaminerRowContextMenu';
import { avatarColumn } from '../canSeeCandidateFile/candidateFilesList/columns/AvatarColumn';
import { shortIdColumn, shortIdFilter } from '../canSeeCandidateFile/candidateFilesList/columns/ShortIdColumn';
import { firstNameColumn, firstNameFilter } from '../canSeeCandidateFile/candidateFilesList/columns/FirstNameColumn';
import { lastNameColumn, lastNameFilter } from '../canSeeCandidateFile/candidateFilesList/columns/LastNameColumn';
import { emailColumn, emailFilter } from '../canSeeCandidateFile/candidateFilesList/columns/EmailColumn';
import {
    specializedSecretaryReviewCommentsColumn,
    specializedSecretaryReviewCommentsFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/CommentsBtwSecretariesColumn';
import {
    particularCaseColumn,
    particularCaseFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/ParticularCaseColumn';
import { lastDegreeColumn, lastDegreeFilter } from '../canSeeCandidateFile/candidateFilesList/columns/LastDegreeColumn';
import {
    lastSchoolNameColumn,
    lastSchoolNameFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/LastSchoolNameColumn';
import {
    examinerReviewStatusColumn,
    examinerReviewStatusFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/ExaminerReviewColumn';
import { ignoreItColumn, ignoreItFilter } from '../canSeeCandidateFile/candidateFilesList/columns/IgnoreItColumn';
import { examinerReviewNotationColumn, examinerReviewNotationFilter } from '../canSeeCandidateFile/candidateFilesList/columns/ExaminerReviewColumn';
import { juryReviewStatusColumn, juryReviewStatusFilter } from '../canSeeCandidateFile/candidateFilesList/columns/JuryReviewStatusColumn';

export const CandidateFilesListInitialColumns = (t) => [
    { ...avatarColumn, defaultVisible: true },
    shortIdColumn,
    lastNameColumn,
    firstNameColumn,
    /* */
    lastSchoolNameColumn,
    { ...lastDegreeColumn, defaultLocked: undefined },
    examinerReviewStatusColumn,
    examinerReviewNotationColumn,
    { ...juryReviewStatusColumn, defaultVisible: false },
    particularCaseColumn,
    ignoreItColumn,
    { ...specializedSecretaryReviewCommentsColumn, defaultVisible: false },
    { ...emailColumn, defaultLocked: undefined, defaultVisible: false },
];

export const CandidateFilesListInitialFilters = [
    shortIdFilter,
    firstNameFilter,
    lastNameFilter,
    emailFilter,
    lastSchoolNameFilter,
    lastDegreeFilter,
    examinerReviewStatusFilter,
    examinerReviewNotationFilter,
    juryReviewStatusFilter,
    particularCaseFilter,
    ignoreItFilter,
    specializedSecretaryReviewCommentsFilter,
];

export function renderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedCandidateFiles) {
    return (
        <ExaminerRowContextMenu
            menuProps={menuProps}
            details={details}
            selectedCandidateFiles={getSelectedCandidateFiles(details.rowProps.data, selectedCandidateFiles)}
        />
    );
}
