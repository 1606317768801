/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { ColdDataSelectorContainer } from '../../../../common/coldData/ColdDataSelectorContainer';
import * as Locale from '../../../../common/locale';

/**
 * Fonction appelee pour la generation de la combo pour filtrage des filières
 * @param props
 * @returns
 */
export function LastDegreeNameFilter(props) {
        // Recuperation des etablissements
        const filterEditorProps = props?.filterEditorProps;

        return props.render(
                <ColdDataSelectorContainer
                        {...filterEditorProps}
                        api="school-tracks"
                        params="{ style: { width: '200px' } }"
                        allowClear
                        mode="multiple"
                        multiple
                        onClear={() => {
                                props.onChange({ ...props.filterValue, value: undefined });
                        }}
                        onChange={(v) => {
                                if (!v) {
                                        props.onChange({ ...props.filterValue, value: [] });
                                        return;
                                }
                                if (Array.isArray(v)) {
                                        props.onChange({ ...props.filterValue, value: v.map((s) => s.id) });
                                } else {
                                        props.onChange({ ...props.filterValue, value: [v.id] });
                                }
                        }}
                />,
        );
}

/**
 * Display School_track value
 * @param value
 * @constructor
 */
export function LastDegreeNameCellRender({ data, value }: any) {
        return (
                <Fragment key={`lastDegreeName_cell_${data.id}`}>
                        {value}
                </Fragment>
        );
}

export const lastDegreeColumn = {
        header: <Locale.Header tkey="candidateFilesList.lastDegreeName" />,
        name: 'lastDegreeName',
        id: 'lastDegreeName',
        group: 'profile',
        defaultWidth: 170,
        defaultLocked: 'start',
        lockable: false,
        filterEditor: LastDegreeNameFilter,
        render: LastDegreeNameCellRender,
        filterable: true,
};

export const lastDegreeFilter = {
        name: 'lastDegreeName', operator: 'inlist', type: 'select', value: '',
};
