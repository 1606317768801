import { InputProps } from 'antd/lib/input';
import React from 'react';

import { Debounced } from '../../withExtraParameters/Debounced';
import { ResponsiveInput } from '../../input/ResponsiveInput';
import { copyEntityFormProps, EntityFormItemContainer, ItemProps } from '../entityForm/EntityFormItemContainer';
import { WithOnChangeValueChange } from '../../withExtraParameters/WithOnChangeValueChange';

/**
 * EntityFormItemContainer for number input
 * @param props labelVisible positionne a false permet de ne pas afficher le label du composant lie a INPUT, RADIO, etc.
 * @constructor
 */
export function InputNumberFormItem(props: ItemProps & Omit<InputProps, 'disabled'> & {labelVisible?: boolean}) {
    const step = props.step || 0.01;
    const min = props.min || 0;
    const max = props.max || 20;
    return (
      <EntityFormItemContainer
        {...copyEntityFormProps(props)}
        labelVisible = {props.labelVisible}
      >
        <Debounced>
          <WithOnChangeValueChange valueMapper={(e) => {
                            const { value } = e.target;
                            if (!value) {
                                return null;
                            }
                            return value;
                        }}
          >
            <ResponsiveInput
              data-testid={`${props.propertyName}`?.replace(/,/g, '_')}
              type="number"
              min={min}
              max={max}
              step={step}
              allowClear
              autoComplete={props.autoComplete}
            />
          </WithOnChangeValueChange>
        </Debounced>
      </EntityFormItemContainer>
);
}
