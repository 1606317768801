/** @jsx jsx */
import {jsx} from '@emotion/core';
import styled from '@emotion/styled';
import {IMissingFieldsList, UserRoleEnum, ValidationStep} from '@fstn/ecandidaturev2_api-interfaces';
import {Card, Empty} from 'antd';
import React, {useContext} from 'react';

import {TextOkButton} from '../../../common/button/TextOkButton';
import {ButtonsFormItemLayout} from '../../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import {EntityFormContainer} from '../../../common/form/entityForm/EntityFormContainer';
import {EntityFormListContainer} from '../../../common/form/entityForm/EntityFormListContainer';
import {ValidationsContextProvider} from '../../../context/validations.context';
import {MissingFieldForm} from './MissingFieldForm';
import {DisabledContextProvider} from '../../../common/rights/DisabledContext';
import {UserContext, UserContextType} from '../../../context/user.context';
import {IsSecretary} from '../../user/conditionnal/IsSecretary';

const Style = styled.div`
.ant-form-item-control-input-content>div{
  box-shadow: none !important;
}
`;

export function MissingFieldsListForm(props: { missingFieldsList: IMissingFieldsList, refresh: any, onBeforeValidate: any }) {
    const { userCtx } = useContext<UserContextType>(UserContext);
    return (
      <Style>
        <Card>
          {props.missingFieldsList?.missingFields?.length > 0
            && (
            <ValidationsContextProvider steps={[ValidationStep.SECRETARY_REVIEW_VALID]} entity="candidate-file">
              <EntityFormContainer
                ignoreUpdateInitialValues
                listeners={{ onBeforeValidate: props.onBeforeValidate }}
                layout="horizontal"
                initialValues={props.missingFieldsList}
                entity="missing-fields-list"
              >
                <DisabledContextProvider value={{
                      disabled: userCtx.user.role.name !== UserRoleEnum.SECRETARY,
                      wrapAuthorized: true,
                      context: 'RightMenuContainer',
                  }}
                >
                  <EntityFormListContainer
                    propertyName="missingFields"
                    entity="missing-fields"
                    compact
                  >
                    <MissingFieldForm />
                  </EntityFormListContainer>
                </DisabledContextProvider>
              </EntityFormContainer>
            </ValidationsContextProvider>
            )}
          {props.missingFieldsList?.missingFields?.length === 0 && <Empty />}
        </Card>
        <IsSecretary>
          <ButtonsFormItemLayout>
            <TextOkButton
              onClick={props.refresh}
              tkey="missingFields.missingFields.refresh"
            />
          </ButtonsFormItemLayout>
        </IsSecretary>
      </Style>
    );
}
