/** @jsx jsx */
import {IPaymentsList, walkEntityPropertyAndMapDateStrAsMoment} from '@fstn/ecandidaturev2_api-interfaces';
import {jsx} from '@emotion/core';
import {Timeline} from 'antd';
import _ from 'lodash';
import React from 'react';
import {useDeepCompareEffect} from 'use-deep-compare';
import {useImmer} from 'use-immer';
import {useEntity} from '../../../hooks/use-entity';
import {PaymentHistoryLine} from './PaymentForm';

/**
 * Affichage de l'historique des transactions bancaires (a la fois pour le SSD et en recap pour le candidat)
 * @param props 
 *   - paymentsList : liste completes de toutes les transactions bancaires   
 *   - needReload : marqueur indiquant qu'il faut recharger les transactions depuis le back suite a une modification intervenue entre temps (reception d'un echec de paiement)
 * @returns 
 */
export function PaymentHistory(props: { paymentsList?:IPaymentsList, needReload?: boolean }) {
    const [state, updateState] = useImmer({ payments: props?.paymentsList?.payments, loaded: false });
    const { loadEntity } = useEntity();

    async function load() {
        updateState((draft) => {
            draft.loaded = true;
        });
        const paymentsList: IPaymentsList = await loadEntity('payments-list');
        walkEntityPropertyAndMapDateStrAsMoment(paymentsList);
        const { payments } = paymentsList;
        updateState((draft) => {
            draft.payments = payments;
        });
    }

    useDeepCompareEffect(() => {
        // On recupere la liste de toutes les transactions bancaires depuis le back s'ils n'ont pas encore ete charges, ou si on detecte des differences, 
        // ou s'il faut forcer un rechargement suite a l'ajout d'une transaction en echec
        if ((!state.loaded || props.needReload) && (!state.payments || !_.isEqual(state.payments, props?.paymentsList?.payments))) {
            load().then();
        }
    }, [state.payments, props.paymentsList]);

    return (
      <Timeline>
        {state.payments?.map?.((p) => <PaymentHistoryLine payment={p} />)}
      </Timeline>
);
}
