import {ValidationStep} from '@fstn/ecandidaturev2_api-interfaces';
import React, {Fragment} from 'react';
import {ValidationsContextProvider} from '../../context/validations.context';
import {CenterForm} from '../anonymous/CenterForm';
import {ReferentRegisterForm} from './ReferentRegisterForm';

const entity = 'academic-referent';

export function ReferentRegisterPage() {
    return (
      <Fragment>
        <ValidationsContextProvider steps={[ValidationStep.REFERENT]} entity={entity}>
          <CenterForm>
            <ReferentRegisterForm />
          </CenterForm>
        </ValidationsContextProvider>
      </Fragment>
);
}
