/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { InputFilter } from '../filters/InputFilter';
import { PopOverCommentsFlat } from '../PopOverCommentsFlat';
import * as Locale from '../../../../common/locale';

export const SpecializedSecretaryReviewCommentsFilter = InputFilter;
export const specializedSecretaryReviewCommentsFilterEditorProps = {
  className: 'specializedSecretaryReviewCommentsFilter',
  'test-id': 'specializedSecretaryReviewCommentsFilter',
};

export function SpecializedSecretaryReviewCommentsCellRender({ value, data }: any) {
  return (
    <Fragment
      key={`specializedSecretaryReviewComments_cell_${data.id}`}
    >
      <PopOverCommentsFlat
        content={value}
        tkey="specializedSecretaryReviewComments"
      />
    </Fragment>
  );
}

export const specializedSecretaryReviewCommentsColumn = {
  header: <Locale.Header tkey="candidateFilesList.specializedSecretaryReview.comments" />,
  name: 'specializedSecretaryReviewComment',
  id: 'specializedSecretaryReviewComment',
  defaultWidth: 100,
  filterEditor: InputFilter,
  render: ({ value, data }: any) => (
    <PopOverCommentsFlat
      content={value}
      tkey="candidateFilesList.header.specializedSecretaryReview.comments"
    />
  ),
};
export const specializedSecretaryReviewCommentsWith = 300;

export const specializedSecretaryReviewCommentsFilter = {
  //name: 'specializedSecretaryReviewComment', operator: 'contains', type: 'filterStringWithoutNotContainsAndEmpty', value: '',
  name: 'specializedSecretaryReviewComment', operator: 'contains', type: 'string', value: '',
};

export const specializedSecretaryReviewCommentsType = {
};
