import styled from '@react-pdf/styled-components';
import React, { ReactNode } from 'react';
import { EmptyPDFValue } from '../../../pages/canSeePDF/candidateFilePDF/Empty';

const Container = styled.Text`
font-size: 9px;
font-weight: 800;
height: ${(props) => (props.height || 'auto')};
`;

export function PDFValue(props: { debug?: boolean, children?: ReactNode, height?: number }) {
    return (
      <Container height={props.height} debug={props.debug}>
        {!props.children && <EmptyPDFValue />}
        {!!props.children && props.children }
      </Container>
    );
}
