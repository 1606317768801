import styled from '@emotion/styled';
import React from 'react';
import { useSafeTranslation } from '../../hooks/use-safe-translation';

const Style = styled('span')`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:pre-wrap;
    text-align: left;
    display: inline!important;
`;

export function Header(props: { tkey: string }) {
    const { t } = useSafeTranslation();
    const r = t(`${props?.tkey}.header.html`) !== undefined ? t(`${props?.tkey}.header.html`) : `${props?.tkey}`;
    return <Style data-label-key={`${props?.tkey}.title.html`} className="translation" dangerouslySetInnerHTML={{ __html: r }} />;
}

export function Title(props: { tkey: string }) {
    const { t } = useSafeTranslation();
    const r = t(`${props?.tkey}.title.html`) !== undefined ? t(`${props?.tkey}.title.html`) : `${props?.tkey}`;
    return <Style data-label-key={`${props?.tkey}.title.html`} className="translation" dangerouslySetInnerHTML={{ __html: r }} />;
}

export function buttonTrad(tkey: string, t: any) {
    const r = t(`${tkey}.button.html`) !== undefined ? t(`${tkey}.button.html`) : `${tkey}`;
    return r;
}

export function Button(props: { tkey: string }) {
    const { t } = useSafeTranslation();
    const r = buttonTrad(props.tkey, t);
    return <Style data-label-key={`${props?.tkey}.button.html`} className="translation" dangerouslySetInnerHTML={{ __html: r }} />;
}

export function Help(props: { tkey: string }) {
    const { t } = useSafeTranslation();
    const r = t(`${props?.tkey}.help.html`) !== undefined ? t(`${props?.tkey}.help.html`) : `${props?.tkey}`;
    return <Style data-label-key={`${props?.tkey}.help.html`} className="translation" dangerouslySetInnerHTML={{ __html: r }} />;
}

export function Error(props: { tkey: string }) {
    const { t } = useSafeTranslation();
    const r = t(`${props?.tkey}.error.html`) !== undefined ? t(`${props?.tkey}.error.html`) : `${props?.tkey}`;
    return <Style data-label-key={`${props?.tkey}.error.html`} className="translation" dangerouslySetInnerHTML={{ __html: r }} />;
}

export function Content(props: { tkey: string }) {
    const { t } = useSafeTranslation();
    const r = t(`${props?.tkey}.content.html`) !== undefined ? t(`${props?.tkey}.content.html`) : `${props?.tkey}`;
    return <Style data-label-key={`${props?.tkey}.content.html`} className="translation" dangerouslySetInnerHTML={{ __html: r }} />;
}

export function labelTrad(tkey: string, t: any) {
    const r = t(`${tkey}.label.html`) !== undefined ? t(`${tkey}.label.html`) : `${tkey}`;
    return r;
}

export function Label(props: { tkey: string, unTrim?: boolean }) {
    const { t } = useSafeTranslation();
    const r = labelTrad(props.tkey, t);
    return <Style data-label-key={`${props?.tkey}.label.html`} className="translation" dangerouslySetInnerHTML={{ __html: (props.unTrim ? ' ' : '') + r + (props.unTrim ? ' ' : '') }} />;
}

export function notificationTrad(tkey: string, t: any) {
    const r = t(`${tkey}.notification.html`) !== undefined ? t(`${tkey}.notification.html`) : `${tkey}`;
    return r;
}

export function Notification(props: { tkey: string }) {
    const { t } = useSafeTranslation();
    const r = notificationTrad(props.tkey, t);
    return <Style data-label-key={`${props?.tkey}.notification.html`} className="translation" dangerouslySetInnerHTML={{ __html: r }} />;
}

export function Suffix(props: { tkey: string }) {
    const { t } = useSafeTranslation();
    const r = t(`${props?.tkey}.suffix`) !== undefined ? t(`${props?.tkey}.suffix`) : `${props?.tkey}`;
    return (
      <Style
        className="translation"
        style={{ fontSize: 8, marginRight: '0.5em' }}
      >
        {r}
      </Style>
);
}

export function PlaceHolder(props: { tkey: string }) {
    const { t } = useSafeTranslation();
    return t(`${props?.tkey}.placeholder`);
}

export function Step(props: { tkey: string }) {
    const { t } = useSafeTranslation();
    const r = t(`${props?.tkey}.step.html`) !== undefined ? t(`${props?.tkey}.step.html`) : `${props?.tkey}`;
    return <Style data-label-key={`${props?.tkey}.step.html`} className="translation" dangerouslySetInnerHTML={{ __html: r }} />;
}

export function CheckBox(props: { tkey: string }) {
    const { t } = useSafeTranslation();
    const r = t(`${props?.tkey}.label.checkbox.html`) !== undefined ? t(`${props?.tkey}.label.checkbox.html`) : `${props?.tkey}`;
    return (
      <Style
        className="translation"
        dangerouslySetInnerHTML={{ __html: r }}
      />
);
}
