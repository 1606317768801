/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {useIsSmallScreen} from '../../../hooks/use-is-small-screen.hook';

export function IsDesktop(props: { children: any, elseChildren?: any }) {
    const smallScreen = useIsSmallScreen();
    return (
      <Fragment>
        {!smallScreen && props.children}
        {smallScreen && props.elseChildren}
      </Fragment>
    );
}
