/** @jsx jsx */
import { jsx } from '@emotion/core';
import { IAcademic, UserRoleEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import React, { useContext } from 'react';
import { useImmer } from 'use-immer';
import UUID from 'uuid-js';
import { UserContext, UserContextType } from '../../../context/user.context';
import { FixSelectFormItem } from '../../../common/form/inputFormItems/FixSelectFormItem';
import { YesNoFormItem } from '../../../common/form/inputFormItems/YesNoFormItems';
import * as Locale from '../../../common/locale';
import { useConfig } from '../../../hooks/use-config.hook';

export function AcademicYearModal(props: {
  hiddenYears?: number[]
  showAcademicYear: boolean,
  onCancel: () => Promise<void>,
  onAddAcademic: (academic: Partial<IAcademic>) => Promise<void>
}) {
  const [academicYearForm] = useForm();
  const { userCtx } = useContext<UserContextType>(UserContext);
  const [{ maxLimit }] = useConfig();
  const maxExportedYears = maxLimit('ACADEMICS') || 6;  // Permet de savoir pour quelles années de la combo on peut saisir année sans etude (les années à remplir oblig.)
  const maxTotalYears = maxLimit('TOTAL_ACADEMICS') || 12;  // Nombre maximal d'années a afficher dans la combo

  const [state, updateState] = useImmer({ loading: false, values: {} as any });

  async function onAdd(academic: Partial<IAcademic>) {
    updateState((draft) => { draft.loading = true; });
    await props.onAddAcademic(academic);
    updateState((draft) => { draft.loading = false; });
    academicYearForm.resetFields();
  }

  const currentYear = new Date().getFullYear();

  let availableYears = [];

  // La combo des dates propose MAX_TOTAL_ACADEMICS elements
  for (let i = 0; i < maxTotalYears; i += 1) {
    availableYears.push({
      value: currentYear - i,
      label: `${currentYear - i - 1}-${currentYear - i}`,
    });
  }

  availableYears = availableYears.filter((o) => !props.hiddenYears?.includes(o.value));
  return (
    <Modal
      title={<Locale.Title tkey="academic.year" />}
      visible={props.showAcademicYear}
      onOk={() => {
        academicYearForm.submit();
      }}
      // @ts-ignore
      okButtonProps={{ 'data-testid': 'academic-year-ok', loading: state.loading }}
      cancelButtonProps={{ disabled: state.loading }}
      onCancel={props.onCancel}
    >
      <Locale.Help tkey="academic.year" />
      <Form
        form={academicYearForm as any}
        onValuesChange={(values) => {
          updateState((draft) => {
            draft.values = { ...state.values, ...values };
          });
        }}
        onFinish={async (v) => {
          const academic: Partial<IAcademic> = {
            id: UUID.create(1).toString(),
            year: v.year,
            // quand le radio "student" est coche automatiquement a oui (pour une date selectionne anterieure aux 6 dernieres années), 
            // l'info ne remonte pas ici, donc si undefined, on remonte true pour les annees anterieures à 6 ans
            student: v.student || parseInt(state.values.year) <= currentYear - maxExportedYears,
            createdAt: moment.utc() as any,
          };

          if (userCtx.user?.role?.name === UserRoleEnum.SECRETARY) {
            // if we are secretary, we switched to edit mode so we need to set the right rights
            academic.rights = {
              manage: [UserRoleEnum.SECRETARY],
              read: Object.values(UserRoleEnum),
              write: [UserRoleEnum.SECRETARY],
              delete: [UserRoleEnum.SECRETARY],
            };
          }
          await onAdd(academic);
        }}
      >

        <FixSelectFormItem
          dependenciesValues={[availableYears]}
          propertyName="year"
          required
          rules={[{ required: true, type: 'number' }]}
          options={availableYears}
        />
        {/* Le choix "J'etais etudiant" n'est proposé que pour les MAX_ACADEMICS dernieres annees
            Pour les années anterieures, on bloque le choix à oui (en fixant la valeur, le rendant readOnly et ne le rendant plus obligatoire)
        */}
        <YesNoFormItem
          propertyName="student"
          required
          dependencies={['year']}
          dependenciesValues={[state.values.year]}
          rules={[{ required: parseInt(state.values.year) > currentYear - maxExportedYears, type: 'boolean' }]}
          value={parseInt(state.values.year) <= currentYear - maxExportedYears}
          readOnly={parseInt(state.values.year) <= currentYear - maxExportedYears}
        />
      </Form>
    </Modal>
  );
}
