import React from 'react';
import {SecretaryPageCandidateFilePDF} from '../../canSeePDF/candidateFilePDF/SecretaryPageCandidateFilePDF';
import {FullScreenExaminerPage} from '../ExaminerPage';
import {ExaminerChild1Container} from './ExaminerChild1Container';

/**
 * Routes starting with /home/welcome/secretary/
 * @constructor
 */
export const ExaminerContainerRoutesDef = () => ({
    '/pdf': () => (
      <FullScreenExaminerPage>
        <SecretaryPageCandidateFilePDF />
      </FullScreenExaminerPage>
    ),
    '*': () => (
      <ExaminerChild1Container />
    ),

});
