import { Select, SelectProps } from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { omit } from 'lodash';
import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { useResponsiveInputSize } from '../../hooks/use-responsive-input-size.hook';

const Style = styled.div`
  .ant-select-selection-item{
    max-width: 100% !important;
  }`;
/**
 * Responsive autocomplete input
 * @param props
 * @constructor
 */
export function ResponsiveAutoComplete(props: AutoCompleteProps & SelectProps<any>) {
    const { size } = useResponsiveInputSize();
    return (
      <Style>
        <Select
          showSearch
          optionFilterProp="children"
          size={size}
          disabled={props.disabled}
          tagRender={(e) => <Fragment />}
          {...omit(props, 'mapper', 'filter')}
        >
          {props.children}
        </Select>
      </Style>
);
}
