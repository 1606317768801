/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import * as Locale from '../../../../common/locale';
import {InputCommentsItemContainer} from '../../../user/inputCommentsItem/InputCommentsItemContainer';

/**
 * Comments Btw Secretaries part of right container
 * @constructor
 */
export function CommentsBtwSecretariesRightContainer() {
    return (
      <Fragment>
        <h1>
          {' '}
          <Locale.Title tkey="secretaryReview.secretary.commentsBtwSecretaries" />
        </h1>
        <br />
        <InputCommentsItemContainer type="commentsBtwSecretaries" />
      </Fragment>
);
}
