import { Draft } from 'immer';
import React, { ReactElement, useCallback } from 'react';
import { useImmer } from 'use-immer';
import { IDocument } from '@fstn/ecandidaturev2_api-interfaces';

export const RightAreaContext = React.createContext({} as RightAreaContextProviderType);

export interface RightAreaContextProviderContentType {
    displayedComponent?: ReactElement;
    incToForceUpdate: number,
    large: boolean,
    document ?: IDocument
}

export interface RightAreaContextProviderType {
  rightAreaContext?: RightAreaContextProviderContentType
  updateRightAreaContext?: (f: (draft: Draft<RightAreaContextProviderContentType>) => void | RightAreaContextProviderContentType) => void
  forceUpdate(): void;
}

export const RightAreaContextProvider = ((props: { children?: any }) => {
    // console.log('Redraw DocumentsListContextProvider');
    const [state, updateState] = useImmer<RightAreaContextProviderContentType>({ incToForceUpdate: 0 } as any);

    const updateStateWrapper = useCallback(
        (f: (draft: Draft<RightAreaContextProviderContentType>) => void | RightAreaContextProviderContentType) => {
             updateState((draft: Draft<RightAreaContextProviderContentType>) => {
                f(draft);
                // localStorage.setItem('DocumentsListContext', stringify(draft));
            });
        }, [updateState],
    );

    function forceUpdate() {
        setTimeout(() => {
            updateState((draft) => {
                draft.incToForceUpdate = state.incToForceUpdate + 1;
            });
        }, 0);
    }

  return (
    <RightAreaContext.Provider value={{ rightAreaContext: state, updateRightAreaContext: updateStateWrapper, forceUpdate }}>
      { props?.children}
    </RightAreaContext.Provider>
  );
});
