import { ClearOutlined } from '@ant-design/icons';
/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { ICandidateFile, walkEntityPropertyAndMapDateStrAsMoment } from '@fstn/ecandidaturev2_api-interfaces';
import Menu from '@inovua/reactdatagrid-community/packages/Menu';
import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import Empty from 'antd/lib/empty';
// @ts-ignore
import { useQueryParams } from 'hookrouter';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import * as Locale from '../../../common/locale';
import { UserConfigContext } from '../../../context/user.config.context';
import { useEntity } from '../../../hooks/use-entity';
import { QueryParamsUtils } from '../../../utils/QueryParamsUtils';
import { filterTypes } from '../../canSeeCandidateFile/candidateFilesList/CandidateFilesList.type';
import { StrictLoading } from '../../../common/indicator/SmartLoading';

const Style = styled.div`
height: 100%;
.InovuaReactDataGrid__column-header__filter-wrapper>div>.InovuaReactDataGrid__column-header__filter__binary_operator_separator{
  flex-basis: 100%;
}

.InovuaReactDataGrid__column-header__filter-wrapper>div{
 flex-wrap: wrap;
}

.inovua-react-toolkit-menu__cell.inovua-react-toolkit-menu__cell--ltr.inovua-react-toolkit-menu__cell--icon{
  display: flex;
  align-items: center;
  padding: 1em;
}
`;

/**
 * Renvoie la liste des elements selectionnes de la grid, ou l'element sur lequel on a clique droit (si aucun element selectionne)
 * @param data L'element de la grid sur lequel on etait au moment du clic droit pour acces au menu contextuel
 * @param selectedElements Liste des elements selectionnes dans la grid
 * @returns La liste des elements selectionnes si elle n'est pas vide, l'element de la grid sur lequel on a clique sinon
 */
export function getSelectedElements(data: any, selectedElements: { id: string }[]) {
    if (selectedElements && selectedElements.length > 0) {
        return selectedElements;
    }
    return [data];
}

/**
 *  L'idée ici c'est que l'on a une liste générique définie dans AdminEditableList que l'on utilise pour toutes les entitées de l'application
 * Cette liste est personnalisés avec les props:
 * @param mapToRow transforme le résultat du endpoint en ligne du tableau
 * @param entity nom du endpoint à appeler par exemple ici locales => /api/locales => apps/back/src/locale/locale.controller.ts
 * @param initialColumns liste des colonnes à afficher
 * @param initialFilters liste des filtres possibles
 * @param renderRowContextMenu menu à afficher au click droit
 * @param gridStyle
 * @param checkboxColumn Specifie si le grid doit contenir des checkbox et permettre les selections pour action via menu contextuel (faux par defaut)
 * @constructor
 */
export function AdminEditableList({
    mapToRow,
    entity,
    initialColumns,
    initialFilters,
    renderRowContextMenu,
    gridStyle,
    checkboxColumn,
}: {
    mapToRow: Function,
    entity: string,
    initialColumns: any[],
    initialFilters: any[],
    renderRowContextMenu,
    gridStyle?: any,
    checkboxColumn?: boolean,
}) {
    const [queryParams, setQueryParams] = useQueryParams();
    const userConfigCtx = useContext(UserConfigContext);
    let savedHiddenColumns = [];
    initialColumns = cloneDeep(initialColumns);
    initialColumns.forEach((c: any) => {
        c.visible = savedHiddenColumns.indexOf(c.name) === -1;
        c.filterDelay = 500;
    });

    const initialColumnsOrder = initialColumns.map((c) => c.name) || [];
    const paginationProps = {
        limit: 20,
        skip: 0,
    };

    const { loadEntities, patchEntity } = useEntity<any>();

    // @ts-ignore
    const [state, updateState] = useImmer<AdminStateType>({
        search: [],
        data: [],
        filteredData: [],
        selectedAdmin: [],
        loading: false,
        sorter: { dir: -1, name: 'shortId' },
        filter: initialFilters,
        columnOrder: initialColumnsOrder || [],
        columns: initialColumns,
    });

    const onSelectionChange = (elt: TypeOnSelectionChangeArg) => {
        if (Array.isArray(elt.data) && elt.data.length === 0) {
            updateState((draft) => {
                draft.selectedAdmin = [];
            });
        } else if (elt.unselected) {
            updateState((draft) => {
                draft.selectedAdmin = state.selectedAdmin.filter((r) => !Object.keys(elt.unselected).includes(r.id));
            });
        } else if (elt.selected === true) {
            updateState((draft) => {
                draft.selectedAdmin = elt.data as any;
            });
        } else {
            updateState((draft) => {
                draft.selectedAdmin = Object.values(elt.selected as any);
            });
        }
    };

    useEffect(() => {
        const initialFiltersByQueryParam = QueryParamsUtils.toFilters(queryParams, initialFilters);
        const fetchData = async () => {
            updateState((draft: any) => {
                draft.loading = true;
            });

            const entitiesValue = await loadEntities(entity);
            entitiesValue?.forEach?.((d) => walkEntityPropertyAndMapDateStrAsMoment(d));
            updateState((draft: any) => {
                draft.filter = initialFiltersByQueryParam;
                draft.columnOrder = initialColumnsOrder;
                draft.columns = initialColumns;
                draft.data = entitiesValue;
                draft.filteredData = (entitiesValue).map((d: ICandidateFile) => mapToRow(d));
                draft.loading = false;
            });
        };
        fetchData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function edit(v) {
        // Ici, on met un filtre qui indique qu'on insère NULL si la cellule est vide, et une chaine vide '' si elle ne contient qu'un espace tout seul ' '
        if (v.value === '') v.value = null;
        if (v.value === ' ') v.value = '';
        updateState((draft: any) => {
            const modifiedRowIndex = draft.filteredData.findIndex((d) => d.id === v.rowId);
            draft.filteredData[modifiedRowIndex][v.columnId] = v.value;
        });
        await patchEntity(entity, v.data, { id: v.rowId, [v.columnId]: v.value });
    }

    return (
        <StrictLoading loading={state.loading}>
            <Style>
                {state.filteredData?.length === 0 && <Empty />}
                {state.filteredData?.length > 0
                    && (
                        <ReactDataGrid
                            licenseKey="AppName=IMTMinesAlesApp,Company=IMTMinesAles,ExpiryDate=2022-09-21,LicenseDeveloperCount=1,LicenseType=single_app,Ref=IMTMinesAlesLicenseRef,Z=-1389124616-117191260076701888-20886508571221706338-2117749941"
                            i18n={i18n}
                            checkboxColumn={checkboxColumn}
                            css={css`.InovuaReactDataGrid__row-cell-wrap:hover{cursor: pointer}`}
                            idProperty="id"
                            style={{ height: '100%', ...gridStyle }}
                            filterable
                            editable
                            defaultSortInfo={state.sorter}
                            showColumnMenuGroupOptions={false}
                            showColumnMenuFilterOptions={false}
                            onEditComplete={edit}
                            columns={state.columns}
                            defaultColumnOrder={state.columnOrder as any}
                            pagination
                            pageSizes={[10, 20, 50, 100, 500, 1000, 5000]}
                            defaultSkip={paginationProps?.skip}
                            defaultLimit={paginationProps?.limit || 1000}
                            multiSelect
                            // @ts-ignore
                            filterTypes={filterTypes}
                            groupColumn={false}
                            theme="blue-light"
                            defaultFilterValue={state.filter}
                            onFilterValueChange={(filters) => {
                                setQueryParams(QueryParamsUtils.fromFilters(filters));
                            }}
                            onColumnVisibleChange={(columns) => {
                                if (columns.visible) {
                                    savedHiddenColumns = savedHiddenColumns.filter((c) => c !== columns.column.name);
                                } else {
                                    savedHiddenColumns.push(columns.column.name);
                                }
                                updateState(((draft) => {
                                    draft.columns.filter((c) => c.name === columns.column.name)
                                        .forEach((c) => {
                                            c.visible = columns.visible;
                                        });
                                }));
                            }}
                            onLimitChange={(limit) => {
                            }}
                            onSkipChange={(skip) => {
                            }}
                            virtualized
                            renderColumnContextMenu={(menuProps, { cellProps }) => (
                                <Menu
                                    {...menuProps}
                                    items={[...menuProps.items, {
                                        label: <Locale.Button tkey="candidateFile.config.clear" />,
                                        icon: <ClearOutlined />,
                                        onClick: async (value) => {
                                            await userConfigCtx.resetTableConfig('Admin');
                                            window.location.reload();
                                        },
                                    }]}
                                />
                            )}
                            dataSource={state.filteredData}
                            enableSelection={checkboxColumn}
                            renderRowContextMenu={(menuProps, details) => renderRowContextMenu(menuProps, details, state.selectedAdmin)}
                            showColumnMenuLockOptions={false}
                            onSelectionChange={onSelectionChange}
                        />
                    )}
            </Style>
        </StrictLoading>
    );
}

// @ts-ignore
window.moment = moment;

/**
 * Override component translation
 */
const i18n = {
    ...ReactDataGrid.defaultProps.i18n, // pagination toolbar
    pageText: 'Page',
    ofText: 'sur',
    perPageText: 'Nb par page',
    showingText: 'Affichés',

    clearAll: 'clearAll',
    clear: 'clear',
    showFilteringRow: 'Show filtering row',
    hideFilteringRow: 'Hide filtering row',

    enable: 'Enable',
    disable: 'Disable',

    sortAsc: 'Sort ascending',
    sortDesc: 'Sort descending',
    unsort: 'Unsort',
    group: 'Group',
    ungroup: 'Ungroup',
    lockStart: 'Lock start',
    lockEnd: 'Lock end',
    unlock: 'Unlock',
    columns: 'Columns',

    // operators,
    contains: 'Contains',
    startsWith: 'Starts with',
    endsWith: 'Ends with',
    notContains: 'Does not contain',
    inlist: 'In list',
    notinlist: 'Not in list',
    neq: 'Does not equal',
    inrange: 'In range',
    notinrange: 'Not in range',
    eq: 'Equals',
    notEmpty: 'Not empty',
    empty: 'Empty',
    lt: 'Less than',
    lte: 'Less than or equal',
    gt: 'Greater than',
    gte: 'Greater than or equal',
    before: 'Before',
    beforeOrOn: 'Before or on',
    afterOrOn: 'After or on',
    after: 'After',
    start: 'Start',
    end: 'End',

    dragHeaderToGroup: 'Drag header to group',
    noRecords: 'No records available',
};
