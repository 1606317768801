/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Affix, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Popconfirm from 'antd/lib/popconfirm';
// @ts-ignore
import React, { Fragment, useContext } from 'react';
import { useImmer } from 'use-immer';
import { TextOkButton } from '../../../common/button/TextOkButton';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { CheckboxFormItem } from '../../../common/form/inputFormItems/CheckboxFormItem';
import * as Locale from '../../../common/locale';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { EntityFormContextProvider } from '../../../context/entityForm.context';
import { EntityFormListContextProvider } from '../../../context/entityFormList.context';
import { UserContext, UserContextType } from '../../../context/user.context';
import { useUser } from '../../../hooks/use-user.hook';
import { sNavigate } from '../../../utils/safeNavigation';

import { ROUTES } from '../UserRoutesDef';
import { DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { ButtonsFormItemLayout } from '../../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import { TextCancelButton } from '../../../common/button/TextCancelButton';
import { IsDesktop } from '../conditionnal/IsDesktop';
import { IsMobile } from '../conditionnal/IsMobile';
import { SwitchFormItem } from '../../../common/form/inputFormItems/SwitchFormItem';

function SubmitButton({ tkey, ...props }: any) {
  const { state } = props;
  return (
    <TextOkButton
      {...props}
      tkey="rgpd"
      type="primary"
      loading={state.loading}
      htmlType="submit"
      className="rgpd-form-button"
    />
  );
}

function RefuseButton({ tkey, ...props }: any) {
  return (
    <TextCancelButton
      {...props}
      tkey="rgpd.refuse"
      loading={props.state.loading}
      className="rgpd-form-button"
    />
  );
}

export function RgpdAskForm() {
  const userCtx = useContext<UserContextType>(UserContext);
  const { loadMe } = useUser(userCtx);
  const [form] = useForm();
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  const [state, updateState] = useImmer({
    captcha: false, valid: false, loading: false, schoolTrack: '', school: '',
  });
  const onFinish = async (values) => {
    try {
      updateState((draft) => {
        draft.loading = true;
      });
      await axios.put('/rgpd/accept');
      await loadMe();
      sNavigate(`${ROUTES.BASE}/${userCtx.userCtx.user.role.name.toLowerCase().replace('_', '-')}`);
    } finally {
      updateState((draft) => {
        draft.loading = false;
      });
    }
  };
  const refuseRgpd = async () => {
    try {
      updateState((draft) => {
        draft.loading = true;
      });
      await axios.put('/rgpd/refuse');
      await loadMe();
      sNavigate('/');
    } finally {
      updateState((draft) => {
        draft.loading = false;
      });
    }
  };
  const validation = (rule: any, value: any, callback: (error?: any) => void) => {
    if (value) {
      return callback();
    }
    return callback('Obligatoire');
  };
  return (
    <Fragment>
      <DisabledContextProvider value={{ disabled: false, wrapAuthorized: false }}>
        <Form
          layout="horizontal"
          form={form}
          className="rgpd-form"
          onFinish={onFinish}
          noValidate
        >
          <EntityFormContextProvider
            entity={`rgpd.${userCtx.userCtx.user?.role?.name}`}
            isFieldTouched={form.isFieldTouched}
            getFieldValue={form.getFieldValue}
            getFieldsValue={form.getFieldsValue}
            onChange={() => {
            }}
            onImmediateChange={() => {
            }}
          >
            <EntityFormListContextProvider
              entity={`rgpd.${userCtx.userCtx.user?.role?.name}`}
              propertyName=""
              remove={() => {
              }}
            >

              <FormContentPanelContainer partName={`rgpd.${userCtx.userCtx.user?.role?.name}`}>
                <IsDesktop>
                  <div css={css`
      .ant-checkbox-inner{
          width: 30px;
          height: 30px;
          border: 3px solid #888888; ;

          &::after{
              top: 9px;
              left: 6px;
              width: 8px;
              height: 18px;
          }
      }
  `}>
                    <CheckboxFormItem
                      propertyName="privateDate"
                      labelCol={{ span: 20 }}
                      wrapperCol={{ span: 2 }}
                      rules={[{ validator: validation }]}
                      required
                    />
                  </div>
                  <Affix offsetBottom={0}>
                    <ButtonsFormItemLayout>
                      <Popconfirm
                        title={<Locale.Title tkey="form.regpd.refuse" />}
                        onConfirm={refuseRgpd}
                        okText={<Locale.Button tkey="ok" />}
                        cancelText={<Locale.Button tkey="cancel" />}
                      >
                        <span css={css`display: flex;flex: 1`}>
                          <RefuseButton state={state} />
                        </span>
                      </Popconfirm>
                      <SubmitButton state={state} />
                    </ButtonsFormItemLayout>
                  </Affix>
                </IsDesktop>
                <IsMobile>
                  <SwitchFormItem
                    propertyName="privateDate"
                    labelCol={{ span: 20 }}
                    wrapperCol={{ span: 2 }}
                    rules={[{ validator: validation }]}
                    required
                  />
                  <br />
                  <RefuseButton state={state} style={{ width: '100%' }} />
                  <SubmitButton state={state} style={{ width: '100%' }} />
                </IsMobile>
              </FormContentPanelContainer>
            </EntityFormListContextProvider>
          </EntityFormContextProvider>
        </Form>
      </DisabledContextProvider>
    </Fragment>
  );
}
