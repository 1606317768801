/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { CandidateFileStatusEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { Form, Modal, Radio, Steps, } from 'antd';
import { useForm } from 'antd/lib/form/Form';
// @ts-ignore
import { Fragment, useContext } from 'react';
import { useImmer } from 'use-immer';
import * as Locale from '../../common/locale';
import { useEntity } from '../../hooks/use-entity';
import { sNavigate } from '../../utils/safeNavigation';
import { CandidateFileContext, CandidateFileContextProviderType } from '../canSeeCandidateFile/CandidateFileContext';
import { ROUTES } from '../user/UserRoutesDef';
import { UserContext } from '../../context/user.context';

const Style = styled.div``;

export function SecretaryExitConfirm() {
  const [form] = useForm();
  const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
  const { userCtx } = useContext(UserContext);
  const [state, updateState] = useImmer({ values: {} as any });

  const { patchEntity, putEntity } = useEntity<any>();

  async function save(values: { touched: boolean, status: number }) {
    localStorage.removeItem('commentsBtwSecretaries');
    localStorage.removeItem('commentsBtwAllTypesOfSecretaries');
    localStorage.removeItem('commentsBtwSecretaryAndCandidate');
    if (values.touched) {
      // change lastChanges on candidateFile
      let realStatus;
      switch (values.status) {
        case 5:
          realStatus = CandidateFileStatusEnum.VALID;
          break;
        case 4:
          realStatus = CandidateFileStatusEnum.VALID_WITH_ERRORS;
          break;
        case 3:
          realStatus = CandidateFileStatusEnum.WAITING_CANDIDATE;
          break;
        case 2:
          realStatus = CandidateFileStatusEnum.PRE_VALIDATED;
          break;
        case 1:
          realStatus = CandidateFileStatusEnum.IN_PROGRESS_SECRETARY;
          break;
        default:
          realStatus = candidateFileContext.selectedCandidateFile?.status?.id;
          break;
      }
      await patchEntity('candidate-file/status', {} as any, { status: realStatus });
      await putEntity('candidate-file/unlock', {} as any, {} as any);
    } else {
      await putEntity('candidate-file/unlock', {} as any, {} as any);
    }
    sNavigate(localStorage.getItem('previousSecretaryUrlWithFilter') || ROUTES.BASE);
  }

  const candidateFile = candidateFileContext.selectedCandidateFile;

  return (
    <Modal
      visible
      closable={false}
      okText={<Locale.Button tkey="secretary.confirm.exit" />}
      onCancel={() => window.history.go(-1)}
      onOk={form.submit}
      okButtonProps={{ 'data-testid': 'secretary.confirm.exit.ok' } as any}
      cancelButtonProps={{ 'data-testid': 'secretary.confirm.exit.cancel' } as any}
    >
      <Style>
        <article className="message  is-small">
          <div className="message-header">
            <Locale.Title tkey="secretary.exit" />
          </div>
          <div className="message-body">
            <Locale.Content tkey="secretary.exit" />
          </div>
          <div className="message-body">
            <Form
              form={form}
              layout="vertical"
              initialValues={{ status: 'keep' }}
              onFinish={save}
              onValuesChange={(values) => {
                updateState((draft) => {
                  draft.values = { ...state.values, ...values };
                });
              }}
            >
              <Form.Item
                name="touched"
                label={<Locale.Label tkey="secretary.exit.touched" />}
                rules={[{ required: true }]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value data-testid="secretary.exit.touched.true">
                    <Locale.Button
                      tkey="secretary.exit.touched.true"
                    />
                  </Radio.Button>
                  <Radio.Button value={false} data-testid="secretary.exit.touched.false">
                    <Locale.Button tkey="secretary.exit.touched.false" />
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              {state.values.touched
                && (
                  <Form.Item
                    name="status"
                    label={<Locale.Label tkey="secretary.exit.status" />}
                    rules={[{ required: true }]}
                    valuePropName="current"
                  >
                    <Steps direction="vertical">
                      <Steps.Step
                        key="keep"
                        data-testid="keep"
                        title={(
                          <Fragment>
                            <Locale.Button tkey="secretary.exit.status.keep" />
                            :
                            <Locale.Label tkey={candidateFile?.status?.id} />
                          </Fragment>
                        )}
                        description={<Locale.Help tkey="secretary.exit.status.keep" />}
                      />
                      <Steps.Step
                        data-testid={CandidateFileStatusEnum.IN_PROGRESS_SECRETARY}
                                  /* ok */ disabled={candidateFile?.status?.id === CandidateFileStatusEnum.IN_PROGRESS_SECRETARY}
                        title={(
                          <Locale.Button
                            tkey={`secretary.exit.status.${CandidateFileStatusEnum.IN_PROGRESS_SECRETARY}`}
                          />
                        )}
                        description={(
                          <Locale.Help
                            tkey={`secretary.exit.status.${CandidateFileStatusEnum.IN_PROGRESS_SECRETARY}`}
                          />
                        )}
                      />
                      <Steps.Step
                        data-testid={CandidateFileStatusEnum.PRE_VALIDATED}
                                  /* ok */ disabled={candidateFile?.status?.id === CandidateFileStatusEnum.PRE_VALIDATED}
                        title={(
                          <Locale.Button
                            tkey={`secretary.exit.status.${CandidateFileStatusEnum.PRE_VALIDATED}`}
                          />
                        )}
                        description={(
                          <Locale.Help
                            tkey={`secretary.exit.status.${CandidateFileStatusEnum.PRE_VALIDATED}`}
                          />
                        )}
                      />
                      <Steps.Step
                        data-testid={CandidateFileStatusEnum.WAITING_CANDIDATE}
                                  /* ok */ disabled={candidateFile?.status?.id === CandidateFileStatusEnum.WAITING_CANDIDATE}
                        title={(
                          <Locale.Button
                            tkey={`secretary.exit.status.${CandidateFileStatusEnum.WAITING_CANDIDATE}`}
                          />
                        )}
                        description={(
                          <Locale.Help
                            tkey={`secretary.exit.status.${CandidateFileStatusEnum.WAITING_CANDIDATE}`}
                          />
                        )}
                      />
                      {/* Les états VALID et VALID_WITH_ERRORS ne sont proposés qu'aux secrétaires avec le droit plusSecretary */
                      }
                      {userCtx?.user?.plusSecretary && (
                        <Fragment>
                          <Steps.Step
                            data-testid={CandidateFileStatusEnum.VALID_WITH_ERRORS}
                                  /* ok */ disabled={candidateFile?.status?.id === CandidateFileStatusEnum.VALID_WITH_ERRORS}
                            title={(
                              <Locale.Button
                                tkey={`secretary.exit.status.${CandidateFileStatusEnum.VALID_WITH_ERRORS}`}
                              />
                            )}
                            description={(
                              <Locale.Help
                                tkey={`secretary.exit.status.${CandidateFileStatusEnum.VALID_WITH_ERRORS}`}
                              />
                            )}
                          />
                          <Steps.Step
                            data-testid={CandidateFileStatusEnum.VALID}
                                  /* ok */ disabled={candidateFile?.status?.id === CandidateFileStatusEnum.VALID}
                            title={(
                              <Locale.Button
                                tkey={`secretary.exit.status.${CandidateFileStatusEnum.VALID}`}
                              />
                            )}
                            description={(
                              <Locale.Help
                                tkey={`secretary.exit.status.${CandidateFileStatusEnum.VALID}`}
                              />
                            )}
                          />
                        </Fragment>
                      )}
                    </Steps>
                  </Form.Item>
                )}
            </Form>
          </div>
        </article>

      </Style>
    </Modal>
  );
}
