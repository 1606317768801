/** @jsx jsx */
import {jsx} from '@emotion/core';
import {UserRoleEnum} from '@fstn/ecandidaturev2_api-interfaces';
import {Fragment, useContext} from 'react';
import {UserContext} from '../../../context/user.context';

export function IsSecretary(props: { children: any, elseChildren?: any }) {
    const { userCtx } = useContext(UserContext);
    return (
      <Fragment>
        {userCtx.user?.role?.name === UserRoleEnum.SECRETARY && props.children}
        {userCtx.user?.role?.name !== UserRoleEnum.SECRETARY && props.elseChildren}
      </Fragment>
    );
}
