/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment, useContext} from 'react';
import {CandidateFileContext, CandidateFileContextProviderType} from '../../canSeeCandidateFile/CandidateFileContext';

export function IsCandidateFileSelected(props: { children: any, elseChildren?: any }) {
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    return (
      <Fragment>
        {candidateFileContext?.selectedCandidateFile && (props.children)}
        {!candidateFileContext?.selectedCandidateFile && (props.elseChildren)}
      </Fragment>
    );
}
