import { CheckCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";
import * as Locale from '../common/locale';

const { confirm } = Modal;

/**
 * Cette petite fonction permet d'afficher un popup signalant la fin de la publication des avis jury avec le detail (nombre de succès et liste des échecs)
 */
export function endPublishJuryReviewPopup(title: string, content: any) {
  const popupTitle = `candidateFile.${title}.finished`
  confirm({
    title: <Locale.Notification tkey={popupTitle} />,
    icon: <CheckCircleOutlined />,
    content: content,
    width: '700px',
    cancelButtonProps: { disabled: true, style: { display: 'none' } }
  })
}