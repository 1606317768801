/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css, jsx } from '@emotion/core';
import { CandidateFilesStatistics } from '../canSeeCandidateFile/CandidateFileStatistics';
import { FullScreenProgramManagerPage } from './ProgramManagerPage';

/**
 * Routes starting with /home/welcome/program_manager/
 * @constructor
 */
export const ProgramManagerContainerRoutesDef = () => ({
  '/statistics': () => (
    <FullScreenProgramManagerPage>
      <div css={css`margin: 20px`}>
        <CandidateFilesStatistics />
      </div>
    </FullScreenProgramManagerPage>
  ),

});
