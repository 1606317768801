// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import { FormLayout } from 'antd/lib/form/Form';
import React from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';

export const PDFEntityFormListContext = React.createContext([undefined, undefined] as [PDFEntityFormListContextType<any>, any]);

const Container = styled(View)`
border: 1px solid #EEE;
`;

export interface PDFEntityFormListContextType<T> {
    entity: string;
    entityValue: T;
    layout?: FormLayout,
}

function Content(props: { children: any }) {
    return <Container>{props.children}</Container>;
}

export const PDFEntityFormListContextProvider = (props: {
    entityValue?: any,
    children: any,
}) => {
    const [state, updateState] = useImmer({
        entityValue: props.entityValue,
    });

    useDeepCompareEffect(() => {
        updateState((draft) => {
            draft.entityValue = props.entityValue;
        });
    }, [props.entityValue, updateState]);

    return (
      <PDFEntityFormListContext.Provider
        value={[state as PDFEntityFormListContextType<any>, updateState]}
      >
        <Content>{props?.children}</Content>
      </PDFEntityFormListContext.Provider>
    );
};
