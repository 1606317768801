import React, { Fragment } from 'react';

export const configsListInitialColumns = () => [
    {
        header: 'Date',
        name: 'eventDate',
        defaultFlex: 1,
        editable: false,
        style: { textAlign: 'left' },
    },
    {
        header: 'Total créé',
        name: 'totalCreated',
        type: 'number',
        defaultFlex: 1,
        editable: false,
        style: { textAlign: 'center' },
    },
    {
        header: 'Total soumis',
        name: 'totalSubmitted',
        type: 'number',
        defaultFlex: 1,
        editable: false,
        style: { textAlign: 'center' },
    },
];

export const configsListInitialFilters = [
    {
        name: 'event_date', operator: 'contains', type: 'string', value: '',
    },
];

export function configsListRenderRowContextMenu() {
    return (
        <Fragment />
    );
}

/**
 * Map result to row
 * @param d
 */
export function configsListMapToRow(d: any) {
    const row: any = {
        eventDate: d.eventDate.format('DD/MM/YYYY'),
        totalCreated: d.totalCreated,
        totalSubmitted: d.totalSubmitted
    };
    return row;
}
