/** @jsx jsx */
import { CheckCircleFilled } from '@ant-design/icons';
import { jsx } from '@emotion/core';
import React from 'react';
import { TextButton } from './TextButton';

/**
 * Simple Warning Button
 * @param tkey
 * @param props
 * @constructor
 */
export function TextWarningButton({ tkey, ...props }:any) {
    return <TextButton type="warning" icon={<CheckCircleFilled />} {...props} tkey={tkey} />;
}
