import { useContext } from 'react';
import { ConfigContext, ConfigContextType } from '../context/config.context';

export function useConfig() {
    const { configCtx } = useContext<ConfigContextType>(ConfigContext);

    const maxLimit = (property) => {
        const res = configCtx.config.number?.find?.((o) => o.id === `MAX_${property?.toUpperCase()}`)?.value;
        return res;
    };
    const date = (key) => configCtx.config.date?.filter?.((o) => o.id === key?.toUpperCase())?.map((v) => new Date(Date.parse(v.value)))[0];
    return [{
        maxLimit, date, config: configCtx.config,
    }];
}
