import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import React, { MutableRefObject } from 'react';
import { getSelectedCandidateFiles } from '../canSeeCandidateFile/candidateFilesList/CandidateFilesList';
import { SpecializedSecretaryRowContextMenu } from './SpecializedSecretaryRowContextMenu';
import { emailColumn, emailFilter } from '../canSeeCandidateFile/candidateFilesList/columns/EmailColumn';
import {
    commentsBtwAllTypesOfSecretariesColumn,
    commentsBtwAllTypesOfSecretariesFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/CommentsBtwAllTypesOfSecretariesColumn';
import { avatarColumn } from '../canSeeCandidateFile/candidateFilesList/columns/AvatarColumn';
import { shortIdColumn, shortIdFilter } from '../canSeeCandidateFile/candidateFilesList/columns/ShortIdColumn';
import { firstNameColumn, firstNameFilter } from '../canSeeCandidateFile/candidateFilesList/columns/FirstNameColumn';
import {
    candidateFileStatusColumn,
    candidateFileStatusFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/CandidateFileStatusColumn';
import { lastNameColumn, lastNameFilter } from '../canSeeCandidateFile/candidateFilesList/columns/LastNameColumn';
import {
    juryReviewStatusColumn,
    juryReviewStatusFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/JuryReviewStatusColumn';
import {
    specializedSecretaryReviewCommentsColumn,
    specializedSecretaryReviewCommentsFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/CommentsBtwSecretariesColumn';
import { lastDegreeColumn, lastDegreeFilter } from '../canSeeCandidateFile/candidateFilesList/columns/LastDegreeColumn';
import {
    submittedAtColumn,
    submittedAtFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/SubmittedAtColumn';
import {
    lastUpdatedAtColumn,
    lastUpdatedAtFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/LastUpdatedAtColumn';
import {
    particularCaseColumn,
    particularCaseFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/ParticularCaseColumn';
import { ignoreItColumn, ignoreItFilter } from '../canSeeCandidateFile/candidateFilesList/columns/IgnoreItColumn';

export const CandidateFilesListInitialColumns = (t) => [
    { ...avatarColumn, defaultVisible: true },
    { ...shortIdColumn, defaultVisible: true },
    lastNameColumn,
    firstNameColumn,
    { ...emailColumn, defaultLocked: 'start' },
    { ...lastDegreeColumn, defaultLocked: 'start' },
    /* */
    specializedSecretaryReviewCommentsColumn,
    commentsBtwAllTypesOfSecretariesColumn,
    candidateFileStatusColumn,
    particularCaseColumn,
    ignoreItColumn,
    juryReviewStatusColumn,
    submittedAtColumn,
    lastUpdatedAtColumn,
];

export const CandidateFilesListInitialFilters = [
    shortIdFilter,
    firstNameFilter,
    lastNameFilter,
    emailFilter,
    candidateFileStatusFilter,
    commentsBtwAllTypesOfSecretariesFilter,
    specializedSecretaryReviewCommentsFilter,
    lastDegreeFilter,
    particularCaseFilter,
    juryReviewStatusFilter,
    ignoreItFilter,
    submittedAtFilter,
    lastUpdatedAtFilter,
];

export function renderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedCandidateFiles) {
    return (
        <SpecializedSecretaryRowContextMenu
            menuProps={menuProps}
            details={details}
            selectedCandidateFiles={getSelectedCandidateFiles(details.rowProps.data, selectedCandidateFiles)}
        />
    );
}
