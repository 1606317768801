import { usePath, useQueryParams } from 'hookrouter';
import React, { Fragment, useContext } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import { DocumentUtils } from '@fstn/ecandidaturev2_api-interfaces';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import {
    CandidateFileListContext,
    CandidateFileListContextProviderType,
} from '../../canSeeCandidateFile/CandidateFileListContext';
import { CandidateFilePDF } from './CandidateFilePDF';
import { CandidateFileForExport } from './export.type';
import { StrictLoading } from '../../../common/indicator/SmartLoading';
import { getDocument, toDataURL } from './utils';

export function SecretaryPageCandidateFilePDF() {
    const { candidateFileListContext } = useContext<CandidateFileListContextProviderType>(CandidateFileListContext);
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const [state, updateState] = useImmer({ candidateFiles: undefined as CandidateFileForExport[] });
    const path = usePath();
    const [query] = useQueryParams();

    useDeepCompareEffect(() => {
        async function load() {
            if (!query.segmentId) {
                return;
            }
            const res = await axios.put('export/secretary/candidate-files/json', {
                groups: [],
            }, { params: { segmentId: query.segmentId } });

            // We load avatar here to keep using http cookie
            for (const candidateFile of res.data) {
                // eslint-disable-next-line no-await-in-loop
                candidateFile.avatarAsBase64 = (await toDataURL(DocumentUtils.getPreviewLink(getDocument(candidateFile), ''))) as any;
            }
            updateState((draft) => {
                draft.candidateFiles = res.data;
            });
        }

        load().then();
    }, [candidateFileListContext.selectedCandidateFiles, path, query.segmentId]);

    return (
        <StrictLoading loading={!state.candidateFiles} tip="Secretary Page CandidateFile PDF">
            <Fragment>
                <CandidateFilePDF candidateFiles={state.candidateFiles} />
            </Fragment>
        </StrictLoading>
    );
}
