/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import styled from '@emotion/styled';
import {PageHeader, PageHeaderProps} from 'antd';

const HeaderStyle = styled.div`
grid-area: header;
background-color: #FAFAFA;

.ant-page-header-heading{
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.ant-page-header{
  padding: 0 !important;
}
`;

export function UserPageHeader(props: PageHeaderProps & { children?: any }) {
    return (
      <HeaderStyle>
        <PageHeader
          {...props}
          className="site-page-header"
          extra={(
            <div css={css`
display: flex;
gap: 1em;
justify-content: flex-end;
position:absolute;
right:10px;
min-width: 500px;
align-items: center;
height: 100%;
text-align: right;`}
            >
              {props.extra}
            </div>
                )}
        />
        {props.children}
      </HeaderStyle>
    );
}
