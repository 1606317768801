/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import Form from 'antd/lib/form';
import * as React from 'react';
import { useRef } from 'react';
import { omit } from 'lodash';
import { ConditionnalPopconfirm } from '../../antd/ConditionnalPopconfirm';
import * as Locale from '../../locale';
/**
 * @see EntityFormItemContainer
 * */

/**
 * 
 * @param props labelVisible positionne a false permet de ne pas afficher le label du composant lie a INPUT, RADIO, etc.
 * @returns 
 */
export function EntityFormItem(props:{
    disabled:boolean,
    canShowDisabledPopup:boolean,
    edit:(e?: React.MouseEvent<HTMLElement>) => void,
    itemProps:any,
    labelVisible?: boolean,
    children:any }) {
    const redraw = useRef({ count: 0 });
    const Style = styled.div`
.ant-form-item-children-icon{
       // right: -30px !important ;
       position: static
    }
    .ant-select-arrow{
        margin-right: 40px;
    }
    .ant-input-group-addon > .ant-select .ant-select-arrow, .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
            .ant-select-clear{
              z-index:20;
              font-size:18px;
              margin-top: -9px !important;
              margin-right: 7px !important;
            }   
    .ant-form-item-children-icon{
       // display: none;
    }
   .ant-form-item-control-input:hover> .ant-form-item-children-icon{
       display: none !important;
    }
    flex: 1;
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
    .ant-input[readonly],
    input[disabled],
    .ant-input[disabled],
    .ant-cascader-picker-label{
        color: black !important
    }
    /*Mask validation check*/
    .ant-form-item-children-icon{    
      //  display:${props.disabled ? 'none' : ''}
    
`;
    redraw.current.count += 1;
    return (
      <Style>
        {/* <span css={css`position:absolute`}>{ redraw.current.count }</span> */}
        <ConditionnalPopconfirm
          /* ok */disabled={(props.canShowDisabledPopup === false || props.disabled === false)}
          title={<Locale.Title tkey="form.edit" />}
          onConfirm={props.edit}
          okText={<Locale.Button tkey="ok" />}
          cancelText={<Locale.Button tkey="cancel" />}
        >
          {/* Si on ne doit pas afficher le label (champs de saisie moyenne, rank, etc. dans tableau page referent par exemple), on met l'attribut "noStyle" */}
          <Form.Item 
            {...omit(props.itemProps, 'skipRerenderOnValidateChange', 'dependenciesValues', 'onAfterChange', 'valueMapper')}
            {...props.labelVisible === false && ({"noStyle": true})}
          >
            {props?.children}
          </Form.Item>
        </ConditionnalPopconfirm>
      </Style>
    );
}
