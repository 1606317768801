import React, {useContext} from 'react';
import {UserContext, UserContextType} from '../../context/user.context';
import {useSafeTranslation} from '../../hooks/use-safe-translation';
import {CandidateFilesList} from '../canSeeCandidateFile/candidateFilesList/CandidateFilesList';
import {
    CandidateFilesListInitialColumns,
    CandidateFilesListInitialFilters,
    renderRowContextMenu,
} from './CandidateFilesListInitialConfig';

export function SecretaryCandidateFilesList(props: { visible:boolean }) {
    const { t } = useSafeTranslation();
    const { userCtx } = useContext<UserContextType>(UserContext);
    return (
      <CandidateFilesList
        visible={props.visible}
        initialColumns={CandidateFilesListInitialColumns(t, userCtx)}
        renderRowContextMenu={renderRowContextMenu}
        gridStyle={{ height: 'calc( 100% - 100px)' }}
        initialFilters={CandidateFilesListInitialFilters}
      />
    );
}
