import React, { Fragment } from 'react';
import { PDFH1 } from '../../../common/pdf/components/PDFH1';
import { PDFEntityForm } from '../../../common/pdf/form/PDFEntityForm';
import { PDFInputFormItem } from '../../../common/pdf/form/PDFInputFormItems';
import { ApprenticeshipCompany } from './export.type';
import { PDFSpacer } from '../../../common/pdf/components/PDFSpacer';
import { PDFH3 } from '../../../common/pdf/components/PDFH3';

export function ApprenticeshipCompanyFormPDF(props: { apprenticeshipCompany: ApprenticeshipCompany, debug?: boolean }) {
  if (!props.apprenticeshipCompany) {
    return (
      <PDFH1>
        Recherche d'entreprise d'accueil pour
        l'apprentissage
      </PDFH1>
    );
  }
  return (
    <Fragment>
      <PDFH1>
        Recherche d'entreprise d'accueil pour
        l'apprentissage
      </PDFH1>
      <PDFEntityForm
        entity="apprenticeship-company"
        value={props.apprenticeshipCompany}
      >
        <Fragment>
          <PDFH3>Je connais déjà une entreprise prête à m'accueillir en contrat d'apprentissage :
            {props.apprenticeshipCompany.found && ' Oui'}{props.apprenticeshipCompany.found === null && ' Non spécifié'}
            {props.apprenticeshipCompany.found === false && ' Non'}
          </PDFH3>
          {props.apprenticeshipCompany.found
            && (
              <Fragment>
                <PDFInputFormItem propertyName="name" debug={props.debug} />
                <PDFInputFormItem propertyName="address" debug={props.debug} />
                {/*<PDFInputFormItem propertyName="lastName" debug={props.debug} />
                <PDFInputFormItem propertyName="phone" debug={props.debug} />
                <PDFInputFormItem propertyName="email" debug={props.debug} />
            <PDFInputFormItem propertyName="jobTitle" debug={props.debug} />*/}
              </Fragment>
            )}
          <PDFSpacer />
          <PDFH3>Je souhaite que l'école m'accompagne dans ma recherche d'entreprise :
            {props.apprenticeshipCompany.needHelp && ' Oui'}{props.apprenticeshipCompany.needHelp === null && ' Non spécifié'}
            {props.apprenticeshipCompany.needHelp === false && ' Non'}
          </PDFH3>
          {props.apprenticeshipCompany.needHelp
            && (
              <Fragment>
                <PDFInputFormItem propertyName="desiredProfile" debug={props.debug} />
                <PDFInputFormItem propertyName="type" debug={props.debug} />
                <PDFInputFormItem propertyName="searchArea" debug={props.debug} />
                <PDFInputFormItem layout="vertical" propertyName="remarks" debug={props.debug} />
              </Fragment>
            )}
        </Fragment>
      </PDFEntityForm>
    </Fragment>
  );
}
