import {
  CheckCircleOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FolderOpenOutlined,
  TableOutlined,
} from '@ant-design/icons';
import Menu from '@inovua/reactdatagrid-community/packages/Menu';
import { Modal } from 'antd';

// @ts-ignore
import React from 'react';
// @ts-ignore
// @ts-ignore
import * as Locale from '../../common/locale';
import { AxiosContextProvider } from '../../context/axios.context';
import { IhmContextProvider } from '../../context/ihm.context';
import { useLoading } from '../../hooks/use-loading';
import { DocumentDownloaderTypeSelector } from '../canDownloadDocuments/documentsDownloader/DocumentDownloaderTypeSelector';
import { ROUTES } from '../user/UserRoutesDef';
import { StrictLoading } from '../../common/indicator/SmartLoading';
import { useExaminerUtils } from './use-examiner-utils';
import { useExportUtils } from '../../hooks/use-export-utils';
import { useSegment } from '../../hooks/use-segment';
import { endExportPopup } from '../../utils/endExportPopup';
import { sNavigate } from '../../utils/safeNavigation';
import { useConfig } from '../../hooks/use-config.hook';

const { confirm } = Modal;

export function ExaminerRowContextMenu({ menuProps, details, selectedCandidateFiles }: any) {
  const { doAction, loading } = useLoading();
  const { navigateToExaminerReview, navigateToCandidateFile } = useExaminerUtils();
  const { downloadExport } = useExportUtils();
  const { segment } = useSegment(selectedCandidateFiles);

  const candidateFileId = details.rowProps.data.id;
  const items = [];
  const [{ maxLimit }] = useConfig();
  const maxExportZipFiles = maxLimit('EXPORTZIP_FILES') || 15;
  const maxPdfSummaryFiles = maxLimit('PDFSUMMARY_FILES') || 50;

  if (selectedCandidateFiles.length === 1) {
    items.push({
      label: <Locale.Button tkey="candidateFile.examiner.doReview" />,
      icon: <CheckCircleOutlined />,
      onClick: async (value) => {
        await doAction(async () => {
          await navigateToExaminerReview(candidateFileId);
        });
      },

    }, {
      label: <Locale.Button tkey="candidateFile.open" />,
      icon: <FolderOpenOutlined />,
      onClick: async (value) => {
        await doAction(async () => {
          await navigateToCandidateFile(candidateFileId);
        });
      },
    });
  }

  // Pour les générations de FS en pdf, on limite à MAX_PDFSUMMARY_FILES car sinon, c'est trop long à generer
  if (selectedCandidateFiles.length >= 1 && selectedCandidateFiles.length <= maxPdfSummaryFiles) {
    items.push({
      label: <Locale.Button tkey="candidateFile.PDF" />,
      icon: <FilePdfOutlined />,
      className: 'openPDF',
      onClick: async () => {
        await doAction(async () => {
          sNavigate(`${ROUTES.EXAMINER_BASE}/pdf`, true, { segmentId: segment.id });
        });
      },
    })
  }
  if (selectedCandidateFiles.length >= 1) {
    items.push({
      label: <Locale.Button tkey="candidateFile.XLS" />,
      icon: <TableOutlined />,
      onClick: async (value) => {
        await doAction(async () => {
          await downloadExport(segment.id, []);
          endExportPopup('downloadXls');
        });
      },
    })
  }
  // Pour les generations d'export fichiers en zip, on limite a MAX_EXPORTZIP_FILES car sinon, ca sature le serveur en process et surtout memoire 
  if (selectedCandidateFiles.length >= 1 && selectedCandidateFiles.length <= maxExportZipFiles) {
    items.push({
      label: <Locale.Button tkey="candidateFile.downloadFiles" />,
      icon: <DownloadOutlined />,
      className: 'openFiles',
      onClick: async () => {
        confirm({
          // @ts-ignore
          title: <Locale.Notification tkey="candidateFile.downloadFiles.selectType" />,
          icon: <ExclamationCircleOutlined />,
          width: 600,
          okButtonProps: { disabled: true, style: { display: 'none' } },
          content: (
            <div>
              <AxiosContextProvider>
                <IhmContextProvider>
                  <DocumentDownloaderTypeSelector
                    details={details}
                    segmentId={segment.id}
                  />
                </IhmContextProvider>
              </AxiosContextProvider>
            </div>
          ),
        });
      },
    });
  }

  return (
    <StrictLoading loading={loading}>
      <Menu
        {...menuProps}
        items={items}
      >
      </Menu>
    </StrictLoading>
  );
}
