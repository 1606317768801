/* eslint-disable react-hooks/exhaustive-deps */
/** @jsx jsx */
import { SaveOutlined } from '@ant-design/icons';
import { css, jsx } from '@emotion/core';
import React, { useCallback, useMemo, useRef } from 'react';
import Viewer from 'react-viewer';
import { ToolbarConfig } from 'react-viewer/lib/ViewerProps';
import { useImmer } from 'use-immer';
import { useEntity } from '../../hooks/use-entity';
import { InlineLoading } from '../indicator/SmartLoading';

const style = css`
height: 100%;
width: 100%;
.react-viewer-close.react-viewer-btn,.react-viewer-mask,.react-viewer-navbar{
  display: none;
}
.react-viewer-inline{
min-height: 100px;
}
`;

/**
 * InlineImageViewer: uses to display image on document viewer (secretary side)
 * @param props
 * @constructor
 */
export function InlineImageViewer(props: {
    url: any;
    documentId: string;
}) {
    const { postEntity } = useEntity();

    const ref = useRef(undefined);
    const [state, updateSate] = useImmer({ container: undefined });
    React.useEffect(() => {
        updateSate((draft) => {
            draft.container = ref.current;
        });
    }, [updateSate, ref]);

    const getCustomViewerToolbar = useCallback((defaultToolbar: ToolbarConfig[]) => {
        const calculateImageRotation = (image, degree) => {
            image.rotation = (image.rotation || 0) + degree;
        };

        defaultToolbar.find(({ key }) => key === 'rotateRight').onClick = (image) => {
            calculateImageRotation(image, 90);
        };
        defaultToolbar.find(({ key }) => key === 'rotateLeft').onClick = (image) => {
            calculateImageRotation(image, -90);
        };
        // Si le bouton d'enregistrement des modifications (rotation) de l'image n'est pas présent, on l'ajoute
        if (!defaultToolbar.some((t) => t.actionType === 1016514654650)) {
            defaultToolbar.push({
                actionType: 1016514654650,
                key: 'save',
                async onClick(activeImage:
                    any) {
                    await postEntity('document/rotate', { id: props.documentId }, {
                        id: props.documentId,
                        rotationAngle: activeImage.rotation,
                    } as any);
                },
                render: <SaveOutlined />,
            });
        }
        // Cela peut sembler faire double emploi avec le bloc ci-dessus, mais c'est nécessaire car si on visualise plusieurs images consécutivement
        // le parametre documentId passé dans la callback conserve celui de la première image visualisée donc on ne modifie pas la bonne image
        defaultToolbar.find(({ actionType }) => actionType === 1016514654650).onClick = async (activeImage: any) => {
            await postEntity('document/rotate', { id: props.documentId }, {
                id: props.documentId,
                rotationAngle: activeImage.rotation,
            } as any);
        };

        return defaultToolbar;
    }, []);

    return useMemo(() => (
        <div css={style} ref={ref}>
            {state.container
                && (
                    <Viewer
                        visible
                        noClose
                        scalable={false}
                        changeable={false}
                        noResetZoomAfterChange
                        loop={false}
                        noImgDetails
                        container={state.container}
                        showTotal={false}
                        customToolbar={getCustomViewerToolbar}
                        className="preview container text-center"
                        images={[{ src: props.url, alt: '' }]}
                    />
                )}
            <InlineLoading loading={!state.container} context="InlineImageViewer" />
        </div>
    ), [getCustomViewerToolbar, props.url, state.container]);
    // loop add getCustomViewerToolbar
}
