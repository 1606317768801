/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useState } from 'react';
import { Importer, ImporterField } from 'react-csv-importer-v2';

import 'react-csv-importer-v2/dist/index.css';
import { useImmer } from 'use-immer';
import { AxiosContext, AxiosContextType } from '../../../../context/axios.context';
import { sNavigateReload } from '../../../../utils/safeNavigation';
import { ADMIN } from '../../../user/UserRoutesDef';
import { PaymentCheckerPage } from './PaymentCheckerPage';

export function PaymentChecker() {
  const [state, updateState] = useImmer({
    values: [],
    error: undefined as string,
    result: undefined as string,
  });
  const [checkResult, setCheckResult] = useState();
  const [loading, setLoading] = useState(true);
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  return (
    <Fragment>
      <Importer
        delimiter=";"
        onStart={() => {
          setLoading(true);
        }}
        processChunk={async (rows) => {
          updateState((draft) => {
            draft.values = [...draft.values, ...rows];
          });
        }}
        onComplete={async () => {
          setLoading(false);
          try {
            const response: any = await axios?.post('/import/payment-checker', {
              bulk: state.values,
            });
            setCheckResult(response.data);
            updateState((draft) => {
              draft.result = `${response.data} payment checker`;
              draft.error = undefined;
            });
          } catch (ex) {
            updateState((draft) => {
              draft.error = ex.response.data.message;
              draft.result = undefined;
            });
          }
        }}
        onClose={async () => {
          sNavigateReload(ADMIN.PAYMENT_CHECKER_IMPORTER);
        }}
      >
        <ImporterField name="tansactionId" label="Transaction" />
        <ImporterField name="candidateFileId" label="Commande" />
        <ImporterField name="email" label="E-mail acheteur" />
        <ImporterField name="amount" label="Montant du paiement" />
        <ImporterField name="statut" label="Statut" />
      </Importer>
      {!loading
        && (
          <Fragment>
            <span><h1>Résultats de la comparaison des paiements existants</h1></span>
            <PaymentCheckerPage importResult={checkResult} />
          </Fragment>
        )}
    </Fragment>
  );
}
