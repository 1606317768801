import { ISchool } from '@fstn/ecandidaturev2_api-interfaces';
import React from 'react';

import { ColdDataAutoCompleteFormItem } from './ColdDataAutoCompleteFormItem';

/**
 * EntityFormItemContainer for chool coldData autocomplete input
 * @param props
 * @constructor
 */
export function SchoolAutoCompleteFormItem(props: {
    propertyName?: any,
    initialValue?: any,
    hidden?: boolean,
    required?: boolean,
    rules?: any,
    onChange?: Function,
    schoolTrack?: any
    mapper?: ((v: ISchool) => any),
    dependenciesValues?: any[],
    allowClear?: boolean,
    dependencies?: any[],
}) {
    return (
      <ColdDataAutoCompleteFormItem
        api="schools"
        propertyName={props.propertyName}
        initialValue={props.initialValue}
        hidden={props.hidden}
        rules={props.rules}
        required={props.required}
        onChange={props.onChange}
        mapper={props.mapper}
        allowClear={props.allowClear}
        dependenciesValues={props.dependenciesValues}
        dependencies={props.dependencies}
        filter={(s: ISchool) => {
                if (!props.schoolTrack) {
                    return true;
                }
                return s.tracks?.some((t) => t.id === props.schoolTrack?.id);
            }}
      />
    );
}
