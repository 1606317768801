/** @jsx jsx */
import {
  SettingOutlined, UploadOutlined, UsergroupAddOutlined, UserSwitchOutlined,
} from '@ant-design/icons';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
// @ts-ignore
import { usePath } from 'hookrouter';
import _ from 'lodash';
import React, { HTMLAttributes } from 'react';
import { sNavigate } from '../../utils/safeNavigation';
// noinspection,TypeScriptCheckImport

// @ts-ignore
// language=LESS prefix=*{ suffix=}
const Style: any = styled.div`
    min-width: 260px;
    grid-area: left;

    .ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 10px;
    }
`;

export type LeftMenuProps = HTMLAttributes<HTMLElement>;

export const AdminLeftMenu: React.FunctionComponent<LeftMenuProps> = () => {
  const path = usePath();

  const navigateTo = (menuEvent: any) => {
    // eslint-disable-next-line no-useless-escape
    sNavigate(`/home/welcome/admin/${menuEvent.key}`);
  };

  return (
    <Style>
      <Menu
        onClick={navigateTo}
        style={{ width: 'auto' }}
        selectedKeys={[(`${_.last(path.split('/'))}`).replace('/', '')]}
        defaultOpenKeys={['candidate-file']}
        mode="inline"
      >
        <SubMenu key="params" icon={<SettingOutlined />} title="Paramètres applicatifs">
          <Menu.Item
            key="localeList"
            eventKey="localeList"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Gestion des traductions"
          >
            Gestion des traductions
          </Menu.Item>
          <Menu.Item
            key="localeEdit"
            eventKey="localeEdit"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Création d'une traduction"
          >
            Création d'une traduction
          </Menu.Item>
          <Menu.Item
            key="numberConfigList"
            eventKey="numberConfigList"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Paramètres numériques"
          >
            Paramètres numériques
          </Menu.Item>
          <Menu.Item
            key="stringConfigList"
            eventKey="stringConfigList"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Paramètres chaînes"
          >
            Paramètres chaînes
          </Menu.Item>
          <Menu.Item
            key="dateConfigList"
            eventKey="dateConfigList"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Paramètres dates"
          >
            Paramètres dates
          </Menu.Item>
          <Menu.Item
            key="juryReviewStatusList"
            eventKey="juryReviewStatusList"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Statuts avis jury"
          >
            Statuts avis jury
          </Menu.Item>
        </SubMenu>

        <SubMenu key="other" icon={<SettingOutlined />} title="Divers">
          <Menu.Item
            key="impersonate"
            eventKey="impersonate"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Reconnexion "en tant que"'
          >
            Reconnexion "en tant que"
          </Menu.Item>
          <Menu.Item
            key="cleanCloseProgram"
            eventKey="cleanCloseProgram"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Suppression des formations fermées'
          >
            Suppression des formations fermées
          </Menu.Item>
          <Menu.Item
            key="paymentCheckerImporter"
            eventKey="paymentCheckerImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Vérification des paiements dossiers"
          >
            Vérification des paiements dossiers
          </Menu.Item>
          <Menu.Item
            key="testPublishJuryReviewEmail"
            eventKey="testPublishJuryReviewEmail"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Test envoi mails avis jury"
          >
            Test envoi mails avis jury
          </Menu.Item>
          <Menu.Item
            key="candidateFilesStatistics"
            eventKey="candidateFilesStatistics"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Statistiques dossiers"
          >
            Statistiques dossiers
          </Menu.Item>
        </SubMenu>

        <SubMenu key="users" icon={<UsergroupAddOutlined />} title="Gestion utilisateurs">
          <Menu.Item
            key="adminList"
            eventKey="adminList"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Gestion Administrateur"
          >
            Gestion Administrateurs
          </Menu.Item>
          <Menu.Item
            key="adminEdit"
            eventKey="adminEdit"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Création Administrateur"
          >
            Création Administrateur
          </Menu.Item>
          <Menu.Item
            key="secretaryList"
            eventKey="secretaryList"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Gestion secrétaires dossier"
          >
            Gestion Secrétaires dossier
          </Menu.Item>
          <Menu.Item
            key="secretaryEdit"
            eventKey="secretaryEdit"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Création secrétaire dossier"
          >
            Création Secrétaire dossier
          </Menu.Item>
          <Menu.Item
            key="examinerList"
            eventKey="examinerList"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Gestion examinateur"
          >
            Gestion Examinateurs
          </Menu.Item>
          <Menu.Item
            key="examinerEdit"
            eventKey="examinerEdit"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Création examinateur"
          >
            Création Examinateur
          </Menu.Item>
          <Menu.Item
            key="juryList"
            eventKey="juryList"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Gestion jury"
          >
            Gestion Jury
          </Menu.Item>
          <Menu.Item
            key="juryEdit"
            eventKey="juryEdit"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Création jury"
          >
            Création Jury
          </Menu.Item>
          <Menu.Item
            key="programManagerList"
            eventKey="programManagerList"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Gestion Responsables formation"
          >
            Gestion Responsables formation
          </Menu.Item>
          <Menu.Item
            key="programManagerEdit"
            eventKey="programManagerEdit"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Création Responsable formation"
          >
            Création Responsable formation
          </Menu.Item>
          <Menu.Item
            key="specializedSecretaryList"
            eventKey="specializedSecretaryList"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Gestion secrétaires formation"
          >
            Gestion Secrétaires formation
          </Menu.Item>
          <Menu.Item
            key="specializedSecretaryEdit"
            eventKey="specializedSecretaryEdit"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Création secrétaire formation"
          >
            Création Secrétaire formation
          </Menu.Item>
          <Menu.Item
            key="candidateEdit"
            eventKey="candidateEdit"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Création candidat"
          >
            Création Candidat
          </Menu.Item>
        </SubMenu>

        <SubMenu key="referents" icon={<UserSwitchOutlined />} title="Référents">
          <Menu.Item
            key="referentList"
            eventKey="referentList"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Gestion référents"
          >
            Gestion référents
          </Menu.Item>
          <Menu.Item
            key="referentReviewList"
            eventKey="referentReviewList"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title="Gestion demandes de reco"
          >
            Gestion demandes de reco
          </Menu.Item>
        </SubMenu>

        <SubMenu key="imports" icon={<UploadOutlined />} title="Imports">
          <Menu.Item
            key="academyImporter"
            eventKey="academyImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Import "académies"'
          >
            Import "académies"
          </Menu.Item>
          <Menu.Item
            key="bachelorSeriesImporter"
            eventKey="bachelorSeriesImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='séries du bac TECHNO (uniquement)'
          >
            Import "séries du bac TECHNO (uniquement)"
          </Menu.Item>
          <Menu.Item
            key="bachelorSpecialityImporter"
            eventKey="bachelorSpecialityImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Import "spécialités du bac"'
          >
            Import "spécialités du bac"
          </Menu.Item>
          <Menu.Item
            key="bachelorMentionImporter"
            eventKey="bachelorMentionImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Import "spécialités du bac"'
          >
            Import "mentions du bac"
          </Menu.Item>
          <Menu.Item
            key="languageImporter"
            eventKey="languageImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Import "langues"'
          >
            Import "langues"
          </Menu.Item>
          <Menu.Item
            key="languageLevelImporter"
            eventKey="languageLevelImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Import "niveaux de langues"'
          >
            Import "niveaux de langues"
          </Menu.Item>
          <Menu.Item
            key="countryImporter"
            eventKey="countryImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Import "pays"'
          >
            Import "pays"
          </Menu.Item>
          <Menu.Item
            key="trafficSourceImporter"
            eventKey="trafficSourceImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Import "sources de trafic"'
          >
            Import "sources de trafic"
          </Menu.Item>
          <Menu.Item
            key="schoolTrackTypeImporter"
            eventKey="schoolTrackTypeImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Import "familles de filières"'
          >
            Import "familles de filières"
          </Menu.Item>
          <Menu.Item
            key="schoolTrackImporter"
            eventKey="schoolTrackImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Import "filières"'
          >
            Import "filières"
          </Menu.Item>
          <Menu.Item
            key="schoolImporter"
            eventKey="schoolImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Import "établissements"'
          >
            Import "établissements"
          </Menu.Item>
          <Menu.Item
            key="userImporter"
            eventKey="userImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Import "utilisateurs"'
          >
            Import "utilisateurs"
          </Menu.Item>
          <Menu.Item
            key="programImporter"
            eventKey="programImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Import "formations"'
          >
            Import "formations"
          </Menu.Item>
          <Menu.Item
            key="previousStatisticImporter"
            eventKey="previousStatisticImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Import "statistiques"'
          >
            Import "statistiques"
          </Menu.Item>
          <Menu.Item
            key="nostudyActivityImporter"
            eventKey="nostudyActivityImporter"
            css={css`overflow: visible !important; padding-top: 0!important;`}
            title='Import "activités non étudiantes"'
          >
            Import "activités non étudiantes"
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Style>
  );
};
