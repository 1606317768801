import { AppConfigDto, walkEntityPropertyAndMapDateStrAsMoment } from '@fstn/ecandidaturev2_api-interfaces';
import moment from 'moment';
import { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { useEntity } from './use-entity';

let appConfig;

/**
 * useAppConfig: allow to retireve appConfig.
 * For example allows to retrieve openning and closing dates scoped by user role
 * @See AppConfigController
 */
export function useAppConfig(role: string) {
    const { loadEntity } = useEntity<AppConfigDto>();
    const [state, updateState] = useImmer({
        appConfig,
        alreadyLoaded: !!appConfig,
    });

    function isOpen() {
        return moment().isAfter(state.appConfig?.open) && moment().isBefore(state.appConfig?.close);
    }

    async function load() {
        appConfig = await loadEntity('app-config');
        appConfig.role = role;  // On ajoute le role dans le appConfig pour verifier qu'on a bien la config lie au role de l'user connecte
        walkEntityPropertyAndMapDateStrAsMoment(appConfig);
        updateState((draft) => {
            draft.appConfig = appConfig;
            draft.alreadyLoaded = true;
        });
    }

    useEffect(() => {
        if (!state.alreadyLoaded && !appConfig) {
            load().then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadEntity, state.alreadyLoaded, updateState]);
    // loop add

    return { ...state, isOpen, load };
}
