import {useEffect, useRef} from 'react';

export function useIsMounted() {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => { isMountedRef.current = false; };
    });
    return isMountedRef;
}
