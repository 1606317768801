import styled from '@emotion/styled';
import React from 'react';

const Style = styled.div`
display: flex;
background-color: rgb(250, 250, 250) !important;
>div{
  flex: 1;
  align-items: center;
  align-self: center;
}
#secretary-edit-button-header{
  width: 50px;
  max-width: 50px;
  flex: 0;
}
`;

export const specializedSecretaryReviewHeaderElementId = 'specialized-secretary-review-header';
export function SpecializedSecretaryReviewHeaderForm() {
    return (
      <Style>
        <div id={specializedSecretaryReviewHeaderElementId} />
      </Style>
);
}
