/** @jsx jsx */
import {jsx} from '@emotion/core';
import Card from 'antd/lib/card';
import React, {Fragment} from 'react';
import * as CurrencyFormat from 'react-currency-format';

export function PaymentLine(props: { title: any, quantity?: number, price: number }) {
    return (
      <Card>

        <div style={{
 display: 'flex', justifyContent: 'space-between', gap: '2em', minHeight: '12px',
}}
        >
          <div>
            {props.title}
            <div style={{ color: '#a8a8b2' }}>
              {props.quantity
                    && (
                    <Fragment>
                      <span>{props.quantity}</span>
                      X
                      <CurrencyFormat
                        displayType="text"
                        suffix="€"
                        value={props.price}
                      />
                    </Fragment>
)}
            </div>
          </div>
          <div style={{ color: 'rgb(66, 67, 70)', fontSize: '16px', fontWeight: 800 }}>
            <CurrencyFormat
              displayType="text"
              suffix="€"
              value={props.quantity !== undefined ? props.quantity * props.price : 1 * props.price}
            />
          </div>
        </div>
      </Card>
);
}
