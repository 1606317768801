/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { formListPropertyPathToKey } from '../../../utils/formListPropertyPathToKey';
import { ColdDataAutoCompleteContainer } from '../../coldData/ColdDataAutoCompleteContainer';
import { DisabledContext } from '../../rights/DisabledContext';
import { copyEntityFormProps, EntityFormItemContainer } from '../entityForm/EntityFormItemContainer';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

export function ColdDataAutoCompleteFormItem<T>(props: {
    api: string,
    propertyName?: any,
    params?: any,
    initialValue?: any,
    hidden?: boolean,
    required?: boolean,
    rules?: any,
    mapper?: ((v: T) => any),
    filter?: ((v: T) => boolean),
    listeners?: { dataLoaded?: (d: any[]) => void },
    allowClear?: boolean,
    onChange?: any,
    labelCol?: any,
    wrapperCol?:any,
    dependencies?: any[],
    dependenciesValues?: any[]
}) {
    // console.log('Redraw ColdDataAutoCompleteFormItem', props);
    return (
      <EntityFormItemContainer
        {...copyEntityFormProps(props)}
      >
        <ColdDataAutoCompleteFormItemContentContainer
          propertyName={props.propertyName}
          allowClear={props.allowClear}
          api={props.api}
          mapper={props.mapper}
          filter={props.filter}
          listeners={props.listeners}
        />
      </EntityFormItemContainer>
    );
}

function ColdDataAutoCompleteFormItemContentContainer(props: { propertyName: any, allowClear: boolean, api: string, value?:any, mapper: (v: any) => any,
    onChange?: any,
    filter: (v: any) => boolean, listeners: { dataLoaded?: (d: any[]) => void } }) {
    const disabled = useScopedSelector('ColdDataAutoCompleteFormItemContentContainer', DisabledContext, (c) => c?.disabled);
    return (
      <ColdDataAutoCompleteContainer
        data-testid={(`${props.propertyName}`)?.replace(/,/g, '_')}
        id={(`${props.propertyName}`)?.replace(/,/g, '_')}
        className={formListPropertyPathToKey(props.propertyName)}
        allowClear={props.allowClear}
        api={props.api}
        mapper={props.mapper}
        onChange={props.onChange}
        disabled={disabled}
        value={props.value}
        filter={props.filter}
        listeners={props.listeners}
      />
    );
}
