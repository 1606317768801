/** @jsx jsx */
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/core';
import { JuryReviewStatusEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { Button } from 'antd';
import { Moment } from 'moment';
import React from 'react';
import { camelCase } from 'lodash';
import * as Locale from '../../../common/locale';
import { IsJury } from '../../user/conditionnal/IsJury';
import { IsSecretary } from '../../user/conditionnal/IsSecretary';
import { IsSpecializedSecretary } from '../../user/conditionnal/IsSpecializedSecretary';
import { CandidateFilesListHeaderStatistic } from './CandidateFilesListHeaderStatistic';
import { CandidateFilesListHeaderStatisticGutter } from './CandidateFilesListHeaderStatisticGutter';

export function CandidateFilesListHeader(props: {
  filters: any[],
  selectedCount: number,
  filteredCount: number,
  fetchedAt: Moment,
  stats: any // dynamic type from candidate-file-row.controller,
  reFetchData: Function
}) {
  return (
    <div css={css` 
  justify-content: space-between;
  overflow: auto;
  max-width: 100%;
  display: flex;
  gap: 1em;
`}
    >
      <CandidateFilesListHeaderStatisticGutter
        tkey="candidateFilesList.stats.candidateFile"
        extra={(
          <div css={css`display: flex; flex-direction: column`}>
            <Button onClick={() => props.reFetchData()} size="large">
              <div css={css`display:flex;align-items:center;gap: 1em`}>
                <ReloadOutlined />
                <span css={css`font-size:10px`}>{props.fetchedAt?.fromNow()}</span>
              </div>
            </Button>
            <Button onClick={() => window.location.reload()} size="large">
              <div css={css`display:flex;align-items:center;gap: 1em`}>
                <FilterOutlined />
                <span css={css`font-size:10px`}>
                  <Locale.Button tkey="clear.filters" />
                  {`(${props.filters.filter((f) => !!f.value).length})`}
                </span>
              </div>
            </Button>
          </div>
        )}
      >
        <IsSecretary>
          <CandidateFilesListHeaderStatistic
            title={<Locale.Label tkey="candidateFilesList.stats.nbCandidateFiles" />}
            value={props.stats.total}
          />
        </IsSecretary>
        <CandidateFilesListHeaderStatistic
          title={<Locale.Label tkey="candidateFilesList.stats.nbFilteredCandidateFiles" />}
          value={props.filteredCount}
        />
        <CandidateFilesListHeaderStatistic
          title={<Locale.Label tkey="candidateFilesList.stats.nbSelectedCandidateFiles" />}
          value={props.selectedCount}
        />
      </CandidateFilesListHeaderStatisticGutter>

      <IsSpecializedSecretary>
        <CandidateFilesListHeaderStatisticGutter tkey="candidateFilesList.stats.status" color="#00B8DE">
          <CandidateFilesListHeaderStatistic
            title={<Locale.Label tkey="candidateFilesList.total" />}
            value={props.stats.total}
          />
          <CandidateFilesListHeaderStatistic
            title={<Locale.Label tkey="candidateFilesList.particularCase" />}
            value={props.stats.particularCase}
          />
          <CandidateFilesListHeaderStatistic
            title={<Locale.Label tkey="candidateFilesList.ignoreIt" />}
            value={props.stats.ignoreIt}
          />
        </CandidateFilesListHeaderStatisticGutter>
      </IsSpecializedSecretary>

      <IsSecretary>
        <CandidateFilesListHeaderStatisticGutter tkey="candidateFilesList.stats.status" color="#00B8DE">
          <CandidateFilesListHeaderStatistic
            title={<Locale.Label tkey="candidateFilesList.stats.new" />}
            value={props.stats.new}
          />
          <CandidateFilesListHeaderStatistic
            title={<Locale.Label tkey="candidateFilesList.stats.withoutPrograms" />}
            value={props.stats.countWithoutProgram}
          />
          <CandidateFilesListHeaderStatistic
            title={<Locale.Label tkey="candidateFilesList.stats.submittedOrForced" />}
            value={props.stats.submitted + props.stats.forced}
          />
          <CandidateFilesListHeaderStatistic
            title={<Locale.Label tkey="candidateFilesList.stats.inProgressSecretary" />}
            value={props.stats.progressSecretary + props.stats.preValidated}
          />
          <CandidateFilesListHeaderStatistic
            title={<Locale.Label tkey="candidateFilesList.stats.waitingCandidate" />}
            value={props.stats.waitingCandidate}
          />
          <CandidateFilesListHeaderStatistic
            title={<Locale.Label tkey="candidateFilesList.validOrWithError" />}
            value={props.stats.valid + props.stats.validWithErrors}
          />
        </CandidateFilesListHeaderStatisticGutter>
      </IsSecretary>

      <IsJury>
        <CandidateFilesListHeaderStatisticGutter tkey="candidateFilesList.stats.status" color="#00B8DE">
          {Object.values(JuryReviewStatusEnum).map((status) => (
            <CandidateFilesListHeaderStatistic
              key={`candidateFilesList.${status}`}
              title={<Locale.Label tkey={`candidateFilesList.${status}`} />}
              value={props.stats[camelCase(status)]}
            />
          ))}
        </CandidateFilesListHeaderStatisticGutter>
      </IsJury>

    </div>
  );
}
