/* @ts-ignore */

import { getEventServiceInstance } from '../../utils/eventService';
import { sNavigate } from '../../utils/safeNavigation';
import { EXAMINER, ROUTES } from '../user/UserRoutesDef';

export function useExaminerUtils() {
    async function navigateToExaminerReview(candidateFileId) {
        getEventServiceInstance().logEvent('review.examiner.candidate_file', { candidate_file: { id: candidateFileId } });
        sNavigate(`${EXAMINER.REVIEW.replace(':candidateFileId', `${candidateFileId}`)}`);
    }

    async function navigateToCandidateFile(candidateFileId) {
        getEventServiceInstance().logEvent('open.examiner.candidate_file', { candidate_file: { id: candidateFileId } });
        sNavigate(`${ROUTES.EXAMINER_BASE}/${candidateFileId}`);
    }

    return {
         navigateToExaminerReview, navigateToCandidateFile,
    };
}
