/** @jsx jsx */
import { jsx } from '@emotion/core';
// @ts-ignore
import React, { useContext } from 'react';
import { useImmer } from 'use-immer';
import { useSafeTranslation } from '../hooks/use-safe-translation';
import { useUser } from '../hooks/use-user.hook';
import { LoginRoutesDef } from '../pages/anonymous/LoginRoutesDef';
import { UserContext, UserContextType } from './user.context';

/**
 *  LAURENT
 *  Use the React context API to stock navigation route Context (see all the files ended by RoutesDef)
 *  https://fr.reactjs.org/docs/context.html
 */
type RoutesContextTypeContent = {
    routes?: any,
    writableRoutesPath?: any,
    routesName: string,
    token?: string };
export type RoutesContextType = { routesCtx?: RoutesContextTypeContent, updateRoutesCtx?: any };
export const RoutesContext = React.createContext({} as RoutesContextType);

export const RoutesContextProvider = (props: { children: any }) => {
    const userCtx = useContext<UserContextType>(UserContext);
    const { unloadMe } = useUser(userCtx);
    const { t } = useSafeTranslation();
    const [routesCtx, updateRoutesCtx] = useImmer<RoutesContextTypeContent>({ routes: LoginRoutesDef(t, unloadMe), routesName: 'LOGIN' });
    return (
      <RoutesContext.Provider value={{ routesCtx, updateRoutesCtx }}>
        {props?.children}
      </RoutesContext.Provider>
    );
};
