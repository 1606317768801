/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useContext } from 'react';
// @ts-ignore
import useMobileDetect from 'use-mobile-detect-hook';
import { WithInitialLoad } from '../../common/withExtraParameters/WithInitialLoad';
import { UserContext } from '../../context/user.context';
import { CandidateFileContextProvider } from '../canSeeCandidateFile/CandidateFileContext';
import { CenterAreaContainer } from '../canSeeDocuments/viewer/CenterAreaContainer';
import { CandidatePageHeader } from './CandidatePage';

export function FullScreenCandidatePage(props: { children: any }) {
    const { userCtx } = useContext(UserContext);
    const detectMobile = useMobileDetect();
    const CandidatePageStyle = detectMobile.isMobile()
        ? styled.div`
            height: 100%;`
        : styled.div`
            height: 100%;
`;

    return (
      <WithInitialLoad entity="candidate-file" entityId={userCtx.user.candidateFile.id} propsName="selectedCandidateFile">
        <CandidateFileContextProvider>
          <CandidatePageStyle>
            <CandidatePageHeader />
            <div css={css`height:calc( 100% - 150px);`}>
              <CenterAreaContainer>
                {props?.children}
              </CenterAreaContainer>
            </div>
          </CandidatePageStyle>
        </CandidateFileContextProvider>
      </WithInitialLoad>
);
}
