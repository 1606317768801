/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FormLayout } from 'antd/lib/form/Form';
import { Fragment, HTMLAttributes } from 'react';
import { Box, Flex } from 'rebass';
import { useDeepCompareCallback } from 'use-deep-compare';
import UUID from 'uuid-js';

import { EntityFormContainer } from '../../../common/form/entityForm/EntityFormContainer';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { FormStyle } from '../../../common/form/FormStyle';
import { ColdDataAutoCompleteFormItem } from '../../../common/form/inputFormItems/ColdDataAutoCompleteFormItem';
import { ColdDataSelectorFormItem } from '../../../common/form/inputFormItems/ColdDataSelectorFormItem';
import { FixSelectFormItem } from '../../../common/form/inputFormItems/FixSelectFormItem';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import { InputNumberFormItem } from '../../../common/form/inputFormItems/InputNumberFormItem';
import { UploadFormItemContainer } from '../../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import { SecretaryReviewFormFooterContainer } from '../../../common/form/secretaryReviewForm/SecretaryReviewFormFooterContainer';
import { WithSwitchToReadOnlyOnDisplayForSecretary } from '../../../common/withExtraParameters/WithSwitchToReadOnlyOnDisplayForSecretary';
import { WithInitialLoad } from '../../../common/withExtraParameters/WithInitialLoad';
import { YesNoFormItem } from '../../../common/form/inputFormItems/YesNoFormItems';
import * as Locale from '../../../common/locale';
import { EntityFormContext } from '../../../context/entityForm.context';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { IsSecretary } from '../../user/conditionnal/IsSecretary';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { DisabledContextProviderForEntityForm } from '../../../common/rights/DisabledContextProviderForEntityForm';
import { StrictLoading } from '../../../common/indicator/SmartLoading';
import { BachelorTypeEnum, IBachelor, IBachelorSpeciality, IBachelorTechnoSeries } from '@fstn/ecandidaturev2_api-interfaces';
import { BachelorDept } from './BachelorDept';

export type BachelorProps = HTMLAttributes<HTMLElement>;
const entity = 'bachelor';

function BachelorEntityForm() {
  const entityId = useScopedSelector<any[], string>('BachelorEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.id);
  const french = useScopedSelector<any[], string>('BachelorEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.french);
  const obtentionYear = useScopedSelector<any[], number>('BachelorEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.obtentionYear);
  const bachelorType = useScopedSelector<any[], string>('BachelorEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.bachelorType?.name);
  const technoSerie = useScopedSelector<any[], IBachelorTechnoSeries>('BachelorEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.technoSerie);
  const speciality1 = useScopedSelector<any[], IBachelorSpeciality>('BachelorEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.speciality1);
  const speciality2 = useScopedSelector<any[], IBachelorSpeciality>('BachelorEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.speciality2);
  const speciality3 = useScopedSelector<any[], IBachelorSpeciality>('BachelorEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.speciality3);

  const responsive = useResponsiveForm();
  const currentYear = new Date().getFullYear();
  const addDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'notationDocument',
    type: 'notationDocument',
    parentType: entity,
    parentId: entityId,
  }), [entity, entityId]);

  const availableYears = [];
  for (let i = 0; i < 15; i += 1) {
    availableYears.push({
      value: currentYear - i,
      label: `${currentYear - i - 1}-${currentYear - i}`,
    });
  }

  return (
    <StrictLoading loading={!entityId} context="BachelorForm">
      <Flex sx={{ flexWrap: 'wrap' }}>
        <Box {...responsive.w1}>
          <FormContentPanelContainer partName={entity}>
            <YesNoFormItem
              propertyName="french"
              labelCol={{ span: 16 }}
              wrapperCol={{ span: 8 }}
              yesLabel={<Locale.Button tkey="bahelor.is.french" />}
              noLabel={<Locale.Button tkey="bahelor.is.not.french" />}
            />
            {
              // On ne demande l'academie que pour les bacs "francais"
            }
            {french
              && (
                <Fragment>
                  <ColdDataAutoCompleteFormItem
                    api="academies"
                    propertyName="academy"
                  />
                  <BachelorDept propertyName='dept'/>
                </Fragment>
              )}
            <ColdDataSelectorFormItem
              api="bachelor-mentions"
              propertyName="mention"
            />
            <FixSelectFormItem
              propertyName="obtentionYear"
              options={availableYears}
            />
            {
              // On ne demande le type de bac que pour les bacs "Francais"
            }
            {french
              && (
                <ColdDataSelectorFormItem
                  api="bachelor-types"
                  propertyName="bachelorType"
                />
              )}
            {
              // Gestion des series selon le bac Techno ou Generaliste
            }
            {french && bachelorType === BachelorTypeEnum.TECHNO
              && (
                <ColdDataSelectorFormItem
                  api="bachelor-techno-series"
                  propertyName="technoSerie"
                />
              )}
            {french && bachelorType === BachelorTypeEnum.GENERALIST && obtentionYear < 2021
              && (
                <ColdDataSelectorFormItem
                  api="bachelor-general-series"
                  propertyName="generalSerie"
                />
              )}
            {
              // Pour les bacs pro et "autre", on demande "otherSpeciality"
            }
            {french && (bachelorType === BachelorTypeEnum.PRO || bachelorType === BachelorTypeEnum.OTHER)
              && (
                <InputFormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  propertyName="otherSpeciality"
                />
              )}
            {
              // Pour les bacs Generalist et techno, on demande si bac a section europeenne
            }
            {(french && (bachelorType === BachelorTypeEnum.GENERALIST || bachelorType === BachelorTypeEnum.TECHNO))
              && (
                <YesNoFormItem
                  propertyName="europeanSection"
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  yesLabel={<Locale.Button tkey="bahelor.is.european" />}
                  noLabel={<Locale.Button tkey="bahelor.is.not.european" />}
                />
              )}
            <InputNumberFormItem
              labelCol={{ span: 16 }}
              wrapperCol={{ span: 8 }}
              propertyName="genCandidateAverage"
            />
            {
              // Cas des bacs generalistes a partir de 2021 (nouveaux bacs)
              // On demande les 3 specialites (nom et moyenne)
            }
            {(french && bachelorType === BachelorTypeEnum.GENERALIST && obtentionYear >= 2021)
              && (
                <Fragment>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 2 }}>
                      <ColdDataSelectorFormItem
                        api="bachelor-specialities"
                        propertyName="speciality1"
                        dependencies={['speciality2', 'speciality3']}
                        dependenciesValues={[speciality2, speciality3]}
                        filter={(s: IBachelorSpeciality) => {
                          return s.id !== speciality2?.id && s.id !== speciality3?.id;
                        }}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <InputNumberFormItem
                        labelCol={{ span: 16 }}
                        wrapperCol={{ span: 8 }}
                        propertyName="speciality1Average"
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 2 }}>
                      <ColdDataSelectorFormItem
                        api="bachelor-specialities"
                        propertyName="speciality2"
                        dependencies={['speciality1', 'speciality3']}
                        dependenciesValues={[speciality1, speciality3]}
                        filter={(s: IBachelorSpeciality) => {
                          return s.id !== speciality1?.id && s.id !== speciality3?.id;
                        }}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <InputNumberFormItem
                        labelCol={{ span: 16 }}
                        wrapperCol={{ span: 8 }}
                        propertyName="speciality2Average"
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 2 }}>
                      <ColdDataSelectorFormItem
                        api="bachelor-specialities"
                        propertyName="speciality3"
                        dependencies={['speciality1', 'speciality2']}
                        dependenciesValues={[speciality1, speciality2]}
                        filter={(s: IBachelorSpeciality) => {
                          return s.id !== speciality1?.id && s.id !== speciality2?.id;
                        }} />
                    </div>
                    <div style={{ flex: 1 }}>
                      <InputNumberFormItem
                        labelCol={{ span: 16 }}
                        wrapperCol={{ span: 8 }}
                        propertyName="speciality3Average"
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            {
              // Cas des bacs techno a partir de 2021 (nouveaux bacs)
              // On demande les notes des 2 specialites
            }
            {(french && bachelorType === BachelorTypeEnum.TECHNO && technoSerie && obtentionYear >= 2021)
              && (
                <Fragment>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                      <InputNumberFormItem
                        labelCol={{ span: 16 }}
                        wrapperCol={{ span: 8 }}
                        propertyName="speciality1Average"
                        label={technoSerie?.speciality1Label}
                        dependencies={['technoSerie']}
                        dependenciesValues={[technoSerie]}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                      <InputNumberFormItem
                        labelCol={{ span: 16 }}
                        wrapperCol={{ span: 8 }}
                        propertyName="speciality2Average"
                        label={technoSerie?.speciality2Label}
                        dependencies={['technoSerie']}
                        dependenciesValues={[technoSerie]}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            {
              // Pour les bacs generaliste et techno à partir de 2021, on ne demande pas maths et physique
            }
            {((bachelorType !== BachelorTypeEnum.GENERALIST && bachelorType !== BachelorTypeEnum.TECHNO)
              || obtentionYear < 2021)
              && (
                <Fragment>
                  <InputNumberFormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    propertyName="mathsCandidateAverage"
                  />
                  <InputNumberFormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    propertyName="physicsCandidateAverage"
                  />
                </Fragment>
              )}
            {
              // Pour tout le monde, note de francais et anglais
            }
            <InputNumberFormItem
              labelCol={{ span: 16 }}
              wrapperCol={{ span: 8 }}
              propertyName="frenchCandidateAverage"
            />
            <InputNumberFormItem
              labelCol={{ span: 16 }}
              wrapperCol={{ span: 8 }}
              propertyName="englishCandidateAverage"
            />
            {
              // Pour les bacs generalistes et techno a partir de 2021, on demande le grand oral
            }
            {(french && (bachelorType === BachelorTypeEnum.GENERALIST || bachelorType === BachelorTypeEnum.TECHNO)
              && obtentionYear >= 2021)
              && (<InputNumberFormItem
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 8 }}
                propertyName="oralAverage"
              />
              )}
            <InputFormItem
              labelCol={{ span: 16 }}
              wrapperCol={{ span: 8 }}
              propertyName="ine"
            />
            <UploadFormItemContainer
              propertyName="notationDocument"
              accept={'image/*,.pdf'}
              factory={addDocument}
            />
          </FormContentPanelContainer>
        </Box>
      </Flex>
    </StrictLoading>
  );
}

export function BachelorForm(props: { layout?: FormLayout }) {
  /**
   * Permet de gerer le nettoyage automatique de certains attributs en fonctions des nouveaux choix effectues par l'utilisateur
   * Ex : s'il change de type de bac, on doit effacer (IHM et BDD) certains champs qui ne doivent plus être renseignes
   * @param values L'objet contenant toutes les valeurs de la form
   * @param differences L'objet contenant le champ modifie (permet de ne tenir compte que du champ modifie pour re-evaluation des nettoayges eventuels a effectuer)
   * @param setFieldsValue Fonction obligatoire qui permet d'effacer les champs dans l'IHM
   * @returns Les valeurs modifiees (a la fois celle effectue par l'utilisateur + toutes celles modifiees par la presente fonction)
   */
  const onBeforeValidate = (values: IBachelor, differences: Partial<IBachelor>, setFieldsValue) => {
    // Cas des bacs non francais, on ne conserve que mention, annee d'obtention, moy gen, math, physique, francais et anglais
    // donc vide tous les autres champs
    if (differences.french === false) {
      differences.academy = null;
      setFieldsValue({ values, academy: null });
      differences.dept = null;
      setFieldsValue({ values, dept: null });
      differences.otherSpeciality = null;
      setFieldsValue({ values, otherSpeciality: null });
      differences.speciality1Average = null;
      setFieldsValue({ values, speciality1Average: null });
      differences.speciality2Average = null;
      setFieldsValue({ values, speciality2Average: null });
      differences.speciality3Average = null;
      setFieldsValue({ values, speciality3Average: null });
      differences.oralAverage = null;
      setFieldsValue({ values, oralAverage: null });
      differences.bachelorType = null;
      setFieldsValue({ values, bachelorType: null });
      differences.technoSerie = null;
      setFieldsValue({ values, technoSerie: null });
      differences.generalSerie = null;
      setFieldsValue({ values, generalSerie: null });
      differences.speciality1 = null;
      setFieldsValue({ values, speciality1: null });
      differences.speciality2 = null;
      setFieldsValue({ values, speciality2: null });
      differences.speciality3 = null;
      setFieldsValue({ values, speciality3: null });
      differences.europeanSection = null;
      setFieldsValue({ values, europeanSection: null });
    }

    // Cas de bacs pro et autres, on ne conserve que academie, type, mention, annee d'obtention, moy gen, math, physique, autre specialité, francais et anglais
    // On vide tous les autres
    if (['Autre', 'Bac pro'].includes(differences.bachelorType?.name)) {
      differences.speciality1Average = null;
      setFieldsValue({ values, speciality1Average: null });
      differences.speciality2Average = null;
      setFieldsValue({ values, speciality2Average: null });
      differences.speciality3Average = null;
      setFieldsValue({ values, speciality3Average: null });
      differences.oralAverage = null;
      setFieldsValue({ values, oralAverage: null });
      differences.technoSerie = null;
      setFieldsValue({ values, technoSerie: null });
      differences.generalSerie = null;
      setFieldsValue({ values, generalSerie: null });
      differences.speciality1 = null;
      setFieldsValue({ values, speciality1: null });
      differences.speciality2 = null;
      setFieldsValue({ values, speciality2: null });
      differences.speciality3 = null;
      setFieldsValue({ values, speciality3: null });
      differences.europeanSection = null;
      setFieldsValue({ values, europeanSection: null });
    }

    // Cas de bacs generalistes anterieur a 2021, on n'a pas les 3 specialites et leurs 3 notes, la serie techno, autre specialite, le grand oral
    // NOTE : on doit doubler le test car 2 modifications peuvent amener a verifier la coherence (et la purge) des donnees : modif de l'annee OU modif du type de bac
    // donc on doit regarder a la fois dans differences et values car differences ne peut contenir qu'une seule donnee a la fois
    if ((differences.bachelorType?.name === 'Bac généraliste' && values.obtentionYear < 2021) ||
      ((values.bachelorType?.name === 'Bac généraliste' && differences.obtentionYear < 2021))) {
      differences.speciality1Average = null;
      setFieldsValue({ values, speciality1Average: null });
      differences.speciality2Average = null;
      setFieldsValue({ values, speciality2Average: null });
      differences.speciality3Average = null;
      setFieldsValue({ values, speciality3Average: null });
      differences.oralAverage = null;
      setFieldsValue({ values, oralAverage: null });
      differences.technoSerie = null;
      setFieldsValue({ values, technoSerie: null });
      differences.speciality1 = null;
      setFieldsValue({ values, speciality1: null });
      differences.speciality2 = null;
      setFieldsValue({ values, speciality2: null });
      differences.speciality3 = null;
      setFieldsValue({ values, speciality3: null });
      differences.otherSpeciality = null;
      setFieldsValue({ values, otherSpeciality: null });
    }

    // Cas de bacs generalistes a partir de 2021, on n'a pas maths, physique, serie generale, la serie techno, autre specialite
    // NOTE : on doit doubler le test car 2 modifications peuvent amener a verifier la coherence (et la purge) des donnees : modif de l'annee OU modif du type de bac
    // donc on doit regarder a la fois dans differences et values car differences ne peut contenir qu'une seule donnee a la fois
    if ((differences.bachelorType?.name === 'Bac généraliste' && values.obtentionYear >= 2021) ||
      ((values.bachelorType?.name === 'Bac généraliste' && differences.obtentionYear >= 2021))) {
      differences.otherSpeciality = null;
      setFieldsValue({ values, otherSpeciality: null });
      differences.technoSerie = null;
      setFieldsValue({ values, technoSerie: null });
      differences.generalSerie = null;
      setFieldsValue({ values, generalSerie: null });
      differences.mathsCandidateAverage = null;
      setFieldsValue({ values, mathsCandidateAverage: null });
      differences.physicsCandidateAverage = null;
      setFieldsValue({ values, physicsCandidateAverage: null });
    }

    // Cas de bacs techno anterieur a 2021, on n'a pas les 3 specialites et leurs 3 notes, la serie generale, autre specialite, le grand oral
    // NOTE : on doit doubler le test car 2 modifications peuvent amener a verifier la coherence (et la purge) des donnees : modif de l'annee OU modif du type de bac
    // donc on doit regarder a la fois dans differences et values car differences ne peut contenir qu'une seule donnee a la fois
    if ((differences.bachelorType?.name === 'Bac technologique' && values.obtentionYear < 2021) ||
      ((values.bachelorType?.name === 'Bac technologique' && differences.obtentionYear < 2021))) {
      differences.speciality1Average = null;
      setFieldsValue({ values, speciality1Average: null });
      differences.speciality2Average = null;
      setFieldsValue({ values, speciality2Average: null });
      differences.speciality3Average = null;
      setFieldsValue({ values, speciality3Average: null });
      differences.oralAverage = null;
      setFieldsValue({ values, oralAverage: null });
      differences.generalSerie = null;
      setFieldsValue({ values, generalSerie: null });
      differences.speciality1 = null;
      setFieldsValue({ values, speciality1: null });
      differences.speciality2 = null;
      setFieldsValue({ values, speciality2: null });
      differences.speciality3 = null;
      setFieldsValue({ values, speciality3: null });
      differences.otherSpeciality = null;
      setFieldsValue({ values, otherSpeciality: null });
    }

    // Cas de bacs techno a partir de 2021, on n'a pas maths, physique, serie generale, autre specialite, les 3 labels de specialites et la note specialite 3
    // NOTE : on doit doubler le test car 2 modifications peuvent amener a verifier la coherence (et la purge) des donnees : modif de l'annee OU modif du type de bac
    // donc on doit regarder a la fois dans differences et values car differences ne peut contenir qu'une seule donnee a la fois
    if ((differences.bachelorType?.name === 'Bac technologique' && values.obtentionYear >= 2021) ||
      ((values.bachelorType?.name === 'Bac technologique' && differences.obtentionYear >= 2021))) {
      differences.otherSpeciality = null;
      setFieldsValue({ values, otherSpeciality: null });
      differences.generalSerie = null;
      setFieldsValue({ values, generalSerie: null });
      differences.mathsCandidateAverage = null;
      setFieldsValue({ values, mathsCandidateAverage: null });
      differences.physicsCandidateAverage = null;
      setFieldsValue({ values, physicsCandidateAverage: null });
      differences.speciality1 = null;
      setFieldsValue({ values, speciality1: null });
      differences.speciality2 = null;
      setFieldsValue({ values, speciality2: null });
      differences.speciality3 = null;
      setFieldsValue({ values, speciality3: null });
      differences.speciality3Average = null;
      setFieldsValue({ values, speciality3Average: null });
    }

    return differences;
  }

  return (
    <FormStyle>
      <WithInitialLoad entity={entity}>
        <WithSwitchToReadOnlyOnDisplayForSecretary>
          <EntityFormContainer listeners={{ onBeforeValidate }} layout={props.layout}>
            <DisabledContextProviderForEntityForm>
              <BachelorEntityForm />
              <IsSecretary>
                <SecretaryReviewFormFooterContainer propertyName="secretaryReview" />
              </IsSecretary>
            </DisabledContextProviderForEntityForm>
          </EntityFormContainer>
        </WithSwitchToReadOnlyOnDisplayForSecretary>
      </WithInitialLoad>
    </FormStyle>
  );
}
