import React, {Fragment} from 'react';
import * as Locale from '../../common/locale';
import {CommentsListItemContainer} from '../user/commentsListItem/CommentsListItemContainer';

export function CandidateChat() {
    return (
      <Fragment>
        <Locale.Content tkey="candidate.chat" />
        <CommentsListItemContainer type="commentsBtwSecretaryAndCandidate" />
      </Fragment>
    );
}
