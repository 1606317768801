/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {useSafeTranslation} from '../../../../hooks/use-safe-translation';
import {AdminEditableList} from '../../common/AdminEditableList';
import {
    configsListInitialColumns,
    configsListInitialFilters,
    configsListMapToRow,
    configsListRenderRowContextMenu,
} from './UserInitialConfig';

export function UserListPage() {
    const { t } = useSafeTranslation();
    return (
      <Fragment>
        <AdminEditableList
          entity="admin/private-user"
          renderRowContextMenu={configsListRenderRowContextMenu}
          initialFilters={configsListInitialFilters}
          initialColumns={configsListInitialColumns(t)}
          gridStyle={{ height: 'calc( 100% - 250px)' }}
          mapToRow={configsListMapToRow}
        />
      </Fragment>
    );
}
