/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { JuryReviewStatusEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { CustomGridSelectEditor } from '../../../../common/customDatagrid/customGridSelectEditor';
import * as Locale from '../../../../common/locale';
import { useSafeTranslation } from '../../../../hooks/use-safe-translation';
import { ResponsiveSelect } from '../../../../common/input/ResponsiveSelect';
import { getOptions } from '../../../../common/coldData/utils';
// Pour alimentation des combos (filtres et edition) du genre
const listJuryReviewStatus = Object.values(JuryReviewStatusEnum)
  ;
/**
 * Fonction appelee pour la generation de la combo pour filtrage des genres
 * @param props
 * @returns
 */
export function JuryReviewStatusFilter(props) {
  const { t } = useSafeTranslation();
  return props.render(
    <ResponsiveSelect
      mode="multiple"
      {...props.filterEditorProps}
      data-testid={props['data-testid']}
      value={props.value}
      allowClear
      listHeight={352 // 32px par ligne, donc permet d'afficher 11 lignes sans scrollbar (11 status pour le moment)
      }
      onClear={() => {
        props.onChange({ ...props.filterValue, value: undefined });
      }}
      onChange={(e) => {
        props.onChange({ ...props.filterValue, value: e });
      }}
    >
      {getOptions(listJuryReviewStatus.map((s) => ({ label: <Locale.Label tkey={s} />, id: s })), 'candidateFilesList', props['test-id'], t)}
    </ResponsiveSelect>,
  );
}

/**
 * Display JuryReviewStatus value
 * @param value
 * @constructor
 */
export function JuryReviewStatusCellRender({ value }: any) {
  return (
    <Fragment>
      {' '}
      <Locale.Label tkey={`candidateFilesList.${value}`} />
    </Fragment>
  );
}

/**
 * Return rendor Editor
 * @param editorProps
 * @constructor
 */
export function JuryReviewStatusFilterEditor(editorProps) {
  return <CustomGridSelectEditor {...editorProps} />;
}

export const juryReviewStatusEditorProps = {
  idProperty: 'juryReviewStatus',
};

export const juryReviewStatusColumn = {
  header: <Locale.Header tkey="candidateFilesList.juryReviewStatus" />,
  name: 'juryReviewStatus',
  id: 'juryReviewStatus',
  group: 'candidateFile',
  defaultWidth: 130,
  filterEditor: JuryReviewStatusFilter,
  render: JuryReviewStatusCellRender,
  filterEditorProps: juryReviewStatusEditorProps,
};

export const juryReviewStatusFilter = {
  name: 'juryReviewStatus', operator: 'inlist', type: 'select', value: '',
};
