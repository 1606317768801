/** @jsx jsx */
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Checkbox } from 'antd';
import { jsx } from '@emotion/core';
import * as Locale from '../../../../common/locale';

export const disabledColumn = {
    header: <Locale.Header tkey="candidateFilesList.disabled" />,
    name: 'disabled',
    id: 'disabled',
    group: 'candidateFile',
    // @ts-ignore
    filterEditor: BoolFilter,
    defaultWidth: 70,
    lockable: false,
    filterable: true,
    render: ({ value, data }: any) => <Checkbox key={`disabled_cell_${data.id}`} disabled checked={value} />,
};

export const disabledFilter = {
    name: 'disabled', operator: 'eq', type: 'boolean', value: null, emptyValue: null,
};
