import * as Locale from '../../common/locale';
import React from "react";
import { Fragment } from "react";
import axios from "axios";
import { sNavigate } from "../../utils/safeNavigation";
import { ROUTES } from "../user/UserRoutesDef";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getEventServiceInstance } from '../../utils/eventService';

/**
 * Cette route est appelée directement depuis un mail de notification de chat de la part d'un candidat
 * Elle verifie si le dossier du candidat n'est pas déjà ouvert par un autre secrétaire. Si c'est le cas, affichage d'une popup 
 * le signalant et entrant dans le dossier candidat si l'utilisateur force, ou revient sur la liste des dossiers sinon
 * @param props Contient l'id du dossier candidat dans lequel on souhaire entrer
 * @returns 
 */
export function Chat(props: {candidateFileId: string}) {
    const { confirm } = Modal;
    setTimeout(async () => {
    const params = { candidateFileId: props.candidateFileId };
    try {
        await axios.put('/api/candidate-file/lock', {}, { params });
        // block it if not blocked
        localStorage.setItem('previousSecretaryUrlWithFilter', window.location.href);
        sNavigate(`${ROUTES.SECRETARY_BASE}/${props.candidateFileId}/empty`);
    } catch (e) {
        if (e.response?.status === 422) {
        confirm({
            // @ts-ignore
            okButtonProps: { 'data-testid': 'secretary-alreadyLocked-ok' },
            // @ts-ignore
            cancelButtonProps: { 'data-testid': 'secretary-alreadyLocked-cancel' },
            title: <Locale.Notification tkey="secretary.alreadyLocked" />,
            icon: <ExclamationCircleOutlined />,
            content: (
            <div>
                <Locale.Content tkey="secretary.alreadyLocked" />
            </div>
            ),
            async onOk() {
            await axios.put('/api/candidate-file/lock', { force: true }, { params });
            // block it if not blocked
            sNavigate(`${ROUTES.SECRETARY_BASE}/${props.candidateFileId}/empty`);
            },
            onCancel() {
                sNavigate(`${ROUTES.SECRETARY_BASE}`);
            }
        });
        } else {
        throw e;
        }
    }
    getEventServiceInstance().logEvent('open.secretary.candidate_file', { candidate_file: { id: props.candidateFileId } });
    }, 0);

    return (
        <Fragment></Fragment>
    )
}