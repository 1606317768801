import { RadioProps, Space } from 'antd';
import Radio from 'antd/lib/radio';
import React, { Fragment } from 'react';

import { DisabledContext } from '../../rights/DisabledContext';
import { copyEntityFormProps, EntityFormItemContainer, ItemProps } from '../entityForm/EntityFormItemContainer';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

/**
 * EntityFormItemContainer for radio form item with fixed number of options define staticaly
 * @param props vertical a true permet d'imposer un affichage des radio vertical. Sinon, ils s'affichent les uns à cote des autres en remplissant l'espace disponible
 * @constructor
 */
export function FixRadioFormItem(props: ItemProps & {vertical?: boolean} & Omit<RadioProps, 'disabled'>) {
  return (
      <EntityFormItemContainer
        {...copyEntityFormProps(props)}
      >
        <FixRadioFormItemContentContainer
          data-testid={`${props.propertyName}`?.replace(/,/g, '_')}
          options={props.options}
          vertical={props.vertical}
        />
      </EntityFormItemContainer>
        );
}

function FixRadioFormItemContentContainer(props: { value?: any, onChange?: any, options: any, vertical? : boolean }) {
    const disabled = useScopedSelector('FixRadioFormItemContentContainer', DisabledContext, (c) => c?.disabled);
    return (
      <Radio.Group
        value={props.value?.id || props.value}  // Ruse car si on passe par une LOV, parfois, c'est l'objet qui passe et il faut chercher l'id pour avoir la valeur, valeur normale sinon
        onChange={props.onChange}
        data-testid={props['data-testid']}
        className={props['data-testid']}
        disabled={disabled}
      >
        {props.vertical && (
          <Space direction="vertical">
            {props.options?.map?.(({ value, label }) => (
          <Radio
            key={value}
            data-testid={value}
            value={value}
          >
            {label}
          </Radio>
            ))}
        </Space>
        )}
        {!props.vertical && (
          <Fragment>
            {props.options?.map?.(({ value, label }) => (
              <Radio
                key={value}
                data-testid={value}
                value={value}
              >
                {label}
              </Radio>
                ))}
          </Fragment>
        )}
      </Radio.Group>
);
}
