/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {AdminEditableList} from '../../common/AdminEditableList';
import {
    specializedSecretariesListInitialColumns,
    specializedSecretariesListInitialFilters,
    specializedSecretariesListMapToRow,
    specializedSecretariesListRenderRowContextMenu,
} from './SpecializedSecretaryListInitialConfig';

export function SpecializedSecretaryListPage(props) {
  return (
    <Fragment>
      <AdminEditableList
        entity="admin/specialized-secretary"
        checkboxColumn
        renderRowContextMenu={specializedSecretariesListRenderRowContextMenu}
        initialFilters={specializedSecretariesListInitialFilters}
        initialColumns={specializedSecretariesListInitialColumns()}
        mapToRow={specializedSecretariesListMapToRow}
      />
    </Fragment>
  );
}
