import styled from '@emotion/styled';

import { SelectProps } from 'antd/lib/select';
import _, { flatMap } from 'lodash';
import React, { useContext } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';

import { ColdDataContext, ColdDataContextType } from '../../context/coldData.context';
import { ColdDataSelector } from './ColdDataSelector';
import { onOptionChange } from './utils';
import { DisabledContext } from '../rights/DisabledContext';
import { useScopedSelector } from '../../hooks/use-scoped-selector';

// @ts-ignore
const Style = styled.div``;

export interface ColdDataSelectorProps extends SelectProps<string> {
    api: string,
    params?: any,
    mapper?: ((v: any) => any),
    filter?: ((v: any) => boolean)
    listeners?: { dataLoaded?: (d: any[]) => void }
}

/**
 * Select Input for ColdData
 * @param props
 * @constructor
 */
export const ColdDataSelectorContainer = (props: SelectProps<any> & { api: string, params?: any, listeners?: any, filter?: any, mapper?: any }) => {
    const { coldDataCtx } = useContext<ColdDataContextType>(ColdDataContext);
    const { coldData } = coldDataCtx;
    const disabled = useScopedSelector('ColdDataSelectorContainer', DisabledContext, (c) => c?.disabled);

    if (!props.onChange) {
        throw Error('Missing onChange for  ColdDataSelectorContainer');
    }
    const [state, updateState] = useImmer({
        data: undefined,
        value: props.value,
    });

    // console.log('Redraw ColdDataSelectorContainer');
    useDeepCompareEffect(() => {
        if (coldData) {
            let data = coldData[props.api];
            data = _.sortBy(data, 'name');
            if (props?.filter) {
                data = data?.filter(props?.filter);
            }
            if (data && props?.mapper) {
                data = flatMap(data, props?.mapper);
            }
            if (!_.isEqual(data, state.data)) {
                if (data) {
                    props.listeners?.dataLoaded?.(data);
                }
                updateState((draft) => {
                    draft.data = data;
                });
            }
        }
    }, [coldData, props]);

    useDeepCompareEffect(() => {
        updateState((draft) => {
            draft.value = props.value;
        });
    }, [props.value, updateState]);

    const notReady = !state.data || !Array.isArray(state.data);
    return (
      <Style>
        <ColdDataSelector
          mode={props.mode}
          id={props.id}
          className={props.className}
          data-testid={props['data-testid']}
          disabled={notReady || disabled}
          value={props.value?.id}
          onChange={onOptionChange(state.data, props.onChange)}
          onOptionChange={onOptionChange}
          api={props.api}
          options={state.data}
        />
      </Style>
    );
};
