/**
 * Return validation status for form item
 * @param isTouched
 * @param getValue
 * @param validationForEntity
 * @param propertyName
 */
export const SUCCESS = 'success';
export const NOVALIDATION = 'novalidation';

export function getValidationStatus<T>(isTouched, getValue,
                                       validationForFormItem: any,
                                       validationRulesForFormItem: Partial<T>,
                                       propertyName: any): 'success' | 'error' | 'warning' | 'validating' | 'novalidation' {
    if (!validationForFormItem && !validationRulesForFormItem && isTouched?.(propertyName)) {
        return SUCCESS;
    }
    if (!validationForFormItem && getValue?.(propertyName)) {
        return SUCCESS;
    }
    if (!validationForFormItem && !getValue?.(propertyName)) {
        return NOVALIDATION;
    }
    if (validationForFormItem?.validating === true) {
        return 'validating';
    }
    if (isTouched?.(propertyName)) {
        return 'error';
    }
    if (isTouched?.(propertyName)) {
        return 'warning';
    }
    return 'warning';
}
