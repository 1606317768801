/** @jsx jsx */
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Checkbox } from 'antd';
import { jsx } from '@emotion/core';
import * as Locale from '../../../../common/locale';

export const ignoreItColumn = {
    header: <Locale.Header tkey="candidateFilesList.ignoreIt" />,
    name: 'ignoreIt',
    id: 'ignoreIt',
    group: 'candidateFile',
    // @ts-ignore
    filterEditor: BoolFilter,
    defaultWidth: 70,
    lockable: false,
    filterable: true,
    render: ({ value, data }: any) => <Checkbox key={`ignore_cell_${data.id}`} disabled checked={value} />,
};

export const ignoreItFilter = {
    name: 'ignoreIt', operator: 'eq', type: 'boolean', value: null, emptyValue: null,
};

export const ignoreItFilterType = {
    name: 'ignoreItFilterType',
        operators: [
        {
            name: 'isIgnored',
            fn: ({ value, filterValue, data }) => {
                if (filterValue === undefined || filterValue === null) {
                    return true;
                }
                const isIgnored = value === true;
                return filterValue === true
                    ? isIgnored
                    : !isIgnored;
            },
        },
    ],
};
