/** @jsx jsx */
import { FilePdfOutlined } from '@ant-design/icons';
import { IDocument } from '@fstn/ecandidaturev2_api-interfaces';
import { css, jsx } from '@emotion/core';
import { AxiosInstance } from 'axios';
import React from 'react';
import { documentDownload } from '../../utils/documentDownload';
import { ResponsiveButton } from '../input/ResponsiveButton';

export function PDFDownloader(props: {
    axios: AxiosInstance,
    token?: string,
    document: IDocument
}) {
    const style = css``;
    return (
      <div css={style}>
        <ResponsiveButton
          data-testid="downloadPDF"
          icon={<FilePdfOutlined />}
          onClick={async (e) => {
                              e.stopPropagation();
                              await documentDownload(props.axios, props.token, props.document);
                          }}
        />
      </div>
);
}
