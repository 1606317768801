import React, { useContext } from 'react';
import { UserRoleEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { UserContext } from '../../context/user.context';
import { DisabledContextProvider } from './DisabledContext';

/**
 * Disabled Context provider to use when data is always Editable for admin
 * @constructor
 */
export function DisabledContextProviderAlwaysEditableAdmin(props:{ children?:any }) {
    const { userCtx } = useContext(UserContext);
    const disabled = userCtx.user?.role?.name !== UserRoleEnum.ADMIN;
    return (

      <DisabledContextProvider value={{
            disabled,
            wrapAuthorized: false,
            context: 'DisabledContextProviderAlwaysEditableAdmin',
        }}
      >
        {props?.children}
      </DisabledContextProvider>
    );
}
