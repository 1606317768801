/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import { Input } from 'antd';

export function InputFilter(props) {
    const [value, setValue] = useState(props.filterValue);
    const filterEditorProps = props?.filterEditorProps;
    //console.log('Redraw InputFilter');
    return props.render(
      <Input
        {...filterEditorProps}
        value={value?.value}
        onChange={(e) => {
                setValue({ ...props.filterValue, value: e.target.value });
            }}
        onBlur={(e) => {
                props.onChange({ ...props.filterValue, value: e.target.value });
            }}
      />,
    );
}
