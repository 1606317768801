import React from 'react';
import {AcademicsListForm} from '../../candidateFile/academicsList/AcademicsListForm';
import {AdditionalDocumentsListForm} from '../../candidateFile/additionalDocumentsList/AdditionalDocumentsListForm';
import {ApprenticeshipCompanyForm} from '../../candidateFile/apprenticeshipCompany/ApprenticeshipCompanyForm';
import {BachelorForm} from '../../candidateFile/bachelor';
import {CompanyExperiencesListForm} from '../../candidateFile/companyExperiencesList/CompanyExperiencesListForm';
import {LanguagesListForm} from '../../candidateFile/languagesList/LanguagesListForm';
import {MotivationForm} from '../../candidateFile/motivation/MotivationForm';
import {PaymentsListForm} from '../../candidateFile/paymentsList/PaymentsListForm';
import {ProfileForm} from '../../candidateFile/profile';
import {ProgramsListForm} from '../../candidateFile/programsList/ProgramsListForm';
import {StaysAbroadListForm} from '../../candidateFile/staysAbroadList/StaysAbroadListForm';
import {SecretaryExitConfirm} from '../SecretaryExitConfirm';

/**
 * Routes starting with /home/welcome/secretary/:candidateFileId/
 * @constructor
 */
export const SecretaryChild2RoutesDef = () => ({
        '': () => (
          <ProfileForm />),
        'programs-list': () => (
          <ProgramsListForm />),
        profile: () => (
          <ProfileForm />),
        bachelor: () => (
          <BachelorForm />),
        'academics-list': () => (
          <AcademicsListForm />),
        'languages-list': () => (
          <LanguagesListForm />),
        'stays-abroad-list': () => (
          <StaysAbroadListForm />),
        'company-experiences-list': () => (
          <CompanyExperiencesListForm />),
        motivation: () => (
          <MotivationForm />),
        'apprenticeship-company': () => (
          <ApprenticeshipCompanyForm />),
        'additional-documents-list': () => (
          <AdditionalDocumentsListForm />),
        'payments-list': () => (
          <PaymentsListForm />),
        exit: () => (
          <SecretaryExitConfirm />
        ),
});
