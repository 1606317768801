/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment, useContext} from 'react';
import {Importer, ImporterField} from 'react-csv-importer-v2';

/**
 * LAURENT
 * DOCUMENTATION: npm i react-csv-importer-v2
 */
// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer-v2/dist/index.css';
import {useImmer} from 'use-immer';
import {AxiosContext, AxiosContextType} from '../../../../context/axios.context';
import {sNavigateReload} from '../../../../utils/safeNavigation';
import {ADMIN} from '../../../user/UserRoutesDef';
import {TrafficSourceListPage} from './TrafficSourceListPage';
import {ResultPanel} from '../../common/ResultPanel';
import {ButtonDeleteAll} from '../../common/ButtonDeleteAll';

export function TrafficSourceImporter() {
  const [state, updateState] = useImmer({ values: [], error: undefined as string, result: undefined as string });
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  return (
    <Fragment>
      <Importer
        delimiter=";"
        // restartable // optional, lets user choose to upload another file when import is complete
        onStart={() => {
        }}
        processChunk={async (rows) => {
          updateState((draft) => {
            draft.values = [...draft.values, ...rows];
          });
        }}
        onComplete={async () => {
          try {
            const response: any = await axios?.post('/import/traffic-source', {
              bulk: state.values,
            });
            updateState((draft) => {
              draft.result = `${response.data} sources de trafic importées`;
              draft.error = undefined;
            });
          } catch (ex) {
            updateState((draft) => {
              draft.error = ex.response.data.message;
              draft.result = undefined;
            });
          }
        }}
        onClose={async () => {
          sNavigateReload(ADMIN.TRAFFIC_SOURCE_IMPORTER);
        }}
      >
        <ImporterField name="category" label="Category" />
        <ImporterField name="id" label="Id" />
        <ImporterField name="other" label="Other" />
        <ImporterField name="ranking" label="Ranking" />
      </Importer>
      <br />
      <ButtonDeleteAll message="sources de traffic supprimées sur" url="/api/admin/traffic-source/deleteAll" updateState={updateState} />
      <ResultPanel error={state.error} result={state.result} redirect={ADMIN.TRAFFIC_SOURCE_IMPORTER} />
      <br />
      <span><h1>Liste des sources de trafic</h1></span>
      <TrafficSourceListPage />
    </Fragment>
  );
}
