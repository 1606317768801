/**
 * Take the entity value and check if user can write
 * @param userRole
 */
import { IRights } from '@fstn/ecandidaturev2_api-interfaces';

export function canWrite(rights: IRights, userRole: string): boolean {
  return (rights && !!rights?.write?.find((r) => r === userRole)) || false;
}

export function canRead(rights: IRights, userRole: string): boolean {
  return (rights && !!rights?.read?.find((r) => r === userRole)) || false;
}

export function canDelete(rights: IRights, userRole: string): boolean {
  return (rights && !!rights?.delete?.find((r) => r === userRole)) || false;
}
