import React, {Fragment} from 'react';
import {SecretaryChild1Container} from './SecretaryChild1Container';

/**
 * Routes starting with /home/welcome/secretary/
 * @constructor
 */
export const SecretaryContainerRoutesDef = () => ({
    '/secretary*': () => (
      <Fragment>
        <SecretaryChild1Container />
      </Fragment>
    ),

});
