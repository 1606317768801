/** @jsx jsx */
import {jsx} from '@emotion/core';
import moment from 'moment';
import React, {Fragment} from 'react';

import {EntityFormListContainer} from '../../../common/form/entityForm/EntityFormListContainer';
import {FormListFieldData} from '../../../common/form/type';
import {EntityFormContext} from '../../../context/entityForm.context';
import {useResponsiveForm} from '../../../hooks/use-responsive-form.hook';
import {CompanyReferentReviewForm} from './CompanyReferentReviewForm';
import {useScopedSelector} from '../../../hooks/use-scoped-selector';

export function CompanyReferentReviewsListForm() {
    const responsive = useResponsiveForm();
     const formEntityValue = useScopedSelector<any[], any>('CompanyReferentReviewsListForm', EntityFormContext, (c) => c[0]?.entityValue);

    function getReferentReviewForField(field: FormListFieldData) {
        return formEntityValue.companyReferentReviews?.[field.fieldKey];
    }

    return (
      <Fragment>
        <EntityFormListContainer
          {...responsive.colProps}
          propertyName="companyReferentReviews"
          entity="companyReferentReviews"
          sort={(f1: FormListFieldData, f2: FormListFieldData) => moment(getReferentReviewForField(f1)?.createdAt).valueOf() - moment(getReferentReviewForField(f2)?.createdAt).valueOf()}
        >
          <CompanyReferentReviewForm />
        </EntityFormListContainer>
      </Fragment>
    );
}
