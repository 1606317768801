/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
// @ts-ignore
import { usePath } from 'hookrouter';
import React, { Fragment, useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { TextDangerButton } from '../../common/button/TextDangerButton';
import * as Locale from '../../common/locale';
import { MenuAndFormContainer } from '../../common/page/Containers';
import { UserContext } from '../../context/user.context';
import { ValidationsContextProvider } from '../../context/validations.context';
import { sNavigate } from '../../utils/safeNavigation';
import { CenterAreaContainer } from '../canSeeDocuments/viewer/CenterAreaContainer';
import { FlexibleCenterRightAreaContainer } from '../canSeeDocuments/viewer/FlexibleCenterRightAreaContainer';
import { LeftAreaContainer } from '../canSeeDocuments/viewer/LeftAreaContainer';
import { RightAreaContainer } from '../canSeeDocuments/viewer/RightAreaContainer';
import { RightMenuContainer } from '../canSeeDocuments/viewer/RightMenuContainer';
import { IsCandidateFileSelected } from '../user/conditionnal/IsCandidateFileSelected';
import { UserPage } from '../user/UserPage';
import { UserPageHeader } from '../user/UserPageHeader';
import { LOGIN, ROUTES } from '../user/UserRoutesDef';
import { SpecializedSecretaryLeftMenu } from './SpecializedSecretaryLeftMenu';

import { SpecializedSecretaryReviewHeaderForm } from './SpecializedSecretaryReviewHeaderForm';
import { IsPath } from '../user/conditionnal/IsPath';
import { DocumentHeaderButton } from '../canSeeDocuments/DocumentHeaderButton';
import { TextPrimaryButton } from '../../common/button/TextPrimaryButton';

export const SecretaryAndExaminerPageStyle = styled.div` 
            display: grid;  
            height: 100%;
            grid-template-areas:  "header header header"
                                  "left content right-menu";
            grid-template-rows: 40px minmax(0, 100%) ;
            grid-template-columns: 60px minmax(0, 1fr) 50px;
            
            /**
            Motivation label issue
             */
            .ant-col.ant-col-8.ant-form-item-label{
              width: 100% !important;
              max-width: 100% !important;
            }
        `;

export function CustomPageHeader() {
  const userCtx = useContext(UserContext);

  return (
    <UserPageHeader
      title={(
        <Locale.Title tkey="specialized-secretary" />)}
      subTitle={`${userCtx.userCtx?.user?.name} ${userCtx.userCtx?.user?.program?.name} (${userCtx.userCtx?.user?.program?.optionName || ''})`}
      extra={(
        <Fragment>
          <DocumentHeaderButton />
          <IsPath different={`${ROUTES.SPECIALIZED_SECRETARY}`}>
            <TextDangerButton
              tkey="secretary.exit"
              onClick={() => sNavigate(`${ROUTES.SPECIALIZED_SECRETARY}`, true)}
            />
          </IsPath>
          <IsPath equals={`${ROUTES.SPECIALIZED_SECRETARY}`}>
            <TextPrimaryButton
              tkey="statistics"
              onClick={() => sNavigate(`${ROUTES.SPECIALIZED_SECRETARY}/statistics`, true)}
            />
            <TextDangerButton
              tkey="logout"
              onClick={() => sNavigate(`${LOGIN.LOGOUT}`)}
            />
          </IsPath>
        </Fragment>
      )}
    >
      <SpecializedSecretaryReviewHeaderForm />
    </UserPageHeader>
  );
}

export function SpecializedSecretaryPage(props: { children: any }) {
  const path = usePath();
  const [, updateState] = useImmer({ loaded: false, routes: undefined as any, path: '' });
  useEffect(() => {
    updateState((draft) => {
      draft.path = path;
    });
  }, [updateState, path]);

  return (
    <UserPage>
      <SecretaryAndExaminerPageStyle>
        <ValidationsContextProvider
          entity="specialized-secretary-review"
          steps={[]}
        >
          <CustomPageHeader />
          <MenuAndFormContainer>
            <IsCandidateFileSelected>
              <LeftAreaContainer>
                <SpecializedSecretaryLeftMenu />
              </LeftAreaContainer>
            </IsCandidateFileSelected>
            <FlexibleCenterRightAreaContainer>
              <CenterAreaContainer>
                {props?.children}
              </CenterAreaContainer>
              <RightAreaContainer />
            </FlexibleCenterRightAreaContainer>
            <RightMenuContainer />
          </MenuAndFormContainer>
        </ValidationsContextProvider>
      </SecretaryAndExaminerPageStyle>
    </UserPage>
  );
}

export function FullScreenSpecializedSecretaryPage(props: { children: any }) {
  return (
    <UserPage>
      <CustomPageHeader />
      <div css={css`height:calc( 100% - 100px)`}>
        {props?.children}
      </div>
    </UserPage>
  );
}
