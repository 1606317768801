import { UserRoleEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { ColProps } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import { useContext } from 'react';
import { UserContext } from '../context/user.context';

export type ResponsiveCols = { colProps: { wrapperCol: ColProps; labelCol: ColProps }; layout: FormLayout; 'w1/5': { p: number; width: number[] }; 'w1/3': { p: number; width: number[] }; 'w2/3': { p: number; width: number[] }; w1: { p: number; width: number[] }; 'w1/2': { p: number; width: number[] } };

export function useResponsiveForm(props?: any): ResponsiveCols {
    const userContext = useContext(UserContext);

    if (userContext.userCtx.user?.role?.name === UserRoleEnum.SECRETARY
        || userContext.userCtx.user?.role?.name === UserRoleEnum.SPECIALIZED_SECRETARY
        || userContext.userCtx.user?.role?.name === UserRoleEnum.EXAMINER) {
        return {
            colProps: {
                wrapperCol: props?.wrapperCol || { span: 24 },
                labelCol: props?.labelCol || { span: 24 },
            },
            w1: {
                p: 1,
                width: [1],
            },
            'w1/3': {
                p: 1,
                width: [1],
            },
            'w2/3': {
                p: 1,
                width: [1],
            },
            'w1/2': {
                p: 1,
                width: [1],
            },
            'w1/5': {
                p: 1,
                width: [1],
            },
            layout: props?.layout || 'vertical',
        };
    }
    return {
        colProps: {
            wrapperCol: props?.wrapperCol || { span: 16 },
            labelCol: props?.labelCol || { span: 8 },
        },
        w1: {
            p: 1,
            width: [1],
        },
        'w1/3': {
            p: 1,
            width: [1, 1, 1, 1 / 3],
        },
        'w2/3': {
            p: 1,
            width: [1, 1, 1, 2 / 3],
        },
        'w1/2': {
            p: 1,
            width: [1, 1, 1, 1 / 2],
        },
        'w1/5': {
            p: 1,
            width: [1, 1, 1, 1 / 5],
        },
        layout: props?.layout || 'horizontal',
    };
}
