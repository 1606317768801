/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/core';
import {IComments} from '@fstn/ecandidaturev2_api-interfaces';
import {Fragment} from 'react';
import {CommentsListComposerContainer} from './CommentsListComposerContainer';

export function CommentsListItem(props: { type: 'commentsBtwSecretaryAndCandidate', onChange: Function, value: IComments[], className?: string }) {
    return (
      <Fragment>
        <CommentsListComposerContainer
          className={props.className}
          value={props.value}
          onChange={props.onChange}
          type={props.type}
        />
      </Fragment>
    );
}
