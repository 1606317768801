/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { Fragment, useContext } from 'react';
import { CandidateFileStatusEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { Tag } from 'antd';
import { CustomGridSelectEditor } from '../../../../common/customDatagrid/customGridSelectEditor';
import * as Locale from '../../../../common/locale';
import { useSafeTranslation } from '../../../../hooks/use-safe-translation';
import { ResponsiveSelect } from '../../../../common/input/ResponsiveSelect';
import { getOptions } from '../../../../common/coldData/utils';
import { UserContext } from '../../../../context/user.context';
// Pour alimentation des combos (filtres et edition) du genre
const listCandidateFileStatus = [
    CandidateFileStatusEnum.NEW,
    CandidateFileStatusEnum.FORCED,
    CandidateFileStatusEnum.SUBMITTED,
    CandidateFileStatusEnum.IN_PROGRESS_SECRETARY,
    CandidateFileStatusEnum.PRE_VALIDATED,
    CandidateFileStatusEnum.WAITING_CANDIDATE,
    CandidateFileStatusEnum.VALID_WITH_ERRORS,
    CandidateFileStatusEnum.VALID];

/**
 * Fonction appelee pour la generation de la combo pour filtrage
 * @param props
 * @returns
 */
export function CandidateFileStatusFilter(props) {
    const { t } = useSafeTranslation();

    const { isSpecializedSecretary } = useContext(UserContext);
    return props.render(
        <ResponsiveSelect
            {...props.filterEditorProps}
            data-testid={props['data-testid']}
            value={props.filterValue.value}
            allowClear
            mode="multiple"
            tagRender={(p) => <Tag color={statusToColor(p.value)}><Locale.Label tkey={`candidateFilesList.${p.value}`} /></Tag>}
            render
            onClear={() => {
                props.onChange({ ...props.filterValue, value: undefined });
            }}
            onChange={(e) => {
                props.onChange({ ...props.filterValue, value: e });
            }}
        >
            {getOptions(listCandidateFileStatus
                .filter((s) => (isSpecializedSecretary() ? [CandidateFileStatusEnum.VALID_WITH_ERRORS, CandidateFileStatusEnum.VALID].includes(s) : true))
                .map((s) => ({ label: s, id: s })), 'candidateFilesList', props['test-id'], t)}

        </ResponsiveSelect>,
    );
}

/**
 * Display CandidateFileStatus value
 * @param value
 * @constructor
 */
export function CandidateFileStatusCellRender({ value }: any) {
    return (
        <Fragment>
            <Tag
                color={statusToColor(value)}
                css={css`
margin-right: 0;
`}
            >
                <Locale.Label tkey={`candidateFilesList.${value}`} />
            </Tag>
        </Fragment>
    );
}

/**
 * Return rendor Editor
 * @param editorProps
 * @constructor
 */
export function CandidateFileStatusFilterEditor(editorProps) {
    return <CustomGridSelectEditor {...editorProps} />;
}

export const candidateFileStatusFilterEditorProps = {};

export const candidateFileStatusColumn = {
    header: <Locale.Header tkey="candidateFilesList.candidateFileStatus" />,
    name: 'candidateFileStatus',
    id: 'candidateFileStatus',
    group: 'candidateFile',
    className: 'candidateFileStatus',
    'test-id': 'candidateFileStatus',
    defaultWidth: 155,
    filterEditor: CandidateFileStatusFilter,
    render: CandidateFileStatusCellRender,
    filterEditorProps: candidateFileStatusFilterEditorProps,

};

export const candidateFileStatusFilter = {
    name: 'candidateFileStatus', operator: 'notinlist', type: 'select', value: 'NEW',
};

function statusToColor(status) {
    switch (status) {
        case CandidateFileStatusEnum.NEW: {
            return '#787878';
        }
        case CandidateFileStatusEnum.FORCED: {
            return '#00B8DE';
        }
        case CandidateFileStatusEnum.SUBMITTED: {
            return '#00B8DE';
        }
        case CandidateFileStatusEnum.IN_PROGRESS_SECRETARY: {
            return '#fb5db7';
        }
        // TODO : definir la couleur
        case CandidateFileStatusEnum.PRE_VALIDATED: {
            return '#FD9B2F';
        }
        case CandidateFileStatusEnum.WAITING_CANDIDATE: {
            return '#B358F9';
        }
        case CandidateFileStatusEnum.VALID_WITH_ERRORS: {
            return '#239466';
        }
        case CandidateFileStatusEnum.VALID: {
            return '#2DBE84';
        }
    }
    return '#ccc';
}
