import { RightsUtils } from '@fstn/ecandidaturev2_api-interfaces';
import _, { omit } from 'lodash';
import React, { useContext } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import { UserContext } from '../../context/user.context';
import { InlineLoading, StrictLoading } from '../indicator/SmartLoading';

/**
 * WithSwitchToReadOnlyOnDisplayForSecretary: switch children to readonly when load
 * when secretary load a page, the form must switch to readonly when come back to the page
 * @param props
 * @constructor
 */
export function WithSwitchToReadOnlyOnDisplayForSecretary<T>(props: { entity?: string, initialValues?: T, children: any }) {
    // console.log('Redraw WithSwitchToReadOnlyOnDisplayForSecretary');
    const { userCtx, canSeeValidation } = useContext(UserContext);
    const [state, updateState] = useImmer({
        initialValues: props.initialValues as any,
        switchedToReadModeExecuted: false,
        children: <InlineLoading loading context="WithSwitchToReadOnlyOnDisplayForSecretary" />,
    });

    /**
     * Turn entity into readOnly
     * @param data
     */
    async function switchBackToReadMode(data) {
        const roleName = userCtx?.user?.role?.name;
        const { rights } = data;
        const isEditable = RightsUtils.isEditable(rights, roleName);

        // Go to read only on every load except for secretary review fields
        if (isEditable
            && !localStorage.getItem('keepEdit')
            && props.entity !== 'missing-fields-list'
            && props.entity !== 'secretary-review'
            && props.entity !== 'specialized-secretary-review') {
            const changedValues = { ...data, rights: RightsUtils.toNotEdit(rights, roleName) };
            if (!_.isEqual(changedValues, state.initialValues)) {
                updateState((draft) => {
                    draft.initialValues = changedValues;
                });
            }
        }
    }

    /**
     * switchBackToReadMode on data load.
     * It means when we are loading the data
     */

    const loaded = !!state.initialValues;
    useDeepCompareEffect(() => {
        if (loaded /* && !state.switchedToReadModeExecuted */) {
            if (canSeeValidation()) {
                switchBackToReadMode(state.initialValues).then(() => {
                });
            }
            updateState((draft) => {
                draft.switchedToReadModeExecuted = true;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateState, loaded, state.initialValues]);

    useDeepCompareEffect(() => {
        if (!_.isEqual(props.initialValues, state.initialValues)) {
            updateState((draft) => {
                draft.initialValues = props.initialValues;
            });
        }
    }, [props.initialValues]);

    useDeepCompareEffect(() => {
        if (state.switchedToReadModeExecuted) {
            let childrenAsArray;
            if (Array.isArray(props.children)) {
                childrenAsArray = props.children;
            } else {
                childrenAsArray = [props.children];
            }
            // rights are ok here
            const children = React.Children.map(childrenAsArray,
                (c) => {
                    const extra = { initialValues: state.initialValues };
                    // on edit do not redraw this part
                    return React.cloneElement(c, { ...c.props, ...omit(props, 'children'), ...extra });
                });
            updateState((draft) => {
                draft.children = children;
            });
        }
    }, [state.initialValues, state.switchedToReadModeExecuted]);

    return <StrictLoading loading={!state.switchedToReadModeExecuted} context="WithSwitchToReadOnlyOnDisplayForSecretary">{state.children}</StrictLoading>;
}
