/** @jsx jsx */
import { InfoCircleFilled, ReadOutlined } from '@ant-design/icons/lib';
import {
  DocumentTypeEnum, IDocument, IProgram, IProgramLine, IRights,
} from '@fstn/ecandidaturev2_api-interfaces';
import { css, jsx } from '@emotion/core';
import { Card, Cascader } from 'antd';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Fragment, useContext, useEffect } from 'react';
import { useDeepCompareCallback } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import UUID from 'uuid-js';

import { TextNeutralButton } from '../../../common/button/TextNeutralButton';
import { TextWarningButton } from '../../../common/button/TextWarningButton';
import { UploadFormItemContainer } from '../../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import { ResponsiveButton } from '../../../common/input/ResponsiveButton';
import * as Locale from '../../../common/locale';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { EntityFormContext } from '../../../context/entityForm.context';
import { EntityFormListContext } from '../../../context/entityFormList.context';
import { UserContext, UserContextType } from '../../../context/user.context';
import { IsCandidate } from '../../user/conditionnal/IsCandidate';
import { getDisabledForWriteAllContexts } from '../../../utils/formDisableStateManager';
import { reverseToId } from './utils';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { IsDesktop } from '../../user/conditionnal/IsDesktop';
import { InputTextAreaFormItem } from '../../../common/form/inputFormItems/InputTextAreaFormItem';

let options;
let programs;

export function ProgramSelector(props: { onChange?: (p: IProgram) => void, value?: IProgram }) {
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  const userContext = useContext<UserContextType>(UserContext);
  const formContextEntity = useScopedSelector<any[], string>('DisabledContextType', EntityFormContext, (c) => c[0]?.disabled);
  const formContextDisabled = useScopedSelector<any[], boolean>('DisabledContextType', EntityFormContext, (c) => c[0]?.disabled);
  const formContextRights = useScopedSelector<any[], IRights>('DisabledContextType', EntityFormContext, (c) => c[0]?.rights);
  const formListRights = useScopedSelector<any[], IRights>('FormListItemContainer', EntityFormListContext, (c) => c[0]?.rights);
  const formListEntity = useScopedSelector<any[], string>('IProgramsList', EntityFormListContext, (c) => c[0]?.entity);

  const [state, updateState] = useImmer({ options: undefined, cascaderValue: undefined });

  function filter(inputValue, path) {
    return path.some(
      (option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    );
  }

  function fillCascader() {
    const currentProgram: IProgram = props?.value;
    const cascaderValue: IProgram = getProgramById(currentProgram?.id);
    if (cascaderValue) {
      updateState((draft: any) => {
        draft.cascaderValue = ['theme', cascaderValue.theme, cascaderValue.id];
      });
    }
  }

  if (!state.cascaderValue) {
    fillCascader();
  }
  useEffect(() => {
    fillCascader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.value)]);

  useEffect(() => {
    (async () => {
      if (!options) {
        const res = await axios.get('/public/programs/select-options');
        options = res.data?.[0]?.data;
        programs = reverseToId(options);
      }
      updateState((draft: any) => {
        draft.options = options;
      });
    })();
  }, [axios, updateState]);

  function getProgramById(id): IProgram {
    return programs?.find((p: IProgram) => p.id === id);
  }

  const disabled = formContextDisabled
    || getDisabledForWriteAllContexts(userContext, formContextEntity, formContextRights, formListEntity, formListRights);
  return (
    <Fragment>
      <Cascader
        style={{ width: '400px' }}
        options={state.options}
        changeOnSelect
        value={state.cascaderValue}
        defaultValue={state.cascaderValue}
        /* ok */ disabled={disabled}
        onChange={(...value: any) => {
          if (props?.onChange && value?.[0]?.[2]) {
            const newProgram = getProgramById(value?.[0]?.[2]);
            props?.onChange?.(newProgram);
          }
        }}
        placeholder={Locale.PlaceHolder({ tkey: 'programForm' })}
        showSearch={{ filter }}
      />
      <ResponsiveButton icon={<InfoCircleFilled />}>
        En savoir plus
      </ResponsiveButton>
      {props?.value?.displayName}
      <img src={props?.value?.logoUrl} alt="" />
      <img src={props?.value?.logo2Url} alt="" />
    </Fragment>
  );
}

export function ProgramForm(props: any) {
  const formListEntity = useScopedSelector<any[], string>('IProgramLine', EntityFormListContext, (c) => c[0]?.entity);
  const field = useScopedSelector<any[], any>('IProgramLine', EntityFormListContext, (c) => c[0].field);
  const formListEntityValue = useScopedSelector<any[], IProgramLine>('IProgramLine', EntityFormListContext, (c) => c[0]?.entityValue);
  const formListEntityId = useScopedSelector<any[], string>('LanguageForm', EntityFormListContext, (c) => c[0]?.entityValue?.id);

  const addMotivationLetter = useDeepCompareCallback((): IDocument => ({
    id: UUID.create(1).toString(),
    name: `${formListEntityValue?.program.name}_${formListEntityValue?.program.optionName}`,
    type: DocumentTypeEnum.MOTIVATION_LETTER_DOCUMENT,
    parentType: formListEntity,
    parentId: formListEntityId,
  }), [formListEntity, formListEntityId]);

  if (!formListEntityValue?.program) {
    return <Fragment />;
  }
  return (
    <Fragment>
      <Card data-testid="programViewer" title={`${formListEntityValue?.program?.name} ${formListEntityValue?.program?.optionName || ''} - ${formListEntityValue?.program?.school}`}>
        <div css={css`display:flex; justify-content:space-between; flex-wrap: nowrap; width: 100%`}>
          <IsCandidate>
            <IsDesktop>
              <div css={css`display:flex; gap:1em;flex-wrap: wrap`}>
                <img alt="logo" src={formListEntityValue?.program?.logoUrl} css={css`height: 100px;`} />
              </div>
            </IsDesktop>
          </IsCandidate>
          <IsCandidate>
            <div css={css`text-align:center`}>
              <p>{formListEntityValue?.program?.school}</p>
              <p>{formListEntityValue?.program?.campus}</p>
              <p>{formListEntityValue?.program?.capacity}</p>
              <p>
                <Locale.Label tkey="programsList.requiredLevel" />
                :
                {formListEntityValue?.program?.requiredLevel}
              </p>
              <p>{formListEntityValue?.program?.status}</p>
              <br />
              <IsCandidate>
                <div css={css`display:flex; gap: 1em; flex-wrap: wrap; margin-bottom: 1em`}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href={formListEntityValue?.program?.website} target="_blank" rel="noopener noreferrer">
                    <TextNeutralButton mode="ghost" tkey="program.website.open" />
                  </a>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href={formListEntityValue?.program?.noticeUrl} target="_blank" rel="noopener noreferrer">
                    <TextWarningButton icon={<ReadOutlined />} tkey="program.notice.open" />
                  </a>
                </div>
              </IsCandidate>
            </div>
          </IsCandidate>
          <IsCandidate>
            <IsDesktop>
              {formListEntityValue?.program?.logo2Url && (
                <img
                  alt="logo"
                  src={formListEntityValue?.program?.logo2Url}
                  css={css`height: 100px;`}
                />
              )}
            </IsDesktop>
          </IsCandidate>
        </div>

        {
          // Seules certaines formations ont une question spécifique donc on ne l'affiche que si elle existe
        }
        {formListEntityValue?.program?.specificQuestion && (
          <InputTextAreaFormItem
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 8 }}
            label={formListEntityValue?.program?.specificQuestion}
            required={true}
            propertyName={['programLines', field.name, 'answerSpecificQuestion']}
          />
        )}

        <UploadFormItemContainer
          propertyName={['programLines', field.name, 'motivationLetter']}
          accept={'image/*,.pdf'}
          factory={addMotivationLetter}
        />

      </Card>
    </Fragment>
  );
}
