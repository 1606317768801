/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { Fragment, useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Logo } from '../../assets/Logo';
import { useIsMounted } from '../../hooks/use-is-mounted';
import * as Locale from '../locale';

function LoadingContent({ tip, subTip, context }:{ tip?:any, subTip?:any, context?:string }) {
    const [visible, setVisible] = useState(false);
    const isMounted = useIsMounted();

    useEffect(() => {
        setTimeout(() => {
            if (isMounted.current) {
                setVisible(true);
            }
        }, 5000);
        // eslint-disable-next-line
    }, []);

    return (
      <div
        css={css`position: fixed;z-index:99;top:0;left:0;width:100vw;height:100vh; background-color:white;display: flex;flex-direction:column;gap:1em;font-size:20px;align-items: center;margin:auto;justify-content:center`}
        className={context}
        data-testid="loading"
      >
        <Logo size={64} />
        <div css={css`align-items:center`}>
          <div>
            {tip || <Locale.Label tkey="loading" />}
            {' '}
          </div>
        </div>
        {visible && <div css={css`color:#aaa;font-size:10px`}>{subTip || '( Around 60sec )'}</div>}
        <br />
      </div>
);
}

/**
 * Loader that hide children if loading after a moment
 * @param loading
 * @param children
 * @param tip
 * @param subTip
 * @constructor
 */
export function SmartLoading({
 loading, children, tip, subTip, context,
}: { loading: boolean, children: any, tip?: any, subTip?:any, context?: string }) {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setVisible(true);
            }, 500);
        }
    }, [loading]);

    if (!loading) {
        return children;
    } if (visible) {
        return (
          <LoadingContent context={context} tip={tip} subTip={subTip} />
        );
    }
        return children;
}

/**
 * Loader that hide children if loading
 * @param loading
 * @param children
 * @param tip
 * @param subTip
 * @constructor
 */
export function StrictLoading({
 loading, children, tip, subTip, context,
}: { loading: boolean, children: any, tip?: any, subTip?:any, context?:string }) {
    const isMounted = useIsMounted();
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                if (isMounted.current) {
                    setVisible(true);
                }
            }, 500);
        }
    }, [loading, isMounted]);

    if (!loading) {
        return <Fragment>{children}</Fragment>;
    } if (visible) {
        return (
          <LoadingContent context={context} tip={tip} subTip={subTip} />
        );
    }
        return <Fragment />;
}

/**
 * InlineLoading
 * @param context
 * @constructor
 */
export function InlineLoading({ loading, context, children }:{ loading: boolean, context:string, children?:any }) {
    if (!loading) {
        return children || <Fragment />;
    }
    return <div className={context} css={css`width:100%; display: flex; align-items: center; justify-content: center`}><LoadingOutlined /></div>;
}
