/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PDFViewer from 'pdf-viewer-reactjs';
import React, { useMemo, useState } from 'react';
import { useEntity } from '../../hooks/use-entity';
import 'material-design-icons/iconfont/material-icons.css';

const style = css`flex: 1;
  max-width: 100%;
  height: 100%;

  .container.text-center {
    max-width: 100%;
  }

  .is-size-3.flex-column.is-marginless {
    position: absolute;
    font-size: 1em !important;
  }

  .column {
    display: flex !important;
    flex-wrap: nowrap;
  }

  .box {
    padding-bottom: 0.1em !important;
    padding-top: 0.1em !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
`;

//const fakePDF = 'https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf';

export function MemoizedPDFViewer(props: {
  url: string,
  documentId: string,
  documentName: string,
  rotationAngle: number,
  canvasCss?: any,
  scale?: any,
  hideNavbar?: any,
  hideZoom?: any,
  showThumbnail?: any,
  hideRotation?: any
}) {
  const {
    postEntity,
  } = useEntity();
  const [url, setUrl] = useState(props.url);

  return useMemo(() => (
    <div css={style}>
      <PDFViewer
        navbarOnTop
        canvasCss={props.canvasCss}
        scale={props.scale}
        hideNavbar={props.hideNavbar}
        hideZoom={props.hideZoom}
        showThumbnail={props.showThumbnail}
        rotationAngle={props.rotationAngle}
        onZoom={async (_zoom) => {
          // fake refresh because there is a bug on zoom and rotaiton with a blank page.
          setUrl(`${props.url}&randomToRefreshItWhenZoom=${Math.random()}`);
        }}
        onRotation={async (_rotation) => {
          // fake refresh because there is a bug on zoom and rotaiton with a blank page.
          setUrl(`${props.url}&randomToRefreshItWhenZoom=${Math.random()}`);
          await postEntity('document/rotate', { id: props.documentId }, {
            id: props.documentId,
            rotationAngle: _rotation,
          } as any);
        }}
        hideRotation={props.hideRotation}
        document={{
          url,
        }}
      />
    </div>
  ), [postEntity, props.canvasCss, props.documentId, props.hideNavbar, props.hideRotation, props.hideZoom, props.rotationAngle, props.scale, props.showThumbnail, url, props.url]);
  // loop add
}
