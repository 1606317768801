import styled from '@emotion/styled';
import {
  Document, Image, Page, PDFViewer, StyleSheet, View, Text
} from '@react-pdf/renderer';
import React, { Fragment, useContext } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';
import { PDFCard } from '../../../common/pdf/components/PDFCard';
import { PDFH1 } from '../../../common/pdf/components/PDFH1';
import { PDFSpacer } from '../../../common/pdf/components/PDFSpacer';
import { PDFText } from '../../../common/pdf/components/PDFText';
import { AxiosContextProvider } from '../../../context/axios.context';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { AcademicsListFormPDF } from './AcademicsListFormPDF';
import { ApprenticeshipCompanyFormPDF } from './ApprenticeshipCompanyFormPDF';
import { BachelorFormPDF } from './BachelorFormPDF';
import { CompanyExperiencesListFormPDF } from './CompanyExperiencesListFormPDF';
import { CandidateFileForExport } from './export.type';
import { LanguagesListFormPDF } from './LanguagesListFormPDF';
import { MotivationFormPDF } from './MotivationFormPDF';
import { ProfileFormPDF } from './ProfileFormPDF';
import { StaysAbroadListFormPDF } from './StaysAbroadListFormPDF';
import { useConfig } from '../../../hooks/use-config.hook';
import { UserContext, UserContextType } from '../../../context/user.context';
import { OtherInformationPDF } from './OtherInformationPDF';
import { ProgramsListPDF } from './ProgramPDF';

const Style = styled.div`
    height: 100%;
    width: 100%;

    iframe {
      height: 100%;
      width: 100%;
    }
`;

// @ts-ignore
const s = StyleSheet.create({
  feed: {
    textAlign: 'center',
    fontSize: '10',
    marginBottom: '4'
  },
  page: {
    padding: 30,
    fontSize: 14,
    color: '#555',
  },
  text: {
    fontSize: 9,
    color: '#555',
  },
  container: {
    color: '#555',
    alignItems: 'center',
    fontSize: 12,
    flexDirection: 'row',
    // @ts-ignore
    '@media max-width: 400': {
      flexDirection: 'column',
    },
  },
  logo: {
    marginRight: '10px',
    width: '100px',
    minHeight: '50px',
  },
  top_right: {
    width: '340px',
  },
  avatar: {
    marginLeft: '10px',
    display: 'flex',
    width: '72px',
    minHeight: '100px',
    backgroundColor: 'whitesmoke',
  },
  image: {},
  section: {}
});

function PDFDocument(props: {
  candidateFiles: CandidateFileForExport[],
  startSchool?: Date,
  isCandidate?: boolean
}) {
  //console.log("Le monstre", props.candidateFiles[0])
  return (
    <PDFViewer>
      <Document>
        <AxiosContextProvider>
          {props.candidateFiles.map((candidateFile: CandidateFileForExport) => (
            <Fragment key={candidateFile.Profile.email}>
              <Page size="A4" style={s.page}>
                <Text style={s.feed} render={({ pageNumber }) => (
                  `${candidateFile.Profile.firstName} ${candidateFile.Profile.lastName} - ID-${candidateFile.CandidateFile.shortId} - Page ${pageNumber}`
                )} fixed />
                <View style={s.section}>
                  <PDFCard
                    bordered
                    titleBgColor="darkgrey"
                    title={(
                      <View style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <View>
                          <PDFText color="white">
                            Dossier de candidature - Session {new Date().getFullYear()}
                          </PDFText>
                        </View>
                        <View>
                          <PDFText color="white">
                            {` ${candidateFile.Profile.firstName || ''} ${candidateFile.Profile.lastName || ''} - ${candidateFile.Profile.email || ''}`}
                          </PDFText>
                        </View>
                      </View>
                    )}
                  >
                    <View style={s.container}>
                      <View style={s.logo}>
                        <Image
                          src="/logoIMT.png"
                          style={s.image}
                        />
                      </View>
                      <View style={s.top_right}>
                        <View>
                          {/* HEADER  CONTENT */}
                          <PDFH1>
                            Formation(s)
                            souhaitée(s)
                          </PDFH1>
                          <View>
                            {candidateFile.CandidateFile?.juryReviews && Object.keys(candidateFile.CandidateFile?.juryReviews)?.map((programDisplayName) => (
                              <PDFText
                                style={s.text}
                                key={programDisplayName}
                              >
                                {`${programDisplayName}`}
                              </PDFText>
                            ))}
                          </View>
                          {/* END HEADER CONTENT */}
                        </View>
                      </View>
                      <View style={s.avatar}>
                        <Image
                          src={candidateFile.avatarAsBase64}
                          style={s.image}
                        />
                      </View>
                    </View>
                    <ProgramsListPDF programsList={candidateFile.ProgramsList} />
                  </PDFCard>
                  <PDFSpacer />
                  <ProfileFormPDF profile={candidateFile.Profile} startSchool={props.startSchool} />
                  <PDFSpacer />
                  <BachelorFormPDF bachelor={candidateFile.Bachelor} />
                  <PDFSpacer />
                  <AcademicsListFormPDF academicsList={candidateFile.AcademicsList} isCandidate={props.isCandidate} />
                  <PDFSpacer />
                </View>
              </Page>
              <Page size="A4" style={s.page}>
                <Text style={s.feed} render={({ pageNumber }) => (
                  `${candidateFile.Profile.firstName} ${candidateFile.Profile.lastName} - ID-${candidateFile.CandidateFile.shortId} - Page ${pageNumber}`
                )} fixed />
                <View style={s.section}>
                  {props.isCandidate || (
                    <Fragment>
                      <OtherInformationPDF otherInformation={candidateFile} />
                      <PDFSpacer />
                    </Fragment>)}
                  <LanguagesListFormPDF languagesList={candidateFile.LanguagesList} />
                  <PDFSpacer />
                  <StaysAbroadListFormPDF staysAbroadList={candidateFile.StaysAbroadList} />
                  <PDFSpacer />
                  <CompanyExperiencesListFormPDF
                    companyExperiencesList={candidateFile.CompanyExperiencesList}
                  />
                  <PDFSpacer />

                </View>
              </Page>
              <Page size="A4" style={s.page} wrap>
                <Text style={s.feed} render={({ pageNumber }) => (
                  `${candidateFile.Profile.firstName} ${candidateFile.Profile.lastName} - ID-${candidateFile.CandidateFile.shortId} - Page ${pageNumber}`
                )} fixed />
                <Fragment>
                  <View style={s.section}>
                    <MotivationFormPDF motivation={candidateFile.Motivation} />
                    <PDFSpacer />
                    <ApprenticeshipCompanyFormPDF
                      apprenticeshipCompany={candidateFile.ApprenticeshipCompany}
                    />
                  </View>
                </Fragment>
              </Page>
            </Fragment>
          ))}
        </AxiosContextProvider>
      </Document>
    </PDFViewer>
  );
}

//
export function CandidateFilePDF(candidateFile: {
  candidateFiles: CandidateFileForExport[]
}) {
  const { t } = useSafeTranslation();
  const [{ date }] = useConfig();
  const userCtx = useContext<UserContextType>(UserContext);
  // eslint-disable-next-line
  const isCandidate = userCtx.isCandidate();

  return useDeepCompareMemo(() => (
    <Fragment>
      {' '}
      <Style>
        <PDFDocument candidateFiles={candidateFile.candidateFiles} startSchool={date('START_SCHOOL')} isCandidate={isCandidate} />
      </Style>
    </Fragment>
  ), [candidateFile.candidateFiles, s.page, s.section, t]);
}
