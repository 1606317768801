import { BachelorTypeEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { View } from '@react-pdf/renderer';
import React, { Fragment } from 'react';
import { PDFBold } from '../../../common/pdf/components/PDFBold';
import { Container, PDFCard } from '../../../common/pdf/components/PDFCard';
import { PDFH1 } from '../../../common/pdf/components/PDFH1';
import { PDFSpacer } from '../../../common/pdf/components/PDFSpacer';
import { PDFText } from '../../../common/pdf/components/PDFText';
import { PDFTextGutter } from '../../../common/pdf/components/PDFTextGutter';
import { Bachelor } from './export.type';

/**
 * Cette fonction récupère la série correspondant au type de bac (generaliste / techno)
 * Cas particulier : pour les bac pro ou autre, la série est remplacée par la "spécialité pro"
 * @param bachelor L'objet contenant toutes les informations du bac
 */
function getSerie(bachelor: Bachelor): string {
  if (bachelor.bachelorType?.name === BachelorTypeEnum.GENERALIST || bachelor.bachelorType?.name === BachelorTypeEnum.TECHNO) {
    return bachelor.serie;
  }
  if (bachelor.bachelorType?.name === BachelorTypeEnum.PRO || bachelor.bachelorType?.name === BachelorTypeEnum.OTHER) {
    return bachelor.otherSpeciality;
  }
  return "";
}

/**
 * Permet de savoir si on est sur un nouveau bac generaliste (c-a-d à partir de 2021)
 * @param bachelor L'objet contenant toutes les informations du bac
 * @returns 
 */
function isNewGeneralistBachelor(bachelor: Bachelor): boolean {
  return bachelor.bachelorType?.name === BachelorTypeEnum.GENERALIST && bachelor.obtentionYear >= 2021;
}

/**
 * Permet de savoir si on est sur un nouveau bac technologique (c-a-d à partir de 2021)
 * @param bachelor L'objet contenant toutes les informations du bac
 * @returns 
 */
function isNewTechnoBachelor(bachelor: Bachelor): boolean {
  return bachelor.bachelorType?.name === BachelorTypeEnum.TECHNO && bachelor.obtentionYear >= 2021;
}

export function BachelorFormPDF({ bachelor }: { bachelor: Bachelor }) {
  if (!bachelor) {
    return <PDFH1>Baccalauréat</PDFH1>;
  }
  return (
    <Container>

      <PDFH1>Baccalauréat</PDFH1>
      <PDFCard
        bordered
        title={(
          <PDFTextGutter>
            <View style={{ width: '15%' }}>
              <PDFText color="white">Année</PDFText>
            </View>
            <View style={{ width: '15%' }}>
              <PDFText color="white">Pays</PDFText>
            </View>
            <View style={{ width: '20%' }}>
              <PDFText color="white">Type</PDFText>
            </View>
            <View style={{ width: '50%' }}>
              <PDFText color="white">Série ou Spécialité pro</PDFText>
            </View>
          </PDFTextGutter>
        )}
      >
        <PDFTextGutter>
          <View style={{ width: '15%' }}>
            <PDFText>{bachelor.obtentionYear}</PDFText>
          </View>
          <View style={{ width: '15%' }}>
            {/* eslint-disable-next-line no-nested-ternary */}
            <PDFText>{bachelor.french === true ? 'Bac français' : (bachelor.french === false ? 'Bac étranger' : '')}</PDFText>
          </View>
          <View style={{ width: '20%' }}>
            <PDFText>{bachelor.bachelorType?.name}</PDFText>
          </View>
          <View style={{ width: '50%' }}>
            <PDFText>{getSerie(bachelor)}</PDFText>
          </View>
        </PDFTextGutter>
        <PDFSpacer />
        <Container bordered>
          <PDFTextGutter>
            <View style={{ width: '17%', textAlign: "left", backgroundColor: "lightgrey", padding: "5px" }}>
              <PDFText>Moyenne générale</PDFText>
            </View>
            <View style={{ width: '8%', textAlign: "left", paddingLeft: "3px" }}>
              <PDFText>{bachelor.genCandidateAverage?.toString()}</PDFText>
            </View>
            <View style={{ width: '15%', textAlign: "left", backgroundColor: "lightgrey", padding: "5px" }}>
              <PDFText>Mention</PDFText>
            </View>
            <View style={{ width: '59%', textAlign: "left", padding: "5px" }}>
              <PDFText>{bachelor.mention?.name}</PDFText>
            </View>
          </PDFTextGutter>
        </Container>

        <PDFTextGutter>
          <View style={{ width: '17%', textAlign: "left", paddingLeft: "5px" }}>
            <PDFBold>Français :</PDFBold>
          </View>
          <View style={{ width: '8%', textAlign: "left", paddingLeft: "5px" }}>
            <PDFText>{bachelor.frenchCandidateAverage?.toString()}</PDFText>
          </View>
          <View style={{ width: '15%', textAlign: "left", paddingLeft: "5px" }}>
            {!isNewGeneralistBachelor(bachelor) && !isNewTechnoBachelor(bachelor) && (
              <PDFBold>Maths :</PDFBold>
            )}
            {isNewTechnoBachelor(bachelor) && (
              <PDFBold>Spécialité 1 :</PDFBold>
            )}
          </View>
          {!isNewGeneralistBachelor(bachelor) && !isNewTechnoBachelor(bachelor) && (
            <View style={{ width: '59%', textAlign: "left", paddingLeft: "5px" }}>
              <PDFText>{bachelor.mathsCandidateAverage?.toString()}</PDFText>
            </View>
          )}
          {// Le bloc ci-dessous est necessaire, sinon, l'affichage de la note se decale a droite car la somme des %age ne fait plus 100%
          }
          {isNewGeneralistBachelor(bachelor) && (
            <View style={{ width: '59%', textAlign: "left", paddingLeft: "5px" }}>
            </View>
          )}
          {isNewTechnoBachelor(bachelor) && (
            <Fragment>
              <View style={{ width: '8%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFText>{bachelor.speciality1Average?.toString()}</PDFText>
              </View>
              <View style={{ width: '50%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFText>{bachelor.speciality1}</PDFText>
              </View>
            </Fragment>
          )}
        </PDFTextGutter>
        <PDFTextGutter>
          <View style={{ width: '17%', textAlign: "left", paddingLeft: "5px" }}>
            <PDFBold>Anglais :</PDFBold>
          </View>
          <View style={{ width: '8%', textAlign: "left", paddingLeft: "5px" }}>
            <PDFText>{bachelor.englishCandidateAverage?.toString()}</PDFText>
          </View>
          <View style={{ width: '15%', textAlign: "left", paddingLeft: "5px" }}>
            {!isNewGeneralistBachelor(bachelor) && !isNewTechnoBachelor(bachelor) && (
              <PDFBold>Physique :</PDFBold>
            )}
            {isNewTechnoBachelor(bachelor) && (
              <PDFBold>Spécialité 2 :</PDFBold>
            )}
          </View>
          {!isNewGeneralistBachelor(bachelor) && !isNewTechnoBachelor(bachelor) && (
            <View style={{ width: '59%', textAlign: "left", paddingLeft: "5px" }}>
              <PDFText>{bachelor.physicsCandidateAverage?.toString()}</PDFText>
            </View>
          )}
          {// Le bloc ci-dessous est necessaire, sinon, l'affichage de la note se decale a droite car la somme des %age ne fait plus 100%
          }
          {isNewGeneralistBachelor(bachelor) && (
            <View style={{ width: '59%', textAlign: "left", paddingLeft: "5px" }}>
            </View>
          )}
          {isNewTechnoBachelor(bachelor) && (
            <Fragment>
              <View style={{ width: '8%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFText>{bachelor.speciality2Average?.toString()}</PDFText>
              </View>
              <View style={{ width: '50%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFText>{bachelor.speciality2}</PDFText>
              </View>
            </Fragment>
          )}
        </PDFTextGutter>

        {isNewTechnoBachelor(bachelor) && (
          <PDFTextGutter>
            <View style={{ width: '17%', textAlign: "left", paddingLeft: "5px" }}>
              <PDFBold>Grand oral :</PDFBold>
            </View>
            <View style={{ width: '8%', textAlign: "left", paddingLeft: "5px" }}>
              <PDFText>{bachelor.oralAverage?.toString()}</PDFText>
            </View>
            <View style={{ width: '74%', textAlign: "left", paddingLeft: "5px" }}>
            </View>
          </PDFTextGutter>
        )}

        {isNewGeneralistBachelor(bachelor) && (
          <Fragment>
            <PDFTextGutter>
              <View style={{ width: '17%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFBold>Grand oral :</PDFBold>
              </View>
              <View style={{ width: '8%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFText>{bachelor.oralAverage?.toString()}</PDFText>
              </View>
              <View style={{ width: '74%', textAlign: "left", paddingLeft: "5px" }}>
              </View>
            </PDFTextGutter>
            <PDFTextGutter>
              <View style={{ width: '17%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFBold>Spécialité 1 :</PDFBold>
              </View>
              <View style={{ width: '8%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFText>{bachelor.speciality1Average?.toString()}</PDFText>
              </View>
              <View style={{ width: '74%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFText>{bachelor.speciality1}</PDFText>
              </View>
            </PDFTextGutter>
            <PDFTextGutter>
              <View style={{ width: '17%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFBold>Spécialité 2 :</PDFBold>
              </View>
              <View style={{ width: '8%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFText>{bachelor.speciality2Average?.toString()}</PDFText>
              </View>
              <View style={{ width: '74%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFText>{bachelor.speciality2}</PDFText>
              </View>
            </PDFTextGutter>
            <PDFTextGutter>
              <View style={{ width: '17%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFBold>Spécialité 3 :</PDFBold>
              </View>
              <View style={{ width: '8%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFText>{bachelor.speciality3Average?.toString()}</PDFText>
              </View>
              <View style={{ width: '74%', textAlign: "left", paddingLeft: "5px" }}>
                <PDFText>{bachelor.speciality3?.name}</PDFText>
              </View>
            </PDFTextGutter>
          </Fragment>

        )}
      </PDFCard>

    </Container>
  );
}
