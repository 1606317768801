/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Statistic } from 'antd';

const Style = css`

  .ant-statistic-title,.ant-statistic-content-value {
    overflow: hidden;
    color:white;
  }
  
  .ant-statistic{
    background:#457992;
    padding: 10px@;
    width: 120px;
    .ant-statistic-content-value{
      font-size: 20px;
    }
    .translation{
      font-size: 12px;
    }
  }
`;

export function CandidateFilesListHeaderStatistic(props) {
    return (
      <div css={Style}>
        <Statistic {...props} />
      </div>
    );
}
