/**
 * Ces fonctions permettent de gerer l'affichage des questions / reponses des questions specifiques aux formation
 */
import { IProgramLine } from '@fstn/ecandidaturev2_api-interfaces';
import { View } from '@react-pdf/renderer';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { PDFSpacer } from '../../../common/pdf/components/PDFSpacer';
import { PDFText } from '../../../common/pdf/components/PDFText';
import { PDFTextGutter } from '../../../common/pdf/components/PDFTextGutter';
import { ProgramsList } from './export.type';

/**
 * Cette fonction permet de tester si parmi toutes les formations selectionnees par le candidat, il y en a au moins une qui possède une question specifique
 * Auquel cas on affiche le bloc avec la (les) question(s) spécifique
 * @param props : - programs Contient la liste des programmes selectionnes par le candidat
 *                - chlidren contient le bloc à afficher si une question est trouvée
 * @returns 
 */
function HasAtLeastOneSpecificQuestion(props: { programs: IProgramLine[], children?: any }) {
  let findOneQuestion = false;
  _.forEach(props.programs, (elem: IProgramLine) => {
    if (elem.program?.specificQuestion?.length > 0) findOneQuestion = true;
  })
  if (findOneQuestion) {
    return (
      <Fragment>
        {props.children}
      </Fragment>
    );
  }
  return <Fragment />;
}

/**
 * Cette fonction gère les 2 cas suivants :
 * - examinateur et secrétaire formation : recupère et affiche (si présente) la question/réponse liée à la formation rattachée à l'utilisateur
 * - secrétaire dossier et candidat : récupère et affiche toutes les question/réponse liées à toutes les formations sélectionnées par la candidat
 * @param props programsList contient toutes les informations sur la liste des formations selectionnées par le candidats
 * @returns 
 */
export function ProgramsListPDF(props: { programsList: ProgramsList, debug?: boolean }) {
  // Gestion du cas SF et Examiner (donc au maximum la question/réponse liée à l'utilisateur connecté)
  let firstElem = true;
  if (props.programsList) {
    return (
      <Fragment>
        {
          props.programsList?.specificQuestion !== '' && props.programsList.specificQuestion && (
            <Fragment>
              <PDFSpacer />
              <PDFTextGutter>
                <View >
                  <PDFText>{props.programsList.specificQuestion} :</PDFText>
                </View>
              </PDFTextGutter>
              <PDFTextGutter>
                <View >
                  <PDFText>{props.programsList.answerSpecificQuestion}</PDFText>
                </View>
              </PDFTextGutter>
            </Fragment>
          )
        }
        {
          // Gestion du cas SD et Candidate (récupération de toutes les questions/reponses de toutes les formations selectionnées par le candidat)
        }
        <HasAtLeastOneSpecificQuestion
          programs={props.programsList.programLines}
          children={
            <Fragment>
              <PDFSpacer />
              <Fragment>
                {
                  Object.values(props.programsList.programLines)?.map((programLine: IProgramLine) => (
                    <Fragment>
                      {!firstElem && (<PDFSpacer />)
                      }
                      {firstElem = false}
                      {programLine.program?.specificQuestion && (
                        <Fragment>
                          <PDFTextGutter>
                            <View >
                              <PDFText>{programLine.program.specificQuestion} :</PDFText>
                            </View>
                          </PDFTextGutter>
                          <PDFTextGutter>
                            <View >
                              <PDFText>{programLine.answerSpecificQuestion}</PDFText>
                            </View>
                          </PDFTextGutter>
                        </Fragment>
                      )}
                    </Fragment>
                  ))}
              </Fragment>
            </Fragment>
          }
        />
      </Fragment>
    )
  }
}