import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Menu from '@inovua/reactdatagrid-community/packages/Menu';
import { Modal, notification, Statistic } from 'antd';

// @ts-ignore
import React, { useContext } from 'react';
import { useImmer } from 'use-immer';
import { AxiosContext, AxiosContextType } from '../../../../context/axios.context';
import { sNavigateReload } from '../../../../utils/safeNavigation';
import { ADMIN } from '../../../user/UserRoutesDef';

const { confirm } = Modal;

/**
 * Composant de gestion du menu contextuel dans la grid
 */
export function ProgramListContextMenu({
    menuProps, details, selectedPrograms, gridRef,
}: any) {
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const [state] = useImmer({ loading: false });

    const items = [];
    /**
     * Fonction appelée quand on demande la suppression des elements selectionnes
     * Affiche le nombre d'elements supprimes en message d'info puis reirige sur la page pour raffraichir la liste
     */
    const onDeleteSelection = async () => {
        if (selectedPrograms.length > 0) {
            const listIds = selectedPrograms.map((cf) => cf.id);
            try {
                const response: any = await axios.post('admin/program/deleteSelection', listIds);
                notification.info({
                    message: `${response.data.deleted} éléments supprimés sur ${response.data.total} sélectionnées !`,
                    duration: 0,
                });
                const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
                await sleep(2000);
                sNavigateReload(ADMIN.PROGRAM_IMPORTER);
            } catch (e) {
                notification.error({
                    message: 'Erreur de suppression',
                    description: e.message,
                    duration: 0,
                });
            }
        }
    };

    /* On ajoute ici dans le menu contextuel le menu permettant d'effacer la selection */
    if (selectedPrograms.length > 0) {
        items.push({
            label: 'Supprimer...',
            icon: <DeleteOutlined />,
            disabled: state.loading,
            onClick: (value) => {
                confirm({
                    // @ts-ignore
                    okButtonProps: { 'data-testid': 'jury-publish-results-ok' },
                    // @ts-ignore
                    cancelButtonProps: { 'data-testid': 'jury-publish-results-cancel' },
                    title: 'Suppression de la sélection',
                    icon: <ExclamationCircleOutlined />,
                    content: (
                      <div>
                        <Statistic
                          title="Nombre d'éléments selectionnés"
                          value={selectedPrograms.length}
                        />
                        Voulez-vous vraiment supprimer ces éléments ?
                      </div>
                    ),
                    onOk: () => onDeleteSelection(), // Appel de la suppression des elements selectionnes
                });
            },
        });
    }

    return (
      <Menu
        {...menuProps}
        /* ok */disabled={state.loading}
        items={items}
      />
    );
}
