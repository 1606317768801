/** @jsx jsx */
import {jsx} from '@emotion/core';
import {IRights, RightsUtils} from '@fstn/ecandidaturev2_api-interfaces';
import {Fragment, useContext} from 'react';
import {UserContext} from '../../../context/user.context';
import {useRights} from '../../../hooks/use-rights.hook';
import {useScopedSelector} from '../../../hooks/use-scoped-selector';
import {RightsContext, RightsContextType} from '../../../context/rights.context';

export function CanWrite(props: { children?: any, entityName: string, else?: any }) {
    const { userCtx } = useContext(UserContext);
    const { allEntitiesAreWrittable } = useRights();
    const rights = useScopedSelector<RightsContextType, IRights>('LeftMenuItem', RightsContext, (c) => c.rightsCtxState.rights);

    // find  a way to manage the submit state here

    const roleName = userCtx.user?.role?.name;
    const entityRights = rights?.[props.entityName];
    const canWrite = entityRights ? RightsUtils.roleCanWrite(entityRights, roleName) : allEntitiesAreWrittable(rights, roleName);
    return (
      <Fragment>
        {canWrite && props.children}
        {!canWrite && props.else}
      </Fragment>
    );
}
