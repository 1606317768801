import React from 'react';
import { FullScreenReferentPage } from '../ReferentPage';
import { DefaultPage } from './DefaultPage';
import { WithInitialLoad } from '../../../common/withExtraParameters/WithInitialLoad';
import { CandidateFileContextProvider } from '../../canSeeCandidateFile/CandidateFileContext';
import { ReferentReviewPage } from '../ReferentReviewPage';
/**
 * Routes starting with /home/welcome/referent/
 * @constructor
 */
export function ReferentChild1RoutesDef(): any {
  return {
    '/:candidateFileId/:academicReferentReviewId/accept-review': ({ candidateFileId, academicReferentReviewId }: any) => (
      <WithInitialLoad
        entity="candidate-file"
        entityId={candidateFileId}
        propsName="selectedCandidateFile"
      >
        <CandidateFileContextProvider>
          <ReferentReviewPage academicReferentReviewId={academicReferentReviewId} />
        </CandidateFileContextProvider>
      </WithInitialLoad>
    ),
    '/*': () => (
      <FullScreenReferentPage>
        <DefaultPage />
      </FullScreenReferentPage>
    ),
  };
}
