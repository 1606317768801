/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {usePath} from 'hookrouter';

/**
 * Conditionnal tests on current path
 * @param props
 * @constructor
 */
export function IsPath(props: { children: any, elseChildren?: any, equals?:string, different?: string, startsWith?: string, notStartsWith?: string }) {
    const path = usePath();
    if (props.equals) {
        return (
          <Fragment>
            {path === `${props.equals}` && props.children}
            {path !== `${props.equals}` && props.elseChildren}
          </Fragment>
        );
    } if (props.different) {
        return (
          <Fragment>
            {path !== `${props.different}` && props.children}
            {path === `${props.different}` && props.elseChildren}
          </Fragment>
        );
    } if (props.startsWith) {
        return (
          <Fragment>
            {path.startsWith(`${props.startsWith}`) && props.children}
            {!path.startsWith(`${props.startsWith}`) && props.elseChildren}
          </Fragment>
        );
    } if (props.notStartsWith) {
        return (
          <Fragment>
            {!path.startsWith(`${props.notStartsWith}`) && props.children}
            {path.startsWith(`${props.notStartsWith}`) && props.elseChildren}
          </Fragment>
        );
    }
        throw new Error('Missing operator');
}
