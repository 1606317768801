import {Card, Divider} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, {ChangeEventHandler, Fragment} from 'react';
import {TextOkButton} from '../../../common/button/TextOkButton';
import {ButtonsFormItemLayout} from '../../../common/form/buttonsFormItem/ButtonsFormItemLayout';

export function CommentsListComposerInput(props: {
    size?: 'large',
    onInternalChange: ChangeEventHandler<any>,
    onSubmit: Function,
    updateState: Function,
    value: string,
    type: 'commentsBtwSecretaryAndCandidate',
    submitting: boolean
}) {
    return (
      <Fragment>
        <Card
          style={{ width: '100%' }}
        >
          <TextArea
            rows={props.size === 'large' ? 10 : 4}
            style={{ width: '100%' }}
            onChange={props.onInternalChange}
            value={props.value}
          />
          <Divider />
          <ButtonsFormItemLayout>
            <TextOkButton
              disabled={!props.value}
              htmlType="submit"
              loading={props.submitting}
              onClick={() => {
                            localStorage.removeItem(props.type);
                            props.onSubmit(props.value);
                            props.updateState((draft) => {
                                draft.value = '';
                            });
                        }}
              tkey={`${props.type}.comments-list`}
            />
          </ButtonsFormItemLayout>
        </Card>
      </Fragment>
    );
}
