/** @jsx jsx */
import { FileImageOutlined } from '@ant-design/icons';
import { css, jsx } from '@emotion/core';
import React from 'react';
import Viewer from 'react-viewer';
import { useImmer } from 'use-immer';
import { ResponsiveButton } from '../input/ResponsiveButton';

/**
 * ImageViewerPop: uses to display image document on upload document
 * @param props
 * @constructor
 */
export function ImageViewerPop(props: {
    url: any;
}) {
    const [state, updateState] = useImmer({ visible: false });

    const style = css`
.react-viewer-inline{
min-height: 100px;
}
`;
    return (
      <div
        css={style}
        onClick={(e) => {
        e.stopPropagation();
    }}
      >
        <ResponsiveButton
          data-testid="openImageViewer"
          icon={<FileImageOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            updateState((draft) => {
                draft.visible = true;
            });
        }}
        />
        <Viewer
            // @ts-ignore
          visible={state.visible}
          onClose={() => updateState((draft) => {
                draft.visible = false;
            })}
          onMaskClick={() => updateState((draft) => {
                draft.visible = false;
            })}
          images={[{ src: props.url, alt: '' }]}
        />
      </div>
);
}
