import { omit } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { useImmer } from 'use-immer';

export function Debounced(props: any) {
    const [state, updateState] = useImmer({ value: props.value });

    // console.log('Redraw Debounced', props);

    useEffect(() => {
            updateState((draft) => {
                draft.value = props.value;
            });
    }, [props.value, updateState]);

    function onChange(e) {
        const value = e?.target?.value !== undefined ? e?.target?.value : e;
        updateState((draft) => {
            draft.value = value;
        });
       // debouncedOnChange(value);
    }

    function onBlur(e) {
        props.onChange(state.value);
    }

    let childrenAsArray;
    if (Array.isArray(props.children)) {
        childrenAsArray = props.children;
    } else {
        childrenAsArray = [props.children];
    }
    const children = React.Children.map(childrenAsArray,
        (c) => React.cloneElement(c, {
        ...c.props,
        ...omit(props, 'children', 'onChange'),
        onChange,
        onBlur,
        value: state.value,
    }));
return (
  <Fragment>
    {children}
  </Fragment>
);
}
