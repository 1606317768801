import styled from '@emotion/styled';
import React from 'react';
import { useSafeTranslation } from '../../hooks/use-safe-translation';
import { ResponsiveAutoComplete } from '../input/ResponsiveAutoComplete';
import { getOptions } from './utils';

// @ts-ignore
const Style = styled.div`
max-width: 100%;
`;

/**
 * Select Input for Autocomplete ColdData
 * @param props
 * @constructor
 */
export function ColdDataAutoComplete(props:{
    id: string;
    api:string,
    className: string;
    disabled: boolean;
    allowClear?: boolean
    onChange: any,
    onOptionChange:(value: any, option: any) => void;
    value:any,
    options:any
}) {
    const { t } = useSafeTranslation();
    return (
      <Style>
        <ResponsiveAutoComplete
          id={props.id}
          className={props.className}
          data-testid={props['data-testid']}
          disabled={props.disabled}
          allowClear={props.allowClear}
          value={props.value?.id}
          onChange={props.onChange}
          onClear={() => props.onOptionChange(null, { value: null })}
          filterOption={(inputValue, option) => option.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        >
          {getOptions(props.options, props.api, props['test-id'], t)}
        </ResponsiveAutoComplete>
      </Style>
    );
}
