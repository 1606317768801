/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { DocumentTypeEnum, IDocument } from '@fstn/ecandidaturev2_api-interfaces';
import Card from 'antd/lib/card';
import React from 'react';
import { Box, Flex } from 'rebass';
import { useDeepCompareCallback } from 'use-deep-compare';
import UUID from 'uuid-js';
import { UploadFormItemContainer } from '../../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import { EntityFormListContext } from '../../../context/entityFormList.context';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { StrictLoading } from '../../../common/indicator/SmartLoading';

// noinspection JSUnusedLocalSymbols
export function CompanyReferentReviewForm(props: {}) {
  const formListEntity = useScopedSelector<any[], string>('CompanyReferentReviewForm', EntityFormListContext, (c) => c[0]?.entity);
  const field = useScopedSelector<any[], any>('CompanyReferentReviewForm', EntityFormListContext, (c) => c[0].field);
  const formListEntityId = useScopedSelector<any[], string>('CompanyReferentReviewForm', EntityFormListContext, (c) => c[0]?.entityValue?.id);
  const responsive = useResponsiveForm();

  const addReviewDocument = useDeepCompareCallback((): IDocument => ({
    id: UUID.create(1).toString(),
    name: `${DocumentTypeEnum.COMPANY_REVIEW_DOCUMENT}`,
    type: DocumentTypeEnum.COMPANY_REVIEW_DOCUMENT,
    parentType: formListEntity,
    parentId: formListEntityId,
  }), [formListEntity, formListEntityId]);

  // noinspection RequiredAttributes
  return (
    <StrictLoading loading={!formListEntityId} context="CompanyReferentReviewForm">
      <Card
        key={`${field.name}field`}
        css={css`width: 100%`}
      >
        <Flex name="companyReferentReviews" flexWrap="wrap">
          <Box {...responsive.w1}>
            <UploadFormItemContainer
              propertyName={['companyReferentReviews', field.name, 'reviewDocument']}
              accept={'image/*,.pdf'}
              factory={addReviewDocument}
            />
          </Box>
        </Flex>

      </Card>
    </StrictLoading>
  );
}
