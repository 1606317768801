import styled from '@react-pdf/styled-components';
import React, { ReactNode } from 'react';

const Container = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    padding: 2px;
`;

export function PDFCardGutter(props: { children: ReactNode, debug?: boolean }) {
    return (
      <Container debug={props.debug}>
        {props.children}
      </Container>
    );
}
