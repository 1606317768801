import { IDocument } from '@fstn/ecandidaturev2_api-interfaces';
import { FormItemProps } from 'antd/lib/form';
import React from 'react';

import { copyEntityFormProps, EntityFormItemContainer, ItemProps } from '../../entityForm/EntityFormItemContainer';
import { UploadFormItemUploadContainer } from './UploadFormItemUploadContainer';

export interface ApiItemProps extends Partial<FormItemProps> {
    api: string,
    propertyName: string | any[]
}
/**
 * Upload component
 * factory function should be memo to prevent rerender
 * @param props
 * @constructor
 */
export function UploadFormItemContainer(props: {
    accept: string,
    propertyName: string | any[],
    factory: () => IDocument | any,
    /* Use to call commentsController for referent */
    onAfterChange?: any,
    onBeforeUpload?: (imageContext: { width: number, height: number }) => Promise<boolean>
} & ItemProps) {
    const propertyName = props?.propertyName;
    const { factory } = props;
    // console.log('Redraw UploadFormItemContainer', props);
    const singlePropertyName = Array.isArray(propertyName) ? propertyName[propertyName.length - 1] : propertyName;

    return (
      <EntityFormItemContainer
        data-testid={`${props.propertyName}`?.replace(/,/g, '_')}
        {...copyEntityFormProps(props)}
      >
        <UploadFormItemUploadContainer
          propertyName={singlePropertyName}
          accept={props.accept}
          onBeforeUpload={props.onBeforeUpload}
          onAfterChange={props.onAfterChange}
          factory={factory}
        />
      </EntityFormItemContainer>
);
}
