// @ts-ignore
import { usePath } from 'hookrouter';
import React from 'react';
import { CandidateFilesList } from '../canSeeCandidateFile/candidateFilesList/CandidateFilesList';
import { CandidateFilesListModalContainer } from '../canSeeCandidateFile/candidateFilesList/CandidateFilesListModalContainer';
import {
  CandidateFilesListInitialColumns,
  CandidateFilesListInitialFilters,
  renderRowContextMenu,
} from './CandidateFilesListInitialConfig';
import { ProgramManagerPageHeader } from './ProgramManagerPage';

export function DefaultPage() {
  const path = usePath();
  const visible = path === '/home/welcome' || path === '/home/welcome/program-manager';
  return (
    <CandidateFilesListModalContainer title={<ProgramManagerPageHeader />} visible={visible}>
      <CandidateFilesList
        initialColumns={CandidateFilesListInitialColumns()}
        renderRowContextMenu={renderRowContextMenu}
        gridStyle={{ height: 'calc( 100% - 100px)' }}
        initialFilters={CandidateFilesListInitialFilters}
        visible={visible}
      />
    </CandidateFilesListModalContainer>
  );
}
