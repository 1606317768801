/** @jsx jsx */
import { jsx } from '@emotion/core';
import { IComments } from '@fstn/ecandidaturev2_api-interfaces';
import { Comment, List } from 'antd';
import React from 'react';
import { formatComments } from './utils';

export const CommentsList = (props: { comments: IComments[], className?: string }) => (
  <div className={props.className}>
    <List
      className="comments-list"
      rowKey="pureDatetime"
      dataSource={formatComments(props.comments)}
      header={`${props.comments.length} ${props.comments.length > 1 ? 'replies' : 'reply'}`}
      itemLayout="horizontal"
      renderItem={(iProps: any) => {
        const dateComment: Date = new Date(iProps.pureDatetime);
        return (
          <Comment
            key={iProps.pureDatetime}
            content={iProps.content}
            author={iProps.author}
            datetime={<span>{dateComment.toLocaleString()}</span>}
          />
        )
      }}
    />
  </div>
);
