/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {RightsContext, RightsContextType} from '../../../context/rights.context';
import {useScopedSelector} from '../../../hooks/use-scoped-selector';

export function AreRightsLoading(props: { children?: any, else?: any }) {
    const loading = useScopedSelector<RightsContextType, boolean>('AreRightsLoading', RightsContext, (c) => c.rightsCtxState.loading);

    return (
      <Fragment>
        {loading && props.children}
        {!loading && props.else}
      </Fragment>
    );
}
