/** @jsx jsx */
import { ICandidateFile } from '@fstn/ecandidaturev2_api-interfaces';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React, { Fragment, useContext } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import { AxiosContext, AxiosContextType } from '../../context/axios.context';
import { UserContext, UserContextType } from '../../context/user.context';
import { CandidateFileReviewWithPDFAndMessageContainer } from '../candidateFile/CandidateFileReviewWithPDFAndMessageContainer';
import { SmartLoading } from '../../common/indicator/SmartLoading';
import { IsDesktop } from '../user/conditionnal/IsDesktop';
import { IsMobile } from '../user/conditionnal/IsMobile';
import * as Locale from '../../common/locale';

const Style = styled.div`
height: 100%;
`;

export function SubmitCandidateFileForm() {
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const { userCtx } = useContext<UserContextType>(UserContext);
    const [state, updateSate] = useImmer({ visible: false, checked: false, candidateFile: undefined as ICandidateFile });

    async function load() {
        const res = await axios.get(`/candidate-file/?s={"id":"${userCtx?.user?.candidateFile?.id}"}`);
        updateSate((draft) => {
            draft.candidateFile = res.data[0];
        });
    }

    useDeepCompareEffect(() => {
        if (!state.candidateFile) {
            load().then();
        }
    }, [state.candidateFile]);

    return (
      <Fragment>
        <IsDesktop>
          <SmartLoading loading={!userCtx?.user?.candidateFile || !state.candidateFile} context="SubmitCandidateFileForm">
            <Style>
              {process.env.NODE_ENV !== 'test' && <CandidateFileReviewWithPDFAndMessageContainer />}
            </Style>
          </SmartLoading>
        </IsDesktop>
        <IsMobile>
          <Locale.Help tkey="Pdf.notAvailableOnMobile" />
        </IsMobile>
      </Fragment>
    );
}
