/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import PageHeader from 'antd/lib/page-header';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useContext } from 'react';
import { TextDangerButton } from '../../common/button/TextDangerButton';
import * as Locale from '../../common/locale';
import { MenuAndFormContainer } from '../../common/page/Containers';
import { UserContext } from '../../context/user.context';
import { sNavigate } from '../../utils/safeNavigation';
import { CenterAreaContainer } from '../canSeeDocuments/viewer/CenterAreaContainer';
import { UserPage } from '../user/UserPage';
import { LOGIN, ROUTES } from '../user/UserRoutesDef';
import { IsPath } from '../user/conditionnal/IsPath';
import { TextPrimaryButton } from '../../common/button/TextPrimaryButton';

const HeaderStyle = styled.div`
grid-area: header;
`;

function CustomPageHeader() {
  const userCtx = useContext(UserContext);

  return (
    <HeaderStyle>
      <PageHeader
        title={<Locale.Title tkey="jury" />}
        className="site-page-header"
        subTitle={`${userCtx.userCtx.user.name} ${userCtx.userCtx?.user?.program?.name} (${userCtx.userCtx?.user?.program?.optionName || ''})`}
        extra={(
          <Fragment>
            <IsPath different={ROUTES.JURY_BASE}>
              <TextDangerButton
                tkey="secretary.exit"
                onClick={() => sNavigate(`${ROUTES.JURY_BASE}`)}
              />
            </IsPath>
            <IsPath equals={`${ROUTES.JURY_BASE}`}>
              <TextPrimaryButton
                tkey="importStatus"
                onClick={() => sNavigate(`${ROUTES.JURY_BASE}/import`, true)}
              />
              <TextDangerButton
                tkey="logout"
                onClick={() => sNavigate(`${LOGIN.LOGOUT}`)}
              />
            </IsPath>
          </Fragment>
        )}
      />
    </HeaderStyle>
  );
}

export function JuryPage(props: { children: any }) {
  const JuryPageStyle = styled.div`
            height: calc( 100% );
            display: grid;
            grid-template-areas:  "header"
                                  "content";
            grid-template-rows: 72px minmax(0, 100%);

`;

  return (
    <UserPage>
      <JuryPageStyle>
        <CustomPageHeader />
        <MenuAndFormContainer>
          <CenterAreaContainer>
            {props?.children}
          </CenterAreaContainer>
        </MenuAndFormContainer>
      </JuryPageStyle>
    </UserPage>
  );
}
