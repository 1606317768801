/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { DocumentUtils } from '@fstn/ecandidaturev2_api-interfaces';
import Avatar from 'antd/lib/avatar/avatar';
import { useContext } from 'react';
import * as Locale from '../../common/locale';

import { CandidateFileContext, CandidateFileContextProviderType } from './CandidateFileContext';

export function CandidateCard() {
  const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
  const profile = candidateFileContext?.selectedCandidateFile?.profile;
  return (

    <article
      id="candidate-card"
      className="message  is-small"
      css={css`
   padding: 0.5em;
    display: flex;
    justify-content: center;
    gap: 1em;
    align-items: center;
    flex-wrap: wrap;
    .message-body{
        padding-left: 0em;
        padding-right: 0em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border: none;
    }
`}
    >
      {!!profile?.avatarDocument && (
        <Avatar
          src={DocumentUtils.getPreviewLink(profile?.avatarDocument)}
        />
      )}
      <div className="message-body">
        <div>{profile?.firstName}</div>
        <div>{profile?.lastName}</div>
        <div css={css`color: #00B8DE`}><Locale.Label tkey="idCandidateHeader" /> : {candidateFileContext?.selectedCandidateFile?.shortId}</div>
      </div>
    </article>
  );
}
