import { DatePickerProps } from 'antd/lib/date-picker';
import React from 'react';
import { ResponsiveDatePicker } from '../../input/ResponsiveDatePicker';
import {
    copyEntityFormProps,
    EntityFormItemContainer,
    EntityFormProps,
    ItemProps,
    /* Use to call commentsController for referent */
} from '../entityForm/EntityFormItemContainer';
/**
 * EntityFormItemContainer for Date picker input
 * @param props
 * @constructor
 */
export function DatePickerFormItem(props: ItemProps & Omit<DatePickerProps, 'disabled'> & EntityFormProps) {
    return (
      <EntityFormItemContainer
        {...copyEntityFormProps(props)}
      >
        <ResponsiveDatePicker
          onAfterChange={props.onAfterChange}
          data-testid={`${props.propertyName}`?.replace(/,/g, '_')}
          allowClear
        />
      </EntityFormItemContainer>
        );
}
