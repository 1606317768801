/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {AdminEditableList} from '../../common/AdminEditableList';
import {
    juryListInitialColumns,
    juryListInitialFilters,
    juryListMapToRow,
    juryListRenderRowContextMenu,
} from './JuryListInitialConfig';

export function JuryListPage(props) {
    return (
      <Fragment>
        <AdminEditableList
          entity="admin/jury"
          checkboxColumn
          renderRowContextMenu={juryListRenderRowContextMenu}
          initialFilters={juryListInitialFilters}
          initialColumns={juryListInitialColumns()}
          mapToRow={juryListMapToRow}
        />
      </Fragment>
    );
}
