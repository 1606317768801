import {IAdditionalDocument, IAdditionalDocumentsList} from '@fstn/ecandidaturev2_api-interfaces';
import {FormLayout} from 'antd/lib/form/Form';
import {Store} from 'antd/lib/form/interface';
import React, {HTMLAttributes, useContext} from 'react';
import {Flex} from 'rebass';
import {DeepPartial} from 'redux';
import UUID from 'uuid-js';
import {EntityFormContainer} from '../../../common/form/entityForm/EntityFormContainer';
import {EntityFormListContainer} from '../../../common/form/entityForm/EntityFormListContainer';
import {FormContentPanelContainer} from '../../../common/form/formContentPanel/FormContentPanelContainer';
import {FormStyle} from '../../../common/form/FormStyle';
import {SecretaryReviewFormFooterContainer} from '../../../common/form/secretaryReviewForm/SecretaryReviewFormFooterContainer';
import {WithSwitchToReadOnlyOnDisplayForSecretary} from '../../../common/withExtraParameters/WithSwitchToReadOnlyOnDisplayForSecretary';
import {WithInitialLoad} from '../../../common/withExtraParameters/WithInitialLoad';
import {YesNoFormItem} from '../../../common/form/inputFormItems/YesNoFormItems';
import {EntityFormListContext} from '../../../context/entityFormList.context';
import {IsSecretary} from '../../user/conditionnal/IsSecretary';
import {AdditionalDocumentForm} from './AdditionalDocumentForm';
import {useScopedSelector} from '../../../hooks/use-scoped-selector';
import {DisabledContextProviderForEntityForm} from '../../../common/rights/DisabledContextProviderForEntityForm';
import {useEntity} from '../../../hooks/use-entity';
import { RightAreaContext, RightAreaContextProviderType } from '../../canSeeCandidateFile/RightAreaContext';

export type AdditionalDocumentsListProps = HTMLAttributes<HTMLElement>;
const entity = 'additional-documents-list';

function AdditionalDocumentsListEntityForm() {
    const { deleteEntity } = useEntity();
    const rightCtx = useContext<RightAreaContextProviderType>(RightAreaContext);
    const haveDocuments = useScopedSelector<any[], boolean>('AdditionalDocumentsListEntityForm', EntityFormListContext, (c) => c[0]?.entityValue?.haveDocuments);

    return (
      <Flex flexDirection="column">
        <FormContentPanelContainer partName={entity}>
          <YesNoFormItem labelCol={{ span: 18 }} wrapperCol={{ span: 4 }} propertyName="haveDocuments" />
        </FormContentPanelContainer>
        {haveDocuments
          && (
            <EntityFormListContainer
              compact
              propertyName="additionalDocuments"
              entity="additionalDocument"
              removeDecorator={async (remove, value: IAdditionalDocument) => {
                  if (value.document) {
                      await deleteEntity('document', value.document);
                  }
                  remove();
                  rightCtx?.forceUpdate?.();
              }}
            >
              <AdditionalDocumentForm />
            </EntityFormListContainer>
          )}
      </Flex>
    );
}

export function AdditionalDocumentsListForm(props:{ layout?: FormLayout }) {
    const { deleteEntity } = useEntity();
    const onBeforeValidate = async (values: Store, differences: DeepPartial<IAdditionalDocumentsList>, setFieldsValue) => {
        if (differences.haveDocuments === false) {
            const additionalDocuments = [];
            // should force to null to delete the stay and the related document, if not set to null, object is still present in database
            if (Array.isArray(values.additionalDocuments)) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                if (Array.isArray(values.additionalDocuments)) {
                    for (const additionalDocument of values.additionalDocuments) {
                        if (additionalDocument.document) {
                           await deleteEntity('document', additionalDocument.document);
                        }
                        additionalDocuments.push(null);
                    }
                }
            }
            differences.additionalDocuments = additionalDocuments;
            setFieldsValue({ values, additionalDocuments });
        } else if (!values?.additionalDocuments && (!differences?.additionalDocuments || differences?.additionalDocuments.length === 0)) {
                const additionalDocuments = values?.additionalDocuments || [{ id: UUID.create(1).toString() }];
                differences.additionalDocuments = additionalDocuments;
                setFieldsValue({ values, additionalDocuments });
            }
        return differences;
    };

    return (
      <FormStyle>
        <WithInitialLoad entity={entity}>
          <WithSwitchToReadOnlyOnDisplayForSecretary>
            <EntityFormContainer listeners={{ onBeforeValidate }} layout={props.layout}>
              <DisabledContextProviderForEntityForm>
                {/* <MotivationLettersListEntityForm /> */}
                <AdditionalDocumentsListEntityForm />
              </DisabledContextProviderForEntityForm>
              <IsSecretary>
                <SecretaryReviewFormFooterContainer propertyName="secretaryReview" />
              </IsSecretary>
            </EntityFormContainer>
          </WithSwitchToReadOnlyOnDisplayForSecretary>
        </WithInitialLoad>
      </FormStyle>
    );
}
