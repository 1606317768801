/** @jsx jsx */
import {jsx} from '@emotion/core';
import styled from '@emotion/styled';
import {DEFAULT} from '@fstn/ecandidaturev2_api-interfaces';
import {useContext} from 'react';
import {useImmer} from 'use-immer';
import {TextOkButton} from '../../../../common/button/TextOkButton';
import {EntityFormContainer} from '../../../../common/form/entityForm/EntityFormContainer';
import {FormContentPanelContainer} from '../../../../common/form/formContentPanel/FormContentPanelContainer';
import {InputFormItem} from '../../../../common/form/inputFormItems/InputFormItem';
import {AxiosContext, AxiosContextType} from '../../../../context/axios.context';
import {sNavigateReload} from '../../../../utils/safeNavigation';
import {ADMIN} from '../../../user/UserRoutesDef';
import {ResultPanel} from '../../common/ResultPanel';
import {ButtonsFormItemLayout} from '../../../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import {DisabledContextProviderAlwaysEditableAdmin} from '../../../../common/rights/DisabledContextProviderAlwaysEditableAdmin';
// @ts-ignore

const Style = styled.div``;

/**
 *  LAURENT exemple de page d'édition
 * @param props
 * @constructor
 */
export function LocaleEditPage(props) {
  const { axios } = useContext<AxiosContextType>(AxiosContext);

  const [state, updateState] = useImmer({
    captcha: false,
valid: false,
loading: false,
    error: undefined as string,
result: undefined as string,
  });

  const onFinish = async (values) => {
    try {
      updateState((draft) => {
        draft.loading = true;
      });
      await axios.post('/locales', values);
      updateState((draft) => {
        draft.result = 'Entrée locale créée';
        draft.error = undefined;
      });
      const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await sleep(2500);
      sNavigateReload(ADMIN.LOCALE_EDIT);
    } catch (ex) {
      updateState((draft) => {
        draft.error = ex.response.data.message;
        draft.result = undefined;
      });
    } finally {
      updateState((draft) => {
        draft.loading = false;
      });
    }
  };

  return (
    <Style>
      <EntityFormContainer
        entity="locale"
        initialValues={{ ...props, rights: DEFAULT }}
        noSave
        layout="vertical"
        onFinish={onFinish}
      >
        <DisabledContextProviderAlwaysEditableAdmin>
          <FormContentPanelContainer partName="locale">
            <InputFormItem
              propertyName="id"
              required
              rules={[{ required: true, message: 'Champ obligatoire !' }]}
            />
            <InputFormItem
              propertyName="value"
              required
              rules={[{ required: true, message: 'Champ obligatoire !' }]}
            />
            <ButtonsFormItemLayout>
              <SubmitButton />
            </ButtonsFormItemLayout>
          </FormContentPanelContainer>
        </DisabledContextProviderAlwaysEditableAdmin>
      </EntityFormContainer>
      <ResultPanel error={state.error} result={state.result} />
    </Style>
  );
}

function SubmitButton() {
  return (
    <TextOkButton
      tkey="locale.add"
      htmlType="submit"
    />
  );
}
