/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { InputTextAreaWithSubmit } from '../../../common/form/inputFormItems/InputTextAreaWithSubmitFormItem';

export function InputComments(props: { type: 'commentsBtwSecretaries' | 'commentsBtwAllTypesOfSecretaries', value: string, notifyNewComment: Function, disabled: boolean }) {
  return (
    <Fragment>
      <InputTextAreaWithSubmit
        rows={15}
        disabled={props.disabled}
        value={props.value}
        propertyName={props.type}
        onChange={props.notifyNewComment}
        onBlur={props.notifyNewComment}
      />
    </Fragment>
  );
}
