/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import styled from '@emotion/styled';
import React, {useContext} from 'react';
import {useDeepCompareEffect} from 'use-deep-compare';
import {useImmer} from 'use-immer';
import {DocumentUtils} from '@fstn/ecandidaturev2_api-interfaces';
import {AxiosContext, AxiosContextType} from '../../context/axios.context';
import {UserContext, UserContextType} from '../../context/user.context';
import {useLoading} from '../../hooks/use-loading';
import {CandidateFilePDF} from '../canSeePDF/candidateFilePDF/CandidateFilePDF';
import {CandidateFileForExport} from '../canSeePDF/candidateFilePDF/export.type';
import {getDocument, toDataURL} from '../canSeePDF/candidateFilePDF/utils';

const Style = styled.div`
    height:100%;
    width: 100%;
    .container {
      width: 100%;
      gap: 1em;
    }
`;

/**
 * Composant to review Candidate Submit Page that contains candidate file review and PDF
 * @constructor
 */
export function CandidateFileReviewWithPDFAndMessageContainer() {
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const { userCtx } = useContext<UserContextType>(UserContext);
    const { doAction, StrictLoadingContainer } = useLoading(true);

    const [state, updateState] = useImmer({ candidateFiles: [] });
    useDeepCompareEffect(() => {
       doAction(async () => {
            async function load() {
                const res = await axios.put('export/candidate/candidate-files/json', {
                    groups: [],
                });
                // We load avatar here to keep using http cookie
                for (const candidateFile of res.data) {
                    // eslint-disable-next-line no-await-in-loop
                    candidateFile.avatarAsBase64 = (await toDataURL(DocumentUtils.getPreviewLink(getDocument(candidateFile), ''))) as any;
                }
                updateState((draft) => {
                    draft.candidateFiles = res.data;
                });
            }

            load().then();
        });
    }, [userCtx.user]);

    return (
      <StrictLoadingContainer>
        <CandidateFileReviewWithPDFAndMessage candidateFiles={state.candidateFiles} />
      </StrictLoadingContainer>
);
}

function CandidateFileReviewWithPDFAndMessage(props: { candidateFiles: CandidateFileForExport[] }) {
    return (
      <Style>
        <div css={css`height:100%`} className="container">
          <div css={css`height: calc( 100%);width: 100%`}>
            <CandidateFilePDF candidateFiles={props.candidateFiles} />
          </div>
        </div>
      </Style>
    );
}
