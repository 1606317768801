/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Divider, Modal } from 'antd';
import Checkbox from 'antd/lib/checkbox';
import { Fragment } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import { TextOkButton } from '../../common/button/TextOkButton';
import * as Locale from '../../common/locale';
import { labelTrad } from '../../common/locale';
import { useLoading } from '../../hooks/use-loading';
import { useSafeTranslation } from '../../hooks/use-safe-translation';
import { ExportGroupEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { useExportUtils } from '../../hooks/use-export-utils';
import { endExportPopup } from '../../utils/endExportPopup';
// @ts-ignore

const Style = styled.div`
  .ant-checkbox-group-item {
    width: 100%;
  }

  .ant-checkbox-wrapper {
    zoom: 1;
  }
`;

/**
 * Allow to select what group of data you want to export
 * @param props : excludedGroups permet de lister les groupes a ne pas proposer dans la sélection (si non compatible avec le rôle notamment)
 * @constructor
 */
export function ExportGroupTypeSelector(props: { segmentId: String, details, excludedGroups?: ExportGroupEnum[] }) {
  const [state, updateSate] = useImmer({
    exportGroupOptions: [],
    selectedExportGroupOptions: [],
    checkAll: false,
    indeterminate: false,
  });
  const { downloadExport } = useExportUtils();
  const { t } = useSafeTranslation();
  const { doAction, InlineLoadingContainer } = useLoading(true);

  useDeepCompareEffect(() => {
    doAction(() => {
      const exportGroupOptions: any[] = [];
      // On crée la liste des cases à cocher pour chaque groupe à partide l'enum ExportGroupEnum
      // Sauf les groupes explicitement exclus (passes en parametre)
      for (const elem in ExportGroupEnum) {
        if (!props.excludedGroups?.includes(ExportGroupEnum[elem])) {
          exportGroupOptions.push({
            value: ExportGroupEnum[elem],  // car elem contient la clé de l'enum, pas la value
            label: labelTrad(elem, t),
            checked: true   // cochée par défaut
          });
        }
      }
      onExportGroupSelection(exportGroupOptions.map((o) => o.value));
      updateSate(((draft) => {
        draft.exportGroupOptions = exportGroupOptions;
      }));
    });
  }, [props.segmentId]);

  const onExportGroupSelection = (values) => {
    updateSate(((draft) => {
      draft.selectedExportGroupOptions = values;
      draft.indeterminate = (!!values.length && values.length < draft.exportGroupOptions.length);
      draft.checkAll = (values.length === draft.exportGroupOptions.length);
    }));
  };

  const onCheckAllChange = (e) => {
    updateSate((draft) => {
      draft.selectedExportGroupOptions = (e.target.checked ? state.exportGroupOptions.map((o) => o.value) : []);
      draft.indeterminate = (false);
      draft.checkAll = (e.target.checked);
    });
  };

  const onExport = () => doAction(async () => {
    await downloadExport(props.segmentId, state.selectedExportGroupOptions);
    Modal.destroyAll();  // On close la modale qui a permis de lancer l'export
    endExportPopup('downloadXls');
  });

  return (
    <InlineLoadingContainer>
      <Style>
        <Fragment>
          <br />
          <div css={css`display: flex;
                          justify-content: space-between;
                          align-items: center`}
          >
            <Checkbox
              indeterminate={state.indeterminate}
              onChange={onCheckAllChange}
              checked={state.checkAll}
            >
              <Locale.Label tkey="checkAll" />
            </Checkbox>

            <TextOkButton
              tkey="export"
              onClick={onExport}
              disabled={state.selectedExportGroupOptions.length === 0}
            />
          </div>
          <Divider />
          <Checkbox.Group
            defaultValue={state.selectedExportGroupOptions}
            value={state.selectedExportGroupOptions}
            options={state.exportGroupOptions}
            onChange={onExportGroupSelection}
          />
        </Fragment>
      </Style>
    </InlineLoadingContainer>
  );
}
