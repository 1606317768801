/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext } from 'react';
import { UserRoleEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { UserContext } from '../../../context/user.context';

export function IsAdmin(props: { children?:any, elseChildren?: any }) {
  const { userCtx } = useContext(UserContext);
  return (
    <Fragment>
      {(userCtx.user?.role?.name === UserRoleEnum.ADMIN) && props.children}
      {(userCtx.user?.role?.name !== UserRoleEnum.ADMIN) && props.elseChildren}
    </Fragment>
  );
}
