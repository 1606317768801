/** @jsx jsx */
import { CheckCircleOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  CandidateFileStatusEnum,
  IPayment,
  IPaymentsList,
  walkEntityPropertyAndMapDateStrAsMoment,
} from '@fstn/ecandidaturev2_api-interfaces';
import { css, jsx } from '@emotion/core';
import KRGlue from '@lyracom/embedded-form-glue';
import { Timeline } from 'antd';
import Alert from 'antd/lib/alert';
import Badge from 'antd/lib/badge';
import Card from 'antd/lib/card';
import Modal from 'antd/lib/modal';
import Popconfirm from 'antd/lib/popconfirm';
import moment from 'moment';
import React, { Fragment, useContext, useLayoutEffect } from 'react';
import * as CurrencyFormat from 'react-currency-format';
import { useImmer } from 'use-immer';
import { ResponsiveButton } from '../../../common/input/ResponsiveButton';
import * as Locale from '../../../common/locale';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { UserContext, UserContextType } from '../../../context/user.context';
import { useEntity } from '../../../hooks/use-entity';
import { CardImage } from '../../../assets/image/CardImage';
import { PaymentCard } from './PaymentCard';
import { PaymentHistory } from './PaymentHistory';
import { IsDesktop } from '../../user/conditionnal/IsDesktop';
import { IsMobile } from '../../user/conditionnal/IsMobile';

export function PaymentHistoryLine(props: { payment: IPayment }) {
  const { canEditValidation } = useContext<UserContextType>(UserContext);
  const { axios } = useContext<AxiosContextType>(AxiosContext);

  // Extraction de l'id de la transaction depuis l'objet de paiement en vue de l'afficher
  let transactionId = null;
  const indexPayment = props.payment.details?.length;
  if (indexPayment) {
    const index = props.payment.details[indexPayment - 1]?.transactions?.length;
    if (index) {
      transactionId = props.payment.details[indexPayment - 1]?.transactions[index - 1]?.transactionDetails?.cardDetails?.legacyTransId;
    }
  }
  return (
    <Timeline.Item
      key={props.payment.id}
      css={css`.ant-timeline-item-head-custom{
align-items: center;
display: flex;
height: 80px !important;
}`}
      color={props.payment.received ? 'green' : 'red'}
      dot={props.payment.received
        ? <CheckCircleOutlined style={{ fontSize: '20px' }} />
        : <ExclamationCircleOutlined style={{ fontSize: '20px' }} />}
    >
      <Card
        size="small"
        css={css`.ant-card-body{width: 100%;}`}
        extra={
          canEditValidation() && props.payment.extra && !props.payment.received && (
            <Popconfirm
              okType="danger"
              title={<Locale.Title tkey="delete.confirm" />}
              onConfirm={async () => {
                await axios.delete(`extra-payment/${props.payment.id}`);
                window.location.reload();
              }}
              okText={<Locale.Button tkey="delete.ok" />}
              cancelText={<Locale.Button tkey="delete.cancel" />}
            >
              <ResponsiveButton
                data-testid="delete"
                danger
                type="primary"
              >
                <DeleteOutlined />
              </ResponsiveButton>
            </Popconfirm>
          )
        }
      >
        <div css={css`width: 100%; display: flex; gap: 1em; align-items: center`}>
          <CurrencyFormat
            displayType="text"
            suffix="€"
            value={props.payment.amount}
          />
        </div>
        {props.payment.details?.map?.((paymentDetail, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={i}>
            <div css={css`width: 100%; display: flex; gap: 1em; align-items: center`}>
              {moment(props.payment.createdAt).locale('fr').format('DD/MM/YYYY')}
              <CurrencyFormat
                displayType="text"
                suffix="€"
                value={(paymentDetail.orderDetails?.orderTotalAmount) / 100}
              />
              <span>
                <Badge
                  status={paymentDetail?.orderStatus === 'PAID' ? 'success' : 'error'}
                  text={paymentDetail?.orderStatus}
                />

              </span>
              {transactionId &&
                <span>
                  <Locale.Label tkey='transactionId' /> : {transactionId}
                </span>}
            </div>
            {paymentDetail.transactions?.[0]?.detailedErrorMessage
              && <span><Alert message={paymentDetail.transactions?.[0]?.detailedErrorMessage} type="error" /></span>}
          </Fragment>
        ))}

        {props.payment?.extraReason && <span>{props.payment?.extraReason}</span>}
      </Card>

    </Timeline.Item>
  );
}

export function PaymentForm({ display, cancelPayment, status }
  : { display: boolean, cancelPayment: () => void, status: CandidateFileStatusEnum.SUBMITTED | CandidateFileStatusEnum.FORCED }) {
  const [state, updateState] = useImmer({
    promiseError: null,
    formToken: undefined,
    loaded: false,
    needReload: false,  // Permet d'indiquer qu'un paiement en echec a ete remonte et qu'il faudra donc recharger la liste des transaction pour affichage dans history
    paymentsList: undefined as IPaymentsList,

  });
  const { loadEntity } = useEntity();

  const endpoint = 'https://api.systempay.fr';

  let publicKey = '96555040:publickey_QAqbYCfuO83IndxuwUbxU35Ze0hlXkL0O4rTtXFEugYcw';
  if ((process.env.REACT_APP_ENV as string) === 'preproduction' || process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'devTest') {
    publicKey = '96555040:testpublickey_Hx07ROrTFcyZBEwQAt63l2SYgt7v9NjJoWUCnwTou2QPS';
  }

  useLayoutEffect(() => {
    async function loadToken() {
      try {
        updateState((draft) => {
          draft.loaded = true;
        });

        const paymentsList = await loadEntity('payments-list');
        walkEntityPropertyAndMapDateStrAsMoment(paymentsList);
        updateState((draft) => {
          draft.paymentsList = paymentsList;
        });

        // On passe status dans l'url pour qu'il soit enregistre en metadata dans la creation de la transaction a la banque
        // car c'est la banque qui va nous appeler directement pour envoyer les informations de paiements (et donc permettre d'enregistrer le bon statut du dossier
        // si le paiement est valide)
        const formToken = await loadEntity(`bank/${status}/create`);  
        updateState((draft) => {
          draft.formToken = formToken;
        });
      } catch (e) {
        updateState((draft) => {
          draft.promiseError = `${e} (see console for more details)`;
        });
      }
    }

    if (!state.loaded && display) {
      loadToken().then();
    }
  }, [state.loaded, display, loadEntity, updateState, status]);
  // loop add  loadEntity, updateState

  if (!state.formToken) {
    return <Fragment />;
  }

  KRGlue.loadLibrary(endpoint, publicKey) /* Load the remote library */
    .then(({ KR }) => KR.setFormConfig({
      /* set the minimal configuration */
      formToken: state.formToken,
      'kr-language': 'fr',
      'kr-post-url-success': `/api/bank/completed`,  // Url appelee apres retour validation de la banque (retour envoye apres la notification IPN)
    }))
    .then(({ KR }) => {
      // record payment error : l'echec a ete notifie en direct (IPN) par la banque, donc on recharge la liste des paiements pour recuperer les details de l'echec
      KR.onError(async (error) => {
        const paymentsList = await loadEntity('payments-list');
        walkEntityPropertyAndMapDateStrAsMoment(paymentsList);

        updateState((draft) => {
          draft.paymentsList = paymentsList;
          draft.needReload = true;  // Nouvel echec de paiement recu a recharger dans l'history donc needReload
        });
      });
      return KR.addForm('#myPaymentForm');
    }) /* add a payment form  to myPaymentForm div */
    .then(({ KR, result }) => KR.showForm(result.formId)) /* show the payment form */
    .catch((error) => updateState((draft) => {
      draft.promiseError = `${error} (see console for more details)`;
    }));

  return (
    <div className="form">

      <div className="container">
        <Modal
          visible
          width={900}
          onCancel={cancelPayment}
          destroyOnClose
          title={<Locale.Title tkey="payment.resume" />}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
        >
          <IsDesktop>
            <PaymentHistory paymentsList={state.paymentsList} needReload = {state.needReload} />
          </IsDesktop>
          <div css={css`
                                .table-title{
                                  background-color: whitesmoke;
                                }
                                .table-row-title{
                                  background-color: whitesmoke;
                                }
`}
          >
            <IsMobile>
              <PaymentCard />
            </IsMobile>
            <div style={{ display: 'flex', backgroundColor: 'whitesmoke' }}>

              <IsDesktop>
                <div style={{
                  padding: '3em',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                }}
                >
                  <PaymentCard />
                </div>
              </IsDesktop>
              <div
                id="myPaymentForm"
                style={{
                  width: '500px', display: 'flex', alignItems: 'center', padding: '3em',
                }}
              >
                <CardImage />
              </div>
            </div>
          </div>
        </Modal>
        <div>{state.promiseError}</div>
      </div>
    </div>
  );
}
