import React, {useContext, useEffect, useState} from 'react';
import {AxiosContext, AxiosContextType} from '../../../context/axios.context';
import {useEntity} from '../../../hooks/use-entity';
import {CandidateFileContext, CandidateFileContextProviderType} from '../../canSeeCandidateFile/CandidateFileContext';
import {InputComments} from './InputCommentsItem';

/**
 * Comments used for comments between secretaries
 * @param props
 * @constructor
 */
export function InputCommentsItemContainer(props: { type: 'commentsBtwSecretaries' | 'commentsBtwAllTypesOfSecretaries', disabled?: boolean }) {
    const { putEntity } = useEntity<any>();
    const [value, setValue] = useState();
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    const notifyNewComment = async (c) => {
        await putEntity(`comments/${props.type}`,
            { [`${props.type}`]: c, id: undefined }, { [`${props.type}`]: c });
    };

    async function load() {
        const params: any = {};
        if (candidateFileContext) {
            params.candidateFileId = candidateFileContext.selectedCandidateFile?.id;
            params.random = Math.random() * 1000;
        }
        const { data } = await axios.get(`comments/${props.type}`, { params });
        setValue(data);
    }

    useEffect(() => {
        load().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <InputComments notifyNewComment={notifyNewComment} type={props.type} value={value} disabled={props.disabled} />
    );
}
