import {usePath} from 'hookrouter';
import React from 'react';
import {CandidateFilesListModalContainer} from '../../canSeeCandidateFile/candidateFilesList/CandidateFilesListModalContainer';
import {ExaminerHome} from '../ExaminerHome';
import {CustomPageHeader} from '../ExaminerPage';

export function DefaultPage() {
    const path = usePath();
    const visible = path === '/home/welcome' || path === '/home/welcome/examiner';
    return (
      <CandidateFilesListModalContainer title={<CustomPageHeader />} visible={visible}>
        <ExaminerHome />
      </CandidateFilesListModalContainer>
    );
}
