/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext } from 'react';
import { Importer, ImporterField } from 'react-csv-importer-v2';

/**
 * LAURENT 
 * DOCUMENTATION: npm i react-csv-importer-v2
 */
// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer-v2/dist/index.css';
import { useImmer } from 'use-immer';
import { AxiosContext, AxiosContextType } from '../../../../context/axios.context';
import { sNavigateReload } from '../../../../utils/safeNavigation';
import { ADMIN } from '../../../user/UserRoutesDef';
import { ResultPanel } from '../../common/ResultPanel';
import { ProgramListPage } from './ProgramListPage';

export function ProgramImporter() {
  const [state, updateState] = useImmer({ values: [], error: undefined as string, result: undefined as string });
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  return (
    <Fragment>
      <Importer
        delimiter=";"
        // restartable // optional, lets user choose to upload another file when import is complete
        onStart={() => {
        }}
        processChunk={async (rows) => {
          updateState((draft) => {
            draft.values = [...draft.values, ...rows];
          });
        }}
        onComplete={async () => {
          try {
            const response: any = await axios?.post('/import/program', {
              bulk: state.values,
            });
            updateState((draft) => {
              draft.result = `${response.data} formations importées --- ${state.values.length - response.data} formations mises à jour`;
              draft.error = undefined;
            });
          } catch (ex) {
            updateState((draft) => {
              draft.error = ex.response.data.message;
              draft.result = undefined;
            });
          }
        }}
        onClose={async () => {
          sNavigateReload(ADMIN.PROGRAM_IMPORTER);
        }}
      >
        <ImporterField name="name" label="Name" />
        <ImporterField name="option_name" label="option_name" />
        <ImporterField name="opened" label="opened" />
        <ImporterField name="capacity" label="capacity" />
        <ImporterField name="home_position" label="home_position" />
        <ImporterField name="website" label="website" />
        <ImporterField name="school" label="school" />
        <ImporterField name="campus" label="campus" />
        <ImporterField name="sendgrid_api_key" label="sendgrid_api_key" />
        <ImporterField name="email_sender" label="email_sender" />
        <ImporterField name="not_summoned_template_id" label="not_summoned_template_id" />
        <ImporterField name="summoned_writings_template_id" label="summoned_writings_template_id" />
        <ImporterField name="summoned_orals_template_id" label="summoned_orals_template_id" />
        <ImporterField name="eligible_comp_list_template_id" label="eligible_comp_list_template_id" />
        <ImporterField name="ok_eligible_template_id" label="ok_eligible_template_id" />
        <ImporterField name="elite_admitted_template_id" label="elite_admitted_template_id" />
        <ImporterField name="admitted_template_id" label="admitted_template_id" />
        <ImporterField name="waiting_list_admitted_template_id" label="waiting_list_admitted_template_id" />
        <ImporterField name="not_selected_jury_final_template_id" label="not_selected_jury_final_template_id" />
        <ImporterField name="cancelled_template_id" label="cancelled_template_id" />
        <ImporterField name="absent_tests_template_id" label="absent_tests_template_id" />
        <ImporterField name="accepted_school_tracks" label="accepted_school_tracks" />
        <ImporterField name="logo_url" label="logo_url" />
        <ImporterField name="logo2_url" label="logo2_url" />
        <ImporterField name="notice_url" label="notice_url" />
        <ImporterField name="status" label="status" />
        <ImporterField name="theme" label="theme" />
        <ImporterField name="required_level" label="required_level" />
        <ImporterField name="specific_question" label="specific_question" />
      </Importer>
      <br />
      <ResultPanel error={state.error} result={state.result} redirect={ADMIN.PROGRAM_IMPORTER} />
      <br />
      <span><h1>Liste des formations</h1></span>
      <ProgramListPage />
    </Fragment>
  );
}
