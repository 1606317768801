/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Modal } from 'antd';
import { UserPage } from '../../user/UserPage';

export function CandidateFilesListModalContainer(props:{ children:any, visible:boolean, title: any }) {
    return (
      <UserPage>
        <Modal
          className="full-screen without-footer"
          closable={false}
          width="100vw"
          footer={false}
          title={props.title}
          visible={props.visible}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          css={css`.ant-form,
.ant-col,
.ant-form-item-control,
.ant-form-item-control-input,
.ant-form-item-control-input-content{
  height: 100% !important;
}`}
        >
          {props.children}
        </Modal>
      </UserPage>
);
}
