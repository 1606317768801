/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext } from 'react';
import { Importer, ImporterField } from 'react-csv-importer-v2';

/**
 * LAURENT
 * DOCUMENTATION: npm i react-csv-importer-v2
 */
// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer-v2/dist/index.css';
import { useImmer } from 'use-immer';
import { AxiosContext, AxiosContextType } from '../../../../context/axios.context';
import { sNavigateReload } from '../../../../utils/safeNavigation';
import { ADMIN } from '../../../user/UserRoutesDef';
import { ResultPanel } from '../../common/ResultPanel';
import { ButtonDeleteAll } from '../../common/ButtonDeleteAll';
import { IdiomListPage } from './IdiomListPage';

export function IdiomImporter() {
  const [state, updateState] = useImmer({ values: [], error: undefined as string, result: undefined as string });
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  return (
    <Fragment>
      <Importer
        delimiter=";"
        // restartable // optional, lets user choose to upload another file when import is complete
        onStart={() => {
        }}
        processChunk={async (rows) => {
          updateState((draft) => {
            draft.values = [...draft.values, ...rows];
          });
        }}
        onComplete={async () => {
          try {
            const response: any = await axios?.post('/import/idiom', {
              bulk: state.values,
            });
            updateState((draft) => {
              draft.result = `${response.data} langues importées`;
              draft.error = undefined;
            });
          } catch (ex) {
            updateState((draft) => {
              draft.error = ex.response.data.message;
              draft.result = undefined;
            });
          }
        }}
        onClose={async () => {
          sNavigateReload(ADMIN.IDIOM_IMPORTER);
        }}
      >
        <ImporterField name="name" label="Name" />
        <ImporterField name="ranking" label="Ranking" />
        <ImporterField name="mandatory" label="Mandatory" />
      </Importer>
      <br />
      <ButtonDeleteAll message="Langues supprimés sur" url="/api/admin/idiom/deleteAll" updateState={updateState} />
      <ResultPanel error={state.error} result={state.result} redirect={ADMIN.IDIOM_IMPORTER} />
      <br />
      <span><h1>Liste des langues</h1></span>
      <IdiomListPage />
    </Fragment>
  );
}
