import React, { Fragment } from 'react';
import { ReferentChild1Container } from './ReferentChild1Container';

/**
 * Routes starting with /home/welcome/referent/
 * @constructor
 */
export const ReferentContainerRoutesDef = () => ({
    '/referent*': () => (
      <Fragment>
        <ReferentChild1Container />
      </Fragment>
    ),

});
