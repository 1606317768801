/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import _ from 'lodash';
import React from 'react';
import styled from '@emotion/styled';
import { useResponsiveInputSize } from '../../hooks/use-responsive-input-size.hook';

const Style = styled.div`
  .ant-select-selection-item{
    max-width: 100% !important;
  }`;
/**
 * Responsive select
 * @param props
 * @constructor
 */
export function ResponsiveSelect(props: SelectProps<string>) {
    const { size } = useResponsiveInputSize();
    return (
      <Style>
        <Select
          {..._.omit(props)}
          size={size}
        >
          {props.children}
        </Select>
      </Style>
);
}
