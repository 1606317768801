import React from 'react';
import { Container, PDFCard } from '../../../common/pdf/components/PDFCard';
import { PDFH1 } from '../../../common/pdf/components/PDFH1';
import { PDFText } from '../../../common/pdf/components/PDFText';
import { PDFTextGutter } from '../../../common/pdf/components/PDFTextGutter';
import { LanguagesList } from './export.type';

export function LanguagesListFormPDF({ languagesList, debug }: { languagesList: LanguagesList, debug?: boolean }) {
  const english = Object.values(languagesList?.languages)?.find((l) => l.idiom?.name?.toLowerCase() === 'anglais');

  return (
    <Container>
      <PDFH1>Anglais</PDFH1>
      <PDFCard
        bordered
        title={(
          <PDFTextGutter>
            <PDFText color="white">Niveau</PDFText>
            <PDFText color="white">Certification</PDFText>
            <PDFText color="white">Score</PDFText>
          </PDFTextGutter>
        )}
      >
        <PDFTextGutter>
          <PDFText>{english?.level?.id}</PDFText>
          <PDFText>{english?.certification}</PDFText>
          <PDFText>{english?.score}</PDFText>
        </PDFTextGutter>
      </PDFCard>

    </Container>
  );
}
