/** @jsx jsx */
import {jsx} from '@emotion/core';
import {InputFilter} from '../filters/InputFilter';
import * as Locale from '../../../../common/locale';

export const EmailFilter = InputFilter;
export const emailFilterEditorProps = {
    className: 'emailFilter',
    'test-id': 'emailFilter',
};

export function EmailCellRender({ value, data }: any) {
    return (
      <a
        key={`email_cell_${data.id}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
        href={`mailto:${value}`}
      >
        {value}
      </a>
);
}

export const emailColumn = {
    header: <Locale.Header tkey="candidateFilesList.email" />,
    name: 'email',
    id: 'email',
    className: 'email',
    'test-id': 'email',
    group: 'profile',
    lockable: false,
    filterable: true,
    defaultWidth: 190,
    filterEditor: EmailFilter,
    filterEditorProps: emailFilterEditorProps,
    render: EmailCellRender,
};

export const emailFilter = {
    name: 'email', operator: 'startsWith', type: 'string', value: '',
};

export const emailFilterType = {
};
