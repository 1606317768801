/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {AreaContainerPanel} from '../../../common/layout/AreaContainerPanel';

export function FlexibleCenterRightAreaContainer(props: any) {
    return (
      <AreaContainerPanel css={css`
    display: flex;
    flex: 1;
`}
      >
        {props.children}
      </AreaContainerPanel>
    );
}
