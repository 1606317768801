import { Radio, RadioChangeEvent } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import React, { Fragment } from 'react';

import * as Locale from '../../locale';
import { DisabledContext } from '../../rights/DisabledContext';
import { copyEntityFormProps, EntityFormItemContainer, ItemProps } from '../entityForm/EntityFormItemContainer';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

/**
 * EntityFormItemContainer for yes no form item
 * @param props
 * @constructor
 */
export function YesNoFormItem(props: ItemProps & Omit<CheckboxProps, 'disabled'> & { yesLabel?: any, noLabel?: any, readOnly?: boolean }) {
  return (
    <EntityFormItemContainer
      {...copyEntityFormProps(props)}
      validateTrigger={['onChange', 'onBlur']}
    >
      <YesNo
        yesLabel={props.yesLabel}
        noLabel={props.noLabel}
        defaultValue={props.value} /* on ne peut pas utiliser value car il est filtre pour une raison inconnue donc on prend defaultValue */
        data-testid={`${props.propertyName}`?.replace(/,/g, '_')}
        readOnly={props.readOnly}
      />
    </EntityFormItemContainer>
  );
}

export function YesNo(props: {
  value?: boolean,
  onChange?: (e: RadioChangeEvent) => void,
  'data-testid'?: any,
  yesLabel?: any,
  noLabel?: any,
  readOnly?: boolean,
  defaultValue?: boolean // Utilise quand on veut forcer la valeur en appel direct (car value ne fonctionne pas)
}) {
  const optionsWithDisabled = [
    { label: props.yesLabel || <Locale.Button tkey="yes" />, value: true, disabled: props.readOnly },
    { label: props.noLabel || <Locale.Button tkey="no" />, value: false, disabled: props.readOnly },
  ];
  const disabled = useScopedSelector('YesNo', DisabledContext, (c) => c?.disabled);

  return (
    <Fragment>
      <Radio.Group
        options={optionsWithDisabled}
        onChange={props.onChange}
        /* Pour value, on prend defaultValue ou value car pour une raison etrange, le value, quand il est passe en prop, voit sa valeur passer a undefined ??
           Donc quand on veut forcer la valeur a oui (cas d'ajout d'une annee post-bac dans la combo), on utilise defaultValue
        */
        value={props.defaultValue || props.value}
        optionType="button"
        buttonStyle="solid"
        size="large"
          /* ok */ disabled={disabled}
        className={props['data-testid']}
        data-testid={props['data-testid']}
      />
    </Fragment>
  );
}
