/** @jsx jsx */
import StringFilter from '@inovua/reactdatagrid-community/StringFilter';
import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import { MutableRefObject } from 'react';
import { jsx } from '@emotion/core';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { ISecretary, SUPER_SECRETARY_RIGHTS } from '@fstn/ecandidaturev2_api-interfaces';

import BoolEditor from '@inovua/reactdatagrid-community/BoolEditor';
import _ from 'lodash';
import { CustomGridSelectEditor } from '../../../../common/customDatagrid/customGridSelectEditor';
import { SecretaryContextMenu } from './SecretaryContextMenu';
import { getSelectedElements } from '../../common/AdminEditableList';

// Pour alimentation des combos (filtres et edition) du genre
const listGender = [
    { id: 'm', label: 'Masculin' },
    { id: 'f', label: 'Féminin' },
    { id: 'o', label: 'Autre' }];

export const secretaryListInitialColumns = () => [
    {
        header: 'Genre',
        name: 'gender',
        width: 150,

        editable: true,
        style: { textAlign: 'center' },

        render: ({ value }) => listGender.find((element) => element.id === value)?.label,
        filterEditor: SelectFilter,
        filterEditorProps: {
            placeholder: 'All',
            dataSource: listGender,
        },
        renderEditor: (editorProps) => <CustomGridSelectEditor {...editorProps} />,
        editorProps: {
            idProperty: 'gender',
            dataSource: listGender,
        },
    }, {
        header: 'Prénom',
        name: 'firstName',
        width: 200,

        editable: true,
        style: { textAlign: 'left' },

    }, {
        header: 'Nom',
        name: 'lastName',
        width: 200,

        editable: true,
        style: { textAlign: 'left' },

    }, {
        header: 'E-mail',
        name: 'email',
        className: 'email',
        'test-id': 'email',
        width: 250,

        editable: true,
        style: { textAlign: 'left' },
        filterEditor: StringFilter,
        filterEditorProps: {
            className: 'emailFilter',
            'test-id': 'emailFilter',
        },

    }, {
        header: 'Super secrétaire',
        name: 'superSecretary',
        render: ({ value }) => (value ? 'OUI' : 'NON'),
        editor: BoolEditor,
        width: 160,

        editable: true,
        style: { textAlign: 'center' },

    }, {
        header: 'SD+',
        name: 'plusSecretary',
        render: ({ value }) => (value ? 'OUI' : 'NON'),
        editor: BoolEditor,
        width: 160,

        editable: true,
        style: { textAlign: 'center' },

    }
];

export const secretaryListInitialFilters = [
    {
        name: 'firstName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'lastName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'email', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'gender', operator: 'contains', type: 'string', value: '',
    },
];

export function secretaryListRenderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedUsers, gridRef) {
    return (
        <SecretaryContextMenu
            menuProps={menuProps}
            details={details}
            selectedUsers={getSelectedElements(details.rowProps.data, selectedUsers)}
            gridRef={gridRef}
        />
    );
}

/**
 * Map result to row
 * @param d
 */
export function secretaryListMapToRow(d: ISecretary) {
    const row: any = {
        id: d.id,
        firstName: d.firstName,
        lastName: d.lastName,
        email: d.email,
        gender: d.gender,
        superSecretary: _.isEqual(d.rightsOverriding, SUPER_SECRETARY_RIGHTS),
        plusSecretary: d.plusSecretary
    };
    return row;
}
