/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Card, Form, Button, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { TextOkButton } from '../../../common/button/TextOkButton';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import * as Locale from '../../../common/locale';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { EntityFormContextProvider } from '../../../context/entityForm.context';
import { EntityFormListContextProvider } from '../../../context/entityFormList.context';
import { UserContext, UserContextType } from '../../../context/user.context';
import { LOGIN } from '../../user/UserRoutesDef';
import { sNavigate } from '../../../utils/safeNavigation';
import { ButtonsFormItemLayout } from '../../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import { useLoading } from '../../../hooks/use-loading';
import { DisabledContextProviderAlwaysEnabled } from '../../../common/rights/DisabledContextProviderAlwaysEnabled';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { labelTrad } from '../../../common/locale/index';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';

function isTestAccount(account) {
  return account?.email?.startsWith?.('test.ecandidature+') || account?.email?.endsWith?.('@yopmail.comm') || account?.email?.endsWith?.('ethereal.email');
}

export function RegisterEmailForm() {
  const [form] = useForm();
  const { doAction, loading } = useLoading();
  const { reset, userCtx } = useContext<UserContextType>(UserContext);
  const { t } = useSafeTranslation();

  const exist = !!userCtx.user;
  useEffect(() => {
    loadCaptchaEnginge(6);
    if (exist) {
      reset?.();
    }
  }, [reset, exist]);

  const [state, updateState] = useImmer({
    captcha: undefined as string, valid: false, email: undefined, error_captcha: false as boolean
  });
  const { axios } = useContext<AxiosContextType>(AxiosContext);

  const onChange = async (values) => {
    updateState((draft) => { draft.email = values.email; });
  };

  const onFinish = async (values) => doAction(async () => {
    values.recaptha = state.captcha;
    await axios.post('/register-email', values);
    sNavigate(LOGIN.REGISTER_CODE.replace(':email', values.email));
  });

  return (
    <Card css={css`max-width: 700px; margin: auto`}>
      <DisabledContextProviderAlwaysEnabled>
        <Form
          layout="vertical"
          form={form}
          noValidate
          onFinish={onFinish}
          onValuesChange={onChange}
        >
          <EntityFormContextProvider
            entity="registrer"
            onChange={() => undefined}
            isFieldTouched={form.isFieldTouched}
            getFieldValue={form.getFieldValue}
            getFieldsValue={form.getFieldsValue}
            onImmediateChange={() => undefined}
          >
            <EntityFormListContextProvider
              entity="registrer"
              propertyName=""
              remove={() => {
              }}
            >

              <FormContentPanelContainer partName="register.email">

                <InputFormItem
                  propertyName="email"
                  rules={[{ type: 'email', required: true }]}
                  labelCol={{ span: 24 }}
                  required
                  wrapperCol={{ span: 24 }}
                  autoComplete="email"
                />
                {(!isTestAccount(state))
                  && (
                    <div>
                      <br />
                      <LoadCanvasTemplate />
                      <Locale.Label tkey='saisiecaptcha' />
                      <Input
                        name="myCaptcha"
                        id="myCaptcha"
                      />
                      <Button onClick={() => {
                        // @ts-ignore
                        const user_captcha_value = document.getElementById('myCaptcha').value;
                        if (validateCaptcha(user_captcha_value) === true) {
                          updateState((draft) => {
                            draft.captcha = "ok";
                            draft.error_captcha = false;
                          });
                        }
                        else {
                          updateState((draft) => {
                            draft.error_captcha = true;
                          });
                        }
                      }
                      }>
                        {labelTrad("verifCaptcha", t)}
                      </Button>
                      {(state.error_captcha) &&
                        (// @ts-ignore
                          <div style={{ color: 'red' }}>
                            <Locale.Error tkey='bad_captcha'></Locale.Error>
                          </div>
                        )}
                    </div>
                  )}
                <br />
                <ButtonsFormItemLayout
                  propertyName="submit"
                  dependencies={['email']}
                >
                  <TextOkButton
                    tkey="email"
                    data-testid="submit.register-email"
                    disabled={!state.captcha && !isTestAccount(state)}
                    loading={loading}
                    htmlType="submit"
                  />
                </ButtonsFormItemLayout>
              </FormContentPanelContainer>
            </EntityFormListContextProvider>
          </EntityFormContextProvider>
        </Form>
      </DisabledContextProviderAlwaysEnabled>
    </Card>
  );
}
