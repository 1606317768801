/** @jsx jsx */
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Checkbox } from 'antd';
import { jsx } from '@emotion/core';
import * as Locale from '../../../../common/locale';

export const particularCaseColumn = {
        header: <Locale.Header tkey="candidateFilesList.particularCase" />,
        name: 'particularCase',
        id: 'particularCase',
        group: 'candidateFile',
        // @ts-ignore
        filterEditor: BoolFilter,
        defaultWidth: 70,
        lockable: false,
        filterable: true,
        render: ({ value, data }: any) => <Checkbox key={`particularCase_cell_${data.id}`} disabled checked={value} />,
    };

export const particularCaseFilter = {
    name: 'particularCase', operator: 'eq', type: 'boolean', value: null, emptyValue: null,
};
