/** @jsx jsx */
import { jsx } from '@emotion/core';
import { IPaymentsList, RightsUtils, } from '@fstn/ecandidaturev2_api-interfaces';
import { parse, stringify } from 'flatted';
import React, { HTMLAttributes, useContext } from 'react';
import { Box, Flex } from 'rebass';
import { useDeepCompareCallback } from 'use-deep-compare';
import UUID from 'uuid-js';

import { EntityFormContainer } from '../../../common/form/entityForm/EntityFormContainer';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { FormStyle } from '../../../common/form/FormStyle';
import { UploadFormItemContainer } from '../../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import { SecretaryReviewFormFooterContainer } from '../../../common/form/secretaryReviewForm/SecretaryReviewFormFooterContainer';
import { WithSwitchToReadOnlyOnDisplayForSecretary } from '../../../common/withExtraParameters/WithSwitchToReadOnlyOnDisplayForSecretary';
import { WithInitialLoad, } from '../../../common/withExtraParameters/WithInitialLoad';
import { YesNoFormItem } from '../../../common/form/inputFormItems/YesNoFormItems';
import { EntityFormContext } from '../../../context/entityForm.context';
import { EntityFormListContext } from '../../../context/entityFormList.context';
import { UserContext } from '../../../context/user.context';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { IsSecretary } from '../../user/conditionnal/IsSecretary';
import { CreateExtraPaymentForm } from './CreateExtraPaymentForm';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { DisabledContextProviderForEntityForm } from '../../../common/rights/DisabledContextProviderForEntityForm';
import { useEntity } from '../../../hooks/use-entity';
import { PaymentCard } from './PaymentCard';
import { EntityFormItemContainer } from '../../../common/form/entityForm/EntityFormItemContainer';
import { IsCandidate } from '../../user/conditionnal/IsCandidate';

export interface PaymentsListProps extends HTMLAttributes<HTMLElement> {
}

const entity = 'payments-list';

function PaymentsListEntityForm() {
  const formEntityValue = useScopedSelector<any[], IPaymentsList>('PaymentListForm', EntityFormContext, (c) => c[0]?.entityValue);

  const formListEntityId = useScopedSelector<any[], string>('LanguageForm', EntityFormListContext, (c) => c[0]?.entityValue?.id);

  const responsive = useResponsiveForm();
  const userContext = useContext(UserContext);
  const addDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'foundedDocument',
    type: 'foundedDocument',
    parentType: entity,
    parentId: formListEntityId,
  }), [entity, formListEntityId]);
  const canAddExtraPayment = RightsUtils.isAuthorizeForTable(userContext?.userCtx?.user?.rightsOverriding?.write, entity);
  return (
    <Flex flexDirection="column">
      <Box {...responsive.w1}>
        <FormContentPanelContainer partName="comon">
          <YesNoFormItem labelCol={{ span: 18 }} wrapperCol={{ span: 4 }} propertyName="funded" />
          {formEntityValue?.funded
            && (
              <UploadFormItemContainer
                propertyName="fundedDocument"
                factory={addDocument}
                accept={'image/*,.pdf'}
              />
            )}
          {formEntityValue?.funded !== undefined
            && (
              <IsCandidate>
                <EntityFormItemContainer propertyName="details" dependenciesValues={[formEntityValue?.funded]}>
                  <PaymentCard />
                </EntityFormItemContainer>
              </IsCandidate>
            )}
        </FormContentPanelContainer>

        {canAddExtraPayment && <CreateExtraPaymentForm />}
      </Box>
    </Flex>
  );
}

export const PaymentsListForm: React.FunctionComponent<PaymentsListProps> = () => {
  const { deleteEntity } = useEntity();
  const onBeforeValidate = async (values: IPaymentsList | any, differences: Partial<IPaymentsList>, setFieldsValue: (values: any) => void) => {
    differences = parse(stringify(differences));
    /* We must remove relative documents when box is unchecked */
    if (differences.funded === false && !!values.fundedDocument) {
      await deleteEntity('document', values.fundedDocument);
      // @ts-ignore remove on next deps update
      differences.fundedDocument = null;
      setFieldsValue({ values, fundedDocument: null });
    }
    return differences;
  };
  return (
    <FormStyle>
      <WithInitialLoad entity={entity}>
        <WithSwitchToReadOnlyOnDisplayForSecretary>
          <EntityFormContainer listeners={{ onBeforeValidate }}>
            <DisabledContextProviderForEntityForm>
              <PaymentsListEntityForm />
              <IsSecretary>
                <SecretaryReviewFormFooterContainer propertyName="secretaryReview" />
              </IsSecretary>
            </DisabledContextProviderForEntityForm>
          </EntityFormContainer>
        </WithSwitchToReadOnlyOnDisplayForSecretary>
      </WithInitialLoad>
    </FormStyle>
  );
};
