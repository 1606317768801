/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Button, Card, Descriptions, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { TextOkButton } from '../../../common/button/TextOkButton';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import * as Locale from '../../../common/locale';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { EntityFormContextProvider } from '../../../context/entityForm.context';
import { EntityFormListContextProvider } from '../../../context/entityFormList.context';
import { UserContext, UserContextType } from '../../../context/user.context';
import { ROUTES } from '../../user/UserRoutesDef';
import { sNavigate } from '../../../utils/safeNavigation';
import { DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { ButtonsFormItemLayout } from '../../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import { Box } from "rebass";
import { useResponsiveForm } from "../../../hooks/use-responsive-form.hook";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { labelTrad } from '../../../common/locale/index';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';

export function ForgotPasswordEmailForm() {
  const [form] = useForm();

  const { reset, userCtx } = useContext<UserContextType>(UserContext);
  const exists = !!userCtx.user;
  useEffect(() => {
    loadCaptchaEnginge(6);
    if (exists) {
      reset?.();
    }
  }, [reset, exists]);

  const [state, updateState] = useImmer({ captcha: undefined as string, valid: false, loading: false, error_captcha: false as boolean });
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  const { t } = useSafeTranslation();
  const responsive = useResponsiveForm();
  const onFinish = async (values) => {
    try {
      updateState((draft) => {
        draft.loading = true;
      });
      try {
        await axios.post('/forgot-password-email', values);
        sNavigate(`${ROUTES.PUBLIC_BASE}/forgot-password-code/${values.email}`);
      } catch (e) {
        if (e?.response?.status === 401) {
          notification.error({
            message: <Locale.Title tkey="forgot-password-email.invalid" />,
            description: <Locale.Help tkey="forgot-password-email.invalid" />,
          });
        } else {
          notification.warn({
            message: <Locale.Title tkey="server.error" />,
            description: <Locale.Help tkey="server.error" />,
          });
        }
        throw e;
      }
    } finally {
      updateState((draft) => {
        draft.loading = false;
      });
    }
  };

  return (
    <Card css={css`max-width: 700px; margin: auto`}>
      <DisabledContextProvider value={{ disabled: false, wrapAuthorized: false }}>
        <Form
          layout="vertical"
          form={form}
          className="login-form"
          noValidate
          onFinish={onFinish}
        >
          <EntityFormContextProvider
            entity="forgot-password"
            isFieldTouched={form.isFieldTouched}
            getFieldValue={form.getFieldValue}
            getFieldsValue={form.getFieldsValue}
            onChange={() => undefined}
            onImmediateChange={() => undefined}
          >
            <EntityFormListContextProvider
              entity="forgot-password"
              propertyName=""
              remove={() => {
              }}
            >

              <Descriptions title={<Locale.Title tkey="forgotPassword.email" />}>
                <Descriptions.Item label="">
                  <Locale.Help
                    tkey="forgot-password.email"
                  />
                </Descriptions.Item>
              </Descriptions>
              <Box {...responsive.w1}>
                <InputFormItem propertyName="email" required rules={[{ type: 'email', required: true }]} />
              </Box>
              <Box {...responsive.w1}>
                <br />
                <LoadCanvasTemplate />
                <Locale.Label tkey='saisiecaptcha' />
                <Input
                  name="myCaptcha"
                  id="myCaptcha"
                />
                <Button onClick={() => {
                  // @ts-ignore
                  const user_captcha_value = document.getElementById('myCaptcha').value;
                  if (validateCaptcha(user_captcha_value) === true) {
                    updateState((draft) => {
                      draft.captcha = "ok";
                      draft.error_captcha = false;
                    });
                  }
                  else {
                    updateState((draft) => {
                      draft.error_captcha = true;
                    });
                  }
                }
                }>
                  {labelTrad("verifCaptcha", t)}
                </Button>
                {(state.error_captcha) &&
                  (// @ts-ignore
                    <div style={{ color: 'red' }}>
                      <Locale.Error tkey='bad_captcha'></Locale.Error>
                    </div>
                  )}
                <ButtonsFormItemLayout>
                  <TextOkButton
                    type="primary"
                    tkey="email"
                        /* ok */ disabled={!!!state.captcha}
                    loading={state.loading}
                    htmlType="submit"
                    className="login-form-button"
                  />
                </ButtonsFormItemLayout>
              </Box>
            </EntityFormListContextProvider>
          </EntityFormContextProvider>
        </Form>
      </DisabledContextProvider>
    </Card>
  );
}
