/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext } from 'react';
import * as Locale from '../../common/locale';
import { CandidateFileContext, CandidateFileContextProviderType } from './CandidateFileContext';

export function CandidateFilePrograms() {
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    return (
      <Fragment>
        <article id="examiner-review" className="message  is-small">
          <div className="message-header">
            <Locale.Label tkey="candidateFile.programs" />
          </div>
          <div className="message-body">
            <ul>
              {candidateFileContext.selectedCandidateFile.programsList.programLines.map(
                            (p) => (
                              <li key={`programLines_${p.id}`}>
                                {/* eslint-disable-next-line */}
                                <a href={p.program?.website} target="_blank" >
                                  {' '}
                                  {`${p.program?.school} ${p.program?.name} (${(p.program?.optionName || '')} )`}
                                </a>
                              </li>
                            ),
                        )}
            </ul>
          </div>
        </article>
      </Fragment>
    );
}
