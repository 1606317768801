/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useCallback, useContext, useEffect, useMemo, useRef,
} from 'react';
import { useImmer } from 'use-immer';
import { createContext } from 'use-context-selector';
import { useValidation } from '../hooks/use-validation.hook';
import { IhmContext } from './ihm.context';

export type Rules = any;
export type RulesCB = () => Promise<Rules>;
export type Validations = any;
export type ValidationsCB = () => Promise<Validations>;

export type ValidationsContextContentType = {
    loadingsValidations: any,
    // loadingsValidationsRules: any,
    validations: any,
    // validationsRules: any
};
export type ValidationsContextType = {
    loaded: boolean,
    steps: string[],
    validationsCtxState: { string: ValidationsContextContentType },
    withUpdateValidations: (step: string, entity: string, ValidationsCB: any) => Promise<Validations>,
};

export const ValidationsContext = createContext({} as ValidationsContextType);

/**
 * Context uses to store entity validation error message
 * @param props
 * @constructor
 */
export const ValidationsContextProvider = (props: { entity: string, children: any, steps: string [], validations?: { string: ValidationsContextContentType } }) => {
    const [state, updateState] = useImmer({ loaded: !!props.validations, isLoading: false });
    const { withBlockIhm } = useContext(IhmContext);
    // console.log('Redraw ValidationsContextProvider');
    const validations = useRef({} as any);
    const initialState = useMemo(() => props.validations || props.steps.reduce((o, step) => ({
            ...o,
            [step]: {
                loadingsValidations: {},
                loadingsValidationsRules: {},
                validations: {},
                validationsRules: {},
            },
        }), {} as { string: ValidationsContextContentType }),
        // eslint-disable-next-line
        []);
    const [validationsCtxState, updateValidationsCtxState] = useImmer(initialState);
    const { validate } = useValidation();
    useEffect(() => {
        if (!state.loaded && !state.isLoading) {
            updateState((draft) => {
                draft.isLoading = true;
            });
            validate(props.entity,
                {},
                withUpdateValidations,
                props.steps).then(() => {
                updateState((draft) => {
                    draft.loaded = true;
                    draft.isLoading = false;
                });
            });
        }
    }, [props.entity, state.loaded, state.isLoading, updateState, validate]);
    // loop add

    const withUpdateValidations = useCallback((step: string, entity: string, cb: ValidationsCB): Promise<Validations> => withBlockIhm(async () => {
            updateValidationsCtxState((draft) => {
                // draft[step].validations[entity] = 'loading';
                // draft[step].loadingsValidations[entity] = true;
            });
            const updatedValidation = await cb();
            if (!validations.current[step]) {
                validations.current[step] = { validations: {}, loadingsValidations: {} };
            }
            const newValidations = { ...validations.current?.[step]?.validations, ...updatedValidation };
            const newValidationsLoadings = { ...validations.current?.[step]?.loadingsValidations, [entity]: false };
            validations.current[step].validations = newValidations;
            validations.current[step].loadingsValidations = newValidationsLoadings;
            updateValidationsCtxState((draft) => {
                draft[step].validations = newValidations;
                draft[step].loadingsValidations = newValidationsLoadings;
            });
            // console.log(`newValidations ${step} ${JSON.stringify(newValidations)}`, newValidations, updatedValidation);
            return newValidations;
        }), [updateValidationsCtxState, withBlockIhm]);

    const ctx = {
        loaded: state.loaded,
        steps: props.steps,
        validationsCtxState,
        withUpdateValidations,
        /* withUpdateRules,
        isLoadingRules, */
    };

    return (
      <ValidationsContext.Provider
        value={ctx}
      >
        {props?.children}
      </ValidationsContext.Provider>
    );
};
