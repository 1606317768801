import styled from '@emotion/styled';
import PageHeader from 'antd/lib/page-header';
// @ts-ignore
import React, { Fragment } from 'react';
import { TextDangerButton } from '../../../common/button/TextDangerButton';
import * as Locale from '../../../common/locale';
import { sNavigate } from '../../../utils/safeNavigation';
import { CenterForm } from '../../anonymous/CenterForm';
import { LOGIN } from '../UserRoutesDef';
import { RgpdAskForm } from './RgpdAskForm';
import { IsDesktop } from '../conditionnal/IsDesktop';

const HeaderStyle = styled.div`
grid-area: header;
`;

export function RgpdAskPage() {
    return (
      <Fragment>

        <HeaderStyle>
          <PageHeader
            title={<Locale.Title tkey="rgpd" />}
            className="site-page-header"
            extra={(
              <IsDesktop>
                <TextDangerButton
                  tkey="logout"
                  onClick={() => sNavigate(`${LOGIN.LOGOUT}`)}
                />
              </IsDesktop>
                    )}
          />
        </HeaderStyle>
        <CenterForm>
          <RgpdAskForm />
        </CenterForm>
      </Fragment>
    );
}
