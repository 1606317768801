import { InfoCircleOutlined } from '@ant-design/icons';
import { IExaminerReview, walkEntityPropertyAndMapDateStrAsMoment, ExaminerReviewStatusEnum } from '@fstn/ecandidaturev2_api-interfaces';
import {
  Col, Row, Spin, Statistic,
} from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import React, { Fragment } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import { AffixedCardHeader } from '../../common/antd/AffixedCardHeader';
import * as Locale from '../../common/locale';
import { useEntity } from '../../hooks/use-entity';

export function ExaminerReviewPreview(props: { layout?: FormLayout, disabled?: boolean }) {
  const [state, updateState] = useImmer({ examinerReviews: undefined as IExaminerReview[] });
  const { loadEntities } = useEntity<IExaminerReview>();

  useDeepCompareEffect(() => {
    async function load() {
      const examinerReviews = await loadEntities('examiner-review');
      examinerReviews?.forEach?.((d) => walkEntityPropertyAndMapDateStrAsMoment(d));
      updateState((draft) => {
        draft.examinerReviews = examinerReviews;
      });
    }

    if (!state.examinerReviews) {
      load().then();
    }
  }, [state.examinerReviews, loadEntities, updateState]);

  if (!state.examinerReviews) {
    return <Spin tip="ExaminerReview" />;
  }
  return (
    <Fragment>
      <article id="examiner-review" className="message  is-small">
        <div className="message-header">
          <AffixedCardHeader>
            <InfoCircleOutlined style={{ marginRight: '1em' }} />
            <Locale.Title tkey="examinerReviews.list" />
          </AffixedCardHeader>
        </div>
        <div className="message-body">
          <Locale.Content tkey="examinerReviews.list" />
        </div>
        <div className="message-body">
          <ExaminerReviewStats examinerReviews={state.examinerReviews} />
        </div>
        <div className="message-body">
          {state?.examinerReviews?.map((e) => <ExaminerReviewRow examinerReview={e} />)}
        </div>
      </article>
    </Fragment>
  );
}

export function ExaminerReviewStats({ examinerReviews }: { examinerReviews: IExaminerReview[] }) {
  return (
    <Row gutter={20}>
      <Col span={3}>
        <Statistic title={<Locale.Title tkey="examinerReview.count" />} value={examinerReviews?.length} />
      </Col>
      <Col span={3}>
        <Statistic
          title={<Locale.Title tkey="examinerReview.unfavorable.count" />}
          value={examinerReviews.filter((r) => r.review === ExaminerReviewStatusEnum.UNFAVORABLE)?.length}
        />
      </Col>
      <Col span={3}>
        <Statistic
          title={<Locale.Title tkey="examinerReview.reserved.count" />}
          value={examinerReviews.filter((r) => r.review === ExaminerReviewStatusEnum.RESERVED)?.length}
        />
      </Col>
      <Col span={3}>
        <Statistic
          title={<Locale.Title tkey="examinerReview.neutral.count" />}
          value={examinerReviews.filter((r) => r.review === ExaminerReviewStatusEnum.NEUTRAL)?.length}
        />
      </Col>
      <Col span={3}>
        <Statistic
          title={<Locale.Title tkey="examinerReview.fairlyFavorable.count" />}
          value={examinerReviews.filter((r) => r.review === ExaminerReviewStatusEnum.FAIRLY_FAVORABLE)?.length}
        />
      </Col>
      <Col span={3}>
        <Statistic
          title={<Locale.Title tkey="examinerReview.favorable.count" />}
          value={examinerReviews.filter((r) => r.review === ExaminerReviewStatusEnum.FAVORABLE)?.length}
        />
      </Col>
      <Col span={3}>
        <Statistic
          title={<Locale.Title tkey="examinerReview.veryFavorable.count" />}
          value={examinerReviews.filter((r) => r.review === ExaminerReviewStatusEnum.VERY_FAVORABLE)?.length}
        />
      </Col>
      <Col span={3}>
        <Statistic
          title={<Locale.Title tkey="examinerReview.undefined.count" />}
          value={examinerReviews.filter((r) => r.review === ExaminerReviewStatusEnum.UNDONE)?.length + examinerReviews.filter((r) => !r.review)?.length}
        />
      </Col>
    </Row>
  );
}

export function ExaminerReviewRow({ examinerReview }: { examinerReview: IExaminerReview }) {
  return (
    <Fragment>
      <Row gutter={16}>
        <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <h1>
            {`${examinerReview.examiner.firstName} ${examinerReview.examiner.lastName}`}
          </h1>
          <h2>
            <Locale.Label tkey={examinerReview.review || 'UNDEFINED'} />
          </h2>
          <div>
            <textarea style={{ width: '100%' }} disabled rows={4} value={examinerReview.comments} />
          </div>
        </Col>
        <Col span={12}>
          <Statistic
            title={<Locale.Title tkey="examinerReview.notation" />}
            value={examinerReview.notation}
          />
        </Col>
      </Row>
      <br />
    </Fragment>
  );
}
