import styled from '@react-pdf/styled-components';
import React, { ReactNode } from 'react';

const Container = styled.Text`
font-size: 9px;
font-weight: 600;
`;

export function PDFBold(props: { debug?: boolean, children?: ReactNode }) {
  return (
    <Container debug={props.debug}>
      {props.children}
    </Container>
  );
}
