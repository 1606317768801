/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment} from 'react';
import {AdminEditableList} from '../../common/AdminEditableList';
import {
    examinerListInitialColumns,
    examinerListInitialFilters,
    examinerListMapToRow,
    examinerListRenderRowContextMenu,
} from './ExaminerListInitialConfig';

export function ExaminerListPage(props) {
  return (
    <Fragment>
      <AdminEditableList
        entity="admin/examiner"
        checkboxColumn
        renderRowContextMenu={examinerListRenderRowContextMenu}
        initialFilters={examinerListInitialFilters}
        initialColumns={examinerListInitialColumns()}
        mapToRow={examinerListMapToRow}
      />
    </Fragment>
  );
}
