/** @jsx jsx */

/*
Secretary style
*/
import styled from '@emotion/styled';

export const CandidateFileStyle = styled.div`
  height: 100%;
  width: 100%;
  
   .ant-card-body {
    padding: 0.2em;
   }
  
   .message {
    //padding: 0.2em;
   }
   
   .message-header{
    padding: 0.2em;
   }
   
   div.ant-form-item{
    margin: 0 !important;
   }
   
   .ant-row.ant-form-item{
     padding: 0.5em;
   }
`;
