/** @jsx jsx */
import { jsx } from '@emotion/core';
import { IRights } from '@fstn/ecandidaturev2_api-interfaces';
import 'antd-button-color/dist/css/style.css'; // or 'antd-button-color/dist/css/style.less'
import 'antd/dist/antd.css';
import 'bulma/css/bulma.min.css';
import { ThemeProvider, ThemeProvider as EmoThemeProvider } from 'emotion-theming';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
// @ts-ignore
import queryString from 'query-string';
import React, { Fragment, Suspense, useContext } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { App } from './App';
import { WithLoadMe } from './common/withExtraParameters/WithLoadMe';
import { AxiosContext, AxiosContextProvider } from './context/axios.context';
import { ColdDataContextProvider } from './context/coldData.context';
import { ConfigContextProvider } from './context/config.context';
import { IhmContextProvider } from './context/ihm.context';
import { RoutesContextProvider } from './context/routes.context';
import { TranslationContextProvider } from './context/translation.context';
import { UserContextProvider } from './context/user.context';
import './index.css';
import 'moment/locale/fr';
import { initAmplitude } from './utils/eventService';

moment.locale('fr');

initAmplitude();

export interface RootStoreType {
    rights?: IRights[];
    loading: boolean;
    validations: any;
    secretaryValidations: any;
    validationsRules: any;
    secretaryValidationsRules: any;
    documentHash: number;
}

Bugsnag.start({
    apiKey: '5c4810f0e75f06372a5d33d70a0de31b',
    plugins: [new BugsnagPluginReact()],
    appVersion: '1.2.3',
});

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React);

const parsed = queryString.parse(window.location.search);
const token = parsed.token as string;

const ec2Theme = {
    primary: '#00b0f0',
};

function AppWrapper() {
    console.log('Starting front App', process.env.REACT_APP_ENV);
    const { axiosWithCache } = useContext<any>(AxiosContext);
    return (
      <TranslationContextProvider axios={axiosWithCache}>
        <ColdDataContextProvider axios={axiosWithCache}>
          <ConfigContextProvider axios={axiosWithCache}>
            <UserContextProvider>
              <RoutesContextProvider>
                <IhmContextProvider>
                  <WithLoadMe>
                    <App />
                  </WithLoadMe>
                </IhmContextProvider>
              </RoutesContextProvider>
            </UserContextProvider>
          </ConfigContextProvider>
        </ColdDataContextProvider>
      </TranslationContextProvider>
);
}

ReactDOM.render(
  <ErrorBoundary>
    <Suspense fallback={<Fragment />}>
      <EmoThemeProvider theme={ec2Theme}>
        <ThemeProvider theme={ec2Theme}>
          <AxiosContextProvider token={token}>
            {/* LAURENT point entrée de l'application */}
            <AppWrapper />
          </AxiosContextProvider>
        </ThemeProvider>
      </EmoThemeProvider>
    </Suspense>
  </ErrorBoundary>,
    document.getElementById('root'),
);
