import {
    CandidateFileStatusEnum,
    ICandidateFile, IRights,
    UserRoleEnum,
    ValidationStep,
} from '@fstn/ecandidaturev2_api-interfaces';
import { ButtonProps } from 'antd/lib/button';
import { omit } from 'lodash';
import React, { Fragment, useContext } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';

import { RightsContext, RightsContextType } from '../../../context/rights.context';
import { UserContext, UserContextType } from '../../../context/user.context';
import { ValidationsContext } from '../../../context/validations.context';
import { useEntity } from '../../../hooks/use-entity';
import { useRights } from '../../../hooks/use-rights.hook';

import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import {
    CandidateFileContext,
    CandidateFileContextProviderType,
} from '../../../pages/canSeeCandidateFile/CandidateFileContext';
import { ROUTES } from '../../../pages/user/UserRoutesDef';
import { PaymentForm } from '../../../pages/candidateFile/paymentsList/PaymentForm';
import { sNavigate } from '../../../utils/safeNavigation';

import { TextOkButton } from '../../button/TextOkButton';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

function ConfirmBackToSecretaryModal(props: any) {
    const { patchEntity } = useEntity<any>();
    const { updateCandidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);

    async function backToInProgressSecretary() {
        const result: ICandidateFile = await patchEntity('candidate-file/status', {} as any, { status: CandidateFileStatusEnum.IN_PROGRESS_SECRETARY });
        updateCandidateFileContext((draft: any) => {
            draft.status = result.status;
        });
        setTimeout(() => window.location.href = `${ROUTES.CANDIDATE_BASE}/home`, 0);
    }

    return (
        <Fragment>
            <TextOkButton tkey="candidate.to.inProgressSecretary" onClick={backToInProgressSecretary} />
        </Fragment>
    );
}

function ConfirmSubmitModal(props: any) {
    const [state, updateSate] = useImmer({ visible: false, checked: false });

    return (
        <Fragment>
            <TextOkButton
                tkey="submit"
                {...omit(props, 'validationsMessages')}
                onClick={() => updateSate((draft: any) => {
                    draft.visible = true;
                })}
            />

            <PaymentForm
                status={CandidateFileStatusEnum.SUBMITTED}
                display={state.visible}
                cancelPayment={() => sNavigate(`${ROUTES.CANDIDATE_BASE}/submit`)}
            />
            {/*
        <Modal
            visible={state.visible}
            okButtonProps={{disabled: !state.checked}}
            title={<Locale.Title tkey='submit'/>}
            onOk={submit}
            cancelText={<Locale.Button tkey='submit.cancel'/>}
            onCancel={() => updateSate((draft: any) => {
                draft.visible = false
            })}
            okText={<Locale.Button tkey='submit.confirm'/>}
            footer={props?.footer}
        >
            <h1><Locale.Title tkey='submit'/></h1>
            <Locale.CheckBox tkey={'submit'}/>

            <Checkbox value={state.checked} onChange={() => updateSate((draft: any) => {
                draft.checked = !state.checked
            })}/></Modal> */}
        </Fragment>
    );
}

function ConfirmForceModal(props: ButtonProps & { validationsMessages?: string[], footer?: any }) {
    const [state, updateSate] = useImmer({ visible: false, checked: false });
    return (
        <Fragment>
            <TextOkButton
                tkey="force"
                {...omit(props, 'validationsMessages')}
                onClick={() => updateSate((draft: any) => {
                    draft.visible = true;
                })}
            />
            <PaymentForm status={CandidateFileStatusEnum.FORCED} display={state.visible} cancelPayment={() => sNavigate(`${ROUTES.CANDIDATE_BASE}`)} />
            {/* <Modal
            visible={state.visible}
            okButtonProps={{disabled: !state.checked}}
            title={<Locale.Title tkey='force'/>}
            onOk={force}
            cancelText={<Locale.Button tkey='force.cancel'/>}
            onCancel={() => updateSate((draft: any) => {
                draft.visible = false
            })}
            okText={<Locale.Button tkey='force.confirm'/>}
            footer={props?.footer}
        >
            <h1><Locale.Title tkey='force'/></h1>
            <Locale.Help tkey={'force'}/>
            {
                props?.validationsMessages.map((msg, i) => <Alert key={i} message={msg} banner/>)
            }
            <hr/>
            <Locale.CheckBox tkey={'force'}/>
            <Checkbox value={state.checked} onChange={() => updateSate((draft: any) => {
                draft.checked = !state.checked
            })}/></Modal> */}
        </Fragment>
    );
}

export function EntityFormSubmitButton() {
    const { userCtx } = useContext<UserContextType>(UserContext);
    const rights = useScopedSelector<RightsContextType, IRights>('EntityFormSubmitButton', RightsContext, (c) => c.rightsCtxState.rights);

    const { t } = useSafeTranslation();
    const { atLeastOneEntityIsWrittable } = useRights();
    const { validations } = useScopedSelector('EntityFormSubmitButton', ValidationsContext, (validationsCtx) => validationsCtx.validationsCtxState[ValidationStep.SUBMIT]);

    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    const [state, updateState] = useImmer<{ submittable: boolean }>({ submittable: false });

    //console.log("validation", validations)
    const validationsMessage = validations && Object.keys(validations)
        .filter((entity) => (validations[entity] && Object.keys(validations[entity]).length > 0))
        .map((entity) => t(`submit.validations.error.${entity}`));

    useDeepCompareEffect(() => {
        // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/naming-convention
        const _atLeastOneEntityIsWrittable = atLeastOneEntityIsWrittable(rights, userCtx?.user?.role?.name);
        if (_atLeastOneEntityIsWrittable !== state.submittable) {
            updateState((draft) => {
                draft.submittable = _atLeastOneEntityIsWrittable;
            });
        }
    }, [rights]);

    switch (userCtx.user?.role?.name) {
        case UserRoleEnum.CANDIDATE:
            switch (candidateFileContext.selectedCandidateFile?.status?.id) {
                case CandidateFileStatusEnum.NEW: {
                    if (validationsMessage?.length > 0) {
                        return (
                            <ConfirmForceModal
                        /* ok */ disabled={!state.submittable}
                                validationsMessages={validationsMessage}
                            />
                        );
                    }
                    return (
                        <ConfirmSubmitModal type="primary" disabled={!state.submittable} />
                    );
                }
                case CandidateFileStatusEnum.WAITING_CANDIDATE: {
                    return (
                        <ConfirmBackToSecretaryModal type="primary" disabled={!state.submittable} />
                    );
                }
                default:
                    return <Fragment />;
            }
        default:
            return <Fragment />;
    }
}
