/** @jsx jsx */
import { EyeOutlined, FileOutlined } from '@ant-design/icons';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { IProgram } from '@fstn/ecandidaturev2_api-interfaces';
import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { Popover } from 'antd';
import Button from 'antd/lib/button';
import useMobileDetect from 'use-mobile-detect-hook';
import * as Locale from '../../../common/locale';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { EntityIcon } from '../../../assets/icons/left-menu.icons';
import { IsDesktop } from '../../user/conditionnal/IsDesktop';
import { ProgramModalListHeader } from './ProgramModalListHeader';

const Style = styled.div`
height: 100%;
display: flex;
flex-direction: column;
.inovua-react-toolkit-menu__cell.inovua-react-toolkit-menu__cell--ltr.inovua-react-toolkit-menu__cell--icon{
  display: flex;
  align-items: center;
  padding: 1em;
}
`;

export function ProgramModalList(props: {
  gridRef: any,
  allPrograms: IProgram[],
  filteredPrograms: IProgram[],
  selectedProgramsInModal: IProgram[],
  onFilterSchool: (value: any, option: any) => void,
  onFilterThemes: (value: any, option: any) => void,
  onSelectionChange: (config: TypeOnSelectionChangeArg) => void;
}) {
  const sorter = { dir: +1, name: 'homePosition' } as any;

  const { t } = useSafeTranslation();
  const detectMobile = useMobileDetect();
  let initialColumns: TypeColumn[];
  if (detectMobile.isMobile()) {
    initialColumns = [
      {
        header: '',
        name: 'homePosition',
        visible: false,
        defaultWidth: 0,
        lockable: false,
        type: 'number',
      },
      {
        header: <Locale.Header tkey="programsList.name" />,
        name: 'name',
        flex: 1,
        render: ({ data }) => (
          <div>
            {data.name}
            {data.optionName && (
              <br />)}
            {data.optionName}

            <br />
            {data.school}
          </div>
        ),
      }];
  } else {
    initialColumns = [
      {
        header: <Locale.Header tkey="programsList.school" />,
        name: 'school',
        defaultWidth: 180,
        defaultLocked: 'start',
        style: { fontSize: '12px' },
      },
      {
        header: <Locale.Header tkey="programsList.name" />,
        name: 'name',
        minWidth: 300,
        defaultLocked: 'start',
        style: { fontSize: '12px' },
        render: ({ value }) => (
          <Popover
            content={value}
          >
            <div>{value}</div>
          </Popover>
        ),
      },
      {
        header: <Locale.Header tkey="programsList.optionName" />,
        name: 'optionName',
        defaultWidth: 300,
        lockable: false,
        style: { fontSize: '12px' },
        render: ({ value }) => (
          <Popover
            content={value}
          >
            <div>{value}</div>
          </Popover>
        ),
      },
      {
        header: '',
        name: 'homePosition',
        visible: false,
        defaultWidth: 0,
        lockable: false,
        type: 'number',
      },
      {
        header: <Locale.Header tkey="programsList.campus" />,
        name: 'campus',
        defaultWidth: 130,
        lockable: false,
        style: { fontSize: '12px' },
        render: ({ value }) => (
          <Popover
            content={value}
          >
            <div>{value}</div>
          </Popover>
        ),
      },
      {
        header: <Locale.Header tkey="programsList.capacity" />,
        name: 'capacity',
        defaultWidth: 100,
        lockable: false,
        style: { fontSize: '12px' },
        render: ({ value }) => (
          <Popover
            content={value}
          >
            <div>{value}</div>
          </Popover>
        ),
      },
      {
        header: <Locale.Header tkey="programsList.requiredLevel" />,
        name: 'requiredLevel',
        defaultWidth: 130,
        lockable: false,
        defaultVisible: false,
        style: { fontSize: '12px' },
      },
      {
        header: <Locale.Header tkey="programsList.status" />,
        name: 'status',
        defaultWidth: 150,
        lockable: false,
        defaultVisible: false,
        style: { fontSize: '12px' },
      },
      {
        header: '',
        name: 'acceptedSchoolTracks',
        defaultWidth: 150,
        style: { textAlign: 'center' },
        defaultLocked: 'end',
        cellSelectable: false,
        render: (c) => (
          <div
            css={css`display: flex; gap:6px;`}
          >
            <Popover
              content={(
                <ul
                  css={css`z-index: 9999; text-align:center; width: 100%;`}
                >
                  {c.value?.split(',')?.map?.((s) => (
                    <li
                      key={s}
                    >
                      {s}
                    </li>
                  ))}
                </ul>
              )}
              title={t('programsList.header.acceptedSchoolTracks')}
            >
              <Button css={css`svg{color:white !important};background-color:#ccc !important;padding: 5px !important;width:auto !important;height:auto !important`} icon={<div><EntityIcon entity="bachelor" /></div>} />
            </Popover>
            <Popover content={<Locale.Help tkey="website" />}>
              {/* eslint-disable-next-line */}
              <a href={c.data?.website} target="_blank" rel="noopener noreferrer">
                <Button css={css`svg{color:white !important};background-color:#1890ff !important;padding: 5px !important;width:auto !important;height:auto !important`} icon={<EyeOutlined style={{ fontSize: '20px' }} twoToneColor="white" />} />
              </a>
            </Popover>

            <Popover content={<Locale.Help tkey="noticeUrl" />}>
              <a
                href={c.data.noticeUrl}
                target="_blank"
                rel="noopener noreferrer"
                css={css`background-color: #EE8947`}
              >
                <Button css={css`svg{color:white !important};background-color:#eca52b !important;padding: 5px !important;width:auto !important;height:auto !important`} icon={<FileOutlined style={{ fontSize: '20px' }} twoToneColor="white" />} />
              </a>
            </Popover>
          </div>
        ),
      },
    ];
  }
  // console.log('Redraw ProgramModalList');
  const initialColumnsOrder = initialColumns.map((c) => c.name) || [];
  return (
    <Style>
      <div css={css`;

  .ant-form-item-explain {
    display: none
  }

  > div.ant-row.ant-form-item.ant-form-item-with-help {
    background-color: rgb(238, 238, 238) !important
  }
`}
      >
        <IsDesktop>
          <ProgramModalListHeader
            allPrograms={props.allPrograms}
            filteredPrograms={props.filteredPrograms}
            selectedPrograms={props.selectedProgramsInModal}
            onFilterSchool={props.onFilterSchool}
            onFilterThemes={props.onFilterThemes}
          />
        </IsDesktop>
      </div>
      <div>
        <ReactDataGrid
          nativeScroll
          virtualized={false}
          licenseKey="AppName=IMTMinesAlesApp,Company=IMTMinesAles,ExpiryDate=2022-09-21,LicenseDeveloperCount=1,LicenseType=single_app,Ref=IMTMinesAlesLicenseRef,Z=-1389124616-117191260076701888-20886508571221706338-2117749941"
          onReady={(r) => { props.gridRef.current = r.current; }}
          css={css`.InovuaReactDataGrid__row-cell-wrap:hover{cursor: pointer}`}
          idProperty="id"
          style={{ height: `${70 + props.filteredPrograms.length * 60}px` }}
          defaultSortInfo={sorter}
          showColumnMenuGroupOptions={false}
          showColumnMenuFilterOptions={false}
          columns={initialColumns}
          defaultColumnOrder={initialColumnsOrder as any}
          checkboxOnlyRowSelect
          checkboxColumn
          pagination={false}
          multiSelect
          renderRow={(rowProps: {
            id?: string | number;
            data?: IProgram;
            rowIndex: number;
            rowSelected: boolean;
            active: boolean;
            style: CSSStyleDeclaration;
            disabled: boolean;
            className: string;
          }) => {
            const disabled = props.selectedProgramsInModal?.some((p) => p.school === rowProps.data.school && rowProps.data.name === p.name && p.id !== rowProps.data.id);
            if (disabled) {
              rowProps.style.opacity = '0.3';
              rowProps.disabled = true;
              rowProps.className += ' remove-checkbox';
            }
            return undefined;
          }}
          groupColumn={false}
          theme="blue-light"
          dataSource={props.filteredPrograms}
          defaultSelected={[]}
          enableSelection
          showColumnMenuLockOptions={false}
          onSelectionChange={props.onSelectionChange}
        />
      </div>
    </Style>
  );
}
