import {
    AppConfigDto,
    IPayment,
    IPaymentsList,
    UserRoleEnum,
    walkEntityPropertyAndMapDateStrAsMoment,
} from '@fstn/ecandidaturev2_api-interfaces';
import React, {Fragment, useCallback, useContext, useEffect,} from 'react';
import {useImmer} from 'use-immer';
import {UserContext} from '../../../context/user.context';
import {useEntity} from '../../../hooks/use-entity';
import {ExtraPaymentForm} from '../../candidateFile/paymentsList/ExtraPaymentForm';

export function ExtraPaymentPageSwitcher({ children }: any) {
    const { userCtx } = useContext(UserContext);

    const { loadEntity } = useEntity<AppConfigDto>();

    const [state, updateState] = useImmer({
        extraPayments: undefined as IPayment[],
    });

    const load = useCallback(async () => {
        const paymentsList: IPaymentsList = await loadEntity('payments-list');
        walkEntityPropertyAndMapDateStrAsMoment(paymentsList);
        const extraPayments = paymentsList.payments.filter((p) => p.extra && !p.received);
        updateState((draft) => {
            draft.extraPayments = extraPayments;
        });
    }, [loadEntity, updateState]);

    const isCandidate = userCtx?.user?.role?.name === UserRoleEnum.CANDIDATE;
    useEffect(() => {
        if (isCandidate) {
            load().then();
        }
    }, [isCandidate, load]);

    if (state.extraPayments?.length > 0) {
        return <ExtraPaymentForm payment={state.extraPayments[0]} />;
    }

    return <Fragment>{children}</Fragment>;
}
