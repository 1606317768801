import {ExclamationCircleFilled} from '@ant-design/icons';
import {Button} from 'antd';
import axios from 'axios';
import React from 'react';

export function ButtonDeleteAll(props: { url: string, message: string, updateState: any }) {
    return (
      <Button
        danger
        icon={<ExclamationCircleFilled />}
        type="primary"
        onClick={async () => {
                    try {
                        const response: any = await axios?.post(props.url, {});
                        //console.log('Result:', response.data);
                        props.updateState((draft) => {
                            draft.result = `${response.data.deleted} ${props.message} ${response.data.total}`;
                            draft.error = undefined;
                        });
                    } catch (ex) {
                        //console.log('Exception:', ex.response);
                        props.updateState((draft) => {
                            draft.error = ex.response.data.message;
                            draft.result = undefined;
                        });
                    }
                }}
      >
        Tout effacer
      </Button>
    );
}
