/** @jsx jsx */
import {css, jsx} from '@emotion/core';

export function CenterForm(props: { children: any }) {
    return (
      <div css={css`display: flex; justify-content: center; /*max-height: 100%;*/min-height: 100%; overflow:auto; grid-area: content; align-items: center`}>
        {props?.children}
      </div>
);
}
