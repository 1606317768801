import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import React from 'react';

import { useResponsiveInputSize } from '../../hooks/use-responsive-input-size.hook';
import { DisabledContext } from '../rights/DisabledContext';
import { useScopedSelector } from '../../hooks/use-scoped-selector';

/**
 * Responsive date picker
 * @param props
 * @constructor
 */
export function ResponsiveDatePicker(props: DatePickerProps & { onAfterChange?: any }) {
  const { size } = useResponsiveInputSize();
  const disabled = useScopedSelector('ResponsiveDatePicker', DisabledContext, (c) => c?.disabled);
  const dateFormat = "DD/MM/YYYY";
  if (props.disabled !== undefined) {
    throw new Error('Use Disabled Context instead props');
  }
  return (
    <DatePicker
      format={dateFormat}
      onSelect={props.onAfterChange}
        /* ok */ disabled={disabled}
      {...props}
      size={size}
    />
  );
}
