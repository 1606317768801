import { AppConfigDto } from '@fstn/ecandidaturev2_api-interfaces';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import React, { Fragment } from 'react';
import * as Locale from '../../../common/locale';

export function MaintenanceModale(props: { appConfig: AppConfigDto }) {
  if (!moment().isBetween(props.appConfig.openMaintenance, props.appConfig.closeMaintenance)) {
    return <Fragment />;
  }

  return (
    <Modal
      visible
      title={<Locale.Title tkey='app.maintenance' />}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      zIndex={2000}
    >
      <Locale.Content tkey='app.maintenance' />
    </Modal>
  );
}
