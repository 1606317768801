/* eslint-disable react-hooks/exhaustive-deps */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Menu } from 'antd';
import _ from 'lodash';
import React, { HTMLAttributes } from 'react';
// @ts-ignore
import useMobileDetect from 'use-mobile-detect-hook';
import { LeftMenuItem } from '../../common/leftMenu/LeftMenuItem';
import { sNavigate } from '../../utils/safeNavigation';
import { CandidateCard } from '../canSeeCandidateFile/CandidateCard';
// noinspection,TypeScriptCheckImport

// @ts-ignore
// language=LESS prefix=*{ suffix=}
const Style: any = styled.div`

    width: 140px;
    // Cand Sec column
    .ant-menu-item {
      justify-content: flex-end;
    }
    .ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 10px;
    }

`;

export type LeftMenuProps = HTMLAttributes<HTMLElement>;

export const SecretaryLeftMenu: React.FunctionComponent<LeftMenuProps> = () => {
    const detectMobile = useMobileDetect();

    const navigateTo = (menuEvent: any) => {
        localStorage.removeItem('keepEdit');
        // eslint-disable-next-line no-useless-escape
        sNavigate(`${window.location.href.replace(/\/[^\/]*$/, '')}/${menuEvent.key}`);
    };

    return (
      <Style>
        <CandidateCard />
        <div className="ant-menu-vertical">
          <div css={css`display: flex; font-size:10px; gap:5px`} className="ant-menu-item">
            <span css={css`min-width:15px`} />
            <span>Cand.</span>
            <span>Sec.</span>
          </div>
        </div>
        <Menu
          onClick={navigateTo}
          /* @ts-ignore use to get tooltip */
          siderCollapsed
          style={{ width: 'auto' }}
          selectedKeys={[(`${_.last(window.location.href.split('/'))}`).replace('/', '')]}
          defaultOpenKeys={['candidate-file']}
          mode={detectMobile.isMobile() ? 'horizontal' : 'inline'}
        >
          <LeftMenuItem compact entityName="programs-list" />
          <LeftMenuItem compact entityName="profile" />
          <LeftMenuItem compact entityName="bachelor" />
          <LeftMenuItem compact entityName="academics-list" />
          <LeftMenuItem compact entityName="languages-list" />
          <LeftMenuItem compact entityName="stays-abroad-list" />
          <LeftMenuItem compact entityName="company-experiences-list" />
          <LeftMenuItem compact entityName="motivation" />
          <LeftMenuItem compact entityName="apprenticeship-company" />
          <LeftMenuItem compact entityName="additional-documents-list" />
          <LeftMenuItem compact entityName="payments-list" />
        </Menu>
      </Style>
    );
};
