/** @jsx jsx */
import {jsx} from '@emotion/core';
import {useCallback, useEffect} from 'react';
import {useImmer} from 'use-immer';
import {CommentsListComposerInput} from './CommentsListComposerInput';

export const CommentsListComposerInputContainer = ({
                                                       onSubmit, submitting, type, size, extraButton,
                                                   }: any) => {
    const [state, updateState] = useImmer({ value: '' });

    const onInternalChange = useCallback(async (e: any) => {
        e?.stopPropagation?.();
        e?.preventDefault?.();
        localStorage.setItem(type, e.target.value);
        const v = e.target.value;
        updateState((draft) => {
            draft.value = v;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateState]);

    const exists = state.value !== undefined;
    useEffect(() => {
        if (!exists) {
            onInternalChange({ target: { value: localStorage.getItem(type) } }).then();
        }
    }, [onInternalChange, type, exists]);
    // loop add

    return (
      <CommentsListComposerInput
        onInternalChange={onInternalChange}
        onSubmit={onSubmit}
        submitting={submitting}
        type={type}
        updateState={updateState}
        value={state.value}
      />
    );
};
