// @ts-ignore
import useMobileDetect from 'use-mobile-detect-hook';
import { useEffect, useState } from 'react';

export function useIsSmallScreen() {
    const detectMobile = useMobileDetect();
    const [smallScreen, setSmallScreen] = useState(false);

    function resize() {
        setSmallScreen(window.innerWidth <= 760 || window.innerHeight <= 400 || detectMobile.isMobile());
    }

    useEffect(() => {
        setSmallScreen(window.innerWidth <= 760 || window.innerHeight <= 400 || detectMobile.isMobile());
        window.addEventListener('resize', resize);
// eslint-disable-next-line
}, []);

    return smallScreen;
}
