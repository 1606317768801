// @ts-ignore
import { useRoutes } from 'hookrouter';
import React, {
 Fragment, ReactNode, useContext, useEffect, useState,
} from 'react';
import { RoutesContext } from '../../context/routes.context';

/**
 *  LAURENT
 *  Add routes def to children props
 */
function IWithAddRoutesProps(props:{ children: (routes)=> ReactNode }) {
    const { routesCtx } = useContext(RoutesContext);
    const routes = useRoutes(routesCtx?.routes || {});
    const [state, updateState] = useState({ loaded: false, routes });

    //console.log('Redraw IWithAddRoutesProps', routes);
    useEffect(() => {
        updateState({
 ...state,
            routes,
            loaded: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routesCtx?.routesName]);

    // console.log('Redraw WithAddRoutesProps', props, routesCtx?.routesName);

    return (
      <Fragment>
        {props.children(state.routes)}
      </Fragment>
);
}

export const WithAddRoutesProps = IWithAddRoutesProps;
