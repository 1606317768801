/** @jsx jsx */
import { jsx } from '@emotion/core';
import { IAcademic, IIdiom, ILanguage, UserRoleEnum, } from '@fstn/ecandidaturev2_api-interfaces';
import { Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext } from 'react';
import { useImmer } from 'use-immer';
import UUID from 'uuid-js';
import { UserContext, UserContextType } from '../../../context/user.context';
import * as Locale from '../../../common/locale';
import { ColdDataAutoCompleteFormItem } from '../../../common/form/inputFormItems/ColdDataAutoCompleteFormItem';

export function LanguageIdiomModal(props: {
  showIdiom: boolean,
  onCancel: () => Promise<void>,
  onAddIdiom: (academic: Partial<IAcademic>) => Promise<void>
}) {
  const [languageIdiomForm] = useForm();
  const { userCtx } = useContext<UserContextType>(UserContext);

  const [state, updateState] = useImmer({ loading: false });
  async function onAdd(academic: Partial<IAcademic>) {
    updateState((draft) => { draft.loading = true; });
    await props.onAddIdiom(academic);
    updateState((draft) => { draft.loading = false; });
    languageIdiomForm.resetFields();
  }

  return (
    <Modal
      title={<Locale.Title tkey="language.idiom" />}
      visible={props.showIdiom}
      onOk={() => {
        languageIdiomForm.submit();
      }}
      // @ts-ignore
      okButtonProps={{ 'data-testid': 'language.idiom-ok', loading: state.loading }}
      cancelButtonProps={{ disabled: state.loading }}
      onCancel={props.onCancel}
    >
      <Locale.Help tkey="language.idiom" />
      <Form
        form={languageIdiomForm as any}
        onFinish={async (v) => {
          const language: Partial<ILanguage> = {
            id: UUID.create(1).toString(),
            idiom: v.idiom,
          };

          if (userCtx.user?.role?.name === UserRoleEnum.SECRETARY) {
            // if we are secretary, we switched to edit mode so we need to set the right rights
            language.rights = {
              manage: [UserRoleEnum.SECRETARY],
              read: Object.values(UserRoleEnum),
              write: [UserRoleEnum.SECRETARY],
              delete: [UserRoleEnum.SECRETARY],
            };
          }
          await onAdd(language);
        }}
      >
        <ColdDataAutoCompleteFormItem<IIdiom>
          api="idioms"
          filter={((idiom) => !idiom.mandatory)}
          propertyName="idiom"
          rules={[
            {
              required: true,
              message: 'Champ obligatoire !'
            }
          ]}
        />
      </Form>
    </Modal>
  );
}
