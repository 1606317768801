import React from 'react';
import { DisabledContextProvider } from './DisabledContext';

/**
 * Disabled Context provider to use when data is always disabled
 * @constructor
 */
export function DisabledContextProviderAlwaysEnabled(props:{ children?:any }) {
    return (

      <DisabledContextProvider value={{
            disabled: false,
            wrapAuthorized: true,
            context: 'DisabledContextProviderAlwaysEnabled',
        }}
      >
        {props?.children}
      </DisabledContextProvider>
    );
}
