/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {Card} from 'antd';
import {Box, Flex} from 'rebass';
import {ColdDataSelectorFormItem} from '../../../common/form/inputFormItems/ColdDataSelectorFormItem';
import {DatePickerFormItem} from '../../../common/form/inputFormItems/DatePickerFormItem';
import {InputFormItem} from '../../../common/form/inputFormItems/InputFormItem';
import {InputTextAreaFormItem} from '../../../common/form/inputFormItems/InputTextAreaFormItem';
import * as Locale from '../../../common/locale';
import {EntityFormListContext} from '../../../context/entityFormList.context';
import {useResponsiveForm} from '../../../hooks/use-responsive-form.hook';
import {useScopedSelector} from '../../../hooks/use-scoped-selector';

export function CompanyExperienceForm() {
    // noinspection JSUnusedLocalSymbols
const field = useScopedSelector<any[], any>('CompanyExperienceForm', EntityFormListContext, (c) => c[0].field);
const formListEntityId = useScopedSelector<any[], string>('CompanyExperienceForm', EntityFormListContext, (c) => c[0]?.entityValue?.id);

    const responsive = useResponsiveForm();

    return (
      <Card
        id={formListEntityId}
        key={formListEntityId}
        title={<Locale.Title tkey="companyExperiences.title" />}
        css={css``}
      >
        <Flex name="companyExperiences" flexWrap="wrap">
          <Box {...responsive.w1}>
            <InputFormItem propertyName={['companyExperiences', field.name, 'name']} />
            <InputFormItem propertyName={['companyExperiences', field.name, 'city']} />
            <ColdDataSelectorFormItem
              api="companies-as"
              propertyName={['companyExperiences', field.name, 'as']}
            />
            <InputFormItem propertyName={['companyExperiences', field.name, 'service']} />
            <InputTextAreaFormItem propertyName={['companyExperiences', field.name, 'production']} />
            <DatePickerFormItem propertyName={['companyExperiences', field.name, 'beginDate']} />
            <DatePickerFormItem propertyName={['companyExperiences', field.name, 'endDate']} />
          </Box>
        </Flex>
      </Card>
);
}
