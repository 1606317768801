import _ from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {AxiosContext, AxiosContextType} from '../../../context/axios.context';

// eslint-disable-next-line react/prop-types
import {useEntity} from '../../../hooks/use-entity';
import {CandidateFileContext, CandidateFileContextProviderType} from '../../canSeeCandidateFile/CandidateFileContext';
import {CommentsListItem} from './CommentsListItem';

/**
 * Comments List Item use in candidate and secretary chat
 * @param props
 * @constructor
 */
export function CommentsListItemContainer(props: { type: 'commentsBtwSecretaryAndCandidate', className?: string } = { type: 'commentsBtwSecretaryAndCandidate' }) {
    const { putEntity } = useEntity<any>();
    const [value, setValue] = useState([]);
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
    const notifyNewComment = async (e) => {
        const c = e;
        await putEntity(`comments/${props.type}`,
            { [`${props.type}`]: c, id: undefined }, { [`${props.type}`]: c });
    };

    async function load() {
        const params: any = {};
        if (candidateFileContext) {
            params.candidateFileId = candidateFileContext.selectedCandidateFile?.id;
            params.random = Math.random() * 1000;
        }
        const { data } = await axios.get(`comments/${props.type}`, { params });
        const sortedValue = _.sortBy(data, 'datetime')?.reverse();
        setValue(sortedValue);
    }

    useEffect(() => {
        load().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <CommentsListItem
        className={props.className}
        onChange={async (e) => {
                await notifyNewComment(e);
                setTimeout(async () => load(), 100);
            }}
        type={props.type}
        value={value}
      />
    );
}
