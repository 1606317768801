/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { TextButton } from './TextButton';

/**
 * Button with positive action, for example register button
 * @param tkey
 * @param props
 * @constructor
 */
export function PositiveActionButton({ tkey, ...props }:any) {
    return <TextButton {...props} tkey={tkey} type="primary" style={{ ...props.style || {}, backgroundColor: props.backgroundColor || '#01B0EF' }} />;
}
