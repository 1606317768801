/** @jsx jsx */
import StringFilter from '@inovua/reactdatagrid-community/StringFilter';
import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import { MutableRefObject } from 'react';
import { jsx } from '@emotion/core';
import { IAcademicReferentReview } from '@fstn/ecandidaturev2_api-interfaces';
import { ReferentReviewContextMenu } from './ReferentReviewContextMenu';
import { getSelectedElements } from '../../common/AdminEditableList';

export const referentReviewListInitialColumns = () => [
    {
        header: 'ID Dossier',
        name: 'shortId',
        width: 100,
        defaultLocked: 'start',
        editable: false,
        style: { textAlign: 'left' },

    }, {
        header: 'Prénom candidat',
        name: 'candidateFirstName',
        width: 200,
        defaultLocked: 'start',
        editable: false,
        style: { textAlign: 'left' },

    }, {
        header: 'Nom candidat',
        name: 'candidateLastName',
        width: 200,
        defaultLocked: 'start',
        editable: false,
        style: { textAlign: 'left' },

    }, {
        header: 'E-mail candidat',
        name: 'candidateEmail',
        className: 'email',
        'test-id': 'email',
        width: 250,

        editable: false,
        style: { textAlign: 'left' },
        filterEditor: StringFilter,
        filterEditorProps: {
            className: 'emailFilter',
            'test-id': 'emailFilter',
        },

    }, {
        header: 'Etat demande',
        name: 'revStatus',
        width: 150,

        editable: false,
        style: { textAlign: 'left' },

    }, {
        header: 'Etat dossier',
        name: 'cfStatus',
        width: 150,

        editable: false,
        style: { textAlign: 'left' },

    }, {
        header: 'Prénom référent',
        name: 'referentFirstName',
        width: 200,

        editable: false,
        style: { textAlign: 'left' },

    }, {
        header: 'Nom référent',
        name: 'referentLastName',
        width: 200,

        editable: false,
        style: { textAlign: 'left' },

    }, {
        header: 'E-mail référent',
        name: 'referentEmail',
        className: 'email',
        'test-id': 'email',
        width: 250,

        editable: false,
        style: { textAlign: 'left' },
        filterEditor: StringFilter,
        filterEditorProps: {
            className: 'emailFilter',
            'test-id': 'emailFilter',
        },

    },
];

export const referentReviewListInitialFilters = [
    {
        name: 'candidateFirstName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'candidateLastName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'candidateEmail', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'referentFirstName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'referentLastName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'referentEmail', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'shortId', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'cfStatus', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'revStatus', operator: 'contains', type: 'string', value: '',
    },
];

export function referentReviewListRenderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedUsers, gridRef) {
    return (
        <ReferentReviewContextMenu
            menuProps={menuProps}
            details={details}
            selectedUsers={getSelectedElements(details.rowProps.data, selectedUsers)}
            gridRef={gridRef}
        />
    );
}

/**
 * Map result to row
 * @param d
 */
export function referentReviewListMapToRow(d: IAcademicReferentReview) {
    const row: any = {
        id: d.id,
        shortId: d?.academicsList?.candidateFile?.shortId?.toString(),
        candidateFirstName: d?.academicsList?.candidateFile?.candidate?.firstName,
        candidateLastName: d?.academicsList?.candidateFile?.candidate?.lastName,
        candidateEmail: d?.academicsList?.candidateFile?.candidate?.email,
        cfStatus: d?.academicsList?.candidateFile?.status?.id,
        revStatus: d.status?.id,
        referentFirstName: d.referent?.firstName,
        referentLastName: d.referent?.lastName,
        referentEmail: d.referent?.email,
    };
    return row;
}
