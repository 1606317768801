import { Context } from 'use-context-selector/src/index';
import { useContextSelector } from 'use-context-selector';
import { useRef } from 'react';
import { isEqual } from 'lodash';

export function useScopedSelector<Value, Selected>(
    name: string,
    context: Context<Value>,
    selector: (value: Value) => Selected,
) {
    const oldValue = useRef(undefined as any);
    const value = useContextSelector(context, selector);

    if (oldValue.current !== undefined && !isEqual(value, oldValue.current)) {
        //console.log(`Scope selector value changed for: ${name}, before: `, oldValue.current, value);
    }
    oldValue.current = value;
    return value;
}
