/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Anchor, Menu } from 'antd';
import { usePath } from 'hookrouter';
import _ from 'lodash';
import React from 'react';
// @ts-ignore
import useMobileDetect from 'use-mobile-detect-hook';
import { LeftMenuItem } from '../../common/leftMenu/LeftMenuItem';
import * as Locale from '../../common/locale';
import { sNavigate } from '../../utils/safeNavigation';
import { CandidateCard } from '../canSeeCandidateFile/CandidateCard';
import { IsDesktop } from '../user/conditionnal/IsDesktop';
// noinspection,TypeScriptCheckImport

const { Link } = Anchor;
// @ts-ignore
const Style: any = styled.div`
    .ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 10px;
    }

    .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
      height: auto;
    }

    .ant-menu-item.ant-menu-item-only-child {
      padding-left: 0.2em !important;
    }
`;

export const navigateTo = (menuEvent: any) => {
  // eslint-disable-next-line no-useless-escape
  sNavigate(`${window.location.href.replace(/\/[^\/]*$/, '')}/${menuEvent.key}`);
};

export function CandidateLeftMenu() {
  const path = usePath();
  const detectMobile = useMobileDetect();

  // console.log('Redraw CandidateLeftMenu');

  return (
    <Style>
      <CandidateCard />
      <Menu
        onClick={navigateTo}
        style={{ width: 'auto' }}
        selectedKeys={[(`${_.last(path.split('/'))}`).replace('/', '')]}
        defaultOpenKeys={['candidate-file']}
        mode={detectMobile.isMobile() ? 'horizontal' : 'inline'}
      >
        <LeftMenuItem compact={false} entityName="programs-list" />
        <LeftMenuItem compact={false} entityName="profile" />
        <LeftMenuItem compact={false} entityName="bachelor" />
        <LeftMenuItem compact={false} entityName="academics-list" />
        <IsDesktop>
          {path.indexOf('academics-list') !== -1
            && (
              <div onClick={(e) => e.stopPropagation()} style={{ margin: '1em' }}>
                <Anchor onClick={(e) => e.stopPropagation()}>
                  <Link href="#academics" title={<Locale.Label tkey="academics-list.academics.menu" />} />
                  <Link href="#documents" title={<Locale.Label tkey="academics-list.documents.menu" />} />
                  <Link href="#reviews" title={<Locale.Label tkey="academics-list.reviews.menu" />} />
                </Anchor>
              </div>
            )}
        </IsDesktop>
        <LeftMenuItem compact={false} entityName="languages-list" />
        <LeftMenuItem compact={false} entityName="stays-abroad-list" />
        <LeftMenuItem compact={false} entityName="company-experiences-list" />
        <LeftMenuItem compact={false} entityName="motivation" />
        <LeftMenuItem compact={false} entityName="apprenticeship-company" />
        <LeftMenuItem compact={false} entityName="additional-documents-list" />
        <LeftMenuItem compact={false} entityName="payments-list" />
        <LeftMenuItem compact={false} entityName="submit" noValidate />
      </Menu>
    </Style>
  );
}
