/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext } from 'react';
import { Importer, ImporterField } from 'react-csv-importer-v2';

/**
 * Page pour lancer l'import CSV des statistiques annee precedente
 */
import 'react-csv-importer-v2/dist/index.css';
import { useImmer } from 'use-immer';
import { AxiosContext, AxiosContextType } from '../../../../context/axios.context';
import { sNavigateReload } from '../../../../utils/safeNavigation';
import { ADMIN } from '../../../user/UserRoutesDef';
import { ResultPanel } from '../../common/ResultPanel';
import { ButtonDeleteAll } from '../../common/ButtonDeleteAll';
import { PreviousStatisticListPage } from './previousStatisticListPage';

export function PreviousStatisticImporter() {
  const [state, updateState] = useImmer({ values: [], error: undefined as string, result: undefined as string });
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  return (
    <Fragment>
      <Importer
        delimiter=";"
        // restartable // optional, lets user choose to upload another file when import is complete
        onStart={() => {
        }}
        processChunk={async (rows) => {
          updateState((draft) => {
            draft.values = [...draft.values, ...rows];
          });
        }}
        onComplete={async () => {
          try {
            const response: any = await axios?.post('/import/previous-statistic', {
              bulk: state.values,
            });
            updateState((draft) => {
              draft.result = `${response.data} stats importées`;
              draft.error = undefined;
            });
          } catch (ex) {
            updateState((draft) => {
              draft.error = ex.response.data.message;
              draft.result = undefined;
            });
          }
        }}
        onClose={async () => {
          sNavigateReload(ADMIN.PREVIOUS_STATISTIC_IMPORTER);
        }}
      >
        <ImporterField name="event_date" label="event_date" />
        <ImporterField name="total_created" label="total_created" />
        <ImporterField name="total_submitted" label="total_submitted" />
      </Importer>
      <br />
      <ButtonDeleteAll message="statistiquess supprimés sur" url="/api/admin/previous-statistic/deleteAll" updateState={updateState} />
      <ResultPanel error={state.error} result={state.result} redirect={ADMIN.PREVIOUS_STATISTIC_IMPORTER} />
      <br />
      <span><h1>Liste des statistiques année précédente</h1></span>
      <PreviousStatisticListPage />
    </Fragment>
  );
}
