import { FormLayout } from 'antd/lib/form/Form';
import React, { Fragment } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import { createContext } from 'use-context-selector';
import { Listeners } from '../common/form/entityForm/EntityFormContainer';
import { useResponsiveForm } from '../hooks/use-responsive-form.hook';

export const EntityFormContext = createContext([undefined, undefined]);

export interface EntityFormContextType<T> {
    doNextChangeImmediately: false;
    parentType: string;
    entity: string;
    isFieldTouched?: (string) => boolean,
    getFieldsValue?: () => any,
    setFieldsValue?: (values: any) => void,
    getFieldValue?: (string) => any,
    listeners?: Listeners<any>,
    entityValue: T;
    layout?: FormLayout,
    cancelCurrentChanges?: Function,
    submit: ()=>any,
    onChange: (changedValues, allvalues) => Promise<void>;
    onImmediateChange: (changedValues?: any, allvalues?: any) => Promise<void>;
}

function Content(props: { children: any }) {
    return <Fragment>{props.children}</Fragment>;
}

/**
 * LAURENT
 * Context that contain all the form important data
 * @param entity entity nom du endpoint à appeler par exemple ici locales => /api/locales => apps/back/src/locale/locale.controller.ts
 * @param entityValue valeur par defaut de l'entité
 * @param children contenu du form, principalement les forms items
 * @param isFieldTouched methode form.isFieldTouched
 * @param getFieldsValue methode form.getFieldsValue
 * @param setFieldsValue methode form.setFieldsValue
 * @param getFieldValue methode form.getFieldValue
 * @param submit methode form.getFieldValue
 * @param listeners EntityFormContainer.listeners
 * @param onChange le onChangeDebounced du EntityFormItemContainer
 * @param onImmediateChange le onChange du EntityFormItemContainer
 * @param cancelCurrentChanges Appeler avant de passer en mode edit pour le secretariat
 */
export const EntityFormContextProvider = (props: {
    entity: string,
    entityValue?: any,
    children: any,
    isFieldTouched?: (string) => boolean,
    getFieldsValue?: () => any,
    setFieldsValue?: (values: any) => void,
    getFieldValue?: (string) => any,
    submit?: ()=>any,
    listeners?: Listeners<any>,
    onChange: (changedValue, allvalue) => void,
    onImmediateChange: (changedValue) => void,
    cancelCurrentChanges?: Function,
}) => {
    // @ts-ignore
    const responsive = useResponsiveForm(props);
    const [state, updateState] = useImmer({
        parentType: props.entity,
        entity: props.entity,
        layout: responsive.layout,
        entityValue: { ...props.entityValue },
        onChange: props.onChange,
        isFieldTouched: props.isFieldTouched,
        getFieldsValue: props.getFieldsValue,
        getFieldValue: props.getFieldValue,
        setFieldsValue: props.setFieldsValue,
        submit: props.submit,
        listeners: props.listeners,
        cancelCurrentChanges: props.cancelCurrentChanges,
        onImmediateChange: props.onImmediateChange,
    });

    useDeepCompareEffect(() => {
        updateState((draft) => {
            draft.entityValue = props.entityValue;
        });
    }, [props.entityValue, updateState]);

    useDeepCompareEffect(() => {
        updateState((draft) => {
            draft.getFieldValue = props.getFieldValue;
        });
    }, [props.getFieldValue, updateState]);

    const values = props.getFieldsValue();
    useDeepCompareEffect(() => {
        updateState((draft) => {
            draft.entityValue = { ...props.entityValue, ...values };
        });
    }, [values, updateState]);

    return (
      <EntityFormContext.Provider
        value={[state as EntityFormContextType<any>, updateState]}
      >
        <Content>{props?.children}</Content>
      </EntityFormContext.Provider>
    );
};
