// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styled from '@react-pdf/styled-components';
import React, { Fragment, ReactNode, useContext } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import { View } from '@react-pdf/renderer';
import { PDFEntityFormContext, PDFEntityFormContextType } from './context/pdfEntityForm.context';
import { PDFEntityFormListContextProvider } from './context/pdfEntityFormList.context';
import { PDFCardGutter } from '../components/PDFCardGutter';
import { PDFText } from '../components/PDFText';

const Container = styled.View`
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function PDFEntityFormList<T extends any>(props: { debug?: boolean, propertyName: any, children: (value, index) => ReactNode | ReactNode[] }) {
    const [formContext] = useContext<[PDFEntityFormContextType<any>, any]>(PDFEntityFormContext);
    const [state, updateState] = useImmer({
        initialValues: Object.values(formContext.entityValue?.[props.propertyName] || {}),
    });

    useDeepCompareEffect(() => {
        updateState((draft) => {
            draft.initialValues = Object.values(formContext.entityValue?.[props.propertyName] as any || {});
        });
    }, [formContext.entityValue?.[props.propertyName]]);

    return (
      <Container debug={props.debug}>
        {state.initialValues && state?.initialValues?.length === 0
        && (
        <Fragment>
          <PDFCardGutter>
            <View>
              <Container>
                <PDFText />
              </Container>
            </View>
          </PDFCardGutter>
        </Fragment>
)}
        {state.initialValues && state?.initialValues?.map?.((v, i) => (
            // eslint-disable-next-line react/no-array-index-key
          <PDFEntityFormListContextProvider key={i} entityValue={state.initialValues}>
            {props.children(v, i)}
          </PDFEntityFormListContextProvider>
))}
      </Container>
    );
}
