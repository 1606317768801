/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

import { Skeleton } from 'antd';
import { FormInstance } from 'antd/lib/form';
import Form, { FormLayout } from 'antd/lib/form/Form';
import React from 'react';
import { EntityFormContextProvider } from '../../../context/entityForm.context';
import { EntityFormListContextProvider } from '../../../context/entityFormList.context';
import { ResponsiveCols } from '../../../hooks/use-responsive-form.hook';
import { InputFormItem } from '../inputFormItems/InputFormItem';
import { Listeners } from './EntityFormContainer';
import { DisabledContextProviderAlwaysDisabled } from '../../rights/DisabledContextProviderAlwaysDisabled';

const Style = styled.div`
grid-area: entity-form;
.ant-form-item-explain{display:none}
//max-width: calc( 100% - 10px );
.ant-btn{
  overflow: hidden;
}
`;

/**
 * @see EntityFormContainer
 * @param props
 * @constructor
 */
export function EntityForm<T>(props: {
    className: string,
    initialValues: any,
    responsive: ResponsiveCols,
    layout?: FormLayout,
    onValuesChange: (changedValues) => Promise<void>, onFinish: (values: T) => void,
    form: FormInstance<T>, entity: string, cancelCurrentChanges: () => Promise<any>,
    listeners: Listeners<T>,
    onImmediateChange: (changedValues) => Promise<any>,
    remove: () => void,
    children: any,
    warningOnly?: boolean,
    noValidate?: boolean
}) {
    // console.log('Redraw EntityForm', props);
    return (
      <Style className={props.className}>
        <Skeleton loading={!props.initialValues || !props.initialValues.rights}>
          <Form
            data-testid={`form.${props.className}`}
            {...props.responsive.colProps}
            style={{ width: '100%' }}
            layout={props.layout || props.responsive.layout}
            size="small"
            labelAlign="right"
            onValuesChange={props.onValuesChange}
            onFinish={props.onFinish}
            requiredMark
            scrollToFirstError
            form={props.form}
            initialValues={props.initialValues}
            /* @ts-ignore */
            warningOnly={props.warningOnly as any}
            noValidate={props.noValidate}
          >
            <EntityFormContextProvider
              entity={props.entity}
              entityValue={props.initialValues}
              isFieldTouched={props.form.isFieldTouched}
              getFieldValue={props.form.getFieldValue}
              getFieldsValue={props.form.getFieldsValue}
              setFieldsValue={props.form.setFieldsValue}
              cancelCurrentChanges={props.cancelCurrentChanges}
              submit={props.form.submit}
              listeners={props.listeners}
              onChange={props.onValuesChange}
              onImmediateChange={props.onImmediateChange}
            >
              <EntityFormListContextProvider
                entityValue={props.initialValues}
                entity={props.entity}
                propertyName=""
                remove={props.remove}
              >
                <DisabledContextProviderAlwaysDisabled>
                  <InputFormItem propertyName="rights" hidden label="" help="" />
                  <InputFormItem propertyName="id" hidden label="" help="" />
                </DisabledContextProviderAlwaysDisabled>
                {props.children}
              </EntityFormListContextProvider>
            </EntityFormContextProvider>
          </Form>
        </Skeleton>
      </Style>
    );
}
