/** @jsx jsx */
import { css, jsx } from '@emotion/core';

export function HideWhenSmallScreen({ children }: any) {
    return (
      <span css={css`
    @media (max-width: 600px) {
      display: none
    },
`}
      >
        {children}
      </span>
    );
}
