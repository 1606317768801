import { BirthdateUtils, IExemptionStatus, INationality, IProfile, UserRoleEnum, ExemptionStatusEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { notification } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import { parse, stringify } from 'flatted';
import React, { Fragment, HTMLAttributes, useContext } from 'react';
import { Box, Flex } from 'rebass';
import { useDeepCompareCallback } from 'use-deep-compare';
import UUID from 'uuid-js';
import { EntityFormContainer } from '../../../common/form/entityForm/EntityFormContainer';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { FormStyle } from '../../../common/form/FormStyle';
import { ColdDataAutoCompleteFormItem } from '../../../common/form/inputFormItems/ColdDataAutoCompleteFormItem';
import { DatePickerFormItem } from '../../../common/form/inputFormItems/DatePickerFormItem';
import { FixSelectFormItem } from '../../../common/form/inputFormItems/FixSelectFormItem';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import { UploadFormItemContainer } from '../../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import { SecretaryReviewFormFooterContainer } from '../../../common/form/secretaryReviewForm/SecretaryReviewFormFooterContainer';
import { WithSwitchToReadOnlyOnDisplayForSecretary } from '../../../common/withExtraParameters/WithSwitchToReadOnlyOnDisplayForSecretary';
import { WithInitialLoad } from '../../../common/withExtraParameters/WithInitialLoad';
import { YesNoFormItem } from '../../../common/form/inputFormItems/YesNoFormItems';
import { EntityFormContext } from '../../../context/entityForm.context';
import { UserContext, UserContextType } from '../../../context/user.context';
import { useConfig } from '../../../hooks/use-config.hook';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { IsSecretary } from '../../user/conditionnal/IsSecretary';
import { DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { DisabledContextProviderForEntityForm } from '../../../common/rights/DisabledContextProviderForEntityForm';
import { useEntity } from '../../../hooks/use-entity';
import { RightAreaContext, RightAreaContextProviderType } from '../../canSeeCandidateFile/RightAreaContext';
import { FixRadioFormItem } from '../../../common/form/inputFormItems/FixRadioFormItem';
import * as Locale from '../../../common/locale';
import { ColdDataContext, ColdDataContextType } from '../../../context/coldData.context';
import { orderBy } from 'lodash';


export type ProfileProps = HTMLAttributes<HTMLElement>;

const entity = 'profile';


function ProfileEntityForm() {
  const entityId = useScopedSelector<any[], string>('ProfileEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.id);
  const thirdPartyTime = useScopedSelector<any[], boolean>('ProfileEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.thirdPartyTime);
  const birthDate = useScopedSelector<any[], Date>('ProfileEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.birthDate);
  const livingOutsideFrance = useScopedSelector<any[], boolean>('ProfileEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.livingOutsideFrance);
  const nationality = useScopedSelector<any[], INationality>('ProfileEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.nationality);
  const exemptionReason = useScopedSelector<any[], IExemptionStatus>('ProfileEntityForm', EntityFormContext, (c) => c[0]?.entityValue?.exemptionReason);

  const responsive = useResponsiveForm();
  const { t } = useSafeTranslation();
  const [{ date }] = useConfig();
  const [{ maxLimit, config }] = useConfig();
  const { userCtx } = useContext<UserContextType>(UserContext);

  const { coldDataCtx } = useContext<ColdDataContextType>(ColdDataContext);

  const addAvatar = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'avatarDocument',
    type: 'avatarDocument',
    parentType: entity,
    parentId: entityId,
  }), [entityId, entity]);

  const addThirdDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'thirdPartyTimeDocument',
    type: 'thirdPartyTimeDocument',
    parentType: entity,
    parentId: entityId,
  }), [entityId, entity]);
  const addExemptionDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'exemptionDocument',
    type: 'exemptionDocument',
    parentType: entity,
    parentId: entityId,
  }), [entityId, entity]);
  const addIdCardRectoDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'idCardRectoDocument',
    type: 'idCardRectoDocument',
    parentType: entity,
    parentId: entityId,
  }), [entityId, entity]);
  const addIdCardVersoDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'idCardVersoDocument',
    type: 'idCardVersoDocument',
    parentType: entity,
    parentId: entityId,
  }), [entityId, entity]);
  const addResidencePermitRectoDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'residencePermitRectoDocument',
    type: 'residencePermitRectoDocument',
    parentType: entity,
    parentId: entityId,
  }), [entityId, entity]);
  const addResidencePermitVersoDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'residencePermitVersoDocument',
    type: 'residencePermitVersoDocument',
    parentType: entity,
    parentId: entityId,
  }), [entityId, entity]);
  const addCvDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'cvDocument',
    type: 'cvDocument',
    parentType: entity,
    parentId: entityId,
  }), [entityId, entity]);

  const onBeforeUpload = async (imageContext: { width: number, height: number }) => {
    const sizeValid = imageContext.width <= await maxLimit('AVATAR_WIDTH')
      && imageContext.height <= await maxLimit('AVATAR_HEIGHT');

    if (!sizeValid) {
      notification.warning({
        duration: 0,
        message: `${t('avatar.max.title') || 'avatar.max.title'}`,
        description: `${t('avatar.max.description') || 'avatar.max.description'}`,
      });
    }
    return sizeValid;
  };

  if (!config) {
    return <Fragment />;
  }
  return (
    <Flex sx={{ flexWrap: 'wrap' }}>
      <Box {...responsive.w1}>
        <FormContentPanelContainer partName={entity}>
          <FixSelectFormItem
            propertyName="gender"
            options={[
              { value: 'm', label: t('gender.male') },
              { value: 'f', label: t('gender.female') },
              { value: 'o', label: t('gender.other') }]}
          />
          <DisabledContextProvider value={{
            disabled: userCtx.user.role.name === UserRoleEnum.CANDIDATE ? true : undefined,
            wrapAuthorized: true,
            context: 'ProfileForm_name',
          }}
          >
            <InputFormItem propertyName="lastName" />
            <InputFormItem propertyName="firstName" />
          </DisabledContextProvider>
          <InputFormItem propertyName="phone" />
          <DisabledContextProvider value={{ disabled: true, wrapAuthorized: true, context: 'ProfileForm_email' }}>
            <InputFormItem propertyName="email" />
          </DisabledContextProvider>
          <InputFormItem propertyName="address" />
          <InputFormItem propertyName="zipCode" />
          <InputFormItem propertyName="city" />
          <ColdDataAutoCompleteFormItem propertyName="country" api="countries" />
          <DatePickerFormItem propertyName="birthDate" />
          <InputFormItem propertyName="birthPlace" />
          <ColdDataAutoCompleteFormItem propertyName="birthCountry" api="countries" />
          <ColdDataAutoCompleteFormItem propertyName="nationality" api="nationalities" />
          { // Dans le cas ou le candidat est de nationalite necessitant un permis, on lui permet de specifier qu'il ne réside pas en france actuellement
            nationality?.isPermitMandatory && (
              <YesNoFormItem labelCol={{ span: 18 }} wrapperCol={{ span: 4 }} propertyName="livingOutsideFrance" />
            )
          }
        </FormContentPanelContainer>
        { BirthdateUtils.isAgeExceed(birthDate ||new Date(), date('START_SCHOOL') || new Date(), 30) && (
        <FormContentPanelContainer partName="exemption">
          <div className="message-block">
          <FixRadioFormItem
                    wrapperCol={{ span: 16 }}
                    labelCol={{ span: 8 }}
                    propertyName="exemptionReason"
                    vertical={true}
                    options={
                      orderBy(coldDataCtx.coldData['exemption-status'], ["ranking"]).map((status) => ({
                        value: status.id,
                        label: <Locale.Label tkey={status.id} />,
                      }
                      ))
                    }
                  />
            {exemptionReason && exemptionReason.toString() !== ExemptionStatusEnum.NO_REASON && exemptionReason?.id !== ExemptionStatusEnum.NO_REASON
              && (
                <UploadFormItemContainer
                  propertyName="exemptionDocument"
                  factory={addExemptionDocument}
                  accept={'image/*,.pdf'}
                />
              )}
          </div>
        </FormContentPanelContainer>
        )}
        <FormContentPanelContainer partName="thirdPartyTime">
          <div className="message-block">
            <YesNoFormItem labelCol={{ span: 18 }} wrapperCol={{ span: 4 }} propertyName="thirdPartyTime" />
            {thirdPartyTime
              && (
                <UploadFormItemContainer
                  propertyName="thirdPartyTimeDocument"
                  factory={addThirdDocument}
                  accept={'image/*,.pdf'}
                />
              )}
          </div>
        </FormContentPanelContainer>
        <FormContentPanelContainer partName="upload">
          <UploadFormItemContainer
              propertyName="avatarDocument"
              onBeforeUpload={onBeforeUpload}
              factory={addAvatar}
              accept={'image/*'}
          />
          <UploadFormItemContainer
            propertyName="idCardRectoDocument"
            factory={addIdCardRectoDocument}
            accept={'image/*,.pdf'}
          />
          <UploadFormItemContainer
            propertyName="idCardVersoDocument"
            factory={addIdCardVersoDocument}
            accept={'image/*,.pdf'}
          />
          {/* TODO  les nationalité ici ne contienne que le nom du coup il faut faire le mapping avec le code data pour avoir l'objet entier... */}
          {/* On demande le permis de séjour si la nationalité l'exige (isPermitMandatory à true) et si le candidat réside en France (livingOutsideFrance à false) */}
          {nationality?.isPermitMandatory && !!!livingOutsideFrance
            && (
              <Fragment>
                <UploadFormItemContainer
                  propertyName="residencePermitRectoDocument"
                  factory={addResidencePermitRectoDocument}
                  accept={'image/*,.pdf'}
                />
                <UploadFormItemContainer
                  propertyName="residencePermitVersoDocument"
                  factory={addResidencePermitVersoDocument}
                  accept={'image/*,.pdf'}
                />
              </Fragment>
            )}
          <UploadFormItemContainer
            propertyName="cvDocument"
            factory={addCvDocument}
            accept={'image/*,.pdf'}
          />
        </FormContentPanelContainer>
      </Box>

    </Flex>
  );
}

export function ProfileForm(props: { layout?: FormLayout }) {
  const { deleteEntity } = useEntity();
  const [{ date }] = useConfig();
  const rightCtx = useContext<RightAreaContextProviderType>(RightAreaContext);

  //console.log(('Redraw Profile Form'));

  const onBeforeValidate = async (values: IProfile | any, differences: Partial<IProfile>, setFieldsValue: (values: any) => void) => {
    differences = parse(stringify(differences));
    /* We must remove relative documents when box is unchecked */
    if (differences.thirdPartyTime === false && !!values.thirdPartyTimeDocument) {
      await deleteEntity('document', values.thirdPartyTimeDocument);
      differences.thirdPartyTimeDocument = null;
      setFieldsValue({ values, thirdPartyTimeDocument: null });
      rightCtx?.forceUpdate?.();
    }
    /* On supprime les pieces "permis de séjour" si les infos de profil ne les necessitent plus : non resident en france ou nationalité ne nécessitant pas de permis */
    if ((differences.nationality?.isPermitMandatory === false || differences.livingOutsideFrance === true) && !!values.residencePermitRectoDocument) {
      await deleteEntity('document', values.residencePermitRectoDocument);
      // @ts-ignore will be remove on next dependencies update
      differences.residencePermitRectoDocument = null;
      setFieldsValue({ values, residencePermitRectoDocument: null });
      rightCtx?.forceUpdate?.();
    }
    if ((differences.nationality?.isPermitMandatory === false || differences.livingOutsideFrance === true) && !!values.residencePermitVersoDocument) {
      await deleteEntity('document', values.residencePermitVersoDocument);
      // @ts-ignore will be remove on next dependencies update
      differences.residencePermitVersoDocument = null;
      setFieldsValue({ values, residencePermitVersoDocument: null });
      rightCtx?.forceUpdate?.();
    }
    // Si la date de naissance ne rend plus obligatoire les conditions derogatoires, on efface champ et document correspondant
    if (differences.birthDate && !BirthdateUtils.isAgeExceed(differences.birthDate || new Date(), date('START_SCHOOL') || new Date(),30)) {
      differences.exemptionReason = null;
      setFieldsValue({ values, exemptionReason: null });
      // On efface le document s'il existait
      if (!!values.exemptionDocument) {
        await deleteEntity('document', values.exemptionDocument);
        differences.exemptionDocument = null;
        setFieldsValue({ values, exemptionDocument: null });
        rightCtx?.forceUpdate?.();
      }
    }
    // Cas improbable : le candidat a mis une condition derogatoire et ajoute le document de justification puis se retracte et dit qu'il n'a pas de condition
    // Il faut supprimer le document derogatoire ...
    if (differences.exemptionReason?.toString() === ExemptionStatusEnum.NO_REASON && !!values.exemptionDocument) {
      await deleteEntity('document', values.exemptionDocument);
      differences.exemptionDocument = null;
      setFieldsValue({ values, exemptionDocument: null });
      rightCtx?.forceUpdate?.();
    }
    return differences;
  };

  return (
    <FormStyle>
      <WithInitialLoad entity={entity}>
        <WithSwitchToReadOnlyOnDisplayForSecretary>
          <EntityFormContainer listeners={{ onBeforeValidate }} layout={props.layout}>
            <DisabledContextProviderForEntityForm>
              <ProfileEntityForm />
              <IsSecretary>
                <SecretaryReviewFormFooterContainer propertyName="secretaryReview" />
              </IsSecretary>
            </DisabledContextProviderForEntityForm>
          </EntityFormContainer>
        </WithSwitchToReadOnlyOnDisplayForSecretary>
      </WithInitialLoad>
    </FormStyle>
  );
}
