import { CheckCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";
import * as Locale from '../common/locale';

const { confirm } = Modal;

/**
 * Cette petite fonction permet d'afficher un popup signalant la fin de l'export (opération pouvant être très longue)
 */
export function endExportPopup(title?: string) {
  const popupTitle = `candidateFile.${title}.finished`
  confirm({
    title: <Locale.Notification tkey={popupTitle} />,
    icon: <CheckCircleOutlined />,
    cancelButtonProps: { disabled: true, style: { display: 'none' } }
  })
}