/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Select } from 'antd';
import { orderBy } from 'lodash';
import * as Locale from '../locale';

export function getLabel(s, api: string, t: (key: string) => string) {
    return s?.name || Locale.labelTrad(`${api}.${s?.id}`, t) || t(`${api}.${s?.id}`) || s?.id;
}

export function getOptions(data: any, api: string, testId: string, t: (key: string) => string) {
    // Le tri de la combo se fait selon l'attribut "ranking" (si présent), sinon sur l'attribut "name" (si present) sinon sur l'id
    return orderBy(data?.slice(), ['ranking', 'name', 'id'])
        .map((s) => ({
            value: s,
            label: getLabel(s, api, t),
            className: `select_${testId} ${api}_${s.id}`,
        })).map?.((o) => <Select.Option key={o.value.id} value={o.value.id}>{o.label}</Select.Option>);
}

export function onOptionChange(data: any, onChange: Function) {
    return (e) => {
        if (Array.isArray(e)) {
            onChange(data.filter((d) => e.includes(d.id)), undefined);
        } else if (e) {
            onChange(data.find((d) => d.id === e), undefined);
        }
        else {
            // On appelle la mise à jour avec null (cas ou "erase" dans une combo)
            onChange(null, undefined);
        }
    };
}
