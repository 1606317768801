/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css, jsx } from '@emotion/core';
// @ts-ignore
import { Fragment, useContext } from 'react';
import styled from '@emotion/styled/macro';
import { TextDangerButton } from '../../common/button/TextDangerButton';
import { HideWhenSmallScreen } from '../../common/withExtraParameters/HideWhenSmallScreen';
import * as Locale from '../../common/locale';
import { MenuAndFormContainer } from '../../common/page/Containers';
import { UserContext, UserContextType } from '../../context/user.context';
import { sNavigate } from '../../utils/safeNavigation';

import {
  CandidateFileContext,
  CandidateFileContextProviderType,
} from '../canSeeCandidateFile/CandidateFileContext';
import { CenterAreaContainer } from '../canSeeDocuments/viewer/CenterAreaContainer';
import { FlexibleCenterRightAreaContainer } from '../canSeeDocuments/viewer/FlexibleCenterRightAreaContainer';
import { LeftAreaContainer } from '../canSeeDocuments/viewer/LeftAreaContainer';
import { RightAreaContainer } from '../canSeeDocuments/viewer/RightAreaContainer';
import { RightMenuContainer } from '../canSeeDocuments/viewer/RightMenuContainer';
import { IsCandidateFileSelected } from '../user/conditionnal/IsCandidateFileSelected';
import { UserPage } from '../user/UserPage';
import { UserPageHeader } from '../user/UserPageHeader';
import { ROUTES } from '../user/UserRoutesDef';
import { SecretaryLeftMenu } from './SecretaryLeftMenu';
import { UploadContentStyle } from '../../common/form/inputFormItems/uploadFormItem/UploadContent';
import { IsPath } from '../user/conditionnal/IsPath';
import { DocumentHeaderButton } from '../canSeeDocuments/DocumentHeaderButton';

const SecretaryPageStyle = styled.div`
            display: grid;
            height: 100%;
            grid-template-areas:  "header header header"
                                  "left content right-menu";
            grid-template-rows: 50px minmax(0, calc(100% - 100px));
            grid-template-columns: 140px minmax(0, 1fr) 52px;
            
            //compact mode
            ${UploadContentStyle},
            .ant-collapse-content-box,
             .message{
              padding: 5px;
              .column{
                padding: 0
              }
            }
            .ant-form-item-label,.ant-form-item-control{
              //ignore les col-8 to display full width label
              max-width: calc(100% - 0px)
            }
            
        `;

function SecretaryPageHeader() {
  const userCtx = useContext<UserContextType>(UserContext);
  const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);

  return (
    <UserPageHeader
      title={(<HideWhenSmallScreen><Locale.Title tkey="secretary" /></HideWhenSmallScreen>)}
      subTitle={<HideWhenSmallScreen>{userCtx.userCtx?.user?.name}</HideWhenSmallScreen>}
      extra={(
        <Fragment>

          <DocumentHeaderButton />
          <IsPath startsWith={`${ROUTES.SECRETARY_BASE}/pdf`}>
            <TextDangerButton
              tkey="secretary.exit"
              onClick={() => sNavigate(`${ROUTES.SECRETARY_BASE}`)}
            />
          </IsPath>
          <IsPath startsWith={`${ROUTES.SECRETARY_BASE}/statistics`}>
            <TextDangerButton
              tkey="secretary.exit"
              onClick={() => sNavigate(`${ROUTES.SECRETARY_BASE}`)}
            />
          </IsPath>

          <IsPath startsWith={`${ROUTES.SECRETARY_BASE}/${candidateFileContext?.selectedCandidateFile?.id}`}>
            <IsPath different={`${ROUTES.SECRETARY_BASE}/${candidateFileContext?.selectedCandidateFile?.id}/exit`}>
              <TextDangerButton
                tkey="secretary.exit"
                onClick={() => sNavigate(`${ROUTES.SECRETARY_BASE}/${candidateFileContext?.selectedCandidateFile?.id}/exit`)}
              />
            </IsPath>
          </IsPath>
        </Fragment>
      )}
    />
  );
}

export function SecretaryPage(props: { children: any }) {
  return (
    <UserPage>
      <SecretaryPageStyle>
        <SecretaryPageHeader />
        <MenuAndFormContainer>
          <IsCandidateFileSelected>
            <LeftAreaContainer>
              <SecretaryLeftMenu />
            </LeftAreaContainer>
          </IsCandidateFileSelected>
          <FlexibleCenterRightAreaContainer>
            <CenterAreaContainer>
              {props?.children}
            </CenterAreaContainer>
            <RightAreaContainer />
          </FlexibleCenterRightAreaContainer>
          <Fragment>
            <RightMenuContainer />
          </Fragment>
        </MenuAndFormContainer>
      </SecretaryPageStyle>
    </UserPage>
  );
}

export function FullScreenSecretaryPage(props: { children: any }) {
  return (
    <UserPage>
      <SecretaryPageHeader />
      <div css={css`height:calc( 100% - 100px)`}>
        {props?.children}
      </div>
    </UserPage>
  );
}
