/** @jsx jsx */
import {jsx} from '@emotion/core';
import {ValidationStep} from '@fstn/ecandidaturev2_api-interfaces';
// @ts-ignore
import React from 'react';
import {ValidationsContextProvider} from '../../context/validations.context';
import {ReferentPage} from './ReferentPage';
import {ReferentReviewForm} from './ReferentReviewEntityForm';

const entity = 'a-r-review';
export function ReferentReviewPage({ academicReferentReviewId }: any) {
    return (
      <ReferentPage>
        <ValidationsContextProvider
          entity={entity}
          steps={[ValidationStep.REFERENT]}
        >
          <ReferentReviewForm academicReferentReviewId={academicReferentReviewId} />
        </ValidationsContextProvider>
      </ReferentPage>
);
}
