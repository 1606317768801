import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import React, { Fragment, MutableRefObject } from 'react';

export const configsListInitialColumns = (t) => [
    {
        header: 'Paramètre',
        name: 'id',
        defaultFlex: 1,

        editable: false,
        style: { textAlign: 'right' },

    },
    {
        header: 'Description',
        name: 'description',

        defaultFlex: 1,
        type: 'number',
    },
    {
        header: 'Valeur',
        name: 'value',

        defaultFlex: 1,
        lockable: false,
        filterable: true,
    },
];

export const configsListInitialFilters = [
    {
        name: 'id', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'description', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'value', operator: 'contains', type: 'string', value: '',
    },
];

export function configsListRenderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedConfig) {
    return (
      <Fragment />
    );
}

/**
 * Map result to row
 * @param d
 */
export function configsListMapToRow(d: any) {
    const row: any = {
        id: d.id,
        value: d.value,
        description: d.description,
    };
    return row;
}
