import { DeleteOutlined } from '@ant-design/icons/lib';
import styled from '@emotion/styled';
import Popconfirm from 'antd/lib/popconfirm';
import React, { HTMLAttributes } from 'react';
import { ResponsiveButton } from '../../input/ResponsiveButton';
import * as Locale from '../../locale';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { EntityFormListContext } from '../../../context/entityFormList.context';

// language=LESS prefix=*{ suffix=}
const Style = styled.div`
`;

export interface ItemListRemoveButtonProps extends HTMLAttributes<HTMLElement> {
    property: string
    remove: Function
    disabled: boolean
}

export const ItemListRemoveButton: React.FunctionComponent<ItemListRemoveButtonProps> = ({ remove, disabled }: ItemListRemoveButtonProps) => {
    const entityValue = useScopedSelector<any[], any>('ItemListRemoveButton', EntityFormListContext, (c) => c[0]?.entityValue);
    return (
      <Style>
        <Popconfirm
          disabled={disabled}
          okType="danger"
          title={<Locale.Title tkey="delete.confirm" />}
          onConfirm={() => {
                             remove(entityValue);
                         }}
          okText={<Locale.Button tkey="delete.ok" />}
          cancelText={<Locale.Button tkey="delete.cancel" />}
        >
          <ResponsiveButton
            disabled={disabled}
            data-testid="itemListRemoveButton"
            danger
            type="primary"
          >
            <DeleteOutlined />
          </ResponsiveButton>
        </Popconfirm>
      </Style>
    );
};
