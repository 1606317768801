/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Divider, Empty, Modal } from 'antd';
import Checkbox from 'antd/lib/checkbox';
import FileDownload from 'js-file-download';
import React, { Fragment, useContext } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import { parse, stringify } from 'flatted';
import { orderBy } from 'lodash';
import { TextOkButton } from '../../../common/button/TextOkButton';
import * as Locale from '../../../common/locale';
import { labelTrad } from '../../../common/locale';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { useLoading } from '../../../hooks/use-loading';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { endExportPopup } from '../../../utils/endExportPopup';
// @ts-ignore

const Style = styled.div`
  .ant-checkbox-group-item {
    width: 100%;
  }

  .ant-checkbox-wrapper {
    zoom: 1;
  }
`;

/**
 * Allow to select what type of document do you want to export
 * @param props
 * @constructor
 */
export function DocumentDownloaderTypeSelector(props: { segmentId: String, details }) {
  const [state, updateSate] = useImmer({
    availableDocumentTypeOptions: [],
    selectedDocumentTypeOptions: [],
    checkAll: false,
    indeterminate: false,
  });
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  const { t } = useSafeTranslation();
  const { doAction, InlineLoadingContainer } = useLoading(true);

  useDeepCompareEffect(() => {
    doAction(async () => {
      const response = await axios.put('serve/document/type-option', {}, {
        params: { segmentId: props.segmentId },
      });
      response.data = parse(stringify(response.data));
      response.data.forEach((d) => {
        d.label = labelTrad(d.label, t);
        d.checked = true;
      });
      const availableDocumentTypeOptions = orderBy(response.data, 'label');
      onDocumentTypeSelection(availableDocumentTypeOptions.map((o) => o.value));
      updateSate(((draft) => {
        draft.availableDocumentTypeOptions = availableDocumentTypeOptions.sort();
      }));
    });
  }, [props.segmentId]);

  const onDocumentTypeSelection = (values) => {
    updateSate(((draft) => {
      draft.selectedDocumentTypeOptions = values;
      draft.indeterminate = (!!values.length && values.length < draft.availableDocumentTypeOptions.length);
      draft.checkAll = (values.length === draft.availableDocumentTypeOptions.length);
    }));
  };

  const onCheckAllChange = (e) => {
    updateSate((draft) => {
      draft.selectedDocumentTypeOptions = (e.target.checked ? state.availableDocumentTypeOptions.map((o) => o.value) : []);
      draft.indeterminate = (false);
      draft.checkAll = (e.target.checked);
    });
  };

  const onDownload = () => doAction(async () => {
    const response = await axios.put('serve/document/all', { documentTypeIds: state.selectedDocumentTypeOptions }, {
      responseType: 'blob',
      params: { segmentId: props.segmentId },
    });
    await FileDownload(response.data, `${props.details.rowProps.data.id}.zip`);
    Modal.destroyAll();  // On close la modale qui a permis de lancer l'export
    endExportPopup('downloadFiles');
  });

  return (
    <InlineLoadingContainer>
      <Style>
        {state.availableDocumentTypeOptions.length === 0 && (
          <Fragment>
            <Locale.Notification tkey="candidateFile.downloadFiles.noDocumentAvailable" />
            <Empty />
          </Fragment>
        )}
        {state.availableDocumentTypeOptions.length > 0 && (
          <Fragment>
            <br />
            <div css={css`display: flex;
                          justify-content: space-between;
                          align-items: center`}
            >
              <Checkbox
                indeterminate={state.indeterminate}
                onChange={onCheckAllChange}
                checked={state.checkAll}
              >
                <Locale.Label tkey="checkAll" />
              </Checkbox>

              <TextOkButton
                tkey="download"
                onClick={onDownload}
                disabled={state.selectedDocumentTypeOptions.length === 0}
              />
            </div>
            <Divider />
            <Checkbox.Group
              defaultValue={state.selectedDocumentTypeOptions}
              value={state.selectedDocumentTypeOptions}
              options={state.availableDocumentTypeOptions}
              onChange={onDocumentTypeSelection}
            />
          </Fragment>
        )}
      </Style>
    </InlineLoadingContainer>
  );
}
