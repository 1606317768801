/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { ExaminerReviewStatusEnum, IExaminerReview, ValidationStep } from '@fstn/ecandidaturev2_api-interfaces';
//import { Popconfirm } from 'antd';
// @ts-ignore
import { Fragment, useContext } from 'react';
import { Box, Flex } from 'rebass';
import { useImmer } from 'use-immer';

import { useDeepCompareEffect } from 'use-deep-compare';
//import { TextOkButton } from '../../common/button/TextOkButton';
import { EntityFormContainer } from '../../common/form/entityForm/EntityFormContainer';
import { FormContentPanelContainer } from '../../common/form/formContentPanel/FormContentPanelContainer';
import { FormStyle } from '../../common/form/FormStyle';
import { FixRadioFormItem } from '../../common/form/inputFormItems/FixRadioFormItem';
import { InputNumberFormItem } from '../../common/form/inputFormItems/InputNumberFormItem';
import { InputTextAreaFormItem } from '../../common/form/inputFormItems/InputTextAreaFormItem';
import * as Locale from '../../common/locale';
import { AxiosContext, AxiosContextType } from '../../context/axios.context';
import { EntityFormContext } from '../../context/entityForm.context';
import { EntityFormListContext } from '../../context/entityFormList.context';
import { ValidationsContext, ValidationsContextProvider } from '../../context/validations.context';
import { useResponsiveForm } from '../../hooks/use-responsive-form.hook';
import { useValidation } from '../../hooks/use-validation.hook';
import { sNavigate } from '../../utils/safeNavigation';
import { ROUTES } from '../user/UserRoutesDef';
import { FullScreenExaminerPage } from './ExaminerPage';
//import { ButtonsFormItemLayout } from '../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import { useScopedSelector } from '../../hooks/use-scoped-selector';
import { DisabledContextProviderForEntityForm } from '../../common/rights/DisabledContextProviderForEntityForm';
import { StrictLoading } from '../../common/indicator/SmartLoading';
import { CandidateCard } from '../canSeeCandidateFile/CandidateCard';
import { getSearchHackToForceReFetch } from '../canSeeCandidateFile/candidateFilesList/CandidateFilesList';
import { WithInitialLoad } from '../../common/withExtraParameters/WithInitialLoad';

const entity = 'examiner-review';

export function ExaminerPageReviewPage({ examinerReview }: any) {
  return (
    <FullScreenExaminerPage>
      <ValidationsContextProvider
        entity={entity}
        steps={[ValidationStep.SUBMIT]}
      >
        {' '}
        <ExaminerPageReviewForm examinerReview={examinerReview} />
      </ValidationsContextProvider>
    </FullScreenExaminerPage>
  );
}

function ExaminerPageReviewEntityForm() {
  const formListEntityId = useScopedSelector<any[], IExaminerReview>('ExaminerPageReviewEntityForm', EntityFormListContext, (c) => c[0]?.entityValue?.id);

  const { validate } = useValidation();
  const responsive = useResponsiveForm();
  const [state, updateState] = useImmer({ alreadyValidated: false });
  const withUpdateValidations = useScopedSelector('ExaminerPageReviewEntityForm', ValidationsContext, (c) => c.withUpdateValidations);
  const entityValue = useScopedSelector<any[], IExaminerReview>('ExaminerPageReviewEntityForm', EntityFormContext, (c) => c[0]?.entityValue);
  const steps = useScopedSelector('ExaminerPageReviewEntityForm', ValidationsContext, (c) => c.steps);
  //const submit = useScopedSelector<any[], any>('ExaminerPageReviewEntityForm', EntityFormContext, (c) => c[0].submit);
  useDeepCompareEffect(() => {
    async function load() {
      await validate(entity,
        entityValue,
        withUpdateValidations,
        steps);
    }

    if (!state.alreadyValidated) {
      updateState((draft) => {
        draft.alreadyValidated = true;
      });
      load().then(() => {
      });
    }
  }, [withUpdateValidations, steps, entityValue, state.alreadyValidated, updateState, validate]);
  // loop add

  return (
    <StrictLoading loading={!formListEntityId} context="ExaminerReviewForm">
      <Flex sx={{ flexWrap: 'wrap' }}>
        <Box {...responsive.w1}>
          <FormContentPanelContainer partName={entity} extra={<CandidateCard />}>
            <Fragment>
              <div css={css`padding: 1em;`}>
                <InputNumberFormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  propertyName="notation"
                />
                <InputTextAreaFormItem
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rows={10}
                  propertyName="comments"
                />
              </div>
              <div css={css`
padding: 1em;
padding-right: 0em;
display: flex;
flex-wrap: wrap;
gap: 1em;
>.ant-form-item{
  width: calc( 33% - .5em);
  min-width: calc( 33% - .5em);
  max-width: calc( 33% - .5em);
  flex: 1;
}
>.label{
  align-items: center;
    display: flex;
}
`}
              >

                <div css={css`padding: 1em; width: 100%;`}>
                  <FixRadioFormItem
                    wrapperCol={{ span: 24 }}
                    labelCol={{ span: 24 }}
                    propertyName="review"
                    options={
                      Object.values(ExaminerReviewStatusEnum).map((status) => ({
                        value: status,
                        label: <Locale.Label tkey={status} />,
                      }
                      ))
                    }
                  />
                </div>
              </div>
              {/*entityValue.review
                && (
                  <ButtonsFormItemLayout>
                    <Popconfirm
                      title={<Locale.Title tkey="examiner-review.complete.confirm" />}
                      onConfirm={(e) => {
                        submit(e);
                      }}
                      okText={<Locale.Button tkey="ok" />}
                      cancelText={<Locale.Button tkey="cancel" />}
                    >
                      <TextOkButton tkey="examiner-review.complete" />
                    </Popconfirm>
                  </ButtonsFormItemLayout>
                )*/
              }
            </Fragment>
          </FormContentPanelContainer>
        </Box>
      </Flex>
    </StrictLoading>
  );
}

export const ExaminerPageReviewForm = ({ examinerReview }: any) => {
  const { axios } = useContext<AxiosContextType>(AxiosContext);

  async function complete(values) {
    await axios.put(`examiner-review/${values.id}/complete`, {});
    sNavigate(`${ROUTES.EXAMINER_BASE}`, true, getSearchHackToForceReFetch());
  }

  return (
    <FormStyle>
      <WithInitialLoad entity={entity} entityId={examinerReview.id}>
        <EntityFormContainer onFinish={complete}>
          <DisabledContextProviderForEntityForm>
            <ExaminerPageReviewEntityForm />
          </DisabledContextProviderForEntityForm>
        </EntityFormContainer>
      </WithInitialLoad>
    </FormStyle>
  );
};
