/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { ValidationStep } from '@fstn/ecandidaturev2_api-interfaces';
import { Modal } from 'antd';
import React, { Fragment, ReactElement } from 'react';

import { flatMap } from 'lodash';
import * as Locale from '../locale';
import { ValidationsContext } from '../../context/validations.context';
import { ROUTES } from '../../pages/user/UserRoutesDef';
import { sNavigate } from '../../utils/safeNavigation';
import { useScopedSelector } from '../../hooks/use-scoped-selector';
import { SubmitCandidateFileForm } from '../../pages/candidate/SubmitCandidateFileForm';
import { IsDesktop } from '../../pages/user/conditionnal/IsDesktop';

/**
 * Will prompt a modal if candidatefile is not ok for payment
 * @param props
 * @constructor
 */
export function WithCandidateFileOkForPaymentOk(props: { children: ReactElement }) {
    const { validations: paymentValidations } = useScopedSelector('WithCandidateFileOkForPaymentOk', ValidationsContext, (validationsCtx) => validationsCtx.validationsCtxState[ValidationStep.PAYMENT]);

    const paymentValidationsErrors = flatMap(Object.values(paymentValidations).filter((k:any) => typeof k !== 'string').filter((k:any) => Object.values(k).length > 0), ((k:any) => Object.values(k)));
    // console.log('paymentValidations', paymentValidationsErrors);

    if (!Object.keys(paymentValidationsErrors).length) {
        return (
          <Fragment>
            {props.children}
          </Fragment>
        );
    }
    return (
      <Modal
        width="800vw"
        okText={<Locale.Button tkey="submit.continue" />}
        cancelText={<Locale.Button tkey="submit.completeCandidateFile" />}
        cancelButtonProps={{ disabled: false, 'data-testid': 'payment-validation-cancel' } as any}
        okButtonProps={{
 disabled: true, visible: false, style: { display: 'none' }, 'data-testid': 'payment-validation-ok',
} as any}
        title={<Locale.Notification tkey="invalidCandidateFile" />}
        visible
        onCancel={async () => {
                await sNavigate(`${ROUTES.CANDIDATE_BASE}/payments-list`);
            }}
      >
        <div css={css`display: flex; gap: 1em;`}>
          <div css={css`display: flex;flex:1`}>
            <Locale.Notification tkey="invalidPaymentValidation.description" />
          </div>
          <IsDesktop>
            <div css={css`height:700px;flex:1;flex-basis: 500px`}>
              <SubmitCandidateFileForm />
            </div>
          </IsDesktop>
        </div>

      </Modal>
    );
}
