/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Fragment, useContext } from 'react';
import { IhmContext } from '../../context/ihm.context';

export function MenuAndFormContainer(props: { children }) {
    return (
      <Fragment>
        {props.children}
      </Fragment>
);
}

export function FormContainer(props: { children }) {
    const { ihmState } = useContext(IhmContext);
    return (
      <div ref={ihmState.scrollableFormContainer} css={css` overflow: auto; height: 100%; width: 100%;`}>
        {props.children}
      </div>
);
}

export function MenuAndFormFooterContainer(props: { children }) {
    return (
      <div css={css`display:flex;justify-content: space-between; align-items: center`}>
        {props.children}
      </div>
);
}
