import React from 'react';
import {ROUTES} from '../../user/UserRoutesDef';

// NOT USE FOR THE MOMENT
import {SecretaryContainer} from './SecretaryContainer';

export const SecretaryRoutesPath = () => [
    `${ROUTES.SECRETARY_BASE}/chat`,
    `${ROUTES.SECRETARY_BASE}/home`,
    `${ROUTES.SECRETARY_BASE}/programs-list`,
    `${ROUTES.SECRETARY_BASE}/profile`,
    `${ROUTES.SECRETARY_BASE}/bachelor`,
    `${ROUTES.SECRETARY_BASE}/academics-list`,
    `${ROUTES.SECRETARY_BASE}/languages-list`,
    `${ROUTES.SECRETARY_BASE}/stays-abroad-list`,
    `${ROUTES.SECRETARY_BASE}/company-experiences-list`,
    `${ROUTES.SECRETARY_BASE}/motivation`,
    `${ROUTES.SECRETARY_BASE}/apprenticeship-company`,
    `${ROUTES.SECRETARY_BASE}/additional-documents-list`,
    `${ROUTES.SECRETARY_BASE}/payments-list`];

export const SecretaryRoutesDef = () => ({
    [`${ROUTES.BASE}`]: () => (
      <SecretaryContainer />
    ),
    [`${ROUTES.BASE}*`]: () => (
      <SecretaryContainer />
    ),
    [`${ROUTES.SECRETARY_BASE}*`]: () => (
      <SecretaryContainer />
    ),
});
