// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FormLayout } from 'antd/lib/form/Form';
import React, { Fragment } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';

export const PDFEntityFormContext = React.createContext([undefined, undefined] as [PDFEntityFormContextType<any>, any]);

export interface PDFEntityFormContextType<T> {
    entity: string;
    entityValue: T;
    layout?: FormLayout,
}

function Content(props: { children: any }) {
    return <Fragment>{props.children}</Fragment>;
}

export const PDFEntityFormContextProvider = (props: {
    entity: string,
    entityValue?: any,
    children: any,
}) => {
    const [state, updateState] = useImmer({
        parentType: props.entity,
        entity: props.entity,
        entityValue: props.entityValue,
    });

    useDeepCompareEffect(() => {
        updateState((draft) => {
            draft.entityValue = props.entityValue;
        });
    }, [props.entityValue, updateState]);

    return (
      <PDFEntityFormContext.Provider
        value={[state as PDFEntityFormContextType<any>, updateState]}
      >
        <Content>{props?.children}</Content>
      </PDFEntityFormContext.Provider>
    );
};
