import { IBachelor } from '@fstn/ecandidaturev2_api-interfaces';
import { Modal } from 'antd';
import React, { Fragment, ReactElement } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import * as Locale from '../locale';
import { useEntity } from '../../hooks/use-entity';
import { ROUTES } from '../../pages/user/UserRoutesDef';
import { sNavigate } from '../../utils/safeNavigation';

/**
 * Will prompt a modal and redirect to the bachelor page if it's not valid,
 * Valid condition : obtentionYear of bachelor must be defined
 * @param props
 * @constructor
 */
export function WithBachelorOk(props: { children: ReactElement }) {
  //console.log('Redraw WithProgramsListOk');
  const { loadEntity } = useEntity<IBachelor>();
  const [state, updateSate] = useImmer({
    loaded: false,
    entityValue: undefined as IBachelor,
  });

  async function load() {
    updateSate((draft) => {
      draft.loaded = true;
    });
    const res = await loadEntity('bachelor');
    updateSate((draft) => {
      draft.entityValue = res;
    });
  }

  useDeepCompareEffect(() => {
    if (!state.loaded) {
      load().then();
    }
  }, [state.loaded]);

  if (!state.loaded || !state.entityValue) {
    return <Fragment />;
  }

  // Pour pouvoir acceder a la page demandee, l'annee d'obtention du bac doit etre definie dans le dossier
  if (state.entityValue.obtentionYear) {
    return (
      <Fragment>
        {props.children}
      </Fragment>
    );
  }
  // Sinon, popup signalant que cette info est manquante, et redirection vers la page du bac pour completer l'annee d'obtention
  return (
    <Modal
      width="800vw"
      okButtonProps={{ 'data-testid': 'bachelor-redirect-ok' } as any}
      okText={<Locale.Button tkey="submit.continue" />}
      cancelText={<Locale.Button tkey="submit.completeCandidateFile" />}
      cancelButtonProps={{ disabled: true, style: { display: 'none' }, 'data-testid': 'bachelor-redirect-cancel' } as any}
      title={<Locale.Notification tkey="invalidBachelor" />}
      visible
      onOk={async () => {
        await sNavigate(`${ROUTES.CANDIDATE_BASE}/bachelor`);
      }}
    >
      <div>
        <Locale.Notification tkey="invalidBachelor.description" />
      </div>

    </Modal>
  );
}
