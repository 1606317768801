/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Card, Divider, Form, Space, } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Fragment, useContext } from 'react';
import { useImmer } from 'use-immer';
import { InlineLoading } from '../../common/indicator/SmartLoading';
import { ResponsiveInput } from '../../common/input/ResponsiveInput';
import { AxiosContext, AxiosContextType } from '../../context/axios.context';
import { ResultPanel } from './common/ResultPanel';

export function TestPublishJuryReviewEmail() {
  const { axios } = useContext<AxiosContextType>(AxiosContext);

  const [state, updateState] = useImmer({
    loading: false,
    error: undefined as string,  // Message retourné en cas d'échec de l'appel au controller
    result: undefined as string,  // chaine contenant le bilan de l'envoi des emails (nb en succès et en échec)
    details: undefined as string[]  // liste de chaines contenant les configurations (formation / statut) pour lesquelles les envois ont été en erreur
  });

  // Appel du service d'envoi des emails tests, avec affichage du résultat en fin de traitement
  // le bouton est désactivé durant l'opération et un "sablier" indique le traitement en cours
  async function onFinish(values) {
    try {
      updateState((draft) => {
        draft.loading = true;
      });
      const result = await axios.post(`/administration/testPublishJuryReviewEmail/${values.email}`);
      updateState((draft) => {
        draft.result = `${result.data.nbEmailSent} emails envoyés --- ${result.data.nbEmailError} emails en erreur`;
        draft.error = undefined;
        draft.details = result.data.listErrors;
      });
    } catch (ex) {
      updateState((draft) => {
        draft.error = ex.response.data.message;
        draft.result = undefined;
      });
    } finally {
      updateState((draft) => {
        draft.loading = false;
      });
    }
  }

  return (
    <Fragment>
      <Title level={2}>Test d'envoi des emails de publication des avis jury</Title>
      <Divider />
      <Space direction="vertical">
        <Card title="Instructions" style={{ width: 800 }}>
          <p>
            Cet outil permet d'envoyer un email pour chaque template existant pour chaque paire formation / avis jury.<br />
            Chaque email contient à la place du nom du candidat le nom de la formation / option ainsi que l'avis jury correspondant
          </p>
          <p>
            <br />
            <strong><em>Note :</em></strong>
            {' '}
            Le résultat indiquera le nombre de mails envoyés par l'application
          </p>

        </Card>
        <br />
        <Form
          name="testEmail"
          onFinish={onFinish}
          wrapperCol={{
            span: 8,
          }}
        >
          <Form.Item
            name="email"
            label="Adresse à laquelle envoyer les emails de test"
            rules={[
              {
                required: true,
                message: 'Email obligatoire !',
              }, { type: 'email' },
            ]}
          >
            <ResponsiveInput data-testid="referent-email" type="email" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={state.loading}>
              Envoi des emails
            </Button>
          </Form.Item>
          <InlineLoading loading={state.loading} context={''} />
        </Form>
      </Space>
      <ResultPanel error={state.error} result={state.result} details={state.details} />
    </Fragment>
  );
}
