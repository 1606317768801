// @ts-ignore
import { usePath } from 'hookrouter';
import React, { Fragment, useContext } from 'react';
import { UserContext } from '../../../context/user.context';
import { sNavigate } from '../../../utils/safeNavigation';

import { RGPD } from '../UserRoutesDef';

export function RgpdPageSwitcher({ children }: any) {
    const path = usePath();
    const { userCtx } = useContext(UserContext);
    if (path !== RGPD.ASK && userCtx?.user && (userCtx?.user?.rgpd === undefined || userCtx?.user?.rgpd === null)) {
        sNavigate(RGPD.ASK);
        return <Fragment />;
    }
    if (path !== RGPD.REFUSED && (userCtx?.user?.rgpd === false)) {
        sNavigate(RGPD.REFUSED);
        return <Fragment />;
    }
    return <Fragment>{children}</Fragment>;
}
