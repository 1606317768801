/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Fragment, useContext } from 'react';
import { UserContext, UserContextType } from '../../../context/user.context';

const Style = styled.div`
  background: #fed857 !important;
  .ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error), .ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-upload.ant-upload-drag{
    background: #fed857 !important;
  }
`;

export function CanSeeValidation(props: { children, className?: string }) {
    const { canSeeValidation } = useContext<UserContextType>(UserContext);
    return (
      <Fragment>
        {canSeeValidation() && (
        <Style className={props.className}>
          {props.children}
        </Style>
)}
      </Fragment>
);
}
